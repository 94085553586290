// @mui
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider } from '../../hook-form';
import { UserContext } from '../../../context/UserContext';
import { editWebhookURL } from 'src/API/Scheduler/Scheduler'; // Adjust the import path accordingly

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function EditWebhook(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, fetchWebhooks, webhook} = props; // webhookData should be passed in props
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
console.log("fetchWebhooks",webhook);
  // Validation Schema
  const membersSchema = Yup.object().shape({
    name: Yup.string().required('Webhook name is required'),
    url: Yup.string().url('Must be a valid URL').required('Webhook URL is required'),
  });

  const defaultValues = {
    name: webhook?.name || '', // Updated to use webhookData
    url: webhook?.url || '',   // Updated to use webhookData
  };

  // Initialize form methods
  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  // Effect to pre-fill form with existing webhook data
  useEffect(() => {
    if (webhook) {
      setValue('name', webhook?.name);
      setValue('url', webhook?.webhook_URL);
    }
  }, [webhook, setValue]);

  const onSubmit = async (value) => {
    console.log(value.url,"value.url");
    const data = {
      name: value.name,
      URL: webhook.webhook_URL,  
      new_URL: value.url, 
      alert_types: value.alert_types || null, 
    };
    setLoading(true);
    
    editWebhookURL(customerId, webhook.id, data) 
      .then((res) => {
        setLoading(false);
        handleClose();
        fetchWebhooks();
        enqueueSnackbar('Webhook updated successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error?.response?.data?.message || 'Error updating webhook', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };
  

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      style={{ margin: 0 }}
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 1 }}>
          Edit Webhook
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent>
          <Stack spacing={3} sx={{ py: 2 }}>
            <RHFTextField name="name" label="Webhook Name" fullWidth placeholder="Enter webhook name" />
            <RHFTextField 
              name="url" 
              label="Webhook URL" 
              fullWidth 
              placeholder="Enter webhook URL" 
              helperText="Must be a valid URL, e.g., https://example.com/webhook" 
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
