import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DeviceUpComponents from '../components/DeviceUpComponets/DeviceUpComponents';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function DeviceUp() {

  return (
    <Page title="Device Up">
      <Container maxWidth={false}>
        <div role="presentation" style={{  display: 'flex', alignItems: 'center',position:'relative'}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 ,cursor: 'pointer'}}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover"  sx={{ pb: 1.5 ,cursor: 'pointer'}}color="inherit" to="/dashboard/inventory">
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" sx={{ pb: 1.5 ,cursor: 'pointer'}} color="inherit" to="/dashboard/network-device">
              Network Devices
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ pointerEvents: 'none' }} href="/">
            <span style={{ cursor: 'default' }}>Device Up</span>
            </Typography>
          </Breadcrumbs>
        </div>
        <DeviceUpComponents />
      </Container>
    </Page>
  );
}
