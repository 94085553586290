/* eslint-disable */
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { FormControlLabel, Tooltip, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


const useStyles = makeStyles(theme => ({
    column: {
        width: '100%',
        minHeight: 130,
        backgroundColor: '#ebecf0',
        borderRadius: 3,
        boxSizing: 'border-box',
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        whiteSpace: 'normal',
        padding: 8,
        margin: 5,
    },
    title: {
        backgroundColor: '#3f51b5',
        fontSize: 17,
        color: ' #ebecf0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        padding: 5,
        borderRadius: 3,
        textAlign: 'center',
    },
    columnContainer: {
        transition: 'background-color 0.2s ease',
        backgroundColor: "#ffebe6",
    },
    eachColumn: {
        fontSize: 13,
        backgroundColor: "#fff",
        boxShadow: "0 1px 0 rgba(9,30,66,.25)",
        color: "#000",
        padding: '0px 10px',
        cursor: 'grab',
        borderRadius: 3,
        marginBottom: 5,
        wordWrap: 'break-word',
        userSelect: 'none',
    },
    icon: {
        margin: "0px 5px -3.8px 0",
        fontSize: 18,
        position: "relative",
        top: 2
    }
}));

const NetworkColumnSettings = props => {
    const classes = useStyles();
    const [leftPinnedItems, setLeftPinnedItmes] = useState([]);
    const [unPinnedItems, setUnPinnedItmes] = useState([]);
    const [rightPinnedItems, setRightPinnedItmes] = useState([]);
    const [items, setItems] = useState(props?.columnsSettingData);

    useEffect(() => {
        if (props.columnsSettingData) {
            setItems(props.columnsSettingData);
        }
    }, [props.columnsSettingData]);



    useEffect(() => {
        if (!!items) {
            setItems(
                items.sort(
                    (a, b) => a.order - b.order
                )
            );
            setLeftPinnedItmes(
                items?.filter(
                    (item) => item.pinned === "left"
                )
            );
            setUnPinnedItmes(
                items?.filter(
                    (item) => !item.pinned
                )
            );
            setRightPinnedItmes(
                items?.filter(
                    (item) => item.pinned === "right"
                )
            );
        }
    }, []);


    const removeItem = (from, fromIndex) => {
        switch (from) {
            case "leftPinned": {
                const removedLeftItem = leftPinnedItems.splice(fromIndex, 1);
                setLeftPinnedItmes(leftPinnedItems);
                return removedLeftItem;
            }
            case "rightPinned": {
                const removedRightItem = rightPinnedItems.splice(fromIndex, 1);
                setRightPinnedItmes(rightPinnedItems);
                return removedRightItem;
            }
            case "unPinned": {
                const removedUnpinnedtItem = unPinnedItems.splice(fromIndex, 1);
                setUnPinnedItmes(unPinnedItems);
                return removedUnpinnedtItem;
            }
            default:
        }
    };


    const findNewOrder = (list, toIndex) => {
        if (toIndex < list.length - 1 && toIndex >= 1) {
            return (
                list[toIndex - 1].order +
                (list[toIndex].order - list[toIndex - 1].order) / 2
            );
        } else if (toIndex === 0 && list.length !== 0) {
            return list[toIndex].order / 2;
        } else if (toIndex >= list.length && list.length !== 0) {
            return list[toIndex - 1].order + 10;
        } else {
            return 10;
        }
    };



    const addItem = (item, to, toIndex) => {
        let items = [];
        switch (to) {
            case "leftPinned":
                item.pinned = "left";
                item.order = findNewOrder(leftPinnedItems, toIndex);
                items = leftPinnedItems;
                items.splice(toIndex, 0, item);
                setLeftPinnedItmes(items);
                break;
            case "rightPinned":
                item.pinned = "right";
                item.order = findNewOrder(rightPinnedItems, toIndex);
                items = rightPinnedItems;
                items.splice(toIndex, 0, item);
                setRightPinnedItmes(items);
                break;
            case "unPinned":
                item.pinned = null;
                item.order = findNewOrder(unPinnedItems, toIndex);
                items = unPinnedItems;
                items.splice(toIndex, 0, item);
                setUnPinnedItmes(items);
                break;
            default:
        }
    };



    const reorder = (fromIndex, toIndex, from, to) => {
        return new Promise((resolve, rej) => {
            const [removedItem] = removeItem(from, fromIndex);
            addItem(removedItem, to, toIndex);
            resolve();
        });
    };


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        reorder(
            result.source.index,
            result.destination.index,
            result.source.droppableId,
            result.destination.droppableId
        ).then(() => {
            setItems([
                ...leftPinnedItems,
                ...unPinnedItems,
                ...rightPinnedItems,
            ]);
            props.setColumnsSettingData([
                ...leftPinnedItems,
                ...unPinnedItems,
                ...rightPinnedItems,
            ])

        });
    };



    const onClickHide = (item) => {
        const columns = [...items?.map((column) => {
            if (column.colId === item.colId) {
                const newCol = column;
                newCol.hide = !item.hide;
                return newCol;
            }
            return column;
        })];
        setItems(columns);
        props.setColumnsSettingData(columns)
    };



    const renderColumnSection = (label, cols, id) => {
        return (
            <div className={classes.column}>
                <div className={classes.title}>{label}</div>
                <Droppable droppableId={id}>
                    {({ innerRef, placeholder }, { isdraggingover }) => (
                        <div ref={innerRef} isdraggingover={isdraggingover}>
                            {cols.length === 0 ? <div>{"No columns in this section"}</div> :
                                cols.map((coll, index) => {
                                    return <Draggable
                                        key={coll.colId}
                                        draggableId={coll.colId}
                                        index={index}
                                        id={coll.colId}>
                                        {(
                                            { draggableProps, dragHandleProps: eventHandlers, innerRef },
                                            { isdragging }
                                        ) => (
                                            <div

                                                className={classes.eachColumn}
                                                ref={innerRef}
                                                {...draggableProps}
                                                {...eventHandlers}
                                                isdragging={isdragging}
                                            >
                                                <Typography variant="body1" style={{ margin: 0, cursor: 'grab' }}>
                                                    <DragIndicatorIcon className={classes.icon} />
                                                    <Tooltip placement="left" title={coll.hide ? "Show" : "Hide"}>
                                                        <FormControlLabel
                                                            style={{ marginRight: 0 }}
                                                            control={
                                                                <Checkbox
                                                                    checked={!coll.hide}
                                                                    onChange={(e) => onClickHide(coll)}
                                                                    name="hide"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </Tooltip>
                                                    {coll.headerName}
                                                </Typography>
                                            </div>
                                        )}
                                    </Draggable>
                                })
                            }
                            {placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }



    return (
        <DragDropContext onDragEnd={onDragEnd} style={{ width: '100%' }}>
            <Droppable droppableId='column_droppableId' type="droppableItem" style={{ width: '100%' }}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                        {renderColumnSection(("Left pinned columns"), leftPinnedItems, "leftPinned")}
                        {renderColumnSection(("Center columns (flexible)"), unPinnedItems, "unPinned")}
                        {renderColumnSection(("Right pinned columns"), rightPinnedItems, "rightPinned")}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
};



export default (NetworkColumnSettings);