/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { networkHealthVPNClients } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';



// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 100
}));

// ---------------------------------------


const VPNClients = ({systemExpanded}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState('');
    const [loading, setLoading] = useState(true);

    const solveCondition = async() => {
        if(!healthDashState.selectedDevice || systemExpanded === false){
            return '';
        }
        fetchVPNClients();
    };

    const fetchVPNClients = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start/1000,
            end: data.end/1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setLoading(true);
        networkHealthVPNClients(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setLoading(false);
                setResData(res?.data);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, systemExpanded]);


    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
           <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{pl:1}}>
                            VPN Clients
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                        <ChartWrapperStyle dir="ltr">
                                <Typography color="#03a9f4" sx={{fontSize:'3.4rem',fontWeight:700,textAlign:'center'}}>
                                    {resData?.series}
                                </Typography>
                        </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default VPNClients;
