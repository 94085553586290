// material
import { Grid } from '@mui/material';
import { DcAgentTable } from './Components';

// ----------------------------------------------------------------------


export default function Dcagent() {

  return (
    <>
      <Grid container>
        {/* <Grid item sm={12} md={12} lg={12}>
            <Card sx={{textAlign:'center',padding:'20px'}}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Data Collector for NMS
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Download the ISO and follow instructions to get started
                </Typography>
                <Button variant="contained">Download DC Agent</Button>
              </Box>
            </Card>
        </Grid> */}
      </Grid>
      <Grid container sx={{paddingTop:'30px'}}>
        <Grid item sm={12} lg={12} md={12}>
            <DcAgentTable />
        </Grid>
      </Grid>
    </>
  );
}
