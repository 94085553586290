import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { DeviceTypesComponent } from '../../components/DeviceTypesComponent';
import Page from '../../components/Page';
// ----------------------------------------------------------------------

export default function DeviceTypes() {
  return (
    <Page title="Inventory">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/system-configuration/device-types">
              System configuration
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/system-configuration/device-types">
              Device-types
            </Typography>
          </Breadcrumbs>
        </div>
        <DeviceTypesComponent />
      </Container>
    </Page>
  );
}
