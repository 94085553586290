// import { yupResolver } from '@hookform/resolvers/yup';
// import CloseIcon from '@mui/icons-material/Close';
// import LoadingButton from '@mui/lab/LoadingButton';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   FormControl,
//   IconButton,
//   LinearProgress,
//   Stack,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { useSnackbar } from 'notistack';
// import { useContext, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import * as Yup from 'yup';
// import { agentReConfigure } from '../../../../API/DcAgent/DcAgent';
// import { UserContext } from '../../../../context/UserContext';
// import { FormProvider, RHFTextField } from '../../../hook-form';
// import ReConfigLocationSelector from './ReConfigLocationSelector';
// import ReConfigSubnetSelector from './ReConfigSubnetSelector';
// import ReConfigZoneSelector from './ReConfigZoneSelector';

// const useStyles = makeStyles({
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
//   fieldContainer: { minHeight: 290 },
// });

// export default function ReconfigureDcAgent(props) {
//   const { openDialog, handleClose, agentData, onConfigure } = props;
//   const classes = useStyles();
//   const [loading, setLoading] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const { customerId } = useContext(UserContext);
//   const [dcAgentLoading, setDcAgentLoading] = useState(false);
//   const [zoneValue, setZoneValue] = useState(agentData?.zones);
//   const [locationValue, setLocationValue] = useState(agentData?.locations || []);
//   const [selectedSubnets, setSelectedSubnets] = useState(agentData?.subnets || []);
//   const [subnetOptions, setSubnetOptions] = useState([]);
//   const [zoneSelectionSource, setZoneSelectionSource] = useState(null);

//   const credentialsSchema = Yup.object().shape({
//     agentName: Yup.string(),
//   });

//   const defaultValues = {
//     agentName: agentData.r_agent_name,
//   };

//   const methods = useForm({
//     resolver: yupResolver(credentialsSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const selectLocations = (value) => {
//     setLocationValue(value);
//   };
//   const _setZoneValue = (value, _source) => {
//     setZoneSelectionSource(_source);
//     setZoneValue(value);
//   };
//   const reConfigAgents = (value) => {
//     setDcAgentLoading(true);

//     const data = {
//       name: value.agentName,
//       zone_uuid: zoneValue?.zone_id,
//       subnets: selectedSubnets?.map((item) => item.subnet_id),
//     };
//     agentReConfigure(customerId, agentData.agent_id, data)
//       .then((res) => {
//         setDcAgentLoading(false);
//         enqueueSnackbar(
//           'Re-configured sucessfully',
//           {
//             variant: 'success',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//         onConfigure();
//       })
//       .catch((res) => {
//         setDcAgentLoading(false);
//         enqueueSnackbar(
//           res.response.data.message,
//           {
//             variant: 'error',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       });
//   };

//   const _setDcAgentSubnetOptions = (_subnetOptions) => {
//     setSelectedSubnets(_subnetOptions);
//     setSubnetOptions(_subnetOptions);
//   };
//   const [val, setVal] = useState([])
//   console.log(zoneValue);

//   return (
//     <Dialog
//       open={openDialog}
//       onClose={handleClose}
//       fullWidth
//       maxWidth="sm"
//       aria-labelledby="responsive-dialog-title"
//       classes={{
//         scrollPaper: classes.topScrollPaper,
//         paperScrollBody: classes.topPaperScrollBody,
//       }}
//     >
//       <FormProvider methods={methods} onSubmit={handleSubmit(reConfigAgents)}>
//         <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//           Re-configure
//           <IconButton onClick={handleClose} sx={{ float: 'right' }}>
//             <CloseIcon sx={{ fontSize: '1.5rem' }} />
//           </IconButton>
//         </DialogTitle>
//         <Divider />
//         {loading && <LinearProgress />}
//         <DialogContent sx={{ py: 3, px: 3 }}>
//           <Stack spacing={2}>
//             <RHFTextField name="agentName" label="Agent name" size="small" />

//             <Stack direction="row" spacing={2} sx={{ width: '550px' }}>
//               <FormControl fullWidth size="small">
//                 <ReConfigZoneSelector zoneValue={zoneValue} />
//               </FormControl>

//               <FormControl fullWidth size="small">
//                 <ReConfigLocationSelector
//                   zoneSelectionSource={zoneSelectionSource}
//                   selectAllLabel='Select all'
//                   onChange={setVal}
//                   zoneValue={zoneValue}
//                   // setZoneValue={(_zone) => _setZoneValue(_zone, 'location')}
//                   setLocationValue={selectLocations}
//                   locationValue={locationValue}
//                   setDcAgentSubnetOptions={_setDcAgentSubnetOptions}
//                 />
//               </FormControl>
//             </Stack>

//             <FormControl fullWidth size="small">
//               <ReConfigSubnetSelector
//                 setSelectedSubnets={setSelectedSubnets}
//                 subNetOptions={subnetOptions}
//                 selectedSubnets={selectedSubnets}
//               />
//             </FormControl>
//           </Stack>
//         </DialogContent>
//         <Divider />
//         <DialogActions>
//           <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
//             Cancel
//           </Button>
//           <LoadingButton
//             sx={{ float: 'right' }}
//             variant="outlined"
//             color="primary"
//             loading={dcAgentLoading}
//             type="submit"
//             disabled={!zoneValue?.r_zone_name || !zoneValue?.zone_id} // Disable if zone name or ID is empty/null
//           >
//             Re-configure
//           </LoadingButton>

//         </DialogActions>
//       </FormProvider>
//     </Dialog>
//   );
// }

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { agentReConfigure } from '../../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../../hook-form';
import ReConfigLocationSelector from './ReConfigLocationSelector';
import ReConfigSubnetSelector from './ReConfigSubnetSelector';
import ReConfigZoneSelector from './ReConfigZoneSelector';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fieldContainer: { minHeight: 290 },
});

export default function ReconfigureDcAgent(props) {
  const { openDialog, handleClose, agentData, onConfigure } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [dcAgentLoading, setDcAgentLoading] = useState(false);
  const [zoneValue, setZoneValue] = useState(agentData?.zones);
  const [locationValue, setLocationValue] = useState(agentData?.locations || []);
  const [selectedSubnets, setSelectedSubnets] = useState(agentData?.subnets || []);
  const [subnetOptions, setSubnetOptions] = useState([]);
  const [zoneSelectionSource, setZoneSelectionSource] = useState(null);

  const credentialsSchema = Yup.object().shape({
    agentName: Yup.string(),
  });

  const defaultValues = {
    agentName: agentData.r_agent_name,
  };

  const methods = useForm({
    resolver: yupResolver(credentialsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const selectLocations = (value) => {
    setLocationValue(value);
  };
  const _setZoneValue = (value, _source) => {
    setZoneSelectionSource(_source);
    setZoneValue(value);
  };
  const reConfigAgents = (value) => {
    setDcAgentLoading(true);

    const data = {
      name: value.agentName,
      zone_uuid: zoneValue?.zone_id,
      subnets: selectedSubnets?.map((item) => item.subnet_id),
    };
    agentReConfigure(customerId, agentData.agent_id, data)
      .then((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          'Re-configured sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        onConfigure();
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const _setDcAgentSubnetOptions = (_subnetOptions) => {
    setSelectedSubnets(_subnetOptions);
    setSubnetOptions(_subnetOptions);
  };
  const [val, setVal] = useState([])

  // Filter duplicate locations by location_id
 const filteredLocationValue = locationValue.filter((value, index, self) =>
  index === self.findIndex((t) => t.location_id === value.location_id)
);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(reConfigAgents)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Re-configure
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent sx={{ py: 3, px: 3 }}>
          <Stack spacing={2}>
            <RHFTextField name="agentName" label="Agent name" size="small" />

            <Stack direction="row" spacing={2} sx={{ width: '550px' }}>
              <FormControl fullWidth size="small">
                <ReConfigZoneSelector zoneValue={zoneValue} />
              </FormControl>

              <FormControl fullWidth size="small">
                <ReConfigLocationSelector
                  zoneSelectionSource={zoneSelectionSource}
                  selectAllLabel='Select all'
                  onChange={setVal}
                  zoneValue={zoneValue}
                  // setZoneValue={(_zone) => _setZoneValue(_zone, 'location')}
                  setLocationValue={selectLocations}
                  locationValue={filteredLocationValue}
                  // locationValue={locationValue}
                  setDcAgentSubnetOptions={_setDcAgentSubnetOptions}
                />
              </FormControl>
            </Stack>

            <FormControl fullWidth size="small">
              <ReConfigSubnetSelector
                setSelectedSubnets={setSelectedSubnets}
                subNetOptions={subnetOptions}
                selectedSubnets={selectedSubnets}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ float: 'right' }}
            variant="outlined"
            color="primary"
            loading={dcAgentLoading}
            type="submit"
            disabled={!zoneValue?.r_zone_name || !zoneValue?.zone_id} // Disable if zone name or ID is empty/null
          >
            Re-configure
          </LoadingButton>

        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}