import { instance as modem } from '../modem';

export const logOut = async (data) => {
  const newPromise =  modem
    .post(`auth/logout`, data)
    .then((response) => {
      localStorage.setItem('AuthorizationToken', null);
      localStorage.clear();
      return response;
    })
    .catch((response) => {
      return response;
    });
  return newPromise;
};

