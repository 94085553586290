import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getLocations = async (id, payload) => {
  const newPromise =  modem
    .post(`${id}/locations/search`, payload)
    .then((response) => {      
      return response.data;
    })
    return newPromise
};

let locations ={
  data : []
}
export const getLocationsWithoutPagination = async (id, data) => {
  if(locations?.data?.length){
    return Promise.resolve(locations)
  }
  const newPromise =  modem
    .get(`${id}/locations?paginate=0`, data)
    .then((response) => {
      locations = response
      return locations 
      // return response.data;
    })
    return newPromise
};

export const deleteLocation = async (id, memberId, data) => {
  const newPromise =  modem
  .delete(`${id}/locations/${memberId}`, data)
    .then((response) => {
      locations = {
        data : []
      };
      return response.data.data;
    })
    return newPromise
};

export const addLocation = async (id,data) => {
  const newPromise =  modem
    .post(`${id}/locations`, data)
    .then((response) => {
      locations = {
        data : []
      }
      return response.data.data;
    })
    return newPromise
};

export const addMultipleLocation = async (id,data) => {
  const newPromise =  modem
    .post(`${id}/locations/bulk`, data)
    .then((response) => {
      locations = {
        data : []
      }
      return response.data.data;
    })
    return newPromise
};

export const updateLocation = async (id,LocationId, data) => {
  const newPromise =  modem
  .post(`${id}/locations/${LocationId}`, data)
    .then((response) => {
      locations = {
        data : []
      }
      return response.data.data;
    })
    return newPromise
};