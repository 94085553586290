import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Grid, Typography } from '@mui/material';
import Page from '../components/Page';
import S3BrowserComponent from '../components/CMDBBackups/S3BrowserComponent';
// ----------------------------------------------------------------------

export default function Configuration() {

  return (
    <Page title="Configuration">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard">
              {<HomeOutlinedIcon />}
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/summary-events">
              CMDB
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/configuration">
              Backups
            </Typography>
          </Breadcrumbs>
        </div>
        <Grid container spacing={4}>
          {/* <Grid item lg={12} md={12} sm={12}>
            <hr />
            <br />
            <LocationSelect />
          </Grid> */}

          <Grid item xs={12} md={12} lg={12}>
              <S3BrowserComponent/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
