import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import DatasetIcon from '@mui/icons-material/Dataset';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import WifiIcon from '@mui/icons-material/Wifi';
import cmdbIcon from './cmndIcon.png';
import endpointIcon from './endpointIcon.png';
import inventoryIcon from './inventoryIcon.png';
import networkDeviceIcon from './networkDeviceIcon.png';
import sitewiseIcon from './sitewiseIcon.png';
import globalMapIcon from './globalMapIcon.png';
import visibilityIcon from './visibilityIcon.png';
// --------------------------------------------




const navConfig = [
  {
    title: 'Dashboards',
    icon: <DashboardOutlinedIcon />,
    children: [
      {
        title: 'Dashboard',
        icon: <DashboardOutlinedIcon />,
        to: '/dashboard/dashboard',
      },

      // {
      //   title: 'Network Health',
      //   icon: <MonitorHeartOutlinedIcon />,
      //   to: '/dashboard/network-health',
      // },
    ],
  },
  {
    title: 'Copilot View (Beta)',
    icon: <WifiIcon />,
    // to: '/dashboard/wifi-events',
    children: [
      {
        title: 'Copilot',
        icon: <WifiIcon />,
        to: '/dashboard/wifi-events',
      },
    ],
  },
  {
    title: 'Network Health',
    icon: <MonitorHeartOutlinedIcon />,
    children: [
      {
        title: 'Sitewise Health',
        icon: <MonitorHeartOutlinedIcon />,
        to: '/dashboard/health',
      },
    ],
  },
  {
    title: 'Visibility',
    icon: <img width="17px" height="17px" src={visibilityIcon} alt="visibilityIcon" />,
    children: [
      {
        title: 'Global Map',
        icon: <img width="17px" height="17px" src={globalMapIcon} alt="globalMapIcon" />,
        to: '/dashboard/global-map',
      },
      {
        title: 'Sitewise Map',
        icon: <img width="17px" height="17px" src={sitewiseIcon} alt="sitewiseIcon" />,
        to: '/dashboard/sitewise-map',
      },
    ],
  },
  {
    title: 'Inventory',
    icon: <img width="17px" height="17px" src={inventoryIcon} alt="inventoryIcon" />,
    children: [
      {
        title: 'Network Devices',
        icon: <img width="17px" height="17px" src={networkDeviceIcon} alt="networkDeviceIcon" />,
        to: '/dashboard/network-device',
      },
      {
        title: 'Client devices',
        icon: <img width="19px" height="19px" src={endpointIcon} alt="endpointIcon" />,
        to: '/dashboard/endpoint',
      },
      {
        title: 'Unknown Devices',
        icon: <HelpOutlineOutlinedIcon />,
        to: '/dashboard/unknown-devices',
      },

      // {
      //   title: 'IPAM',
      //   icon: <CalendarViewDayOutlinedIcon />,
      //   to: '/dashboard/ipam',
      // },
    ],
  },
  {
    title: 'CMDB',
    icon: <img width='17px' height='17px' src={cmdbIcon} alt='cmdbIcon' />,
    children: [
      // {
      //   title: 'Grouped summary',
      //   icon: <DatasetIcon />,
      //   to: '/dashboard/grouped-summary',
      // },
      // {
      //   title: 'Devicewise summary',
      //   icon: <ViewTimelineIcon />,
      //   to: '/dashboard/devicewise-summary',
      // },
      {
        title: 'Change events',
        icon: <StorageIcon />,
        to: '/dashboard/summary-events',
      },
      {
        title: 'CMDB Inventory',
        icon: <BackupTableIcon />,
        to: '/dashboard/config-change-backup-table',
      },
      // {
      //   title: 'Backups(Old)',
      //   icon: <PieChartOutlinedIcon />,
      //   to: '/dashboard/configuration',
      // },
    ]
  },
  // {
  //   title: 'ISP Management',
  //   icon: <NetworkCheckIcon />,
  //   children: [
  //     {
  //       title: 'Overview',
  //       icon: <StackedLineChartOutlinedIcon />,
  //       to: '/dashboard/isp-management',
  //     },
  //     {
  //       title: 'SDWAN',
  //       icon: <StackedLineChartOutlinedIcon />,
  //       to: '/dashboard/sdwan',
  //     },
  //     {
  //       title: 'Sitewise',
  //       icon: <PieChartOutlinedIcon />,
  //       to: '/dashboard/ISP-sitewise',
  //     },
  //   ]
  // },
  {
    title: 'Logs',
    icon: <EventOutlinedIcon />,
    children: [
      {
        title: 'Device Up/Down',
        icon: <AnalyticsOutlinedIcon />,
        to: '/dashboard/device-up-down',
      },
      {
        title: 'CMDB Scan Events',
        icon: <MonitorHeartOutlinedIcon />,
        to: '/dashboard/config-backup-events',
      },
      {
        title: 'CMDB Change Events',
        icon: <AirlineStopsIcon />,
        to: '/dashboard/config-change-events',
      },

    ],
  },
  {
    title: 'Settings',
    icon: <SettingsOutlinedIcon />,
    children: [
      {
        title: 'My Profile',
        icon: <AccountBoxIcon />,
        to: './settings',
      },
      {
        title: 'Users',
        icon: <GroupOutlinedIcon />,
        to: '/dashboard/members',
      },
      // {
      //   title: 'Schedulers',
      //   icon: <DateRangeOutlinedIcon />,
      //   to: '/dashboard/schedulers',
      // },
      {
        title: 'Roles',
        icon: <AttributionOutlinedIcon />,
        to: '/dashboard/roles',
      },
      {
        title: 'Alerts',
        icon: <AddAlertIcon />,
        to: '/dashboard/email-config',
      },
      {
        title: 'Notifications',
        icon: <NotificationsActiveOutlinedIcon />,
        to: '/dashboard/general',
      },
      {
        title: 'CMDB Ignore Rule',
        icon: <RuleIcon />,
        to: '/dashboard/ignore-rule',
        strict: true,
        permission: "NMS_PORTAL_DEVICE_CONFIG_IGNORE_RULES_READ"
      },
    ],
  },
  {
    title: 'Onboarding',
    icon: <RocketLaunchOutlinedIcon />,
    children: [
      {
        title: 'DC Agents',
        icon: <TokenOutlinedIcon />,
        to: '/dashboard/dc-agent',
      },
      {
        title: 'Locations',
        icon: <AddLocationOutlinedIcon />,
        to: '/dashboard/locations',
      },
      // {
      //   title: 'On-demand',
      //   icon: <OndemandVideoIcon />,
      //   to: '/dashboard/ondemand',
      // },
      {
        title: 'Zones',
        icon: <MyLocationOutlinedIcon />,
        to: '/dashboard/zones',
      },
      {
        title: 'Credentials',
        icon: <PasswordOutlinedIcon />,
        to: '/dashboard/credential',
      },
    ],
  },

];

export default navConfig;
