import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getConfigChangeEventsTable } from '../../../API/S3/ConfigEvents';
import { UserContext } from '../../../context/UserContext';
import TooltipRendererForSummary from '../../../utils/TooltipRendererForSummary';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import { toCamelizeWords } from '../../../utils/formatString';
import { fDateTime } from '../../../utils/formatTime';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';
import ActionRendere from './renderer/ActionRenderer';
import StatusRendere from './renderer/StatusRendere';

//---------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  cellCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textAlign: 'center',
    marginTop: '8px',
  },
}));
//----------------------------------------------------------------------

export default function DevicewiseSummaryTable(props) {
  const classess = useStyles();
  const { locationData } = props;
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);

  // ----------------------------------------------------------------------------

  // ----------------------------------------------------------------------

  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');



  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    }
    if (isDesktop) {
      return '20px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }


  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };


  // ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-devicewise-summary-change-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
        };
        delete payload.context;
        getConfigChangeEventsTable(customerId, payload)
          .then((configData) => {
            let lastRow = -1;
            if (configData.total <= _params.endRow) {
              lastRow = configData.total;
            }
            setTotalItems(configData.total);
            if (configData.total === 0) {
              params.api.showNoRowsOverlay();
            }
            _params.successCallback(configData.data, lastRow);
            setRefreshLoading(false);
          })
          .catch((e) => {
            console.log(e, 'error');
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [customerId, query]);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      suppressMenu: true,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: 'Event Date',
      colId: 'g_created_at',
      width: 230,
      // pinned: 'left',
      sortable: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.data?.g_created_at;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.g_created_at;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Change Since',
      colId: 'r_config_change_since',
      // pinned: 'left',
      width: 200,
      sortable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_config_change_since;
      },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_node_mac_address',
      // pinned: 'left',
      width: 200,
      sortable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_mac_address;
      },
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      sortable: true,
      filter: true,
      width: 200,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_ip_address) {
          return (
            <div className={classess.root}>
              {params?.data?.r_dvc_ip_address}
              <span className={classess.field}>
                {TooltipRendererForSummary(params)}
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classess.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return params.data?.r_dvc_ip_address;
      },
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: true,
      filter: true,
      width: 248,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_serial_number;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      // pinned: 'left',
      width: 200,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_location_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      // pinned: 'right',
      sortable: true,
      width: 200,
      filter: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.l_dvc_typ_name);
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Status',
      colId: 'r_criticality',
      sortable: false,
      filter: false,
      width: 200,
      // pinned: 'right',
      floatingFilter: false,
      cellRenderer: 'statusRendere',
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Actions',
      colId: 'actions',
      // pinned: 'right',
      sortable: false,
      filter: false,
      width: 200,
      floatingFilter: false,
      cellRenderer: 'actionRendere',
    },
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const components = useMemo(() => ({
    actionRendere: ActionRendere,
    statusRendere: StatusRendere
  }), []);

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem(
      'nms-devicewise-summary-change-column',
      JSON.stringify(mapColumnDefinitionToStore(columnHeaders))
    );
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-devicewise-summary-change-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  // ----------------------------------------------------------------------------
  return (
    <>
      <Card elevation={0} sx={{ borderColor: 'none', boxShadow: 'none' }}>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Chip label={totalItems || 0} />
                {refreshLoading && (
                  <CircularProgress
                    size={28}
                    style={{
                      position: 'absolute',
                      left: 12,
                      top: 26,
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  Change event
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search events"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Box>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      openDialog={openColSettingsDialog}
                      handleClose={() => setOpenColSettingsDialog(false)}
                      columnDefs={columnDefs}
                      gridApi={gridApi}
                      resetColumnSetting={resetColumnSetting}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            components={components}
            onDragStopped={onDragStopped}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
          />
        </Box>
      </Card>
    </>
  );
}

DevicewiseSummaryTable.propTypes = {
  locationData: PropTypes.array,
};