/* eslint-disable */
import { RestartAlt, Settings, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
  Link,
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Panel,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,

} from 'reactflow';
import 'reactflow/dist/style.css';
// import '../../../../styles/sitewise-map-styling.css';
import { getSitewiseTopology } from '../../../../API/NetworkDevices/topology';
import EnterFullscreen from '../../../../Assets/enterFullscreen.svg';
import ExitFullscreen from '../../../../Assets/exitFullscreen.svg';
import sitewiseIcon from '../../../../Assets/sitewiseIcon.png';
import { updateLocation } from '../../../../actions/updateLocation';
import { UserContext } from '../../../../context/UserContext';
import { useFullscreen } from "../../../../hooks/useFullscreen";
import { toCamelizeWords } from '../../../../utils/formatString';
import LocationSelectGraph from '../../LocationSelectGraph';
import PanControl from '../../PanControl';
import { CustomDeviceNode } from './CustomeNodes/CustomDeviceNode';
import FloatingConnectionLine from './FloatingConnectionLine';
import LegendControl from './LegendControl';
import NodeSelector from './NodeSelector';
import SitewiseFloatingEdge from './SitewiseFloatingEdge';
import { useMapSettingContext } from './mapSettingProvider';
import SSHdeviceTable from './SSHdeviceTable'
import CloseIcon from '@mui/icons-material/Close';


const nodeTypes = {
  'ACCESS POINT': CustomDeviceNode,
  'endpoint': CustomDeviceNode,
  'INTERNET': CustomDeviceNode,
  'FIREWALL': CustomDeviceNode,
  'FIREWALL_STACK': CustomDeviceNode,
  'SERVER': CustomDeviceNode,
  'STACK': CustomDeviceNode,
  'SWITCH': CustomDeviceNode,
  'SWITCH_STACK': CustomDeviceNode,
  'SWITCHOSX': CustomDeviceNode,
  'VIRTUAL': CustomDeviceNode,
  'WIRELESS_MOBILITY_MASTER': CustomDeviceNode,
  'WIRELESS_VIRTUAL_CONTROLLER': CustomDeviceNode,
};

const edgeTypes = {
  floating: SitewiseFloatingEdge,
};

const useStyles = makeStyles(() => ({
  overlayCard: {
    width: '300px',
    height: 'auto',
    fontSize: '1.6rem',
    opacity: 1,

  },
  CardItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  cardText: {
    width: '85%',
    overflow: 'hidden',
  },
  bannerContainer: {
    width: '100%',
    height: '120%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #eeeeee'
  },
  graphContainer: {
    width: '100%',
    height: '90%',
    backgroundColor: '#f2f2f226',
    overflowY: 'auto',
  },

  // graphContainer: {
  //   width: '100%',
  //   height: '50%',
  //   backgroundColor: '#f2f2f226',
  //   overflowY: 'auto',

  //   minHeight: '600px',
  //   minWidth: '600px',
  //   '@media (max-width: 1024px)': {
  //     height: '90%',
  //   },
  //   '@media (min-width: 1440px)': {
  //     height: '70%',
  //   },
  //   '@media (min-width: 2560px)': {
  //     height: '90%',
  //   },
  // },

  panelGridBox: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'right',
    alignItems: 'center',
    padding: '10px',
  },
  panelGridBoxItem: {
    backgroundColor: 'white',
  },
  TypoGridBox1: {
    fontSize: '1.25rem',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    padding: '5px'
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '10px',
    left: '10px',

  },
  fullScreenCss: {
    height: "100vh",
    background: 'white',
    overflow: 'auto',
  },
  notfullScreenCss: {
    height: "-webkit-calc(100vh - 200px)",
    height: "-moz-calc(100vh - 200px)",
    height: "calc(100vh - 200px)",
    background: 'white',
    overflow: 'auto',

  },
}));




// -------------------------------------------------------------------------------------------
export default function OverView() {
  const classess = useStyles();
  const location = useLocation();
  const { customerId } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [hoveredEdge, setHoveredEdge] = useState([]);
  const [edgeLabelOnHover, setEdgeLabelOnHover] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState([]);
  const [overallNodes, setOverallNodes] = useNodesState([])
  const [overallEdges, setOverallEdges] = useEdgesState([])
  const [loading, setLoading] = useState(false);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);
  const locationData = useSelector((state) => state.locationSelectReducer.overview.locationData);
  const reactFlowWrapper = useRef(null);
  const [resetLayout, setResetLayout] = useState(false);
  const dispatch = useDispatch();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { mapSettingState, mapSettingDispatch } = useMapSettingContext()
  const fullScreenRef = useRef(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(fullScreenRef);
  const abortControllerRef = useRef(null);
  const [isFullscreen1, setIsFullscreen1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState('');
  // const [vendorsType, setVendorsType] = useState('');
  // const [devicesData, setDevicesData] = useState({});
  const selectDeviceType = async (_deviceType) => {
    setDeviceTypes(_deviceType);
  };
  console.log(openDialog, 'setOpenDialog');
  const onToggleFullscreen = () => {
    toggleFullscreen();
    // onFullscreenChange();
    setIsFullscreen1((prev) => !prev);
    mapSettingDispatch({ type: 'TOGGLE_FULLSCREEN', payload: null })
  }

  const onFullscreenChange = () => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView({ duration: 1000 })
      }, 500);
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  // --------------------------------------------------------------------

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'overview', locationUuid: _locations }));
  };

  const onSelectNode = async (_node) => {
    mapSettingDispatch({ type: 'SET_NODE_SELECTED', payload: _node })
    zoomToNode(_node)
  };

  const zoomToNode = (nodeId) => {
    reactFlowInstance.fitView({ nodes: [{ id: nodeId }], duration: 1000 })
  };

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.locationUuid) {
        dispatch(updateLocation({ page: 'overview', locationUuid: [parsed.locationUuid] }));
      }
    }
  }, [location]);

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleVisibility = (item) => {
    if (hiddenItems.includes(item)) {
      setHiddenItems(hiddenItems.filter((hiddenItem) => hiddenItem !== item));
    } else {
      setHiddenItems([...hiddenItems, item]);
    }
  };

  useEffect(() => {
    setNodes(overallNodes.filter((i) => !hiddenItems.includes(i.type)))
  }, [hiddenItems, availableDeviceTypes]);

  const applyNodes = (_nodes, _edges) => {
    setOverallNodes(_nodes)
    setOverallEdges(_edges)
    setEdges(_edges)
    setAvailableDeviceTypes(
      [
        "INTERNET",
        "FIREWALL",
        "WIRELESS_VIRTUAL_CONTROLLER",
        "SWITCH_STACK",
        "SWITCH",
        "ACCESS POINT",
      ]
    );

  }

  const fetchData = async () => {
    setLoading(true);
    if (!locationData || locationData?.length === 0) {
      applyNodes([], []);
      setLoading(false);
      setResetLayout(false);
      return
    }
    const payload = {
      // endRow: 200,
      // startRow: 0,
    };
    if (locationData.length > 0) {
      payload.location_uuids = locationData;
    }
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const newAbortController = new AbortController();

      abortControllerRef.current = newAbortController;
      const res = await getSitewiseTopology(customerId, payload, newAbortController);
      applyNodes(res.nodes, res.edges);
      setResetLayout(false);
      if (reactFlowInstance) {
        setTimeout(() => {
          reactFlowInstance.fitView({ duration: 1000 })
        }, 500);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResetLayout(false);
    }
  };

  const handleResetLayout = () => {
    setResetLayout(true);
    applyNodes([], [])
    fetchData();
  };

  useEffect(() => {
    fetchData();
    mapSettingDispatch({ type: 'SET_NODE_SELECTED', payload: "" })
  }, [customerId, locationData]);

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (hoveredEdge.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
            },
            style: {
              strokeWidth: 2,
              stroke: "url(#gradient)"
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          style: {},
          data: {
            ..._edge.data,
          },
        }
      })
    }
    )
  }, [hoveredEdge]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const handleNodeHover = (event, node) => {
    const connectedEdges = edges.filter(
      (edge) =>
        edge.type === 'floating' &&
        (edge.source === node.id || edge.target === node.id || edge.source === '0')
    );
    setHoveredEdge(connectedEdges);
  };

  const handleNodeHoverStop = () => {
    setHoveredEdge([]);
  };

  const handleEdgeMouseEnter = (event, edge) => {
    setEdgeLabelOnHover([edge]);
  };

  const handleEdgeMouseLeave = () => {
    setEdgeLabelOnHover([]);
  };

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (edgeLabelOnHover.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
              hoverEdgeLabel: true,
            },
            style: {
              strokeWidth: 3,
              stroke: "url(#gradient)"
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          style: {},
          data: {
            ..._edge.data,
            hoverEdgeLabel: false,
          },
        }
      })
    }
    )
  }, [edgeLabelOnHover]);

  const handleShowHideLabel = () => {
    mapSettingDispatch({ type: 'TOGGLE_SHOW_LABEL' })
  };

  const handleShowLegend = () => {
    mapSettingDispatch({ type: 'TOGGLE_SHOW_LEGEND' })
  };

  const handleShowCounter = () => {
    mapSettingDispatch({ type: 'TOGGLE_COUNTER' })
  };

  const isTablet = useMediaQuery('(max-width: 1020px)');

  const isDesktop = useMediaQuery('(min-width: 1440px)');

  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    }
    if (isDesktop) {
      return '20px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }

  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };


  // ----------------------------------------------------------------------------------------------
  return (
    // <div id="react-flow-sitewise-map" ref={fullScreenRef}>
    //   <Box className={isFullscreen ? classess.fullScreenCss : classess.notfullScreenCss} >
    //     <div className={classess.graphContainer} ref={reactFlowWrapper}>
    //       {loading && <LinearProgress />}
    //       <ReactFlowProvider>
    //         <ReactFlow
    //           fitView
    //           nodes={nodes}
    //           edges={edges}
    //           nodeTypes={nodeTypes}
    //           edgeTypes={edgeTypes}
    //           onConnect={onConnect}
    //           onEdgesChange={onEdgesChange}
    //           onNodesChange={onNodesChange}
    //           onInit={(instance) => setReactFlowInstance(instance)}
    //           connectionLineComponent={FloatingConnectionLine}
    //           onEdgeMouseEnter={handleEdgeMouseEnter}
    //           onEdgeMouseLeave={handleEdgeMouseLeave}
    //           onNodeMouseEnter={handleNodeHover}
    //           onNodeMouseLeave={handleNodeHoverStop}
    //         >

    //           {mapSettingState.showGridline && (
    //             <Background variant="dots" gap={12} size={1} />
    //           )}

    //           <Panel position="top-left" style={{ backgroundColor: 'white', color: 'black', padding: '10px', marginTop: '0px', marginLeft: '0px' }}>
    //             <Typography gutterBottom className={classess.TypoGridBox1}>
    //               <img width="35px" height="35px" src={sitewiseIcon} alt="sitewiseIcon" />
    //               Sitewise Map
    //             </Typography>
    //           </Panel>

    //           <Panel position="top-right" style={{ padding: '10px', marginTop: '0px', marginRight: '0px' }}>
    //             <Grid container spacing={0} className={classess.panelGridBox}>
    //               <Grid item xs="auto" className={classess.panelGridBoxItem}>
    //                 <LocationSelectGraph selectLocation={selectLocation} locationData={locationData} />
    //               </Grid>

    //               <Grid item xs="auto" className={classess.panelGridBoxItem}>
    //                 <Tooltip title="Reset layout">
    //                   <IconButton onClick={handleResetLayout}>
    //                     {resetLayout ? (
    //                       <CircularProgress size={22} sx={{ mr: '3px' }} />
    //                     ) : (
    //                       <RestartAlt sx={{ fontSize: '1.8rem' }} />
    //                     )}
    //                   </IconButton>
    //                 </Tooltip>
    //               </Grid>

    //               <Grid item xs="auto" className={classess.panelGridBoxItem}>
    //                 <Tooltip title="Settings">
    //                   <IconButton ref={anchorRef} onClick={handleButtonClick}>
    //                     <Settings sx={{ fontSize: '1.7rem' }} />
    //                   </IconButton>
    //                 </Tooltip>
    //               </Grid>
    //             </Grid>

    //             {locationData?.length !== 0 && <Grid container spacing={0} className={classess.panelGridBox}>
    //               <Grid item className={classess.panelGridBoxItem} >
    //                 <NodeSelector onSelectNode={onSelectNode} locationData={locationData} />
    //               </Grid>
    //             </Grid>}

    //             <Grid container spacing={0} direction="column" className={classess.panelGridBox}>
    //               <Grid item xs="auto" className={classess.panelGridBoxItem}>
    //                 <Grid item xs="auto" className={classess.panelGridBoxItem}>
    //                   {mapSettingState.showLegend && <LegendControl />}
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //           </Panel>

    //           {locationData?.length === 0 && <Box className={classess.bannerContainer}>
    //             <Typography gutterBottom variant="body2" color="inherit"
    //               sx={{
    //                 fontSize: {
    //                   xs: '18px',
    //                   sm: '26px',
    //                   md: '30px',
    //                   lg: '34px',
    //                   xl: '40px',
    //                   xxl: '42px',
    //                 },
    //               }}>
    //               Please Select location to view site
    //             </Typography>
    //           </Box>}

    //           {locationData?.length !== 0 && nodes?.length === 0 && !loading && <Box className={classess.bannerContainer}>
    //             <Typography gutterBottom variant="body2" color="inherit" fontSize={40}>
    //               Topology data not available for the selected site.
    //             </Typography>
    //           </Box>}

    //           <svg>
    //             <defs>
    //               <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
    //                 <stop offset="0%" stopColor="rgba(253,29,29,1)" />
    //                 <stop offset="100%" stopColor="rgba(131,58,180,1)" />
    //               </linearGradient>
    //             </defs>
    //           </svg>

    //           <Controls
    //             className={classess.controls}
    //             showInteractive
    //             position='bottom-left'
    //           // style={{ marginLeft: '-1005px', marginBottom: '-300px' }}
    //           >
    //             {isFullscreen ? (
    //               <ControlButton onClick={onToggleFullscreen} title="Exit Fullscreen">
    //                 <img src={ExitFullscreen} width={15} height={15} alt="Exit Fullscreen" />
    //               </ControlButton>
    //             ) : (
    //               <ControlButton onClick={onToggleFullscreen} title="Enter Fullscren">
    //                 <img src={EnterFullscreen} width={15} height={15} alt="Enter Fullscreen" />
    //               </ControlButton>
    //             )}
    //           </Controls>
    //           {/* <Controls className={classess.controls} /> */}
    //         </ReactFlow>
    //       </ReactFlowProvider>
    //     </div>

    //     <Popper
    //       open={open}
    //       anchorEl={anchorRef.current}
    //       placement='bottom-end'
    //       disablePortal={true}
    //     >
    //       <ClickAwayListener onClickAway={handleClose}>
    //         <Paper className={classess.overlayCard} variant="outlined">
    //           {availableDeviceTypes.length === 0 ? (
    //             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
    //               <Typography className={classess.cardText}>
    //                 Devices not available
    //               </Typography>
    //             </Box>
    //           ) : (
    //             <List>
    //               {availableDeviceTypes.map((item, index) => (
    //                 <ListItem key={index} sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', gap: 3 }}>
    //                   <ListItemText primary={toCamelizeWords(item)} />
    //                   <ListItemIcon
    //                     onClick={() => handleVisibility(item)}
    //                     sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    //                   >
    //                     {hiddenItems.includes(item) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
    //                   </ListItemIcon>
    //                 </ListItem>
    //               ))}
    //               <Divider />

    //               <ListItem>
    //                 <ListItemText primary="Show device name" />
    //                 <ListItemIcon
    //                   sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    //                   onClick={handleShowHideLabel}
    //                 >
    //                   {!mapSettingState.showLabel ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
    //                 </ListItemIcon>
    //               </ListItem>

    //               <ListItem>
    //                 <ListItemText primary="Show Legend" />
    //                 <ListItemIcon
    //                   onClick={handleShowLegend}
    //                   sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    //                 >
    //                   {!mapSettingState.showLegend ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
    //                 </ListItemIcon>
    //               </ListItem>

    //               <ListItem>
    //                 <ListItemText primary="Show device counts" />
    //                 <ListItemIcon
    //                   onClick={handleShowCounter}
    //                   sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    //                 >
    //                   {!mapSettingState.showCounterIcon ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
    //                 </ListItemIcon>
    //               </ListItem>
    //             </List>
    //           )}
    //         </Paper>

    //       </ClickAwayListener>
    //     </Popper>
    //     <Typography variant="body2" className={classess.TypoGridBox1}>
    //       <Link component="button" onClick={handleClickOpenDialog} underline="hover">
    //         Click here,
    //       </Link>
    //       to see failed devices.
    //     </Typography>

    //     <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth disablePortal={isFullscreen1}
    //      PaperProps={{
    //       sx: {
    //         ...(isFullscreen1 && {
    //           zIndex: (theme) => theme.zIndex.modal + 1, 
    //         }),
    //       },
    //     }} >
    //       <DialogTitle>
    //         Device Information
    //         <IconButton
    //           aria-label="close"
    //           onClick={handleCloseDialog}
    //           sx={{
    //             position: 'absolute',
    //             right: 8,
    //             top: 8,
    //             color: (theme) => theme.palette.grey[500],
    //           }}
    //         >
    //           <CloseIcon />
    //         </IconButton>
    //       </DialogTitle>
    //       <DialogContent>
    //         <Typography variant="body2" sx={{ fontSize: '0.9rem', color: 'text.secondary' }}>
    //           <SSHdeviceTable
    //             locationData={locationData}
    //             deviceTypes={deviceTypes}
    //             setDeviceTypes={setDeviceTypes}
    //             // vendorsType={vendorsType}
    //             // setVendorsType={setVendorsType}
    //             sx={{
    //               '@media (max-width: 768px)': { 
    //                 padding: '0 16px',
    //               },
    //               '@media (min-width: 769px) and (max-width: 1200px)': { 
    //                 padding: '0 32px',
    //               }
    //             }}
    //           />
    //         </Typography>
    //       </DialogContent>

    //     </Dialog>
    //   </Box>

    // </div>

    <div id="react-flow-sitewise-map" ref={fullScreenRef} style={{ width: '100%', height: '95%' }}>
      <Box
        className={isFullscreen ? 'fullScreenCss' : 'notfullScreenCss'}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: isFullscreen ? 'white' : 'initial',
        }}
      >
        <div
          className="graphContainer"
          ref={reactFlowWrapper}
          style={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            padding: '20px',
            width: '100%',
          }}
        >
          {loading && <LinearProgress />}
          <ReactFlowProvider>
            <ReactFlow
              fitView
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onNodesChange={onNodesChange}
              onInit={(instance) => setReactFlowInstance(instance)}
              connectionLineComponent={FloatingConnectionLine}
              onEdgeMouseEnter={handleEdgeMouseEnter}
              onEdgeMouseLeave={handleEdgeMouseLeave}
              onNodeMouseEnter={handleNodeHover}
              onNodeMouseLeave={handleNodeHoverStop}
            >
              {mapSettingState.showGridline && (
                <Background variant="dots" gap={12} size={1} />
              )}

              <Panel
                position="top-left"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  padding: '10px',
                  marginTop: '0px',
                  marginLeft: '0px',
                  fontSize: '1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img width="35px" height="35px" src={sitewiseIcon} alt="sitewiseIcon" />
                <Typography gutterBottom style={{ marginLeft: '10px', fontSize: '1.4rem' }}>
                  Sitewise Map
                </Typography>
              </Panel>


              <Panel position="top-right" style={{ padding: '10px', marginTop: '0px', marginRight: '0px' }}>
                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid item xs="auto">
                    <LocationSelectGraph selectLocation={selectLocation} locationData={locationData} />
                  </Grid>

                  <Grid item xs="auto">
                    <Tooltip title="Reset layout">
                      <IconButton onClick={handleResetLayout}>
                        {resetLayout ? (
                          <CircularProgress size={22} sx={{ mr: '3px' }} />
                        ) : (
                          <RestartAlt sx={{ fontSize: '1.8rem' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs="auto">
                    <Tooltip title="Settings">
                      <IconButton ref={anchorRef} onClick={handleButtonClick}>
                        <Settings sx={{ fontSize: '1.7rem' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {locationData?.length !== 0 && (
                  <Grid container spacing={0} style={{ marginTop: '10px' }}>
                    <Grid item>
                      <NodeSelector onSelectNode={onSelectNode} locationData={locationData} />
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={0} direction="column" style={{ marginTop: '10px', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <Grid item xs="auto">
                    {mapSettingState.showLegend && <LegendControl />}
                  </Grid>
                </Grid>
              </Panel>

              {locationData?.length === 0 && (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '95%',
                  }}
                >
                  <Typography variant="body2" style={{ fontSize: '38px', textAlign: 'center' }}>
                    Please Select location to view site
                  </Typography>
                </Box>
              )}

{locationData?.length !== 0 && nodes?.length === 0 && !loading && (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', 
      width: '100%',  
    }}
  >
    <Typography
      variant="body2"
      style={{
        fontSize: '40px',
        textAlign: 'center',
        padding: '20px', 
      }}
    >
      Topology data not available for the selected site.
    </Typography>
  </Box>
)}

              <svg>
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(253,29,29,1)" />
                    <stop offset="100%" stopColor="rgba(131,58,180,1)" />
                  </linearGradient>
                </defs>
              </svg>

              <Controls
                style={{
                  position: 'absolute',
                  bottom: 10,

                }}
              >
                {isFullscreen ? (
                  <ControlButton onClick={onToggleFullscreen} title="Exit Fullscreen">
                    <img src={ExitFullscreen} width={15} height={15} alt="Exit Fullscreen" />
                  </ControlButton>
                ) : (
                  <ControlButton onClick={onToggleFullscreen} title="Enter Fullscreen">
                    <img src={EnterFullscreen} width={15} height={15} alt="Enter Fullscreen" />
                  </ControlButton>
                )}
              </Controls>
            </ReactFlow>
          </ReactFlowProvider>
        </div>

        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" disablePortal={true}>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              style={{
                padding: '16px',
                width: '300px',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
              }}
            >
              {availableDeviceTypes.length === 0 ? (
                <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', 
                  width: '100%',  
                }}
              >
                <Typography style={{ textAlign: 'center' }}>
                  Devices not available
                </Typography>
              </Box>
              ) : (
                <List>
                  {availableDeviceTypes.map((item, index) => (
                    <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <ListItemText primary={toCamelizeWords(item)} />
                      <ListItemIcon
                        onClick={() => handleVisibility(item)}
                        style={{ cursor: 'pointer' }}
                      >
                        {hiddenItems.includes(item) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </ListItemIcon>
                    </ListItem>
                  ))}
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Show device name" />
                    <ListItemIcon
                      style={{ cursor: 'pointer' }}
                      onClick={handleShowHideLabel}
                    >
                      {!mapSettingState.showLabel ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemText primary="Show Legend" />
                    <ListItemIcon
                      onClick={handleShowLegend}
                      style={{ cursor: 'pointer' }}
                    >
                      {!mapSettingState.showLegend ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemText primary="Show device counts" />
                    <ListItemIcon
                      onClick={handleShowCounter}
                      style={{ cursor: 'pointer' }}
                    >
                      {!mapSettingState.showCounterIcon ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>
                </List>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Typography
  variant="body2"
  style={{
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center', 
    whiteSpace: 'nowrap', 
  }}
>
  <Link component="button" onClick={handleClickOpenDialog} underline="hover">
    Click here
  </Link>
  , to see failed devices.
</Typography>


        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="lg"
          fullWidth
          disablePortal={isFullscreen1}
          PaperProps={{
            sx: {
              ...(isFullscreen1 && {
                zIndex: (theme) => theme.zIndex.modal + 1,
              }),
            },
          }}
        >
          <DialogTitle>
            Device Information
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" style={{ fontSize: '0.9rem', color: 'text.secondary' }}>
              <SSHdeviceTable
                locationData={locationData}
                deviceTypes={deviceTypes}
                setDeviceTypes={setDeviceTypes}
                sx={{
                  '@media (max-width: 768px)': {
                    padding: '0 16px',
                  },
                  '@media (min-width: 1440px)': {
                    fontSize: '1.1rem',
                  },
                }}
              />
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </div>

  );
}