// @mui
import {Grid} from '@mui/material';
import CredentialTable from './Components/CredentialTable';
import CredentialCards from './Components/CredentialCards';

export default function CredentialComponents() {
  return (
    <>
        {/* <Grid container spacing={3}>
            <CredentialCards />
        </Grid> */}

    <Grid container>
      <Grid item lg={12} md={12} sm={12}>
        <CredentialTable />
      </Grid>
    </Grid>
    </>
  );
}
