// @mui
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
    Dialog, Slide,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider, Box,
    Typography,
    Stack,
} from '@mui/material';
import { forwardRef } from 'react';


//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => { return <Slide direction="up" ref={ref} {...props} />; });


const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-start"
    },
    topPaperScrollBody: {
        verticalAlign: "top"
    },
    fileNameBox: {
        width: '100%',
        padding: '10px',
        marginBottom: '25px',
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#f5f5f5',
        fontFamily: 'Sans-serif',
    },
    stackContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
});


//----------------------------------------------------------------------

export default function StatusDialog(props) {
    const classes = useStyles();
    const { open, handleClose, data } = props;

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="diff-viewer"
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody
                }}
            >
                <DialogTitle>
                    <Stack direction="row" className={classes.stackContainer}>
                        <Box className={classes.root}>
                            Activity Details
                            <Typography variant="subtitle2" gutterBottom>
                                {data.r_device_id}
                            </Typography>
                        </Box>

                        <IconButton onClick={handleClose}>
                            <Close sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <Divider />

                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Box className={classes.fileNameBox} >
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: '13%', textAlign: 'left' }}>Message</td>
                                    <td style={{ fontWeight: 700 }}>: {data.r_criticality_message}  </td>
                                </tr>

                                {data.r_criticality === "ON_HOLD" && <tr>
                                    <td style={{ width: '13%', textAlign: 'left' }}>Hold untill</td>
                                    <td style={{ fontWeight: 700 }}>: {data.r_criticality_untill}  </td>
                                </tr>}
                            </tbody>
                        </table>
                    </Box>

                </DialogContent>
                <DialogActions />
            </Dialog>
        </>
    );
}
