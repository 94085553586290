
import { Container, Grid } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LocationSelect from '../../../../NetworkHealthComponents/LocationSelect';
import BackupSuccessTable from './BackupSuccessTable';
// ----------------------------------------------------------------------

export default function BackupSuccess() {
  const [locationData, setLocationData] = useState([]);
  const location = useLocation();
  const date = location.state;

  const selectLocation = async (_locations) => {
    setLocationData(_locations);
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12}>
          <hr style={{ marginBottom: '30px' }} />
          <LocationSelect selectLocation={selectLocation} selectAllLabel='Select all' />
        </Grid>

        <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
        {(locationData && date) && <BackupSuccessTable locationData={locationData} date={date} />}
        </Grid>
      </Grid>
    </Container>
  );
}



