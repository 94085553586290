import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../../context/UserContext';
import { DevicesUpIcon, LinksDownIcon, LinksUpIcon } from '../../../icons';

import { getNetworkStat } from '../../../API/NetworkDevices/networkDevices';
// ----------------------------------------------------------------------

const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
});

// ----------------------------------------------------------------------

export default function AlertsCards() {
  const classes = useStyles();
  const [networkData, setNetworkData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const { customerId } = useContext(UserContext);

  const getNetworkData = async () => {
    setCardLoading(true);
    const res = await getNetworkStat(customerId);
    setNetworkData(res);
    setNetworkData(Object.keys(res).map((t) => ({ label: t, value: res[t] })));

    setCardLoading(false);
  };

  useEffect(() => {
    getNetworkData();
  }, []);

  let totalDeviceUp = 0;
  const totalOfDeviceUp = () => {
    networkData
      .map((e) => e.value.map((e) => e.stats))
      .forEach((item) => {
        totalDeviceUp = item.map((e) => e.network_device_up).reduce((a, b) => a + b);
      });
  };
  totalOfDeviceUp();

  let totalEndpoints = 0;
  const totalOfEndpoints = () => {
    networkData
      .map((e) => e.value.map((e) => e.stats))
      .forEach((item) => {
        totalEndpoints = item.map((e) => e.total_endpoints).reduce((a, b) => a + b);
      });
  };
  totalOfEndpoints();

  let totalDeviceDown = 0;
  const totalOfDeviceDown = () => {
    networkData
      .map((e) => e.value.map((e) => e.stats))
      .forEach((item) => {
        totalDeviceDown = item.map((e) => e.network_device_down).reduce((a, b) => a + b);
      });
  };
  totalOfDeviceDown();

  let totalDevices = 0;
  const totalOfDevices = () => {
    networkData
      .map((e) => e.value.map((e) => e.stats))
      .forEach((item) => {
        totalDevices = item.map((e) => e.total_network_devices).reduce((a, b) => a + b);
      });
  };
  totalOfDevices();

  return (
    <>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'blue', background: '#c3cfe6', borderRadius: '12px' }}>
                <LinksUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Total Devices
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h2" component="div">
                {totalDevices}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px' }}>
                <LinksDownIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Total Endpoints
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h2" component="div">
                {totalEndpoints}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px' }}>
                <DevicesUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Device Up
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h2" component="div">
                {totalDeviceUp}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px' }}>
                <DevicesUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Device Down
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h2" component="div">
                {totalDeviceDown}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}
