


/* eslint-disable */
// import {
//     Box,
//     LinearProgress, Paper
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { AgGridReact } from 'ag-grid-react';
// import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// // import { networkTable } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';

// // -------------------------------------

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     height: 480,
// }));

// // ---------------------------------------

// const HaCluster = ({ haClusterExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [resData, setResData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [columnHeaders, setColumnHeaders] = useState([]);

//     // -------------------------------------------------------------------------------------

//     const isTablet = useMediaQuery('(max-width: 1020px)');
//     const isDesktop = useMediaQuery('(min-width: 1440px)');
//     const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

//     const getFontSize = () => {
//         if (isLargeDesktop) {
//             return '20px';
//         }
//         if (isDesktop) {
//             return '16px';
//         }
//         if (isTablet) {
//             return '14px';
//         }
//         return '12px';
//     };

//     const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

//     // ----------------------------------------------------------------------------------------------------


//     // const transformData = (data) => {
//     //     console.log(data,"data");
//     //     if (!data || data.length === 0) {
//     //         return [];
//     //     }

//     //     const transformedData = [];
//     //     const keys = Object.keys(data[0]);


//     //     const groupedData = {};

//     //     keys.forEach((key) => {

//     //         const pattern = key.match(/[^a-zA-Z0-9](.*?)$/); 
//     //         if (pattern) {
//     //             const dynamicPattern = pattern[1];
//     //             if (!groupedData[dynamicPattern]) {
//     //                 groupedData[dynamicPattern] = {};
//     //             }
//     //             const baseKey = key.split('.')[0];
//     //             groupedData[dynamicPattern][baseKey] = data[0][key][0];
//     //         } else {

//     //             console.warn(`Key without identifiable dynamic pattern: ${key}`);
//     //         }
//     //     });


//     //     Object.keys(groupedData).forEach((pattern) => {
//     //         transformedData.push(groupedData[pattern]);
//     //     });

//     //     return transformedData;
//     // };

//     const renameKey = (obj, oldKey, newKey) => {
//         if (oldKey !== newKey) {
//             Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey));
//             delete obj[oldKey];
//         }
//     };

//     // Flexible pattern mapping with regex
//     const patternMap = [
//         { pattern: /fgHaGroupId/i, newName: 'HA-Group-ID' },
//         { pattern: /fgHaAutoSync/i, newName: 'Sync Status' },
//         { pattern: /fgHaPriority/i, newName: 'Priority' },
//         { pattern: /fgSysVersion/i, newName: 'System Version' },
//         { pattern: /fgHaSystemMode/i, newName: 'Mode' }
//     ];


    
//     const getNewKeyName = (key) => {
//         for (const entry of patternMap) {
//             if (entry.pattern.test(key)) {
//                 return entry.newName;
//             }
//         }
//         return null;
//     };

    
//     const transformData = (data) => {
//         if (!data || data.length === 0) {
//             return [[], []]; 
//         }

//         const transformedData = [];
//         const keys = Object.keys(data[0]);

//         const groupedData = {};


//         keys.forEach((key) => {
//             const pattern = key.match(/[^a-zA-Z0-9](.*?)$/);
//             if (pattern) {
//                 const dynamicPattern = pattern[1];
//                 if (!groupedData[dynamicPattern]) {
//                     groupedData[dynamicPattern] = {};
//                 }
//                 const baseKey = key.split('.')[0];
//                 groupedData[dynamicPattern][baseKey] = data[0][key][0];
//             } else {
//                 console.warn(`Key without identifiable dynamic pattern: ${key}`);
//             }
//         });


//         const validColumns = [];
//         Object.keys(groupedData).forEach((pattern) => {
//             const dataObject = groupedData[pattern];

//             for (const key in dataObject) {
//                 const newKey = getNewKeyName(key);
//                 if (newKey) {
//                     renameKey(dataObject, key, newKey);
//                     if (!validColumns.includes(newKey)) {
//                         validColumns.push(newKey);
//                     }
//                 }
//             }

//             transformedData.push(dataObject);
//         });

//         return [transformedData, validColumns];
//     };
//     const fetchData = useCallback(() => {
//         // if (!healthDashState.selectedDevice || networksExpanded === false) {
//         //     return '';
//         // }
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
//             // device_os: healthDashState.selectedDevice.r_dvc_os,
//         };
//         setLoading(true);
//         // networkTable(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//         // .then((res) => {

//         setLoading(false);
//         const networkTableData = allData?.hacluster_table || [];
//         const [transformedData, validColumns] = transformData(networkTableData);
//         setResData(transformedData);
//         if (transformedData.length > 0) {
//             const headers = validColumns.map((key) => ({
//                 headerName: key,
//                 field: key,
//                 width: 180,
//                 sortable: true,
//                 filter: true,
//                 cellStyle: responsiveCellStyle,
//                 valueFormatter: (params) => {
//                     const value = params.value;
//                     return value === null || value === '' ? 'NA' : value;
//                 },
//             }));
//             setColumnHeaders(headers);
//         }
//         // })
//         // .catch(() => {
//         //     setLoading(false);
//         // });
//     }, [healthDashState.selectedDevice, haClusterExpanded, customerId, getStartEnd]);

//     useEffect(() => {
//         fetchData();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, haClusterExpanded, fetchData]);

//     const defaultColDef = useMemo(() => ({
//         resizable: true,
//         filter: true,
//         sortable: true,
//         suppressMenu: true,
//         floatingFilter: false,
//     }), []);

//     // -----------------------------------------------------------------------------------------------

//     return (
//         <>
//             {loading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper variant="outlined">
//                 <ChartWrapperStyle dir="ltr">
//                     <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
//                         <AgGridReact
//                             rowData={resData}
//                             columnDefs={columnHeaders}
//                             // onGridReady={fetchData}
//                             defaultColDef={defaultColDef}
//                             rowHeight={50}  // Set row height
//                         />
//                     </Box>
//                 </ChartWrapperStyle>
//             </Paper>
//         </>
//     );
// };

// export default HaCluster;


import {
    Box,
    LinearProgress,
    Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// Styled wrapper for chart layout
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 150,
}));

const HaCluster = ({ haClusterExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([]);

    // Media queries for responsive font size
    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) return '20px';
        if (isDesktop) return '16px';
        if (isTablet) return '14px';
        return '12px';
    };

    const responsiveCellStyle = {
        fontSize: getFontSize(),
        fontFamily: 'Arial',
        fontWeight: '400',
        letterSpacing: 1
    };

    // Flexible pattern mapping with regex for column renaming
    const patternMap = [
        { pattern: /fgHaGroupId/i, newName: 'HA-Group-ID' },
        { pattern: /fgHaAutoSync/i, newName: 'Sync Status' },
        { pattern: /fgHaPriority/i, newName: 'Priority' },
        { pattern: /fgSysVersion/i, newName: 'System Version' },
        { pattern: /fgHaSystemMode/i, newName: 'Mode' }
    ];

    const getNewKeyName = (key) => {
        for (const entry of patternMap) {
            if (entry.pattern.test(key)) return entry.newName;
        }
        return key;
    };

    // Data transformation function
    // const transformData = (data) => {
    //     if (!data || data.length === 0) return [[], []];

    //     const transformedData = [];
    //     const groupedData = {};

    //     Object.keys(data[0]).forEach((key) => {
    //         const dynamicPattern = key.split('.')[1];
    //         const baseKey = key.split('.')[0];

    //         if (!groupedData[dynamicPattern]) {
    //             groupedData[dynamicPattern] = {};
    //         }
    //         groupedData[dynamicPattern][baseKey] = data[0][key][0];
    //     });

    //     const validColumns = new Set();

    //     Object.values(groupedData).forEach((dataObject) => {
    //         const renamedDataObject = {};

    //         Object.keys(dataObject).forEach((key) => {
    //             const newKey = getNewKeyName(key);
    //             renamedDataObject[newKey] = dataObject[key];
    //             validColumns.add(newKey);
    //         });

    //         transformedData.push(renamedDataObject);
    //     });

    //     return [transformedData, [...validColumns]];
    // };
    const transformData = (data) => {
        if (!data || data.length === 0) return [[], []];
    
        const transformedData = [];
        const groupedData = {};
    
        // Group data by the part after the dot in the keys (e.g., 'dynamicPattern')
        Object.keys(data[0]).forEach((key) => {
            const dynamicPattern = key.split('.')[1];
            const baseKey = key.split('.')[0];
    
            if (!groupedData[dynamicPattern]) {
                groupedData[dynamicPattern] = {};
            }
            groupedData[dynamicPattern][baseKey] = data[0][key][0];
        });
    
        const validColumns = new Set();
    
        Object.values(groupedData).forEach((dataObject) => {
            const matchedDataObject = {};
    
            Object.keys(dataObject).forEach((key) => {
                const newKey = getNewKeyName(key);
    
                // Check if the key matches any pattern
                if (patternMap.some(entry => entry.newName === newKey)) {
                    matchedDataObject[newKey] = dataObject[key];
                    validColumns.add(newKey);  // Only add columns that match
                }
            });
    
            // Add only matched data objects to transformed data
            if (Object.keys(matchedDataObject).length > 0) {
                transformedData.push(matchedDataObject);
            }
        });
    
        return [transformedData, [...validColumns]];
    };
    

    const fetchData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
        };

        setLoading(true);

        // Mock API data retrieval
        const networkTableData = allData?.hacluster_table || [];
        const [transformedData, validColumns] = transformData(networkTableData);

        setResData(transformedData);
        setLoading(false);

        if (transformedData.length > 0) {
            const headers = validColumns.map((key) => ({
                headerName: key,
                field: key,
                width: 180,
                sortable: true,
                filter: true,
                cellStyle: responsiveCellStyle,
                valueFormatter: (params) => params.value ?? 'NA',
            }));
            setColumnHeaders(headers);
        }
    }, [allData, healthDashState.selectedDevice, getStartEnd]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '140px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={resData}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            rowHeight={50}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default HaCluster;
