import { CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useRef } from 'react';
import { addZone } from '../../../API/Zones/zones';
import { UserContext } from '../../../context/UserContext';

export default function DcZoneSelector(props) {
  const { zoneOptions, zoneValue, setZoneValue, zoneLoading, zoneData } = props;
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);
  const initialized = useRef(false);
  const filter = createFilterOptions();

  useEffect(() => {
    const mappedOptions = zoneOptions.map((item) => ({
      id: item.zone_id,
      name: item.r_zone_name,
    }));
    setOptions(mappedOptions);
    if (zoneData && zoneData.r_zone_name ) {
      setZoneValue({ name: zoneData.r_zone_name, id: zoneData.zone_id });
      initialized.current = true;
    } else if (!zoneData ) {
      setZoneValue(null);
      initialized.current = true;
    }
  }, [zoneOptions, zoneData, setZoneValue]);

  const _addZone = (value) => {
    const data = { name: value };
    return addZone(customerId, data)
      .then((res) => {
        enqueueSnackbar('Zone added successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        return Promise.resolve(res);
      })
      .catch((res) => {
        enqueueSnackbar(res.response.data.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return (
    <Autocomplete
      value={zoneValue || null} 
      fullWidth
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setZoneValue({ name: newValue });
        } else if (newValue && newValue.inputValue) {
          
          _addZone(newValue.inputValue).then((res) => {
            setZoneValue({
              name: res.r_zone_name,
              id: res.zone_id,
            });
          });
        } else {
          setZoneValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Create "${inputValue}"`,
            name: inputValue,
            value: inputValue,
            create: true,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => option.name || ''} 
      renderOption={(props, option) => <li {...props}>{option.create ? option.label : option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select zones"
          label="Zone (optional)"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

DcZoneSelector.propTypes = {
  zoneOptions: PropTypes.array.isRequired,
  zoneValue: PropTypes.object,
  setZoneValue: PropTypes.func.isRequired,
  zoneLoading: PropTypes.bool.isRequired,
  zoneData: PropTypes.object, 
};
