/* eslint-disable */
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

var userAgent = navigator.userAgent;

// Define a function to detect the browser and add a class to the body element
function detectBrowser() {
    var body = document.querySelector('body');
    
    if (userAgent.indexOf("Chrome") > -1) {
        body.classList.add("chrome");
    } else if (userAgent.indexOf("Safari") > -1) {
        body.classList.add("safari");
    } else if (userAgent.indexOf("Firefox") > -1) {
        body.classList.add("firefox");
    } else if (userAgent.indexOf("Edge") > -1) {
        body.classList.add("edge");
    } else if (userAgent.indexOf("IE") > -1 || userAgent.indexOf("Trident/") > -1) {
        body.classList.add("ie");
    } else {
        body.classList.add("other-browser");
    }
}

// Call the detectBrowser function
detectBrowser();