import { Box, Chip } from "@mui/material";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { deAssignSubnets } from '../../../../API/Subnets/Subnets';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';

 // ----------------------------------------------------------------

 const useStyles = makeStyles({
  subnetBox2: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px'
  }
});

 // -----------------------------------------------------------------

const SubnetRenderer = (props) => {
    const classes = useStyles();
    const { data, confirm, api } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);


    const handleSubnetDelete = (subnet, location) => {
        const payload = {
          subnets: [
            subnet.subnet_id,
          ]
        };
        deAssignSubnets(customerId, location.location_id, payload)
          .then((res) => {
            props.api.purgeInfiniteCache();       
            enqueueSnackbar(
              'Subnet deleted successfully',
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          })
          .catch((res) => {
            enqueueSnackbar(
              res.response.data.message,
              {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          });
      };

    if (data === undefined) {
        return '';
    }
    
    const subnets = Array.isArray(data.subnets) ? data.subnets : [data]
    return (
        <>
            <Box className={classes.subnetBox2}>
            {subnets.map((_subnet) => {
    if (_subnet.r_subnet_value) {
        return (
            <Chip
                size="small"
                label={_subnet.r_subnet_value}
                key={_subnet.subnet_id}
                variant="outlined"
                onDelete={confirm((e) => handleSubnetDelete(_subnet, data), {
                    title: 'Information',
                    confirmationText: 'Yes',
                    disableOkBtn: false,
                    description: 'Are you sure you want to delete this subnet',
                })}
            />
        );
    }
    return null; 
})}

            </Box>
        </>
    );
};

SubnetRenderer.propTypes = {
    data: PropTypes.object,
};

export default WithConfirm(SubnetRenderer);


// import { Box, Chip } from "@mui/material";
// import { useSnackbar } from 'notistack';
// import PropTypes from "prop-types";
// import { useContext, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
// import { deAssignSubnets } from '../../../../API/Subnets/Subnets';
// import { UserContext } from '../../../../context/UserContext';
// import { WithConfirm } from '../../../WithConfirm';

// // ----------------------------------------------------------------

// const useStyles = makeStyles({
//   subnetBox2: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '5px',
//   },
// });

// // -----------------------------------------------------------------

// const SubnetRenderer = (props) => {
//   const classes = useStyles();
//   const { data, confirm, api ,params} = props;
//   const { enqueueSnackbar } = useSnackbar();
//   const { customerId } = useContext(UserContext);

//   const handleSubnetDelete = (subnet, location) => {
//     console.log(location,subnet);
    
//     const payload = {
//       subnet_uuids: [subnet.subnet_id],
//     };
//     deAssignSubnets(customerId, location.location_id, payload)
//       .then((res) => {
//         props.api.purgeInfiniteCache();
//         enqueueSnackbar('Subnet deleted successfully', {
//           variant: 'success',
//           anchorOrigin: {
//             vertical: 'bottom',
//             horizontal: 'right',
//           },
//         });
//       })
//       .catch((res) => {
//         enqueueSnackbar(res.response.data.message, {
//           variant: 'error',
//           anchorOrigin: {
//             vertical: 'bottom',
//             horizontal: 'right',
//           },
//         });
//       });
//   };

//   useEffect(() => {
//     if (!data) {
//       console.warn("Data is undefined!");
//     }
//   }, [data]);
  

//   const subnets = Array.isArray(data) ? data : [data];
// console.log(data);

//   return (
//     <Box className={classes.subnetBox2}>
//       {subnets.map((_subnet) => (
//         <Chip
//           size="small"
//           label={_subnet.r_subnet_value}
//           key={_subnet._uid}
//           variant="outlined"
//           onDelete={confirm(() => handleSubnetDelete(_subnet, subnets), {
//             title: 'Information',
//             confirmationText: 'Yes',
//             disableOkBtn: false,
//             description: 'Are you sure you want to delete this subnet?',
//           })}
//         />
//       ))}
//     </Box>
//   );
// };

// SubnetRenderer.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object), // Expecting an array of objects
// };

// export default WithConfirm(SubnetRenderer);
