import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getLocationStatistics = async (id, data) => {
  const newPromise =  testbedModem.post(`/${id}/location-statistics`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
