/* eslint-disable */
import { Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { CertificateValidity } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { fDateTimeFormat } from '../../../../../../utils/formatTime';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 400,
}));

// ---------------------------------------


const CerificateValidityTable = ({ expandedState , allData}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [statusData, setStatusData] = useState([]);
    const [textColor, setTextColor] = useState('');

// -------------------------------------------------------------------------------------

const isTablet = useMediaQuery('(max-width: 1020px)');
const isDesktop = useMediaQuery('(min-width: 1440px)');
const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

const getFontSize = () => {
 if (isLargeDesktop) {
   return '20px';
 } 
 if (isDesktop) {
   return '16px';
 } 
 if (isTablet) {
   return '14px';
 } 
 return '12px';
 }

const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

// ----------------------------------------------------------------------------------------------------

    const columnHeaders = [
        {
            headerName: 'Certificate',
            field: 'name',
            width: 450,
            sortable: false,
            filter: false,
            cellStyle: responsiveCellStyle
        },
        {
            headerName: 'Valid till',
            field: 'valid_till',
            width: 450,
            sortable: true,
            filter: false,
            cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1, color: `${textColor}` },
            valueFormatter: (params) => {
                const formattedDate = fDateTimeFormat(params.value);
                return formattedDate;
            },
        },
    ];

    const fetchData = useCallback(() => {
        if (!healthDashState.selectedDevice || expandedState === false) {
            return '';
        }
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os,
        };
        setLoading(true);
        // CertificateValidity(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
        //     .then((res) => {
                setLoading(false);
                setStatusData(allData?.certificate_table);
            // })
            // .catch(() => {
            //     setLoading(false);
            // });
    }, [healthDashState.selectedDevice, expandedState, customerId]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    useEffect(() => {
        if (statusData) {
            let newTextColor = '#00c853';
            let hasExpired = false;
            statusData.forEach((item) => {
                const timeDifference = new Date(item.valid_till).getTime() - new Date().getTime();

                if (timeDifference < 0) {
                    hasExpired = true;
                } else if (timeDifference <= 3 * 30 * 24 * 60 * 60 * 1000) {
                    newTextColor = '#ffd600';
                }
            });
            setTextColor(hasExpired ? '#d50000' : newTextColor);
        }
    }, [statusData]);

    // ----------------------------------------------------------

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '400px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={statusData}
                            columnDefs={columnHeaders}
                            onGridReady={fetchData}
                            defaultColDef={defaultColDef}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};
export default CerificateValidityTable;
