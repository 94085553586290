const initialState = {
  dashboard: {
    locationData: [],
  },
  inventory: {
    locationData: [],
  },
  deviceup: {
    locationData: [],
  },
  devicedown: {
    locationData: [],
  },
  inactive: {
    locationData: [],
  },
  overview: {
    locationData: [],
  },
  clientdevice: {
    locationData: [],
  },
  unknowndevice: {
    locationData: [],
  },
  changeevent: {
    locationData: [],
  },
  cmdbinventory: {
    locationData: [],
  },
  alertupdown: {
    locationData: [],
  },
  cmdbscanevent: {
    locationData: [],
  },
  cmdbchangeevent: {
    locationData: [],
  },
  globalmap: {
    locationData: [],
  },
  health: {
    locationData: [],
  },
  sitewisehealth: {
    locationData: [],
  }
};

const locationSelectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_LOCATION_OPTIONS':
      return {
        ...state,
        [action.payload.page]: {
          locationData: action.payload.location_uuid,
        },
      };

    default:
      return state;
  }
};

export default locationSelectReducer;


