import {Grid} from '@mui/material';
import LocationsTable from './Components/LocationsTable';


export default function LocationsComponents() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12}>
          <LocationsTable />
        </Grid>
      </Grid>
    </>
  );
}
