import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const sendSupportMail = async (id, data) => {
  const newPromise =  testbedModem.post(`api/${id}/emails/support`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

