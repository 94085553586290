
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import Page from '../components/Page';
// ----------------------------------------------------------------------

export default function ConfigBackupsTable() {
  return (
    <Page title="CMDB Inventory">
      <Container maxWidth={false}>
        <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{pb:1.5, cursor:'pointer'}}>
              <HomeOutlinedIcon 
              sx={{
                fontSize: {
                  xs: '20px',
                  sm: '22px',
                  md: '24px',
                  lg: '26px',
                  xl: '28px',
                  xxl: '30px',
                },
              }} />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor:'pointer',
          fontSize: {
            xs: '8px',
            sm: '12px',
            md: '14px',
            lg: '16px',
            xl: '18px',
            xxl: '20px',
          },}} to="/dashboard/summary-events">
              CMDB
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor:'pointer',
          fontSize: {
            xs: '8px',
            sm: '12px',
            md: '14px',
            lg: '16px',
            xl: '18px',
            xxl: '20px',
          },
          }} to="/dashboard/config-change-backup-table">
            CMDB Inventory
            </Typography>
          </Breadcrumbs>
        </div>
        
        {/* <ConfigBackupTableComponent /> */}
        <Outlet />
      </Container>
    </Page>
  );
}
