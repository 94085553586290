

import {
    Box,
    LinearProgress,
    Paper,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 480,
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

// ---------------------------------------

const MacTable = ({ macExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // -------------------------------------------------------------------------------------

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };
    const renameKey = (obj, oldKey, newKey) => {
        if (oldKey !== newKey) {
            Object.defineProperty(obj, newKey,
                Object.getOwnPropertyDescriptor(obj, oldKey));
            delete obj[oldKey];
        }
    };
    
    
    const patternMap = [
        { pattern: /port/i, newName: 'Port' },
        { pattern: /status/i, newName: 'Status' },
        { pattern: /address/i, newName: 'MAC Address' }
    ];
    
    
    const getNewKeyName = (key) => {
        for (const entry of patternMap) {
            if (entry.pattern.test(key)) {
                return entry.newName;
            }
        }
        return key; 
    };
    
    
    const transformData = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
    
        const transformedData = [];
        const keys = Object.keys(data[0]);
    
        const groupedData = {};
    
       
        keys.forEach((key) => {
            const pattern = key.match(/[^a-zA-Z0-9](.*?)$/);
            if (pattern) {
                const dynamicPattern = pattern[1];
                if (!groupedData[dynamicPattern]) {
                    groupedData[dynamicPattern] = {};
                }
                const baseKey = key.split('.')[0];
                groupedData[dynamicPattern][baseKey] = data[0][key][0];
            } else {
                console.warn(`Key without identifiable dynamic pattern: ${key}`);
            }
        });
    
       
        Object.keys(groupedData).forEach((pattern) => {
            const dataObject = groupedData[pattern];
            
            for (const key in dataObject) {
                const newKey = getNewKeyName(key);
                renameKey(dataObject, key, newKey);
            }
            
            transformedData.push(dataObject);
        });
    
        return transformedData;
    };
    const fetchData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
        };
        setLoading(true);
        const macTableData = allData?.mac_table || [];
        const transformedData = transformData(macTableData);

        setResData(transformedData);
        if (transformedData.length > 0) {
            const headers = Object.keys(transformedData[0]).map((key) => ({
                headerName: key, 
                field: key,
                width: 180,
                sortable: true,
                filter: true,
                cellStyle: responsiveCellStyle,
            }));
            setColumnHeaders(headers);
        }
        setLoading(false);
    }, [healthDashState.selectedDevice, macExpanded, customerId, getStartEnd]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, macExpanded, fetchData]);

    useEffect(() => {
        setFilteredData(
            searchTerm
                ? resData.filter((row) =>
                    Object.values(row).some((value) =>
                        value && value.toString().toLowerCase().includes(searchTerm)
                    )
                )
                : resData
        );
    }, [searchTerm, resData]); 

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    // -----------------------------------------------------------------------------------------------

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <SearchContainer>
                    <TextField
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                        placeholder="Search..."
                        sx={{ width: '300px' }}
                    />
                </SearchContainer>
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={filteredData}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            rowHeight={50}  
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default MacTable;
