import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getMembers = async (id, page, payload) => {
  const newPromise =  modem
    .post(`${id}/members`, payload)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const pendingMembers = async (id, page, payload) => {
  const newPromise =  modem
    .post(`${id}/members/sso/ssoregusers/0`, payload)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const rejectedMembers = async (id, page, payload) => {
  const newPromise =  modem
    .post(`${id}/members/sso/ssoregusers/-1`, payload)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const approveMembers = async (id, payload) => {
  const newPromise =  modem
    .post(`${id}/members/sso/approvessouser`, payload)
    .then((response) => {      
      return response.data;
    })
    return newPromise
};

export const addMember = async (id,data) => {
  const newPromise =  modem
    .post(`${id}/members/create`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const deleteMember = async (id,memberId, data) => {
  const newPromise =  modem
  .delete(`${id}/members/${memberId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const editMember = async (id,userId, data) => {
  const newPromise =  modem
  .post(`${id}/members/${userId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

