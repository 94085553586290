// @mui
import { Grid, Box } from '@mui/material';
import { useState } from 'react';
import DevicesByTypeChart from './DevicesByTypeChart';
import VendorChart from './VendorChart';
import DashboardMap from './DashboardMap'
import DownDevicesByTypeChart from './DownDevicesByTypeChart';


const DashboardCharts = (props) => {
  const { locationData } = props;
  const [showGraph, setShowGraph] = useState(true);
  const [openedOnce, setOpenedOnce] = useState(true);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [locationId, setLocationId] = useState("");
  // const [locationId, setLocationId] = useState(-1);
  const [forceGroupDetailsRefresh, setForceGroupDetailsRefresh] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [fullScreenChecking, setFullScreenChecking] = useState(false);
  const [selectedGroupNames, setSelectedGroupNames] = useState([]);

  const toggleShowGraph = () => {
    setOpenedOnce(true)
    setShowGraph(!showGraph)
  }

  return (
    <>
      <Grid item lg={6} sm={12} xs={12} md={6}>
        <DashboardMap
          selectedGroupIds={selectedGroupIds}
          setLocationId={setLocationId}
          setSelectedGroupIds={setSelectedGroupIds}
          setForceGroupDetailsRefresh={setForceGroupDetailsRefresh}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          fullScreenChecking={fullScreenChecking}
          setFullScreenChecking={setFullScreenChecking}
          selectedGroupNames={selectedGroupNames}
          setSelectedGroupNames={setSelectedGroupNames}
          locationData={locationData} />
      </Grid>
      <Grid container item spacing={4} marginTop={2}>
        <Grid item lg={6} sm={12} xs={12} md={12}>
          <VendorChart
            showGraph={showGraph}
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph}
            locationData={locationData} />
        </Grid>
        <Grid item lg={6} sm={12} xs={12} md={12}>
          <DevicesByTypeChart
            showGraph={showGraph}
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph}
            locationData={locationData} />
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <DownDevicesByTypeChart
            showGraph={showGraph}
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph}
            locationData={locationData} />
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardCharts;
