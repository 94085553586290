
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 260,
//     textAlign: 'center', // Center align the message
// }));

// const MemoryFreeChart = ({ utilizationExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     const solveCondition = async () => {
//         fetchMemoryFree();
//     };

//     const fetchMemoryFree = async () => {
//         const data = getStartEnd();
        
//         setChartLoading(true);
        
//         const convertedArray = allData?.memory_usages?.map(Number);
//         const formattedXAxisData = allData?.timestamps?.map(timestamp => {
//             const date = new Date(timestamp * 1000);
//             const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//             const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
//             return `${timeString} ${dateString}`;
//         });
        
//         setChartLoading(false);
        
//         setSeriesData([{ "name": 'Memory Free', "data": convertedArray || [] }]);
//         setXAxisData(formattedXAxisData || []);
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, utilizationExpanded, allData]);

//     return (
//         <>
//             {chartLoading && <LinearProgress />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Memory Utilization
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                         {seriesData?.length > 0 && xAxisData?.length > 0 ? (
//                             <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} />
//                         ) : (
//                             <div  > 
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                            No data at the moment
//                         </Typography>
//                         </div>
//                         )}
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default MemoryFreeChart;

// import { Grid, LinearProgress, Paper, Typography, CircularProgress } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 260,
//     textAlign: 'center', // Center align the message
// }));

// const MemoryFreeChart = ({ utilizationExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     const solveCondition = async () => {
//         await fetchMemoryFree();
//     };

//     const fetchMemoryFree = async () => {
//         const data = getStartEnd();
        
//         setChartLoading(true);
        
//         // Simulating the data fetching
//          setTimeout(() => {
//             const convertedArray = allData?.memory_usages?.map(Number);
//             const formattedXAxisData = allData?.timestamps?.map(timestamp => {
//                 const date = new Date(timestamp * 1000);
//                 const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//                 const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
//                 return `${timeString} ${dateString}`;
//             });
            
//             setSeriesData([{ name: 'Memory Free', data: convertedArray || [] }]);
//             setXAxisData(formattedXAxisData || []);
//             setChartLoading(false);
//          }, 1000); // Simulating delay for loading indicator
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, utilizationExpanded, allData]);

//     return (
//         <>
//             {chartLoading && <LinearProgress />} {/* Top-level loading indicator */}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Memory Utilization
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                         {chartLoading ? ( // Circular loading indicator for the chart
//                             <CircularProgress />
//                         ) : (
//                             seriesData?.length > 0 && xAxisData?.length > 0 ? (
//                                 <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} />
//                             ) : (
//                                 <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                                     No data at the moment
//                                 </Typography>
//                             )
//                         )}
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default MemoryFreeChart;

// MemoryFreeChart.js
import { Grid, LinearProgress, Paper, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 260,
    textAlign: 'center', // Center align the message
}));

const MemoryFreeChart = ({ utilizationExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [chartLoading, setChartLoading] = useState(true);

    const solveCondition = async () => {
        await fetchMemoryFree();
    };

    const fetchMemoryFree = async () => {
        const data = getStartEnd();

        setChartLoading(true);

        // Simulating the data fetching
        setTimeout(() => {
            const memoryUsages = allData?.memory_usages?.map(Number) || [];
            const timestamps = allData?.timestamps?.map(ts => ts * 1000) || []; // Convert to milliseconds

            // Debug: Log the fetched data
            // console.log('Memory Usages:', memoryUsages);
            // console.log('Timestamps (ms):', timestamps);

            // Ensure both arrays are of the same length
            const minLength = Math.min(memoryUsages.length, timestamps.length);
            const formattedSeriesData = [
                {
                    name: 'Memory Free',
                    data: memoryUsages.slice(0, minLength).map((usage, index) => ({
                        x: timestamps[index],
                        y: usage,
                    })),
                },
            ];

            // Debug: Log the formatted series data
           

            setSeriesData(formattedSeriesData);
            setChartLoading(false);
        }, 1000); // Simulating delay for loading indicator
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, utilizationExpanded, allData]);

    return (
        <>
            {chartLoading && <LinearProgress />} {/* Top-level loading indicator */}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Memory Utilization
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        {chartLoading ? ( // Circular loading indicator for the chart
                            <CircularProgress />
                        ) : (
                            seriesData?.length > 0 && seriesData[0].data.length > 0 ? (
                                <MainMemoryFreeChart seriesData={seriesData} />
                            ) : (
                                <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                                    No data at the moment
                                </Typography>
                            )
                        )}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default MemoryFreeChart;
