import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import CopytoClipboard from "../../CopytoClipboard";


const useStyles = makeStyles(() => ({
  key_content: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    "word-break": "break-word",
  },
}));

const IpRenderer = (props) => {
  const classes = useStyles();
  const { data } = props;

  if (props.data === undefined) {
    return '';
  }
  return <div className={classes.key_content}>{data.r_dvc_ip_address} <CopytoClipboard text={data.r_dvc_ip_address}/></div>;
};

IpRenderer.propTypes = {
  data: PropTypes.object,
};
export default IpRenderer;
