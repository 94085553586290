import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import StatusDialog from '../../../DevicewiseSummary/Components/StatusDialog';

// ---------------------------------------------------------------------
const useStyles = makeStyles(() => ({}));

// ---------------------------------------------------------------------

const StatusRenderer = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [openstatus, setOpenstatus] = useState(false);

  const handleclickstatus = () => {
    setOpenstatus(false);
  };

  const handleclickstatuscolse = () => {
    setOpenstatus(false);
  };

  // -----------------------------------------------------------------------
  if (data === undefined) {
    return '';
  }
  // if (data?.r_dvc_up_down?.toLowerCase() === 'down') {
  //   return (
  //     <div className={classes.downHigh}>
  //       <Chip label="Failed" color="error" className={classes.criticalChip} onClick={handleclickstatus}/>
  //       {openstatus &&
  //             <StatusDialog
  //                 open={openstatus}
  //                 handleClose={handleclickstatuscolse}
  //                 data={data}
  //             />
  //         }
  //     </div>
  //   );
  // }

  if (data?.r_criticality === 'OPEN_ALL') {
    return (
      <div className={classes.downHigh}>
        <Chip label="OPEN ALL" color="error" className={classes.criticalChip} />
      </div>
    );
  }
  if (data?.r_criticality === 'APPROVED_ALL') {
    return (
      <div>
        <Chip label="APPROVED ALL" color="success" className={classes.criticalChip} onClick={handleclickstatus} />
        {
          openstatus &&
          <StatusDialog
            open={openstatus}
            handleClose={handleclickstatuscolse}
            data={data}
          />
        }
      </div>)
  }
  if (data?.r_criticality === 'UN_APPROVED_ALL') {
    return (
      <div>
        <Chip label="UN APPROVED ALL" color="info" className={classes.criticalChip} onClick={handleclickstatus} />
        {
          openstatus &&
          <StatusDialog
            open={openstatus}
            handleClose={handleclickstatuscolse}
            data={data}
          />
        }
      </div>)
  }
  if (data?.r_criticality === 'ON_HOLD_ALL') {
    
      return (
        <Box style={{display:'flex',flexDirection:'row',gap: '10px', margin:'10px'}}>
         <Chip label="ON HOLD ALL" color="warning" className={classes.criticalChip} onClick={handleclickstatus} />
          <Typography sx={{fontSize: '18px', fontFamily: 'Arial', fontWeight: '400'}}>
            Till {data?.r_criticality_untill}
          </Typography>
        {openstatus && <StatusDialog open={openstatus} handleClose={handleclickstatuscolse} data={data} />}

        </Box>
      
       
    );
  }
  if (data?.r_criticality === 'PARTYLY_CHANGED') {
    return (
      <div>
        <Chip label="PARTYLY CHANGED" color="info" className={classes.criticalChip} onClick={handleclickstatus} />
        {openstatus && <StatusDialog open={openstatus} handleClose={handleclickstatuscolse} data={data} />}
      </div>
    );
  }
};

StatusRenderer.propTypes = {
  data: PropTypes.object,
};

export default StatusRenderer;
