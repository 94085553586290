import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  Breadcrumbs,
  Container,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { NetworkHealthComponent } from '../components/NetworkHealthComponents';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Network() {

  return (
    <Page title="Network Health">
      <Container maxWidth={false}>
        <div role="presentation" style={{ paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
              Dashboard
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/network-health">
              Network-Health
            </Typography>
          </Breadcrumbs>
        </div>
        <NetworkHealthComponent />
      </Container>
    </Page>
  );
}
