// @mui
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
    Dialog, Slide,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,Box,
    Typography,
    Stack,
} from '@mui/material';
import { forwardRef } from 'react';


//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => { return <Slide direction="up" ref={ref} {...props} />; });


const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-start"
    },
    topPaperScrollBody: {
        verticalAlign: "top"
    },
      fileNameBox: {
        width:'100%',
        padding:'10px',
        marginBottom:'25px',
        borderStyle:'solid',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#f5f5f5',
        fontFamily:'Sans-serif',
      },
      stackContainer: {
        display:'flex', 
        justifyContent:'space-between',
        alignItems:'center',
      }
});
 

//----------------------------------------------------------------------

export default function VulnerableDialog(props) {
    const classes = useStyles();
    const { openDialog, handleClose, data } = props;

    return (
        <>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="diff-viewer"
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody
                }}
            >
                <DialogTitle>
                    <Stack direction="row" className={classes.stackContainer}>
                        <Box className={classes.root}>
                            Vulnarable details
                            <Typography variant="subtitle2" gutterBottom>
                                {data.r_dvc_name} - {data.r_dvc_ip_address}
                            </Typography>
                        </Box>

                        <IconButton onClick={handleClose}>
                            <Close sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <Divider />

                <DialogContent sx={{ py: 3, px: 3 }}>
                        {data.vulnerables.map((item) => {
                            return(
                                <Box className={classes.fileNameBox} key={item.r_cve_id}>
                                <table key={item.r_cve_id}>
                                    <tbody>
                                        <tr>
                                            <td style={{width:'13%',textAlign: 'left'}}>CVE</td>
                                            <td>:{item.r_cve_id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'13%', textAlign: 'left'}}>Description</td>
                                            <td>:{item.r_cve_summary}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'13%',textAlign: 'left'}}>SOLUTION link</td>
                                            <td>:<a href={item.r_cve_solution_link}  rel="noreferrer" target="_blank"> {item.r_cve_solution_link}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </Box>
                            );
                        })}
                </DialogContent>
                <DialogActions />
            </Dialog>
        </>
    );
}
