

/* eslint-disable */
import { TestbedInstance, instance as modem } from '../modem';
const testbedModem = TestbedInstance();

export const getSitewiseTopology = async (id, data, controller) => {
  const newPro = modem.post(`/${id}/network/topology/sitewise`, data, {
    signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getSSHfailure = async (id, data, controller) => {
  console.log(id,"id");
  const newPro = modem.post(`/${id}/ssh_failure_reason`, data, {
    // signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};

// export const getSitewiseTopology = async (id, data, controller) => {
//   const newPro = {
//    "data":{
//     "nodes": [
//       {
//           "id": "48B4C3CDDB6E",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:49.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.100",
//                   "r_dvc_host": "IN1043_AP002",
//                   "r_dvc_last_up_ts": 1717158556,
//                   "r_dvc_node_mac_address": "48B4C3CDDB6E",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58GX",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58GX",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cddb6e",
//                   "r_dvc_last_down_ts": 1715671399,
//                   "r_dvc_name": "IN1043_AP002",
//                   "position_x": "0",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_y": "300",
//                   "g_updated_at": "2024-06-12 13:17:28",
//                   "r_dvc_unique_identifier": "48b4c3cddb6e_CNPKLJ58GX",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 09:49:07",
//                   "_id": 17413,
//                   "_uid": "9ac9fa14-c760-4124-82a2-564c3a83150f",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:49.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.100",
//               "r_dvc_host": "IN1043_AP002",
//               "r_dvc_last_up_ts": 1717158556,
//               "r_dvc_node_mac_address": "48B4C3CDDB6E",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58GX",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58GX",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cddb6e",
//               "r_dvc_last_down_ts": 1715671399,
//               "r_dvc_name": "IN1043_AP002",
//               "position_x": "0",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_y": "300",
//               "g_updated_at": "2024-06-12 13:17:28",
//               "r_dvc_unique_identifier": "48b4c3cddb6e_CNPKLJ58GX",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 09:49:07",
//               "_id": 17413,
//               "_uid": "9ac9fa14-c760-4124-82a2-564c3a83150f",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 0,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 0,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDACEA",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:38.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.96",
//                   "r_dvc_host": "IN1043_AP005",
//                   "r_dvc_last_up_ts": 1717158552,
//                   "r_dvc_node_mac_address": "48B4C3CDACEA",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58HT",
//                   "r_dvc_pingable": true,
//                   "r_dvc_model": "505",
//                   "r_dvc_serial_number": "CNPKLJ58HT",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cdacea",
//                   "r_dvc_last_down_ts": 1714671534,
//                   "r_dvc_name": "IN1043_AP005",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "100",
//                   "position_y": "300",
//                   "g_updated_at": "2024-06-12 13:37:14",
//                   "r_dvc_unique_identifier": "48b4c3cdacea_CNPKLJ58HT",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 08:59:43",
//                   "_id": 17408,
//                   "_uid": "9ac9e869-00d4-4954-8053-4e1b12868096",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:38.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.96",
//               "r_dvc_host": "IN1043_AP005",
//               "r_dvc_last_up_ts": 1717158552,
//               "r_dvc_node_mac_address": "48B4C3CDACEA",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58HT",
//               "r_dvc_pingable": true,
//               "r_dvc_model": "505",
//               "r_dvc_serial_number": "CNPKLJ58HT",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cdacea",
//               "r_dvc_last_down_ts": 1714671534,
//               "r_dvc_name": "IN1043_AP005",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "100",
//               "position_y": "300",
//               "g_updated_at": "2024-06-12 13:37:14",
//               "r_dvc_unique_identifier": "48b4c3cdacea_CNPKLJ58HT",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 08:59:43",
//               "_id": 17408,
//               "_uid": "9ac9e869-00d4-4954-8053-4e1b12868096",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 1,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 100,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDAE84",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:43.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.97",
//                   "r_dvc_host": "IN1043_AP006",
//                   "r_dvc_last_up_ts": 1717158554,
//                   "r_dvc_node_mac_address": "48B4C3CDAE84",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58HK",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58HK",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cdae84",
//                   "r_dvc_last_down_ts": 1714671535,
//                   "r_dvc_name": "IN1043_AP006",
//                   "position_x": "200",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_y": "300",
//                   "g_updated_at": "2024-06-12 13:44:58",
//                   "r_dvc_unique_identifier": "48b4c3cdae84_CNPKLJ58HK",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 08:32:41",
//                   "_id": 17407,
//                   "_uid": "9ac9debe-4eb5-4b65-a37e-d334ad1bacab",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:43.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.97",
//               "r_dvc_host": "IN1043_AP006",
//               "r_dvc_last_up_ts": 1717158554,
//               "r_dvc_node_mac_address": "48B4C3CDAE84",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58HK",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58HK",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cdae84",
//               "r_dvc_last_down_ts": 1714671535,
//               "r_dvc_name": "IN1043_AP006",
//               "position_x": "200",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_y": "300",
//               "g_updated_at": "2024-06-12 13:44:58",
//               "r_dvc_unique_identifier": "48b4c3cdae84_CNPKLJ58HK",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 08:32:41",
//               "_id": 17407,
//               "_uid": "9ac9debe-4eb5-4b65-a37e-d334ad1bacab",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 2,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 200,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDCF3A",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:49.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.99",
//                   "r_dvc_last_up_ts": 1721854620,
//                   "r_dvc_config_latest_failed_at": "2023-12-08 02:58:57",
//                   "r_dvc_host": "IN1043_AP001",
//                   "r_dvc_node_mac_address": "48B4C3CDCF3A",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "r_dvc_mgmt_type": "VIRTUAL_CONTROLLER",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "rp_dvc_serial_number": "CNPKLJ58C4",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58C4",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cdcf3a",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1721853951,
//                   "r_dvc_name": "IN1043_AP001",
//                   "r_dvc_config_latest_success_at": "2024-05-13 11:47:57",
//                   "position_x": "300",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_y": "300",
//                   "g_updated_at": "2024-07-25 02:27:00",
//                   "r_dvc_unique_identifier": "48b4c3cdcf3a_CNPKLJ58C4",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 08:19:42",
//                   "_id": 17405,
//                   "_uid": "b5931aef9d8c41748c18aea5bbe5e6a6",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:49.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.99",
//               "r_dvc_last_up_ts": 1721854620,
//               "r_dvc_config_latest_failed_at": "2023-12-08 02:58:57",
//               "r_dvc_host": "IN1043_AP001",
//               "r_dvc_node_mac_address": "48B4C3CDCF3A",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "r_dvc_mgmt_type": "VIRTUAL_CONTROLLER",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "r_dvc_mgmt_ip": "10.26.54.70",
//               "rp_dvc_serial_number": "CNPKLJ58C4",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58C4",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cdcf3a",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1721853951,
//               "r_dvc_name": "IN1043_AP001",
//               "r_dvc_config_latest_success_at": "2024-05-13 11:47:57",
//               "position_x": "300",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_y": "300",
//               "g_updated_at": "2024-07-25 02:27:00",
//               "r_dvc_unique_identifier": "48b4c3cdcf3a_CNPKLJ58C4",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 08:19:42",
//               "_id": 17405,
//               "_uid": "b5931aef9d8c41748c18aea5bbe5e6a6",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 3,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 300,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDE8DC",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:40.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.101",
//                   "r_dvc_host": "IN1043_AP003",
//                   "r_dvc_last_up_ts": 1721854632,
//                   "r_dvc_node_mac_address": "48B4C3CDE8DC",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58FR",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58FR",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cde8dc",
//                   "r_dvc_last_down_ts": 1721853965,
//                   "r_dvc_name": "IN1043_AP003",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "400",
//                   "position_y": "300",
//                   "g_updated_at": "2024-07-25 02:27:12",
//                   "r_dvc_unique_identifier": "48b4c3cde8dc_CNPKLJ58FR",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 07:14:24",
//                   "_id": 17402,
//                   "_uid": "9ac9c2bf-983d-44f3-8a13-f1cf15e81b57",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:40.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.101",
//               "r_dvc_host": "IN1043_AP003",
//               "r_dvc_last_up_ts": 1721854632,
//               "r_dvc_node_mac_address": "48B4C3CDE8DC",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58FR",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58FR",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cde8dc",
//               "r_dvc_last_down_ts": 1721853965,
//               "r_dvc_name": "IN1043_AP003",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "400",
//               "position_y": "300",
//               "g_updated_at": "2024-07-25 02:27:12",
//               "r_dvc_unique_identifier": "48b4c3cde8dc_CNPKLJ58FR",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 07:14:24",
//               "_id": 17402,
//               "_uid": "9ac9c2bf-983d-44f3-8a13-f1cf15e81b57",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 4,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 400,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDE54E",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:22:37.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.98",
//                   "r_dvc_host": "IN1043_AP007",
//                   "r_dvc_last_up_ts": 1721854626,
//                   "r_dvc_node_mac_address": "48B4C3CDE54E",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58GF",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58GF",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cde54e",
//                   "r_dvc_last_down_ts": 1721853959,
//                   "r_dvc_name": "IN1043_AP007",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "500",
//                   "position_y": "300",
//                   "g_updated_at": "2024-07-25 02:27:06",
//                   "r_dvc_unique_identifier": "48b4c3cde54e_CNPKLJ58GF",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 06:54:57",
//                   "_id": 17400,
//                   "_uid": "9ac9bbcb-067d-497b-8fc1-c2533060e49e",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:22:37.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.98",
//               "r_dvc_host": "IN1043_AP007",
//               "r_dvc_last_up_ts": 1721854626,
//               "r_dvc_node_mac_address": "48B4C3CDE54E",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58GF",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58GF",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cde54e",
//               "r_dvc_last_down_ts": 1721853959,
//               "r_dvc_name": "IN1043_AP007",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "500",
//               "position_y": "300",
//               "g_updated_at": "2024-07-25 02:27:06",
//               "r_dvc_unique_identifier": "48b4c3cde54e_CNPKLJ58GF",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 06:54:57",
//               "_id": 17400,
//               "_uid": "9ac9bbcb-067d-497b-8fc1-c2533060e49e",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 5,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 500,
//               "y": 300
//           }
//       },
//       {
//           "id": "48B4C3CDD7FE",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "30 days, 18:19:20.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_ip_address": "10.26.54.95",
//                   "r_dvc_host": "IN1043_AP004",
//                   "r_dvc_last_up_ts": 1721441117,
//                   "r_dvc_node_mac_address": "48B4C3CDD7FE",
//                   "r_dvc_ha_enabled": true,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNPKLJ58DB",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNPKLJ58DB",
//                   "r_dvc_model": "505",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "g_status": "ACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "48b4c3cdd7fe",
//                   "r_dvc_last_down_ts": 1716537909,
//                   "r_dvc_name": "IN1043_AP004",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "600",
//                   "position_y": "300",
//                   "g_updated_at": "2024-07-20 07:35:17",
//                   "r_dvc_unique_identifier": "48b4c3cdd7fe_CNPKLJ58DB",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-12-07 06:50:46",
//                   "_id": 17399,
//                   "_uid": "9ac9ba4c-b278-4f45-8367-cbbf09a05c3f",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "30 days, 18:19:20.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_ip_address": "10.26.54.95",
//               "r_dvc_host": "IN1043_AP004",
//               "r_dvc_last_up_ts": 1721441117,
//               "r_dvc_node_mac_address": "48B4C3CDD7FE",
//               "r_dvc_ha_enabled": true,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNPKLJ58DB",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNPKLJ58DB",
//               "r_dvc_model": "505",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_typ_vendor": "Aruba",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "g_status": "ACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "48b4c3cdd7fe",
//               "r_dvc_last_down_ts": 1716537909,
//               "r_dvc_name": "IN1043_AP004",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "600",
//               "position_y": "300",
//               "g_updated_at": "2024-07-20 07:35:17",
//               "r_dvc_unique_identifier": "48b4c3cdd7fe_CNPKLJ58DB",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-12-07 06:50:46",
//               "_id": 17399,
//               "_uid": "9ac9ba4c-b278-4f45-8367-cbbf09a05c3f",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 6,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 600,
//               "y": 300
//           }
//       },
//       {
//           "id": "9C8CD8C4BAC2",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:49:30.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.96",
//                   "r_dvc_last_up_ts": 1717158551,
//                   "r_dvc_node_mac_address": "9C8CD8C4BAC2",
//                   "r_dvc_host": "IN1043_AP002",
//                   "r_dvc_config_latest_failed_at": "2023-06-04 16:53:36",
//                   "r_dvc_ha_enabled": true,
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC5X",
//                   "r_dvc_serial_number": "CNH3JSSC5X",
//                   "r_dvc_pingable": true,
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4bac2",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_name": "IN1043_AP002",
//                   "r_dvc_last_down_ts": 1714671533,
//                   "r_dvc_config_latest_success_at": "2023-10-09 03:25:26",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "700",
//                   "position_y": "300",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 08:37:23",
//                   "r_dvc_unique_identifier": "9c8cd8c4bac2_CNH3JSSC5X",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 10:07:51",
//                   "_id": 15301,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "51a141da85d040dea159f90124d53147",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:49:30.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.96",
//               "r_dvc_last_up_ts": 1717158551,
//               "r_dvc_node_mac_address": "9C8CD8C4BAC2",
//               "r_dvc_host": "IN1043_AP002",
//               "r_dvc_config_latest_failed_at": "2023-06-04 16:53:36",
//               "r_dvc_ha_enabled": true,
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC5X",
//               "r_dvc_serial_number": "CNH3JSSC5X",
//               "r_dvc_pingable": true,
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4bac2",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_name": "IN1043_AP002",
//               "r_dvc_last_down_ts": 1714671533,
//               "r_dvc_config_latest_success_at": "2023-10-09 03:25:26",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "700",
//               "position_y": "300",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 08:37:23",
//               "r_dvc_unique_identifier": "9c8cd8c4bac2_CNH3JSSC5X",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 10:07:51",
//               "_id": 15301,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "51a141da85d040dea159f90124d53147",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 7,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 700,
//               "y": 300
//           }
//       },
//       {
//           "id": "9C8CD8C4BC68",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:47:51.76",
//                   "r_dvc_ha_item_count": "1",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.100",
//                   "r_dvc_config_latest_failed_at": "2023-10-30 12:23:51",
//                   "r_dvc_last_up_ts": 1721854636,
//                   "r_dvc_host": "IN1043_AP003",
//                   "r_dvc_node_mac_address": "9C8CD8C4BC68",
//                   "r_dvc_ha_enabled": false,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC5W",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC5W",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4bc68",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1721853971,
//                   "r_dvc_name": "IN1043_AP003",
//                   "r_dvc_config_latest_success_at": "2023-11-27 13:03:30",
//                   "position_x": "800",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_y": "300",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:48:20",
//                   "r_dvc_unique_identifier": "9c8cd8c4bc68_CNH3JSSC5W",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 10:06:03",
//                   "_id": 15273,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "e122fe2576cb4f709a024e1adab5cddb",
//                   "r_dvc_ha_type": "0"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:47:51.76",
//               "r_dvc_ha_item_count": "1",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.100",
//               "r_dvc_config_latest_failed_at": "2023-10-30 12:23:51",
//               "r_dvc_last_up_ts": 1721854636,
//               "r_dvc_host": "IN1043_AP003",
//               "r_dvc_node_mac_address": "9C8CD8C4BC68",
//               "r_dvc_ha_enabled": false,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC5W",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC5W",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4bc68",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1721853971,
//               "r_dvc_name": "IN1043_AP003",
//               "r_dvc_config_latest_success_at": "2023-11-27 13:03:30",
//               "position_x": "800",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_y": "300",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:48:20",
//               "r_dvc_unique_identifier": "9c8cd8c4bc68_CNH3JSSC5W",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 10:06:03",
//               "_id": 15273,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "e122fe2576cb4f709a024e1adab5cddb",
//               "r_dvc_ha_type": "0",
//               "with_access_layer": false,
//               "sibling_item": 8,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 800,
//               "y": 300
//           }
//       },
//       {
//           "id": "9C8CD8C4DF60",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:49:29.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.97",
//                   "r_dvc_config_latest_failed_at": "2023-07-19 10:40:08",
//                   "r_dvc_last_up_ts": 1721854646,
//                   "r_dvc_host": "IN1043_AP007",
//                   "r_dvc_node_mac_address": "9C8CD8C4DF60",
//                   "r_dvc_ha_enabled": true,
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC62",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC62",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4df60",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1721853979,
//                   "r_dvc_name": "IN1043_AP007",
//                   "r_dvc_config_latest_success_at": "2023-07-25 10:35:23",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "900",
//                   "position_y": "300",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:52:19",
//                   "r_dvc_unique_identifier": "9c8cd8c4df60_CNH3JSSC62",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 10:05:25",
//                   "_id": 15235,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "40d54384132142c8a05817de9217833c",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:49:29.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.97",
//               "r_dvc_config_latest_failed_at": "2023-07-19 10:40:08",
//               "r_dvc_last_up_ts": 1721854646,
//               "r_dvc_host": "IN1043_AP007",
//               "r_dvc_node_mac_address": "9C8CD8C4DF60",
//               "r_dvc_ha_enabled": true,
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC62",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC62",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4df60",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1721853979,
//               "r_dvc_name": "IN1043_AP007",
//               "r_dvc_config_latest_success_at": "2023-07-25 10:35:23",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "900",
//               "position_y": "300",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:52:19",
//               "r_dvc_unique_identifier": "9c8cd8c4df60_CNH3JSSC62",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 10:05:25",
//               "_id": 15235,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "40d54384132142c8a05817de9217833c",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 9,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 900,
//               "y": 300
//           }
//       },
//       {
//           "id": "9C8CD8C4C46E",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:47:58.15",
//                   "r_dvc_ha_item_count": "1",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.99",
//                   "r_dvc_config_latest_failed_at": "2023-07-12 10:35:17",
//                   "r_dvc_last_up_ts": 1721854640,
//                   "r_dvc_host": "IN1043_AP004",
//                   "r_dvc_node_mac_address": "9C8CD8C4C46E",
//                   "r_dvc_ha_enabled": false,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC5Z",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC5Z",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4c46e",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_name": "IN1043_AP004",
//                   "r_dvc_last_down_ts": 1721853975,
//                   "r_dvc_config_latest_success_at": "2023-07-17 10:46:38",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "0",
//                   "position_y": "400",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:50:01",
//                   "r_dvc_unique_identifier": "9c8cd8c4c46e_CNH3JSSC5Z",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 10:05:18",
//                   "_id": 15228,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "14196a37689c47858084fe8b2592fcb4",
//                   "r_dvc_ha_type": "0"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:47:58.15",
//               "r_dvc_ha_item_count": "1",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.99",
//               "r_dvc_config_latest_failed_at": "2023-07-12 10:35:17",
//               "r_dvc_last_up_ts": 1721854640,
//               "r_dvc_host": "IN1043_AP004",
//               "r_dvc_node_mac_address": "9C8CD8C4C46E",
//               "r_dvc_ha_enabled": false,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC5Z",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC5Z",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4c46e",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_name": "IN1043_AP004",
//               "r_dvc_last_down_ts": 1721853975,
//               "r_dvc_config_latest_success_at": "2023-07-17 10:46:38",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "0",
//               "position_y": "400",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:50:01",
//               "r_dvc_unique_identifier": "9c8cd8c4c46e_CNH3JSSC5Z",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 10:05:18",
//               "_id": 15228,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "14196a37689c47858084fe8b2592fcb4",
//               "r_dvc_ha_type": "0",
//               "with_access_layer": false,
//               "sibling_item": 10,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 0,
//               "y": 400
//           }
//       },
//       {
//           "id": "9C8CD8C4C830",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:48:45.87",
//                   "r_dvc_ha_item_count": "1",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.95",
//                   "r_dvc_last_up_ts": 1721854617,
//                   "r_dvc_config_latest_failed_at": "2023-06-20 11:50:09",
//                   "r_dvc_node_mac_address": "9C8CD8C4C830",
//                   "r_dvc_host": "IN1043_AP005",
//                   "r_dvc_ha_enabled": false,
//                   "r_dvc_controller": "VC",
//                   "r_dvc_mgmt_type": "VIRTUAL_CONTROLLER",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "rp_dvc_serial_number": "CNH3JSSC4Y",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC4Y",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4c830",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1721853949,
//                   "r_dvc_name": "IN1043_AP005",
//                   "r_dvc_config_latest_success_at": "2024-05-13 11:47:57",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "100",
//                   "position_y": "400",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:30:24",
//                   "r_dvc_unique_identifier": "9c8cd8c4c830_CNH3JSSC4Y",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 10:00:10",
//                   "_id": 14920,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "64a28c1e5d2c4b22bdce08c216ddca3c",
//                   "r_dvc_ha_type": "0"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:48:45.87",
//               "r_dvc_ha_item_count": "1",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.95",
//               "r_dvc_last_up_ts": 1721854617,
//               "r_dvc_config_latest_failed_at": "2023-06-20 11:50:09",
//               "r_dvc_node_mac_address": "9C8CD8C4C830",
//               "r_dvc_host": "IN1043_AP005",
//               "r_dvc_ha_enabled": false,
//               "r_dvc_controller": "VC",
//               "r_dvc_mgmt_type": "VIRTUAL_CONTROLLER",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "r_dvc_mgmt_ip": "10.26.54.70",
//               "rp_dvc_serial_number": "CNH3JSSC4Y",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC4Y",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4c830",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1721853949,
//               "r_dvc_name": "IN1043_AP005",
//               "r_dvc_config_latest_success_at": "2024-05-13 11:47:57",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "100",
//               "position_y": "400",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:30:24",
//               "r_dvc_unique_identifier": "9c8cd8c4c830_CNH3JSSC4Y",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 10:00:10",
//               "_id": 14920,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "64a28c1e5d2c4b22bdce08c216ddca3c",
//               "r_dvc_ha_type": "0",
//               "with_access_layer": false,
//               "sibling_item": 11,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 100,
//               "y": 400
//           }
//       },
//       {
//           "id": "9C8CD8C4A354",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:48:40.55",
//                   "r_dvc_ha_item_count": "1",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.101",
//                   "r_dvc_host": "IN1043_AP001",
//                   "r_dvc_node_mac_address": "9C8CD8C4A354",
//                   "r_dvc_last_up_ts": 1703633273,
//                   "r_dvc_ha_enabled": false,
//                   "r_dvc_controller": "VC",
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC5D",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC5D",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4a354",
//                   "r_dvc_name": "IN1043_AP001",
//                   "r_dvc_last_down_ts": 1704735097,
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "200",
//                   "position_y": "400",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-01-29 17:40:49",
//                   "r_dvc_unique_identifier": "9c8cd8c4a354_CNH3JSSC5D",
//                   "r_dvc_up_down": "DOWN",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 09:59:11",
//                   "_id": 14862,
//                   "g_deleted_at": "2024-01-29 17:40:49",
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "98f7495d-13cb-4743-bad8-47d7c860076c",
//                   "r_dvc_ha_type": "0"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:48:40.55",
//               "r_dvc_ha_item_count": "1",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.101",
//               "r_dvc_host": "IN1043_AP001",
//               "r_dvc_node_mac_address": "9C8CD8C4A354",
//               "r_dvc_last_up_ts": 1703633273,
//               "r_dvc_ha_enabled": false,
//               "r_dvc_controller": "VC",
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC5D",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC5D",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4a354",
//               "r_dvc_name": "IN1043_AP001",
//               "r_dvc_last_down_ts": 1704735097,
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "200",
//               "position_y": "400",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-01-29 17:40:49",
//               "r_dvc_unique_identifier": "9c8cd8c4a354_CNH3JSSC5D",
//               "r_dvc_up_down": "DOWN",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 09:59:11",
//               "_id": 14862,
//               "g_deleted_at": "2024-01-29 17:40:49",
//               "r_dvc_eos": "Not Announced",
//               "_uid": "98f7495d-13cb-4743-bad8-47d7c860076c",
//               "r_dvc_ha_type": "0",
//               "with_access_layer": false,
//               "sibling_item": 12,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 200,
//               "y": 400
//           }
//       },
//       {
//           "id": "9C8CD8C4CACA",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_uptime": "2 days, 0:49:30.00",
//                   "r_dvc_ha_item_count": "7",
//                   "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//                   "r_dvc_ip_address": "10.26.54.98",
//                   "r_dvc_host": "IN1043_AP006",
//                   "r_dvc_node_mac_address": "9C8CD8C4CACA",
//                   "r_dvc_last_up_ts": 1721854644,
//                   "r_dvc_ha_enabled": true,
//                   "l_dvc_typ_name": "ACCESS_POINT",
//                   "rp_dvc_serial_number": "CNH3JSSC60",
//                   "r_dvc_pingable": true,
//                   "r_dvc_serial_number": "CNH3JSSC60",
//                   "r_dvc_model": "305",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "ACCESS_POINT",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_mac_address": "9c8cd8c4caca",
//                   "r_dvc_last_down_ts": 1721853978,
//                   "r_dvc_name": "IN1043_AP006",
//                   "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//                   "position_x": "300",
//                   "position_y": "400",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:51:42",
//                   "r_dvc_unique_identifier": "9c8cd8c4caca_CNH3JSSC60",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_os",
//                   "g_created_at": "2023-04-19 09:55:35",
//                   "_id": 14645,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "98f74812-ee11-4363-8a40-399c86d34b9b",
//                   "r_dvc_ha_type": "iap"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_uptime": "2 days, 0:49:30.00",
//               "r_dvc_ha_item_count": "7",
//               "r_dvc_description": "ArubaOS(MODEL:305):Version8.10.0.9-8.10.0.9LSR",
//               "r_dvc_ip_address": "10.26.54.98",
//               "r_dvc_host": "IN1043_AP006",
//               "r_dvc_node_mac_address": "9C8CD8C4CACA",
//               "r_dvc_last_up_ts": 1721854644,
//               "r_dvc_ha_enabled": true,
//               "l_dvc_typ_name": "ACCESS_POINT",
//               "rp_dvc_serial_number": "CNH3JSSC60",
//               "r_dvc_pingable": true,
//               "r_dvc_serial_number": "CNH3JSSC60",
//               "r_dvc_model": "305",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "ACCESS_POINT",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_type_source": "DC",
//               "r_dvc_mac_address": "9c8cd8c4caca",
//               "r_dvc_last_down_ts": 1721853978,
//               "r_dvc_name": "IN1043_AP006",
//               "r_dvc_firmware": "Version 8.10.0.9-8.10.0.9 LSR",
//               "position_x": "300",
//               "position_y": "400",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:51:42",
//               "r_dvc_unique_identifier": "9c8cd8c4caca_CNH3JSSC60",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_os",
//               "g_created_at": "2023-04-19 09:55:35",
//               "_id": 14645,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "98f74812-ee11-4363-8a40-399c86d34b9b",
//               "r_dvc_ha_type": "iap",
//               "with_access_layer": false,
//               "sibling_item": 13,
//               "sibling_count": 14
//           },
//           "type": "ACCESS_POINT",
//           "position": {
//               "x": 300,
//               "y": 400
//           }
//       },
//       {
//           "id": "D4E0533E16C0",
//           "nodes": [
//               {
//                   "l_dvc_l2_parents_snmp": [
//                       "E8EDD6F28ABE"
//                   ],
//                   "r_dvc_uptime": "19 days, 2:53:01.23",
//                   "r_dvc_ha_item_count": "1",
//                   "r_dvc_description": "ArubaR8N85A600048GCL44SFPSwchPL.10.11.1010",
//                   "l_dvc_l2_child_snmp": [
//                       "9C8CD8C4BAC2",
//                       "9C8CD8C4DF60",
//                       "9C8CD8C4CACA",
//                       "9C8CD8C4C46E",
//                       "9C8CD8C4BC68",
//                       "9C8CD8C4A354",
//                       "9C8CD8C4C830",
//                       "48B4C3CDD7FE",
//                       "48B4C3CDCF3A",
//                       "48B4C3CDDB6E",
//                       "48B4C3CDE8DC",
//                       "48B4C3CDACEA",
//                       "48B4C3CDAE84",
//                       "48B4C3CDE54E"
//                   ],
//                   "r_dvc_ip_address": "10.26.54.66",
//                   "r_dvc_last_up_ts": 1717158559,
//                   "r_dvc_config_latest_failed_at": "2023-07-02 14:23:28",
//                   "r_dvc_node_mac_address": "D4E0533E16C0",
//                   "r_dvc_host": "IN1043-ASW01",
//                   "r_dvc_ha_enabled": false,
//                   "l_dvc_typ_name": "SWITCH",
//                   "rp_dvc_serial_number": "CN32L3J16N",
//                   "r_dvc_serial_number": "CN32L3J16N",
//                   "r_dvc_pingable": true,
//                   "r_dvc_model": "Aruba R8N85A 6000 48G CL4 4SFP Swch PL.10.11.1010",
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "CORE_SWITCH",
//                   "l_dvc_typ_vendor": "Aruba",
//                   "g_status": "INACTIVE",
//                   "l_dvc_l2_parents": [
//                       "E8EDD6F28ABE"
//                   ],
//                   "r_dvc_backupable": true,
//                   "r_dvc_type_source": "DC",
//                   "l_dvc_l2_childrens": [
//                       "9C8CD8C4BAC2",
//                       "9C8CD8C4DF60",
//                       "9C8CD8C4CACA",
//                       "9C8CD8C4C46E",
//                       "9C8CD8C4BC68",
//                       "9C8CD8C4A354",
//                       "9C8CD8C4C830",
//                       "48B4C3CDD7FE",
//                       "48B4C3CDCF3A",
//                       "48B4C3CDDB6E",
//                       "48B4C3CDE8DC",
//                       "48B4C3CDACEA",
//                       "48B4C3CDAE84",
//                       "48B4C3CDE54E"
//                   ],
//                   "r_dvc_mac_address": "d4e0533e16c0",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1716460221,
//                   "r_dvc_name": "IN1043-ASW01",
//                   "r_dvc_config_latest_success_at": "2023-12-20 05:22:58",
//                   "position_x": "460",
//                   "r_dvc_firmware": "PL.10.11.1010",
//                   "position_y": "180",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 08:44:20",
//                   "r_dvc_unique_identifier": "d4e0533e16c0_CN32L3J16N",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "aruba_oscx",
//                   "g_created_at": "2023-06-30 01:28:53",
//                   "_id": 15618,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "88368adfc2374e0ca0b0835384783f89",
//                   "r_dvc_ha_type": "0"
//               }
//           ],
//           "data": {
//               "l_dvc_l2_parents_snmp": [
//                   "E8EDD6F28ABE"
//               ],
//               "r_dvc_uptime": "19 days, 2:53:01.23",
//               "r_dvc_ha_item_count": "1",
//               "r_dvc_description": "ArubaR8N85A600048GCL44SFPSwchPL.10.11.1010",
//               "l_dvc_l2_child_snmp": [
//                   "9C8CD8C4BAC2",
//                   "9C8CD8C4DF60",
//                   "9C8CD8C4CACA",
//                   "9C8CD8C4C46E",
//                   "9C8CD8C4BC68",
//                   "9C8CD8C4A354",
//                   "9C8CD8C4C830",
//                   "48B4C3CDD7FE",
//                   "48B4C3CDCF3A",
//                   "48B4C3CDDB6E",
//                   "48B4C3CDE8DC",
//                   "48B4C3CDACEA",
//                   "48B4C3CDAE84",
//                   "48B4C3CDE54E"
//               ],
//               "r_dvc_ip_address": "10.26.54.66",
//               "r_dvc_last_up_ts": 1717158559,
//               "r_dvc_config_latest_failed_at": "2023-07-02 14:23:28",
//               "r_dvc_node_mac_address": "D4E0533E16C0",
//               "r_dvc_host": "IN1043-ASW01",
//               "r_dvc_ha_enabled": false,
//               "l_dvc_typ_name": "SWITCH",
//               "rp_dvc_serial_number": "CN32L3J16N",
//               "r_dvc_serial_number": "CN32L3J16N",
//               "r_dvc_pingable": true,
//               "r_dvc_model": "Aruba R8N85A 6000 48G CL4 4SFP Swch PL.10.11.1010",
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "CORE_SWITCH",
//               "l_dvc_typ_vendor": "Aruba",
//               "g_status": "INACTIVE",
//               "l_dvc_l2_parents": [
//                   "E8EDD6F28ABE"
//               ],
//               "r_dvc_backupable": true,
//               "r_dvc_type_source": "DC",
//               "l_dvc_l2_childrens": [
//                   "9C8CD8C4BAC2",
//                   "9C8CD8C4DF60",
//                   "9C8CD8C4CACA",
//                   "9C8CD8C4C46E",
//                   "9C8CD8C4BC68",
//                   "9C8CD8C4A354",
//                   "9C8CD8C4C830",
//                   "48B4C3CDD7FE",
//                   "48B4C3CDCF3A",
//                   "48B4C3CDDB6E",
//                   "48B4C3CDE8DC",
//                   "48B4C3CDACEA",
//                   "48B4C3CDAE84",
//                   "48B4C3CDE54E"
//               ],
//               "r_dvc_mac_address": "d4e0533e16c0",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1716460221,
//               "r_dvc_name": "IN1043-ASW01",
//               "r_dvc_config_latest_success_at": "2023-12-20 05:22:58",
//               "position_x": "460",
//               "r_dvc_firmware": "PL.10.11.1010",
//               "position_y": "180",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 08:44:20",
//               "r_dvc_unique_identifier": "d4e0533e16c0_CN32L3J16N",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "aruba_oscx",
//               "g_created_at": "2023-06-30 01:28:53",
//               "_id": 15618,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "88368adfc2374e0ca0b0835384783f89",
//               "r_dvc_ha_type": "0",
//               "with_access_layer": false,
//               "sibling_item": 0,
//               "sibling_count": 1
//           },
//           "type": "SWITCH",
//           "position": {
//               "x": 390,
//               "y": 180
//           }
//       },
//       {
//           "id": "E8EDD6F28ABE",
//           "nodes": [
//               {
//                   "r_dvc_uptime": "2 days, 2:20:40.73",
//                   "r_dvc_ha_item_count": "1",
//                   "l_dvc_l2_child_snmp": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_ip_address": "10.26.54.65",
//                   "r_dvc_host": "IN1043_FW01.DECATHLONIN.NET",
//                   "r_dvc_last_up_ts": 1721854628,
//                   "r_dvc_node_mac_address": "E8EDD6F28ABE",
//                   "r_dvc_config_latest_failed_at": "2023-05-24 00:37:11",
//                   "r_dvc_ha_enabled": true,
//                   "l_dvc_typ_name": "FIREWALL",
//                   "rp_dvc_serial_number": "FGT60FTK21099WEX",
//                   "r_dvc_model": "Fortinet Firewall FortiGate-60F",
//                   "r_dvc_serial_number": "FGT60FTK21099WEX",
//                   "r_dvc_pingable": true,
//                   "r_dvc_type": "NETWORK",
//                   "l_dvc_l2_type": "FIREWALL",
//                   "l_dvc_typ_vendor": "Fortinet",
//                   "g_status": "INACTIVE",
//                   "r_dvc_type_source": "DC",
//                   "l_dvc_l2_childrens": [
//                       "D4E0533E16C0"
//                   ],
//                   "r_dvc_mac_address": "e8edd6f28abe",
//                   "r_dvc_name": "IN1043_FW01.DECATHLONIN.NET",
//                   "r_dvc_config_backup_status": "SCAN_SUCCESS",
//                   "r_dvc_last_down_ts": 1721853959,
//                   "r_dvc_config_latest_success_at": "2024-04-19 10:30:23",
//                   "position_x": "465",
//                   "r_dvc_firmware": "v7.0.14,build0601,240206 (GA.M)",
//                   "position_y": "60",
//                   "r_dvc_eol": "Not Announced",
//                   "g_updated_at": "2024-08-11 09:43:39",
//                   "r_dvc_unique_identifier": "e8edd6f28abe_FGT60FTK21099WEX",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_os": "fortinet",
//                   "g_created_at": "2023-04-19 10:02:40",
//                   "_id": 15070,
//                   "r_dvc_eos": "Not Announced",
//                   "_uid": "721c1b071ef54bb194ea0fc3cf152581",
//                   "r_dvc_ha_type": "1"
//               }
//           ],
//           "data": {
//               "r_dvc_uptime": "2 days, 2:20:40.73",
//               "r_dvc_ha_item_count": "1",
//               "l_dvc_l2_child_snmp": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_ip_address": "10.26.54.65",
//               "r_dvc_host": "IN1043_FW01.DECATHLONIN.NET",
//               "r_dvc_last_up_ts": 1721854628,
//               "r_dvc_node_mac_address": "E8EDD6F28ABE",
//               "r_dvc_config_latest_failed_at": "2023-05-24 00:37:11",
//               "r_dvc_ha_enabled": true,
//               "l_dvc_typ_name": "FIREWALL",
//               "rp_dvc_serial_number": "FGT60FTK21099WEX",
//               "r_dvc_model": "Fortinet Firewall FortiGate-60F",
//               "r_dvc_serial_number": "FGT60FTK21099WEX",
//               "r_dvc_pingable": true,
//               "r_dvc_type": "NETWORK",
//               "l_dvc_l2_type": "FIREWALL",
//               "l_dvc_typ_vendor": "Fortinet",
//               "g_status": "INACTIVE",
//               "r_dvc_type_source": "DC",
//               "l_dvc_l2_childrens": [
//                   "D4E0533E16C0"
//               ],
//               "r_dvc_mac_address": "e8edd6f28abe",
//               "r_dvc_name": "IN1043_FW01.DECATHLONIN.NET",
//               "r_dvc_config_backup_status": "SCAN_SUCCESS",
//               "r_dvc_last_down_ts": 1721853959,
//               "r_dvc_config_latest_success_at": "2024-04-19 10:30:23",
//               "position_x": "465",
//               "r_dvc_firmware": "v7.0.14,build0601,240206 (GA.M)",
//               "position_y": "60",
//               "r_dvc_eol": "Not Announced",
//               "g_updated_at": "2024-08-11 09:43:39",
//               "r_dvc_unique_identifier": "e8edd6f28abe_FGT60FTK21099WEX",
//               "r_dvc_up_down": "UP",
//               "r_dvc_os": "fortinet",
//               "g_created_at": "2023-04-19 10:02:40",
//               "_id": 15070,
//               "r_dvc_eos": "Not Announced",
//               "_uid": "721c1b071ef54bb194ea0fc3cf152581",
//               "r_dvc_ha_type": "1",
//               "with_access_layer": false,
//               "sibling_item": 0,
//               "sibling_count": 1
//           },
//           "type": "FIREWALL",
//           "position": {
//               "x": 395,
//               "y": 60
//           }
//       },
//       {
//           "id": "INTERNET",
//           "type": "INTERNET",
//           "position": {
//               "x": 405,
//               "y": -50
//           },
//           "data": {
//               "r_dvc_host": "INTERNET",
//               "l_dvc_typ_name": "INTERNET",
//               "children_count": 1,
//               "r_dvc_up_down": "UP"
//           }
//       }
//   ],
//   "edges": [
//       {
//           "id": "2fbfc71f6b7f44ddd5cd7876576c2695b5cce6bc71801a4b9d14fb763db9e382",
//           "source": "E8EDD6F28ABE",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "internal1",
//               "g_updated_at": "2023-08-21 02:07:26",
//               "unique_link_id": "2fbfc71f6b7f44ddd5cd7876576c2695b5cce6bc71801a4b9d14fb763db9e382",
//               "r_link_remote_port": "1/1/48",
//               "r_link_local_chassis": "E8EDD6F28ABE",
//               "g_created_at": "2023-08-21 02:07:26",
//               "_id": 1631,
//               "g_status": "ACTIVE",
//               "_uid": "99f012ae-927d-43d4-8a2e-aff1a223ba9c",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "2be0ea76703600859f8f298a3761e7ae838d572bf5a78ba142a4dba2b6ced31e",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4BAC2",
//           "data": {
//               "r_link_local_port": "1/1/33",
//               "g_updated_at": "2023-08-21 02:11:45",
//               "unique_link_id": "2be0ea76703600859f8f298a3761e7ae838d572bf5a78ba142a4dba2b6ced31e",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:11:45",
//               "_id": 1704,
//               "g_status": "ACTIVE",
//               "_uid": "99f0143a-3137-41b2-804b-6ddb057954c0",
//               "r_link_remote_chassis": "9C8CD8C4BAC2",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "180268b9717801cd6bc12052e6a71a9e510219c0998bb3fa0d2c34e0249f5927",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4DF60",
//           "data": {
//               "r_link_local_port": "1/1/37",
//               "g_updated_at": "2023-08-21 02:11:59",
//               "unique_link_id": "180268b9717801cd6bc12052e6a71a9e510219c0998bb3fa0d2c34e0249f5927",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:11:59",
//               "_id": 1709,
//               "g_status": "ACTIVE",
//               "_uid": "99f0144e-affc-4123-bac5-64a56e14e1c3",
//               "r_link_remote_chassis": "9C8CD8C4DF60",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "54ddb44ce513661bd13752c7370a29f9541ae2cf2df6e4ae0cb8d2b85f1baba8",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4CACA",
//           "data": {
//               "r_link_local_port": "1/1/38",
//               "g_updated_at": "2023-08-21 02:12:12",
//               "unique_link_id": "54ddb44ce513661bd13752c7370a29f9541ae2cf2df6e4ae0cb8d2b85f1baba8",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:12:12",
//               "_id": 1712,
//               "g_status": "ACTIVE",
//               "_uid": "99f01463-50c0-417e-8db7-7d5c914293dc",
//               "r_link_remote_chassis": "9C8CD8C4CACA",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "5474f08860e581e2397eaf1206a28cdd642402e7d858479a9128f9d549900b5e",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4C46E",
//           "data": {
//               "r_link_local_port": "1/1/39",
//               "g_updated_at": "2023-08-21 02:12:24",
//               "unique_link_id": "5474f08860e581e2397eaf1206a28cdd642402e7d858479a9128f9d549900b5e",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:12:24",
//               "_id": 1715,
//               "g_status": "ACTIVE",
//               "_uid": "99f01475-eecb-4172-bb8f-5df70baf685c",
//               "r_link_remote_chassis": "9C8CD8C4C46E",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "548c5c97523b780ae3a2615c05125099dea473fa9fc058b67b99ef804fdf65b1",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4BC68",
//           "data": {
//               "r_link_local_port": "1/1/41",
//               "g_updated_at": "2023-08-21 02:12:35",
//               "unique_link_id": "548c5c97523b780ae3a2615c05125099dea473fa9fc058b67b99ef804fdf65b1",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:12:35",
//               "_id": 1720,
//               "g_status": "ACTIVE",
//               "_uid": "99f01487-0cd3-4f20-93a7-8bbff2b8a086",
//               "r_link_remote_chassis": "9C8CD8C4BC68",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "e7833f44491af474227137c0d4324810eb3314627185941af7c732ac324e82e2",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4A354",
//           "data": {
//               "r_link_local_port": "1/1/42",
//               "g_updated_at": "2023-08-21 02:12:49",
//               "unique_link_id": "e7833f44491af474227137c0d4324810eb3314627185941af7c732ac324e82e2",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:12:49",
//               "_id": 1726,
//               "g_status": "ACTIVE",
//               "_uid": "99f0149b-205d-4312-9561-f61ed31f8a3c",
//               "r_link_remote_chassis": "9C8CD8C4A354",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "2170c28b872e2e9bcbd077ff781cdc817b3cf3eefae17502eb549c719c67e5cc",
//           "source": "D4E0533E16C0",
//           "target": "9C8CD8C4C830",
//           "data": {
//               "r_link_local_port": "1/1/43",
//               "g_updated_at": "2023-08-21 02:13:00",
//               "unique_link_id": "2170c28b872e2e9bcbd077ff781cdc817b3cf3eefae17502eb549c719c67e5cc",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-08-21 02:13:00",
//               "_id": 1732,
//               "g_status": "ACTIVE",
//               "_uid": "99f014ac-1c3e-4dc8-93d2-419b5c8085a3",
//               "r_link_remote_chassis": "9C8CD8C4C830",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "350930f398a4e15f3c0f2ee26abcaf83c368ed94c3f9583ac7a1a3e1b680d740",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDD7FE",
//           "data": {
//               "r_link_local_port": "1/1/33",
//               "g_updated_at": "2023-12-10 08:34:01",
//               "unique_link_id": "350930f398a4e15f3c0f2ee26abcaf83c368ed94c3f9583ac7a1a3e1b680d740",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:34:01",
//               "_id": 2681,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe82d-2b10-4495-a576-0a29e2ccaa40",
//               "r_link_remote_chassis": "48B4C3CDD7FE",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "d646a6cddc38a30896c01538e1e1b2c364a4622cfbb763d4e0e8fe67f1e34f8a",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDCF3A",
//           "data": {
//               "r_link_local_port": "1/1/37",
//               "g_updated_at": "2023-12-10 08:34:17",
//               "unique_link_id": "d646a6cddc38a30896c01538e1e1b2c364a4622cfbb763d4e0e8fe67f1e34f8a",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:34:17",
//               "_id": 2682,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe845-89d4-4015-8eab-f9027e893277",
//               "r_link_remote_chassis": "48B4C3CDCF3A",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "6a064194db5c29848e1da9a578355fd1afce85e941fa34296ef435f1b5a188d1",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDDB6E",
//           "data": {
//               "r_link_local_port": "1/1/38",
//               "g_updated_at": "2023-12-10 08:34:36",
//               "unique_link_id": "6a064194db5c29848e1da9a578355fd1afce85e941fa34296ef435f1b5a188d1",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:34:36",
//               "_id": 2683,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe861-526e-4e57-bc0a-e8f8b0350529",
//               "r_link_remote_chassis": "48B4C3CDDB6E",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "857d80e3dfc623fe9aeea7ed881beb47cc2db83e24debd19adc621aad0341434",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDE8DC",
//           "data": {
//               "r_link_local_port": "1/1/39",
//               "g_updated_at": "2023-12-10 08:35:00",
//               "unique_link_id": "857d80e3dfc623fe9aeea7ed881beb47cc2db83e24debd19adc621aad0341434",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:35:00",
//               "_id": 2684,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe886-6645-4a87-8b8e-b31460ac3f10",
//               "r_link_remote_chassis": "48B4C3CDE8DC",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "21d34aa82ddb6560e70f3fc285348ebf72623901a4039dd70fe0cf009a3ed43c",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDACEA",
//           "data": {
//               "r_link_local_port": "1/1/41",
//               "g_updated_at": "2023-12-10 08:35:29",
//               "unique_link_id": "21d34aa82ddb6560e70f3fc285348ebf72623901a4039dd70fe0cf009a3ed43c",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:35:29",
//               "_id": 2686,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe8b2-59d9-48c8-9473-977a9d5b929a",
//               "r_link_remote_chassis": "48B4C3CDACEA",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "fae3ac46c038417fec804930b941fb56b4a59ec660e0fbddeca3e281b5d6dc86",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDAE84",
//           "data": {
//               "r_link_local_port": "1/1/42",
//               "g_updated_at": "2023-12-10 08:36:01",
//               "unique_link_id": "fae3ac46c038417fec804930b941fb56b4a59ec660e0fbddeca3e281b5d6dc86",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:36:01",
//               "_id": 2688,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe8e3-3353-403f-8718-239b713b314d",
//               "r_link_remote_chassis": "48B4C3CDAE84",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "325542af56b4c1ead5071edc67ddcd32bc54b6852903374bce33af203b223c19",
//           "source": "D4E0533E16C0",
//           "target": "48B4C3CDE54E",
//           "data": {
//               "r_link_local_port": "1/1/43",
//               "g_updated_at": "2023-12-10 08:36:33",
//               "unique_link_id": "325542af56b4c1ead5071edc67ddcd32bc54b6852903374bce33af203b223c19",
//               "r_link_remote_port": "eth0",
//               "r_link_local_chassis": "D4E0533E16C0",
//               "g_created_at": "2023-12-10 08:36:33",
//               "_id": 2690,
//               "g_status": "ACTIVE",
//               "_uid": "9acfe914-061f-4d53-8703-10cc0992adcd",
//               "r_link_remote_chassis": "48B4C3CDE54E",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "6181ee00e046d6cbe2b216bb735e7f12c2ece239a652192c5e07e1eab8cbc9d4",
//           "source": "48B4C3CDDB6E",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "bond0",
//               "g_updated_at": "2024-08-03 12:47:30",
//               "unique_link_id": "6181ee00e046d6cbe2b216bb735e7f12c2ece239a652192c5e07e1eab8cbc9d4",
//               "r_link_remote_port": "1/1/38",
//               "r_link_local_chassis": "48B4C3CDDB6E",
//               "g_created_at": "2024-08-03 12:47:30",
//               "_id": 3519,
//               "g_status": "ACTIVE",
//               "_uid": "9cad0611-9979-4925-8c21-bad654442b71",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "21ff1feb207d80d6b2661cc679ce2c850e29f16f4ba9ba86893f1d0947fe902a",
//           "source": "48B4C3CDE8DC",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "bond0",
//               "g_updated_at": "2024-08-03 20:28:42",
//               "unique_link_id": "21ff1feb207d80d6b2661cc679ce2c850e29f16f4ba9ba86893f1d0947fe902a",
//               "r_link_remote_port": "1/1/39",
//               "r_link_local_chassis": "48B4C3CDE8DC",
//               "g_created_at": "2024-08-03 20:28:42",
//               "_id": 3527,
//               "g_status": "ACTIVE",
//               "_uid": "9cadab00-9d23-4837-8d2d-9d93cf4e4fa7",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "e7d89523121e575c14915792b322a636f399526fa604a02424cbe42d58506eb6",
//           "source": "48B4C3CDE54E",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "bond0",
//               "g_updated_at": "2024-08-03 20:06:49",
//               "unique_link_id": "e7d89523121e575c14915792b322a636f399526fa604a02424cbe42d58506eb6",
//               "r_link_remote_port": "1/1/43",
//               "r_link_local_chassis": "48B4C3CDE54E",
//               "g_created_at": "2024-08-03 20:06:49",
//               "_id": 3525,
//               "g_status": "ACTIVE",
//               "_uid": "9cada32d-ebd5-4c85-aed0-fce02723ac5b",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "a5125f48d6067e31616690176e7761a6d03afb2a9a2a49bc6cfee6fa8ad2696c",
//           "source": "9C8CD8C4BAC2",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:07:08",
//               "unique_link_id": "a5125f48d6067e31616690176e7761a6d03afb2a9a2a49bc6cfee6fa8ad2696c",
//               "r_link_remote_port": "1/1/33",
//               "r_link_local_chassis": "9C8CD8C4BAC2",
//               "g_created_at": "2023-08-21 02:07:08",
//               "_id": 1626,
//               "g_status": "ACTIVE",
//               "_uid": "99f01293-fcb7-420d-9d50-e034982d2bb6",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "12c946dc817659dbb08f91aa2e9ef0f9ae1f0a75364a7171c9507df0a061d45a",
//           "source": "9C8CD8C4BC68",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:07:12",
//               "unique_link_id": "12c946dc817659dbb08f91aa2e9ef0f9ae1f0a75364a7171c9507df0a061d45a",
//               "r_link_remote_port": "1/1/41",
//               "r_link_local_chassis": "9C8CD8C4BC68",
//               "g_created_at": "2023-08-21 02:07:12",
//               "_id": 1629,
//               "g_status": "ACTIVE",
//               "_uid": "99f0129a-0058-4595-9711-678a1c584e6d",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "8bc91651374da6f412db748b3e9959e273b9cdf21a3d1f3da97089990e26dd76",
//           "source": "9C8CD8C4DF60",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:06:36",
//               "unique_link_id": "8bc91651374da6f412db748b3e9959e273b9cdf21a3d1f3da97089990e26dd76",
//               "r_link_remote_port": "1/1/37",
//               "r_link_local_chassis": "9C8CD8C4DF60",
//               "g_created_at": "2023-08-21 02:06:36",
//               "_id": 1622,
//               "g_status": "ACTIVE",
//               "_uid": "99f01262-c268-4bed-9fe8-e34adac8e1f4",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "58da47235ef813fe74bcf8d11c86eec17aaf39d8a5e41d400659a94a284dd0e7",
//           "source": "9C8CD8C4DF60",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "bond0",
//               "g_updated_at": "2024-08-06 03:09:59",
//               "unique_link_id": "58da47235ef813fe74bcf8d11c86eec17aaf39d8a5e41d400659a94a284dd0e7",
//               "r_link_remote_port": "1/1/42",
//               "r_link_local_chassis": "9C8CD8C4DF60",
//               "g_created_at": "2024-08-06 03:09:59",
//               "_id": 3602,
//               "g_status": "ACTIVE",
//               "_uid": "9cb2407c-0877-4edc-9a97-9252298ce0a0",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "0d38f84eea12f2e6debc0f14ad18d5337156c30b3e47332f745eb404bab85f25",
//           "source": "9C8CD8C4C46E",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:07:11",
//               "unique_link_id": "0d38f84eea12f2e6debc0f14ad18d5337156c30b3e47332f745eb404bab85f25",
//               "r_link_remote_port": "1/1/39",
//               "r_link_local_chassis": "9C8CD8C4C46E",
//               "g_created_at": "2023-08-21 02:07:11",
//               "_id": 1627,
//               "g_status": "ACTIVE",
//               "_uid": "99f01297-576e-4eb4-b094-39d91b3d4f4c",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "5c74c1c8a3ca0ebd35b1dd2b59ff57e2dd3b6e75aee1d884c8bfe034ad94dedc",
//           "source": "9C8CD8C4C46E",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "bond0",
//               "g_updated_at": "2024-08-05 22:04:04",
//               "unique_link_id": "5c74c1c8a3ca0ebd35b1dd2b59ff57e2dd3b6e75aee1d884c8bfe034ad94dedc",
//               "r_link_remote_port": "1/1/37",
//               "r_link_local_chassis": "9C8CD8C4C46E",
//               "g_created_at": "2024-08-05 22:04:04",
//               "_id": 3601,
//               "g_status": "ACTIVE",
//               "_uid": "9cb1d314-83af-4bf9-ad56-8971406e93a0",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "17e143bd2f64463b4916800ea7b3a74d28c339528630e8a3387a8b116837bc35",
//           "source": "9C8CD8C4C830",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:07:12",
//               "unique_link_id": "17e143bd2f64463b4916800ea7b3a74d28c339528630e8a3387a8b116837bc35",
//               "r_link_remote_port": "1/1/43",
//               "r_link_local_chassis": "9C8CD8C4C830",
//               "g_created_at": "2023-08-21 02:07:12",
//               "_id": 1628,
//               "g_status": "ACTIVE",
//               "_uid": "99f01299-a815-40af-88c1-7b7827639980",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "a50201941e66fbb7c7af67ad7e9f07ea9c96681e3371f001687bb834e4fff922",
//           "source": "9C8CD8C4A354",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:07:12",
//               "unique_link_id": "a50201941e66fbb7c7af67ad7e9f07ea9c96681e3371f001687bb834e4fff922",
//               "r_link_remote_port": "1/1/42",
//               "r_link_local_chassis": "9C8CD8C4A354",
//               "g_created_at": "2023-08-21 02:07:12",
//               "_id": 1629,
//               "g_status": "ACTIVE",
//               "_uid": "99f01299-d3ce-484b-a070-4c419b5c82c3",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "5031b6b7b6fc12d7a621361ad06dfd9bf7b132bab99c3162366011c2bac48e39",
//           "source": "9C8CD8C4CACA",
//           "target": "D4E0533E16C0",
//           "data": {
//               "r_link_local_port": "eth0",
//               "g_updated_at": "2023-08-21 02:06:55",
//               "unique_link_id": "5031b6b7b6fc12d7a621361ad06dfd9bf7b132bab99c3162366011c2bac48e39",
//               "r_link_remote_port": "1/1/38",
//               "r_link_local_chassis": "9C8CD8C4CACA",
//               "g_created_at": "2023-08-21 02:06:55",
//               "_id": 1625,
//               "g_status": "ACTIVE",
//               "_uid": "99f0127f-2dbd-4f88-91b6-c73d252800ff",
//               "r_link_remote_chassis": "D4E0533E16C0",
//               "model": "Neighbour"
//           },
//           "type": "floating"
//       },
//       {
//           "id": "INTERNETE8EDD6F28ABE",
//           "source": "INTERNET",
//           "target": "E8EDD6F28ABE",
//           "data": [],
//           "type": "floating"
//       }
//   ]
// }
//   }
//   return newPro.data;
// };

// export const getSitewiseTopology = async (id, data, controller) => {
//   const newPro = {
//     "data":{
//       "nodes": [
//           {
//               "id": "48B4C3CDACEA",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDACEA",
//                       "network_device_id": 2239,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP005",
//                       "r_dvc_node_mac_address": "48B4C3CDACEA",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": null,
//                       "r_dvc_ip_address": "10.26.54.99",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": null,
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654038",
//                       "r_dvc_serial_number": "CNPKLJ58HT",
//                       "r_dvc_uptime": "9:56:44.45",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "0",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDACEA",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDACEA_CNPKLJ58HT",
//                       "g_updated_at": 1724652295,
//                       "g_created_at": 1724615458
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDACEA",
//                   "network_device_id": 2239,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP005",
//                   "r_dvc_node_mac_address": "48B4C3CDACEA",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": null,
//                   "r_dvc_ip_address": "10.26.54.99",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": null,
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654038",
//                   "r_dvc_serial_number": "CNPKLJ58HT",
//                   "r_dvc_uptime": "9:56:44.45",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "0",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDACEA",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDACEA_CNPKLJ58HT",
//                   "g_updated_at": 1724652295,
//                   "g_created_at": 1724615458,
//                   "with_access_layer": false,
//                   "sibling_item": 0,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 0,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603297Z",
//               "g_created_at": "2024-08-28T13:48:08.603306Z"
//           },
//           {
//               "id": "48B4C3CDAE84",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDAE84",
//                       "network_device_id": 2245,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP006",
//                       "r_dvc_node_mac_address": "48B4C3CDAE84",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.100",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654040",
//                       "r_dvc_serial_number": "CNPKLJ58HK",
//                       "r_dvc_uptime": "9:56:53.26",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "100",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDAE84",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDAE84_CNPKLJ58HK",
//                       "g_updated_at": 1724652299,
//                       "g_created_at": 1724342033
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDAE84",
//                   "network_device_id": 2245,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP006",
//                   "r_dvc_node_mac_address": "48B4C3CDAE84",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.100",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654040",
//                   "r_dvc_serial_number": "CNPKLJ58HK",
//                   "r_dvc_uptime": "9:56:53.26",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "100",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDAE84",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDAE84_CNPKLJ58HK",
//                   "g_updated_at": 1724652299,
//                   "g_created_at": 1724342033,
//                   "with_access_layer": false,
//                   "sibling_item": 1,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 100,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603308Z",
//               "g_created_at": "2024-08-28T13:48:08.603309Z"
//           },
//           {
//               "id": "48B4C3CDCF3A",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDCF3A",
//                       "network_device_id": 2370,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP001",
//                       "r_dvc_node_mac_address": "48B4C3CDCF3A",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.95",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654257",
//                       "r_dvc_serial_number": "CNPKLJ58HT",
//                       "r_dvc_uptime": "9:56:43.99",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "200",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDCF3A",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDCF3A_CNPKLJ58HT",
//                       "g_updated_at": 1724652297,
//                       "g_created_at": 1724342033
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDCF3A",
//                   "network_device_id": 2370,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP001",
//                   "r_dvc_node_mac_address": "48B4C3CDCF3A",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.95",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654257",
//                   "r_dvc_serial_number": "CNPKLJ58HT",
//                   "r_dvc_uptime": "9:56:43.99",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "200",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDCF3A",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDCF3A_CNPKLJ58HT",
//                   "g_updated_at": 1724652297,
//                   "g_created_at": 1724342033,
//                   "with_access_layer": false,
//                   "sibling_item": 2,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 200,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603311Z",
//               "g_created_at": "2024-08-28T13:48:08.603313Z"
//           },
//           {
//               "id": "48B4C3CDCF3A",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDCF3A",
//                       "network_device_id": 2371,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP001",
//                       "r_dvc_node_mac_address": "48B4C3CDCF3A",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.70",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MANAGER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654257",
//                       "r_dvc_serial_number": "VCIP",
//                       "r_dvc_uptime": "9:56:55.50",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "300",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDCF3A",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDCF3A_VCIP",
//                       "g_updated_at": 1724652297,
//                       "g_created_at": 1724342032
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDCF3A",
//                   "network_device_id": 2371,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP001",
//                   "r_dvc_node_mac_address": "48B4C3CDCF3A",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.70",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MANAGER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654257",
//                   "r_dvc_serial_number": "VCIP",
//                   "r_dvc_uptime": "9:56:55.50",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "300",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDCF3A",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDCF3A_VCIP",
//                   "g_updated_at": 1724652297,
//                   "g_created_at": 1724342032,
//                   "with_access_layer": false,
//                   "sibling_item": 3,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 300,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603315Z",
//               "g_created_at": "2024-08-28T13:48:08.603316Z"
//           },
//           {
//               "id": "48B4C3CDD7FE",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDD7FE",
//                       "network_device_id": 2405,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP004",
//                       "r_dvc_node_mac_address": "48B4C3CDD7FE",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.96",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654268",
//                       "r_dvc_serial_number": "CNPKLJ58DB",
//                       "r_dvc_uptime": "9:57:18.88",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "400",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDD7FE",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDD7FE_CNPKLJ58DB",
//                       "g_updated_at": 1724652299,
//                       "g_created_at": 1724342033
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDD7FE",
//                   "network_device_id": 2405,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP004",
//                   "r_dvc_node_mac_address": "48B4C3CDD7FE",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.96",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654268",
//                   "r_dvc_serial_number": "CNPKLJ58DB",
//                   "r_dvc_uptime": "9:57:18.88",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "400",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDD7FE",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDD7FE_CNPKLJ58DB",
//                   "g_updated_at": 1724652299,
//                   "g_created_at": 1724342033,
//                   "with_access_layer": false,
//                   "sibling_item": 4,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 400,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603318Z",
//               "g_created_at": "2024-08-28T13:48:08.603320Z"
//           },
//           {
//               "id": "48B4C3CDDB6E",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDDB6E",
//                       "network_device_id": 2416,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP002",
//                       "r_dvc_node_mac_address": "48B4C3CDDB6E",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.97",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654271",
//                       "r_dvc_serial_number": "CNPKLJ58GX",
//                       "r_dvc_uptime": "9:56:53.65",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "500",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDDB6E",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDDB6E_CNPKLJ58GX",
//                       "g_updated_at": 1724652297,
//                       "g_created_at": 1724342031
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDDB6E",
//                   "network_device_id": 2416,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP002",
//                   "r_dvc_node_mac_address": "48B4C3CDDB6E",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.97",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654271",
//                   "r_dvc_serial_number": "CNPKLJ58GX",
//                   "r_dvc_uptime": "9:56:53.65",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "500",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDDB6E",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDDB6E_CNPKLJ58GX",
//                   "g_updated_at": 1724652297,
//                   "g_created_at": 1724342031,
//                   "with_access_layer": false,
//                   "sibling_item": 5,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 500,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603321Z",
//               "g_created_at": "2024-08-28T13:48:08.603324Z"
//           },
//           {
//               "id": "48B4C3CDE54E",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDE54E",
//                       "network_device_id": 2456,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP007",
//                       "r_dvc_node_mac_address": "48B4C3CDE54E",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.101",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654283",
//                       "r_dvc_serial_number": "CNPKLJ58GF",
//                       "r_dvc_uptime": "9:57:00.36",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "600",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDE54E",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDE54E_CNPKLJ58GF",
//                       "g_updated_at": 1724652295,
//                       "g_created_at": 1724361485
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDE54E",
//                   "network_device_id": 2456,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP007",
//                   "r_dvc_node_mac_address": "48B4C3CDE54E",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.101",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654283",
//                   "r_dvc_serial_number": "CNPKLJ58GF",
//                   "r_dvc_uptime": "9:57:00.36",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "600",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDE54E",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDE54E_CNPKLJ58GF",
//                   "g_updated_at": 1724652295,
//                   "g_created_at": 1724361485,
//                   "with_access_layer": false,
//                   "sibling_item": 6,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 600,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603326Z",
//               "g_created_at": "2024-08-28T13:48:08.603328Z"
//           },
//           {
//               "id": "48B4C3CDE8DC",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDE8DC",
//                       "network_device_id": 2471,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP003",
//                       "r_dvc_node_mac_address": "48B4C3CDE8DC",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.98",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654288",
//                       "r_dvc_serial_number": "CNPKLJ58FR",
//                       "r_dvc_uptime": "9:56:35.75",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": "10.26.54.70",
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "700",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDE8DC",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "48B4C3CDE8DC_CNPKLJ58FR",
//                       "g_updated_at": 1724652297,
//                       "g_created_at": 1724342032
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDE8DC",
//                   "network_device_id": 2471,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP003",
//                   "r_dvc_node_mac_address": "48B4C3CDE8DC",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.98",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654288",
//                   "r_dvc_serial_number": "CNPKLJ58FR",
//                   "r_dvc_uptime": "9:56:35.75",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": "10.26.54.70",
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "700",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDE8DC",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "48B4C3CDE8DC_CNPKLJ58FR",
//                   "g_updated_at": 1724652297,
//                   "g_created_at": 1724342032,
//                   "with_access_layer": false,
//                   "sibling_item": 7,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 700,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603330Z",
//               "g_created_at": "2024-08-28T13:48:08.603332Z"
//           },
//           {
//               "id": "48B4C3CDACEA",
//               "nodes": [
//                   {
//                       "node_id": "48B4C3CDACEA",
//                       "network_device_id": 8196,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_AP005",
//                       "r_dvc_node_mac_address": "48B4C3CDACEA",
//                       "l_dvc_l2_type": "ACCESS POINT",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                       "r_dvc_ip_address": "10.26.54.96",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "D4E0533E16FF"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": null,
//                       "r_dvc_controller": "VC MEMBER",
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724616252",
//                       "r_dvc_serial_number": null,
//                       "r_dvc_uptime": "2 days, 2:36:57.96",
//                       "l_dvc_typ_name": "ACCESS POINT",
//                       "r_dvc_model": "AP505",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "300",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": null,
//                       "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba AP500 Series",
//                       "position_x": "800",
//                       "l_dvc_l2_parents_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "l_dvc_l2_childrens": null,
//                       "r_dvc_mac_address": "48B4C3CDACEA",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": null,
//                       "r_dvc_unique_identifier": "10.26.54.96",
//                       "g_updated_at": 1724615458,
//                       "g_created_at": 1724342033
//                   }
//               ],
//               "data": {
//                   "node_id": "48B4C3CDACEA",
//                   "network_device_id": 8196,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_AP005",
//                   "r_dvc_node_mac_address": "48B4C3CDACEA",
//                   "l_dvc_l2_type": "ACCESS POINT",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": "VIRTUAL CONTROLLER",
//                   "r_dvc_ip_address": "10.26.54.96",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "D4E0533E16FF"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": null,
//                   "r_dvc_controller": "VC MEMBER",
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724616252",
//                   "r_dvc_serial_number": null,
//                   "r_dvc_uptime": "2 days, 2:36:57.96",
//                   "l_dvc_typ_name": "ACCESS POINT",
//                   "r_dvc_model": "AP505",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "300",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": null,
//                   "r_dvc_description": "ArubaOS (MODEL: 505), Version 8.11.2.2-8.11.2.2 SSR",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba AP500 Series",
//                   "position_x": "800",
//                   "l_dvc_l2_parents_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "l_dvc_l2_childrens": null,
//                   "r_dvc_mac_address": "48B4C3CDACEA",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": null,
//                   "r_dvc_unique_identifier": "10.26.54.96",
//                   "g_updated_at": 1724615458,
//                   "g_created_at": 1724342033,
//                   "with_access_layer": false,
//                   "sibling_item": 8,
//                   "sibling_count": 9
//               },
//               "type": "ACCESS POINT",
//               "position": {
//                   "x": 800,
//                   "y": 300
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603334Z",
//               "g_created_at": "2024-08-28T13:48:08.603335Z"
//           },
//           {
//               "id": "D4E0533E16FF",
//               "nodes": [
//                   {
//                       "node_id": "D4E0533E16FF",
//                       "network_device_id": 2851,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043-ASW01",
//                       "r_dvc_node_mac_address": "D4E0533E16FF",
//                       "l_dvc_l2_type": "CORE SWITCH",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": null,
//                       "r_dvc_ip_address": "10.26.54.66",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": [
//                           "E8EDD6F28ABE"
//                       ],
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": [
//                           "48B4C3CDACEA",
//                           "48B4C3CDAE84",
//                           "48B4C3CDCF3A",
//                           "48B4C3CDD7FE",
//                           "48B4C3CDDB6E",
//                           "48B4C3CDE54E",
//                           "48B4C3CDE8DC"
//                       ],
//                       "r_dvc_controller": null,
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724497051",
//                       "r_dvc_serial_number": "CN32L3J16N",
//                       "r_dvc_uptime": "5 days, 22:21:48.52",
//                       "l_dvc_typ_name": "SWITCH",
//                       "r_dvc_model": "R8N85A",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "180",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": null,
//                       "r_dvc_description": "Aruba R8N85A 6000 48G CL4 4SFP Swch PL.10.14.0007",
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Aruba",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "Aruba 6000 Series",
//                       "position_x": "460",
//                       "l_dvc_l2_parents_snmp": [
//                           "E8EDD6F28ABE"
//                       ],
//                       "l_dvc_l2_childrens": [
//                           "48B4C3CDACEA",
//                           "48B4C3CDAE84",
//                           "48B4C3CDCF3A",
//                           "48B4C3CDDB6E",
//                           "48B4C3CDD7FE",
//                           "48B4C3CDE54E",
//                           "48B4C3CDE8DC"
//                       ],
//                       "r_dvc_mac_address": "D4E0533E16FF",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "ArubaOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": "PL.10.14.0007",
//                       "r_dvc_unique_identifier": "D4E0533E16FF_CN32L3J16N",
//                       "g_updated_at": 1724266409,
//                       "g_created_at": 1724140277
//                   }
//               ],
//               "data": {
//                   "node_id": "D4E0533E16FF",
//                   "network_device_id": 2851,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043-ASW01",
//                   "r_dvc_node_mac_address": "D4E0533E16FF",
//                   "l_dvc_l2_type": "CORE SWITCH",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": null,
//                   "r_dvc_ip_address": "10.26.54.66",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": [
//                       "E8EDD6F28ABE"
//                   ],
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": [
//                       "48B4C3CDACEA",
//                       "48B4C3CDAE84",
//                       "48B4C3CDCF3A",
//                       "48B4C3CDD7FE",
//                       "48B4C3CDDB6E",
//                       "48B4C3CDE54E",
//                       "48B4C3CDE8DC"
//                   ],
//                   "r_dvc_controller": null,
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724497051",
//                   "r_dvc_serial_number": "CN32L3J16N",
//                   "r_dvc_uptime": "5 days, 22:21:48.52",
//                   "l_dvc_typ_name": "SWITCH",
//                   "r_dvc_model": "R8N85A",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "180",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": null,
//                   "r_dvc_description": "Aruba R8N85A 6000 48G CL4 4SFP Swch PL.10.14.0007",
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Aruba",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "Aruba 6000 Series",
//                   "position_x": "460",
//                   "l_dvc_l2_parents_snmp": [
//                       "E8EDD6F28ABE"
//                   ],
//                   "l_dvc_l2_childrens": [
//                       "48B4C3CDACEA",
//                       "48B4C3CDAE84",
//                       "48B4C3CDCF3A",
//                       "48B4C3CDDB6E",
//                       "48B4C3CDD7FE",
//                       "48B4C3CDE54E",
//                       "48B4C3CDE8DC"
//                   ],
//                   "r_dvc_mac_address": "D4E0533E16FF",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "ArubaOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": "PL.10.14.0007",
//                   "r_dvc_unique_identifier": "D4E0533E16FF_CN32L3J16N",
//                   "g_updated_at": 1724266409,
//                   "g_created_at": 1724140277,
//                   "with_access_layer": false,
//                   "sibling_item": 0,
//                   "sibling_count": 1
//               },
//               "type": "SWITCH",
//               "position": {
//                   "x": 415,
//                   "y": 180
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603337Z",
//               "g_created_at": "2024-08-28T13:48:08.603338Z"
//           },
//           {
//               "id": "E8EDD6F28ABE",
//               "nodes": [
//                   {
//                       "node_id": "E8EDD6F28ABE",
//                       "network_device_id": 3942,
//                       "g_status": "ACTIVE",
//                       "location_id": 735,
//                       "r_dvc_name": "IN1043_FW01.DECATHLONIN.NET",
//                       "r_dvc_node_mac_address": "E8EDD6F28ABE",
//                       "l_dvc_l2_type": "FIREWALL",
//                       "r_dvc_ha_enabled": null,
//                       "r_dvc_mgmt_type": null,
//                       "r_dvc_ip_address": "10.26.55.248",
//                       "r_dvc_config_latest_failed_at": null,
//                       "l_dvc_l2_parents": null,
//                       "rp_dvc_serial_number": null,
//                       "g_deleted_at": null,
//                       "l_dvc_l2_child_snmp": [
//                           "D4E0533E16FF"
//                       ],
//                       "r_dvc_controller": null,
//                       "r_dvc_network": null,
//                       "r_dvc_last_up_ts": "1724654546",
//                       "r_dvc_serial_number": "FGT60FTK21099WEX",
//                       "r_dvc_uptime": "10:01:50.56",
//                       "l_dvc_typ_name": "FIREWALL",
//                       "r_dvc_model": "FortiGate 60F",
//                       "r_dvc_config_backup_status": null,
//                       "r_dvc_type": "NETWORK",
//                       "r_dvc_pingable": 1,
//                       "position_y": "60",
//                       "r_dvc_eos": null,
//                       "r_dvc_is_vulnerable": null,
//                       "r_dvc_mgmt_ip": null,
//                       "r_dvc_description": null,
//                       "r_dvc_error": null,
//                       "r_dvc_ha_type": null,
//                       "r_dvc_config_latest_success_at": null,
//                       "r_dvc_backupable": null,
//                       "r_dvc_last_down_ts": null,
//                       "r_dvc_ha_item_count": null,
//                       "r_dvc_status_inactive_since": null,
//                       "r_dvc_eol": null,
//                       "l_dvc_typ_vendor": "Fortinet",
//                       "r_dvc_up_down": "UP",
//                       "r_dvc_family": "FortiGate Series",
//                       "position_x": "465",
//                       "l_dvc_l2_parents_snmp": null,
//                       "l_dvc_l2_childrens": [
//                           "D4E0533E16FF"
//                       ],
//                       "r_dvc_mac_address": "E8EDD6F28ABE",
//                       "r_dvc_type_source": "DC",
//                       "r_dvc_os": "FortiOS",
//                       "r_dvc_host": null,
//                       "r_dvc_firmware": "v7.4.4,build2662,240514 (GA.F)",
//                       "r_dvc_unique_identifier": "E8EDD6F28ABE_FGT60FTK21099WEX",
//                       "g_updated_at": 1724652299,
//                       "g_created_at": 1724361487
//                   }
//               ],
//               "data": {
//                   "node_id": "E8EDD6F28ABE",
//                   "network_device_id": 3942,
//                   "g_status": "ACTIVE",
//                   "location_id": 735,
//                   "r_dvc_name": "IN1043_FW01.DECATHLONIN.NET",
//                   "r_dvc_node_mac_address": "E8EDD6F28ABE",
//                   "l_dvc_l2_type": "FIREWALL",
//                   "r_dvc_ha_enabled": null,
//                   "r_dvc_mgmt_type": null,
//                   "r_dvc_ip_address": "10.26.55.248",
//                   "r_dvc_config_latest_failed_at": null,
//                   "l_dvc_l2_parents": null,
//                   "rp_dvc_serial_number": null,
//                   "g_deleted_at": null,
//                   "l_dvc_l2_child_snmp": [
//                       "D4E0533E16FF"
//                   ],
//                   "r_dvc_controller": null,
//                   "r_dvc_network": null,
//                   "r_dvc_last_up_ts": "1724654546",
//                   "r_dvc_serial_number": "FGT60FTK21099WEX",
//                   "r_dvc_uptime": "10:01:50.56",
//                   "l_dvc_typ_name": "FIREWALL",
//                   "r_dvc_model": "FortiGate 60F",
//                   "r_dvc_config_backup_status": null,
//                   "r_dvc_type": "NETWORK",
//                   "r_dvc_pingable": 1,
//                   "position_y": "60",
//                   "r_dvc_eos": null,
//                   "r_dvc_is_vulnerable": null,
//                   "r_dvc_mgmt_ip": null,
//                   "r_dvc_description": null,
//                   "r_dvc_error": null,
//                   "r_dvc_ha_type": null,
//                   "r_dvc_config_latest_success_at": null,
//                   "r_dvc_backupable": null,
//                   "r_dvc_last_down_ts": null,
//                   "r_dvc_ha_item_count": null,
//                   "r_dvc_status_inactive_since": null,
//                   "r_dvc_eol": null,
//                   "l_dvc_typ_vendor": "Fortinet",
//                   "r_dvc_up_down": "UP",
//                   "r_dvc_family": "FortiGate Series",
//                   "position_x": "465",
//                   "l_dvc_l2_parents_snmp": null,
//                   "l_dvc_l2_childrens": [
//                       "D4E0533E16FF"
//                   ],
//                   "r_dvc_mac_address": "E8EDD6F28ABE",
//                   "r_dvc_type_source": "DC",
//                   "r_dvc_os": "FortiOS",
//                   "r_dvc_host": null,
//                   "r_dvc_firmware": "v7.4.4,build2662,240514 (GA.F)",
//                   "r_dvc_unique_identifier": "E8EDD6F28ABE_FGT60FTK21099WEX",
//                   "g_updated_at": 1724652299,
//                   "g_created_at": 1724361487,
//                   "with_access_layer": false,
//                   "sibling_item": 0,
//                   "sibling_count": 1
//               },
//               "type": "FIREWALL",
//               "position": {
//                   "x": 420,
//                   "y": 60
//               },
//               "g_updated_at": "2024-08-28T13:48:08.603339Z",
//               "g_created_at": "2024-08-28T13:48:08.603341Z"
//           },
//           {
//               "id": "INTERNET",
//               "type": "INTERNET",
//               "position": {
//                   "x": 430,
//                   "y": -50
//               },
//               "data": {
//                   "r_dvc_host": "INTERNET",
//                   "l_dvc_typ_name": "INTERNET",
//                   "children_count": 1,
//                   "r_dvc_up_down": "UP"
//               }
//           }
//       ],
//       "edges": [
//           {
//               "id": "11",
//               "source": "E8EDD6F28ABE",
//               "target": "D4E0533E16FF",
//               "data": {
//                   "neighbour_id": 1,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "E8EDD6F28ABE",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "11",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "D4E0533E16FF",
//                   "g_updated_at": 1724760776,
//                   "g_created_at": 1724760776
//               },
//               "type": "floating"
//           },
//           {
//               "id": "12",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDACEA",
//               "data": {
//                   "neighbour_id": 2,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "12",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDACEA",
//                   "g_updated_at": 1724761286,
//                   "g_created_at": 1724761286
//               },
//               "type": "floating"
//           },
//           {
//               "id": "13",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDAE84",
//               "data": {
//                   "neighbour_id": 3,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "13",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDAE84",
//                   "g_updated_at": 1724761356,
//                   "g_created_at": 1724761356
//               },
//               "type": "floating"
//           },
//           {
//               "id": "14",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDCF3A",
//               "data": {
//                   "neighbour_id": 4,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "14",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDCF3A",
//                   "g_updated_at": 1724761382,
//                   "g_created_at": 1724761382
//               },
//               "type": "floating"
//           },
//           {
//               "id": "15",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDD7FE",
//               "data": {
//                   "neighbour_id": 5,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "15",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDD7FE",
//                   "g_updated_at": 1724761414,
//                   "g_created_at": 1724761414
//               },
//               "type": "floating"
//           },
//           {
//               "id": "16",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDDB6E",
//               "data": {
//                   "neighbour_id": 6,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "16",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDDB6E",
//                   "g_updated_at": 1724761432,
//                   "g_created_at": 1724761432
//               },
//               "type": "floating"
//           },
//           {
//               "id": "17",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDE54E",
//               "data": {
//                   "neighbour_id": 7,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "17",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDE54E",
//                   "g_updated_at": 1724761458,
//                   "g_created_at": 1724761458
//               },
//               "type": "floating"
//           },
//           {
//               "id": "18",
//               "source": "D4E0533E16FF",
//               "target": "48B4C3CDE8DC",
//               "data": {
//                   "neighbour_id": 8,
//                   "g_status": "ACTIVE",
//                   "r_link_local_chassis": "D4E0533E16FF",
//                   "r_link_local_port": "3",
//                   "unique_link_id": "18",
//                   "r_link_remote_port": "port16",
//                   "r_link_remote_chassis": "48B4C3CDE8DC",
//                   "g_updated_at": 1724761477,
//                   "g_created_at": 1724761477
//               },
//               "type": "floating"
//           },
//           {
//               "id": "INTERNETE8EDD6F28ABE",
//               "source": "INTERNET",
//               "target": "E8EDD6F28ABE",
//               "data": [],
//               "type": "floating"
//           }
//       ]
//   }
//   }
//   return newPro.data;
// };

export const getGlobalTopology = async (id, data, controller) => {
  const newPro = modem.post(`/${id}/network/topology/global`, data, {
    // signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};


export const getChildrensL2 = async (id, locationData, parentId, parentType, except) => {
  const data = {
    location_uuids: locationData,
    parent_id: parentId,
    l_dvc_typ_name: parentType,
    except: except
  }
  const newPro = modem.post(`/${id}/network/topology/children`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getSiblingsL2 = async (id, parentId, parentType, controller) => {
  const data = {
    parentId,
    parentType
  }
  const newPro = modem.post(`/${id}/network/topology/sibling`, data, {
    signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};