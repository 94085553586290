import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button, Dialog,
  DialogActions, DialogContent, DialogTitle, Divider,
  IconButton, LinearProgress, List, Table, TableBody,
  TableCell,
  TableHead, TableRow, Typography, TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDropzone } from 'react-dropzone';
import { addCredentials } from '../../../API/Credentials/listCredentials';
import FiletoDownload from '../../../Assets/Credentials_Template.xlsx';
import { UserContext } from '../../../context/UserContext/userContext';
import * as XLSX from 'xlsx';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function UploadCredential(props) {
  const classes = useStyles();
  const { openDialog, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [sheetData, setSheetData] = useState([]); // Store data from all sheets
  const [currentSheetIndex, setCurrentSheetIndex] = useState(0); // To track current sheet being viewed
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [hasErrors, setHasErrors] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]); // To track which fields have errors
  const [loading, setLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false); // New state for tracking upload completion
  const [uploadComplete, setUploadComplete] = useState(Array(sheetData.length).fill(false)); // Track upload state for each sheet


  // --------------------------------------------------------------------------------------------
  const CSV_SEPARATER = ',';

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const allSheetsData = [];
        const allFilteredData = []; // Create an array to hold filtered data for all sheets
        for (let i = 1; i <= 5; i++) {
          const sheetName = workbook.SheetNames[i];
          const sheet = workbook.Sheets[sheetName];

          const sheetJson = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract the header from the first row
          const header = [...sheetJson[0]];

          if (i === 1) {
            header.push('Credential_type', 'SNMP_Version', 'message');
          } else if (i === 2) {
            header.push('Credential_type', 'SNMP_Version', 'message');
          } else if (i === 3) {
            header.push('Credential_type', 'SNMP_Version', 'message');
          } else {
            header.push('Credential_type', 'message');
          }

          // Skip the header and filter out empty rows for the remaining data
          const parsedSheet = sheetJson.slice(1).filter(row =>
            row.some(cell => cell !== undefined && cell.toString().trim() !== "")
          );

          // Add Credential_type and SNMP_Version based on the current sheet
          const updatedParsedSheet = parsedSheet.map((row) => {
            let credentialType = '';
            let snmpVersion = '';

            if (i === 1) { // Sheet 2
              credentialType = 'SNMP';
              snmpVersion = 'SNMPV1';
            } else if (i === 2) { // Sheet 3
              credentialType = 'SNMP';
              snmpVersion = 'SNMPV2';
            } else if (i === 3) { // Sheet 4
              credentialType = 'SNMP';
              snmpVersion = 'SNMPV3';
            } else if (i === 4) { // Sheet 5
              credentialType = 'SSH';
              snmpVersion = ''; // No SNMP_Version for SSH
            } else if (i === 5) { // Sheet 6
              credentialType = 'API_KEY';
              snmpVersion = ''; // No SNMP_Version for API_KEY
            }

            // Return the updated row, including Credential_type and SNMP_Version for SNMP sheets
            return i <= 3
              ? [...row, credentialType, snmpVersion] // For SNMP sheets
              : [...row, credentialType]; // For SSH and API_KEY
          });

          // Map updatedParsedSheet to header and create objects [{header1: value1, header2: value2, ...}]
          const mappedData = updatedParsedSheet.map((row) => {
            const rowObject = {};
            header.forEach((col, index) => {
              rowObject[col] = row[index] || ""; // Map each header to the corresponding row data, default to empty string if undefined
            });
            return rowObject;
          });

          allSheetsData.push({ header, data: updatedParsedSheet });

          allFilteredData.push(...mappedData); // Push the mapped data to allFilteredData
        }

        setSheetData(allSheetsData); // Store all sheets' data
        setFilteredData(allFilteredData); // Set filtered data from all sheets
        setShowText(false);
        setShowTable(true);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  // Navigate to the next sheet
  // const handleNext = () => {
  //   setCurrentSheetIndex((prevIndex) => (prevIndex < sheetData.length - 1 ? prevIndex + 1 : prevIndex));
  // };
  const validateFields = () => {
    const currentSheet = sheetData[currentSheetIndex].data;
    const errors = [];

    // Iterate over each row of the current sheet
    currentSheet.forEach((row, rowIndex) => {
      const rowErrors = [];

      row.forEach((cell, cellIndex) => {
        const header = sheetData[currentSheetIndex].header[cellIndex];

        if (header !== 'No' && header !== 'message') {
          // Handle validation for different types of cells
          let cellValue = cell || ''; // Ensure null/undefined is treated as an empty string

          // If cell is an object (e.g., an array, number, or something else), convert to a string
          if (typeof cellValue !== 'string') {
            cellValue = String(cellValue);
          }

          // Perform trim validation only on strings
          if (cellValue.trim() === '') {
            rowErrors[cellIndex] = true; // Mark as error
          } else {
            rowErrors[cellIndex] = false; // No error
          }
        }
      });

      errors.push(rowErrors); // Collect errors for each row
    });

    setFieldErrors(errors); // Update state
    setHasErrors(errors.some(row => row.includes(true))); // Check if there are any errors
  };

  const handleNext = () => {
    if (currentSheetIndex < sheetData.length - 1) {
      setCurrentSheetIndex((prevIndex) => prevIndex + 1);
      setIsUploadComplete(false);
    } else {
      enqueueSnackbar("All sheets processed", {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };


  // Navigate to the previous sheet
  const handlePrevious = () => {
    setCurrentSheetIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };
  // Render the current sheet data
  const renderTable = (sheet) => (
    <Table>
      <TableHead>
        <TableRow>
          {sheet.header?.map((headerCell, index) => (
            <TableCell key={index}>{headerCell}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sheet.data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <TableCell key={cellIndex}>
                {sheet.header[cellIndex] === 'No' ? (
                  <span>{cell || ""}</span> // Non-editable 'No' field
                ) : sheet.header[cellIndex] === 'message' ? (
                  <span
                    style={{
                      color: cell === "Successfully Created" ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {cell || ""}
                  </span> // Non-editable 'message' field with styling
                ) : sheet.header[cellIndex] === 'Credential_type' || sheet.header[cellIndex] === 'SNMP_Version' ? (
                  <span>{cell || ""}</span> // Non-editable Credential_type and SNMP_Version fields
                ) : (
                  <TextField
                    value={cell || ""}
                    onChange={(e) => handleCellChange(e, rowIndex, cellIndex)} // Handle changes for other fields
                    variant="outlined"
                    fullWidth
                    required
                    error={fieldErrors[rowIndex]?.[cellIndex] || false} // Highlight field if error
                    helperText={
                      fieldErrors[rowIndex]?.[cellIndex]
                        ? `${sheet.header[cellIndex]} is required` // Dynamic field name in the error message
                        : ''
                    }
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const handleCellChange = (e, rowIndex, cellIndex) => {
    const updatedSheetData = [...sheetData];
    const currentSheet = updatedSheetData[currentSheetIndex].data;

    // Update the specific cell in the current sheet
    const newValue = e.target.value;
    currentSheet[rowIndex][cellIndex] = newValue;

    // Set the updated sheet data back to the state
    setSheetData(updatedSheetData);

    // Run validation to check for errors
    validateFields();
  };

  // const handleSubmit = async (value) => {
  //   console.log("value", value);

  //   setLoading(true);
  //   try {
  //     if (filteredData.length === 0) {
  //       throw new Error("No credentials to add");
  //     }
  // console.log("filteredData",filteredData);

  //     await Promise.all(
  //       filteredData.map(async (item) => {
  //         console.log("here",item);
  //         await _addCredentials(item);
  //         return item;
  //       })
  //     );

  //     setLoading(false);
  //     enqueueSnackbar('Credentials added successfully', {
  //       variant: 'success',
  //       anchorOrigin: {
  //         vertical: 'bottom',
  //         horizontal: 'right',
  //       },
  //     });
  //     setIsUploadComplete(true); // Set upload completion to true after successful upload

  //   } catch (error) {
  //     setLoading(false);
  //     enqueueSnackbar(error.response?.data?.message || error.message, {
  //       variant: 'error',
  //       anchorOrigin: {
  //         vertical: 'bottom',
  //         horizontal: 'right',
  //       },
  //     });
  //   }
  // };

  // const handleSubmit = async () => {
  //   const currentSheetData = sheetData[currentSheetIndex].data;

  //   if (currentSheetData.length === 0) {
  //     enqueueSnackbar("No credentials to add", {
  //       variant: "warning",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right",
  //       },
  //     });
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const currentSheetFilteredData = currentSheetData.map((row) => {
  //       const rowObject = {};
  //       sheetData[currentSheetIndex].header.forEach((headerCell, index) => {
  //         rowObject[headerCell] = row[index] || "";
  //       });
  //       return rowObject;
  //     });


  //     const updatedSheetData = await Promise.all(
  //       currentSheetFilteredData.map(async (item) => {
  //         try {
  //           const response = await _addCredentials(item);
  //           item.message = "Successfully Created";
  //           return item;
  //         } catch (error) {
  //           item.message = error.response?.data?.message || error.message;
  //           return item;
  //         }
  //       })
  //     );

  //     // Check if all rows were successfully created
  //     const allSuccess = updatedSheetData.every((item) => item.message === "Successfully Created");

  //     // Update the sheetData with new data containing messages
  //     const updatedSheets = [...sheetData];
  //     updatedSheets[currentSheetIndex].data = updatedSheetData.map((item) =>
  //       sheetData[currentSheetIndex].header.map((header) => item[header] || "")
  //     );

  //     setSheetData(updatedSheets);

  //     if (allSuccess) {
  //       setIsUploadComplete(true); // Only allow "Next" if all are successfully created
  //     } else {
  //       setIsUploadComplete(false); // Prevent "Next" if there are any errors
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error.response?.data?.message || error.message, {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right",
  //       },
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async () => {
    const currentSheetData = sheetData[currentSheetIndex].data;

    if (currentSheetData.length === 0) {
      enqueueSnackbar("No credentials to add", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }

    setLoading(true);
    try {
      const currentSheetFilteredData = currentSheetData.map((row) => {
        const rowObject = {};
        sheetData[currentSheetIndex].header.forEach((headerCell, index) => {
          rowObject[headerCell] = row[index] || "";
        });
        return rowObject;
      });

      const updatedSheetData = await Promise.all(
        currentSheetFilteredData.map(async (item) => {
          try {
            const response = await _addCredentials(item);
            item.message = "Successfully Created";
            return item;
          } catch (error) {
            item.message = error.response?.data?.message || error.message;
            return item;
          }
        })
      );

      // Update sheetData with new data containing messages
      const updatedSheets = [...sheetData];
      updatedSheets[currentSheetIndex].data = updatedSheetData.map((item) =>
        sheetData[currentSheetIndex].header.map((header) => item[header] || "")
      );

      setSheetData(updatedSheets);

      const newUploadComplete = [...uploadComplete];
      newUploadComplete[currentSheetIndex] = true; // Mark the current sheet as completed
      setUploadComplete(newUploadComplete);

      setIsUploadComplete(true); // Allow navigation to the next sheet
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const _addCredentials = async (value) => {
    let data = {
      type: value.Credential_type,
    };
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV1') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        snmp_string: value.SNMPV1_String,
      }
    }
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV2') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        snmp_community: value.SNMPV2_Community,
      }
    }
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV3') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        user_snmpv3: value.SNMPV3_User,
        security_level_snmpv3: value.SNMPV3_Security_level,
        auth_type_snmpv3: isEmpty(value.SNMPV3_Auth_Type) ? '' : value.SNMPV3_Auth_Type,
        auth_type_passphrase_snmpv3: isEmpty(value.SNMPV3_Auth_Type_password) ? '' : value.SNMPV3_Auth_Type_password,
        priv_type_snmpv3: isEmpty(value.SNMPV3_Priv_Type) ? '' : value.SNMPV3_Priv_Type,
        priv_type_passphrase_snmpv3: isEmpty(value.SNMPV3_Priv_Type_Password) ? '' : value.SNMPV3_Priv_Type_Password,
      };
    }
    if (value.Credential_type === 'API_KEY') {
      data = {
        ...data,
        api_key: value.APIkey_String,
        api_endpoint: value.API_Endpoint,
      }
    }
    if (value.Credential_type === 'SSH') {
      data = {
        ...data,
        ssh_user: value.SSH_User,
        ssh_password: value.SSH_Password,
        ssh_port: value.SSH_Port,
      }
    }
    return addCredentials(customerId, data);
  };


  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    },
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  const sheetTitles = [
    'SNMP Version 1',
    'SNMP Version 2',
    'SNMP Version 3',
    'SSH',
    'API'
  ];
  // --------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Upload Credentials File
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        {loading && <LinearProgress />}
        <Divider />
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Box
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation();
                document.querySelector('input[type="file"]').click();
              },
              style,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? 'Drop file here!' : 'Click here or drag and drop the file to upload!'}
            {isDragReject && 'File type not supported!'}
          </Box>
          <Box sx={{ py: 2 }}>
            {showText && (
              <Box>
                <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
                  File information will be shown here after the import
                </Typography>
              </Box>
            )}
            {showTable && (
              <div>
                <Typography variant="h6">Preview: {sheetTitles[currentSheetIndex]}</Typography>

                {renderTable(sheetData[currentSheetIndex])}

                {/* Container for buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                  {/* Left: Previous and Next buttons */}
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                      variant="contained"
                      onClick={handlePrevious}
                      disabled={currentSheetIndex === 0}
                    >
                      <ArrowBackIcon />
                    </Button>

                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={currentSheetIndex === sheetTitles.length - 1}
                    >
                      <ArrowForwardIcon />
                    </Button>
                  </div>

                  {/* Right: Save & Upload button */}
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={uploadComplete[currentSheetIndex] || loading}
                  >
                    Save & Upload
                  </LoadingButton>

                </div>
              </div>
            )}

          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button>
            <a href={FiletoDownload} download="Credential_template.xlsx">
              Download sample file
            </a>
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>


    </>
  );
}