import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import NewLogoWithoutText from '../Assets/LinkEyeLogowithouttext.svg';

// ----------------------------------------------------------------------

LogoWithoutText.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoWithoutText({ disabledLink = false, sx }) {

  // const logo = (
  //   <>
  //     <Box sx={{ width: 130, height: 30, ...sx }}>
  //       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 443.8 100" style={{ enableBackground: "new 0 0 443.8 100" }} xmlSpace="preserve">
  //         <g>
  //           <g>
  //             <rect x="-31.1" y="46.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -43.8706 62.5757)" fill={'#FFC200'} width="80.8" height="13.5" />
  //             <rect x="47.1" y="66.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -5.5515 140.7907)" fill={'#FFC200'} width="40.9" height="13.5" />
  //             <rect x="38.2" y="5.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 10.1628 79.6072)" fill={'#FFC200'} width="13.5" height="58.9" />
  //             <rect x="44.6" y="1.5" fill={'#FFC200'} width="29.8" height="13.5" />
  //             <rect x="-10" y="43.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -11.53 88.5021)" fill={'#FFC200'} width="97.1" height="13.5" />
  //           </g>
  //         </g>
  //       </svg>
  //     </Box>
  //   </>
  // );

  const newLogo = (
    <>
      <Box sx={{ width: 150, height: 30,display:'flex',justifyContent:'center',alignItems:'center', ...sx }}>
        <img src={NewLogoWithoutText} alt="logo only" />
      </Box>
    </>
  );

  if (disabledLink) {
    return <>{newLogo}</>;
  }

  return <RouterLink to="/dashboard/dashboard">{newLogo}</RouterLink>;
}
