import { ContentCopy, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import TooltipRenderer from '../../../../utils/TooltipRenderer';
import DeleteDialog from '../DeleteDialog';


const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
    minWidth: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '5px',
    gap: 8,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
}));

const IpRenderer = (props) => {
  const { data } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  if (data === undefined) {
    return '';
  }

  if (data === undefined) {
    return '';
  }
  if (data?.r_dvc_ip_address) {
    return (
      <div className={classes.root}>
        {data?.r_dvc_ip_address}
        <span className={classes.field}>
          {TooltipRenderer(data?.r_dvc_mgmt_ip, data?.r_dvc_mgmt_type,data?.r_dvc_controller)}
          <CopyToClipboard
            text={data?.r_dvc_ip_address}
            options={{ format: 'text/plain' }}
            onCopy={(e) => onCopyText(e, data?.r_dvc_ip_address)}
          >
            <Tooltip title={'Click to copy'}>
              <IconButton size="small" className={classes.icon}>
                <ContentCopy fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
          <Box>
            {data?.r_dvc_type_source === 'MANUAL' ? (
              <Tooltip title={'Delete'} >
                <Button
                  className={classes.icon}
                  startIcon={<Delete style={{ color: 'black' }} />}
                  onClick={() => handleOpenDeleteDialog()}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          {openDeleteDialog && (
            <DeleteDialog
              data={data}
              openDialog={openDeleteDialog}
              handleClose={() => {
                setOpenDeleteDialog(false);
                props.api.purgeInfiniteCache();
              }}
            />
          )}
        </span>
      </div>
    );
  }
};

IpRenderer.propTypes = {
  data: PropTypes.object,
};

export default IpRenderer;
