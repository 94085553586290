/* eslint-disable */
const statCounter = (
  state = {
    refreshStats: true,
  },
  {type} = {}
) => {
  if(!window.navigator.onLine){
    return state;
  }
  switch (type) {
    case 'TOGGLE':
      return {
        ...state,
        refreshStats: !state.refreshStats,
      };
    default:
      return state;
  }
};

export default statCounter;
