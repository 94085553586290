import { Grid } from '@mui/material';
import { useState } from 'react';
import AppExperienceChart from './AppExperienceChart';
import LANChart from './LANChart';
import LocationSelect from './LocationSelect';
import ServicesChart from './ServicesChart';
import SiteHealthChart from './SiteHealthChart';
import UserExperienceChart from './UserExperienceChart';
import WANChart from './WANChart';
import WifiChart from './WifiChart';


// ----------------------------------------------------------------------

export default function NetworkHealthComponent() {
  const [ locationData, setLocationData ] = useState([{ code: 'AD', label: 'Location1', phone: '376', id: 1 }])

  const selectLocation = (value)=> {
    setLocationData(value)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
        <hr />
        <br />
        <LocationSelect selectLocation={selectLocation} />
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <UserExperienceChart locationData={locationData} />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AppExperienceChart locationData={locationData} />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <SiteHealthChart locationData={locationData} />
        </Grid>
      </Grid>

      <Grid container item spacing={4}>
        <Grid item xs={12} md={6} lg={3}>
          <ServicesChart locationData={locationData}/>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <WifiChart locationData={locationData} />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LANChart locationData={locationData}/>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <WANChart locationData={locationData}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
