import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'simplebar-react/dist/simplebar.min.css';
import './pages/Pages.css';
import './styles/aggrid-styling.css';
import 'react-grid-layout/css/styles.css';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import allReducers from './reducer/index';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import "./utils/browserSupports"
import { HealthProvider } from './components/FirewallHealth/components/userContextHealth';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(allReducers, applyMiddleware(thunk));

const msalConfig = {
  auth: {
    clientId: '78fa7565-8a12-4ebd-8cdc-514488af1934', 
    authority: 'https://login.microsoftonline.com/0644003f-0b3f-4517-814d-768fa69ab4ae', 
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // set to true if you are having issues on IE11 or Edge
  }
};

const pca = new PublicClientApplication(msalConfig);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="1053302908226-3oadht4ksd655neaqfr26ji07npbjnf2.apps.googleusercontent.com">
        <MsalProvider instance={pca}>
          <HealthProvider>
            <App />
          </HealthProvider>
        </MsalProvider>
        </GoogleOAuthProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
