// material
import { Grid } from '@mui/material';
import { AddDeleteEmailCard } from './Components';
// ----------------------------------------------------------------------

export default function EmailConfigComponents() {
  return (
    <>
      <Grid container>
        <Grid item sm={12} lg={12} md={12}>
          <AddDeleteEmailCard />
        </Grid>
      </Grid>
    </>
  );
}
