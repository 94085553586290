/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  getBezierPath,
  BaseEdge,
  EdgeLabelRenderer,
  useStore,
  useEdges,
  getStraightPath,
} from "reactflow";
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { getEdgeParams } from './utils';
// -----------------------------------------------

const useStyles = makeStyles(() => ({
  up: {
    color: 'green',
    "&:before": {
      "content": "",
      "width": "4px",
      "height": "4px",
      "background": "green",
      "display": "inline-block",
      "border-radius": "50%",
      "margin-right": "2px",
    }
  },
  down: {
    color: 'red',
    "&:before": {
      "content": "",
      "width": "4px",
      "height": "4px",
      "background": "red",
      "display": "inline-block",
      "border-radius": "50%",
      "margin-right": "2px",
    }
  }
}));

export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY }, offset) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX + offset} ${centerY + offset} ${targetX} ${targetY}`;
};

// -------------------------------------------
export const GlobalFloatingEdge = React.memo(
  ({
    id,
    data,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    style,
  }) => {
    const edges = useEdges();
    const classess = useStyles();
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);
    const edgePathParams = {
      id,
      source,
      target,
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx === sx ? tx + 1 : tx,
      targetY: ty === sy ? ty + 1 : ty,
    };


    const [vpnPath, setVpnPath] = useState("");
    const [vpnPathStatus, setVpnPathStatus] = useState("");
    const [vpnStatus, setVpnStatus] = useState("rgb(0, 128, 0)");

    const calculateEdgeProps = () => {
      let path = ""
      let r_link_status = [data.r_link_status]
      if (data.expandEdges) {
        path = getSpecialPath(edgePathParams, data.expandEdges * 20);
        setVpnPath(path)
      } else {
        [path] = getBezierPath({ ...edgePathParams, curvature: 20 });
        setVpnPath(path)

        const _sourceEdges = edges.filter((e) => e.source === source && e.target === target)
        r_link_status = new Set([..._sourceEdges.map(i => i.data.r_link_status)]);
      }

      if (r_link_status.size > 1) {
        setVpnPathStatus('url("#few-down")')
      } else {
        r_link_status = Array.from(r_link_status);
        setVpnPathStatus(`url("#all-${r_link_status[0]}")`)
        if (r_link_status[0] == "down") {
          setVpnStatus("rgb(255, 0, 0)")
        }
      }

    }
    useEffect(() => {
      calculateEdgeProps()
    }, [data.expandEdges, sx, sy, tx, ty, sourcePos, targetPos])
    useEffect(() => {
      calculateEdgeProps()
    }, [])

    const renderLinkLocalPort = useCallback(() => {
      const _sourceEdges = edges.filter((e) => e.source === source && e.target === target)
      const uniqueRemotePorts = new Set();
      const ports = [];
      _sourceEdges.map((i, idx) => {
        if (!uniqueRemotePorts.has(i.data.r_link_local_interface)) {
          uniqueRemotePorts.add(i.data.r_link_local_interface)
          ports.push({ port: i.data.r_link_local_interface, status: i.data.r_link_status })
        }
      })

      return ports.map(i => {
        return <p key={i.port} className={i.status == "up" ? classess.up : classess.down}>
          {i.port}
        </p>
      })
    });

    const renderLinkRemotPort = useCallback(() => {
      const _sourceEdges = edges.filter((e) => e.source === source && e.target === target)
      const uniqueRemotePorts = new Set();
      const ports = [];
      _sourceEdges.map((i, idx) => {
        if (!uniqueRemotePorts.has(i.data.r_link_remote_interface)) {
          uniqueRemotePorts.add(i.data.r_link_remote_interface)
          ports.push({ port: i.data.r_link_remote_interface, status: i.data.r_link_status })
        }
      })
      return ports.map(i => {
        return <p key={i.port} className={i.status === "up" ? classess.up : classess.down}>
          {i.port}
        </p>
      })
    });


    // ----------------------------------------------------------
    return (
      <>
        <BaseEdge
          path={vpnPath}
          markerEnd={markerEnd}
          style={{
            ...style,
            stroke: vpnPathStatus,
          }}
          displayName={data.r_link_name}
        />
        {data.expandEdges && <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${(sx + tx) / 2 + (data.expandEdges * 20)}px,${(sy + ty) / 2 + (data.expandEdges * 20)}px)`,
              background: vpnStatus,
              padding: 2,
              borderRadius: 2,
              fontSize: 6,
              color: "#fff"
            }}
            className="nodrag nopan"
          >
            {data.r_link_name}
            <p> {data.r_link_local_interface} &harr; {data.r_link_remote_interface}</p>
          </div>
        </EdgeLabelRenderer>}

        <EdgeLabelRenderer>
          {data.r_link_local_interface && (
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, 0%) translate(${sx}px,${sy}px)`,
                background: '#ffebee',
                padding: 2,
                color: '#f44336',
                borderRadius: 5,
                fontSize: 8,
                zIndex: 10000,
                'opacity': data.hoverEdgeLabel ? 1 : 0
              }}
              className="nodrag nopan"
            >
              {renderLinkLocalPort()}
            </div>
          )}
        </EdgeLabelRenderer>
        <EdgeLabelRenderer>
          {data.r_link_remote_interface && (
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -100%) translate(${tx}px,${ty}px)`,
                background: '#ffebee',
                padding: 2,
                color: '#f44336',
                borderRadius: 5,
                fontSize: 8,
                zIndex: 10000,
                'opacity': data.hoverEdgeLabel ? 1 : 0
              }}
              className="nodrag nopan"
            >
              {renderLinkRemotPort()}
            </div>
          )}
        </EdgeLabelRenderer>
      </>
    );
  }
);
