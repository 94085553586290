import { Grid } from '@mui/material';
import IndividualDevices from './Components/IndividualDevices';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const IndividualDevicesComponent = (props) => {
  const { locationData } = props;


  return (
    <>
      <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <IndividualDevices locationData={locationData}/>
          </Grid>
      </Grid>
    </>
  );
};
export default IndividualDevicesComponent;

