/* eslint-disable*/
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { groupUnApproveAllChange } from '../../../../API/CMDBOperations';
import { UserContext } from "../../../../context/UserContext";

// -----------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// --------------------------------------------------------------------------
function UnapproveGroupDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, data, gridApi, groupedApi } = props;
  const [unapproveValue, setUnapproveValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(false);
  const [allowed, setAllowed] = useState(false)
  const [warningMessage, setWarningMessage] = useState("")

  // ------------------------------------------------------------------------------

  useEffect(() => {
    if (allowed === true) {
      if (unapproveValue.length > 0) {
        setEnable(true);
        return
      }
    }
    setEnable(false)
  }, [allowed, unapproveValue])

  const handleTextfield = (e) => {
    setUnapproveValue(e.target.value);
  };

  useEffect(() => {
    const scores = data?.device_summary_event?.map((item) => (item.r_dvc_config_change_version - 1) - item.c_m_d_b_network_device?.r_dvc_latest_approved_config_change_version);
    if (scores.filter(i => i==0).length === 0) {
      setAllowed(false)
      setWarningMessage("The change is already approved")
    } else {
      setAllowed(true)
      setWarningMessage("Are you sure you. you want to  approve?")
    }
  }, [data])

  const handleConfirm = () => {
    const payload = {
      message: unapproveValue,
    }
    setLoading(true);
    groupUnApproveAllChange(customerId, data.uid, payload)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'All devices are updated as un-approved successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
        gridApi.purgeInfiniteCache();
        groupedApi.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  }
  // -------------------------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box style={{ textAlign: 'left' }}>
            Un-Approve All
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {warningMessage && <Alert severity="warning">
          {warningMessage}
        </Alert>}

        <TextField
          fullWidth
          multiline
          rows={3}
          type="text"
          size="normal"
          label="Message *"
          variant="outlined"
          placeholder="Add Message"
          onChange={handleTextfield}
          InputLabelProps={{ shrink: true }}
        // error={!enable}
        // helperText={!enable ? 'Please write message' : ''}
        />
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!enable}
          onClick={handleConfirm}
          loading={loading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default UnapproveGroupDialog;
