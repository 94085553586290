import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DcAgentSubnetS(props) {
  const { selectedSubnets, setSelectedSubnets, subNetOptions } = props;

  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <>
      {errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
      <Autocomplete
        size="small"
        value={selectedSubnets || ''}
        multiple
        limitTags={6}
        onChange={(event, newValue, reason, details) => {
          // const valueList = selectedSubnets;

          // if (details.option.create && reason !== 'removeOption') {
          //   valueList.push({ id: undefined, name: details.option.name, create: details.option.create });
          //   setSelectedSubnets(valueList);
          //   // }
          // } else {
          setSelectedSubnets(newValue);
          // }
        }}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option._uid === value._uid}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // const { inputValue } = params;
          // // Suggest the creation of a new value
          // const isExisting = options.some((option) => inputValue === option.name);
          // if (inputValue !== '' && !isExisting && inputValue === null) {
          //   filtered.push({
          //     inputValue,
          //     label: `Add "${inputValue}"`,
          //     // create: true,
          //     name: `Create "${inputValue}"`,
          //   });
          // }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="tags-Create"
        options={subNetOptions}
        getOptionLabel={(option) => option.r_subnet_value}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.r_subnet_value}
          </li>
        )}
        // freeSolo
        renderInput={(params) => (
          <TextField {...params} placeholder="Select subnet" label="Subnets" InputLabelProps={{ shrink: true }} />
        )}
      />
    </>
  );
}

const data = [];
