import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useContext, useEffect, useState } from 'react';
import { CMDBSkipPause, CMDBUnskipResume } from '../../../API/CMDBOperations/index';
import { UserContext } from '../../../context/UserContext';
import { WithConfirm } from '../../WithConfirm';

const ActionRenderer = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [skipLoading, setSkipLoading] = useState(false);
  const [unskipLoading, setUnskipLoading] = useState(false);

  const [backupable, setBackupable] = useState(true);
  const [skipable, setSkipable] = useState(null);

  useEffect(() => {
    if (data) {
      if (Object.prototype.hasOwnProperty.call(data, "r_skip_scan")) {
        setSkipable(data?.r_skip_scan);
      }
    }
  }, [data]);

  const handleSkip = (data) => {
    setSkipLoading(true);
    CMDBSkipPause(customerId, data?.cmdbnd_id)
      .then((res) => {
        setSkipLoading(false);
        setSkipable(1); 
        setBackupable(false);
        enqueueSnackbar(
          'CMDB scan is skipped successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setSkipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleUnskip = (data) => {
    setUnskipLoading(true);
    CMDBUnskipResume(customerId, data?.cmdbnd_id)
      .then((res) => {
        setUnskipLoading(false);
        setSkipable(0); 
        setBackupable(true);
        enqueueSnackbar(
          'CMDB scan is un-skipped successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setUnskipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  if (props.data === undefined) {
    return '';
  }

  if (props?.data) {
    return (
      <>
        {(skipable === 0) || (skipable === null) || (skipable === "") ? ( 
          <LoadingButton
            variant="outlined"
            loading={skipLoading}
            startIcon={<PauseIcon style={{ color: "red" }} />}
            onClick={confirm(() => handleSkip(data), {
              title: 'Information',
              confirmationText: 'Yes',
              disableOkBtn: false,
              description: 'Are you sure you want to skip scan?',
            })}
          >
            Skip scan
          </LoadingButton>

        ) : (
          <LoadingButton 
            variant="outlined"
            loading={unskipLoading}
            startIcon={<PlayArrowIcon style={{ color: "green" }} />}
            onClick={confirm(() => handleUnskip(data), {
              title: 'Information',
              confirmationText: 'Yes',
              disableOkBtn: false,
              description: 'Are you sure you want to unskip scan?',
            })}
          >
            Resume scan
          </LoadingButton>
        )}
      </>
    );
  }
};

ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionRenderer);
