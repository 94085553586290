import { TestbedInstance ,instance as modem } from '../modem';

const id =1;
// export const getWifiEvents = async (id, data) => {
//   const testbedModem = TestbedInstance();
//   const newPromise =  modem
//     .post(`${id}/inventory/unknown`, data)
//     .then((response) => {
//       return response.data.data;
//     })
//     return newPromise
// };

export const getWifiEvents = async () => {
    
    const hardcodedData = {
        data: {
            current_page: 1,
          data:
          [
            {
              'user_name': 'Sahana M',
              'mac_addr': 'a0:29:42:22:fb:32',
              'ap_name': 'Open-Area-2F',
              'location': 'Bangalore HQ',
              'ssid': 'Secure',
              'signal_strength': '56',
              'ip_addr': '0.0.0.0',
              'co_pilot_summary': 'Client Authentication is Successful,But Client is unable to Get an IP Address. The Role configuration in the AccessPoint has DHCP allowed . Check VLAN Configuration on the Transient Switches or Check the DHCP server Lease',
              'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status.Checking Client SNR. Checking User Firewall/Role  Policies. Checking Client VLAN Configuration on SSID.Checking IP Address Assignment',
              'rca_data': ''
            },
            {
              'user_name': 'Sachin Gupta',
              'mac_addr': '1e:a6:22:72:be:c7',
              'ap_name': '4F-3B-AP',
              'location': 'Delhi', 'ssid':
                'Secure', 'signal_strength': '72',
              'ip_addr': '0.0.0.0',
              'co_pilot_summary': 'Client Association was successful But Dot1x Authentication failed . External Radius Server is configured . Please check the Logs in the External Radius Server.',
              'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status',
              'rca_data': ''
            },
            {
              'user_name': 'Charles',
              'mac_addr': '28:de:65:b1:a3:25',
              'ap_name': 'FF-25-AP',
              'location': 'Chennai',
              'ssid': 'Secure',
              'signal_strength': '65',
              'ip_addr': '0.0.0.0',
              'co_pilot_summary': 'Client Association failed due to Maximum client Thresold reached on the AccessPoint .Check the Status of Other AccessPoints in vicinity or increase the AP count.',
              'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model',
              'rca_data': ''
            }
          ]
        }
    }
    return Promise.resolve(hardcodedData.data.data);
}

export const getWanCopilotEvents = async () => {
    
  const hardcodedData = {
    data: {
      current_page: 1,
     data :
     [
      {
        "uid": 18035,
        "isp_wan_id": 11319,
        "client_id": 110,
        "public_ip": "103.182.158.60",
        "good_cnt": 0,
        "bad_cnt": 0,
        "up_cnt": 0,
        "down_cnt": 579,
        "ent": "2024-11-06 17:05:43",
        "info_id": 718956083,
        "circuit_id": "TITAN_SATARA_IN_RTR_YTD",
        "min_rtt": 0,
        "avg_rtt": 0,
        "max_rtt": 0,
        "jitter": 0,
        "packet_loss": 100,
        "status": 0,
        "dg_reach_status": 1,
        "cust_id": 110,
        "bu_id": 12,
        "zone_id": 193,
        "group_id": 198,
        "ext_probe_id": -1,
        "device_id": 1178,
        "location_id": 2870,
        "location": "PIT-Tanishq Store",
        "private_ip": null,
        "internal_ip": "192.168.0.57",
        "vendor_id": 3097,
        "vendor": "SB NETWORK",
        "vendor_email": "support@sbnetworkbd.com",
        "default_gateway": "0.0.0.0",
        "firewall_ip": "10.130.126.1",
        "promised_uptime": 99,
        "resolution_time": 4,
        "business_hr_start": 10,
        "business_hr_end": 18,
        "link_type": "DBB",
        "provisioned_speed": 200,
        "price": 1000,
        "city": "Mumbai",
        "pause_status": 0,
        "util_alert_status": 1,
        "util_warn_th": 80,
        "util_critical_th": 100,
        "renewal_date": "2024-01-01",
        "if_name": "ge0/2",
        "if_index": 3,
        "tag_values": null,
        "created_date": "2024-06-13 11:01:31",
        "cust_name": "Titan",
        "ed_vendor_id": 13,
        "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
      },
      {
        "uid": 16293,
        "isp_wan_id": 19495,
        "client_id": 110,
        "public_ip": "180.151.14.129",
        "good_cnt": 0,
        "bad_cnt": 0,
        "up_cnt": 0,
        "down_cnt": 1014,
        "ent": "2024-11-08 10:46:16",
        "info_id": 718955803,
        "circuit_id": "PATPARGANJ-CFA",
        "min_rtt": 0,
        "avg_rtt": 0,
        "max_rtt": 0,
        "jitter": 0,
        "packet_loss": 100,
        "status": 0,
        "dg_reach_status": 0,
        "cust_id": 110,
        "bu_id": 11,
        "zone_id": 193,
        "group_id": 208,
        "ext_probe_id": 11,
        "device_id": 0,
        "location_id": 4483,
        "location": "Patparganj-CFA",
        "private_ip": null,
        "internal_ip": "180.151.14.129",
        "vendor_id": 2944,
        "vendor": "TCL",
        "vendor_email": "corp.helpdesk@tatacommunications.com",
        "default_gateway": "180.151.14.130",
        "firewall_ip": null,
        "promised_uptime": 99,
        "resolution_time": 4,
        "business_hr_start": 9,
        "business_hr_end": 18,
        "link_type": "ILL",
        "provisioned_speed": 100,
        "price": 140000,
        "city": "East Delhi",
        "pause_status": 0,
        "util_alert_status": 1,
        "util_warn_th": 80,
        "util_critical_th": 100,
        "renewal_date": "2025-01-01",
        "if_name": null,
        "if_index": null,
        "tag_values": null,
        "created_date": "2024-10-03 11:40:35",
        "cust_name": "Titan",
        "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
      },
      {
        "uid": 18085,
        "isp_wan_id": 19525,
        "client_id": 110,
        "public_ip": "106.219.123.235",
        "good_cnt": 0,
        "bad_cnt": 0,
        "up_cnt": 0,
        "down_cnt": 6192,
        "ent": "2024-11-08 10:46:16",
        "info_id": 718956238,
        "circuit_id": "Faridabad_Fragrance-CFA_Unknown",
        "min_rtt": 0,
        "avg_rtt": 0,
        "max_rtt": 0,
        "jitter": 0,
        "packet_loss": 100,
        "status": 0,
        "dg_reach_status": 1,
        "cust_id": 110,
        "bu_id": 11,
        "zone_id": 193,
        "group_id": 208,
        "ext_probe_id": -1,
        "device_id": 1888,
        "location_id": 4480,
        "location": "Faridabad_Fragrance-CFA",
        "private_ip": null,
        "internal_ip": "192.168.1.3",
        "vendor_id": 2601,
        "vendor": "AIRTEL",
        "vendor_email": "esupport@in.airtel.com",
        "default_gateway": "192.168.1.3",
        "firewall_ip": "172.28.22.1",
        "promised_uptime": 99,
        "resolution_time": 4,
        "business_hr_start": 9,
        "business_hr_end": 18,
        "link_type": "DBB",
        "provisioned_speed": 200,
        "price": 0,
        "city": "Faridabad",
        "pause_status": 0,
        "util_alert_status": 1,
        "util_warn_th": 80,
        "util_critical_th": 100,
        "renewal_date": "1970-01-01",
        "if_name": "wan1",
        "if_index": 9,
        "tag_values": null,
        "created_date": "2024-10-04 13:31:24",
        "cust_name": "Titan",
        "ed_vendor_id": 12,
        "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
      }
    ]
    }

  };

  return Promise.resolve(hardcodedData.data.data);
}