/* eslint-disable*/
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { approveDeviceSummaryChange } from '../../../../../API/CMDBOperations';
import { UserContext } from '../../../../../context/UserContext';
import SummaryDiff from './SummaryDiff';

// -----------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// --------------------------------------------------------------------------
function ApproveDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, data, cmdbGroupSummaryEvent } = props;
  const [approveValue, setApproveValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [enabled, setEnable] = useState(false);
  const [alertText, setAlertText] = useState('');

  // ------------------------------------------------------------------------------

  useEffect(() => {
    const a = data?.c_m_d_b_network_device?.r_dvc_latest_approved_config_change_version;
    const b = data?.r_dvc_config_change_version;

    if (b - 1 === a) {
      setAllowed(true);
      setAlertText('Are you sure you want to approve ?');
    }
    if (b - 1 > a) {
      setAllowed(false);
      setAlertText(
        ' Cannot approve this change as there are past un-approved changes. Please review them and then proceed.'
      );
    }
    if (b - 1 < a) {
      setAllowed(false);
      setAlertText('The change is already approved');
    }
    if (data?.r_criticality == 'APPROVED') {
      setAllowed(false);
      setAlertText('The change is already approved');
    }
  }, [data]);

  useEffect(() => {
    if (allowed === true) {
      if (approveValue.length > 0) {
        setEnable(true);
        return;
      }
    }
    setEnable(false);
  }, [allowed, approveValue]);

  const handleTextfield = (e) => {
    setApproveValue(e.target.value);
  };

  const handleConfirm = () => {
    const payload = {
      message: approveValue,
    };
    setLoading(true);
    approveDeviceSummaryChange(customerId, data._uid, payload)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Changes are approved successful',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
        props.api.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  };

  // -------------------------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
        
          <Box style={{ textAlign: 'left' }}>
            Approve change <br />
            <Typography variant="body2" gutterBottom>
              {data?.c_m_d_b_network_device?.r_dvc_ip_address} - {data?.c_m_d_b_network_device?.r_dvc_serial_number}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SummaryDiff cmdbGroupSummaryEvent={cmdbGroupSummaryEvent}/> 
        <Alert severity="warning">{alertText}</Alert>
        {/* <Accordion sx={{ backgroundColor: '#f5f5f5' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{data?.g_created_at}</TableCell>
                    <TableCell>
                      {data?.latest_group_summary?.r_criticality ? (
                        <Chip color="success" label={data?.latest_group_summary?.r_criticality} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>Diff view</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: 'white', px: 0, py: 0 }}>
            <Typography>
              <Typography>
                <iframe
                  width="100%"
                  height="200px"
                  style={{ border: '1px solid black', padding: '5px' }}
                  srcDoc={data?.latest_group_summary?.r_grp_smry_diff_html}
                />
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <TextField
          fullWidth
          multiline
          rows={3}
          type="text"
          size="normal"
          label="Message *"
          variant="outlined"
          placeholder="Add Message"
          onChange={handleTextfield}
          InputLabelProps={{ shrink: true }}
          // error={!enabled}
          // helperText={!enabled ? 'Please write message' : ''}
        />
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!enabled}
          onClick={handleConfirm}
          loading={loading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default ApproveDialog;
