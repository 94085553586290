// @mui
import {Grid} from '@mui/material';
import OndemandTable from './Components/OndemandTable';

export default function OnDemandcomponent() {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12}>
        <OndemandTable />
      </Grid>
    </Grid>
  );
}
