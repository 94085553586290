// material
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import AlertComponents from '../components/AlertsComponents/AlertsComponents';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Alerts() {
  return (
    <Page title="Device up-down">
      <Container maxWidth={false}>
        <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5, cursor: 'pointer'}}>
              <HomeOutlinedIcon 
              sx={{
                fontSize: {
                  xs: '20px',
                  sm: '22px',
                  md: '24px',
                  lg: '26px',
                  xl: '28px',
                  xxl: '30px',
                },
              }}/>
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer',
          fontSize: {
            xs: '8px',
            sm: '12px',
            md: '14px',
            lg: '16px',
            xl: '18px',
            xxl: '20px',
          },
          }} to="/dashboard/device-up-down">
              Logs
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer', 
            fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                }, }} to="/dashboard/device-up-down">
              Device Up-Down
            </Typography>
          </Breadcrumbs>
        </div>

        <AlertComponents />
      </Container>
    </Page>
  );
}
