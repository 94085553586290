import axios from 'axios';
import clientCredentials from "./api_endpoints"
 
const defaultOptions = {
  baseURL: clientCredentials.apiHost,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  crossOrigin: true,
};
 
const instance = axios.create(defaultOptions);
 
instance.interceptors.request.use((config) => {
  if (localStorage.getItem('AuthorizationToken') === null) {
    config.headers.Authorization = null;
  } else {
    try {
      const token = JSON.parse(localStorage.getItem('AuthorizationToken'));
      config.headers.Authorization = `Bearer ${token.token}`;
    } catch (error) {
      console.log(error);
    }
  }
  return config;
});
 
instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response?.status === 401) {
    localStorage.setItem('AuthorizationToken', null);
    localStorage.clear();
    window.location = '/';
  } else {
    return Promise.reject(error);
  }
});
 
const TestbedInstance = () => {
  const testbedOptions = {
    baseURL: 'https://basestation-api.linkeye.io',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    crossOrigin: true,
  };
 
  const testbedInstance = axios.create(testbedOptions);
 
  testbedInstance.interceptors.request.use((config) => {
    if (localStorage.getItem('AuthorizationToken') === null) {
      config.headers.Authorization = null;
    } else {
      try {
        const token = JSON.parse(localStorage.getItem('AuthorizationToken'));
        config.headers.Authorization = `Bearer ${token.token}`;
      } catch (error) {
        console.log(error);
      }
    }
    return config;
  });
 
  testbedInstance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response?.status === 401) {
      localStorage.setItem('AuthorizationToken', null);
      localStorage.clear();
      window.location = '/';
    } else {
      return Promise.reject(error);
    }
  });
 
  return testbedInstance;
};
 
export { instance, TestbedInstance };