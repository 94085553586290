import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  if (date) {
    return format(new Date(date), 'dd MMMM yyyy');
  }
  return ""
}

export function fDateTime(date) {
  if (date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  }
  return ""
}
export function fDateTimeForDownloadCsv(date) {
  if (date) {
    return format( date, `dd-MMM-yyyy`);
  }
  return ""
}
export function fDateOnly(date) {
  if (date) {
    try{
      return format( date, `yyyy-MM-dd`);
    }catch{
      return ""
    }
  }
  return ""
}
export function fDateTimeSuffix(date) {
  if (date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
  }
  return ""
}

export function fDateTimeFormat(date) {
  if (date) {
    return format(new Date(date), 'yyyy-mm-dd hh:mm:ss');
  }
  return ""
}

export function fToNow(date) {
  if (date) {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  }
  return ""
}

export function formatDateDifference(endDate) {
  if(endDate){
    const now = new Date();
    const timeDifference = Math.abs(now - new Date(endDate));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const formatTimeUnit = (value, unit) => `${value} ${unit}${value !== 1 ? 's' : ''}`;
    const formattedTime = [days && formatTimeUnit(days, 'day'), hours && formatTimeUnit(hours, 'hr'), minutes && formatTimeUnit(minutes, 'min')]
      .filter(Boolean) // Remove falsy values (null or undefined)
      .join(' ');
    return formattedTime || '0 seconds ago';
  }
  return ""
}
