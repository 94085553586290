import { S3Browser } from "./index";


function S3BrowserComponent() {
  return (
    <div style={{justifyContent:'center',alignItems:'center'}}>
      <S3Browser/>
    </div>
  )
}

export default S3BrowserComponent;