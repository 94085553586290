
import { Breadcrumbs, Container, Grid, Link } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Page from '../components/Page';
import { SidewiseCards } from '../components/SidewiseComponents/Components';
import LocationSpecificData from '../components/SidewiseComponents/Components/LocationSpecificData';


const countries = [
  { code: 'AD', label: 'Location1', phone: '376', id: 1 },
  {
    code: 'AE',
    label: 'Location2',
    phone: '971',
    id: 2,
  },
  { code: 'AF', label: 'Location3', phone: '93', id: 3 },
  {
    code: 'AG',
    label: 'Location4',
    phone: '1-268',
    id: 4,
  },
  { code: 'AI', label: 'Location5', phone: '1-264', id: 5 },
];

export default function Sidewise() {
  const [locationData, setLocationdata] = useState(null);

  const handelChange = (e, value) => {
    setLocationdata(value);
  };

  return (
    <Page title="Sidewise">
      <Container maxWidth={false}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/dashboard">
              Linkeye Basestation
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/dashboard">
              IPS Management
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/sidewise">
              Sidewise Map
            </Link>
          </Breadcrumbs>
        </div>
        <Autocomplete
          id="location-select-demo"
          sx={{ width: '70%', margin: '0 auto', mt: 2 }}
          options={countries}
          autoHighlight
          onChange={handelChange}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Locations"
              label="Choose  location"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        {locationData && (
          <>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <SidewiseCards />
            </Grid>
            <LocationSpecificData locationData={locationData} />
          </>
        )}
      </Container>
    </Page>
  );
}
