import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,LinearProgress,
  Table,Chip,FormControl,
  TableBody,Tooltip,
  TableCell,
  TableRow,
  CardContent,
  TableContainer,
  TableHead,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { timeSince, toCamelizeWords } from '../../../utils/formatString';
import { fDateTimeSuffix } from '../../../utils/formatTime';
import { UserContext } from '../../../context/UserContext';
import { WithConfirm } from '../../WithConfirm';
import { FormProvider } from '../../hook-form';
import OndemandZoneSelector from './OndemandZoneSelector';
import OndemandSubnetSelector from './OndemandSubnetSelector';
import OndemandLocationSelector from './OndemandLocationSelector';
import { assignSubnets } from '../../../API/OnDemand/Ondemand';
import { getDcAgent } from '../../../API/DcAgent/DcAgent';


// -----------------------------------------------------------------------------------------------------------

const OndemandTable = (props) => {
  const { agentData} = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [subnetOptions, setSubnetOptions] = useState([]);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [zoneSelectionSource, setZoneSelectionSource] = useState(null);
  const [zoneValue, setZoneValue] = useState(agentData?.zone || '');
  const [locationValue, setLocationValue] = useState(agentData?.locations || []);
  const [loading, setLoading] = useState(false);
  const [dcAgentsData, setDcAgentsData] = useState('');
  const [dcAgentLoading, setDcAgentLoading] = useState(false);
  const [ assignSubnetsData, setAssignSubnetsData ] = useState('');
  const [showTime, setShowTime] = useState(false);
  const [page, setPage] = useState(0);

 

  const fetchDcAgents = async () => {
    setDcAgentLoading(true);
    getDcAgent(customerId, page+1)
      .then((res) => {
        setDcAgentLoading(false);
        setDcAgentsData(res);
      })
      .catch(() => {
        setDcAgentLoading(false);
      });
  };

  useEffect(() => {
    fetchDcAgents();
  }, [customerId]);


  const methods = useForm({
    // resolver: yupResolver(credentialsSchema),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (value) => {
    const data = {
      subnet_uuids: selectedSubnets?.map((item) => item._uid),
    };
    setDcAgentLoading(true);
    setLoading(true);
   const agentId = dcAgentsData.data.find((agent)=>agent?.zone._uid === zoneValue?._uid )
    assignSubnets(customerId, agentId._uid, data)
      .then((res) => {
        setDcAgentLoading(false);
        setAssignSubnetsData(res);
        setLoading(false);
        setShowTime(true)
        enqueueSnackbar(
          'Selected subnets are scanned sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );        
      })
      .catch((res) => {
        setDcAgentLoading(false);
        setLoading(false);
        setShowTime(true)
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );        
      });
  };


  const showAgentStatus = (assignSubnetsData) => {
    switch (assignSubnetsData.r_agent_status) {
      case 'NEW':
        return <Chip variant="outlined" color="success" label={toCamelizeWords('NEW')} />;
      case 'CONFIGURING':
        return <Chip variant="outlined" color="warning" label={toCamelizeWords('CONFIGURING')} />;
      case 'CONFIGURED':
        return <Chip variant="outlined" color="warning" label={toCamelizeWords('CONFIGURED')} />;
      case 'RUNNING':
        return <Tooltip title={fDateTimeSuffix(assignSubnetsData.r_scan_end_time * 1000)}>
          <div style={{
            height: 35
          }}><Chip variant="outlined" color="success" label={toCamelizeWords('RUNNING')} />
          </div>
        </Tooltip>
      case 'STOPPED':
        return <Chip variant="outlined" label={toCamelizeWords('STOPPED')} />;
      case 'SCAN_INPROGRESS':
        return <Tooltip title={fDateTimeSuffix(assignSubnetsData.r_scan_end_time * 1000)}>
          <div style={{
            height: 35
          }}>
            <Chip variant="outlined" color="warning" label={"Scan in progress"} />
          </div>
        </Tooltip>
      case 'NOT_RESPONDING':
        return <Chip variant="outlined" color="error" label={toCamelizeWords('NOT_RESPONDING')} />;
      case 'INACTIVE':
        return <Chip variant="outlined" label={toCamelizeWords('INACTIVE')} />;
      default:
        return ''
    }
  };

  const _setZoneValue = (value, _source) => {
    setZoneSelectionSource(_source);
    setZoneValue(value);
  };
  const selectLocations = (value) => {
    setLocationValue(value);
  };

  const _setOnDemandSubnetOptions = (_subnetOptions) => {
    setSelectedSubnets(_subnetOptions);
    setSubnetOptions(_subnetOptions);
  };


  return (
    <>
      <Card>
        <CardHeader
          color="text.error"
          sx={{ mb: 2 }}
          title="On demand discovery"
        />
        <Divider />
        {dcAgentLoading && <LinearProgress />}
        <FormProvider methods={methods}>
          <CardContent style={{ padding: '0px' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width:'18%'}}>
                      Zone
                    </TableCell>
                    <TableCell sx={{width:'25%'}}>
                      Location
                    </TableCell>
                    <TableCell>
                      Subnets
                    </TableCell>
                    <TableCell sx={{width:'15%'}}>
                      Status
                    </TableCell>
                    <TableCell sx={{width:'12%'}}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  <TableRow>
                    <TableCell sx={{width:'18%',verticalAlign: 'top'}}>
                    <FormControl fullWidth size="small">
                      <OndemandZoneSelector 
                        setZoneValue={_setZoneValue}
                        zoneValue={zoneValue}
                      />
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{width:'25%', verticalAlign: 'top'}}>
                    <FormControl fullWidth size="small">
                      <OndemandLocationSelector 
                        zoneSelectionSource={zoneSelectionSource}
                        zoneValue={zoneValue}
                        setZoneValue={(_zone) => _setZoneValue(_zone, 'location')}
                        setLocationValue={selectLocations}
                        locationValue={locationValue}
                        setOnDemandSubnetOptions={_setOnDemandSubnetOptions}
                      />
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{verticalAlign: 'top'}}>
                    <FormControl fullWidth size="small">
                      <OndemandSubnetSelector   
                        setSelectedSubnets={setSelectedSubnets}
                        subNetOptions={subnetOptions}
                        selectedSubnets={selectedSubnets}
                      />
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{width:'15%', verticalAlign: 'top'}}>
                     {showTime && ( 
                          <div>
                              {showAgentStatus(assignSubnetsData)}
                              {timeSince(assignSubnetsData.r_scan_end_time * 1000)} ago
                          </div>
                      )}
                    </TableCell>
                    <TableCell sx={{width:'12%', verticalAlign: 'top'}}>
                          <LoadingButton 
                            variant="contained"
                            loading={loading}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Scan selected 
                          </LoadingButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          </FormProvider>
      </Card>
    </>
  );
};

export default WithConfirm(OndemandTable);
