/* eslint-disable */
import { Card } from '@mui/material';
import { ReactFlowProvider } from 'reactflow';
import OverView from './RelatedToGraph/OverView';
import { MapSettingProvider } from './RelatedToGraph/mapSettingProvider';


const SitewiseNetworkGraph = () => {

  return (
    <>
      <Card sx={{ height: '750px', px: 0, py: 0 }} variant="outlined">
        <MapSettingProvider>
          <ReactFlowProvider>
            <OverView />
          </ReactFlowProvider>
        </MapSettingProvider>
      </Card>
    </>
  );
};
export default SitewiseNetworkGraph;
