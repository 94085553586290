import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  key_content: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    "word-break": "break-word",
  },
}));

const ReasonRenderer = (props) => {
  const classes = useStyles();

  if (props.data === undefined) {
    return '';
  }
  return <div className={classes.key_content}>'reason reason reason</div>;
};

ReasonRenderer.propTypes = {
  data: PropTypes.object,
};

export default ReasonRenderer;
