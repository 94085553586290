// // import { yupResolver } from '@hookform/resolvers/yup';
// // import { Close } from '@mui/icons-material';
// // import LoadingButton from '@mui/lab/LoadingButton';
// // import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Stack } from '@mui/material';
// // import { makeStyles } from '@mui/styles';
// // import { useSnackbar } from 'notistack';
// // import { useContext, useState } from 'react';
// // import { useForm } from 'react-hook-form';
// // import * as Yup from 'yup';
// // import PropTypes from 'prop-types';
// // import { Controller } from 'react-hook-form'; 
// // import { editMember } from '../../../API/Members/members';
// // import {approveMembers} from '../../../API/Members/members'
// // import { UserContext } from '../../../context/UserContext';
// // import { FormProvider, RHFTextField } from '../../hook-form';
// // import RoleSelectorRenderer from './renderer/RoleSelectorRenderer';
// // import MemberRoleSelector from './MemberRoleSelector';

// // //----------------------------------------------------

// // const useStyles = makeStyles({
// //     topScrollPaper: {
// //         alignItems: "flex-start"
// //     },
// //     topPaperScrollBody: {
// //         verticalAlign: "top"
// //     }
// // });

// // //----------------------------------------------------

// // export default function PendingMemberModel(props) {
// //     const classes = useStyles();
// //     const { openDialog, handleClose, member, gridApi } = props;
// //     const { enqueueSnackbar } = useSnackbar();
// //     const { customerId } = useContext(UserContext);
// //     const [loading, setLoading] = useState(false);
// //     const [memberRole, setMemberRole] = useState(null);
// //     const [roleOptions, setRoleOptions] = useState([]);

// //     //----------------------------------------------------

// //     const membersSchema = Yup.object().shape({
// //         role: Yup.string().required('role required'),
// //         Remarks: Yup.string().max(255, 'Remarks must be at least 255 characters').required('Remarks are required'),
// //     });

// //     const defaultValues = {
// //         name: member.name,
// //         Remarks: '', 
// //     };

// //     const methods = useForm({
// //         resolver: yupResolver(membersSchema),
// //         defaultValues,
// //     });

// //     const {
// //         handleSubmit,
// //     } = methods;

// //     const onSubmit = async (formData) => {
// //         console.log("formData",formData);
// //         const data = {
// //             name: formData.name,
// //             email: formData.email
// //         };
// //         setLoading(true);
// //         editMember(customerId, member.user_id, data)
// //             .then(() => {
// //                 setLoading(false);
// //                 gridApi.purgeInfiniteCache();
// //                 handleClose();
// //                 enqueueSnackbar(
// //                     'Member updated sucessfully',
// //                     {
// //                         variant: 'success',
// //                         anchorOrigin: {
// //                             vertical: 'bottom',
// //                             horizontal: 'right',
// //                         },
// //                     },
// //                     500
// //                 );
// //             })
// //             .catch((res) => {
// //                 setLoading(false);
// //                 enqueueSnackbar(
// //                     res.response.data.message,
// //                     {
// //                         variant: 'error',
// //                         anchorOrigin: {
// //                             vertical: 'bottom',
// //                             horizontal: 'right',
// //                         },
// //                     },
// //                     500
// //                 );
// //             });
// //     };
// //     const selectRole = (value) => {
// //         setMemberRole(value);
// //     };

// //     //----------------------------------------------------

// //     return (
// //         <>
// //             <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title"
// //                 classes={{
// //                     scrollPaper: classes.topScrollPaper,
// //                     paperScrollBody: classes.topPaperScrollBody
// //                 }}
// //             >
// //                 <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
// //                     <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
// //                         Approve member
// //                         <IconButton onClick={handleClose} sx={{ float: 'right' }}>
// //                             <Close sx={{ fontSize: '1.5rem' }} />
// //                         </IconButton>
// //                     </DialogTitle>
// //                     <Divider />
// //                     {loading && <LinearProgress />}
// //                     <DialogContent sx={{ py: 3, px: 3 }}>
// //                         <Stack spacing={3}>
// //                             <MemberRoleSelector
// //                                 placeholder="Select role"
// //                                 name="roleSelector"
// //                                 roleOptions={roleOptions}
// //                                 selectRole={selectRole}
// //                             />
// //                             <RHFTextField name="Remarks" label="Remarks *" placeholder="Enter remarks" />

// //                         </Stack>
// //                     </DialogContent>
// //                     <Divider />
// //                     <DialogActions>
// //                         <Button onClick={handleClose}>Cancel</Button>
// //                         <LoadingButton type="submit" variant="contained" loading={loading}>
// //                             Save
// //                         </LoadingButton>
// //                     </DialogActions>
// //                 </FormProvider>
// //             </Dialog>
// //         </>
// //     );
// // }

// // PendingMemberModel.propTypes = {
// //     member: PropTypes.object,
// //     gridApi: PropTypes.object,
// //     openDialog: PropTypes.bool.isRequired,
// //     handleClose: PropTypes.func.isRequired,
// // };

// import { yupResolver } from '@hookform/resolvers/yup';
// import { Close } from '@mui/icons-material';
// import LoadingButton from '@mui/lab/LoadingButton';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Stack } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { useSnackbar } from 'notistack';
// import { useContext, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import { approveMembers } from '../../../API/Members/members';
// import { UserContext } from '../../../context/UserContext';
// import { FormProvider, RHFTextField } from '../../hook-form';
// import MemberRoleSelector from './MemberRoleSelector';

// //----------------------------------------------------

// const useStyles = makeStyles({
//     topScrollPaper: {
//         alignItems: "flex-start"
//     },
//     topPaperScrollBody: {
//         verticalAlign: "top"
//     }
// });

// //----------------------------------------------------

// export default function PendingMemberModel(props) {
//     const classes = useStyles();
//     const { openDialog, handleClose, member, gridApi } = props;
//     const { enqueueSnackbar } = useSnackbar();
//     const { customerId } = useContext(UserContext);
//     const [loading, setLoading] = useState(false);
//     const [memberRole, setMemberRole] = useState(null);
//     const [roleOptions, setRoleOptions] = useState([]);

//     //----------------------------------------------------

//     const membersSchema = Yup.object().shape({
//         role_id: Yup.object().required("Role is required"), // Make role mandatory
//     });

//     const defaultValues = {
//         Remarks: '',  
//     };

//     const methods = useForm({
//         resolver: yupResolver(membersSchema),
//         defaultValues,
//     });

//     const {
//         handleSubmit,
//         setValue,
//         formState: { errors },
//     } = methods;

//     const onSubmit = async (formData) => {
//         const data = {
//             reg_user_id : member.user_id,
//             role_id: memberRole.role_id,  
//             remarks: formData.Remarks,  
//             status : member.status
//         };

//         setLoading(true);
//         approveMembers(customerId, data)
//             .then((res) => {
//                 console.log(res);
//                 setLoading(false);
//                 gridApi.purgeInfiniteCache();  
//                 handleClose();  
//                 enqueueSnackbar(
//                     res?.message,
//                     {
//                         variant: 'success',
//                         anchorOrigin: {
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         },
//                     },
//                     500
//                 );
//             })
//             .catch((res) => {
//                 setLoading(false);
//                 enqueueSnackbar(
//                     res?.message,
//                     {
//                         variant: 'error',
//                         anchorOrigin: {
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         },
//                     },
//                     500
//                 );
//             });
//     };

//     const selectRole = (value) => {
//         setMemberRole(value);
//         setValue("role_id", value);
//     };

//     //----------------------------------------------------

//     return (
//         <>
//             <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title"
//                 classes={{
//                     scrollPaper: classes.topScrollPaper,
//                     paperScrollBody: classes.topPaperScrollBody
//                 }}
//             >
//                 <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//                     <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//                         Approve Member
//                         <IconButton onClick={handleClose} sx={{ float: 'right' }}>
//                             <Close sx={{ fontSize: '1.5rem' }} />
//                         </IconButton>
//                     </DialogTitle>
//                     <Divider />
//                     {loading && <LinearProgress />}
//                     <DialogContent sx={{ py: 3, px: 3 }}>
//                         <Stack spacing={3}>
//                             <MemberRoleSelector
//                                 placeholder="Select role"
//                                 name="roleSelector"
//                                 roleOptions={roleOptions}
//                                 selectRole={selectRole}
//                             />
//                             <RHFTextField name="Remarks" label="Remarks *" placeholder="Enter remarks" />
//                         </Stack>
//                     </DialogContent>
//                     <Divider />
//                     <DialogActions>
//                         <Button onClick={handleClose}>Cancel</Button>
//                         <LoadingButton type="submit" variant="contained" loading={loading}>
//                             Save
//                         </LoadingButton>
//                     </DialogActions>
//                 </FormProvider>
//             </Dialog>
//         </>
//     );
// }

// PendingMemberModel.propTypes = {
//     member: PropTypes.object.isRequired,
//     gridApi: PropTypes.object.isRequired,
//     openDialog: PropTypes.bool.isRequired,
//     handleClose: PropTypes.func.isRequired,
// };

import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { approveMembers } from '../../../API/Members/members';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';
import MemberRoleSelector from './MemberRoleSelector';

//----------------------------------------------------

const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-start"
    },
    topPaperScrollBody: {
        verticalAlign: "top"
    }
});

//----------------------------------------------------

export default function PendingMemberModel(props) {
    const classes = useStyles();
    const { openDialog, handleClose, member, gridApi } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [memberRole, setMemberRole] = useState(null);
    const [roleOptions, setRoleOptions] = useState([]);

    //----------------------------------------------------

    const membersSchema = Yup.object().shape({
        role_id: Yup.object().required("Role is required"),
    });

    const defaultValues = {
        Remarks: '',
    };

    const methods = useForm({
        resolver: yupResolver(membersSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = methods;

    const onSubmit = async (formData) => {
        const data = {
            reg_user_id: member.user_id,
            role_id: memberRole.role_id,
            remarks: formData.Remarks,
            status: 1
        };

        setLoading(true);
        approveMembers(customerId, data)
            .then((res) => {
                console.log(res);
                setLoading(false);
                gridApi.purgeInfiniteCache();
                handleClose();
                enqueueSnackbar(
                    res?.message,
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            })
            .catch((res) => {
                setLoading(false);
                enqueueSnackbar(
                    res?.message,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            });
    };

    const selectRole = (value) => {
        setMemberRole(value);
        setValue("role_id", value);  // Set the value for form validation
        clearErrors("role_id");  // Clear the error for the role_id field
    };


    //----------------------------------------------------

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title"
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody
                }}
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                        Approve Member
                        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
                            <Close sx={{ fontSize: '1.5rem' }} />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    {loading && <LinearProgress />}
                    <DialogContent sx={{ py: 3, px: 3 }}>
                        <Stack spacing={3}>
                            <MemberRoleSelector
                                placeholder="Select role"
                                name="role_id" // Use this name for form validation
                                roleOptions={roleOptions}
                                selectRole={selectRole}
                                error={!!errors.role_id} // Indicate if there's an error
                                helperText={errors.role_id?.message} // Display error message
                            />
                            <RHFTextField
                                name="Remarks"
                                label="Remarks"
                                placeholder="Enter remarks"
                                multiline
                                rows={4}
                            />
                        </Stack>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" loading={loading}>
                            Save
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
}

PendingMemberModel.propTypes = {
    member: PropTypes.object.isRequired,
    gridApi: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
