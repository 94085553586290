
import { TextField, CircularProgress,Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';

const filter = createFilterOptions();

export default function OndemandSubnetSelector(props) {
  const { selectedSubnets, setSelectedSubnets, subNetOptions } = props;
const [ subnetLoading, setSubnetLoading] = useState(false);

  return (
    <>
      <Autocomplete
        size="small"
        value={selectedSubnets}
        multiple
        limitTags={8}
        onChange={(event, newValue, reason, details) => {
          // const valueList = selectedSubnets;
          // if (details.option.create && reason !== 'removeOption') {
          //   valueList.push({ id: undefined, name: details.option.name, create: details.option.create });
          //   setSelectedSubnets(valueList);
          //   // }
          // } else {
          setSelectedSubnets(newValue);
          // }
        }}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // const { inputValue } = params;
          // // Suggest the creation of a new value
          // const isExisting = options.some((option) => inputValue === option.name);
          // if (inputValue !== '' && !isExisting && inputValue === null) {
          //   filtered.push({
          //     inputValue,
          //     label: `Add "${inputValue}"`,
          //     // create: true,
          //     name: `Create "${inputValue}"`,
          //   });
          // }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="tags-Create"        
        options={subNetOptions}
        loading={subnetLoading}
        getOptionLabel={(option) => option.r_subnet_value}
        renderOption={(props, option) => <li {...props}>{option.r_subnet_value}</li>}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select subnet"
            label="Subnets"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {subnetLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

