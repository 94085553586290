
/* eslint-disable */
import { ArrowDownward, ArrowUpward, MyLocation, SettingsInputAntenna } from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, Divider, Paper, Popper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { x } from "@xstyled/styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Handle, Position, useEdges } from "reactflow";
import { toCamelizeWords } from '../../../../../utils/formatString';
import { useMapSettingContext } from '../../../components/RelatedToGraph/mapSettingProvider';
import { labelStyle } from './constants';

// ----------------------------------------------------

const useStyles = makeStyles((theme) => ({
  arrowDownward: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    top: -50,
    left: -10,
  },
  iconButtons: {
    padding: 0,
    backgroundColor: '#eeeeee',
    display: 'none'
  },
  nodeRoot: {
    position: "relative",
    background: "transparent",
    width: 50,
    height: 50,
    '&:hover $iconsBox': {
      visibility: 'visible',
    },
    '&:hover $iconsBoxHidden': {
      visibility: 'visible',
    }
  },
  nodeRootSelected: {
    position: "relative",
    width: 50,
    height: 50,
    '&:hover $iconsBox': {
      visibility: 'visible',
    },
    '&:hover $iconsBoxHidden': {
      visibility: 'visible',
    },
    "& $circle": {
      border: "4px solid #8581fa",
    },
    "& $circleUp": {
      border: "4px solid #8581fa",
    },
    "& $circleDown": {
      border: "4px solid #8581fa",
    },
    "& $circleFewDown": {
      border: "4px solid #8581fa",
    }
  },
  circle: {
    border: "1px solid #81d4fa",
    width: 50,
    height: 50,
    borderRadius: "50%",
    background: "#b3e5fc",
    animationName: '$blinkerUp',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  circleUp: {
    border: "1px solid #81d4fa",
    width: 50,
    height: 50,
    borderRadius: "50%",
    fill: "rgb(0, 150, 0)",
  },
  circleDown: {
    border: "1px solid #81d4fa",
    width: 50,
    height: 50,
    borderRadius: "50%",
    fill: "#b3e5fc",
    animationName: '$blinkerDown',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',

  },
  circleDanggled: {
    border: "1px solid #81d4fa",
    width: 50,
    height: 50,
    borderRadius: "50%",
    fill: "#b3e5fc",
    animationName: '$blinkerFewDown',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  circleNoDevice: {
    border: "1px solid black",
    width: 50,
    height: 50,
    borderRadius: "50%",
    fill: "black",
    animationName: '$blinkerNoDevices',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  iconsBox: {
    visibility: 'visible',
    marginTop: -1
  },
  iconsBoxHidden: {
    visibility: 'hidden',
  },
  childrenLoadingLoader: {
    position: "absolute",
    bottom: -3,
    left: 17
  },
  clickToView: {
    cursor: "pointer",
    "&:hover": {
      background: "#f1f1f1"
    }
  },
  statusContainer: {
    position: 'absolute',
    left: '40px',
    paddingLeft: '15px',
    top: '7px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '40px',
  },
  '@keyframes blinkerDown': {
    from: { fill: "rgb(255 0 0)" },
    to: { fill: "#000000" },
  },
  '@keyframes blinkerFewDown': {
    from: { fill: "rgb(255, 191, 0)" },
    to: { fill: "#000000" },
  },
  '@keyframes blinkerNoDevices': {
    from: { fill: "black" }, 
    to: { fill: "black" },    
  },


  popper: {
    zIndex: 10000,
  },
}));

// -------------------------------------------------
export const CustomLocationNode = React.memo(
  ({ data, id, position, xPos, yPos, ...props }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { mapSettingState } = useMapSettingContext()
    const locationData = useSelector((state) => state.locationSelectReducer.globalmap.locationData);
    const [nodeSelected, setNodeSelected] = useState(false);
    const edges = useEdges();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ifConnectionExists = data?.connectivity_exists

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setNodeSelected(locationData.findIndex((loc) => { return loc === id }) !== -1)
    }, [locationData])

    const renderLinkLocalPort = useCallback(() => {
      const _sourceEdges = edges.filter((e) => e.source === id && e.target === id)
      const uniqueRemotePorts = new Set();
      const ports = [];
      _sourceEdges.map((i, idx) => {
        if (!uniqueRemotePorts.has(i.data.r_link_local_interface)) {
          uniqueRemotePorts.add(i.data.r_link_local_interface)
          ports.push({ port: i.data.r_link_local_interface, status: i.data.r_link_status })
        }
      })
      return ports.map(i => {
        return <p key={i.port} className={i.status === "up" ? classes.up : classes.down}>
          {i.port}
        </p>
      })
    }, [edges]);

    const handleNetworkMap = useCallback((item) => {
      // const networkLocUuid = item._uid;
      const networkLocUuid = item.location_id;
      navigate(`/dashboard/sitewise-map?locationUuid=${networkLocUuid}`);
    })

    const viewUpDevices = useCallback((item) => {
      // const networkLocUuid = item._uid;
      const networkLocUuid = item.location_id;

      navigate(`/dashboard/dashboard/device-up?locationUuid=${networkLocUuid}`);
    })

    const viewDownDevices = useCallback((item) => {

      // const networkLocUuid = item._uid;
      const networkLocUuid = item.location_id;
      navigate(`/dashboard/dashboard/device-down?locationUuid=${networkLocUuid}`);
    })

    const viewInventory = useCallback((item) => {


      // const networkLocUuid = item._uid;
      const networkLocUuid = item.location_id;
      navigate(`/dashboard/network-device?locationUuid=${networkLocUuid}`);
    })



    // ---------------------------------------------------------
    return (
      <x.div
        className={nodeSelected ? classes.nodeRootSelected : classes.nodeRoot}
        aria-describedby={open ? 'mouse-over-popper' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <x.div
          display="flex"
          alignItems="center"
          flexDirection="column"
          spaceY={0}
          spaceX={0}
        >
          <x.div
            h={50}
          >
            <Handle
              type="target"
              position={Position.Top}
              style={{ background: '#b3e5fc', border: 'none', display: 'none' }}
            />

            <x.div>
              {/* <MyLocation
                className={data?.networkDeviceUpCount > 0 && data.networkDeviceDownCount > 0 ? classes.circleDanggled : data.networkDeviceUpCount > 0 ? classes.circleUp : classes.circleDown}
              /> */}
              <MyLocation
                className={
                  data?.networkDeviceCount === 0 &&
                    data.networkDeviceDownCount === 0 &&
                    data.networkDeviceUpCount === 0
                    ? classes.circleNoDevice
                    : data.networkDeviceUpCount > 0 && data.networkDeviceDownCount > 0
                      ? classes.circleDanggled
                      : data.networkDeviceUpCount > 0
                        ? classes.circleUp
                        : classes.circleDown
                }
              />

            </x.div>

            <Handle
              type="source"
              position={Position.Bottom}
              style={{ background: '#b3e5fc', border: 'none', display: 'none' }}
            />
          </x.div>

          {mapSettingState.showGlobalLocationName && (
            <x.p {...labelStyle}>
              {data.r_location_name}
            </x.p>
          )}


          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="left-start"
            container={document.getElementById("react-flow-global-map")}
            className={classes.popper}
          >
            <Paper elevation={7} sx={{ px: 1, py: 1 }}>
              <Box sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'row', letterSpacing: '2px' }}>
                <Typography variant="h6">{toCamelizeWords(data.r_location_name)}</Typography>
              </Box>
              <Divider sx={{ height: '3px' }} />
              <Box sx={{ px: 2, py: 1 }}>
                <Box
                  className={classes.clickToView}
                  onClick={() => viewInventory(data)}
                  sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px', cursor: "pointer", px: 1, py: 1 }}
                >
                  <SettingsInputAntenna sx={{ color: '#673ab7' }} size="inherit" />&ensp;
                  <Typography variant="subtitle2">{data.networkDeviceCount} Devices</Typography>
                </Box>
                <Box
                  className={classes.clickToView}
                  onClick={() => viewUpDevices(data)}
                  sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px', cursor: "pointer", px: 1, py: 1 }}
                >
                  <ArrowUpward color="success" size="inherit" />&ensp;
                  <Typography variant="subtitle2"> {data.networkDeviceUpCount} Up</Typography>
                </Box>
                <Box
                  className={classes.clickToView}
                  onClick={() => viewDownDevices(data)}
                  sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px', cursor: "pointer", px: 1, py: 1 }}
                >
                  <ArrowDownward color="error" size="inherit" />&ensp;
                  <Typography variant="subtitle2">{data.networkDeviceDownCount} Down</Typography>
                </Box>
                {!ifConnectionExists && <Box
                  className={classes.clickToView}
                  sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px', cursor: "pointer", px: 1, py: 1 }}
                >
                  <PriorityHighIcon sx={{ color: 'orange', size: "inherit" }} />&ensp;
                  <Typography variant="subtitle2" >No tunnels found,<br /> please check ssh credentials or<br /> if device is up or not.
                  </Typography>
                </Box>}
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', px: 1, py: 1 }}
                >
                  {renderLinkLocalPort()}
                </Box>


                <Box
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', px: 1, py: 1 }}
                >
                  <Button variant="outlined" onClick={() => handleNetworkMap(data)}>
                    View network
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Popper>

        </x.div>
      </x.div>
    );
  }
);


