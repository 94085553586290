import { Folder } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Divider,
  Grid, Paper, Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { WithConfirm } from '../../WithConfirm';
import APITable from '../Table/APITable';
import SNMPTable from '../Table/SNMPTable';
import SSHTable from '../Table/SSHTable';
import AddCredentials from './AddCredentials';
import UploadCredential from './UploadCredential';

// -----------------------------------------------------------------------------
const CredentialTable = (props) => {
  const [credentialData, setCredentialData] = useState(null);
  const [credentialDialog, setCredentialDialog] = useState(false);
  const [uploadCredentialDialog, setUploadCredentialDialog] = useState(false);
  const [value, setValue] = useState(0);


  const handleDialogClose = () => {
    setCredentialDialog(false);
    setCredentialData(null);
  };

  const handleCloseUploadCredentialDialog = () => {
    setUploadCredentialDialog(false);
    setCredentialData(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }
  // ----------------------------------------------------------------------------------------------

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h6" gutterBottom sx={{ ml: 1, marginTop: '8px' }}>
                  {' '}
                  Credentials{' '}
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
            
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setUploadCredentialDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                  startIcon={<Folder />}
                >
                  Import file
                </Button>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setCredentialDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                >
                  Add Credentials
                </Button>
              </Grid>

              {/* <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1.8 }}>
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid> */}
            </Grid>
          }
        />

        <Divider />
        <CardContent style={{ padding: '0px'}}>
          <Paper style={{ height: '646px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="SNMP" />
              <Tab label="SSH" />
              <Tab label="API" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <SNMPTable />
            </TabPanel>


            <TabPanel value={value} index={1}>
              <SSHTable />
            </TabPanel>
            

            <TabPanel value={value} index={2}>
              <APITable />
            </TabPanel>
            

            {uploadCredentialDialog && (
              <UploadCredential
                openDialog={uploadCredentialDialog}
                handleClose={handleCloseUploadCredentialDialog}
              />
            )}
            {credentialDialog && (
              <AddCredentials
                openDialog={credentialDialog}
                handleClose={handleDialogClose}
              />
            )}
           
          </Paper>
        </CardContent>
      </Card>
    </>
  );
};
export default WithConfirm(CredentialTable);
