import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useReducer } from 'react';
import { timeRangeOptions } from "./timeRangeOptions";




export const HealthContext = createContext({});


export const healthDashboardReducer = (state, action = {}) => {
  switch (action.type) {

    case 'SET_DEVICE':
      return {
        ...state,
        selectedDevice: action.payload,
      }
    case 'SET_TIME_RANGE':
      return {
        ...state,
        timeRange: action.payload,
      }
    case 'SET_LOCATION':
      return {
        ...state,
        locationuuid: action.payload,
      }
    case 'SET_Interface':
      return {
        ...state,
        interface: action.payload,
      }
    default:
      return state
  }
}



export const HealthProvider = ({ children }) => {
  const initialState = {
    selectedDevice: null,
    locationuuid: '',
    step: 1,
    timeRange: '5m',
    interface: '',
  };

  const [healthDashState, healthDashDispatch] = useReducer(
    healthDashboardReducer, {...initialState, ...JSON.parse(localStorage.getItem("healthDashState"))} 
  );


  const getStartEnd = () => {
    const selectedOption = timeRangeOptions.find((i) => i.id === healthDashState.timeRange)
    let callable = () => { }
    if (selectedOption) {
      callable = selectedOption.calculatevalue
    } else {
      callable = timeRangeOptions[0].calculatevalue
    }
    return callable();
  }

  useEffect(() => {
    localStorage.setItem("healthDashState", JSON.stringify({step:healthDashState.step, timeRange:healthDashState.timeRange}));
  }, [healthDashState]);

  return <HealthContext.Provider value={{ healthDashState, healthDashDispatch, getStartEnd }}>
    {children}
  </HealthContext.Provider>
}


export const useHealthContext = () => {
  return useContext(HealthContext);
};

HealthProvider.propTypes = {
  children: PropTypes.object,
};