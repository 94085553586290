/* eslint-disable*/
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { unApproveDeviceSummaryChange } from '../../../../../API/CMDBOperations';
import { UserContext } from "../../../../../context/UserContext";
import SummaryDiff from './SummaryDiff';

// -----------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// --------------------------------------------------------------------------
function UnapproveDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, data, cmdbGroupSummaryEvent } = props;
  const [unapproveValue, setUnapproveValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false)
  const [enable, setEnable] = useState(false);
  const [alertText, setAlertText] = useState('');
  // ------------------------------------------------------------------------------

  useEffect(() => {
    const a = data?.c_m_d_b_network_device?.r_dvc_latest_approved_config_change_version;
    const b = data?.r_dvc_config_change_version;

    if (b - 1 === a) {
      setAllowed(true)
      setAlertText("Are you sure you want to un-approve ?")
    }
    if (b - 1 > a) {
      setAllowed(false)
      setAlertText(" Cannot approve this change as there are past un-approved changes. Please review them and then proceed.")
    }
    if (b - 1 < a) {
      setAllowed(false)
      setAlertText("The change is already approved")
    }
    if (data?.r_criticality == "UN_APPROVED") {
      setAllowed(false)
      setAlertText("The change is already in un-approved")
    }
  }, [data])

  useEffect(() => {
    if (allowed === true) {
      if (unapproveValue.length > 0) {
        setEnable(true);
        return
      }
    }
    setEnable(false)
  }, [allowed, unapproveValue])

  const handleTextfield = (e) => {
    setUnapproveValue(e.target.value);
  };

  const handleConfirm = () => {
    const payload = {
      message: unapproveValue,
    }
    setLoading(true);
    unApproveDeviceSummaryChange(customerId, data._uid, payload)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Changes are set to unapproved',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
        props.api.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  }
  // -------------------------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box style={{ textAlign: 'left' }}>
            Un-Approve change <br />
            <Typography variant='body2' gutterBottom>
              {data?.c_m_d_b_network_device?.r_dvc_ip_address} - {data?.c_m_d_b_network_device?.r_dvc_serial_number}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SummaryDiff cmdbGroupSummaryEvent={cmdbGroupSummaryEvent} />
        <Alert severity="warning">
          {alertText}
        </Alert>

        <TextField
          fullWidth
          multiline
          rows={3}
          size="normal"
          type="text"
          label="Message *"
          variant="outlined"
          placeholder="Add Message"
          onChange={handleTextfield}
          InputLabelProps={{ shrink: true }}
        // error={!enable}
        // helperText={!enable ? 'Please write message' : ''}
        />
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!enable}
          onClick={handleConfirm}
          loading={loading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default UnapproveDialog;
