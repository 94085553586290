// @mui
import {
    Grid,
  } from '@mui/material';
  import SidewiseCard1 from './SidewiseCard1';
  import SidewiseCard2 from './SidewiseCard2';
  // components
  // ----------------------------------------------------------------------
  
  export default function SidewiseTotalLinks() {

    return (
      <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SidewiseCard1 />
            </Grid>
          </Grid>
      </>
    );
  }
  