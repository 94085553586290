import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const DateInputDialog = ({ open, onClose, minDate }) => {
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleConfirm = () => {
    if (selectedDate) {
      onClose(selectedDate); 
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(null)} maxWidth="xs" fullWidth>
      <DialogTitle>Select Hold Date</DialogTitle>
      <DialogContent>
        <TextField
          label="Hold Until"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: minDate }} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(null)} color="primary">Cancel</Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

DateInputDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  minDate: PropTypes.string.isRequired, 
};

export default DateInputDialog;
