/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
 import { networkHealthMemoryUsage } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 259,
}));

// ---------------------------------------


const MemoryChart = ({systemExpanded}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [chartLoading, setChartLoading] = useState(true);

    const solveCondition = async() => {
        if(!healthDashState.selectedDevice || systemExpanded ===false){
            return '';
        }
        fetchMemoryUsage();
    };

    const fetchMemoryUsage = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start/1000,
            end: data.end/1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setChartLoading(true);
        networkHealthMemoryUsage(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                setSeriesData(res?.data?.series)
            })
            .catch(() => {
                setChartLoading(false);
            });
    };
        
    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, systemExpanded]);

    const chartSeries = seriesData;

    const chartOptions = {
        chart: {
            height: 380,
            type: 'radialBar',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
        },
        stroke: {
            lineCap: "round",
        },
        colors:['#ffd54f'],
        plotOptions: {
            radialBar: {
                inverseOrder: false,
                startAngle: -110,
                endAngle: 110,
                offsetX: 0,
                offsetY: -15,
                hollow: {
                    margin: 0,
                    size: "60%"
                },
                track: {
                    show: true,
                    startAngle: -110,
                    endAngle: 110,
                    background: "#f2f2f2",
                    strokeWidth: "100%",
                    opacity: 1,
                    margin: 1,
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    }
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: false,
                        fontSize: "1.0rem",
                        fontWeight: 600,
                        offsetY: 65
                    },
                    value: {
                        show: true,
                        fontSize: "2.5rem",
                        offsetY: 20,
                        formatter: function (val) {
                            return val + "%";
                        },
                    },
                    total: {
                        show: false,
                    }
                }
            },
        },
        legend: {
            show: false
        },
    };


    return (
        <>
            {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Memory Usage
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                        <ChartWrapperStyle dir="ltr">
                                <ReactApexChart series={chartSeries} options={chartOptions} type="radialBar" height={380} />
                        </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default MemoryChart;
