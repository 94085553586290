import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Paper, CardContent, Typography, Box } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import cardStyles from '../../styles/cardsstyles';

const appExperienceData = {
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
      barHeight: '30%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  series: [
    {
      data: [
        {
          x: 'Office 365',
          y: 410,
        },
        {
          x: 'Firefox',
          y: 430,
        },
        {
          x: 'Dropbox',
          y: 480,
        },
        {
          x: 'Slack',
          y: 300,
        },
        {
          x: 'Spotify',
          y: 350,
        },
      ],
    },
  ],
};

const AppExperienceChart = (props) => {
  const classes = cardStyles();
  return (
    <Paper elevation={15} sx={{ height: '100%' }}>
      <Box style={{ display: 'flex', flexDirection: 'row', padding: 12 }}>
        <DisplaySettingsIcon className={classes.boxTitleIcon} />
        <Typography variant="h6" component="div" className={classes.boxTitle}>
          App Experience
        </Typography>
        <MoreHorizIcon
          style={{
            fontSize: '1.5rem',
            color: '#e0e0e0',
            float: 'right',
            borderRadius: '2px',
            marginTop: '15px',
            marginRight: '15px',
            borderColor: '#eeeeee',
          }}
        />
      </Box>
      <Box sx={{ padding: 3 }}>
        <ReactApexChart options={appExperienceData} series={appExperienceData.series} type="bar" />
      </Box>
    </Paper>
  );
};
export default AppExperienceChart;
