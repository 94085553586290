import { Grid } from '@mui/material';
import Notifications from './Components/Notifications';
// ----------------------------------------------------------------------

export default function GeneralComponents() {
  return (
    <>
      <Grid container>
        <Grid item sm={12} lg={7} md={12}>
          <Notifications />
        </Grid>
      </Grid>
    </>
  );
}
