import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineOutlined, Close, Delete, EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  Slide
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { addRole, getPermissions } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from '../../../utils/formatString';
import { FormProvider, RHFTextField } from '../../hook-form';

 // ------------------------------------------------------------------------------------

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const permissionIcons = {
  create: AddCircleOutlineOutlined,
  update: EditOutlined,
  read: RemoveRedEyeOutlined,
  delete: Delete,
};

 // ------------------------------------------------------------------------------------
export default function AddRoleDialog(props) {
  const classes = useStyles();
  const { openDialog, handleClose, gridApi, role } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [rolePermission, setRolePermissions] = useState([]);

 // ------------------------------------------------------------------------------------


 const _getPermissions = useCallback(async () => {
  setLoading(true);
    try {
      const permissions = await getPermissions(customerId);
      if (!role) {
        setRolePermissions(
          Object.keys(permissions)
            .map((group) => {
              return [
                ...Object.keys(permissions[group]).map((ability) => {
                  return {
                    ability,
                    group,
                    name: permissions[group][ability],
                  };
                }),
              ];
            })
            .reduce((value, pre) => [...pre, ...value])
        );
      } else {
        setRolePermissions(role.permissions);
      }
      setPermissionOptions(permissions);
    } catch (error) {
      enqueueSnackbar("Couldn't receive permissions list, please try later");
      setLoading(false);
      handleClose();
    } finally {
      setLoading(false);
    }
  }, [customerId, enqueueSnackbar, handleClose, role]); 

  useEffect(() => {
    _getPermissions();
  }, [_getPermissions]);

  const membersSchema = Yup.object().shape({
    rolename: Yup.string().required('Role name is required'),
  });

  const defaultValues = {
    rolename: '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = async (formData) => {
    const permissions = {};
    console.log(rolePermission,"rolePermission");
    rolePermission.forEach((item) => {
      if (permissions[item.group]) {
        permissions[item.group].push(item.ability);
      } else {
        permissions[item.group] = [item.ability];
      }
    });
    const data = {
      role_name: formData.rolename,
      permissions,
    };
;
    setLoading(true);
    addRole(customerId, data)
      .then(() => {
        setLoading(false);
        handleClose();
        gridApi.purgeInfiniteCache(); 
        enqueueSnackbar(
          'Role added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const toolgePermissoin = (group, ability, name) => {
    const ItemIndex = rolePermission.findIndex(
      (item) => item.group === group && item.ability === ability && item.name === name
    );

    if (ItemIndex === -1) {
      setRolePermissions((_rolePermission) => {
        return [
          ..._rolePermission,
          {
            group,
            ability,
            name,
          },
        ];
      });
    } else {
      setRolePermissions((_rolePermission) => {
        return [
          ..._rolePermission.filter((item) => item.group !== group || item.ability !== ability || item.name !== name),
        ];
      });
    }
  };

  const isActiveAbility = (group, ability, name) => {
    return (
      rolePermission.findIndex((item) => item.group === group && item.ability === ability && item.name === name) === -1
    );
  };

  const renderIcon = (group, ability, name, key) => {
    const IconItem = permissionIcons[ability];
    return (
      <IconButton
        key={key}
        onClick={() => {
          toolgePermissoin(group, ability, name);
        }}
      >
        <IconItem color={isActiveAbility(group, ability, name) ? 'disabled' : 'primary'} />
      </IconButton>
    );
  };

  // ---------------------------------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        transition={Slide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Add role
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <RHFTextField name="rolename" label="Role Name" placeholder="Enter your role name" />

            {Object.keys(permissionOptions).map((group, key) => (
              <div key={key} style={{ margin: '7px 0' }}>
                <Paper
                  elevation={3}
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
                >
                  <Typography sx={{ marginLeft: 1, fontSize: '13px' }}>{toCamelizeWords(group)}</Typography>
                  <div>
                    {Object.keys(permissionOptions[group]).map((ability, key) =>
                      renderIcon(group, ability, permissionOptions[group][ability], key)
                    )}
                  </div>
                </Paper>
              </div>
            ))}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add role
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

AddRoleDialog.propTypes = {
  gridApi: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  role: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.object),
  }),
};
