const globalConfig = [
    {
      key: `up-down-v3i-pooling-seconds`,
      label: 'Up/Down polling interval',
      valueComponent: 'text',
      defaultValue: '',
    },
    {
      key: `up-down-v3i-status-job-delay-seconds`,
      label: 'Up/Down status cool down period',
      valueComponent: 'text',
      defaultValue: '',
    },
    {
      key: "up-down-v3i-status-notify-delay-seconds",
      label: 'Email notify after status cool down period',
      valueComponent: 'text',
      defaultValue: '',
    },
  ];
  export default globalConfig;
