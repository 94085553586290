// import {
//   MonitorHeart,
//   Pause, PlayArrow
// } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
// import {
//   Box,
//   IconButton,
//   Stack, Tooltip
// } from '@mui/material';
// import { useSnackbar } from 'notistack';
// import PropTypes from 'prop-types';
// import { useContext, useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { networkHealthCpuUsage } from '../../../../API/NetworkDevices/health';
// import { pauseUpDown, resumeUpDown } from '../../../../API/CMDBOperations/index';
// import { UserContext } from '../../../../context/UserContext';
// import { WithConfirm } from '../../../WithConfirm';
// import { useHealthContext } from '../../../FirewallHealth/components/userContextHealth';




// // ----------------------------------------------------------------------


// // ----------------------------------------------------------------


// const ActionRender = (props) => {
//   const navigate = useNavigate();
//   const { healthDashState, getStartEnd } = useHealthContext();
//   const { data, confirm } = props;
//   const { enqueueSnackbar } = useSnackbar();
//   const { customerId } = useContext(UserContext);
//   const [skipLoading, setSkipLoading] = useState(false);
//   const [unskipLoading, setUnskipLoading] = useState(false);
//   const [showDashboard, setShowDashboard] = useState(false);
//   const [pingable, setPingable] = useState();

//   const [triggerNavigation, setTriggerNavigation] = useState(false);


//   useEffect(() => {
//     if (data) {
//       if (Object.prototype.hasOwnProperty.call(data, "r_dvc_pingable")) {
//         setPingable(data?.r_dvc_pingable)
//       }
//     }
//   }, [data]);

//   useEffect(() => {
//     if(data?.l_dvc_typ_name === "ACCESS_POINT" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC"){
//       setShowDashboard(false);
//     }
//     if(data?.l_dvc_typ_name === "SWITCH" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC"){
//       setShowDashboard(true);
//     }else{
//       setShowDashboard(true);
//     }
//   }, [data]);

//   useEffect(() => {
//     if (customerId && triggerNavigation) {
     
//       navigateToDashboard();
//       setTriggerNavigation(false);
//     }
//   }, [customerId, triggerNavigation]);

//   const navigateToDashboard = () => {
//     navigate(`/dashboard/health`);
//   };
  
// // const filteredData = data?.l_dvc_typ_name === 'WIRELESS'  ? data : null;
// // console.log(filteredData,'-------------------------filteredData');



//   const handleSkip = (value) => {
//     setSkipLoading(true);
//     pauseUpDown(customerId, value?.r_dvc_unique_identifier)
//       .then(() => {
//         setSkipLoading(false);
//         setPingable(0)
//         enqueueSnackbar(
//           'Device is removed from up down monitoring',
//           {
//             variant: 'success',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       })
//       .catch((res) => {
//         setSkipLoading(false);
//         enqueueSnackbar(
//           res.response.data.message,
//           {
//             variant: 'error',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       });
//   }

//   const handleUnskip = (value) => {
//     setUnskipLoading(true);
//     resumeUpDown(customerId, value?.r_dvc_unique_identifier)
//       .then(() => {
//         setUnskipLoading(false);
//         setPingable(1)
//         enqueueSnackbar(
//           'Device is set for up down monitoring',
//           {
//             variant: 'success',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       })
//       .catch((res) => {
//         setUnskipLoading(false);
//         enqueueSnackbar(
//           res.response.data.message,
//           {
//             variant: 'error',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       });
//   }

//   // const handleNavigate = async (data) => {
//   //   try {
//   //     if (!data?.r_dvc_unique_identifier || !data?.location_id) {
//   //       throw new Error('Required data fields are missing.');
//   //     }
  
//   //     const startEndData = getStartEnd();
     
//   //     const payload = {
       
//   //       start: startEndData.start / 1000,
//   //       end: startEndData.end / 1000,
//   //       step: startEndData.step,
//   //       device_type: data?.r_dvc_unique_identifier,
//   //     };
//   //     const cpuUsageData = await networkHealthCpuUsage(customerId, payload);
//   //     setTriggerNavigation(true);
//   //     navigate(`/dashboard/health?locationUuid=${data?.location_id}&identifier=${data?.r_dvc_unique_identifier}`,{state : {cpuUsageData,deviceTypeName: data?.l_dvc_typ_name, rDvcController: data?.r_dvc_controller}});
//   //   } catch (error) {
//   //     console.error('API Error:', error);
//   //     enqueueSnackbar('Failed to fetch CPU usage data', {
//   //       variant: 'error',
//   //       anchorOrigin: {
//   //         vertical: 'bottom',
//   //         horizontal: 'right',
//   //       },
//   //     });
//   //   }
//   // };

//   const handleNavigate = async (data) => {
//   try {
//     if (!data?.r_dvc_unique_identifier || !data?.location_id) {
//       throw new Error('Required data fields are missing.');
//     }

//     const startEndData = getStartEnd();

//     const payload = {
//       start: startEndData.start / 1000,
//       end: startEndData.end / 1000,
//       step: startEndData.step,
//       device_type: data?.r_dvc_unique_identifier,
//     };

//     const cpuUsageData = await networkHealthCpuUsage(customerId, payload);

//     // Navigate to the correct path
//     navigate(`/dashboard/health?locationUuid=${data?.location_id}&identifier=${data?.r_dvc_unique_identifier}`, {
//       state: {
//         cpuUsageData,
//         deviceTypeName: data?.l_dvc_typ_name,
//         rDvcController: data?.r_dvc_controller
//       }
//     });
//   } catch (error) {
//     console.error('API Error:', error);
//     enqueueSnackbar('Failed to fetch CPU usage data', {
//       variant: 'error',
//       anchorOrigin: {
//         vertical: 'bottom',
//         horizontal: 'right',
//       },
//     });
//   }
// };

  

//   if (props.data === undefined) {
//     return '';
//   }
//   if (props?.data) {
    
//     return (
//       <>
//         <Stack direction="row" spacing={2}>
//           <Box>
//             {(pingable === 1) || (pingable === null) || (pingable === " ")? (
//               <LoadingButton
//                 variant="outlined"
//                 loading={skipLoading}
//                 startIcon={<Pause style={{ color: "red" }} />}
//                 onClick={confirm(() => handleSkip(data), {
//                   title: 'Information',
//                   confirmationText: 'Yes',
//                   disableOkBtn: false,
//                   description: 'Are you sure you want to remove from up down monitoring ?',
//                 })}
//               >
//                 Skip scan
//               </LoadingButton>

//             ) : (
//               <LoadingButton
//                 variant="outlined"
//                 loading={unskipLoading}
//                 startIcon={<PlayArrow style={{ color: "green" }} />}
//                 onClick={confirm(() => handleUnskip(data), {
//                   title: 'Information',
//                   confirmationText: 'Yes',
//                   disableOkBtn: false,
//                   description: 'Are you sure you want to add device to up down monitoring ?',
//                 })}
//               >
//                 Resume scan
//               </LoadingButton>
//             )
//             }

//           </Box>

//           {showDashboard && (
//             <Box>
//               <Tooltip title="Go to health dashboard">
//                 <IconButton
//                   // onClick={() => {
//                   //   navigate(`/dashboard/health?locationUuid=${data?.location_id}&identifier=${data?.r_dvc_unique_identifier}`);
//                   // }}
//                   // onClick={() => handleNavigate(data?.location_id, data?.r_dvc_unique_identifier)}
//                   onClick={() => handleNavigate(data)}
//                 >
//                   <MonitorHeart color="#212121" />
//                 </IconButton>
//               </Tooltip>
//             </Box>
//           )}
//         </Stack>
//       </>
//     )
//   }
//   return null;
// };


// ActionRender.propTypes = {
//   data: PropTypes.object,
//   confirm: PropTypes.func.isRequired,
// };

// export default WithConfirm(ActionRender);


import { MonitorHeart, Pause, PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { networkHealthCpuUsage } from '../../../../API/NetworkDevices/health';
import { pauseUpDown, resumeUpDown } from '../../../../API/CMDBOperations/index';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import { useHealthContext } from '../../../FirewallHealth/components/userContextHealth';

const ActionRender = (props) => {
  const navigate = useNavigate();
  const { healthDashState, getStartEnd } = useHealthContext();
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [skipLoading, setSkipLoading] = useState(false);
  const [unskipLoading, setUnskipLoading] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [pingable, setPingable] = useState();
  const [dashboardLoading, setDashboardLoading] = useState(false); // State for dashboard loading indicator

  useEffect(() => {
    if (data) {
      if (Object.prototype.hasOwnProperty.call(data, "r_dvc_pingable")) {
        setPingable(data?.r_dvc_pingable);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.l_dvc_typ_name === "ACCESS_POINT" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC") {
      setShowDashboard(false);
    }
    if (data?.l_dvc_typ_name === "SWITCH" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC") {
      setShowDashboard(true);
    } else {
      setShowDashboard(true);
    }
  }, [data]);

  const handleSkip = (value) => {
    setSkipLoading(true);
    pauseUpDown(customerId, value?.r_dvc_unique_identifier)
      .then(() => {
        setSkipLoading(false);
        setPingable(0);
        enqueueSnackbar(
          'Device is removed from up down monitoring',
          { variant: 'success' }
        );
      })
      .catch((res) => {
        setSkipLoading(false);
        enqueueSnackbar(res.response.data.message, { variant: 'error' });
      });
  };

  const handleUnskip = (value) => {
    setUnskipLoading(true);
    resumeUpDown(customerId, value?.r_dvc_unique_identifier)
      .then(() => {
        setUnskipLoading(false);
        setPingable(1);
        enqueueSnackbar(
          'Device is set for up down monitoring',
          { variant: 'success' }
        );
      })
      .catch((res) => {
        setUnskipLoading(false);
        enqueueSnackbar(res.response.data.message, { variant: 'error' });
      });
  };

  const handleNavigate = async (data) => {
    try {
      setDashboardLoading(true); // Start dashboard loading
      if (!data?.r_dvc_unique_identifier || !data?.location_id) {
        throw new Error('Required data fields are missing.');
      }

      const startEndData = getStartEnd();
      const payload = {
        start: startEndData.start / 1000,
        end: startEndData.end / 1000,
        step: startEndData.step,
        device_type: data?.r_dvc_unique_identifier,
      };

      const cpuUsageData = await networkHealthCpuUsage(customerId, payload);

      navigate(`/dashboard/health?locationUuid=${data?.location_id}&identifier=${data?.r_dvc_unique_identifier}`, {
        state: {
          cpuUsageData,
          deviceTypeName: data?.l_dvc_typ_name,
          rDvcController: data?.r_dvc_controller,
        },
      });
    } catch (error) {
      enqueueSnackbar('Failed to fetch CPU usage data', { variant: 'error' });
    } finally {
      setDashboardLoading(false); // Stop dashboard loading
    }
  };

  if (props.data === undefined) {
    return '';
  }
  if (props?.data) {
    return (
      <>
        <Stack direction="row" spacing={2}>
          <Box>
            {pingable === 1 || pingable === null || pingable === " " ? (
              <LoadingButton
                variant="outlined"
                loading={skipLoading}
                startIcon={<Pause style={{ color: "red" }} />}
                onClick={confirm(() => handleSkip(data), {
                  title: 'Information',
                  confirmationText: 'Yes',
                  description: 'Are you sure you want to remove from up down monitoring?',
                })}
              >
                Skip scan
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="outlined"
                loading={unskipLoading}
                startIcon={<PlayArrow style={{ color: "green" }} />}
                onClick={confirm(() => handleUnskip(data), {
                  title: 'Information',
                  confirmationText: 'Yes',
                  description: 'Are you sure you want to add device to up down monitoring?',
                })}
              >
                Resume scan
              </LoadingButton>
            )}
          </Box>

          {showDashboard && (
            <Box>
              <Tooltip title="Go to health dashboard">
                <IconButton onClick={() => handleNavigate(data)}>
                  {dashboardLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <MonitorHeart color="#212121" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Stack>
      </>
    );
  }
  return null;
};

ActionRender.propTypes = {
  data: PropTypes.object,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(ActionRender);
