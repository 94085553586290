import { Grid } from '@mui/material';
import GlobalGraph from './GlobalComponents/GlobalGraph';

//----------------------------------------------------------------------
export default function GlobalMapComponent( ) {

  return (
    <>
      <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <GlobalGraph />
          </Grid>
      </Grid>
    </>
  );
}
