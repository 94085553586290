
import { Autocomplete, CircularProgress, Skeleton, TextField } from "@mui/material";
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocationsWithoutPagination } from '../../../API/Locations/showLocations';
import { UserContext } from '../../../context/UserContext';
import { useHealthContext } from './userContextHealth';


export default function LocationSingleSelector() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { healthDashDispatch } = useHealthContext();
  const { customerId } = useContext(UserContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [locationData, setLocationData] = useState({});
  

  const getQueryParams = () => {    
    return queryString.parse(window.location.search);
  };


  useEffect(() => {
    if (locationOptions ) {
      
      const locationUuidQuery = getQueryParams().locationUuid      
      // const location = locationOptions.find((option) => (option?.location_id) === locationUuidQuery)
      const location = locationOptions.find((option) => String(option?.location_id) === String(locationUuidQuery));
      setLocationData(location);
      healthDashDispatch({ type: 'SET_LOCATION', payload: locationUuidQuery });
    }
  }, [locationOptions, search]);

  const loadLoactionOptions = async () => {
    try {
      setLoadingData(true);
      getLocationsWithoutPagination(customerId).then((_locationOption) => {
        setLocationOptions(_locationOption?.data?.sort((a, b) => a.r_location_name - b.r_location_name));
        setLoadingData(false);
      });
    } catch (error) {
      setLoadingData(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadLoactionOptions();
  }, [customerId]);


  const handleSelectLocation = (event, selectedOptions) => {
    
    healthDashDispatch({ type: 'SET_DEVICE', payload: null });
    if (selectedOptions) {
      setLocationData(selectedOptions);
      navigate(`/dashboard/health?locationUuid=${selectedOptions.location_id}`);
    } else {
      healthDashDispatch({ type: 'SET_DEVICE', payload: null });
      setLocationData(null);
      navigate('/dashboard/health');
    }
  };

  if(loadingData) {
    return <Skeleton maxwidth={350} minwidth={200}/>
  }
  return (
    <div>
      <Autocomplete
        sx={{ maxwidth: 250, minwidth: 200 }}
        loading={loadingData}
        size="small"
        handleHomeEndKeys
        value={locationData || null}
        options={locationOptions}
        onChange={handleSelectLocation}
        isOptionEqualToValue={(option, value) => {
          return option?._uid === value?._uid
        }}
        getOptionLabel={(option) => {
          return option?.r_location_name || ""
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option?.r_location_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose Locations"
            label="Locations"
            type="text"
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {loadingData ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}