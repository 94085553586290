// import { yupResolver } from '@hookform/resolvers/yup';
// import { AddCircleOutlineOutlined, Close, Delete, EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
// import LoadingButton from '@mui/lab/LoadingButton';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   IconButton,
//   LinearProgress,
//   Paper,
//   Tooltip,
//   Typography
// } from '@mui/material';
// import Slide from '@mui/material/Slide';
// import { makeStyles } from '@mui/styles';
// import { useSnackbar } from 'notistack';
// import { useContext, useEffect, useState, useCallback } from 'react';
// import { useForm } from 'react-hook-form';
// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import { getPermissions, getRoleByUuid, updateRole } from '../../../API/Roles/roles';
// import { UserContext } from '../../../context/UserContext';
// import { toCamelizeWords } from '../../../utils/formatString';
// import { FormProvider, RHFTextField } from '../../hook-form';

// // -------------------------------------------------------------------------

// const useStyles = makeStyles({
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
// });

// const permissionIcons = {
//   create: AddCircleOutlineOutlined,
//   update: EditOutlined,
//   read: RemoveRedEyeOutlined,
//   delete: Delete,
// };

// const permissionTooltip = {
//   create: 'create',
//   update: 'update',
//   read: 'read',
//   delete: 'delete',
// };

// // ------------------------------------------------------------

// export default function ReadPermissionsDialog(props) {
//   const classes = useStyles();
//   const { enqueueSnackbar } = useSnackbar();
//   const { openDialog, handleClose, gridApi, roleuuid } = props;
//   const { customerId } = useContext(UserContext);
//   const [role, setRole] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [permissionOptions, setPermissionOptions] = useState([]);
//   const [rolePermission, setRolePermissions] = useState(null);

//   // --------------------------------------------------------------------

//   const fetchRoles = useCallback(async () => {
//     const roleData = await getRoleByUuid(customerId, roleuuid);
//     setRolePermissions(roleData.permissions);
//     setRole(roleData);
//   }, [customerId, roleuuid]);

//   const _getPermissions = useCallback(async () => {
//     setLoading(true);
//     try {
//       const permissions = await getPermissions(customerId);
//       if (roleuuid) {
//         setRolePermissions(
//           Object.keys(permissions)
//             .map((group) => {
//               return [
//                 ...Object.keys(permissions[group]).map((ability) => {
//                   return {
//                     ability,
//                     group,
//                     name: permissions[group][ability],
//                   };
//                 }),
//               ];
//             })
//             .reduce((value, pre) => [...pre, ...value])
//         );
//       } 
//       setPermissionOptions(permissions);
//     } catch (error) {
//       enqueueSnackbar("Couldn't receive permissions list, please try later");
//       setLoading(false);
//       handleClose();
//     } finally {
//       setLoading(false);
//     }
//   }, [customerId, roleuuid, enqueueSnackbar, handleClose]); 

//   useEffect(() => {
//     _getPermissions();
//     fetchRoles();
//   }, [_getPermissions, fetchRoles]);

//   const membersSchema = Yup.object().shape({
//     rolename: Yup.string(),
//   });

//   const defaultValues = {
//     rolename: role.name,
//   };

//   const methods = useForm({
//     resolver: yupResolver(membersSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     reset,
//   } = methods;

//   useEffect(() => {
//     // reset form with user data
//     // setRolenameValue({ rolename: role.name,})
//     reset({ rolename: role.name });
//   }, [role, reset]);

//   const onSubmit = async (formData) => {
//     const permissions = {};
//     rolePermission.forEach((item) => {
//       if (permissions[item.group]) {
//         permissions[item.group].push(item.ability);
//       } else {
//         permissions[item.group] = [item.ability];
//       }
//     });
//     const payload = {
//       role_name: formData.rolename,
//       permissions,
//     };

//     setLoading(true);
//     updateRole(customerId, roleuuid, payload)
//       .then(() => {
//         setLoading(false);
//         gridApi.purgeInfiniteCache();
//         handleClose();
//         enqueueSnackbar(
//           'Role updated sucessfully',
//           {
//             variant: 'success',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       })
//       .catch((res) => {
//         setLoading(false);
//         enqueueSnackbar(
//           res.response.data.message,
//           {
//             variant: 'error',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       });
//   };

//   const toolgePermissoin = (group, ability, name) => {
//     const ItemIndex = rolePermission.findIndex(
//       (item) => item.group === group && item.ability === ability && item.name === name
//     );

//     if (ItemIndex === -1) {
//       setRolePermissions((_rolePermission) => {
//         return [
//           ..._rolePermission,
//           {
//             group,
//             ability,
//             name,
//           },
//         ];
//       });
//     } else {
//       setRolePermissions((_rolePermission) => {
//         return [
//           ..._rolePermission.filter((item) => item.group !== group || item.ability !== ability || item.name !== name),
//         ];
//       });
//     }
//   };

//   const isActiveAbility = (group, ability, name) => {
//     return (
//       rolePermission.findIndex((item) => item.group === group && item.ability === ability && item.name === name) === -1
//     );
//   };

//   const renderIcon = (group, ability, name, key) => {
//     const IconItem = permissionIcons[ability];
//     if(!rolePermission){
//       return ""
//     }
//     return (
//       <IconButton
//         key={key}
//         onClick={() => {
//           toolgePermissoin(group, ability, name);
//         }}
//       >
//         <Tooltip title={permissionTooltip[ability]}>
//           <IconItem color={isActiveAbility(group, ability, name) ? 'disabled' : 'primary'} />
//         </Tooltip>
//       </IconButton>
//     );
//   };

//   // ----------------------------------------------------------

//   return (
//     <>
//       <Dialog
//         open={openDialog}
//         onClose={handleClose}
//         transition={Slide}
//         fullWidth
//         maxWidth="sm"
//         aria-labelledby="responsive-dialog-title"
//         classes={{
//           scrollPaper: classes.topScrollPaper,
//           paperScrollBody: classes.topPaperScrollBody,
//         }}
//       >
//         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//           <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//             Update role
//             <IconButton onClick={handleClose} sx={{ float: 'right' }}>
//               <Close sx={{ fontSize: '1.5rem' }} />
//             </IconButton>
//           </DialogTitle>
//           <Divider />
//           {loading && <LinearProgress />}
//           <DialogContent>
//             <RHFTextField name="rolename" label="Role name" placeholder="Enter role name" value={role.name || ''}/>

//             {Object.keys(permissionOptions).map((group, key) => (
//               <div key={key} style={{ margin: '7px 0' }}>
//                 <Paper
//                   elevation={3}
//                   sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
//                 >
//                   <Typography sx={{ marginLeft: 1, fontSize: '13px' }}>{toCamelizeWords(group)}</Typography>
//                   <div >
//                     {Object.keys(permissionOptions[group]).map((ability, key) =>
//                       renderIcon(group, ability, permissionOptions[group][ability], key)
//                     )}
//                   </div>
//                 </Paper>
//               </div>
//             ))}
//           </DialogContent>
//           <Divider />
//           <DialogActions>
//             <Button onClick={handleClose}>Cancel</Button>
//             <LoadingButton type="submit" variant="contained" loading={loading}>
//               update role
//             </LoadingButton>
//           </DialogActions>
//         </FormProvider>
//       </Dialog>
//     </>
//   );
// }

// ReadPermissionsDialog.propTypes = {
//   openDialog: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   gridApi: PropTypes.object.isRequired,
//   roleuuid: PropTypes.string.isRequired,
// };

import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddCircleOutlineOutlined,
  Close,
  Delete,
  EditOutlined,
  RemoveRedEyeOutlined
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { getPermissions, getRoleByUuid, updateRole } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from '../../../utils/formatString';
import { FormProvider, RHFTextField } from '../../hook-form';

// -------------------------------------------------------------------------

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const permissionIcons = {
  create: AddCircleOutlineOutlined,
  update: EditOutlined,
  read: RemoveRedEyeOutlined,
  delete: Delete,
};

const permissionTooltip = {
  create: 'Create',
  update: 'Update',
  read: 'Read',
  delete: 'Delete',
};

// ------------------------------------------------------------

export default function ReadPermissionsDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, gridApi, roleuuid } = props;
  const { customerId } = useContext(UserContext);
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [rolePermission, setRolePermissions] = useState([]);

  // --------------------------------------------------------------------

  const fetchRoles = useCallback(async () => {
    try {
      const roleData = await getRoleByUuid(customerId, roleuuid);
      setRole(roleData);
      setRolePermissions(roleData.permissions || []); // Ensure it's an array
    } catch (error) {
      enqueueSnackbar("Failed to fetch role data", { variant: 'error' });
      handleClose();
    }
  }, [customerId, roleuuid, enqueueSnackbar, handleClose]);

  const _getPermissions = useCallback(async () => {
    setLoading(true);
    try {
      const permissions = await getPermissions(customerId);
      setPermissionOptions(permissions);
    } catch (error) {
      enqueueSnackbar("Couldn't receive permissions list, please try later", { variant: 'error' });
      handleClose();
    } finally {
      setLoading(false);
    }
  }, [customerId, enqueueSnackbar, handleClose]);

  useEffect(() => {
    if (openDialog) {
      _getPermissions();
      fetchRoles();
    }
  }, [_getPermissions, fetchRoles, openDialog]);

  const membersSchema = Yup.object().shape({
    rolename: Yup.string().required('Role name is required'),
  });

  const defaultValues = {
    rolename: role.name || '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    // Reset form with role data
    reset({ rolename: role.name || '' });
  }, [role, reset]);

  const onSubmit = async (formData) => {
    const permissions = {};
    rolePermission.forEach((item) => {
      if (permissions[item.group]) {
        permissions[item.group].push(item.ability);
      } else {
        permissions[item.group] = [item.ability];
      }
    });
    const payload = {
      role_name: formData.rolename,
      permissions,
    };

    setLoading(true);
    try {
      await updateRole(customerId, roleuuid, payload);
      gridApi.purgeInfiniteCache();
      enqueueSnackbar('Role updated successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar(
        error.response?.data?.message || "Failed to update role",
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const togglePermission = (group, ability, name) => {
    const index = rolePermission.findIndex(
      (item) => item.group === group && item.ability === ability && item.name === name
    );

    if (index === -1) {
      setRolePermissions([...rolePermission, { group, ability, name }]);
    } else {
      setRolePermissions(rolePermission.filter(item => !(item.group === group && item.ability === ability && item.name === name)));
    }
  };

  const isActiveAbility = (group, ability, name) => {
    return rolePermission.some(item => item.group === group && item.ability === ability && item.name === name);
  };

  const renderIcon = (group, ability, name, key) => {
    const IconItem = permissionIcons[ability];
    if (!IconItem) return null;

    return (
      <IconButton
        key={key}
        onClick={() => {
          togglePermission(group, ability, name);
        }}
      >
        <Tooltip title={permissionTooltip[ability]}>
          <IconItem color={isActiveAbility(group, ability, name) ? 'primary' : 'disabled'} />
        </Tooltip>
      </IconButton>
    );
  };

  // ----------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Slide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Update Role
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <RHFTextField
              name="rolename"
              label="Role Name"
              placeholder="Enter role name"
            />

            {permissionOptions && Object.keys(permissionOptions).map((group, key) => (
              <div key={key} style={{ margin: '7px 0' }}>
                <Paper
                  elevation={3}
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
                >
                  <Typography sx={{ marginLeft: 1, fontSize: '13px' }}>{toCamelizeWords(group)}</Typography>
                  <div>
                    {Object.keys(permissionOptions[group]).map((ability, idx) =>
                      renderIcon(group, ability, permissionOptions[group][ability], idx)
                    )}
                  </div>
                </Paper>
              </div>
            ))}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Update Role
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

ReadPermissionsDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  gridApi: PropTypes.object.isRequired,
  roleuuid: PropTypes.string.isRequired,
};
