import {
    Refresh, Search
  } from '@mui/icons-material';
  import {
    Avatar, Box, Button, Card,
    CardHeader, Chip,
    Divider, Grid, IconButton, InputAdornment, LinearProgress, Link,
    TextField,
    Typography,
    useMediaQuery,
  } from '@mui/material';
  import CircularProgress from '@mui/material/CircularProgress';
  import { makeStyles } from '@mui/styles';
  import { AgGridReact } from 'ag-grid-react';
  import { useSnackbar } from 'notistack';
  import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
  import { pendingMembers } from '../../../API/Members/members';
  import { UserContext } from '../../../context/UserContext';
  import { defaultAgGridProps } from '../../../utils/agGridProps';
  import { WithConfirm } from '../../WithConfirm';
  import AddMemberDialog from './AddMemberDialog';
  import AddRoleDialog from './AddRoleDialog';
  import ActionsRenderer from './renderer/PendingActionRenderer';
  import RoleSelectorRenderer from './renderer/RoleSelectorRenderer';
  
  
  // ---------------------------------------------------------------------------------
  
  const useStyles = makeStyles({
    cardAvatar: {
      width: '28px',
      height: '28px',
      fontSize: '12px',
      marginLeft: '10px',
    },
    circularprogress: {
      position: 'absolute',
      left: 12,
      top: 18,
    },
    cardActionButtons: {
      height: '40px',
    },
    AvatarBox: {
      alignItems: 'center',
      display: 'flex'
    },
    Avatar: {
      height: 42,
      width: 42,
    },
  });
  
  // -----------------------------------------------------------------------------------------------------------
  
  const PendingMembers = () => {
    const classes = useStyles();
    const { customerId } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [query, setQuery] = useState('');
    const [count, setCount] = useState(0);
    const [gridApi, setGridApi] = useState(null);
    const [roleDialog, setroleDialog] = useState(false);
    const [membersDialog, setMembersDialog] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
  
    // ---------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------
  
    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');
  
  
  
    const getFontSize = () => {
      if (isLargeDesktop) {
        return '24px';
      }
      if (isDesktop) {
        return '20px';
      }
      if (isTablet) {
        return '14px';
      }
      return '12px';
    }
  
  
    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };
  
  
    // ----------------------------------------------------------------------------------------------------
  
    useEffect(() => {
      if (gridApi) {
        gridApi.purgeInfiniteCache();
      }
    }, [gridApi]);
  
    const handleQueryChange = (event) => {
      setQuery(event.target.value);
      gridApi?.purgeInfiniteCache();
    };
  
    const onGridReady = useCallback((params) => {
      setGridApi(params.api);
      params.api.sizeColumnsToFit();
  
      return params.api.setDatasource({
        rowCount: null,
        context: {
          global_keyword: query,
        },
  
        getRows: async (_params) => {
          setRefreshLoading(true);
          params.api.hideOverlay();
          const payload = {
            ..._params,
            global_keyword: _params.context.global_keyword,
            filterModel: {
              ..._params.filterModel,
            },
            sortModel: {
              ..._params.sortModel,
            },
          };
          delete payload.context;
          const pageCount = params.api.paginationGetCurrentPage() + 1;
          pendingMembers(customerId, pageCount, payload)
            .then((membersData) => {
              let lastRow = -1;
              if (membersData.total) {
                lastRow = membersData.total;
              }
              setCount(membersData.total);
              if (membersData.total === 0) {
                params.api.showNoRowsOverlay();
              }
              _params.successCallback(membersData?.data, lastRow);
              setRefreshLoading(false);
            })
            .catch((res) => {
              setRefreshLoading(false);
              _params.failCallback();
              enqueueSnackbar(
                res.response?.data?.message || "An error occurred",
                {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                },
                500
              );
            });
        },
      });
    }, [customerId, query]);
  
    const handleRefresh = () => {
      setRefreshLoading(true);
      gridApi?.refreshInfiniteCache();
      setTimeout(() => setRefreshLoading(false), 2000);
    };
  
    const components = useMemo(() => ({
      actionRenderer: ActionsRenderer,
      roleSelectorRenderer: RoleSelectorRenderer,
    }), []);
  
    const columnHeaders = [
      {
        headerName: 'User name',
        field: 'name',
        width: 400,
        sortable: true,
        filter: true,
        cellStyle: responsiveCellStyle,
        cellRenderer: (params) => {
          if (params.data === undefined) {
            return '';
          }
          if (params.data) {
            return (
              <Box className={classes.AvatarBox}>
                <Avatar className={classes.Avatar}
                  src={params?.data?.profile_photo_url}
                  alt="img"
                />
                <Box sx={{ ml: 1 }}>
                  <Link color="inherit" variant="subtitle2">
                    {params.data?.user_name || '-----------'}
                  </Link>
                  <Typography color="textSecondary" variant="body2">
                    {params.data?.email || '----------'}
                  </Typography>
                </Box>
              </Box>
            )
          }
          return '';
        },
      },
      {
        headerName: 'Contact Number',
        colId: 'Contact Number',
        width: 200,
        sortable: false,
        filter: false,
        floatingFilter: false,
        cellStyle: responsiveCellStyle,
        cellRenderer: (params) => {
          if (params.data === undefined) {
            return '';
          }
          if (params.data?.phone_no) {
            return params.data?.phone_no;
          }
          return '';
        }
      },
      {
        headerName: 'Requested On',
        colId: 'Created At',
        width: 200,
        sortable: false,
        filter: false,
        floatingFilter: false,
        cellStyle: responsiveCellStyle,
        cellRenderer: (params) => {
          if (params.data === undefined) {
            return '';
          }
          if (params.data?.created_at) {
            return params.data?.created_at;
          }
          return '';
        }
      },
      {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        filter: false,
        cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1, display: 'flex', alignItems: 'center', justifyContent: 'left' },
        cellRenderer: 'actionRenderer',
      }
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
      };
    }, []);
  
    // ---------------------------------------------------------------------------------------------------------------------
  
    return (
      <>
        <Card>
          <CardHeader
            style={{ padding: '10px' }}
            avatar={
              <Grid
                container
                spacing={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                  <Chip label={count || 0} />
                  {refreshLoading && (
                    <CircularProgress size={30} className={classes.circularprogress} />
                  )}
                </Grid>
                <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                  <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}> Pending Members </Typography>
                </Grid>
              </Grid>
            }
            action={
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}            >
                {/* <Grid
                  item
                  xs="auto"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setroleDialog(true)}
                    className={classes.cardActionButtons}
                  >
                    Add role
                  </Button>
                </Grid> */}
  
                {/* <Grid
                  item
                  xs="auto"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setMembersDialog(true)}
                    className={classes.cardActionButtons}
                  >
                    Add member
                  </Button>
                </Grid> */}
  
                <Grid
                  item
                  xs="auto"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
                >
                  <TextField
                    focused
                    autoFocus
                    type="text"
                    size="small"
                    value={query}
                    variant="outlined"
                    name='new-password'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleQueryChange}
                    placeholder="Search members"
                  />
                </Grid>
  
                <Grid
                  item
                  xs="auto"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1.8 }}
                >
                  <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                    {refreshLoading ? (
                      <CircularProgress size={27} sx={{ mr: '5px' }} />
                    ) : (
                      <Refresh sx={{ fontSize: '2rem' }} />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            }
          />
  
          <Divider />
          {refreshLoading && <LinearProgress />}
  
          <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
            <AgGridReact
              rowHeight={80}
              columnDefs={columnHeaders}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              components={components}
              {...defaultAgGridProps}
              // pagination
              // rowBuffer={100}
              // paginationPageSize={100}
              // cacheBlockSize={15}
              // cacheOverflowSize={15}
              suppressRowClickSelection
              context={{
                global_keyword: query,
              }}
            />
          </Box>
        </Card>
  
        {membersDialog && (
          <AddMemberDialog
            openDialog={membersDialog}
            handleClose={() => setMembersDialog(false)}
            gridApi={gridApi}
          />
        )}
        {roleDialog && (
          <AddRoleDialog
            openDialog={roleDialog}
            handleClose={() => setroleDialog(false)}
            gridApi={gridApi}
          />
        )}
      </>
    );
  };
  
  export default WithConfirm(PendingMembers);
  