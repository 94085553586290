export const csvGenerateRow = (row, headerKeys, delimiter) => {
    return headerKeys
        .map((fieldName) =>
            typeof row[fieldName] === "number"
                ? row[fieldName]
                : `"${String(row[fieldName]).replace(/"/g, '""')}"`
        )
        .join(delimiter);
}

export const csvGenerate = (data, headers, delimiter) => {
    const headerKeys = Object.keys(data[0]);
    const columnNames = headers || headerKeys;
    const csv = data.map((row) => csvGenerateRow(row, headerKeys, delimiter));

    csv.unshift(columnNames.join(delimiter));
    const separatorMetadata = `sep=${delimiter} \r\n`;
    return separatorMetadata + csv.join("\r\n");
};


const CSV_FILE_TYPE = "text/csv;charset=utf-8;";

const ArrayToCSVdownload = ({ data, filename = "export.csv", delimiter = ",", headers }) => {
    const formattedFilename = getFilename(filename);
    if (data.length === 0) {
        triggerCsvDownload(
            headers ? headers.join(delimiter) : "",
            formattedFilename
        );
        return;
    }
    const csvAsString = csvGenerate(data, headers, delimiter);
    triggerCsvDownload(csvAsString, formattedFilename);
};

const triggerCsvDownload = (csvAsString, fileName) => {
    // BOM support for special characters in Excel
    const byteOrderMark = "\ufeff";

    const blob = new Blob([byteOrderMark, csvAsString], {
        type: CSV_FILE_TYPE,
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const getFilename = (providedFilename) => {
    return /csv$/i.test(providedFilename)
        ? providedFilename
        : `${providedFilename}.csv`;
};

export default ArrayToCSVdownload;