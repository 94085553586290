import {
  Breadcrumbs,
  Container,
  Grid,
  Typography
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Outlet, Link } from 'react-router-dom';
import { useState } from 'react';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import ISPDashboardCards from '../components/ISPManagementTable/ISPManagementDashboard/ISPDashboardCards';
import Page from '../components/Page';  


// ----------------------------------------------------------------------

export default function Overview() {
 
  const [ locationData, setLocationData ] = useState([{ code: 'AD', label: 'Location1', phone: '376', id: 1 }])

  const selectLocation = (value)=> {
    setLocationData(value)
  }
  
  return (
    <Page title="Overview">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
              IPS Management
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/isp-management">
              Dashboard
            </Typography>
          </Breadcrumbs>
        </div>

        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}}>
          <ISPDashboardCards />
        </Grid>

        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:2}}>
          <hr />
          <br />
          <LocationSelect  selectLocation={selectLocation} />
        </Grid>
        
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Outlet />
        </Grid>

      </Container>
    </Page>
  );
}
