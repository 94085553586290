import { Difference, MoreVert } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { UserContext } from '../../../../context/UserContext'
import { getdiff } from '../../../../API/S3/ConfigEvents'
import ApproveDialog from '../../../DevicewiseSummary/Components/renderer/ButtonDialogs/ApproveDialog';
// import DeleteDialog from '../../../DevicewiseSummary/Components/renderer/ButtonDialogs/DeleteDialog';
import HoldtillDialog from '../../../DevicewiseSummary/Components/renderer/ButtonDialogs/HoldtillDialog';
import UnapproveDialog from '../../../DevicewiseSummary/Components/renderer/ButtonDialogs/UnapproveDialog';
import DiffDialogwithTabs from '../../../SummaryComponents/Components/DiffDialogwithTab';
// ----------------------------------------------------------------------

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));


// ---------------------------------------------------------------------
const AffectedActionRenderer = (props) => {
  const { data, context } = props;
  const { customerId } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [differenceDialog, setDifferenceDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openUnapproveDialog, setOpenUnapproveDialog] = useState(false);
  // const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
  const [openholdtill, setOpenholdtill] = useState(false);

  // const handleClickOpenDiffDialog = () => {
  //   setDifferenceDialog(true);
  // };
  
  const [backupDetails, setBackupDetails] = useState(null);

  const handleClickOpenDiffDialog = () => {
    getdiff(customerId, data?.cmdb_event_id).then((res) => {      
      setBackupDetails(res);
    }).catch((error) => {
      console.error("Error fetching backup details:", error);
    });
    setDifferenceDialog(true);
  };


  const handleClickOpenUnapproveDialog = () => {
    setOpenUnapproveDialog(true);
  };

  const handleClickOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  }

  // const handleClickOpenDeleteDialog = () => {
  //   setOpenDeleteDialog(true);
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpenHoldtill = () => {
    setOpenholdtill(true);
  };

  const handleCloseHoldtill = () => {
    setOpenholdtill(false);
  };

  // -----------------------------------------------------------------------
  if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
        <Tooltip title={'View Diff'}>
          <IconButton onClick={handleClickOpenDiffDialog}>
            <Difference fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {differenceDialog && (
          <DiffDialogwithTabs
          openDialog={differenceDialog}
          goldenConfigPath={data.r_golden_config_path}
          currentConfigPath={data.r_change_backup_path}
          previousConfigPath={data.r_change_previous_path}
          data={data}
          cmdbGroupSummaryEvent={data}
          backupDetails={backupDetails}
          handleClose={() => setDifferenceDialog(false)}
          />
        )}
        <IconButton
          onClick={handleClick}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreVert sx={{ fontSize: '1.8rem' }} />
        </IconButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          sx={{ px: 1, py: 1 }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem disableRipple onClick={() => { handleCloseMenu(); handleClickOpenApproveDialog(); }}>
            <ListItemIcon >
              <CheckCircleOutlinedIcon fontSize="small" sx={{ color: '#0ed689!important' }} />
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: '-10px' }}>Approve</ListItemText>
          </MenuItem>
          <MenuItem disableRipple onClick={() => { handleCloseMenu(); handleClickOpenUnapproveDialog(); }}>
            <ListItemIcon>
              <CancelOutlinedIcon fontSize="small" sx={{ color: '#c04617 !important' }} />
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: '-10px' }}>Un-Approve</ListItemText>
          </MenuItem>
          <MenuItem disableRipple onClick={() => { handleCloseMenu(); handleClickOpenHoldtill(); }}>
            <ListItemIcon>
              <BlockIcon fontSize="small" sx={{ color: '#dff840!important' }} />
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: '-10px' }}>Hold Untill</ListItemText>
          </MenuItem>
          {/* <MenuItem disableRipple onClick={() => { handleCloseMenu(); handleClickOpenDeleteDialog();}}>
            Delete
          </MenuItem> */}
        </StyledMenu>
        {openApproveDialog && (
          <ApproveDialog
            openDialog={openApproveDialog}
            handleClose={() => setOpenApproveDialog(false)}
            data={data}
            cmdbGroupSummaryEvent={context.groupSummaryEvent}
            api={props.api}
          />
        )}
        {openUnapproveDialog && (
          <UnapproveDialog
            openDialog={openUnapproveDialog}
            handleClose={() => setOpenUnapproveDialog(false)}
            data={data}
            cmdbGroupSummaryEvent={context.groupSummaryEvent}
            api={props.api}
          />
        )}
        {/* {openDeleteDialog && (
              <DeleteDialog
                openDialog={openDeleteDialog}
                handleClose={() => setOpenDeleteDialog(false)}
                data={data}
                api={props.api}
              />
            )} */}
        {openholdtill && (
          <HoldtillDialog
            data={data}
            api={props.api}
            cmdbGroupSummaryEvent={context.groupSummaryEvent}
            openDialog={openholdtill}
            handleClose={handleCloseHoldtill}
          />
        )}
      </Box>
    );
  }
};


AffectedActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default AffectedActionRenderer;
