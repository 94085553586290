import { Grid } from '@mui/material';
import SitewiseNetworkGraph from './components/SitewiseNetworkGraph';

// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function SitewiseMapComponent(props) {
  const { locationData } = props;

  return (
    <>
     <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <SitewiseNetworkGraph locationData={locationData} />
          </Grid>
      </Grid>
    </>
  );
}
