import { Difference, HelpOutline } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState ,useContext} from 'react';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import DiffDialogwithTabs from '../../../SummaryComponents/Components/DiffDialogwithTab';
import AffectedDeviceDialog from '../AffectedDeviceDialog';
import {getdiff} from '../../../../API/S3/ConfigEvents'
import { UserContext } from '../../../../context/UserContext'

const ActionRenderer = (props) => {
  const { data } = props;
  const { customerId } = useContext(UserContext);
  const [ differenceDialog, setDifferenceDialog ] = useState(false);
  const [openAffectedDeviceDialog, setAffectedDeviceDialog] = useState(false);
  const [backupDetails, setBackupDetails] = useState(null);

  const handleClickOpenDiffDialog = () => {
    getdiff(customerId, data?.cmdb_group_event_id).then((res) => {      
      setBackupDetails(res);
    }).catch((error) => {
      console.error("Error fetching backup details:", error);
    });
    setDifferenceDialog(true);
  };

  const handleClickOpen = () => {
    setAffectedDeviceDialog(true);
  };

  if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box sx={{ textAlign: 'center' }}>
         {/* <Tooltip title={'View Diff'}>
          <IconButton onClick={handleClickOpenDiffDialog}>
            <Difference fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
            {differenceDialog && (
              <DiffDialogwithTabs
                openDialog={differenceDialog}
                goldenConfigPath={data?.device_summary_event?.r_golden_config_path}
                currentConfigPath={data?.device_summary_event?.r_change_backup_path}
                previousConfigPath={data?.device_summary_event?.r_change_previous_path}
                data={data}
                backupDetails={backupDetails}
                cmdbGroupSummaryEvent={data}
                handleClose={() => setDifferenceDialog(false)}
              />
            )}
        <Tooltip title="View affected devices">
          <IconButton  onClick={handleClickOpen}>
            <DatasetLinkedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {openAffectedDeviceDialog && (
          <AffectedDeviceDialog
            openDialog={openAffectedDeviceDialog}
            handleClose={() => {
              setAffectedDeviceDialog(false)
            }}
            groupSummaryEvent={data}
            groupedApi={props.api}
          />
        )}
      </Box>
    );
  }
};

ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default ActionRenderer;