import { Grid } from "@mui/material";
import UnknownDevicesTable from "./UnknownDevicesTable";

// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function UnknownDevicesTableHead(props) {
  const { locationData } = props;


  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
          <UnknownDevicesTable locationData={locationData}/>
      </Grid>
    </Grid>
  );
}
