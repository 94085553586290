import React, { useState, useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Menu,MenuItem, Button} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context/UserContext';
import { scanFullConfig, scanNewlyAddedSubnet ,getDcAgent} from '../../../API/DcAgent/DcAgent';

import ScanSelectedSubnetsDialog from './ScanSelectedSubnetsDialog';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ScanButton(props) {
  const { agent } = props;
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const [scanSelectedSubnetsDialog, setScanSelectedSubnetsDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scanFullConfigure = (agent) => {
    
    const data = {
      // status: agent.r_agent_status,
      onDemandScanType : 2
    };
    setLoading(true);
    scanNewlyAddedSubnet(customerId, agent.agent_id, data)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Configuration updated successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const scanNewlyAddedSubnets = (agent) => {
    const data = {
      // status: agent.r_agent_status,
      onDemandScanType : 1
    };
    setLoading(true);
    scanNewlyAddedSubnet(customerId, agent.agent_id, data)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Configuration updated successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

 

  const handleScanSelectedSubnets = (value) => {
    setScanSelectedSubnetsDialog(true);
  };

  return (
    <div>
      <Button
        disabled={props.disabled || false}
        sx={{ width: '160px' }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Scan
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        sx={{ px: 1, py: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            scanNewlyAddedSubnets(agent);
          }}
          disableRipple
        >
          Scan newly added subnets
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            scanFullConfigure(agent);
          }}
          disableRipple
        >
          Scan full configuration
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose();
            handleScanSelectedSubnets();
          }}
        >
          Scan specific subnets on-demand
        </MenuItem>
      </StyledMenu>
      {scanSelectedSubnetsDialog && (
        <ScanSelectedSubnetsDialog
          agent={agent}
          openDialog={scanSelectedSubnetsDialog}
          handleClose={() => setScanSelectedSubnetsDialog(false)}
        />
      )}
    </div>
  );
}
