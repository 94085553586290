import { Grid } from '@mui/material';
import DeviceLifeCycleTable from './components/DeviceLifeCycleTable';
// -------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------

export const DeviceLifeCycleComponent = () => {


  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12} sm={12}>
        <DeviceLifeCycleTable/>
      </Grid>
    </Grid>
  );
};
