import {
  Breadcrumbs, Container, Grid, Typography
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import { SchedulersComponents } from '../components/SchedulersComponents';

//--------------------------------------------------
export default function Schedulers() {
  return (
    <Page title="Collector Agent">
      <Container maxWidth={false}>
        <div role="presentation" style={{paddingBottom: '30px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
              Settings
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/schedulers">
              Schedulers
            </Typography>
          </Breadcrumbs>
        </div>

        <Grid container>
          <Grid item sm={12} md={12} lg={12}>
            <SchedulersComponents />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
