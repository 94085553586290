import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReactFlow } from "reactflow";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const useStyles = makeStyles(() => ({
    remoteContainer: {
      position: 'relative',
      width: '160px',
      height: '160px',
      marginLeft:'auto'
    },
    remoteButton: {
      position: 'absolute',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
      backgroundColor: '#efefef',
      
      borderRadius: '50%',
      fontSize: '18px',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#0056b3',
        color: '#fff',
      },
    },
    center: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    right: {
      top: '50%',
      right: '20px',
      transform: 'translateY(-50%)',
    },
    bottom: {
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    left: {
      top: '50%',
      left: '20px',
      transform: 'translateY(-50%)',
    },
    top: {
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }));
function PanControl() {
    const reactFlowInstance = useReactFlow();
    const classes = useStyles()

    const handlePanFitView = () => {
        reactFlowInstance.fitView({ duration: 100 })
    }
    const handlePanUp = () => {
        const position = reactFlowInstance.getViewport()
        reactFlowInstance.setViewport({ ...position, y: position.y + 100 }, { duration: 100 })
    };

    const handlePanDown = () => {
        const position = reactFlowInstance.getViewport()
        reactFlowInstance.setViewport({ ...position, y: position.y - 100 }, { duration: 100 })

    };

    const handlePanLeft = () => {
        const position = reactFlowInstance.getViewport()
        reactFlowInstance.setViewport({ ...position, x: position.x + 100 }, { duration: 100 })

    };

    const handlePanRight = () => {
        const position = reactFlowInstance.getViewport()
        reactFlowInstance.setViewport({ ...position, x: position.x - 100 }, { duration: 100 })

    };

    return <div className={classes.remoteContainer}>
        <IconButton  title="fitview" className={`${classes.remoteButton} ${classes.center}`} onClick={handlePanFitView}><FullscreenIcon/></IconButton>
        <IconButton  title="move up" className={`${classes.remoteButton} ${classes.top}`} onClick={handlePanUp}><KeyboardArrowUpIcon/></IconButton>
        <IconButton  title="move right" className={`${classes.remoteButton} ${classes.right}`} onClick={handlePanRight}><ChevronRightIcon/></IconButton>
        <IconButton  title="move down"  className={`${classes.remoteButton} ${classes.bottom}`} onClick={handlePanDown}><KeyboardArrowDownIcon/></IconButton>
        <IconButton title="move left"    className={`${classes.remoteButton} ${classes.left}`} onClick={handlePanLeft}><ChevronLeftIcon/></IconButton>
    </div>
}

export default PanControl;