// @mui
import {
    Grid,
  } from '@mui/material';
  import ISPBad1Card1 from './ISPBad1Card1';
  // components
  // ----------------------------------------------------------------------
  
  export default function ISPBad1MainPage() {

    return (
      <>
          <Grid container spacing={1} sx={{py:1}}>
              <ISPBad1Card1 />
          </Grid>
      </>
    );
  }
  