import {
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import ConsoleContent from './components/ConsoleContent';
// -------------------------------------------------------------------------------------------


// ----------------------------------------------------------------------------------------------

const DeveloperDashboard = () => {

    return (
        <>
            <Card>
                <CardHeader
                    color="text.error"
                    title="Developer console"
                />
                <CardContent>
                    <ConsoleContent />
                </CardContent>
            </Card>
        </>
    );
};

export default DeveloperDashboard;








