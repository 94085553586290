/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { networkHealthMemoryUsageDetailed } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250,
}));


// ---------------------------------------


const MemoryChartDetailed = ({systemExpanded}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [loading, setLoading] = useState(true);

    const solveCondition = async() => {
        if(!healthDashState.selectedDevice || systemExpanded === false){
            return '';
        }
        fetchMemoryUsage();
    };

    const fetchMemoryUsage = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start/1000,
            end: data.end/1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setLoading(true);
        networkHealthMemoryUsageDetailed(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setLoading(false);
                setSeriesData(res?.data)
                setXAxisData(res?.timestamps)
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, systemExpanded]);

    const chartSeries = seriesData;

    const chartOptions = {
        chart: {
            type: 'line',
            height: 250,
            toolbar: { show: false },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        dataLabels: {
            enabled: false,
            dropShadow: { enabled: true }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (value) => value.toFixed(0) + '%',
            },
            x: {
                show: false,
            },
        },
        colors: ['#ffcc80'],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            offsetX: 0.5,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
        },
        xaxis: {
            categories: xAxisData,
            type: 'datetime',
            labels: {
                hideOverlappingLabels: true,
                formatter: function (value) {
                    const date = new Date(value*1000);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    return timeString;
                },
            }
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: (value) => value.toFixed(0) + '%',
            },
        },
    };

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{pl:1}}>
                            Memory Usage Total
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                        <ChartWrapperStyle dir="ltr">
                                <ReactApexChart series={chartSeries} options={chartOptions} type="line" height={250} />
                        </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default MemoryChartDetailed;
