/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// import { haClusterCPUusageRatio } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import MainCpuUsageRatioChart from '../../../MainCpuChart/MainCpuUsageRatioChart';



// -------------------------------------
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250,
}));


// ---------------------------------------


const CPUUsageRatioChart = ({expandedState, allData}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async () => {
        // if (!healthDashState.selectedDevice || expandedState == false) {
        //     return '';
        // }
        fetchCpuUsageRatio();
    }

    const fetchCpuUsageRatio = async () => {
        const data = getStartEnd()
        // const payload = {
        //     start: data.start / 1000,
        //     end: data.end / 1000,
        //     step: data.step,
        //     // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
        //     device_os: healthDashState.selectedDevice.r_dvc_os
        // }
        setChartLoading(true);
        // networkHealthCpuUsage(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
        //     .then((res) => {
            const cpuData = allData?.hacluster_table[0]?.device_hacpu || [];
            const timestamps = allData?.timestamps || [];
            
            // Convert CPU data to numbers
            const convertedArray = cpuData.map(Number);
            
            // Format X-axis data
            const formattedXAxisData = timestamps.map(timestamp => {
                const date = new Date(timestamp * 1000);
                const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                return `${timeString}\n${dateString}`;
            });
            
            // Update state
            setChartLoading(false);
            setSeriesData([{ name: 'CPU Usage Ratio', data: convertedArray }]);
            setXAxisData(formattedXAxisData);
            // })
            // .catch(() => {
            //     setChartLoading(false);
            // });
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    

    return (
        <>
            {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            CPU Usage Ratio
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                    {seriesData?.length > 0 && xAxisData?.length > 0 && <MainCpuUsageRatioChart seriesData={seriesData} xAxisData={xAxisData} />}
                    {/* <MainCpuUsageRatioChart seriesData={seriesData} xAxisData={xAxisData} /> */}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default CPUUsageRatioChart;
