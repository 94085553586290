import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  key_content: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    "word-break": "break-word",
  },
}));

const LocationNameRender = (props) => {
  const classes = useStyles();
  const { data } = props;
  
  if (props.data === undefined) {
    return ;
  }
  return (<div className={classes.key_content}>{data.location?.r_location_name}</div>);
};

LocationNameRender.propTypes = {
  data: PropTypes.object,
};

export default LocationNameRender;
