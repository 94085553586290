// /* eslint-disable */
// import { Box, LinearProgress, Paper } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { AgGridReact } from 'ag-grid-react';
// import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// // import { SdwanStateChange } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { fToNow } from '../../../../../../utils/formatTime';
// import { useHealthContext } from '../../../userContextHealth';


// // -------------------------------------
// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     height: 660,
// }));

// // ---------------------------------------


// const SdwanStatusTable = ({ expandedState, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext()
//     const { customerId } = useContext(UserContext);
//     const [statusData, setStatusData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [columnHeaders, setColumnHeaders] = useState([]);

// // -------------------------------------------------------------------------------------

// const isTablet = useMediaQuery('(max-width: 1020px)');
// const isDesktop = useMediaQuery('(min-width: 1440px)');
// const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

// const getFontSize = () => {
//  if (isLargeDesktop) {
//    return '20px';
//  } 
//  if (isDesktop) {
//    return '16px';
//  } 
//  if (isTablet) {
//    return '14px';
//  } 
//  return '12px';
//  }

// const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1  };

// // ----------------------------------------------------------------------------------------------------
// const transformData = (data) => {
//     const keys = Object.keys(data[0]);
//     const numRows = data[0][keys[0]].length;
//     const transformedData = [];

//     for (let i = 0; i < numRows; i++) {
//         const row = {};
//         keys.forEach(key => {
//             row[key] = data[0][key][i];
//         });
//         transformedData.push(row);
//     }

//     return transformedData;
// };
//     // const columnHeaders = [
//     //     {
//     //         headerName: 'Interface',
//     //         field: 'interface',
//     //         width: 200,
//     //         sortable: false,
//     //         filter: false,
//     //         cellStyle: responsiveCellStyle,
//     //     },
//     //     {
//     //         headerName: 'sla',
//     //         field: 'sla',
//     //         width: 200,
//     //         sortable: true,
//     //         filter: false,
//     //         cellStyle: responsiveCellStyle,
//     //     },
//     //     {
//     //         headerName: 'value',
//     //         field: 'value',
//     //         width: 300,
//     //         sortable: false,
//     //         filter: false,
//     //         cellStyle: responsiveCellStyle,
//     //         valueFormatter: (params) => {
//     //             const formattedDate = fToNow(params.value);
//     //             return formattedDate;
//     //         },
//     //     },
//     // ];

//     const fetchData = useCallback(() => {
//         if (!healthDashState.selectedDevice || expandedState === false) {
//             return '';
//         }
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//             device_os: healthDashState.selectedDevice.r_dvc_os,
//         };
//         setLoading(true);
//         // SdwanStateChange(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//         //     .then((res) => {
//             setLoading(false);
//             const clientTableData = allData?.sdwan_table[0].device_sdwan_interface_name || [];
//             const transformedData = transformData(clientTableData);
//             setResData(transformedData);
//             if (transformedData.length > 0) {
//                 const headers = Object.keys(transformedData[0]).map((key) => ({
//                     headerName: key,
//                     field: key,
//                     width: 180,
//                     sortable: true,
//                     filter: true,
//                     cellStyle: responsiveCellStyle,
//                 }));
//                 setColumnHeaders(headers);
//             }
//         // })
//         // .catch(() => {
//         //     setLoading(false);
//         // });
// }, [healthDashState.selectedDevice, expandedState, customerId, getStartEnd]);
//     useEffect(() => {
//         fetchData();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

//     const defaultColDef = useMemo(() => {
//         return {
//             resizable: true,
//             filter: true,
//             sortable: true,
//             suppressMenu: true,
//             floatingFilter: false,
//         };
//     }, []);

//     // -----------------------------------------------------------------------------------------------


//     return (
//         <>
//             {loading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper variant="outlined">
//                 <ChartWrapperStyle dir="ltr">
//                     <Box style={{ height: '660px', width: '100%' }} className="ag-theme-material">
//                         <AgGridReact
//                             rowData={statusData}
//                             columnDefs={columnHeaders}
//                             onGridReady={fetchData}
//                             defaultColDef={defaultColDef}
//                         />
//                     </Box>
//                 </ChartWrapperStyle>
//             </Paper>
//         </>
//     );
// };
// export default SdwanStatusTable;

import { Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 320,
}));

const SdwanStatusTable = ({ expandedState, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [interfaceNames, setInterfaceNames] = useState([]);
    const [loading, setLoading] = useState(true);

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    const fetchData = useCallback(() => {
        // if (!healthDashState.selectedDevice || expandedState === false) {
        //     return;
        // }
        setLoading(true);
        // Simulating the API call and setting data
        setLoading(false);
        const interfaceNamesData = allData?.sdwan_table[0]?.device_sdwan_interface_name || [];
        setInterfaceNames(interfaceNamesData.map(name => ({ interface_name: name })));
    }, [healthDashState.selectedDevice, expandedState, allData]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState, fetchData]);

    const columnHeaders = useMemo(() => {
        return [
            {
                headerName: 'Interface Name',
                field: 'interface_name',
                width: 500,
                sortable: true,
                filter: true,
                cellStyle: responsiveCellStyle,
            }
        ];
    }, [responsiveCellStyle]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '300px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={interfaceNames}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            onGridReady={fetchData}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default SdwanStatusTable;
