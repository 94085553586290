


import {
    Box,
    LinearProgress,
    Paper,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 480,
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),  
    paddingRight: theme.spacing(2),
}));


const ApTable = ({ apExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) return '20px';
        if (isDesktop) return '16px';
        if (isTablet) return '14px';
        return '12px';
    };
    
    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    // Define pattern maps for AP Table, adjusting based on device type
    const standardPatternMap = [
        { pattern: /wlanAPIpAddress/i, newName: 'IP' },
        { pattern: /wlanAPName/i, newName: 'Name' },
        { pattern: /wlanAPGroupName/i, newName: 'Group-Name' },
        { pattern: /wlanAPSerialNumber/i, newName: 'Serial Number' },
        { pattern: /wlanAPIpsecMode/i, newName: 'IPSec' },
        { pattern: /wlanAPUpTime/i, newName: 'Uptime' },
        { pattern: /wlanAPModelName/i, newName: 'Model Name' },
        { pattern: /wlanAPLocation/i, newName: 'Location' },
        { pattern: /wlanAPRemoteLanIpAddress/i, newName: 'Lan IP' },
        { pattern: /wlanAPActiveUplink/i, newName: 'Uplink' }
    ];

    const renameKey = (obj, oldKey, newKey) => {
        if (oldKey !== newKey) {
            Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey));
            delete obj[oldKey];
        }
    };

    const getNewKeyName = (key) => {
        for (const entry of standardPatternMap) {
            if (entry.pattern.test(key)) {
                return entry.newName;
            }
        }
        return null;
    };

    const transformData = (data) => {
        if (!data || data.length === 0) {
            return [[], []];
        }

        const transformedData = [];
        const keys = Object.keys(data[0]);
        const groupedData = {};

        keys.forEach((key) => {
            const pattern = key.match(/[^a-zA-Z0-9](.*?)$/);
            if (pattern) {
                const dynamicPattern = pattern[1];
                if (!groupedData[dynamicPattern]) {
                    groupedData[dynamicPattern] = {};
                }
                const baseKey = key.split('.')[0];
                groupedData[dynamicPattern][baseKey] = data[0][key][0];
            } else {
                console.warn(`Key without identifiable dynamic pattern: ${key}`);
            }
        });

        const validColumns = [];
        Object.keys(groupedData).forEach((pattern) => {
            const dataObject = groupedData[pattern];

            for (const key in dataObject) {
                const newKey = getNewKeyName(key);
                if (newKey) {
                    renameKey(dataObject, key, newKey);
                    if (!validColumns.includes(newKey)) {
                        validColumns.push(newKey);
                    }
                }
            }

            transformedData.push(dataObject);
        });

        return [transformedData, validColumns];
    };

    const fetchData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
        };
        setLoading(true);

        // Replace with actual API call
        // clientsTable(customerId, healthDashState.selectedDevice?.r_dvc_serial_number, payload)
        //     .then((res) => {
                setLoading(false);
                const clientTableData = allData?.ap_table || [];
                const [transformedData, validColumns] = transformData(clientTableData);
                setResData(transformedData);
                setFilteredData(transformedData);
                if (transformedData.length > 0) {
                    const headers = validColumns.map((key) => ({
                        headerName: key,
                        field: key,
                        width: 180,
                        sortable: true,
                        filter: true,
                        cellStyle: responsiveCellStyle,
                        valueFormatter: (params) => {
                            const value = params.value;
                            return value === null || value === '' ? 'NA' : value;
                        },
                    }));
                    setColumnHeaders(headers);
                }
        // })
        // .catch(() => {
        //     setLoading(false);
        // });
    }, [healthDashState.selectedDevice,healthDashState.timeRange, customerId, getStartEnd, allData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredData(resData.filter((row) =>
            Object.values(row).some((value) => value && value.toString().toLowerCase().includes(term))
        ));
    };
    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
            <SearchContainer>
                    <TextField
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                        placeholder="Search..."
                        sx={{ width: '300px' }}
                        
                    />
                </SearchContainer>
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={filteredData}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            rowHeight={50}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default ApTable;

// import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import { Box, LinearProgress, Paper, TextField } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { AgGridReact } from 'ag-grid-react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(2), // Adjusted to add space for the search bar
//     height: 480,
// }));

// const SearchContainer = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginBottom: theme.spacing(1),
//     marginTop: theme.spacing(2),
//     paddingRight: theme.spacing(2),
// }));

// const ApTable = ({ apExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [resData, setResData] = useState([]);
//     const [filteredData, setFilteredData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [columnHeaders, setColumnHeaders] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');

//     const isTablet = useMediaQuery('(max-width: 1020px)');
//     const isDesktop = useMediaQuery('(min-width: 1440px)');
//     const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

//     const getFontSize = () => {
//         if (isLargeDesktop) {
//             return '20px';
//         }
//         if (isDesktop) {
//             return '16px';
//         }
//         if (isTablet) {
//             return '14px';
//         }
//         return '12px';
//     };

//     const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

//     const fetchData = useCallback(() => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
//         };
//         setLoading(true);

//         setLoading(false);
//         const apTableData = allData?.ap_table || [];
//         setResData(apTableData);
//         setFilteredData(apTableData); // Initialize filtered data

//         if (apTableData.length > 0) {
//             const headers = Object.keys(apTableData[0]).map((key) => ({
//                 headerName: key,
//                 field: key,
//                 width: 180,
//                 sortable: true,
//                 filter: true,
//                 cellStyle: responsiveCellStyle,
//             }));
//             setColumnHeaders(headers);
//         }
//     }, [healthDashState.selectedDevice, getStartEnd, allData]);

//     useEffect(() => {
//         fetchData();
//     }, [customerId, healthDashState.selectedDevice, apExpanded, fetchData]);

//     const handleSearchChange = (event) => {
//         const term = event.target.value.toLowerCase();
//         setSearchTerm(term);
//         setFilteredData(resData.filter((row) =>
//             Object.values(row).some((value) => value && value.toString().toLowerCase().includes(term))
//         ));
//     };

//     const defaultColDef = useMemo(() => ({
//         resizable: true,
//         filter: true,
//         sortable: true,
//         suppressMenu: true,
//         floatingFilter: false,
//     }), []);

//     return (
//         <>
//             {loading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper variant="outlined">
//                 <SearchContainer>
//                     <TextField
//                         value={searchTerm}
//                         onChange={handleSearchChange}
//                         variant="outlined"
//                         size="small"
//                         placeholder="Search..."
//                         sx={{ width: '300px' }}
//                     />
//                 </SearchContainer>
//                 <ChartWrapperStyle dir="ltr">
//                     <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
//                         <AgGridReact
//                             rowData={filteredData}
//                             columnDefs={columnHeaders}
//                             defaultColDef={defaultColDef}
//                             rowHeight={50}
//                         />
//                     </Box>
//                 </ChartWrapperStyle>
//             </Paper>
//         </>
//     );
// };

// export default ApTable;
