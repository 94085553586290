import AWS,{Signer} from 'aws-sdk';

import path from 'path-browserify';

import clientCredentials from '../api_endpoints';

// The AWS credentials below only have read-only access to the Chonky demo bucket.
// You will need to create custom credentials for your bucket.
export const BUCKET_NAME = clientCredentials.cmdbBucket;

export const BUCKET_REGION = process.env.REACT_APP_BUCKET_REGION;
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
    region: BUCKET_REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3();
export const s3Client =  s3

export const fetchS3BucketContents = (bucket, prefix) => {
    return s3
        .listObjectsV2({
            Bucket: bucket,
            Delimiter: '/',
            Prefix: prefix !== '/' ? prefix : '',
        })
        .promise()
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents;
            const s3Prefixes = response.CommonPrefixes;

            if (s3Objects) {
                chonkyFiles.push(
                    ...s3Objects.map((object) => ({
                        id: object.Key,
                        name: path.basename(object.Key),
                        modDate: object.LastModified,
                        size: object.Size,
                    }))
                );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map((prefix) => ({
                        id: prefix.Prefix,
                        name: path.basename(prefix.Prefix),
                        isDir: true,
                    }))
                );
            }

            return chonkyFiles;
        });
};

export const downloadS3FileContent = (bucket, file) => {
    const params = {
        Bucket: bucket,
        Key: file,
        ResponseCacheControl:'no-cache'
    }
    return s3
        .getObject(params)
        .promise()
        .then((response) => {
            return response
        });
};

export const fetchS3FileContent = (bucket, file) => {
    const params = {
        Bucket: bucket,
        Key: file,
        ResponseCacheControl:'no-cache'
    }
    return s3
        .getObject(params)
        .promise()
        .then((response) => {
            return response.Body.toString()
        });
};

export const getAuthToken = ()=>{
    
    return AWS.Signers.V2().signature(s3.config.credentials)
    
}