import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@mui/material';

const UnapproveDialog = ({ openDialog, handleClose, cmdbGroupSummaryEvent, data, api, onReject }) => {
  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="unapprove-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="unapprove-dialog-title">Reject Event</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to reject the change ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onReject} color="error" variant="contained">
          Un-Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnapproveDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  cmdbGroupSummaryEvent: PropTypes.object,
  data: PropTypes.object,
  api: PropTypes.any,
  eventId: PropTypes.string.isRequired, 
  onReject: PropTypes.func.isRequired,
};

export default UnapproveDialog;