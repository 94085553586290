import { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography, Box, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { UserContext } from '../../../context/UserContext';
import { checkUserEmail } from '../../../API/UserAuth/changePassword';

// ---------------------------------------------------------------------

// ------------------------------------------------------
export default function ChangeProfileInfoForm(props) {
  const { register, setError, formState: { errors }, } = useForm();
  const { userInfo } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [emailLoading, setEmailLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [modeEnable, setmodeEnable] = useState([]);


  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    checkemail: Yup.boolean(),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    username: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {handleSubmit,formState: { isSubmitting },reset,} = methods;

  useEffect(() => {
    reset({ username: userInfo.name, email: userInfo.email });
  }, [userInfo]);

  useEffect(() => {
    if (isEmpty(localStorage.getItem('developer_mode'))) {
      localStorage.setItem('developer_mode', '0');
      setmodeEnable(false)
    }
    else {
      const value = localStorage.getItem('developer_mode');
      setmodeEnable(value === '1')
    }
  }, [])

  const onSubmit = (value) => {
    // const data = {
    //   email: value.email,
    // };
    // if (value.email !== userInfo.email) {
    //   setEmailLoading(true);
    //   checkUserEmail(customerId, data).then((res) => {
    //     setStatus(res.data.data.status);
    //     setEmailLoading(false);
    //     enqueueSnackbar(
    //       'Email updated sucessfully',
    //       {
    //         variant: 'success',
    //         anchorOrigin: {
    //           vertical: 'bottom',
    //           horizontal: 'right',
    //         },
    //       },
    //       500
    //     );
    //   });
    // } else {
    //   checkUserEmail(customerId, data)
    //     .then((res) => {
    //       setStatus(res.data.data.status);
    //     })
    //     .catch((res) => res.response.data.message);
    // }
  };

  // const stutusCheck = (value) => {
  //   switch (value) {
  //     case 'taken':
  //       return <span style={{ color: 'red', fontSize: '12px', padding: '20px', marginTop: 0 }}>taken*</span>;
  //     case 'available':
  //       return <span style={{ color: 'green', fontSize: '12px', padding: '20px', marginTop: 0 }}>available</span>;
  //     default:
  //       return '';
  //   }   
  // };

  const handleDevMode = (modeEnable) => {
    if (modeEnable) {
      localStorage.setItem('developer_mode', '1');
      setmodeEnable(true);
    }
    else {
      localStorage.setItem('developer_mode', '0');
      setmodeEnable(false);
    }
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom sx={{ py: 2 }}>
          Profile Information
        </Typography>

        <Stack spacing={3}>
          <RHFTextField name="username" label="Name *" />
          <div>
            <RHFTextField name="email" label="Login email-ID *" />
          </div>
        </Stack>

        {/* <Box sx={{ py: 2 }}>
          <LoadingButton
            type="submit"
            loading={emailLoading}
            color="primary"
            sx={{ mr: 1, height: '40px', float: 'right', width: '150px' }}
            variant="outlined"
          >
            Update profile
          </LoadingButton>
        </Box> */}
      </FormProvider>
    </>
  );
}
