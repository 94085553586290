// /* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// // import { SdwanVirtualActiveSessions } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';


// // -------------------------------------

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 259,
// }));

// // ---------------------------------------


// const VirtualWanActiveSessions = ({expandedState, allData}) => {
//     const { healthDashState, getStartEnd } = useHealthContext()
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     const solveCondition = async() => {
//         if(!healthDashState.selectedDevice || expandedState === false){
//             return '';
//         }
//         fetchSdwanActiveSessions();
//     };

//     const fetchSdwanActiveSessions = async () => {
//         const data = getStartEnd()
//         const payload = {
//             start: data.start/1000,
//             end: data.end/1000,
//             step: data.step,
//             // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//             device_os: healthDashState.selectedDevice.r_dvc_os
//         }
//         setChartLoading(true);
//         SdwanVirtualActiveSessions(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//             .then((res) => {
//                 setChartLoading(false)
//                 setSeriesData(res?.data)
//                 setXAxisData(res?.timestamps)
//             })
//             .catch(() => {
//                 setChartLoading(false);
//             });
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

//     const chartSeries = seriesData;

//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 250,
//             toolbar: { show: false },
//         },
//         annotations: {
//             yaxis: [{
//                 y: 30,
//             }],
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 2,
//         },
//         colors: ['#ef9a9a','#f48fb1','#ce93d8','#fff59d','#b39ddb','#9fa8da','#90caf9','#81d4fa','#80deea','#80cbc4','#a5d6a7','#c5e1a5','#e6ee9c','#ffe082','#ffcc80','#ffab91'],
//         dataLabels: {
//             enabled: false,
//             dropShadow: { enabled: true }
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: function(value) {
//                     var val = Math.abs(value)
//                     if (val >= 1000) {
//                       val = (val / 1000).toFixed(0) + ' K'
//                     }
//                     return val
//                   }
//             },
//             x: {
//               show: false,
//             },
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             horizontalAlign: 'left',
//             showForSingleSeries: true,
//             offsetX: 0.5,
//             markers: {
//                 width: 15,
//                 height: 7,
//                 strokeWidth: 0,
//                 radius: 12,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         xaxis: {
//             categories: xAxisData,
//             type: 'datetime',
//             labels: {
//                 hideOverlappingLabels: true,
//                 formatter: function (value, timestamp) {
//                     const date = new Date(value*1000);
//                     const hours = date.getHours();
//                     const minutes = date.getMinutes();
//                     const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//                     return timeString;
//                 },
//             }
//         },
//         yaxis: {
//             forceNiceScale: true,
//             labels: {
//               formatter: function(value) {
//                 var val = Math.abs(value)
//                 if (val >= 1000) {
//                   val = (val / 1000).toFixed(0) + ' K'
//                 }
//                 return val
//               }
//             }
//         }
//     };


//     return (
//         <>
//             {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Virtual WAN Active Sessions
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                         <ChartWrapperStyle dir="ltr">
//                         {seriesData?.length > 0 && xAxisData?.length > 0 && <ReactApexChart series={chartSeries} options={chartOptions} type="area" height={250} />}   
//                         </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };
// export default VirtualWanActiveSessions;

import { Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 320,
}));

const VirtualWanActiveSession = ({ expandedState, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [interfaceNames, setInterfaceNames] = useState([]);
    const [loading, setLoading] = useState(true);

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    const fetchData = useCallback(() => {
        // if (!healthDashState.selectedDevice || expandedState === false) {
        //     return;
        // }
        setLoading(true);
        // Simulating the API call and setting data
        setLoading(false);
        const interfaceNamesData = allData?.sdwan_table[0]?.device_sdwan_linkname || [];
        setInterfaceNames(interfaceNamesData.map(name => ({ interface_name: name })));
    }, [healthDashState.selectedDevice, expandedState, allData]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState, fetchData]);

    const columnHeaders = useMemo(() => {
        return [
            {
                headerName: 'Linkname',
                field: 'interface_name',
                width: 500,
                sortable: true,
                filter: true,
                cellStyle: responsiveCellStyle,
            }
        ];
    }, [responsiveCellStyle]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '300px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={interfaceNames}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            onGridReady={fetchData}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default VirtualWanActiveSession;
