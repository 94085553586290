import ShareIcon from '@mui/icons-material/Share';
import { Box, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { deviceByTypeChart } from '../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../context/UserContext';
import cardStyles from '../../styles/cardsstyles';
import { toCamelizeWords } from '../../utils/formatString';
import NoDataComponent from '../NoDataComponent';


const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: "100%",
    display: 'flex',
    height: 400,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------

const DeviceTypeChart = (props) => {
  const { locationData, selectDeviceType } = props;
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const [series, setSeries] = useState([]);
  const [ndLabels, setNdLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [xAxis, setXAxis] = useState([])


  const fetchDeviceByType = async () => {
    const locations = locationData;
    deviceByTypeChart(customerId, locations)
      .then((res) => {
        const _xAxis = Object.keys(res.data.ND)
        const _series = Object.keys(res.data).map((key) => {
          return {
            "name": key === "ND" ? "Total" : "CMDB Scannable",
            "data": _xAxis.map(i => res.data[key][i] || 0),
          }
        })
        setSeries(_series)
        setXAxis(_xAxis)
        setChartLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    fetchDeviceByType();
  }, [customerId, locationData]);

  const chartLabels = ndLabels;

  const options = {
    labels: chartLabels,
    chart: {
      grouped: true,
      type: 'bar',
      height: 'auto',
      width: '100%',
      padding: '0px',
      toolbar: { show: false },
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
        },
        dataPointSelection: (event, chartContext, config) => {
          const selectedSeriesName = config.w.config.series[config.seriesIndex].name;
          if (selectedSeriesName === "Total") {
            event.target.style.cursor = 'not-allowed';
            return
          } else {
            event.target.style.cursor = 'pointer';
          }
          const selectedIndex = config.dataPointIndex;
          if (config.dataPointIndex === selectedIndex) {
            const val = config.w.config.xaxis.categories[selectedIndex];
            const tableNode = document.getElementById("CMDBTable")
            let scrollTo = 700
            if (tableNode) {
              scrollTo = tableNode.offsetTop - document.getElementById("topBar")?.offsetHeight
            }
            selectDeviceType(val);
            window.scrollTo({ top: scrollTo, left: 0, behavior: "smooth" });
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        columnWidth: '70%',
        barHeight: '70%',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        colors: {
          ranges: [{
            from: 0,
            to: 0,
            color: undefined
          }],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: 'top',
          maxItems: 100,
          hideOverflowingLabels: true,
          orientation: "horizontal",
          total: {
            enabled: false,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#373d3f',
              fontSize: '12px',
              fontFamily: undefined,
              fontWeight: 600
            }
          }
        }
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100]
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: { enabled: true }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        title: {
          formatter: (val, opts) => {
            if (val === 'Total') {
              return "Total Count";
            }
            if (val === 'CMDB Scannable') {
              return "CMDB Scannable Count";
            }
          },
        },
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return toCamelizeWords(value);
        }
      },
    },
    xaxis: {
      categories: xAxis,
      labels: {
        formatter: (val) => {
          return val
        }
      }

    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      },
    }

  };

  return (
    <>
      <Card elevation={10}>
        <CardHeader
          sx={{ py: 2, px: 2 }}
          color="text.error"
          title={'CMDB Inventory'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
        />
        <CardContent sx={{ pt: 0, pb: 0 }}>
          {series?.length === 0 && !chartLoading ? (
            <Box className={classess.noDataContainer}>
              <NoDataComponent noDataFoundText="CMDB Inventory" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">
              {chartLoading ? (
                <Skeleton animation="wave" variant="rectangular" className={classess.skeletonLoader} />
              ) : (
                <ReactApexChart height={419} options={options} series={series} type="bar" />
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default DeviceTypeChart;
