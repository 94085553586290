// GetTroubleshootInfoApiCall
export const GetTroubleshootInfoApiCall = async (ispWanId, setInfoList,setRcaData) => {
    const info = "Provider Equipment(PE) is reachable./Site is UP./Customer Equipment(CE) is reachable./Customer Equipment(CE) authentication was successfull./WAN interface is UP on Customer Equipment(CE)./WAN IP(CE-IP) is not functional on Customer Equipment (CE) [Packet Loss: 100 Latency: 0]./"
    const str = info.slice(0, -1);
    setInfoList(str.split('/'));
    setRcaData("The WAN IP (CE-IP) is not functional on Customer Equipment (CE).Please contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure.");
};

export const ISPDownTableDataAPI = async (setDownIspData) => {
    setDownIspData([
        {
          "uid": 12675,
          "isp_wan_id": 6731,
          "client_id": 110,
          "public_ip": "182.78.80.14",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 18192,
          "ent": "2024-10-07 14:47:32",
          "info_id": 644836184,
          "circuit_id": "TITA_HYD_14479914-JBH",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": 1,
          "device_id": 1022,
          "location_id": 2660,
          "location": "ZHJ-Tanishq Store",
          "private_ip": null,
          "internal_ip": "182.78.80.14",
          "vendor_id": 2801,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "esupport@in.airtel.com",
          "default_gateway": "182.78.80.13",
          "firewall_ip": null,
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "ILL",
          "provisioned_speed": 35,
          "price": 11666,
          "city": "Hyderabad",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-06-30",
          "if_name": "Gi0/0/1",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-03-07 12:02:55",
          "cust_name": "Titan",
          "ed_vendor_id": 6,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 12715,
          "isp_wan_id": 6762,
          "client_id": 110,
          "public_ip": "122.186.164.214",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 1264,
          "ent": "2024-10-14 16:15:38",
          "info_id": 644836680,
          "circuit_id": "Titan_4549685_Hosur-HOS",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": 1,
          "device_id": 1050,
          "location_id": 2689,
          "location": "HOS-Tanishq Store",
          "private_ip": null,
          "internal_ip": "122.186.164.214",
          "vendor_id": -1,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "ip.misuse@airtel.com",
          "default_gateway": "122.186.164.213",
          "firewall_ip": null,
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "ILL",
          "provisioned_speed": 35,
          "price": 140000,
          "city": "Hosur",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-07-01",
          "if_name": "ge0/0",
          "if_index": 1,
          "tag_values": null,
          "created_date": "2024-03-07 12:03:03",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The Provider Equipment (PE) is not reachable.\nPlease contact the site manager to check for physical connectivity issues, firewall configuration issues or restrictions, and IP conflicts.\nIf none of these are the cause, please contact the service provider."
        },
        {
          "uid": 13098,
          "isp_wan_id": 9944,
          "client_id": 110,
          "public_ip": "10.85.253.186",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 5144,
          "ent": "2024-10-06 11:07:03",
          "info_id": 644837030,
          "circuit_id": "091DEHR3499037312497",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 11,
          "zone_id": -1,
          "group_id": 202,
          "ext_probe_id": 8,
          "device_id": 1704,
          "location_id": 3872,
          "location": "Pantnagar_Jew",
          "private_ip": null,
          "internal_ip": "10.85.253.186",
          "vendor_id": 2944,
          "vendor": "TCL",
          "vendor_email": "corp.helpdesk@tatacommunications.com",
          "default_gateway": "10.85.253.185",
          "firewall_ip": null,
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 9,
          "business_hr_end": 18,
          "link_type": "MPLS",
          "provisioned_speed": 8,
          "price": 250000,
          "city": "Pantnagar",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-07-01",
          "if_name": "wan2",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-04-03 18:10:22",
          "cust_name": "Titan",
          "ed_vendor_id": 10,
          "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
        },
        {
          "uid": 13059,
          "isp_wan_id": 10624,
          "client_id": 110,
          "public_ip": "43.251.217.74",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 6271,
          "ent": "2024-10-10 17:51:41",
          "info_id": 644835327,
          "circuit_id": "BOR-Tanishq_Store_ONEOTT_INTERTAINMENT_LIMITED",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": -1,
          "device_id": 1035,
          "location_id": 2673,
          "location": "BOR-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 3533,
          "vendor": "AIRNET CABLE AND DATACOM PVT LTD",
          "vendor_email": "cajetan@airnetnetworks.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.84.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 100,
          "price": 1000,
          "city": "Mumbai",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-04-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The Customer Equipment (CE) is currently unreachable.\nThis may be due to power issues, wiring problems, or reachability issues from the internal probe.\nPlease contact the site manager to address this issue."
        },
        {
          "uid": 13063,
          "isp_wan_id": 10636,
          "client_id": 110,
          "public_ip": "103.102.64.150",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-07 15:30:03",
          "info_id": 644835340,
          "circuit_id": "TVC-Tanishq_Store_Redking_India_It_Services_Private_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1059,
          "location_id": 2698,
          "location": "TVC-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 2889,
          "vendor": "REDKING INDIA IT SERVICES PRIVATE LIMITED",
          "vendor_email": "veenitzode@redkingindiait.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.78.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 16000,
          "city": "Mumbai",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-04-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 13114,
          "isp_wan_id": 10643,
          "client_id": 110,
          "public_ip": "122.161.49.41",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-14 03:51:45",
          "info_id": 644835366,
          "circuit_id": "NOD-Tanishq_Store_Bharti_Airtel_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1058,
          "location_id": 2697,
          "location": "NOD-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 2801,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "esupport@in.airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.38.1",
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 500,
          "price": 15000,
          "city": "Noida",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-08-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 13071,
          "isp_wan_id": 10654,
          "client_id": 110,
          "public_ip": "122.179.198.225",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 11299,
          "ent": "2024-10-08 14:43:09",
          "info_id": 644836836,
          "circuit_id": "FRD-Tanishq_Store_Bharti_Airtel_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1045,
          "location_id": 2684,
          "location": "FRD-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 2801,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "shankar.B@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.12.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 500,
          "price": 22000,
          "city": "Faridabad",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-01-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
        },
        {
          "uid": 13065,
          "isp_wan_id": 10668,
          "client_id": 110,
          "public_ip": "182.77.13.235",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-15 13:01:50",
          "info_id": 644835346,
          "circuit_id": "MLP-Tanishq_Store_Bharti_Airtel_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1061,
          "location_id": 2700,
          "location": "MLP-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 2801,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "shankar.B@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.94.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 200,
          "price": 1000,
          "city": "Mumbai",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-01-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 13067,
          "isp_wan_id": 10669,
          "client_id": 110,
          "public_ip": "223.177.190.83",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 2497,
          "ent": "2024-10-14 16:15:32",
          "info_id": 644836916,
          "circuit_id": "PRA-Tanishq_Store_Bharti_Airtel_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1057,
          "location_id": 2696,
          "location": "PRA-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": -1,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "shankar.B@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.34.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 500,
          "price": 60000,
          "city": "New New Delhi",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-04-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 13069,
          "isp_wan_id": 10670,
          "client_id": 110,
          "public_ip": "115.246.164.3",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-07 14:47:32",
          "info_id": 644835350,
          "circuit_id": "PTA-Tanishq_Store_Reliance_Jio_Infocomm_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": -1,
          "device_id": 1054,
          "location_id": 2693,
          "location": "PTA-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.29.2",
          "vendor_id": 2945,
          "vendor": "JIO",
          "vendor_email": "support@jio.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.122.1",
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "DBB",
          "provisioned_speed": 1000,
          "price": 1000,
          "city": "Patna",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-01-01",
          "if_name": "ge0/2",
          "if_index": 3,
          "tag_values": null,
          "created_date": "2024-04-05 16:43:26",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 12785,
          "isp_wan_id": 10802,
          "client_id": 110,
          "public_ip": "47.247.145.18",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 627,
          "ent": "2024-10-07 14:47:33",
          "info_id": 644836533,
          "circuit_id": "091AHME34990A114178-AHMEDABAD-CFA-branch",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 11,
          "zone_id": 194,
          "group_id": 208,
          "ext_probe_id": 11,
          "device_id": 1132,
          "location_id": 2842,
          "location": "AHMEDABAD-CFA-branch",
          "private_ip": null,
          "internal_ip": "47.247.145.18",
          "vendor_id": 2945,
          "vendor": "JIO",
          "vendor_email": "support@jio.com",
          "default_gateway": "47.247.145.17",
          "firewall_ip": null,
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 10,
          "business_hr_end": 18,
          "link_type": "ILL",
          "provisioned_speed": 20,
          "price": 120000,
          "city": "Ahmedabad",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-10-01",
          "if_name": "wan2",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-04-17 16:38:53",
          "cust_name": "Titan",
          "ed_vendor_id": 10,
          "troubleshoot_action": "Site is not reachable because all the links are down, along with the Customer Equipment (CE), while the Provider Equipment (PE) remains reachable.\nPlease contact the site manager to get further resolution."
        },
        {
          "uid": 16424,
          "isp_wan_id": 10813,
          "client_id": 110,
          "public_ip": "36.255.211.37",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 827,
          "ent": "2024-10-07 14:47:33",
          "info_id": 644836783,
          "circuit_id": "AHMEDABAD-CFA-branch_Fiberpipe_communications_pvt._ltd",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 11,
          "zone_id": 194,
          "group_id": 208,
          "ext_probe_id": -1,
          "device_id": 1132,
          "location_id": 2842,
          "location": "AHMEDABAD-CFA-branch",
          "private_ip": null,
          "internal_ip": "36.255.211.37",
          "vendor_id": 2956,
          "vendor": "BHARAT SANCHAR NIGAM LTD",
          "vendor_email": "abuse1@bsnl.co.in",
          "default_gateway": "36.255.211.1",
          "firewall_ip": "None",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 18,
          "link_type": "DBB",
          "provisioned_speed": 200,
          "price": 25000,
          "city": "Ahmedabad",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-01-01",
          "if_name": "wan1",
          "if_index": 1,
          "tag_values": null,
          "created_date": "2024-04-17 19:21:58",
          "cust_name": "Titan",
          "ed_vendor_id": 10,
          "troubleshoot_action": "Site is not reachable because all the links are down, along with the Customer Equipment (CE).\nPlease contact the site manager to get further resolution."
        },
        {
          "uid": 12756,
          "isp_wan_id": 10816,
          "client_id": 110,
          "public_ip": "125.23.63.146",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 571,
          "ent": "2024-10-14 16:15:34",
          "info_id": 644836333,
          "circuit_id": "Kolkata-CFA-Branch_Bharti_Airtel_Limited",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 11,
          "zone_id": 194,
          "group_id": 208,
          "ext_probe_id": 11,
          "device_id": 1135,
          "location_id": 2845,
          "location": "Kolkata-CFA-Branch",
          "private_ip": null,
          "internal_ip": "125.23.63.146",
          "vendor_id": -1,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "shankar.B@airtel.com",
          "default_gateway": "125.23.63.145",
          "firewall_ip": "None",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 10,
          "business_hr_end": 18,
          "link_type": "ILL",
          "provisioned_speed": 20,
          "price": 140000,
          "city": "Kolkata",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2025-01-01",
          "if_name": "wan1",
          "if_index": 1,
          "tag_values": null,
          "created_date": "2024-04-17 19:21:58",
          "cust_name": "Titan",
          "ed_vendor_id": 10,
          "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
        },
        {
          "uid": 15876,
          "isp_wan_id": 12477,
          "client_id": 110,
          "public_ip": "223.236.215.72",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 1993,
          "ent": "2024-10-04 16:59:48",
          "info_id": 644836963,
          "circuit_id": "THZL_Unknown",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 14,
          "zone_id": 191,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1085,
          "location_id": 2742,
          "location": "THZL",
          "private_ip": null,
          "internal_ip": "192.168.2.2",
          "vendor_id": 0,
          "vendor": "Bharti Airtel Limited",
          "vendor_email": "Rahul3.Jain@airtel.com",
          "default_gateway": "192.168.2.1",
          "firewall_ip": "122.160.111.245",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 9,
          "business_hr_end": 6,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 1000,
          "city": "Lucknow",
          "pause_status": 0,
          "util_alert_status": 1,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": null,
          "if_name": "wan2",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-07-14 23:59:40",
          "cust_name": "Titan",
          "ed_vendor_id": 10,
          "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
        },
        {
          "uid": 16358,
          "isp_wan_id": 19550,
          "client_id": 110,
          "public_ip": "182.76.101.56",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-11 11:29:25",
          "info_id": 644835367,
          "circuit_id": "TVC-Tanishq_Store_Unknown",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1059,
          "location_id": 2698,
          "location": "TVC-Tanishq Store",
          "private_ip": null,
          "internal_ip": "182.76.101.56",
          "vendor_id": 0,
          "vendor": "Bharti Airtel Limited",
          "vendor_email": "ip.misuse@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.78.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 9,
          "business_hr_end": 6,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 1000,
          "city": "Delhi",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": null,
          "if_name": "loopback100",
          "if_index": 6,
          "tag_values": null,
          "created_date": "2024-10-07 22:46:27",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 16377,
          "isp_wan_id": 19556,
          "client_id": 110,
          "public_ip": "182.78.141.6",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 9581,
          "ent": "2024-10-11 11:29:25",
          "info_id": 644836921,
          "circuit_id": "KLR-Tanishq_Store_Unknown",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 198,
          "ext_probe_id": -1,
          "device_id": 1063,
          "location_id": 2702,
          "location": "KLR-Tanishq Store",
          "private_ip": null,
          "internal_ip": "182.78.141.6",
          "vendor_id": 0,
          "vendor": "Bharti Airtel Limited",
          "vendor_email": "ip.misuse@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.96.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 9,
          "business_hr_end": 6,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 1000,
          "city": "Delhi",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": null,
          "if_name": "ge0/1",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-10-09 15:21:41",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "WAN interface is DOWN on the Customer Equipment (CE).\nPlease contact the site manager to verify if there are any cabeling issues, setting mismatches,\nISP-side issues, network congestion or if the port is administratively shut down."
        },
        {
          "uid": 16458,
          "isp_wan_id": 19568,
          "client_id": 110,
          "public_ip": "122.186.215.145",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 2751,
          "ent": "2024-10-14 09:18:20",
          "info_id": 644835326,
          "circuit_id": "BOR-Tanishq_Store_Unknown",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": -1,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": -1,
          "device_id": 1035,
          "location_id": 2673,
          "location": "BOR-Tanishq Store",
          "private_ip": null,
          "internal_ip": "122.186.215.145",
          "vendor_id": 0,
          "vendor": "Bharti Airtel Limited",
          "vendor_email": "ip.misuse@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.84.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 9,
          "business_hr_end": 6,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 1000,
          "city": "Pune",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": null,
          "if_name": "loopback100",
          "if_index": 6,
          "tag_values": null,
          "created_date": "2024-10-14 09:11:37",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The Customer Equipment (CE) is currently unreachable.\nThis may be due to power issues, wiring problems, or reachability issues from the internal probe.\nPlease contact the site manager to address this issue."
        },
        {
          "uid": 16460,
          "isp_wan_id": 19569,
          "client_id": 110,
          "public_ip": "125.20.255.117",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 917,
          "ent": "2024-10-14 09:48:21",
          "info_id": 644835348,
          "circuit_id": "PTA-Tanishq_Store_Unknown",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": -1,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": -1,
          "device_id": 1054,
          "location_id": 2693,
          "location": "PTA-Tanishq Store",
          "private_ip": null,
          "internal_ip": "125.20.255.117",
          "vendor_id": 0,
          "vendor": "Bharti Airtel Limited",
          "vendor_email": "noc-dataprov@airtel.com",
          "default_gateway": "0.0.0.0",
          "firewall_ip": "10.130.122.1",
          "promised_uptime": 95,
          "resolution_time": 8,
          "business_hr_start": 9,
          "business_hr_end": 6,
          "link_type": "DBB",
          "provisioned_speed": 50,
          "price": 1000,
          "city": "Mumbai",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": null,
          "if_name": "loopback100",
          "if_index": 6,
          "tag_values": null,
          "created_date": "2024-10-14 09:31:40",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "The WAN IP (CE-IP) is not functional on Customer Equipment (CE).\nPlease contact the site manager to check for any IP misconfiguration, DHCP conflicts, or interface failure."
        },
        {
          "uid": 16464,
          "isp_wan_id": 19573,
          "client_id": 110,
          "public_ip": "125.19.231.70",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 2444,
          "ent": "2024-10-14 15:20:52",
          "info_id": 644836487,
          "circuit_id": "14489459",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": 11,
          "device_id": 1939,
          "location_id": 4515,
          "location": "BGR-Tanishq Store",
          "private_ip": null,
          "internal_ip": "125.19.231.70",
          "vendor_id": 2801,
          "vendor": "BHARTI AIRTEL LIMITED",
          "vendor_email": "noc-dataprov@airtel.com",
          "default_gateway": "125.19.231.69",
          "firewall_ip": null,
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 10,
          "business_hr_end": 21,
          "link_type": "ILL",
          "provisioned_speed": 35,
          "price": 140000,
          "city": "Mumbai",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-12-31",
          "if_name": "Gi0/0/3",
          "if_index": 4,
          "tag_values": null,
          "created_date": "2024-10-14 14:17:55",
          "cust_name": "Titan",
          "ed_vendor_id": 6,
          "troubleshoot_action": "The Customer Equipment (CE) is currently unreachable.\nThis may be due to power issues, wiring problems, or reachability issues from the internal probe.\nPlease contact the site manager to address this issue."
        },
        {
          "uid": 16466,
          "isp_wan_id": 19575,
          "client_id": 110,
          "public_ip": "192.168.1.2",
          "good_cnt": 0,
          "bad_cnt": 0,
          "up_cnt": 0,
          "down_cnt": 2365,
          "ent": "2024-10-14 15:47:15",
          "info_id": 644836858,
          "circuit_id": "14735260",
          "min_rtt": 0,
          "avg_rtt": 0,
          "max_rtt": 0,
          "jitter": 0,
          "packet_loss": 100,
          "status": 0,
          "dg_reach_status": 1,
          "cust_id": 110,
          "bu_id": 12,
          "zone_id": 194,
          "group_id": 332,
          "ext_probe_id": 1,
          "device_id": 1940,
          "location_id": 4516,
          "location": "CPV-Tanishq Store",
          "private_ip": null,
          "internal_ip": "192.168.1.2",
          "vendor_id": 3107,
          "vendor": "AIRFIBER",
          "vendor_email": "bdsupport@airfiber.com",
          "default_gateway": "192.168.1.2",
          "firewall_ip": "10.130.150.1",
          "promised_uptime": 99,
          "resolution_time": 4,
          "business_hr_start": 9,
          "business_hr_end": 18,
          "link_type": "DBB",
          "provisioned_speed": 100,
          "price": 140000,
          "city": "Chennai",
          "pause_status": 0,
          "util_alert_status": 0,
          "util_warn_th": 80,
          "util_critical_th": 100,
          "renewal_date": "2024-12-31",
          "if_name": "GigabitEthernet0/0/1",
          "if_index": 2,
          "tag_values": null,
          "created_date": "2024-10-14 14:37:02",
          "cust_name": "Titan",
          "ed_vendor_id": 13,
          "troubleshoot_action": "Site is not reachable because the link is down.\nPlease contact the site manager to check for any power outages or malfunctions with the customer equipment (CE)."
        }
      ]);

}

export const ISPCard1RestCallForCard = async (ispWanID, setIspParams, setUpStatus = null, setGoodStatus = null) => {
const data={
  "cust_id": 110,
  "cust_name": "Titan",
  "cust_phone_no": "9998881234",
  "cust_email": "tanishq@tata.com",
  "cust_address": "Bangalore",
  "custom_cust_id": "HT1A2UQWBU",
  "cust_type": 0,
  "msp_status": 0,
  "is_organic": 0,
  "onboard_status": 1,
  "server_id": 39,
  "cust_subnet": 30,
  "cust_domains": null,
  "ent_date": "2024-03-07 10:48:24",
  "info_id": 645186372,
  "isp_wan_id": 6731,
  "client_id": 110,
  "circuit_id": "TITA_HYD_14479914-JBH",
  "public_ip": "182.78.80.14",
  "min_rtt": 0,
  "avg_rtt": 0,
  "max_rtt": 0,
  "jitter": 0,
  "packet_loss": 100,
  "status": 0,
  "dg_reach_status": 1,
  "ent": "2024-10-07 14:47:32",
  "uid": 12675,
  "good_cnt": 0,
  "bad_cnt": 0,
  "up_cnt": 0,
  "down_cnt": 18353,
  "bu_id": 12,
  "zone_id": 194,
  "group_id": 198,
  "ext_probe_id": 1,
  "device_id": 1022,
  "location_id": 2660,
  "location": "ZHJ-Tanishq Store",
  "private_ip": null,
  "internal_ip": "182.78.80.14",
  "vendor_id": 2801,
  "vendor": "BHARTI AIRTEL LIMITED",
  "vendor_email": "esupport@in.airtel.com",
  "default_gateway": "182.78.80.13",
  "firewall_ip": null,
  "promised_uptime": 99,
  "resolution_time": 4,
  "business_hr_start": 10,
  "business_hr_end": 21,
  "link_type": "ILL",
  "provisioned_speed": 35,
  "price": 11666,
  "city": "Hyderabad",
  "pause_status": 0,
  "util_alert_status": 1,
  "util_warn_th": 80,
  "util_critical_th": 100,
  "renewal_date": "2024-06-30",
  "if_name": "Gi0/0/1",
  "if_index": 2,
  "tag_values": null,
  "created_date": "2024-03-07 12:02:55",
  "device_ip": "10.130.124.1",
  "ed_vendor_id": 6,
  "dg_jitter": 1.02892,
  "dg_latency": 44.0926,
  "dg_packet_loss": 60
}
  setIspParams(data)
  if (setUpStatus !== null) {
    if (data.packet_loss === 100) {
        setUpStatus(false);
    }
    else {
        setUpStatus(true);
    }

    if (parseFloat(data.jitter) < 150 && parseFloat(data.isp_data.avg_rtt) < 150) {
        setGoodStatus(true);
    }
    else {
        setGoodStatus(false);
    }
}
}

export const ISPCard1RestCallForGraphCard = async (ispWanID, timeValue, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {
  setJitterData([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ]);
  setLatencyData([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ]);
  setPacketLossData([
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ]);
  setStatusData([
    0,
    0
  ]);
  setTimeData([
    "08:44",
    "08:45",
    "08:46",
    "08:47",
    "08:48",
    "08:49",
    "08:50",
    "08:51",
    "08:52",
    "08:53",
    "08:54",
    "08:55",
    "08:56",
    "08:57",
    "08:58",
    "08:59",
    "09:00",
    "09:01",
    "09:02",
    "09:03",
    "09:04",
    "09:05",
    "09:06",
    "09:07",
    "09:08",
    "09:09",
    "09:10",
    "09:11",
    "09:12",
    "09:13",
    "09:14",
    "09:15",
    "09:16",
    "09:17",
    "09:18",
    "09:19",
    "09:20",
    "09:21",
    "09:22",
    "09:23",
    "09:24",
    "09:25",
    "09:26",
    "09:27",
    "09:28",
    "09:29",
    "09:30",
    "09:31",
    "09:32",
    "09:33",
    "09:34",
    "09:35",
    "09:36",
    "09:37",
    "09:38",
    "09:39",
    "09:40",
    "09:41",
    "09:42",
    "09:43"
  ]);
  setStatusTimeData([
    "08:44",
    "09:43"
  ]);
  setUpDownSeriesData([
    {
      "name": "DOWN(08:44-09:43)",
      "data": [
        59
      ],
      "color": "#F07470"
    }
  ]);
  setOpenBackdrop(false);
}

export const ISPCard1BandWidthData = async (ispWanID, timeValue, setInBandWidthData, setOutBandWidthData, setBandWithTimeData) => {
  setInBandWidthData([
    0.25103,
    0.206058,
    0.257072,
    0.249525,
    0.271411,
    0.271832,
    0.286349,
    0.299526,
    0.301701,
    0.288336,
    0.259181,
    0.253473,
    0.27133,
    0.287221,
    0.250514,
    0.2692,
    0.25508,
    0.296058,
    0.275238,
    0.26627,
    0.253929,
    0.237214,
    0.282644,
    0.253176,
    0.298484,
    0.277262,
    0.267301,
    0.277553,
    0.343671,
    0.27574,
    0.281107,
    0.297671,
    0.288981,
    0.277416,
    0.27862,
    0.281202,
    1.59065,
    0.269307,
    0.312116,
    0.279876,
    0.259792,
    0.288185,
    0.272944,
    0.302828,
    0.269881,
    0.272871,
    0.329104,
    0.271354,
    0.270856,
    0.282867,
    0.278732,
    0.300573,
    0.296788,
    0.296986,
    0.297406,
    0.284437,
    0.304138,
    0.306025,
    0.28918
  ]);
  setOutBandWidthData([
    9.92673,
    7.75043,
    10.2695,
    9.86722,
    10.1357,
    10.151,
    10.7753,
    10.5805,
    10.3515,
    9.18141,
    11.1577,
    10.8897,
    11.0976,
    11.977,
    10.8466,
    10.4561,
    8.92915,
    4.38484,
    7.31814,
    7.97064,
    10.953,
    10.3308,
    12.017,
    10.9014,
    11.9539,
    11.3906,
    11.2635,
    11.6849,
    11.8158,
    11.0839,
    11.7474,
    12.3256,
    11.3175,
    10.9256,
    10.9463,
    10.6317,
    12.0471,
    11.13,
    12.1246,
    11.5632,
    10.8296,
    11.8803,
    11.5221,
    11.1456,
    11.2652,
    11.2323,
    12.675,
    11.3081,
    10.8912,
    6.1153,
    11.5241,
    12.4008,
    12.172,
    12.03,
    12.0774,
    11.3116,
    12.2429,
    12.4252,
    11.8015
  ]);
  setBandWithTimeData([
    "08:44:25",
    "08:45:26",
    "08:46:25",
    "08:47:26",
    "08:48:25",
    "08:49:26",
    "08:50:27",
    "08:51:26",
    "08:52:25",
    "08:53:25",
    "08:54:25",
    "08:55:26",
    "08:56:27",
    "08:57:25",
    "08:58:26",
    "08:59:25",
    "09:00:27",
    "09:01:27",
    "09:02:25",
    "09:03:25",
    "09:04:25",
    "09:05:29",
    "09:06:26",
    "09:07:27",
    "09:08:25",
    "09:09:26",
    "09:10:27",
    "09:11:26",
    "09:12:26",
    "09:13:26",
    "09:14:26",
    "09:16:26",
    "09:17:25",
    "09:18:25",
    "09:19:25",
    "09:20:27",
    "09:21:25",
    "09:22:28",
    "09:23:25",
    "09:24:25",
    "09:25:28",
    "09:26:26",
    "09:27:25",
    "09:28:26",
    "09:29:28",
    "09:30:30",
    "09:31:25",
    "09:32:25",
    "09:33:27",
    "09:34:27",
    "09:35:28",
    "09:36:26",
    "09:37:25",
    "09:38:25",
    "09:39:25",
    "09:40:29",
    "09:41:28",
    "09:42:26",
    "09:43:27"
  ]);
}


export const ISPCard1RestCallForGraphCardByDate = async (ispWanID, date, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {

  setJitterData([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ]);
            setLatencyData([
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]);
            setPacketLossData([
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100,
              100
            ]);
            setStatusData([
              0,
              0
            ]);
            setTimeData([
              "00:00",
              "00:01",
              "00:02",
              "00:03",
              "00:04",
              "00:05",
              "00:06",
              "00:07",
              "00:08",
              "00:09",
              "00:10",
              "00:11",
              "00:12",
              "00:13",
              "00:14",
              "00:15",
              "00:16",
              "00:17",
              "00:18",
              "00:19",
              "00:20",
              "00:21",
              "00:22",
              "00:23",
              "00:24",
              "00:25",
              "00:26",
              "00:27",
              "00:28",
              "00:29",
              "00:30",
              "00:31",
              "00:32",
              "00:33",
              "00:34",
              "00:35",
              "00:36",
              "00:37",
              "00:38",
              "00:39",
              "00:40",
              "00:41",
              "00:42",
              "00:43",
              "00:44",
              "00:45",
              "00:46",
              "00:47",
              "00:48",
              "00:49",
              "00:50",
              "00:51",
              "00:52",
              "00:53",
              "00:54",
              "00:55",
              "00:56",
              "00:57",
              "00:58",
              "00:59",
              "01:00",
              "01:01",
              "01:02",
              "01:03",
              "01:04",
              "01:05",
              "01:06",
              "01:07",
              "01:08",
              "01:09",
              "01:10",
              "01:11",
              "01:12",
              "01:13",
              "01:14",
              "01:15",
              "01:16",
              "01:17",
              "01:18",
              "01:19",
              "01:20",
              "01:21",
              "01:22",
              "01:23",
              "01:24",
              "01:25",
              "01:26",
              "01:27",
              "01:28",
              "01:29",
              "01:30",
              "01:31",
              "01:32",
              "01:33",
              "01:34",
              "01:35",
              "01:36",
              "01:37",
              "01:38",
              "01:39",
              "01:40",
              "01:41",
              "01:42",
              "01:43",
              "01:44",
              "01:45",
              "01:46",
              "01:47",
              "01:48",
              "01:49",
              "01:50",
              "01:51",
              "01:52",
              "01:53",
              "01:54",
              "01:55",
              "01:56",
              "01:57",
              "01:58",
              "01:59",
              "02:00",
              "02:01",
              "02:02",
              "02:03",
              "02:04",
              "02:05",
              "02:06",
              "02:07",
              "02:08",
              "02:09",
              "02:10",
              "02:11",
              "02:12",
              "02:13",
              "02:14",
              "02:15",
              "02:16",
              "02:17",
              "02:18",
              "02:19",
              "02:20",
              "02:21",
              "02:22",
              "02:23",
              "02:24",
              "02:25",
              "02:26",
              "02:27",
              "02:28",
              "02:29",
              "02:30",
              "02:31",
              "02:32",
              "02:33",
              "02:34",
              "02:35",
              "02:36",
              "02:37",
              "02:38",
              "02:39",
              "02:40",
              "02:41",
              "02:42",
              "02:43",
              "02:44",
              "02:45",
              "02:46",
              "02:47",
              "02:48",
              "02:49",
              "02:50",
              "02:51",
              "02:52",
              "02:53",
              "02:54",
              "02:55",
              "02:56",
              "02:57",
              "02:58",
              "02:59",
              "03:00",
              "03:01",
              "03:02",
              "03:03",
              "03:04",
              "03:05",
              "03:06",
              "03:07",
              "03:08",
              "03:09",
              "03:10",
              "03:11",
              "03:12",
              "03:13",
              "03:14",
              "03:15",
              "03:16",
              "03:17",
              "03:18",
              "03:19",
              "03:20",
              "03:21",
              "03:22",
              "03:23",
              "03:24",
              "03:25",
              "03:26",
              "03:27",
              "03:28",
              "03:29",
              "03:30",
              "03:31",
              "03:32",
              "03:33",
              "03:34",
              "03:35",
              "03:36",
              "03:37",
              "03:38",
              "03:39",
              "03:40",
              "03:41",
              "03:42",
              "03:43",
              "03:44",
              "03:45",
              "03:46",
              "03:47",
              "03:48",
              "03:49",
              "03:50",
              "03:51",
              "03:52",
              "03:53",
              "03:54",
              "03:55",
              "03:56",
              "03:57",
              "03:58",
              "03:59",
              "04:00",
              "04:01",
              "04:02",
              "04:03",
              "04:04",
              "04:05",
              "04:06",
              "04:07",
              "04:08",
              "04:09",
              "04:10",
              "04:11",
              "04:12",
              "04:13",
              "04:14",
              "04:15",
              "04:16",
              "04:17",
              "04:18",
              "04:19",
              "04:20",
              "04:21",
              "04:22",
              "04:23",
              "04:24",
              "04:25",
              "04:26",
              "04:27",
              "04:28",
              "04:29",
              "04:30",
              "04:31",
              "04:32",
              "04:33",
              "04:34",
              "04:35",
              "04:36",
              "04:37",
              "04:38",
              "04:39",
              "04:40",
              "04:41",
              "04:42",
              "04:43",
              "04:44",
              "04:45",
              "04:46",
              "04:47",
              "04:48",
              "04:49",
              "04:50",
              "04:51",
              "04:52",
              "04:53",
              "04:54",
              "04:55",
              "04:56",
              "04:57",
              "04:58",
              "04:59",
              "05:00",
              "05:01",
              "05:02",
              "05:03",
              "05:04",
              "05:05",
              "05:06",
              "05:07",
              "05:08",
              "05:09",
              "05:10",
              "05:11",
              "05:12",
              "05:13",
              "05:14",
              "05:15",
              "05:16",
              "05:17",
              "05:18",
              "05:19",
              "05:20",
              "05:21",
              "05:22",
              "05:23",
              "05:24",
              "05:25",
              "05:26",
              "05:27",
              "05:28",
              "05:29",
              "05:30",
              "05:31",
              "05:32",
              "05:33",
              "05:34",
              "05:35",
              "05:36",
              "05:37",
              "05:38",
              "05:39",
              "05:40",
              "05:41",
              "05:42",
              "05:43",
              "05:44",
              "05:45",
              "05:46",
              "05:47",
              "05:48",
              "05:49",
              "05:50",
              "05:51",
              "05:52",
              "05:53",
              "05:54",
              "05:55",
              "05:56",
              "05:57",
              "05:58",
              "05:59",
              "06:00",
              "06:01",
              "06:02",
              "06:03",
              "06:04",
              "06:05",
              "06:06",
              "06:07",
              "06:08",
              "06:09",
              "06:10",
              "06:11",
              "06:12",
              "06:13",
              "06:14",
              "06:15",
              "06:16",
              "06:17",
              "06:18",
              "06:19",
              "06:20",
              "06:21",
              "06:22",
              "06:23",
              "06:24",
              "06:25",
              "06:26",
              "06:27",
              "06:28",
              "06:29",
              "06:30",
              "06:31",
              "06:32",
              "06:33",
              "06:34",
              "06:35",
              "06:36",
              "06:37",
              "06:38",
              "06:39",
              "06:40",
              "06:41",
              "06:42",
              "06:43",
              "06:44",
              "06:45",
              "06:46",
              "06:47",
              "06:48",
              "06:49",
              "06:50",
              "06:51",
              "06:52",
              "06:53",
              "06:54",
              "06:55",
              "06:56",
              "06:57",
              "06:58",
              "06:59",
              "07:00",
              "07:01",
              "07:02",
              "07:03",
              "07:04",
              "07:05",
              "07:06",
              "07:07",
              "07:08",
              "07:09",
              "07:10",
              "07:11",
              "07:12",
              "07:13",
              "07:14",
              "07:15",
              "07:16",
              "07:17",
              "07:18",
              "07:19",
              "07:20",
              "07:21",
              "07:22",
              "07:23",
              "07:24",
              "07:25",
              "07:26",
              "07:27",
              "07:28",
              "07:29",
              "07:30",
              "07:31",
              "07:32",
              "07:33",
              "07:34",
              "07:35",
              "07:36",
              "07:37",
              "07:38",
              "07:39",
              "07:40",
              "07:41",
              "07:42",
              "07:43",
              "07:44",
              "07:45",
              "07:46",
              "07:47",
              "07:48",
              "07:49",
              "07:50",
              "07:51",
              "07:52",
              "07:53",
              "07:54",
              "07:55",
              "07:56",
              "07:57",
              "07:58",
              "07:59",
              "08:00",
              "08:01",
              "08:02",
              "08:03",
              "08:04",
              "08:05",
              "08:06",
              "08:07",
              "08:08",
              "08:09",
              "08:10",
              "08:11",
              "08:12",
              "08:13",
              "08:14",
              "08:15",
              "08:16",
              "08:17",
              "08:18",
              "08:19",
              "08:20",
              "08:21",
              "08:22",
              "08:23",
              "08:24",
              "08:25",
              "08:26",
              "08:27",
              "08:28",
              "08:29",
              "08:30",
              "08:31",
              "08:32",
              "08:33",
              "08:34",
              "08:35",
              "08:36",
              "08:37",
              "08:38",
              "08:39",
              "08:40",
              "08:41",
              "08:42",
              "08:43",
              "08:44",
              "08:45",
              "08:46",
              "08:47",
              "08:48",
              "08:49",
              "08:50",
              "08:51",
              "08:52",
              "08:53",
              "08:54",
              "08:55",
              "08:56",
              "08:57",
              "08:58",
              "08:59",
              "09:00",
              "09:01",
              "09:02",
              "09:03",
              "09:04",
              "09:05",
              "09:06",
              "09:07",
              "09:08",
              "09:09",
              "09:10",
              "09:11",
              "09:12",
              "09:13",
              "09:14",
              "09:15",
              "09:16",
              "09:17",
              "09:18",
              "09:19",
              "09:20",
              "09:21",
              "09:22",
              "09:23",
              "09:24",
              "09:25",
              "09:26",
              "09:27",
              "09:28",
              "09:29",
              "09:30",
              "09:31",
              "09:32",
              "09:33",
              "09:34",
              "09:35",
              "09:36",
              "09:37",
              "09:38",
              "09:39",
              "09:40",
              "09:41",
              "09:42",
              "09:43",
              "09:44",
              "09:45",
              "09:46",
              "09:47",
              "09:48",
              "09:49",
              "09:50",
              "09:51",
              "09:52",
              "09:53",
              "09:54",
              "09:55",
              "09:56",
              "09:57",
              "09:58",
              "09:59",
              "10:00",
              "10:01",
              "10:02",
              "10:03",
              "10:04",
              "10:05",
              "10:06",
              "10:07",
              "10:08",
              "10:09",
              "10:10",
              "10:11",
              "10:12",
              "10:13",
              "10:14",
              "10:15",
              "10:16",
              "10:17",
              "10:18",
              "10:19",
              "10:20",
              "10:21",
              "10:22",
              "10:23",
              "10:24",
              "10:25",
              "10:26",
              "10:27",
              "10:28",
              "10:29",
              "10:30",
              "10:31",
              "10:32",
              "10:33",
              "10:34",
              "10:35",
              "10:36",
              "10:37",
              "10:38",
              "10:39",
              "10:40",
              "10:41",
              "10:42",
              "10:43",
              "10:44",
              "10:45",
              "10:46",
              "10:47",
              "10:48",
              "10:49",
              "10:50",
              "10:51",
              "10:52",
              "10:53",
              "10:54",
              "10:55",
              "10:56",
              "10:57",
              "10:58",
              "10:59",
              "11:00",
              "11:01",
              "11:02",
              "11:03",
              "11:04",
              "11:05",
              "11:06",
              "11:07",
              "11:08",
              "11:09",
              "11:10",
              "11:11",
              "11:12",
              "11:13",
              "11:14",
              "11:15",
              "11:16",
              "11:17",
              "11:18",
              "11:19",
              "11:20",
              "11:21",
              "11:22",
              "11:23",
              "11:24",
              "11:25",
              "11:26",
              "11:27",
              "11:28",
              "11:29",
              "11:30",
              "11:31",
              "11:32",
              "11:33",
              "11:34",
              "11:35",
              "11:36",
              "11:37",
              "11:38",
              "11:39",
              "11:40",
              "11:41",
              "11:42",
              "11:43",
              "11:44",
              "11:45",
              "11:46",
              "11:47",
              "11:48",
              "11:49",
              "11:50",
              "11:51",
              "11:52",
              "11:53",
              "11:54",
              "11:55",
              "11:56",
              "11:57",
              "11:58",
              "11:59",
              "12:00",
              "12:01",
              "12:02",
              "12:03",
              "12:04",
              "12:05",
              "12:06",
              "12:07",
              "12:08",
              "12:09",
              "12:10",
              "12:11",
              "12:12",
              "12:13",
              "12:14",
              "12:15",
              "12:16",
              "12:17",
              "12:18",
              "12:19",
              "12:20",
              "12:21",
              "12:22",
              "12:23",
              "12:24",
              "12:25",
              "12:26",
              "12:27",
              "12:28",
              "12:29",
              "12:30",
              "12:31",
              "12:32",
              "12:33",
              "12:34",
              "12:35",
              "12:36",
              "12:37",
              "12:38",
              "12:39",
              "12:40",
              "12:41",
              "12:42",
              "12:43",
              "12:44",
              "12:45",
              "12:46",
              "12:47",
              "12:48",
              "12:49",
              "12:50",
              "12:51",
              "12:52",
              "12:53",
              "12:54",
              "12:55",
              "12:56",
              "12:57",
              "12:58",
              "12:59",
              "13:00",
              "13:01",
              "13:02",
              "13:03",
              "13:04",
              "13:05",
              "13:06",
              "13:07",
              "13:08",
              "13:09",
              "13:10",
              "13:11",
              "13:12",
              "13:13",
              "13:14",
              "13:15",
              "13:16",
              "13:17",
              "13:18",
              "13:19",
              "13:20",
              "13:21",
              "13:22",
              "13:23",
              "13:24",
              "13:25",
              "13:26",
              "13:27",
              "13:28",
              "13:29",
              "13:30",
              "13:31",
              "13:32",
              "13:33",
              "13:34",
              "13:35",
              "13:36",
              "13:37",
              "13:38",
              "13:39",
              "13:40",
              "13:41",
              "13:42",
              "13:43",
              "13:44",
              "13:45",
              "13:46",
              "13:47",
              "13:48",
              "13:49",
              "13:50",
              "13:51",
              "13:52",
              "13:53",
              "13:54",
              "13:55",
              "13:56",
              "13:57",
              "13:58",
              "13:59",
              "14:00",
              "14:01",
              "14:02",
              "14:03",
              "14:04",
              "14:05",
              "14:06",
              "14:07",
              "14:08",
              "14:09",
              "14:10",
              "14:11",
              "14:12",
              "14:13",
              "14:14",
              "14:15",
              "14:16",
              "14:17",
              "14:18",
              "14:19",
              "14:20",
              "14:21",
              "14:22",
              "14:23",
              "14:24",
              "14:25",
              "14:26",
              "14:27",
              "14:28",
              "14:29",
              "14:30",
              "14:31",
              "14:32",
              "14:33",
              "14:34",
              "14:35",
              "14:36",
              "14:37",
              "14:38",
              "14:39",
              "14:40",
              "14:41",
              "14:42",
              "14:43",
              "14:44",
              "14:45",
              "14:46",
              "14:47",
              "14:48",
              "14:49",
              "14:50",
              "14:51",
              "14:52",
              "14:53",
              "14:54",
              "14:55",
              "14:56",
              "14:57",
              "14:58",
              "14:59",
              "15:00",
              "15:01",
              "15:02",
              "15:03",
              "15:04",
              "15:05",
              "15:06",
              "15:07",
              "15:08",
              "15:09",
              "15:10",
              "15:11",
              "15:12",
              "15:13",
              "15:14",
              "15:15",
              "15:16",
              "15:17",
              "15:18",
              "15:19",
              "15:20",
              "15:21",
              "15:22",
              "15:23",
              "15:24",
              "15:25",
              "15:26",
              "15:27",
              "15:28",
              "15:29",
              "15:30",
              "15:31",
              "15:32",
              "15:33",
              "15:34",
              "15:35",
              "15:36",
              "15:37",
              "15:38",
              "15:39",
              "15:40",
              "15:41",
              "15:42",
              "15:43",
              "15:44",
              "15:45",
              "15:46",
              "15:47",
              "15:48",
              "15:49",
              "15:50",
              "15:51",
              "15:52",
              "15:53",
              "15:54",
              "15:55",
              "15:56",
              "15:57",
              "15:58",
              "15:59",
              "16:00",
              "16:01",
              "16:02",
              "16:03",
              "16:04",
              "16:05",
              "16:06",
              "16:07",
              "16:08",
              "16:09",
              "16:10",
              "16:11",
              "16:12",
              "16:13",
              "16:14",
              "16:15",
              "16:16",
              "16:17",
              "16:18",
              "16:19",
              "16:20",
              "16:21",
              "16:22",
              "16:23",
              "16:24",
              "16:25",
              "16:26",
              "16:27",
              "16:28",
              "16:29",
              "16:30",
              "16:31",
              "16:32",
              "16:33",
              "16:34",
              "16:35",
              "16:36",
              "16:37",
              "16:38",
              "16:39",
              "16:40",
              "16:41",
              "16:42",
              "16:43",
              "16:44",
              "16:45",
              "16:46",
              "16:47",
              "16:48",
              "16:49",
              "16:50",
              "16:51",
              "16:52",
              "16:53",
              "16:54",
              "16:55",
              "16:56",
              "16:57",
              "16:58",
              "16:59",
              "17:00",
              "17:01",
              "17:02",
              "17:03",
              "17:04",
              "17:05",
              "17:06",
              "17:07",
              "17:08",
              "17:09",
              "17:10",
              "17:11",
              "17:12",
              "17:13",
              "17:14",
              "17:15",
              "17:16",
              "17:17",
              "17:18",
              "17:19",
              "17:20",
              "17:21",
              "17:22",
              "17:23",
              "17:24",
              "17:25",
              "17:26",
              "17:27",
              "17:28",
              "17:29",
              "17:30",
              "17:31",
              "17:32",
              "17:33",
              "17:34",
              "17:35",
              "17:36",
              "17:37",
              "17:38",
              "17:39",
              "17:40",
              "17:41",
              "17:42",
              "17:43",
              "17:44",
              "17:45",
              "17:46",
              "17:47",
              "17:48",
              "17:49",
              "17:50",
              "17:51",
              "17:52",
              "17:53",
              "17:54",
              "17:55",
              "17:56",
              "17:57",
              "17:58",
              "17:59",
              "18:00",
              "18:01",
              "18:02",
              "18:03",
              "18:04",
              "18:05",
              "18:06",
              "18:07",
              "18:08",
              "18:09",
              "18:10",
              "18:11",
              "18:12",
              "18:13",
              "18:14",
              "18:15",
              "18:16",
              "18:17",
              "18:18",
              "18:19",
              "18:20",
              "18:21",
              "18:22",
              "18:23",
              "18:24",
              "18:25",
              "18:26",
              "18:27",
              "18:28",
              "18:29",
              "18:30",
              "18:31",
              "18:32",
              "18:33",
              "18:34",
              "18:35",
              "18:36",
              "18:37",
              "18:38",
              "18:39",
              "18:40",
              "18:41",
              "18:42",
              "18:43",
              "18:44",
              "18:45",
              "18:46",
              "18:47",
              "18:48",
              "18:49",
              "18:50",
              "18:51",
              "18:52",
              "18:53",
              "18:54",
              "18:55",
              "18:56",
              "18:57",
              "18:58",
              "18:59",
              "19:00",
              "19:01",
              "19:02",
              "19:03",
              "19:04",
              "19:05",
              "19:06",
              "19:07",
              "19:08",
              "19:09",
              "19:10",
              "19:11",
              "19:12",
              "19:13",
              "19:14",
              "19:15",
              "19:16",
              "19:17",
              "19:18",
              "19:19",
              "19:20",
              "19:21",
              "19:22",
              "19:23",
              "19:24",
              "19:25",
              "19:26",
              "19:27",
              "19:28",
              "19:29",
              "19:30",
              "19:31",
              "19:32",
              "19:33",
              "19:34",
              "19:35",
              "19:36",
              "19:37",
              "19:38",
              "19:39",
              "19:40",
              "19:41",
              "19:42",
              "19:43",
              "19:44",
              "19:45",
              "19:46",
              "19:47",
              "19:48",
              "19:49",
              "19:50",
              "19:51",
              "19:52",
              "19:53",
              "19:54",
              "19:55",
              "19:56",
              "19:57",
              "19:58",
              "19:59",
              "20:00",
              "20:01",
              "20:02",
              "20:03",
              "20:04",
              "20:05",
              "20:06",
              "20:07",
              "20:08",
              "20:09",
              "20:10",
              "20:11",
              "20:12",
              "20:13",
              "20:14",
              "20:15",
              "20:16",
              "20:17",
              "20:18",
              "20:19",
              "20:20",
              "20:21",
              "20:22",
              "20:23",
              "20:24",
              "20:25",
              "20:26",
              "20:27",
              "20:28",
              "20:29",
              "20:30",
              "20:31",
              "20:32",
              "20:33",
              "20:34",
              "20:35",
              "20:36",
              "20:37",
              "20:38",
              "20:39",
              "20:40",
              "20:41",
              "20:42",
              "20:43",
              "20:44",
              "20:45",
              "20:46",
              "20:47",
              "20:48",
              "20:49",
              "20:50",
              "20:51",
              "20:52",
              "20:53",
              "20:54",
              "20:55",
              "20:56",
              "20:57",
              "20:58",
              "20:59",
              "21:00",
              "21:01",
              "21:02",
              "21:03",
              "21:04",
              "21:05",
              "21:06",
              "21:07",
              "21:08",
              "21:09",
              "21:10",
              "21:11",
              "21:12",
              "21:13",
              "21:14",
              "21:15",
              "21:16",
              "21:17",
              "21:18",
              "21:19",
              "21:20",
              "21:21",
              "21:22",
              "21:23",
              "21:24",
              "21:25",
              "21:26",
              "21:27",
              "21:28",
              "21:29",
              "21:30",
              "21:31",
              "21:32",
              "21:33",
              "21:34",
              "21:35",
              "21:36",
              "21:37",
              "21:38",
              "21:39",
              "21:40",
              "21:41",
              "21:42",
              "21:43",
              "21:44",
              "21:45",
              "21:46",
              "21:47",
              "21:48",
              "21:49",
              "21:50",
              "21:51",
              "21:52",
              "21:53",
              "21:54",
              "21:55",
              "21:56",
              "21:57",
              "21:58",
              "21:59",
              "22:00",
              "22:01",
              "22:02",
              "22:03",
              "22:04",
              "22:05",
              "22:06",
              "22:07",
              "22:08",
              "22:09",
              "22:10",
              "22:11",
              "22:12",
              "22:13",
              "22:14",
              "22:15",
              "22:16",
              "22:17",
              "22:18",
              "22:19",
              "22:20",
              "22:21",
              "22:22",
              "22:23",
              "22:24",
              "22:25",
              "22:26",
              "22:27",
              "22:28",
              "22:29",
              "22:30",
              "22:31",
              "22:32",
              "22:33",
              "22:34",
              "22:35",
              "22:36",
              "22:37",
              "22:38",
              "22:39",
              "22:40",
              "22:41",
              "22:42",
              "22:43",
              "22:44",
              "22:45",
              "22:46",
              "22:47",
              "22:48",
              "22:49",
              "22:50",
              "22:51",
              "22:52",
              "22:53",
              "22:54",
              "22:55",
              "22:56",
              "22:57",
              "22:58",
              "22:59",
              "23:00",
              "23:01",
              "23:02",
              "23:03",
              "23:04",
              "23:05",
              "23:06",
              "23:07",
              "23:08",
              "23:09",
              "23:10",
              "23:11",
              "23:12",
              "23:13",
              "23:14",
              "23:15",
              "23:16",
              "23:17",
              "23:18",
              "23:19",
              "23:20",
              "23:21",
              "23:22",
              "23:23",
              "23:24",
              "23:25",
              "23:26",
              "23:27",
              "23:28",
              "23:29",
              "23:30",
              "23:31",
              "23:32",
              "23:33",
              "23:34",
              "23:35",
              "23:36",
              "23:37",
              "23:38",
              "23:39",
              "23:40",
              "23:41",
              "23:42",
              "23:43",
              "23:44",
              "23:45",
              "23:46",
              "23:47",
              "23:48",
              "23:49",
              "23:50",
              "23:51",
              "23:52",
              "23:53",
              "23:54",
              "23:55",
              "23:56",
              "23:57",
              "23:58"
            ]);
            setStatusTimeData([
              "00:00",
              "23:58"
            ]);
            setUpDownSeriesData([
              {
                "name": "DOWN(00:00-23:58)",
                "data": [
                  1438
                ],
                "color": "#F07470"
              }
            ]);
            setOpenBackdrop(false);
}

export const ISPCard1BandWidthDataByDate = async (ispWanID, date, setInBandWidthData, setOutBandWidthData, setBandWithTimeData) => {

  setInBandWidthData([
    0.26537,
    0.0779831,
    0.0916937,
    0.0756063,
    0.0696822,
    0.0693681,
    0.0734734,
    0.0694337,
    0.0554798,
    0.101682,
    0.32803,
    0.338457,
    0.595636,
    1.38347,
    0.335337,
    8.62666,
    0.0677312,
    1.22183,
    0.492823,
    0.191783,
    0.224388,
    0.232226,
    0.234727,
    0.21843,
    0.222073,
    0.220666,
    0.227575,
    0.190318,
    0.059906,
    0.0638551,
    0.0696836,
    0.105432,
    0.0622421,
    0.0685595,
    0.0689944,
    0.069504,
    0.0681233,
    0.0769379,
    1.01329,
    3.00908,
    1.20537,
    1.08474,
    2.78084,
    2.81432,
    2.43444,
    2.74443,
    2.41378,
    1.32294,
    0.510941,
    2.23901,
    1.1711,
    1.17515,
    0.072014,
    0.228889,
    0.265721,
    0.251774,
    0.252405,
    0.245387,
    0.246592,
    0.248614,
    0.251737,
    0.238454,
    0.0898368,
    0.0888289,
    0.165326,
    4.46066,
    1.46153,
    1.70289,
    3.74997,
    3.81832,
    3.63456,
    2.29032,
    0.0861865,
    0.0844592,
    0.0824773,
    0.0788069,
    0.150296,
    0.205366,
    0.0755651,
    4.6301,
    6.56121,
    1.04204,
    2.53975,
    1.27265,
    1.00818,
    2.28755,
    1.59211,
    1.53295,
    5.88335,
    0.859561,
    0.966636,
    0.795396,
    0.811445,
    0.646621,
    0.675233,
    0.794733,
    0.946132,
    0.892649,
    0.72278,
    0.73717,
    0.689225,
    0.640912,
    0.907702,
    0.881279,
    0.547588,
    0.665714,
    0.753179,
    0.883555,
    0.478783,
    0.519415,
    0.456925,
    0.692406,
    0.61035,
    0.559707,
    0.690985,
    0.537512,
    0.0717186,
    0.0690158,
    0.0682833,
    0.0713333,
    0.132103,
    0.161574,
    0.194968,
    0.154446,
    0.165686,
    0.0553794,
    0.0486534,
    0.0848601,
    0.0568462,
    0.0764717,
    0.0870499,
    0.0548108,
    0.0541679,
    0.0617535,
    0.511752,
    0.706515,
    0.785838,
    0.558861,
    0.589362,
    0.834953,
    1.14417,
    1.41394,
    1.26357,
    1.08343,
    0.242718,
    0.364035,
    0.401174,
    4.00998,
    0.892218,
    0.607428,
    1.30073,
    0.591328,
    0.660322,
    0.598245,
    0.632208,
    0.798808,
    0.863666,
    0.923604,
    0.670877,
    1.02825,
    0.836548,
    0.512682,
    1.10123,
    0.547251,
    0.523085,
    0.677833,
    0.952871,
    0.742707,
    0.968312,
    1.26254,
    0.986374,
    3.9694,
    0.311569,
    0.327007,
    0.085225,
    0.0737419,
    0.0641212,
    0.0569738,
    0.0609324,
    0.0568228,
    0.0810055,
    0.0823085,
    0.120062,
    0.0765691,
    0.0594194,
    0.0724836,
    0.0698584,
    0.0665933,
    0.0603657,
    0.0655226,
    0.069674,
    0.0622758,
    0.0656822,
    0.0728906,
    0.0669711,
    0.0686577,
    0.063424,
    0.0594549,
    0.0679241,
    0.0590539,
    0.0630161,
    0.0601408,
    0.113928,
    0.180492,
    0.097228,
    0.0783402,
    0.0732699,
    0.0679234,
    0.0555342,
    0.0734277,
    0.0822576,
    0.0982119,
    0.0646805,
    0.161191,
    0.280556,
    4.89334,
    0.936034,
    1.5339,
    0.832908,
    1.15651,
    0.723154,
    1.18597,
    0.419549,
    0.299637,
    8.36729,
    1.08185,
    0.979666,
    0.849446,
    0.678848,
    2.50241,
    0.570427,
    0.427894,
    1.10733,
    1.03376,
    3.29219,
    1.32861,
    2.86863,
    0.619059,
    0.641153,
    0.801831,
    0.981503,
    5.63179,
    0.830992,
    5.99486,
    0.813837,
    0.757599,
    1.05244,
    0.821844,
    2.38751,
    1.72365,
    1.2765,
    0.590028,
    0.991328,
    0.688894,
    0.540678,
    0.555099,
    0.719425,
    0.905108,
    0.946301,
    0.778597,
    0.178323,
    0.0446776,
    0.0497404,
    2.4662,
    0.809554,
    0.766458,
    0.342639,
    0.894708,
    1.10564,
    1.11054,
    1.22506,
    0.917654,
    0.326356,
    1.90118,
    5.48578,
    1.24743,
    1.1216,
    0.890024,
    0.907128,
    0.950857,
    0.840903,
    0.993009,
    1.05321,
    1.0428,
    1.09281,
    0.717798,
    0.19523,
    0.0457422,
    0.0515446,
    0.0556566,
    4.94492,
    1.71489,
    1.59087,
    1.02968,
    1.32737,
    1.60412,
    1.32992,
    0.907841,
    0.308526,
    0.313665,
    0.364719,
    3.66664,
    0.330289,
    0.276026,
    1.90096,
    0.48644,
    0.281176,
    0.375911,
    0.284511,
    0.421634,
    0.300237,
    0.483004,
    0.332617,
    0.284597,
    0.338323,
    0.44663,
    0.489045,
    0.334011,
    0.322158,
    0.305187,
    0.314338,
    0.305123,
    0.295434,
    0.270187,
    0.274616,
    0.271407,
    0.307155,
    0.285581,
    0.288661,
    0.289144,
    0.287154,
    0.290825,
    0.291499,
    0.290156,
    0.300472,
    0.0556844,
    0.057211,
    0.0498189,
    0.146469,
    0.289201,
    0.296593,
    0.166726,
    0.286183,
    0.289798,
    0.280158,
    0.227871,
    0.282394,
    0.253589,
    0.237593,
    0.27314,
    0.239963,
    0.267543,
    0.269447,
    0.280816,
    0.271321,
    0.275253,
    0.296111,
    0.257869,
    0.263774,
    0.249998,
    0.264724,
    0.258724,
    0.260427,
    0.280018,
    0.392303,
    0.266246,
    1.36839,
    0.24617,
    0.26208,
    0.265518,
    0.261489,
    0.24897,
    0.276866,
    0.277475,
    0.257886,
    0.28797,
    0.235704,
    0.263216,
    0.256411,
    0.266161,
    0.375412,
    0.244468,
    0.250395,
    0.249187,
    0.242785,
    0.302202,
    1.04755,
    6.97909,
    1.57307,
    1.01894,
    5.84933,
    9.78191,
    1.55323,
    2.44202,
    1.77205,
    1.93841,
    11.0467,
    4.8051,
    1.02829,
    0.268785,
    0.269234,
    0.262323,
    0.246346,
    9.08119,
    1.33706,
    1.15195,
    1.48512,
    3.38839,
    3.26947,
    2.02226,
    2.92343,
    1.20117,
    1.07322,
    1.1185,
    0.97059,
    1.37539,
    1.10593,
    0.53544,
    0.231052,
    0.235571,
    0.235318,
    4.71935,
    0.836822,
    1.23341,
    1.61677,
    0.846975,
    1.46742,
    1.51191,
    1.12805,
    1.44614,
    1.60944,
    1.8214,
    1.48692,
    1.63853,
    1.4435,
    1.07555,
    1.80741,
    1.78532,
    1.3881,
    1.69817,
    2.38205,
    1.15854,
    0.265879,
    0.268671,
    0.263501,
    4.55436,
    1.24271,
    1.20391,
    1.29185,
    1.65937,
    1.12569,
    1.62333,
    1.54728,
    2.64472,
    1.22875,
    1.17902,
    1.44437,
    1.13445,
    1.65014,
    1.49989,
    1.98783,
    1.97262,
    1.38236,
    0.99725,
    0.943586,
    4.78631,
    1.03396,
    0.773953,
    0.879231,
    1.22614,
    1.1161,
    1.67901,
    2.62441,
    2.58492,
    3.60693,
    1.30579,
    3.29194,
    7.78807,
    11.504,
    0.976642,
    1.57477,
    1.71827,
    1.95373,
    2.84595,
    2.31111,
    1.48564,
    1.72247,
    0.486849,
    0.266586,
    0.281111,
    0.284069,
    0.287796,
    0.0911249,
    0.0550899,
    0.0585276,
    0.0553624,
    0.0547559,
    0.0552512,
    0.0759984,
    0.0647327,
    0.0661669,
    0.0643608,
    0.057505,
    0.0822857,
    0.093861,
    0.0647457,
    0.0620572,
    0.0640628,
    0.0744127,
    0.13137,
    0.0802874,
    0.0703539,
    0.0799947,
    0.0722548,
    0.0803616,
    0.0691925,
    0.0723582,
    0.0722162,
    0.0715914,
    0.0721492,
    0.0734208,
    0.0665497,
    0.0621206,
    0.0682886,
    0.0815505,
    0.0745501,
    0.0579232,
    0.0809475,
    0.0752652,
    0.0600848,
    0.0551978,
    0.0793549,
    0.0624879,
    0.0708803,
    0.0866684,
    0.0636461,
    0.0647979,
    0.0711883,
    0.0778832,
    0.0556289,
    0.059449,
    0.0522722,
    0.0559199,
    0.0586176,
    0.0490318,
    0.140465,
    0.095139,
    0.058095,
    0.0545985,
    0.077849,
    0.0765495,
    0.0660263,
    0.0552784,
    0.321332,
    0.0656502,
    0.660486,
    0.0742639,
    0.0616758,
    0.0687902,
    0.274262,
    0.546121,
    1.17671,
    2.46453,
    0.320086,
    0.120237,
    0.0632307,
    0.0883742,
    0.164516,
    0.0663667,
    0.0672067,
    0.0898994,
    2.1423,
    0.0637796,
    0.0554458,
    0.0583878,
    0.0637351,
    0.246045,
    0.0608315,
    0.0713664,
    0.0855995,
    0.0504136,
    0.0539461,
    0.0429254,
    0.082515,
    0.0517519,
    0.0505446,
    0.0483713,
    0.0606848,
    0.0649689,
    0.05554,
    0.0548115,
    0.0569356,
    0.054951,
    0.0494983,
    0.0923812,
    0.0593932,
    0.0608034,
    0.0522312,
    0.0571283,
    0.0512887,
    0.0456983,
    0.0550814,
    0.0491296,
    0.0483941,
    0.0476219,
    0.0633499,
    0.0416734,
    0.0499644,
    0.344443,
    0.0463266,
    0.0799871,
    0.0466862,
    0.0431217,
    0.0464838,
    0.0470806,
    0.0457518,
    0.068782,
    0.0804765,
    0.0487494,
    0.051377,
    0.0457805,
    0.0477854,
    0.0477247,
    0.0477041,
    0.0771665,
    0.04929,
    0.0648861,
    0.0482756,
    0.332066,
    0.196141,
    2.36561,
    2.40345,
    4.12814,
    9.71678,
    18.8955,
    4.3061,
    0.326648,
    0.07942,
    0.126689,
    0.0936718,
    0.0539495,
    0.0733383,
    0.0581038,
    0.166155,
    0.0595805,
    0.0462903,
    0.201106,
    0.167903,
    0.0542075,
    0.0555675,
    0.105786,
    0.281221,
    0.0683478,
    0.152551,
    0.0845437,
    0.0525014,
    0.174925,
    0.0717019,
    0.0565897,
    0.0862416,
    0.0591607,
    0.0767166,
    0.132457,
    0.0941388,
    0.810354,
    0.0595874,
    0.197648,
    0.331098,
    0.161221,
    0.885979,
    0.175451,
    0.148112,
    0.0996557,
    0.109898,
    0.122519,
    0.0604622,
    0.0517344,
    0.143476,
    0.250137,
    0.586905,
    0.198602,
    0.0739539,
    0.138814,
    0.0588809,
    0.154716,
    0.0763451,
    1.38004,
    0.311581,
    0.101069,
    0.0595754,
    0.0829536,
    0.0543866,
    0.208154,
    1.44164,
    1.09275,
    0.521428,
    0.110906,
    0.134573,
    0.0953435,
    0.0684544,
    0.127582,
    0.0925322,
    0.0733769,
    0.122081,
    0.108868,
    0.263132,
    0.0892802,
    0.184845,
    0.0979867,
    0.612032,
    1.99003,
    0.263443,
    0.0805341,
    0.0864543,
    0.276175,
    0.13605,
    0.0693962,
    0.0832503,
    0.140856,
    0.0622505,
    0.193156,
    1.95602,
    0.178968,
    0.452419,
    0.258584,
    0.636246,
    0.992344,
    0.156978,
    0.469308,
    9.00834,
    8.39848,
    2.60019,
    0.0782033,
    0.083066,
    0.0766544,
    0.113104,
    0.120912,
    0.259511,
    0.0655386,
    0.084218,
    0.609824,
    0.304889,
    0.154479,
    0.106219,
    0.169452,
    1.06415,
    0.112911,
    1.3103,
    2.97313,
    2.63111,
    5.87602,
    23.1612,
    2.87694,
    4.61599,
    10.1906,
    5.69469,
    3.46747,
    1.65354,
    1.41286,
    0.992548,
    1.68825,
    0.253275,
    0.395156,
    1.04203,
    2.41828,
    2.44832,
    0.854515,
    3.661,
    3.74618,
    9.33231,
    1.96436,
    0.425451,
    0.309726,
    0.248574,
    0.116195,
    0.0499529,
    0.0981954,
    0.0582355,
    0.15533,
    0.973772,
    1.22079,
    0.789133,
    1.56466,
    2.12691,
    6.05177,
    1.93861,
    1.13758,
    0.120466,
    0.113327,
    0.266382,
    0.851694,
    0.427665,
    2.27372,
    3.37935,
    1.80857,
    2.09222,
    2.36996,
    9.51763,
    2.72834,
    0.784309,
    0.0594832,
    0.0881148,
    0.0853202,
    0.0666154,
    0.0893428,
    0.19546,
    0.0810722,
    0.0444693,
    0.817098,
    0.48006,
    0.487007,
    0.0663498,
    0.0795108,
    0.213279,
    0.618757,
    0.0721379,
    0.534295,
    1.21628,
    1.65868,
    0.532748,
    0.921219,
    0.834517,
    3.12838,
    4.67043,
    1.08682,
    0.706413,
    0.616653,
    2.07815,
    2.3849,
    2.37585,
    3.83524,
    1.09643,
    2.1871,
    0.847459,
    2.31831,
    2.12917,
    4.40211,
    3.509,
    1.08819,
    1.95861,
    1.38383,
    1.15143,
    1.75681,
    1.69095,
    2.00136,
    3.56928,
    0.959837,
    3.41383,
    8.6761,
    0.523184,
    3.93482,
    0.335452,
    0.22023,
    0.514266,
    0.439221,
    0.102496,
    3.51209,
    1.13515,
    4.72467,
    0.798038,
    0.264242,
    1.92598,
    0.331129,
    0.12258,
    0.142206,
    0.155289,
    0.912131,
    0.334392,
    0.402118,
    0.613816,
    0.0808201,
    0.882672,
    0.231428,
    0.103618,
    0.300061,
    0.277347,
    0.154067,
    0.105806,
    0.112236,
    0.0939302,
    0.0955229,
    0.528506,
    0.107488,
    0.0946915,
    0.177353,
    0.103585,
    0.0545603,
    0.0958871,
    0.0699098,
    0.120213,
    0.552565,
    0.089143,
    0.0993156,
    0.09322,
    0.161464,
    0.902776,
    0.843826,
    0.151191,
    0.442172,
    0.0989423,
    0.871762,
    0.0652509,
    0.636501,
    0.108273,
    0.493345,
    0.111109,
    0.0586079,
    0.0835681,
    0.0897927,
    0.827644,
    0.0770636,
    0.205629,
    0.0655903,
    0.0763501,
    0.103002,
    0.572519,
    0.0800691,
    0.0686199,
    0.0742199,
    0.14494,
    0.268463,
    0.469641,
    0.662266,
    0.610526,
    0.59873,
    0.487614,
    0.349158,
    5.64534,
    0.43308,
    1.15668,
    0.511447,
    0.404254,
    1.94522,
    0.269594,
    0.255946,
    0.552631,
    0.412135,
    0.154141,
    0.110481,
    0.25266,
    0.520845,
    0.221839,
    0.0966324,
    0.498815,
    0.045763,
    0.0653929,
    0.0445922,
    0.11697,
    0.0587523,
    0.0761449,
    0.0709405,
    0.590245,
    0.072753,
    0.0400028,
    0.0575628,
    0.0555854,
    0.0861582,
    0.0487087,
    0.556025,
    0.0385757,
    0.627438,
    0.0684496,
    0.0487492,
    0.0697741,
    0.10051,
    0.223857,
    0.0832852,
    1.13544,
    0.952051,
    0.0727775,
    0.0765902,
    0.980146,
    0.35859,
    0.363327,
    0.162955,
    0.088377,
    0.158666,
    0.087558,
    0.105019,
    0.0742852,
    0.051943,
    0.078528,
    0.817115,
    0.13078,
    0.101067,
    1.16334,
    3.85431,
    2.74188,
    0.12362,
    0.771653,
    0.0550692,
    0.0602622,
    0.0544578,
    0.062006,
    0.0688477,
    0.0910254,
    0.117635,
    0.19721,
    0.0872569,
    0.0701978,
    0.102612,
    0.15193,
    0.255851,
    0.406491,
    3.96068,
    31.3192,
    3.60831,
    7.1619,
    3.86546,
    2.22328,
    2.20797,
    0.814398,
    0.146139,
    0.177701,
    0.219585,
    0.212348,
    0.143972,
    0.313754,
    0.404537,
    0.101095,
    0.759737,
    0.0617913,
    0.0703798,
    0.227711,
    0.123321,
    0.101282,
    2.76749,
    0.215088,
    1.03751,
    2.58808,
    0.592018,
    0.375462,
    0.656847,
    0.324216,
    1.06317,
    1.79973,
    1.08377,
    1.45997,
    0.239365,
    0.128643,
    0.450308,
    0.30079,
    0.287536,
    0.222959,
    0.210798,
    0.0891054,
    0.392324,
    0.0929404,
    0.173776,
    0.100426,
    0.115294,
    0.470118,
    0.106508,
    1.30942,
    1.33565,
    7.99119,
    0.172942,
    1.13614,
    0.0754131,
    0.299891,
    0.293321,
    0.438337,
    0.0818488,
    0.0958613,
    0.145016,
    0.0923406,
    0.081796,
    0.134492,
    0.176813,
    0.0676016,
    0.0865751,
    0.170543,
    0.0936963,
    0.0838678,
    0.0628685,
    0.0549723,
    0.058838,
    0.0826866,
    0.388205,
    0.312967,
    0.271427,
    0.224405,
    0.238927,
    0.239896,
    0.270963,
    0.253994,
    0.264154,
    0.397375,
    0.286688,
    0.365294,
    0.329695,
    0.329049,
    0.368029,
    0.56901,
    1.00828,
    0.376317,
    0.0941342,
    0.12411,
    0.181924,
    0.33074,
    0.0941707,
    0.179838,
    0.314091,
    0.0582871,
    0.142505,
    0.108234,
    0.0829712,
    0.0587332,
    0.133138,
    0.360939,
    0.0760192,
    0.141647,
    0.0889868,
    0.0735377,
    0.097941,
    0.134091,
    0.564284,
    0.305446,
    0.126589,
    0.0630669,
    0.218395,
    0.0936037,
    0.175715,
    0.0947615,
    0.0474773,
    0.145456,
    0.161733,
    0.0994069,
    0.0702203,
    0.0681391,
    0.0754151,
    1.46625,
    0.83684,
    0.0786903,
    0.0699426,
    0.106805,
    0.123068,
    0.165707,
    0.0744563,
    0.0559499,
    0.0516623,
    0.0575193,
    21.8126,
    8.51458,
    0.261359,
    0.756582,
    0.116399,
    1.0551,
    1.16752,
    0.450652,
    0.200217,
    0.50151,
    0.457198,
    0.297519,
    0.112694,
    0.352235,
    0.458177,
    0.117006,
    0.119868,
    0.913052,
    0.656005,
    2.09207,
    2.79916,
    2.39192,
    3.38311,
    2.59382,
    2.7763,
    2.86928,
    1.4106,
    2.25512,
    3.97762,
    0.356303,
    0.609354,
    1.24048,
    0.585515,
    0.618865,
    0.328319,
    0.693769,
    0.362663,
    0.279161,
    0.318623,
    0.322558,
    0.292602,
    0.425378,
    0.443596,
    0.660733,
    0.505193,
    0.335892,
    0.286261,
    0.4376,
    0.487016,
    0.383036,
    0.104757,
    0.327591,
    0.486852,
    0.710141,
    0.488846,
    0.410098,
    0.578855,
    0.526227,
    0.455792,
    0.293803,
    0.152462,
    0.265329,
    1.66386,
    0.584856,
    0.437202,
    1.66699,
    6.18166,
    0.833097,
    1.2155,
    0.305907,
    0.588561,
    2.33453,
    1.91892,
    1.81746,
    1.67562,
    0.912032,
    1.22674,
    0.393696,
    0.407151,
    0.474907,
    1.04017,
    1.09342,
    0.223092,
    0.722366,
    0.589424,
    0.48447,
    0.0970723,
    0.260974,
    1.03031,
    0.805161,
    0.145349,
    1.79733,
    0.485473,
    0.758275,
    0.293336,
    0.0847496,
    0.223278,
    0.0790084,
    0.433686,
    0.0743854,
    0.244169,
    0.494074,
    0.136036,
    0.18719,
    0.155737,
    0.147159,
    0.0654036,
    0.100327,
    0.0802142,
    0.11255,
    0.0938294,
    10.9281,
    0.171466,
    0.069422,
    0.244471,
    0.159514,
    0.208542,
    0.387502,
    0.442379,
    0.202404,
    0.131963,
    1.50494,
    5.58622,
    0.473127,
    2.42367,
    0.621716,
    0.650807,
    0.368555,
    0.108289,
    0.187567,
    0.182328,
    0.454136,
    0.187537,
    0.0576936,
    0.12379,
    0.349684,
    0.276625,
    0.0679488,
    0.121356,
    0.354153,
    0.38342,
    0.115939,
    0.070364,
    0.314996,
    0.373977,
    0.286481,
    0.0787267,
    0.189311,
    0.0679435,
    0.0525393,
    0.0900095,
    1.16099,
    0.254934,
    0.361054,
    0.204999,
    0.0755965,
    0.110194,
    0.0947071,
    0.111433,
    0.0818869,
    0.784931,
    0.459488,
    0.47237,
    0.0831515,
    1.56143,
    1.32685,
    0.0955095,
    0.0679115,
    0.0838482,
    0.113566,
    0.526734,
    0.0855424,
    0.235043,
    0.0989225,
    0.169475,
    3.29765,
    7.07238,
    0.0794804,
    0.0822595,
    0.071906,
    0.0826358,
    0.129488,
    0.094332,
    0.452186,
    0.134973,
    0.484185,
    0.670219,
    1.03006,
    0.384419,
    0.127995,
    0.651146,
    0.279176,
    0.0928588,
    0.0748824,
    0.107449,
    0.070809,
    0.117423,
    0.103145,
    0.539281,
    0.0873946,
    0.0969113,
    0.145894,
    0.198048,
    0.0690947,
    0.306203,
    0.105517,
    0.150814,
    0.102038,
    0.168348,
    0.473854,
    0.251993,
    0.137431,
    0.154122,
    0.173299,
    0.250565,
    0.0825054,
    0.186212,
    0.28085,
    0.182999,
    0.197718,
    0.152818,
    0.132197,
    0.111352,
    0.101001,
    0.081746,
    0.430699,
    0.144594,
    0.598375,
    0.546287,
    1.74277,
    0.160547,
    0.289781,
    0.182508,
    0.161621,
    0.13486,
    0.638818,
    0.470064,
    0.539461,
    0.1703,
    0.268355,
    0.112863,
    0.207354,
    0.365022,
    0.175185,
    0.218956,
    0.0718276,
    0.0862239,
    0.286454,
    0.0781636,
    0.0715117,
    1.19597,
    0.153278,
    0.202494,
    0.0633096,
    0.361785,
    2.25639,
    0.496587,
    0.117214,
    0.562932,
    0.0780771,
    0.0603058,
    0.0406195,
    0.0882623,
    0.227637,
    0.0460093,
    0.0489029,
    0.22817,
    0.0791044,
    0.957105,
    0.308576,
    0.118758,
    0.140542,
    0.0524429,
    0.115024,
    0.0832112,
    0.0686555,
    0.155438,
    0.0849292,
    0.0658871,
    0.0464321,
    0.0865774,
    0.0858121,
    0.055785,
    0.0801646,
    0.0488979,
    0.295884,
    0.0579291
  ]);
  setOutBandWidthData([
    11.3509,
    2.32641,
    2.62344,
    2.50241,
    2.48283,
    2.44549,
    2.50885,
    2.18482,
    0.997904,
    3.01324,
    8.53915,
    7.49261,
    4.82041,
    9.67914,
    10.0862,
    1.49076,
    1.35183,
    1.31158,
    1.42515,
    7.57147,
    9.11533,
    9.67984,
    9.71852,
    8.97274,
    9.23965,
    9.19683,
    9.56642,
    7.25241,
    1.2241,
    1.34861,
    1.33123,
    1.45747,
    1.32441,
    1.33027,
    1.37689,
    1.3963,
    1.38539,
    1.46584,
    1.43449,
    1.38929,
    1.39394,
    1.48182,
    1.50418,
    1.47864,
    1.55247,
    1.48816,
    1.5215,
    3.1088,
    10.8644,
    10.9887,
    2.23152,
    1.46612,
    1.46598,
    8.69589,
    11.0523,
    10.6319,
    10.5552,
    10.2976,
    10.0203,
    10.0959,
    9.9806,
    7.36634,
    1.52635,
    1.53202,
    1.59548,
    1.53707,
    1.82071,
    1.60424,
    1.57966,
    1.55657,
    1.47559,
    1.53169,
    1.40263,
    1.27953,
    1.34219,
    1.37159,
    1.34513,
    8.10395,
    1.31669,
    1.25128,
    1.29729,
    1.40933,
    1.45694,
    1.43727,
    1.41347,
    1.41952,
    1.46895,
    1.4855,
    1.45305,
    1.40554,
    1.33966,
    1.44191,
    1.37616,
    7.19805,
    10.7573,
    10.6977,
    10.7222,
    10.2883,
    9.14431,
    1.31308,
    1.27145,
    1.3212,
    1.03928,
    1.04369,
    1.04133,
    1.02531,
    1.04992,
    0.939503,
    1.11406,
    0.92869,
    1.06236,
    0.963026,
    0.933732,
    0.9429,
    0.948743,
    0.955093,
    0.951146,
    0.943619,
    0.993638,
    1.05117,
    4.1729,
    4.9125,
    7.43184,
    3.3512,
    4.90564,
    0.645037,
    0.427367,
    1.02564,
    0.791643,
    1.69147,
    2.6448,
    0.873601,
    1.07899,
    0.969605,
    1.08145,
    1.16793,
    1.09501,
    0.977423,
    1.24037,
    1.07961,
    1.17833,
    1.15356,
    1.1565,
    1.07861,
    1.11028,
    1.16346,
    1.06501,
    1.10946,
    1.16593,
    1.2625,
    1.16789,
    1.2236,
    1.14112,
    1.16985,
    1.16668,
    1.17835,
    1.16975,
    1.07076,
    1.26055,
    1.15529,
    1.17648,
    1.12864,
    1.329,
    1.22429,
    1.25752,
    1.24043,
    1.21713,
    1.20694,
    1.04795,
    1.30968,
    1.12684,
    1.18361,
    0.989727,
    1.16347,
    1.19672,
    1.31103,
    1.19749,
    1.11433,
    1.1394,
    1.04835,
    1.26252,
    1.11105,
    1.16552,
    1.28436,
    1.03989,
    1.32041,
    1.18904,
    1.16044,
    1.04617,
    1.16136,
    1.22766,
    1.08975,
    1.13469,
    1.25281,
    1.16158,
    1.18982,
    1.16495,
    1.05353,
    1.26759,
    1.1241,
    1.16339,
    1.08709,
    1.35691,
    1.13659,
    1.33313,
    1.18474,
    1.04195,
    1.21607,
    1.05477,
    1.28127,
    1.23493,
    1.28368,
    1.12854,
    5.92452,
    11.7753,
    10.0039,
    10.8336,
    10.4208,
    10.873,
    11.6887,
    10.6184,
    10.7116,
    10.7189,
    11.6492,
    10.9787,
    10.7849,
    10.792,
    8.38418,
    1.25101,
    0.785365,
    0.179019,
    0.171581,
    0.187031,
    0.286093,
    0.316579,
    0.362563,
    0.937253,
    0.819648,
    0.979647,
    0.915934,
    0.868582,
    0.874932,
    2.65816,
    13.1134,
    13.1398,
    13.1003,
    14.1496,
    13.1851,
    13.0321,
    12.2897,
    14.1773,
    11.9241,
    15.1052,
    11.9432,
    12.018,
    10.4133,
    12.7993,
    11.7938,
    11.9283,
    11.1587,
    7.26134,
    0.911521,
    0.897964,
    0.918745,
    0.936033,
    1.01414,
    0.86694,
    8.44075,
    12.7554,
    13.0751,
    11.6433,
    12.8613,
    12.806,
    12.6698,
    12.6549,
    12.9386,
    12.8895,
    13.9334,
    12.2036,
    11.3535,
    12.9306,
    11.9713,
    12.9326,
    11.5497,
    10.6557,
    13.155,
    8.16558,
    0.889412,
    0.864382,
    0.936231,
    11.3653,
    12.8899,
    13.6504,
    14.056,
    15.2276,
    12.901,
    13.8754,
    15.198,
    12.8433,
    13.5058,
    13.9186,
    12.4652,
    14.632,
    12.3698,
    13.5085,
    14.4942,
    12.7018,
    14.6086,
    12.8039,
    13.6889,
    13.376,
    13.9951,
    14.787,
    12.623,
    14.8508,
    11.8631,
    13.6596,
    14.8671,
    13.0533,
    13.4906,
    13.9038,
    13.5505,
    12.9358,
    11.6648,
    11.947,
    11.8758,
    13.3209,
    11.8618,
    12.7352,
    12.78,
    12.6657,
    12.7981,
    12.6382,
    12.7144,
    13.1958,
    1.34043,
    1.38783,
    1.20583,
    5.70376,
    12.683,
    12.5893,
    6.67069,
    12.5089,
    12.6989,
    11.7791,
    9.81511,
    12.2923,
    11.0351,
    10.4822,
    11.9464,
    10.4612,
    11.4609,
    11.7851,
    12.1756,
    11.6746,
    11.9062,
    12.5222,
    10.4775,
    11.3191,
    10.8504,
    11.4369,
    11.2874,
    11.4027,
    11.9989,
    10.5135,
    11.3354,
    12.0252,
    10.5489,
    11.3485,
    11.3118,
    11.2083,
    10.7316,
    11.8511,
    11.9173,
    11.0766,
    12.3351,
    10.1128,
    11.4794,
    11.0916,
    11.5284,
    10.887,
    10.4026,
    10.6425,
    10.3395,
    10.4407,
    11.3195,
    10.3501,
    11.5045,
    11.4044,
    11.3771,
    11.2081,
    11.6529,
    11.3457,
    11.2316,
    12.2241,
    11.3406,
    10.9528,
    11.5994,
    11.976,
    11.6623,
    11.418,
    11.3685,
    10.4092,
    9.90977,
    10.0581,
    10.1768,
    10.3892,
    10.2081,
    10.1897,
    10.276,
    9.74281,
    9.58406,
    10.2138,
    8.79087,
    9.64045,
    10.4114,
    9.6805,
    10.2645,
    10.2651,
    10.1626,
    10.1336,
    10.4652,
    10.2536,
    10.37,
    11.2278,
    9.78092,
    10.3071,
    10.4841,
    10.4442,
    10.3693,
    10.5476,
    10.2615,
    10.1596,
    10.5854,
    10.9985,
    9.63343,
    10.2984,
    10.5097,
    10.3734,
    11.249,
    11.8833,
    11.769,
    11.7337,
    11.8058,
    11.5126,
    11.4358,
    11.1504,
    11.4236,
    11.034,
    11.4636,
    11.0735,
    11.4421,
    11.3959,
    11.1708,
    11.7424,
    10.5212,
    11.114,
    11.3539,
    11.0725,
    11.3365,
    11.4191,
    10.3292,
    9.96944,
    9.32296,
    10.6022,
    11.2611,
    10.8951,
    10.8105,
    10.8799,
    11.9011,
    12.771,
    12.5227,
    13.3606,
    13.7408,
    11.8932,
    11.487,
    11.4471,
    11.0505,
    11.3886,
    11.0856,
    11.357,
    11.0622,
    11.1878,
    11.1939,
    12.1139,
    11.7063,
    12.1751,
    12.0291,
    11.8444,
    12.2024,
    11.5206,
    12.2027,
    2.87951,
    1.28488,
    1.12482,
    1.22254,
    1.20475,
    1.21174,
    1.20273,
    1.23989,
    1.17843,
    1.2375,
    1.19937,
    1.22383,
    1.35691,
    1.20976,
    1.2006,
    1.16695,
    1.19544,
    1.21523,
    1.15703,
    1.17307,
    1.23665,
    1.55333,
    1.71187,
    1.74675,
    1.7568,
    1.78424,
    1.72599,
    1.78489,
    1.74329,
    1.23384,
    1.24413,
    1.15757,
    1.21582,
    1.12057,
    1.13077,
    1.37785,
    1.22202,
    1.17552,
    1.15968,
    1.17373,
    1.19058,
    1.33696,
    1.25433,
    1.3675,
    1.2706,
    1.27987,
    1.14777,
    1.18098,
    1.21027,
    1.1499,
    1.15097,
    1.22532,
    1.09543,
    1.57698,
    1.19436,
    1.20851,
    1.2426,
    1.27088,
    1.21332,
    1.22785,
    1.19265,
    1.24662,
    1.25517,
    2.03233,
    1.34567,
    1.31059,
    1.2709,
    1.31219,
    1.51477,
    1.17561,
    1.27766,
    1.18823,
    1.33716,
    1.17123,
    1.12456,
    1.21608,
    1.09241,
    1.17395,
    1.1239,
    1.19845,
    1.16052,
    1.12312,
    1.06687,
    1.09076,
    1.24207,
    1.0342,
    1.03701,
    1.06583,
    1.02122,
    1.05486,
    0.896832,
    1.1109,
    1.00175,
    1.04913,
    1.05353,
    1.0541,
    0.970191,
    1.02785,
    1.10069,
    1.04399,
    1.03529,
    0.944771,
    1.12964,
    1.01533,
    1.06094,
    0.995173,
    1.02635,
    1.01328,
    0.966824,
    1.03458,
    0.984263,
    1.01773,
    0.892672,
    0.756335,
    0.663025,
    0.75009,
    0.757303,
    0.761916,
    0.860494,
    0.767514,
    0.790846,
    0.683569,
    0.429573,
    0.625861,
    0.366311,
    0.235192,
    0.591408,
    0.431501,
    0.326467,
    0.411115,
    0.403531,
    0.381621,
    0.511219,
    0.381605,
    0.419559,
    0.394021,
    0.439749,
    0.426992,
    0.450791,
    0.4671,
    0.441071,
    1.30571,
    0.522682,
    0.449051,
    0.413367,
    0.36185,
    0.533356,
    0.413996,
    0.403405,
    0.428499,
    0.514286,
    0.868637,
    0.786222,
    0.784231,
    0.802775,
    0.843979,
    0.762299,
    0.821639,
    0.817227,
    0.817549,
    0.834954,
    0.744192,
    0.94708,
    0.791483,
    0.878286,
    0.80747,
    0.852529,
    0.814755,
    0.798257,
    0.806507,
    0.845052,
    0.867877,
    0.867775,
    0.802622,
    0.926141,
    0.913552,
    1.01543,
    0.8928,
    0.965266,
    0.97753,
    0.962064,
    0.888362,
    0.972742,
    0.932035,
    0.865673,
    0.866504,
    1.04682,
    0.903461,
    0.866401,
    0.923758,
    0.914739,
    0.846624,
    1.04097,
    0.906725,
    1.14314,
    1.07212,
    0.899783,
    0.906932,
    0.866746,
    0.77489,
    0.91059,
    0.918543,
    0.983127,
    0.85805,
    0.937978,
    0.896241,
    0.867241,
    1.00778,
    1.18064,
    0.964433,
    0.912197,
    0.91694,
    1.3724,
    1.60653,
    1.45913,
    1.53422,
    1.49203,
    1.50157,
    1.58481,
    1.58262,
    1.47106,
    1.1856,
    1.10727,
    0.818235,
    0.733131,
    0.755103,
    0.76111,
    0.725796,
    0.76522,
    1.10711,
    0.764234,
    0.77373,
    1.45752,
    1.46971,
    1.07861,
    0.890475,
    1.21604,
    1.32811,
    1.20926,
    1.03456,
    0.896206,
    0.767736,
    0.785209,
    0.771633,
    1.10482,
    0.918821,
    0.7789,
    0.735986,
    1.11281,
    1.14913,
    1.29777,
    1.39603,
    1.57862,
    1.79732,
    1.65173,
    1.88328,
    1.99136,
    1.05143,
    1.00227,
    1.52786,
    0.952199,
    5.49393,
    0.940342,
    0.574144,
    0.433359,
    0.706487,
    0.675558,
    0.695297,
    0.486553,
    0.421363,
    0.544757,
    1.03078,
    3.41173,
    0.755206,
    6.46796,
    12.7067,
    12.9637,
    13.4075,
    11.1914,
    0.79054,
    0.849187,
    0.752425,
    0.739704,
    0.819581,
    0.879465,
    0.934352,
    0.932024,
    1.2173,
    1.12617,
    1.20227,
    0.391811,
    0.385716,
    0.932531,
    0.605598,
    0.448719,
    0.43685,
    0.348717,
    0.42978,
    0.475443,
    0.596372,
    0.864922,
    0.811542,
    0.761345,
    0.826939,
    0.850581,
    0.893691,
    0.835177,
    0.749758,
    0.761547,
    0.786217,
    0.860806,
    0.772454,
    0.768755,
    0.735288,
    0.666428,
    0.631173,
    0.683992,
    0.733048,
    0.589382,
    0.674084,
    0.693656,
    0.744994,
    0.739296,
    0.674156,
    0.989507,
    0.717152,
    0.773209,
    0.743363,
    0.722017,
    0.73645,
    0.786546,
    0.805704,
    0.799571,
    0.68806,
    0.831233,
    0.824963,
    0.858384,
    1.10875,
    1.10582,
    1.08322,
    1.07065,
    0.858188,
    0.8645,
    1.37585,
    0.856583,
    0.860132,
    0.868956,
    1.03153,
    1.1071,
    0.929016,
    1.03783,
    0.869341,
    0.833737,
    0.984983,
    0.87237,
    1.34457,
    1.67469,
    1.05444,
    1.12809,
    0.862551,
    0.539033,
    0.35703,
    0.448963,
    0.877178,
    0.798191,
    0.759997,
    0.822358,
    0.727859,
    0.930542,
    0.701882,
    0.803689,
    0.637968,
    0.651142,
    0.677448,
    0.722532,
    0.704606,
    0.684476,
    0.687913,
    0.668924,
    0.649124,
    0.678819,
    0.594435,
    0.71889,
    0.704535,
    0.67573,
    0.621964,
    0.61365,
    0.603679,
    0.875177,
    0.758183,
    0.656842,
    0.59688,
    0.65015,
    0.718577,
    1.27921,
    1.40346,
    1.39241,
    1.52671,
    1.42509,
    1.42984,
    1.56675,
    1.47339,
    1.15597,
    1.09924,
    1.01462,
    0.796809,
    1.16573,
    0.959915,
    0.990891,
    0.822564,
    0.893469,
    0.982841,
    1.00711,
    1.00244,
    0.511496,
    0.275212,
    0.308319,
    0.306962,
    0.276462,
    0.269446,
    0.290692,
    0.346354,
    0.299997,
    0.3503,
    0.748996,
    1.1569,
    1.10569,
    1.10937,
    1.18679,
    1.15413,
    1.39737,
    5.34046,
    6.33296,
    9.34016,
    8.31781,
    6.48976,
    6.34472,
    6.22408,
    6.34333,
    3.72549,
    1.16761,
    1.17531,
    1.6252,
    1.27385,
    1.12326,
    0.830868,
    1.10209,
    1.03409,
    1.06781,
    1.06085,
    1.08013,
    0.972113,
    0.677943,
    0.592714,
    0.525361,
    0.609168,
    0.580318,
    0.55862,
    0.560924,
    0.60343,
    0.752234,
    0.586957,
    0.637106,
    0.634751,
    0.672662,
    0.651365,
    0.718636,
    0.600182,
    0.75645,
    0.692502,
    0.676302,
    0.559562,
    0.622841,
    0.543137,
    0.508526,
    0.759219,
    0.689448,
    0.580764,
    0.629587,
    0.691228,
    0.631818,
    0.624388,
    0.606145,
    0.532754,
    0.517684,
    0.55852,
    0.591183,
    0.522966,
    0.511829,
    0.56537,
    0.772202,
    0.476197,
    0.606433,
    0.58284,
    0.508791,
    0.248404,
    0.251106,
    0.235769,
    0.239759,
    0.227836,
    0.235657,
    0.2599,
    0.23549,
    0.261958,
    0.349106,
    0.99571,
    1.03479,
    1.06424,
    1.10164,
    1.05827,
    1.16998,
    1.08839,
    1.19558,
    1.88179,
    1.29206,
    1.28817,
    1.19834,
    1.22703,
    1.11931,
    1.0781,
    1.24258,
    1.5716,
    1.13407,
    1.08878,
    1.04307,
    1.00255,
    1.08386,
    1.03642,
    0.899722,
    0.972031,
    1.05655,
    1.08999,
    1.03638,
    1.03695,
    1.06596,
    7.33223,
    6.61903,
    7.90022,
    7.93925,
    7.48134,
    8.3195,
    7.82636,
    7.6777,
    8.10101,
    7.80568,
    1.11227,
    1.14583,
    1.16224,
    1.28796,
    1.11246,
    1.25923,
    1.11628,
    1.0924,
    1.05332,
    1.05569,
    1.07901,
    1.0442,
    1.14731,
    1.16231,
    1.12553,
    1.20366,
    1.23376,
    1.19428,
    1.19438,
    1.12523,
    1.11486,
    1.03396,
    1.04691,
    1.7482,
    1.18431,
    1.11308,
    1.07962,
    1.13083,
    1.10324,
    1.06561,
    0.97379,
    0.927244,
    0.908752,
    0.928654,
    2.98719,
    1.07183,
    1.02608,
    1.00617,
    0.912737,
    0.962437,
    1.23021,
    6.36739,
    10.9785,
    9.32605,
    9.21342,
    9.7385,
    9.57203,
    9.65324,
    9.76929,
    9.6086,
    9.25542,
    9.31471,
    9.44269,
    9.1316,
    9.62374,
    9.47248,
    9.53302,
    9.6963,
    9.56106,
    1.96956,
    0.44645,
    0.543576,
    0.64659,
    0.369768,
    0.623492,
    0.373826,
    0.360365,
    0.385065,
    0.373305,
    0.345137,
    0.299406,
    0.4108,
    0.386414,
    0.378112,
    0.462487,
    0.377672,
    0.30243,
    0.425158,
    0.400248,
    0.463002,
    0.474572,
    0.334417,
    0.334891,
    0.276202,
    0.311396,
    0.325172,
    0.373656,
    0.485936,
    0.572746,
    0.360239,
    0.354984,
    0.34696,
    0.331569,
    0.363228,
    0.588396,
    0.418265,
    0.348771,
    0.302065,
    0.281568,
    0.266731,
    2.38711,
    0.279608,
    0.453582,
    0.464404,
    0.409473,
    0.720624,
    0.531486,
    0.597129,
    0.566544,
    0.402974,
    0.571195,
    0.538572,
    0.415339,
    0.474236,
    0.436688,
    0.417819,
    0.308251,
    0.325915,
    0.302982,
    0.359688,
    0.667939,
    0.945277,
    0.995698,
    1.1413,
    1.29719,
    1.01634,
    8.15649,
    7.26052,
    1.10557,
    1.06947,
    1.1796,
    0.974332,
    1.09482,
    1.25984,
    1.06211,
    0.95634,
    1.15542,
    1.05201,
    1.259,
    0.921351,
    1.09233,
    1.09232,
    0.900562,
    1.05438,
    0.891631,
    0.96925,
    1.15024,
    1.03827,
    0.867223,
    1.02861,
    0.847168,
    0.988311,
    1.19287,
    1.08088,
    1.18947,
    1.07071,
    0.977864,
    1.05804,
    1.03425,
    0.975338,
    2.60393,
    2.24438,
    2.83997,
    1.07999,
    0.989918,
    1.09683,
    0.95357,
    1.02006,
    0.933981,
    1.1032,
    1.1206,
    0.988841,
    1.09496,
    1.15168,
    0.986687,
    1.06569,
    1.21554,
    1.13605,
    1.22302,
    0.994136,
    1.07113,
    0.749999,
    0.961892,
    1.10275,
    1.13012,
    1.78694,
    2.07672,
    1.94511,
    1.44288,
    0.890983,
    3.36119,
    0.929366,
    1.07899,
    1.15598,
    1.04232,
    1.09659,
    1.17947,
    1.06061,
    1.08011,
    1.07616,
    1.09993,
    1.0733,
    1.07038,
    1.08288,
    1.07922,
    1.11896,
    1.04033,
    0.956267,
    0.992123,
    1.13459,
    0.958621,
    0.583186,
    0.603292,
    0.777635,
    0.697223,
    0.815236,
    0.971292,
    0.611012,
    0.638631,
    2.7024,
    0.542933,
    0.614708,
    0.617292,
    0.661951,
    0.544901,
    0.695958,
    6.43705,
    2.98235,
    0.72027,
    0.710328,
    0.686785,
    0.748341,
    0.741516,
    0.840062,
    1.37613,
    0.677373,
    0.637736,
    0.730604,
    0.886443,
    1.14677,
    1.30483,
    1.20826,
    1.20988,
    1.3695,
    1.1671,
    1.26203,
    1.24507,
    1.17728,
    1.23065,
    1.22303,
    1.19097,
    1.21296,
    1.14953,
    1.19209,
    1.04196,
    1.135,
    1.27004,
    1.29273,
    1.46127,
    1.26286,
    1.19199,
    1.25717,
    1.23511,
    1.31506,
    1.11448,
    2.71117,
    1.37418,
    1.23673,
    1.18788,
    1.18159,
    1.10762,
    1.02205,
    1.5912,
    1.0666,
    0.965764,
    1.17612,
    1.09231,
    1.22665,
    1.16877,
    1.26528,
    1.77116,
    1.15145,
    1.10775,
    1.31654,
    1.11762,
    1.21112,
    1.18161,
    1.21669,
    1.22353,
    1.23341,
    1.15445,
    1.22351,
    1.28963,
    1.27513,
    1.10625,
    1.1111,
    1.04925,
    1.1844,
    1.17562,
    1.24588,
    1.1995,
    1.10436,
    1.1895,
    1.27767,
    1.08153,
    1.09615,
    1.11901,
    1.12089,
    1.10087,
    1.15839,
    1.14958,
    1.0844,
    1.18637,
    1.28776,
    1.06062,
    1.15647,
    1.11698,
    1.17158,
    1.08525,
    1.10897,
    1.11022,
    1.184,
    1.1608,
    1.22569,
    1.20003,
    1.15866,
    1.04408,
    1.04749,
    0.92101,
    0.825708,
    15.8682,
    0.861808,
    11.8864,
    19.5933,
    3.53705,
    0.845297,
    0.915488,
    1.08398,
    0.905821,
    3.71256,
    19.9348,
    19.175,
    26.9233,
    0.789369,
    0.803901,
    0.745614,
    0.65789,
    0.540874,
    0.562616,
    0.639957,
    0.636173,
    0.657079,
    0.664932,
    0.670597,
    0.59044,
    0.651138,
    0.594138,
    0.59607,
    0.556847,
    0.576284,
    0.624172,
    0.56282,
    0.616234,
    0.661228,
    0.62059,
    0.501673,
    0.529064,
    0.704723,
    0.610738,
    0.492794,
    0.551663,
    0.529339,
    0.53626,
    0.744087,
    0.775692,
    0.602416,
    0.526412,
    0.497978,
    0.508192,
    0.533638,
    0.48874,
    0.51858,
    0.522329,
    0.50532,
    0.501644,
    0.527761,
    0.959972,
    0.381018,
    0.360133,
    0.500702,
    0.502016,
    0.594241
  ]);
  setBandWithTimeData([
    "23:59:23",
    "00:00:25",
    "00:01:23",
    "00:02:23",
    "00:03:23",
    "00:04:24",
    "00:05:24",
    "00:06:23",
    "00:07:23",
    "00:08:22",
    "00:09:23",
    "00:10:25",
    "00:11:23",
    "00:12:23",
    "00:13:23",
    "00:15:23",
    "00:16:23",
    "00:17:23",
    "00:18:24",
    "00:19:23",
    "00:20:24",
    "00:21:23",
    "00:22:23",
    "00:23:23",
    "00:24:22",
    "00:25:23",
    "00:26:23",
    "00:27:22",
    "00:28:23",
    "00:29:23",
    "00:30:25",
    "00:31:23",
    "00:32:23",
    "00:33:23",
    "00:34:23",
    "00:35:23",
    "00:36:22",
    "00:37:22",
    "00:38:23",
    "00:39:23",
    "00:40:24",
    "00:41:23",
    "00:42:23",
    "00:43:23",
    "00:44:22",
    "00:45:24",
    "00:46:23",
    "00:47:24",
    "00:48:24",
    "00:49:23",
    "00:50:25",
    "00:51:24",
    "00:52:23",
    "00:53:23",
    "00:54:23",
    "00:55:24",
    "00:56:23",
    "00:57:23",
    "00:58:23",
    "00:59:23",
    "01:00:24",
    "01:01:23",
    "01:02:23",
    "01:03:24",
    "01:04:23",
    "01:05:23",
    "01:06:23",
    "01:07:23",
    "01:08:23",
    "01:09:23",
    "01:10:24",
    "01:11:23",
    "01:12:23",
    "01:13:25",
    "01:14:23",
    "01:15:23",
    "01:16:23",
    "01:17:23",
    "01:18:23",
    "01:19:23",
    "01:20:25",
    "01:21:22",
    "01:22:23",
    "01:23:23",
    "01:24:23",
    "01:25:23",
    "01:26:23",
    "01:27:23",
    "01:28:23",
    "01:29:23",
    "01:30:25",
    "01:31:23",
    "01:32:23",
    "01:33:23",
    "01:34:23",
    "01:35:23",
    "01:36:23",
    "01:37:24",
    "01:38:24",
    "01:39:23",
    "01:40:25",
    "01:41:23",
    "01:42:23",
    "01:43:23",
    "01:44:23",
    "01:45:23",
    "01:46:23",
    "01:47:22",
    "01:48:23",
    "01:49:22",
    "01:50:24",
    "01:51:23",
    "01:52:23",
    "01:53:23",
    "01:54:23",
    "01:55:23",
    "01:56:23",
    "01:57:24",
    "01:58:23",
    "01:59:23",
    "02:00:24",
    "02:01:23",
    "02:02:23",
    "02:03:23",
    "02:04:23",
    "02:05:23",
    "02:06:22",
    "02:07:23",
    "02:08:23",
    "02:09:23",
    "02:10:25",
    "02:11:23",
    "02:12:23",
    "02:13:23",
    "02:14:22",
    "02:15:23",
    "02:16:23",
    "02:17:23",
    "02:18:23",
    "02:19:22",
    "02:20:25",
    "02:21:24",
    "02:22:23",
    "02:23:23",
    "02:24:23",
    "02:25:23",
    "02:26:23",
    "02:27:22",
    "02:28:22",
    "02:29:23",
    "02:30:24",
    "02:31:23",
    "02:32:23",
    "02:33:23",
    "02:34:23",
    "02:35:23",
    "02:36:23",
    "02:37:23",
    "02:38:23",
    "02:39:24",
    "02:40:24",
    "02:41:23",
    "02:42:23",
    "02:43:23",
    "02:44:23",
    "02:45:23",
    "02:46:23",
    "02:47:23",
    "02:48:23",
    "02:49:23",
    "02:50:25",
    "02:51:23",
    "02:52:23",
    "02:53:23",
    "02:54:23",
    "02:55:24",
    "02:56:23",
    "02:57:23",
    "02:58:23",
    "02:59:23",
    "03:00:25",
    "03:01:23",
    "03:02:23",
    "03:03:23",
    "03:04:23",
    "03:05:23",
    "03:06:23",
    "03:07:23",
    "03:08:23",
    "03:09:23",
    "03:10:25",
    "03:11:22",
    "03:12:23",
    "03:13:23",
    "03:14:24",
    "03:15:23",
    "03:16:23",
    "03:17:23",
    "03:18:23",
    "03:19:23",
    "03:20:25",
    "03:21:23",
    "03:22:23",
    "03:23:23",
    "03:24:23",
    "03:25:24",
    "03:26:23",
    "03:27:23",
    "03:28:23",
    "03:29:24",
    "03:30:25",
    "03:31:26",
    "03:32:23",
    "03:33:23",
    "03:34:24",
    "03:35:23",
    "03:36:23",
    "03:38:23",
    "03:39:23",
    "03:40:24",
    "03:42:23",
    "03:43:23",
    "03:44:23",
    "03:45:24",
    "03:46:23",
    "03:47:23",
    "03:48:24",
    "03:49:22",
    "03:50:24",
    "03:51:23",
    "03:52:23",
    "03:53:23",
    "03:54:23",
    "03:55:24",
    "03:56:23",
    "03:57:23",
    "03:58:23",
    "03:59:23",
    "04:00:25",
    "04:01:22",
    "04:02:23",
    "04:03:23",
    "04:04:23",
    "04:05:23",
    "04:06:23",
    "04:07:23",
    "04:08:24",
    "04:09:24",
    "04:10:25",
    "04:11:23",
    "04:12:24",
    "04:13:23",
    "04:14:23",
    "04:15:24",
    "04:16:24",
    "04:17:26",
    "04:18:27",
    "04:19:28",
    "04:20:28",
    "04:21:26",
    "04:22:26",
    "04:23:22",
    "04:24:23",
    "04:25:23",
    "04:26:23",
    "04:27:24",
    "04:28:23",
    "04:29:23",
    "04:30:25",
    "04:31:24",
    "04:32:23",
    "04:33:23",
    "04:34:23",
    "04:35:23",
    "04:36:23",
    "04:37:23",
    "04:38:23",
    "04:39:24",
    "04:40:26",
    "04:41:23",
    "04:42:23",
    "04:44:23",
    "04:45:23",
    "04:46:23",
    "04:47:24",
    "04:48:24",
    "04:49:24",
    "04:50:25",
    "04:51:23",
    "04:52:23",
    "04:53:23",
    "04:54:23",
    "04:55:23",
    "04:56:23",
    "04:57:24",
    "04:58:23",
    "04:59:24",
    "05:00:25",
    "05:01:23",
    "05:02:24",
    "05:03:23",
    "05:04:23",
    "05:05:24",
    "05:06:23",
    "05:07:23",
    "05:08:25",
    "05:09:23",
    "05:10:25",
    "05:11:23",
    "05:12:23",
    "05:13:24",
    "05:14:23",
    "05:15:24",
    "05:16:23",
    "05:17:24",
    "05:18:24",
    "05:19:24",
    "05:20:25",
    "05:21:23",
    "05:22:24",
    "05:23:23",
    "05:24:24",
    "05:25:23",
    "05:26:25",
    "05:28:23",
    "05:29:24",
    "05:30:25",
    "05:31:23",
    "05:32:23",
    "05:33:23",
    "05:34:23",
    "05:35:23",
    "05:36:23",
    "05:37:23",
    "05:38:26",
    "05:39:26",
    "05:40:25",
    "05:41:23",
    "05:42:23",
    "05:43:23",
    "05:44:23",
    "05:45:23",
    "05:46:23",
    "05:47:22",
    "05:48:23",
    "05:49:23",
    "05:50:24",
    "05:51:25",
    "05:52:23",
    "05:53:25",
    "05:54:23",
    "05:55:24",
    "05:56:24",
    "05:57:22",
    "05:58:23",
    "05:59:23",
    "06:00:25",
    "06:01:23",
    "06:02:23",
    "06:03:25",
    "06:04:24",
    "06:05:24",
    "06:06:23",
    "06:07:25",
    "06:08:23",
    "06:09:23",
    "06:10:25",
    "06:11:23",
    "06:12:23",
    "06:13:23",
    "06:14:23",
    "06:15:26",
    "06:16:23",
    "06:18:23",
    "06:19:24",
    "06:20:24",
    "06:21:24",
    "06:22:23",
    "06:23:24",
    "06:24:23",
    "06:25:23",
    "06:26:24",
    "06:27:23",
    "06:28:23",
    "06:29:23",
    "06:30:25",
    "06:31:24",
    "06:32:23",
    "06:33:23",
    "06:34:23",
    "06:35:24",
    "06:36:23",
    "06:37:23",
    "06:38:24",
    "06:39:25",
    "06:40:25",
    "06:41:23",
    "06:42:24",
    "06:43:23",
    "06:44:23",
    "06:45:24",
    "06:46:23",
    "06:47:22",
    "06:48:23",
    "06:49:24",
    "06:50:24",
    "06:51:23",
    "06:52:23",
    "06:53:23",
    "06:54:23",
    "06:55:24",
    "06:56:24",
    "06:57:25",
    "06:58:24",
    "06:59:23",
    "07:00:25",
    "07:01:23",
    "07:02:23",
    "07:03:23",
    "07:04:24",
    "07:05:24",
    "07:06:23",
    "07:07:23",
    "07:09:23",
    "07:10:25",
    "07:11:23",
    "07:12:24",
    "07:13:24",
    "07:14:24",
    "07:15:24",
    "07:16:23",
    "07:17:24",
    "07:18:24",
    "07:19:23",
    "07:20:26",
    "07:21:24",
    "07:22:24",
    "07:23:23",
    "07:24:23",
    "07:25:23",
    "07:26:23",
    "07:27:23",
    "07:28:23",
    "07:29:23",
    "07:30:24",
    "07:31:23",
    "07:32:24",
    "07:33:23",
    "07:34:24",
    "07:35:23",
    "07:36:24",
    "07:37:23",
    "07:38:22",
    "07:39:22",
    "07:40:25",
    "07:41:23",
    "07:42:24",
    "07:43:23",
    "07:44:24",
    "07:45:23",
    "07:46:22",
    "07:47:24",
    "07:48:23",
    "07:49:25",
    "07:50:25",
    "07:51:23",
    "07:52:23",
    "07:53:23",
    "07:54:23",
    "07:55:23",
    "07:56:24",
    "07:58:25",
    "07:59:23",
    "08:00:25",
    "08:01:24",
    "08:02:23",
    "08:03:23",
    "08:04:24",
    "08:05:24",
    "08:06:25",
    "08:07:24",
    "08:08:23",
    "08:09:23",
    "08:10:24",
    "08:11:23",
    "08:12:24",
    "08:13:23",
    "08:14:23",
    "08:15:24",
    "08:16:23",
    "08:17:25",
    "08:18:23",
    "08:19:23",
    "08:20:25",
    "08:21:23",
    "08:22:23",
    "08:23:23",
    "08:24:23",
    "08:25:23",
    "08:26:22",
    "08:27:24",
    "08:28:23",
    "08:29:23",
    "08:30:24",
    "08:31:22",
    "08:32:23",
    "08:33:24",
    "08:34:24",
    "08:35:23",
    "08:36:24",
    "08:37:23",
    "08:38:24",
    "08:39:24",
    "08:40:24",
    "08:41:24",
    "08:42:24",
    "08:43:24",
    "08:44:23",
    "08:45:24",
    "08:46:23",
    "08:47:23",
    "08:48:25",
    "08:49:23",
    "08:50:25",
    "08:51:23",
    "08:52:23",
    "08:53:24",
    "08:54:23",
    "08:55:24",
    "08:56:23",
    "08:57:23",
    "08:58:23",
    "08:59:23",
    "09:00:26",
    "09:01:25",
    "09:02:23",
    "09:03:25",
    "09:04:23",
    "09:05:24",
    "09:06:23",
    "09:07:23",
    "09:08:25",
    "09:09:23",
    "09:10:26",
    "09:11:23",
    "09:12:23",
    "09:13:24",
    "09:14:24",
    "09:15:24",
    "09:16:23",
    "09:17:24",
    "09:18:23",
    "09:19:23",
    "09:20:24",
    "09:21:23",
    "09:22:23",
    "09:23:24",
    "09:24:24",
    "09:25:23",
    "09:26:24",
    "09:27:24",
    "09:28:24",
    "09:29:23",
    "09:30:27",
    "09:31:26",
    "09:32:25",
    "09:33:26",
    "09:34:24",
    "09:35:25",
    "09:36:23",
    "09:37:25",
    "09:38:23",
    "09:39:23",
    "09:40:25",
    "09:41:27",
    "09:42:27",
    "09:43:23",
    "09:44:24",
    "09:45:24",
    "09:46:23",
    "09:47:23",
    "09:48:23",
    "09:49:24",
    "09:50:26",
    "09:51:23",
    "09:52:23",
    "09:53:23",
    "09:54:23",
    "09:55:27",
    "09:56:28",
    "09:57:26",
    "09:58:26",
    "09:59:26",
    "10:00:28",
    "10:01:26",
    "10:02:27",
    "10:03:25",
    "10:04:26",
    "10:05:26",
    "10:06:26",
    "10:07:27",
    "10:08:26",
    "10:09:26",
    "10:10:29",
    "10:11:26",
    "10:12:26",
    "10:13:26",
    "10:14:26",
    "10:15:27",
    "10:16:27",
    "10:17:26",
    "10:18:24",
    "10:19:23",
    "10:20:25",
    "10:21:24",
    "10:22:23",
    "10:23:24",
    "10:24:24",
    "10:25:24",
    "10:26:23",
    "10:27:22",
    "10:28:23",
    "10:29:23",
    "10:30:26",
    "10:31:24",
    "10:32:24",
    "10:33:23",
    "10:34:24",
    "10:35:23",
    "10:36:24",
    "10:37:24",
    "10:38:23",
    "10:39:24",
    "10:40:26",
    "10:41:23",
    "10:42:23",
    "10:43:26",
    "10:44:23",
    "10:45:26",
    "10:46:25",
    "10:47:27",
    "10:48:26",
    "10:49:25",
    "10:50:27",
    "10:51:23",
    "10:52:24",
    "10:53:23",
    "10:54:23",
    "10:55:25",
    "10:56:24",
    "10:57:24",
    "10:58:24",
    "10:59:24",
    "11:00:25",
    "11:01:22",
    "11:02:24",
    "11:03:24",
    "11:04:27",
    "11:05:23",
    "11:06:24",
    "11:07:24",
    "11:08:24",
    "11:09:26",
    "11:10:25",
    "11:11:23",
    "11:12:23",
    "11:13:23",
    "11:14:24",
    "11:15:24",
    "11:16:23",
    "11:17:23",
    "11:18:23",
    "11:19:23",
    "11:20:26",
    "11:21:22",
    "11:22:24",
    "11:23:24",
    "11:24:24",
    "11:25:24",
    "11:26:23",
    "11:27:24",
    "11:28:23",
    "11:29:24",
    "11:30:25",
    "11:31:24",
    "11:32:24",
    "11:33:24",
    "11:34:24",
    "11:35:24",
    "11:36:23",
    "11:37:23",
    "11:38:23",
    "11:39:24",
    "11:40:25",
    "11:41:22",
    "11:42:23",
    "11:43:24",
    "11:44:24",
    "11:45:25",
    "11:46:24",
    "11:47:23",
    "11:48:24",
    "11:49:23",
    "11:50:25",
    "11:51:24",
    "11:52:23",
    "11:53:24",
    "11:54:23",
    "11:55:24",
    "11:56:24",
    "11:57:24",
    "11:58:24",
    "11:59:24",
    "12:00:26",
    "12:01:22",
    "12:02:22",
    "12:03:24",
    "12:04:24",
    "12:05:24",
    "12:06:24",
    "12:07:24",
    "12:08:23",
    "12:09:24",
    "12:10:26",
    "12:11:24",
    "12:12:24",
    "12:13:22",
    "12:14:24",
    "12:15:23",
    "12:16:24",
    "12:17:23",
    "12:18:24",
    "12:19:24",
    "12:20:29",
    "12:21:25",
    "12:22:22",
    "12:23:23",
    "12:24:23",
    "12:25:24",
    "12:26:23",
    "12:27:22",
    "12:28:23",
    "12:29:23",
    "12:30:26",
    "12:31:22",
    "12:32:22",
    "12:33:23",
    "12:34:24",
    "12:35:24",
    "12:36:23",
    "12:37:24",
    "12:38:23",
    "12:39:24",
    "12:40:24",
    "12:41:26",
    "12:42:24",
    "12:43:23",
    "12:44:23",
    "12:45:24",
    "12:46:23",
    "12:47:24",
    "12:48:23",
    "12:49:23",
    "12:50:18",
    "12:51:24",
    "12:52:24",
    "12:53:24",
    "12:55:24",
    "12:56:24",
    "12:58:23",
    "12:59:24",
    "13:00:25",
    "13:01:23",
    "13:02:22",
    "13:03:24",
    "13:04:23",
    "13:05:23",
    "13:06:23",
    "13:07:24",
    "13:08:23",
    "13:09:23",
    "13:10:25",
    "13:11:23",
    "13:12:24",
    "13:13:23",
    "13:14:23",
    "13:15:25",
    "13:16:23",
    "13:17:23",
    "13:18:24",
    "13:19:24",
    "13:20:25",
    "13:21:24",
    "13:22:24",
    "13:23:24",
    "13:24:24",
    "13:25:24",
    "13:26:25",
    "13:27:23",
    "13:28:24",
    "13:29:24",
    "13:30:25",
    "13:31:24",
    "13:32:23",
    "13:33:24",
    "13:34:23",
    "13:35:25",
    "13:36:24",
    "13:37:24",
    "13:38:23",
    "13:39:24",
    "13:40:25",
    "13:41:24",
    "13:42:23",
    "13:43:23",
    "13:44:24",
    "13:45:25",
    "13:46:25",
    "13:47:23",
    "13:48:23",
    "13:49:24",
    "13:50:24",
    "13:51:23",
    "13:52:24",
    "13:53:23",
    "13:54:23",
    "13:55:24",
    "13:56:26",
    "13:57:23",
    "13:58:24",
    "13:59:23",
    "14:00:26",
    "14:01:23",
    "14:02:22",
    "14:03:23",
    "14:04:24",
    "14:05:24",
    "14:06:23",
    "14:07:22",
    "14:08:24",
    "14:09:23",
    "14:10:26",
    "14:11:24",
    "14:12:23",
    "14:13:23",
    "14:14:24",
    "14:15:24",
    "14:16:24",
    "14:17:24",
    "14:18:23",
    "14:19:23",
    "14:20:24",
    "14:21:23",
    "14:22:23",
    "14:23:24",
    "14:24:23",
    "14:25:25",
    "14:26:24",
    "14:27:23",
    "14:28:24",
    "14:29:23",
    "14:30:25",
    "14:31:24",
    "14:32:23",
    "14:33:24",
    "14:34:23",
    "14:35:24",
    "14:36:23",
    "14:37:24",
    "14:38:24",
    "14:39:25",
    "14:40:27",
    "14:41:24",
    "14:42:22",
    "14:43:24",
    "14:44:23",
    "14:45:24",
    "14:46:23",
    "14:47:24",
    "14:48:24",
    "14:49:24",
    "14:50:25",
    "14:51:23",
    "14:52:24",
    "14:53:23",
    "14:54:24",
    "14:55:24",
    "14:56:24",
    "14:57:24",
    "14:58:23",
    "14:59:23",
    "15:00:25",
    "15:01:24",
    "15:02:23",
    "15:03:24",
    "15:04:23",
    "15:05:24",
    "15:06:25",
    "15:07:23",
    "15:08:23",
    "15:09:23",
    "15:10:25",
    "15:11:24",
    "15:12:22",
    "15:13:23",
    "15:14:24",
    "15:15:26",
    "15:16:28",
    "15:17:24",
    "15:18:23",
    "15:19:24",
    "15:20:27",
    "15:21:23",
    "15:22:23",
    "15:23:24",
    "15:24:24",
    "15:25:24",
    "15:26:23",
    "15:27:23",
    "15:28:23",
    "15:29:24",
    "15:30:26",
    "15:31:23",
    "15:32:24",
    "15:33:23",
    "15:34:23",
    "15:35:24",
    "15:36:23",
    "15:37:23",
    "15:38:24",
    "15:39:24",
    "15:40:25",
    "15:41:24",
    "15:42:23",
    "15:43:24",
    "15:44:23",
    "15:45:24",
    "15:46:24",
    "15:47:22",
    "15:48:24",
    "15:49:23",
    "15:50:26",
    "15:51:23",
    "15:52:23",
    "15:53:23",
    "15:54:23",
    "15:55:24",
    "15:56:24",
    "15:57:24",
    "15:58:23",
    "15:59:23",
    "16:00:26",
    "16:01:23",
    "16:02:23",
    "16:03:23",
    "16:04:24",
    "16:05:24",
    "16:06:24",
    "16:07:24",
    "16:08:24",
    "16:09:23",
    "16:10:25",
    "16:11:23",
    "16:12:23",
    "16:13:23",
    "16:14:23",
    "16:15:24",
    "16:16:23",
    "16:17:23",
    "16:18:24",
    "16:19:23",
    "16:20:25",
    "16:21:23",
    "16:22:23",
    "16:23:24",
    "16:24:23",
    "16:25:25",
    "16:26:23",
    "16:27:23",
    "16:28:24",
    "16:29:24",
    "16:30:25",
    "16:31:23",
    "16:32:23",
    "16:33:23",
    "16:34:24",
    "16:35:23",
    "16:36:24",
    "16:37:24",
    "16:38:23",
    "16:39:23",
    "16:40:26",
    "16:41:24",
    "16:42:24",
    "16:43:23",
    "16:44:24",
    "16:45:24",
    "16:46:23",
    "16:47:24",
    "16:48:24",
    "16:49:23",
    "16:50:25",
    "16:51:23",
    "16:52:23",
    "16:53:23",
    "16:54:23",
    "16:55:24",
    "16:56:24",
    "16:57:23",
    "16:58:23",
    "16:59:23",
    "17:00:25",
    "17:01:23",
    "17:02:23",
    "17:03:24",
    "17:04:23",
    "17:05:24",
    "17:06:24",
    "17:07:24",
    "17:08:23",
    "17:09:24",
    "17:10:26",
    "17:11:23",
    "17:12:23",
    "17:13:23",
    "17:14:23",
    "17:15:24",
    "17:16:23",
    "17:17:24",
    "17:18:24",
    "17:19:23",
    "17:20:26",
    "17:21:23",
    "17:22:23",
    "17:23:24",
    "17:24:24",
    "17:25:24",
    "17:26:24",
    "17:27:24",
    "17:28:23",
    "17:29:23",
    "17:30:26",
    "17:31:23",
    "17:32:23",
    "17:33:24",
    "17:34:24",
    "17:35:24",
    "17:36:23",
    "17:37:23",
    "17:38:23",
    "17:39:24",
    "17:40:25",
    "17:41:23",
    "17:42:23",
    "17:43:23",
    "17:44:23",
    "17:45:24",
    "17:46:23",
    "17:47:23",
    "17:48:24",
    "17:49:23",
    "17:50:26",
    "17:51:23",
    "17:52:24",
    "17:53:23",
    "17:54:23",
    "17:55:24",
    "17:56:23",
    "17:57:24",
    "17:58:23",
    "17:59:23",
    "18:00:24",
    "18:01:23",
    "18:02:24",
    "18:03:23",
    "18:04:23",
    "18:05:24",
    "18:06:23",
    "18:07:23",
    "18:08:23",
    "18:09:24",
    "18:10:26",
    "18:11:24",
    "18:12:23",
    "18:13:24",
    "18:14:23",
    "18:15:23",
    "18:17:24",
    "18:18:24",
    "18:19:24",
    "18:20:26",
    "18:21:24",
    "18:22:23",
    "18:23:23",
    "18:24:23",
    "18:25:24",
    "18:26:23",
    "18:27:24",
    "18:28:23",
    "18:29:23",
    "18:30:26",
    "18:31:23",
    "18:32:23",
    "18:33:24",
    "18:34:23",
    "18:35:23",
    "18:36:23",
    "18:37:23",
    "18:38:23",
    "18:39:24",
    "18:40:25",
    "18:41:24",
    "18:42:24",
    "18:43:23",
    "18:44:23",
    "18:45:24",
    "18:46:23",
    "18:47:23",
    "18:48:24",
    "18:49:24",
    "18:50:26",
    "18:51:23",
    "18:52:23",
    "18:53:24",
    "18:54:23",
    "18:55:23",
    "18:56:24",
    "18:57:23",
    "18:58:23",
    "18:59:23",
    "19:00:25",
    "19:01:23",
    "19:02:24",
    "19:03:25",
    "19:04:23",
    "19:05:24",
    "19:06:22",
    "19:07:23",
    "19:08:23",
    "19:09:23",
    "19:10:27",
    "19:11:24",
    "19:12:24",
    "19:13:24",
    "19:14:23",
    "19:15:24",
    "19:16:23",
    "19:17:23",
    "19:18:24",
    "19:19:23",
    "19:20:26",
    "19:21:23",
    "19:22:23",
    "19:23:23",
    "19:24:23",
    "19:25:25",
    "19:26:24",
    "19:27:23",
    "19:28:24",
    "19:29:23",
    "19:30:26",
    "19:31:24",
    "19:32:23",
    "19:33:23",
    "19:34:23",
    "19:35:24",
    "19:36:23",
    "19:37:25",
    "19:38:23",
    "19:39:23",
    "19:40:26",
    "19:41:23",
    "19:42:23",
    "19:43:23",
    "19:44:23",
    "19:45:24",
    "19:46:23",
    "19:47:23",
    "19:48:24",
    "19:49:23",
    "19:50:25",
    "19:51:23",
    "19:52:23",
    "19:53:23",
    "19:54:23",
    "19:55:23",
    "19:56:24",
    "19:57:23",
    "19:58:23",
    "19:59:23",
    "20:00:26",
    "20:01:23",
    "20:02:23",
    "20:03:23",
    "20:04:23",
    "20:05:23",
    "20:06:24",
    "20:07:23",
    "20:08:23",
    "20:09:23",
    "20:10:25",
    "20:11:23",
    "20:12:24",
    "20:13:23",
    "20:14:24",
    "20:15:23",
    "20:16:22",
    "20:17:24",
    "20:18:23",
    "20:19:23",
    "20:20:25",
    "20:21:23",
    "20:22:23",
    "20:23:23",
    "20:24:23",
    "20:25:24",
    "20:26:23",
    "20:27:23",
    "20:28:23",
    "20:29:23",
    "20:30:26",
    "20:31:23",
    "20:32:23",
    "20:33:23",
    "20:34:23",
    "20:35:24",
    "20:36:25",
    "20:37:24",
    "20:38:25",
    "20:39:24",
    "20:40:27",
    "20:41:25",
    "20:42:24",
    "20:43:24",
    "20:44:25",
    "20:45:25",
    "20:46:25",
    "20:47:24",
    "20:48:24",
    "20:49:24",
    "20:50:26",
    "20:51:24",
    "20:52:24",
    "20:53:24",
    "20:54:25",
    "20:55:25",
    "20:56:25",
    "20:57:24",
    "20:58:24",
    "20:59:24",
    "21:00:27",
    "21:01:25",
    "21:02:24",
    "21:03:25",
    "21:04:24",
    "21:05:25",
    "21:06:25",
    "21:07:24",
    "21:08:25",
    "21:09:24",
    "21:10:25",
    "21:11:25",
    "21:12:24",
    "21:13:25",
    "21:14:25",
    "21:15:25",
    "21:16:24",
    "21:17:24",
    "21:18:25",
    "21:19:24",
    "21:20:27",
    "21:21:24",
    "21:22:25",
    "21:23:24",
    "21:24:24",
    "21:25:25",
    "21:26:25",
    "21:27:25",
    "21:28:24",
    "21:29:24",
    "21:30:25",
    "21:31:25",
    "21:32:25",
    "21:33:24",
    "21:34:25",
    "21:35:25",
    "21:36:25",
    "21:37:25",
    "21:38:24",
    "21:39:25",
    "21:40:28",
    "21:41:24",
    "21:42:26",
    "21:43:24",
    "21:44:25",
    "21:45:25",
    "21:46:25",
    "21:47:25",
    "21:48:24",
    "21:49:24",
    "21:50:26",
    "21:51:25",
    "21:52:25",
    "21:53:24",
    "21:54:24",
    "21:55:25",
    "21:56:25",
    "21:57:24",
    "21:58:24",
    "21:59:25",
    "22:00:27",
    "22:01:24",
    "22:02:24",
    "22:03:25",
    "22:04:24",
    "22:05:25",
    "22:06:25",
    "22:07:25",
    "22:08:25",
    "22:09:25",
    "22:10:26",
    "22:11:24",
    "22:12:24",
    "22:13:25",
    "22:14:25",
    "22:15:25",
    "22:16:24",
    "22:17:25",
    "22:18:25",
    "22:19:24",
    "22:20:26",
    "22:21:25",
    "22:22:24",
    "22:23:25",
    "22:24:24",
    "22:25:25",
    "22:26:24",
    "22:27:24",
    "22:28:24",
    "22:29:24",
    "22:30:27",
    "22:31:25",
    "22:32:24",
    "22:33:24",
    "22:34:25",
    "22:35:25",
    "22:36:25",
    "22:37:24",
    "22:38:26",
    "22:39:24",
    "22:40:26",
    "22:41:24",
    "22:42:24",
    "22:43:25",
    "22:44:24",
    "22:45:25",
    "22:46:25",
    "22:47:24",
    "22:48:24",
    "22:49:24",
    "22:50:27",
    "22:51:25",
    "22:52:24",
    "22:53:24",
    "22:54:24",
    "22:55:24",
    "22:56:24",
    "22:57:25",
    "22:58:24",
    "22:59:24",
    "23:00:25",
    "23:01:25",
    "23:02:25",
    "23:03:25",
    "23:04:25",
    "23:05:24",
    "23:06:25",
    "23:07:24",
    "23:08:24",
    "23:09:24",
    "23:10:25",
    "23:11:25",
    "23:12:24",
    "23:13:25",
    "23:14:24",
    "23:15:24",
    "23:16:25",
    "23:17:26",
    "23:18:24",
    "23:19:25",
    "23:20:27",
    "23:21:24",
    "23:22:24",
    "23:23:24",
    "23:24:24",
    "23:25:25",
    "23:26:24",
    "23:27:24",
    "23:28:24",
    "23:29:24",
    "23:30:27",
    "23:31:24",
    "23:32:24",
    "23:33:25",
    "23:34:24",
    "23:35:24",
    "23:36:24",
    "23:37:24",
    "23:38:24",
    "23:39:24",
    "23:40:26",
    "23:41:24",
    "23:42:24",
    "23:43:25",
    "23:44:24",
    "23:45:25",
    "23:46:24",
    "23:47:24",
    "23:48:24",
    "23:49:25",
    "23:50:27",
    "23:51:24",
    "23:52:25",
    "23:53:24",
    "23:54:25",
    "23:55:25",
    "23:56:25",
    "23:57:25",
    "23:58:24"
  ]);
}