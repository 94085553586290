// import { Card, Container, Typography, LinearProgress, Grid, Box } from '@mui/material';
// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import LoginImg from '../Assets/illustration_login.png';
// import Logo from '../components/Logo';
// import Page from '../components/Page';
// import { LoginForm } from '../sections/auth/login';



// export default function Login() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const authToken = JSON.parse(localStorage.getItem('AuthorizationToken'));

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       await new Promise((resolve) => setTimeout(resolve, 2000));
//       if (authToken !== null) {
//         navigate(`/dashboard/dashboard`);
//       }
//       setLoading(false);
//     };
//     fetchData();
//   }, [authToken, navigate]);

//   return (
//     <Page title="Login">
//       {loading && <LinearProgress />}
//       <Box
//         sx={{
//           minHeight: '100vh',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           position: 'relative',
//           p: { xs: 3, sm: 6, md: 6, lg: 6 },
//         }}
//       >
//         <Box sx={{ position: 'absolute', top: 0, left: 100, p: { xs: 3, md: 5, lg: 7 },}}>
//           <Logo />
//         </Box>

//         <Container >
//           <Grid container spacing={2} justifyContent="center" alignItems="center">
//             <Grid item xs={12} md={6} lg={6}  sx={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   p: { xs: 2, sm: 3, md: 4, lg: 2 },
//                   maxWidth: { xs: '100%', sm: '100%', lg: '100%' },
//                 }}>
//               {/* <Card
//                 sx={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   p: { xs: 2, sm: 3, md: 4, lg: 2 },
//                   maxWidth: { xs: '100%', sm: '100%', lg: '100%' },
//                 }}
//               > */}
//                 <Typography variant="h3" sx={{px: 5, mt: 5, mb: 5 }}>
//                   Hi, Welcome Back
//                 </Typography>
//                 <img src={LoginImg} alt="login" style={{ width: '100%' }} />
//               {/* </Card> */}
//             </Grid>

//             <Grid item xs={12} md={6} lg={6}>
//               <Box
//                 sx={{
//                   maxWidth: '100%',
//                   margin: 'auto',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   p: { xs: 6, sm: 8, md: 10, lg: 12 },
//                 }}
//               >
//                 <Typography variant="h5"  gutterBottom >
//                   Sign in to Linkeye Basestation
//                 </Typography>
//                 <Typography sx={{ color: 'text.secondary', mb: 0 }}>Enter your details below.</Typography>
//                 <LoginForm />
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </Page>
//   );
// }

import { Container, Typography, LinearProgress, Grid, Box } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginImg from '../Assets/illustration_login.png';
import Logo from '../components/Logo';
import Page from '../components/Page';
import { LoginForm } from '../sections/auth/login';

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const authToken = localStorage.getItem('AuthorizationToken');

  useLayoutEffect(() => {
    const validateTokenAndRedirect = async () => {
      setLoading(true);


      const isValidToken = authToken && authToken !== 'null';

      if (isValidToken) {
        navigate('/dashboard/dashboard');
      }

      setLoading(false);
    };

    validateTokenAndRedirect();
  }, [authToken, navigate]);

  return (
    <Page title="Login">
      {loading && <LinearProgress />}
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          p: { xs: 3, sm: 6, md: 6, lg: 6 },
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 100, p: { xs: 3, md: 5, lg: 7 } }}>
          <Logo />
        </Box>

        <Container>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: { xs: 2, sm: 3, md: 4, lg: 2 },
                maxWidth: { xs: '100%', sm: '100%', lg: '100%' },
              }}
            >
              <Typography variant="h3" sx={{ px: 5, mt: 5, mb: 5 }}>
                Hi, Welcome Back
              </Typography>
              <img src={LoginImg} alt="login" style={{ width: '100%' }} />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Box
                sx={{
                  maxWidth: '100%',
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  p: { xs: 6, sm: 8, md: 10, lg: 12 },
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Sign in to Linkeye Basestation
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 0 }}>
                  Enter your details below.
                </Typography>
                <LoginForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
}
