import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Paper, CardContent, Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import cardStyles from '../../styles/cardsstyles';
import { locationsData } from '../../_mock/locations';

const UserExperienceChart = (props) => {
  const { locationData } = props;
  const classes = cardStyles();
  const [wanChartbgColor, setWanChartbgColor] = useState(null);
  const [series, setSeries] = useState([90, 10]);
  useEffect(() => {
    setSeries(
      locationsData
        .filter((locations) => locationData.length === 0 || locationData.find((item) => item.id === locations.id))
        .reduce(
          (preVal, curVal) => {
            return [preVal[0] + curVal.series[0], preVal[1] + curVal.series[1]];
          },
          [0, 0]
        )
    );
  }, [locationData]);
  const chartOptions = {
    options: {
      dataLabels: {
        enabled: false,
      },
      labels: ['Office users', 'Remote users'],
      chart: {
        events: {
          dataPointMouseEnter(event, chartContext, config) {
            let color = chartContext.theme.colors[config.dataPointIndex];
            color += '75';
            setWanChartbgColor(color);
          },
          dataPointMouseLeave(event) {
            setWanChartbgColor(null);
          },
          mouseLeave() {
            setWanChartbgColor(null);
          },
        },
      },
      fill: {
        type: 'gradient',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 50,
          donut: {
            dataLabels: {
              show: true,
              name: 'total',
              value: '86',
              color: '#e5e5e5',
            },
            size: '70%',
            background: wanChartbgColor,
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Rubik',
                color: '#dfsda',
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                color: undefined,
                offsetY: 16,
                formatter(val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'Total',
                color: '#373d3f',
                fontWeight: 900,
                fontSize: '1rem',
                formatter(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      grid: {
        padding: {
          bottom: 0,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        markers: {
          width: 30,
          height: 30,
          strokeWidth: 0,
          fillColors: undefined,
          radius: 4,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 10,
        },
      },
    },
  };
  return (
    <Paper elevation={15}>
      <div style={{ display: 'flex', padding: 16 }}>
        <ManageAccountsIcon className={classes.boxTitleIcon} />
        <Typography variant="h6" component="div" className={classes.boxTitle}>
          User Experience
        </Typography>
        <MoreHorizIcon
          style={{
            fontSize: '1.5rem',
            color: '#e0e0e0',
            float: 'right',
            borderRadius: '2px',
            marginTop: '15px',
            marginRight: '15px',
            borderColor: '#eeeeee',
          }}
        />
      </div>
      <Box sx={{ padding: '24px' }}>
        <ReactApexChart options={chartOptions.options} series={series} type="donut" />
      </Box>
    </Paper>
  );
};
export default UserExperienceChart;
