/* eslint-disable */
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  ContentCopy,
  Download,
  Refresh,
  Search,
  Settings,
} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getInactiveDevices } from '../../../API/Inactive/Inactive';
import { deleteDevice } from '../../../API/NetworkDevices/networkDevices';
import { getUser } from '../../../API/User/User';
import { UserContext } from '../../../context/UserContext';
import TooltipRenderer from '../../../utils/TooltipRenderer';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import { timeSince, toCamelizeWords } from '../../../utils/formatString';
import { fDateTimeForDownloadCsv, fDateTimeFormat } from '../../../utils/formatTime';
import ArrayToCSVdownload from '../../ArrayToCSVdownload';
import { WithConfirm } from '../../WithConfirm';
import ColumnSettingsDialog from './filters/ColumnSettingsDialog';

// ----------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 13,
    top: 18,
  },
}));

// ----------------------------------------------------------------------

const InactiveTable = (props) => {
  const classes = useStyles();
  const { locationData, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [filterModel, setFilterModel] = useState('');
  const [sortModel, setSortModel] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [deleteIPLoading, setDeleteIPLoading] = useState('');

// ----------------------------------------------------------------------

  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

  const getFontSize = () => {
    if (isLargeDesktop) {
      return '20px';
    } 
    if (isDesktop) {
      return '16px';
    } 
    if (isTablet) {
      return '14px';
    } 
    return '12px';
    }

  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

  // -------------------------------------------------------------------------------------------------------



  useEffect(() => {
    if (navigator.onLine) {
      getUser().then((res) => {
        setUserCustomer(res?.customer?.name);
      });
    }
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-Inactive-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const isUndefined = (value, def = '') => {
    return value === undefined || value == null || value.length <= 0 || value === '' ? def : value;
  };

  const handleDeleteIP = (value) => {
    setDeleteIPLoading(true);
    const payload = {
      _uid: value?._uid,
      type: value?.r_dvc_type,
      name: value?.r_dvc_name,
      ip_address: value?.r_dvc_ip_address,
      serial_number: value?.r_dvc_serial_number,
      mac_address: value?.r_dvc_mac_address,
      host: value?.r_dvc_host,
      status: value?.status,
      type_name: value?.deviceType.r_dvc_typ_name,
      vendor: value?.deviceType.r_dvc_typ_vendor,
      os: value?.deviceType.r_dvc_typ_os,
      g_deleted_at: fDateTimeFormat(new Date()),
    };

    deleteDevice(customerId, value?._uid, payload)
      .then((res) => {
        setDeleteIPLoading(false);
        enqueueSnackbar(
          'IP Address deleted successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((err) => {
        setDeleteIPLoading(false);
        enqueueSnackbar(
          err.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'id',
      width: 80,
      // pinned: 'left',
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      sortable: true,
      // pinned: 'left',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRenderer(params?.data?.r_dvc_mgmt_ip, params?.data?.r_dvc_mgmt_type)}
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
                <Tooltip title="delete">
                  <IconButton size="small" className={classes.icon}
                    onClick={confirm(() => handleDeleteIP(params.data), {
                      title: 'Information',
                      confirmationText: 'Yes',
                      disableOkBtn: false,
                      description: 'Are you sure you want to delete ?',
                    })}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      floatingFilter: false,
      // pinned: 'left',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_location_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      sortable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_mac_address;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_serial_number;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Vendor Model',
      colId: 'l_dvc_typ_vendor',
      sortable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        const l_dvc_typ_vendor = params.data?.r_dvc_model?.indexOf(params.data?.l_dvc_typ_vendor) === -1 || !params.data?.r_dvc_model ? params.data?.l_dvc_typ_vendor : ""
        const r_dvc_model = params.data?.r_dvc_model ?? ""
        return `${l_dvc_typ_vendor} ${r_dvc_model}`;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Status',
      colId: 'r_dvc_up_down',
      width: 230,
      sortable: true,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'down') {
          return (
            <div className={classes.downHight}>
              <Chip label="Down" variant="outlined" avatar={<ArrowCircleDownOutlined style={{ color: '#f44336' }} />} />
            </div>
          );
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'up') {
          return (
            <div className={classes.downHight}>
              <Chip label="Up" variant="outlined" avatar={<ArrowCircleUpOutlined style={{ color: '#4caf50' }} />} />
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Inactive since',
      colId: 'r_dvc_status_inactive_since',
      width: 270,
      floatingFilter: false,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.g_status?.toLowerCase() === 'inactive') {
          let since = '';
          if (params?.data?.r_dvc_status_inactive_since) {
            since =  `${timeSince(params?.data?.r_dvc_status_inactive_since * 1000)}`;
          }
          console.log(since,"since");
          return (
            <div className={classes.downHight}>
              <Typography>{since}</Typography>
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      sortable: true,
      // pinned: 'right',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.l_dvc_typ_name);
      },
      cellStyle: responsiveCellStyle,
    },
  ];

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
          filterModel: {
            ..._params.filterModel,
            g_status: {
              filterType: 'text',
              type: '=',
              filter: 'INACTIVE',
            },
          },
        };
        delete payload.context;
        setFilterModel(payload.filterModel);
        setSortModel(_params.sortModel);
        getInactiveDevices(customerId, payload).then((InactiveData) => {
          let lastRow = -1;
          if (InactiveData.total <= _params.endRow) {
            lastRow = InactiveData.total;
          }
          setTotalItems(InactiveData.total);
          if (InactiveData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(InactiveData.data, lastRow);
          setRefreshLoading(false);
        });
      },
    });
  }, []);

  const onBtnExport = () => {
    setDownloadLoading(true);
    const Inactive = [];
    const pages = Math.ceil(totalItems / 200);
    const primises = Array(pages)
      .fill()
      .map((item, index) => {
        const startIndex = index * 200;
        const endIndex = Math.min((index + 1) * 200, totalItems + 1) - 1;
        const payload = {
          endRow: endIndex,
          startRow: startIndex,
          location_uuids: locationData,
          global_keyword: query,
          sortModel,
          filterModel,
        };
        return getInactiveDevices(customerId, payload);
      });
    const allowedColumns = columnDefs.filter((def) => !def.hide).map((def) => def.colId);

    try {
      Promise.all(primises)
        .then((res) => {
          res.map((item) => {
            const response = item.data?.map((item) => {
              const record = {};
              allowedColumns.forEach((column) => {
                switch (column) {
                  case 'r_dvc_ip_address':
                    record[`IP Address`] = isUndefined(item?.r_dvc_ip_address);
                    break;
                  case 'r_location_name':
                    record.Location = isUndefined(item?.r_location_name);
                    break;
                  case 'r_dvc_name':
                    record[`Device Name`] = isUndefined(item?.r_dvc_name);
                    break;
                  case 'r_dvc_mac_address':
                    record[`Mac Address`] = isUndefined(item?.r_dvc_mac_address);
                    break;
                  case 'r_dvc_serial_number':
                    record[`Serial Number`] = isUndefined(item?.r_dvc_serial_number);
                    break;
                  case 'l_dvc_typ_vendor':
                    record.Vendor = isUndefined(item?.l_dvc_typ_vendor);
                    break;
                  case 'l_dvc_typ_name':
                    record[`Device Type`] = isUndefined(item?.l_dvc_typ_name);
                    break;
                  case 'r_dvc_firmware':
                    record[`Firmware Version`] = isUndefined(item?.r_dvc_firmware);
                    break;
                  case 'r_dvc_os':
                    record.OS = isUndefined(item?.r_dvc_os);
                    break;
                  case 'r_dvc_eos':
                    record.EOS = isUndefined(item?.r_dvc_eos);
                    break;
                  case 'r_dvc_eol':
                    record.EOL = isUndefined(item?.r_dvc_eol);
                    break;
                  case 'r_dvc_is_vulnerable':
                    record.Vulnerable = isUndefined(item?.r_dvc_is_vulnerable);
                    break;
                  case 'r_dvc_description':
                    record.Description = isUndefined(item?.r_dvc_description);
                    break;
                  case 'item.r_dvc_error':
                    record.Information = isUndefined(item?.r_dvc_error);
                    break;
                  case 'r_dvc_uptime':
                    record.Uptime = isUndefined(item?.r_dvc_uptime);
                    break;
                  default:
                    break;
                }
              });
              Inactive.push(record);
              return {};
            });
            return {};
          });
        })
        .then(() => {
          const CSV_SEPARATER = ',';
          ArrayToCSVdownload({
            data: Inactive,
            headers: Object.keys(Inactive[0]),
            delimiter: CSV_SEPARATER,
            filename: `NetworkDevices-${userCustomer}-${fDateTimeForDownloadCsv(new Date())}.csv`,
          });

          setDownloadLoading(false);
          enqueueSnackbar('Downloaded successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        })
        .catch((error) => {
          setDownloadLoading(false);
          enqueueSnackbar('Error occurred while exporting data', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    } catch (error) {
      setDownloadLoading(false);
      enqueueSnackbar('Error occurred while exporting data', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-network-Inactive-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-Inactive-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  // ----------------------------------------------------------------------
  return (
    <Card>
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Chip label={totalItems || 0} />
              {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
            </Grid>
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                Inactive devices
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <TextField
                focused
                autoFocus
                type="text"
                size="small"
                value={query}
                variant="outlined"
                name='new-password'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search devices"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Tooltip title="Download as CSV">
                <IconButton color="primary" onClick={onBtnExport}>
                  {downloadLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Download sx={{ fontSize: '1.8rem' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Tooltip title="Column settings">
                <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                  <Settings sx={{ fontSize: '2rem' }} />
                </IconButton>
              </Tooltip>
              {openColSettingsDialog && (
                <ColumnSettingsDialog
                  gridApi={gridApi}
                  columnDefs={columnDefs}
                  openDialog={openColSettingsDialog}
                  resetColumnSetting={resetColumnSetting}
                  handleClose={() => setOpenColSettingsDialog(false)}
                />
              )}
            </Grid>
          </Grid>
        }
      />

      <Divider />
      {refreshLoading && <LinearProgress />}

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          onDragStopped={onDragStopped}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData,
          }}
        />
      </Box>
    </Card>
  );
};
export default WithConfirm(InactiveTable);
