import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@mui/material';

const ApproveDialog = ({ openDialog, handleClose, cmdbGroupSummaryEvent, data, api, onApprove }) => {
  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="approve-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="approve-dialog-title">Approve Event</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to approve the change?  {/* Display eventId here */}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onApprove} color="success" variant="contained">
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApproveDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  cmdbGroupSummaryEvent: PropTypes.object,
  data: PropTypes.object,
  api: PropTypes.any,
  eventId: PropTypes.string.isRequired, 
  onApprove: PropTypes.func.isRequired,
};

export default ApproveDialog;
