// material
import { Grid } from '@mui/material';
import { SchedulerTable } from './Components';
// ----------------------------------------------------------------------

export default function SchedulersComponents() {
  return (
    <>
      <Grid container spacing={3} sx={{ justifyContent:'center'}}>
        <Grid item sm={12} lg={12} md={12}>
          <SchedulerTable />
        </Grid>
      </Grid>
    </>
  );
}
