// /* eslint-disable */
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import { Checkbox, CircularProgress } from '@mui/material';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { useContext, useEffect, useState } from 'react';
// import { getLocationsWithoutPagination } from '../../../../API/Locations/showLocations';
// import { UserContext } from '../../../../context/UserContext';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// export default function DcLocationSelector(props) {
//   const { zoneValue, setZoneValue, setLocationValue, locationValue, setDcAgentSubnetOptions, zoneSelectionSource ,selectAllLabel } =
//     props;
//   const { customerId } = useContext(UserContext);
//   const [locationOptions, setLocationOptions] = useState([]);
//   const [locationLoading, setLocationLoading] = useState(false);
//   const [locations, setLocations] = useState([]);
//   const [page, setPage] = useState(0);
//   const filter = createFilterOptions();

//   const loadLoactionOptions = async () => {
//     setLocationLoading(true);
//     try {
//       const _locationOption = await getLocationsWithoutPagination(customerId, page);
//       setPage(_locationOption.current_page - 1);
//       setLocations(_locationOption.data);
//       setLocationOptions(_locationOption.data);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLocationLoading(false);
//       setTimeout(() => {
//         if (zoneValue) {
//           _zoneValueChanged(zoneValue);
//         }
//       }, 1000);
//     }
//   };

//   useEffect(() => {
//     loadLoactionOptions();
//     // if (props.locationValue?.data?.uuid) {
//     //   setLocationLoading(true);
//     //   const _addLocations = getLocationsWithoutPagination(customerId);
//     //   setLocationOptions(_addLocations).filter((item) => item.zone._uid === props.locationValue?.data._uid);
//     //   setTimeout(() => {
//     //     setLocationLoading(false);
//     //   }, 1000);
//     // }
//   }, []);

//   const _zoneValueChanged = (zone, locationValue) => {
//     const newLocations = zone ? locations.filter((loc) => loc.zone?._uid === zone._uid) : locations;
//     setLocationOptions(newLocations);
//     if (zoneSelectionSource !== 'location') {
//       setDcAgentSubnetOptions(newLocations.reduce((current, loc) => [...current, ...loc.subnets], []));
//       setLocationValue(newLocations);
//     }
//   };

//   useEffect(() => {
//     if (zoneValue) {
//       _zoneValueChanged(zoneValue, locationValue);
//     } else {
//       setLocationOptions(locations);
//       setLocationValue([]);
//       setDcAgentSubnetOptions([]);
//     }

//     // const _addLocations = getLocationsWithoutPagination(customerId);
//     // setLocationOptions(
//     //   _addLocations?.data?.map((item) => {
//     //     return {
//     //       uuid: item.uuid,
//     //       r_location_name: item.r_location_name,
//     //     };
//     //   })
//     // );
//   }, [zoneValue]);


//   const _selectLocations = (_locations) => {
//     if (_locations?.length === 1 && zoneValue === null) {
//       setZoneValue(_locations[0].zone);
//       // setTimeout(() => {
//       //   setLocationValue(_locations);
//       // }, 1000);
//     }
//     setLocationValue(_locations);
//     setDcAgentSubnetOptions(_locations.reduce((current, loc) => [...current, ...loc.subnets], []));
//   };


//   const [selectedOptions, setSelectedOptions] = useState([]);
//   // const allSelected = locationValue.length === selectedOptions.length;

//   const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
//   const handleClearOptions = () => setSelectedOptions([]);
//   const [allSelected, setAllSelected] = useState(locationValue.length === selectedOptions.length);

//   const handleSelectAll = (isSelected) => {
//     if (isSelected) {
//       setAllSelected(true);
//       // setSelectedOptions(locationValue);
//     } else {
//       // handleClearOptions();
//       setAllSelected(false);
//       // setSelectedOptions([]);
//     }
//   };

//   const [value, setValue] = useState([]);

//   const handleToggleSelectAll = (event) => {
//     handleSelectAll && handleSelectAll(event.target.checked);
//   };

// console.log("locationValue",locationValue);

//   return (
//     <Autocomplete
//       size="small"
//       value={locationValue || ''}
//       fullWidth
//       limitTags={4}
//       multiple
//       disableCloseOnSelect
//       onChange={(event, newValue, reason) => {
//         if (reason === 'selectOption') {
//           if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
//             handleToggleSelectAll(event);
//             const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
//             _selectLocations(result);
//             handleToggleOption && handleToggleOption(result);
//           } else {
//             handleToggleOption && handleToggleOption(newValue);
//             _selectLocations(newValue);
//           }
//         } else if (reason === 'clear') {
//           handleToggleSelectAll(event);
//           handleClearOptions && handleClearOptions();
//           handleToggleOption && handleToggleOption([]);
//           _selectLocations(newValue);
//         } else if (reason === 'removeOption') {
//           if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
//             handleToggleSelectAll(event);
//             const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
//             _selectLocations([]);
//             handleToggleOption && handleToggleOption([]);
//           } else {
//             handleToggleOption && handleToggleOption(newValue);
//             _selectLocations(newValue);
//           }
//         }
//       }}
//       isOptionEqualToValue={(option, value) => option._uid === value._uid}
//       filterOptions={(options, params) => {
//         const filtered = filter(options, params);

//         // const { inputValue } = params;
//         // // Suggest the creation of a new value
//         // const isExisting = locationOptions?.some(
//         //   (option) => inputValue.toLocaleLowerCase() === option.r_location_name.toLocaleLowerCase()
//         // );
//         // if (inputValue !== '' && !isExisting) {
//         //   filtered.push({
//         //     inputValue,
//         //     // r_location_name: `Create "${inputValue}"`,
//         //   });
//         // }
//         return [{ r_location_name: selectAllLabel }, ...filtered];
//       }}
//       selectOnFocus
//       clearOnBlur
//       handleHomeEndKeys
//       id="free-solo-with-text-demo"
//       options={locationOptions}
//       loading={locationLoading}
//       getOptionLabel={(option) => option.r_location_name}
//       // renderOption={(props , option)=>{<li {...props}>{option.r_location_name}</li>}}
//       renderOption={(props, option, { selected }) => {
//         const selectAllProps = option.r_location_name === selectAllLabel ? { checked: allSelected } : {};
//         return (
//           <li {...props}>
//             {' '}
//             <Checkbox
//               icon={icon}
//               checkedIcon={checkedIcon}
//               style={{ marginRight: 8 }}
//               checked={selected}
//               {...selectAllProps}
//             />
//             {option.r_location_name}
//           </li>
//         );
//       }}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           placeholder="Select Location"
//           label="Location"
//           InputLabelProps={{ shrink: true }}
//           InputProps={{
//             ...params.InputProps,
//             endAdornment: (
//               <>
//                 {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
//                 {params.InputProps.endAdornment}
//               </>
//             ),
//           }}
//         />
//       )}
//     />
//   );
// }

/* eslint-disable */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { getLocationsWithoutPagination } from '../../../../API/Locations/showLocations';
import { UserContext } from '../../../../context/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DcLocationSelector(props) {
  const { zoneValue, setLocationValue, locationValue, setDcAgentSubnetOptions, onChange, selectAllLabel } = props;
  const { customerId } = useContext(UserContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);

  const filter = createFilterOptions();

  const loadLoactionOptions = async () => {
    setLocationLoading(true);
    try {
      const _locationOption = await getLocationsWithoutPagination(customerId, {});
      const filteredLocations = _locationOption.data.filter((loc) => loc.zone?.zone_id === zoneValue?.zone_id);
      setLocationOptions(filteredLocations);
      const subnets = filteredLocations.reduce((current, loc) => [...current, ...loc.subnets], []);
      setDcAgentSubnetOptions(subnets); 
    } catch (error) {
      console.log(error);
    } finally {
      setLocationLoading(false);
    }
  };
  

  useEffect(() => {
    loadLoactionOptions();
  }, [zoneValue]);

  
  const _selectLocations = (_locations) => {    
    const _locationsFiltered = locationOptions.filter((i) => _locations.findIndex((_l) => _l.location_id === i.location_id) !== -1);
    setLocationValue(_locationsFiltered);
    setDcAgentSubnetOptions(_locationsFiltered.reduce((current, loc) => [...current, ...loc.subnets], []));
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
  const handleClearOptions = () => setSelectedOptions([]);
  const [allSelected, setAllSelected] = useState(locationValue.length === selectedOptions.length);

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setAllSelected(true);
      // setSelectedOptions(locationValue);
    } else {
      // handleClearOptions();
      setAllSelected(false);
      // setSelectedOptions([]);
    }
  };

  const [value, setValue] = useState([]);

  const handleToggleSelectAll = (event) => {
    handleSelectAll && handleSelectAll(event.target.checked);
  };

  return (
    <Autocomplete
      size="small"
      value={locationValue || ''}
      fullWidth
      limitTags={4}
      multiple
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
            handleToggleSelectAll(event);
            const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
            _selectLocations(result);
            handleToggleOption && handleToggleOption(result);
          } else {
            handleToggleOption && handleToggleOption(newValue);
            _selectLocations(newValue);
          }
        } else if (reason === 'clear') {
          handleToggleSelectAll(event);
          handleClearOptions && handleClearOptions();
          handleToggleOption && handleToggleOption([]);
          _selectLocations(newValue);
        } else if (reason === 'removeOption') {
          if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
            handleToggleSelectAll(event);
            const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
            _selectLocations([]);
            handleToggleOption && handleToggleOption([]);
          } else {
            handleToggleOption && handleToggleOption(newValue);
            _selectLocations(newValue);
          }
        }
      }}
      isOptionEqualToValue={(option, value) => option.location_id === value.location_id}
      disableCloseOnSelect
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = locationOptions?.some(
          (option) => inputValue.toLocaleLowerCase() === option.r_location_name.toLocaleLowerCase()
        );
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            // r_location_name: `Create "${inputValue}"`,
          });
        }
        return [{ r_location_name: selectAllLabel }, ...filtered];
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={locationOptions}
      loading={locationLoading}
      // getOptionLabel={(option) => (option ? option.r_location_name : '')}
      getOptionLabel={(option) =>  option.r_location_name}
      // renderOption={(props , option)=>{<li {...props}>{option.r_location_name}</li>}}
      renderOption={(props, option, { selected }) => {        
        const selectAllProps = option.r_location_name === selectAllLabel ? { checked: allSelected } : {};
        return (
          <li {...props}>
            {' '}
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option.r_location_name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Location"
          label="Location"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}