import { Box, Tooltip, Typography,useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AffectedDeviceDialog from '../AffectedDeviceDialog';


const AffectedRenderer = (props) => {
  const { data } = props;
  const [openAffectedDeviceDialog, setAffectedDeviceDialog] = useState(false);

  const handleClickOpen = () => {
    setAffectedDeviceDialog(true);
  };
  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    } 
    if (isDesktop) {
      return '20px';
    } 
    if (isTablet) {
      return '14px';
    } 
    return '12px';
    }


  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

  if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box sx={{ py: 1.4 }}>
        <Tooltip title="View affected devices">
          <Typography
            gutterBottom
            onClick={handleClickOpen}
            sx={{ cursor: 'pointer', fontSize: responsiveCellStyle, fontFamily: responsiveCellStyle, fontWeight: responsiveCellStyle }}
          >
            {data.r_grp_smry_affected_device_count} devices
          </Typography>
        </Tooltip>
        {openAffectedDeviceDialog && (
          <AffectedDeviceDialog
            openDialog={openAffectedDeviceDialog}
            handleClose={() => {
              setAffectedDeviceDialog(false)
            }}
            groupSummaryEvent={data}
            groupedApi={props.api}
          />
        )}
      </Box>
    );
  }
};

AffectedRenderer.propTypes = {
  data: PropTypes.object,
};

export default AffectedRenderer;
