import { useState } from 'react';
// @mui
import {
  Box, Grid, Paper, Typography
} from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// components
import DHCPtable from './components/DHCPtable';
import DNStable from './components/DNStable';
import ScopesTable from './components/ScopesTable';
// sections

// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function IPAMComponents() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <Box >
              <Paper elevation={15}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="DHCP Server" />
                  <Tab label="DHCP Scopes" />
                  <Tab label="DNS Server" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <DHCPtable />
                </TabPanel>
                <TabPanel value={value} index={1}>
                   <ScopesTable />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <DNStable />
                </TabPanel>
              </Paper>
            </Box>
          </Grid>
        </Grid>
    </>
  );
}
