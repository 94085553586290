import { instance as modem } from '../modem';

export const changePassword = async (data) => {
  const newPromise =  modem
  .post(`/user/change-password?current_password=${encodeURIComponent(data?.current)}&password=${encodeURIComponent(data?.password)}&password_confirmation=${encodeURIComponent(data?.confirm)}`, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((response) => {
      throw response;
    });
  return newPromise;
};

export const checkUserEmail = async (id,data) => {
  const newPromise =  modem
  .post(`${id}/members/email-availability`, data)
    .then((response) => {
      return response;
    })
    .catch((response) => {
      throw response;
    });
  return newPromise;
};
