import ShareIcon from '@mui/icons-material/Share';
import { Card, CardHeader, CardContent, IconButton, Skeleton, Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { debounce } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../../chart';
import cardStyles from '../../../styles/cardsstyles';
import { getDeviceByVendor } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext';
import NoDataComponent from '../../NoDataComponent';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: "100%",
    display:'flex',
    height: 400,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------


const VendorChart = (props) => {
  const { locationData, setShowGraph, showGraph, openedOnce, selectVendors } = props;
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const [series, setSeries] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(true);
  


  const fetchDevicesByVendor = async () => {
    const locations = locationData;
    setChartLoading(true);
    getDeviceByVendor(customerId, locations)
      .then((res) => {
        const toArray = Object.keys(res).map((item) => ({ x: item, y: res[item] })).sort((a, b) => b.y - a.y)
        setLabels(toArray.map(x => x.x));
        setSeries(toArray.map(x => x.y));
        setChartLoading(false);
      })
      .catch(() => {
        setChartLoading(false);
      });
  };

  useEffect(() => {
    if (openedOnce) {
      fetchDevicesByVendor();
    }
    setTimeout(() => {
    }, 100)
  }, [customerId, locationData, openedOnce]);

  const chartSeries = series;

  
  // const chartOptions = {
  //   labels,
  //   chart: {
  //     height: 'auto',
  //     width: '100%',
  //     events: {
  //       dataPointMouseEnter: (event, chartContext, config) => {
  //         event.target.style.cursor = 'pointer';
  //         setIsHovered(true); 
  //       },
  //       dataPointMouseLeave: (event, chartContext, config) => {
  //         setIsHovered(false); 
  //       },
  //       dataPointSelection: (event, chartContext, config) => {
  //         const selectedIndex = config.dataPointIndex;
  //         if (config.dataPointIndex === selectedIndex) {
  //           const val = config.w.config.labels[selectedIndex];
  //           const tableNode = document.getElementById("ndTable");
  //           const topBarNode = document.getElementById("topBar");
  //           let scrollTo = 700;
  //           if (tableNode && topBarNode) {
  //             scrollTo = tableNode.offsetTop - topBarNode.offsetHeight;
  //           }
  //           selectVendors(val);
  //           window.scrollTo({ top: scrollTo, left: 0, behavior: "smooth" });
  //         }
  //       },
  //     },
  //   },
  //   legend: {
  //     position: 'bottom',
  //     horizontalAlign: 'center',
  //     onItemHover: {
  //       highlightDataSeries: true,
  //     },
  //     onItemClick: {
  //       toggleDataSeries: true,
  //     },
  //     formatter(label, opts) {
  //       return [label, " - ", opts.w.globals.series[opts.seriesIndex]];
  //     },
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: (val) => `${val}`, // Format tooltip values as needed
  //     },
  //   },
  //   fill: {
  //     type: 'gradient',
  //     gradient: {
  //       shade: 'light',
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     formatter: (val, opts) => '', // Remove percentage values from labels
  //     dropShadow: { enabled: true },
  //   },
  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         size: '80%',
  //         labels: {
  //           show: true,
  //           value: {
  //             show: true,
  //             fontSize: '14px',
  //             fontWeight: 400,
  //             formatter: (val) => `${val}`, // Show actual values instead of percentages
  //           },
  //           total: {
  //             show: true,
  //             label: 'Total',
  //             color: '#373d3f',
  //             fontWeight: 900,
  //             fontSize: '20px',
  //             // Show the total only when not hovering over segments
  //             formatter: (w) => {
  //               return !isHovered
  //                 ? w.globals.seriesTotals.reduce((a, b) => a + b, 0)
  //                 : ''; 
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  

  const chartOptions = {
    labels,
    chart: {
      height: 'auto',
      width: '100%',
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
          setIsHovered(true);
        },
        dataPointMouseLeave: (event, chartContext, config) => {
          setIsHovered(false);
        },
        dataPointSelection: (event, chartContext, config) => {
          const selectedIndex = config.dataPointIndex;
          const selectedLabel = config.w.config.labels[selectedIndex];
  
          if (selectedLabel) {
            const tableNode = document.getElementById("ndTable");
            const topBarNode = document.getElementById("topBar");
            let scrollTo = 700;
  
            if (tableNode && topBarNode) {
              scrollTo = tableNode.offsetTop - topBarNode.offsetHeight;
            }
  
            selectVendors(selectedLabel);
            window.scrollTo({ top: scrollTo, left: 0, behavior: "smooth" });
          }
        },
      },
    
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      onItemHover: {
        highlightDataSeries: true,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      formatter(label, opts) {
        return [label, " - ", opts.w.globals.series[opts.seriesIndex]];
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => '', // Remove percentage values from labels
      dropShadow: { enabled: true },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '14px',
              fontWeight: 400,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 400,
              formatter: (val) => `${val}`, 
            },
            total: {
              show: true,
              label: 'Total',
              color: '#373d3f',
              fontWeight: 900,
              fontSize: '20px',
              formatter: (w) => {
                // Calculate the total sum of the series
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return total > 0 ? total : ''; 
              },
            },
          },
        },
      },
    },
  };
  
  return (
    <>
      <Card elevation={10}>
        <CardHeader
          sx={{ py: 2, px: 2 }}
          color="text.error"
          title={'Devices by vendor'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
          action={<IconButton onClick={() => setShowGraph(!showGraph)} className={classes.vendorChartActionArrowButton}>
            {showGraph ? <KeyboardArrowUpIcon  sx={{ fontSize: '1.8rem' }}/> : <KeyboardArrowDownIcon  sx={{ fontSize: '1.8rem' }}/>}
          </IconButton>}
        />
        {showGraph && <CardContent sx={{padding:'0px',pl:'10px',pr:'10px',pb:'0px'}}>
          {series?.length === 0 && !chartLoading ? (
            <Box className={classess.noDataContainer}>
              <NoDataComponent noDataFoundText="Device by vendor" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">     
              {chartLoading ? (
                <Skeleton animation="wave"  variant="rectangular"  className={classess.skeletonLoader}/>
              ) : (
                  <ReactApexChart series={chartSeries} options={chartOptions} type="donut" height={470} />
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
        }
      </Card>
    </>
  );
};
export default VendorChart;
