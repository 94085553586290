/* eslint-disable */
import { useCallback } from 'react';
import PortIcon from '../../../../Assets/port.png';
import { getBezierPath, useStore, BaseEdge, EdgeLabelRenderer, useEdges  } from 'reactflow';
import { getEdgeParams } from './utils';
// -------------------------------------------------------------------



// ----------------------------------------------------------------
function SitewiseFloatingEdge({ id, data, source, target, markerEnd, style }) {
  const edges = useEdges();
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);
  

  if (!sourceNode || !targetNode) {
    return null;
  }

  const [edgePath] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const renderLinkLocalPort = useCallback(() => {
    const _sourceEdges = edges.filter((e) => e.source  === source && e.target  === target).map(i => i.data.r_link_local_port)
    const uniqueRemotePorts = new Set([..._sourceEdges]);
    const finalRemotPort = Array.from(uniqueRemotePorts).join(', ');   
    return finalRemotPort;
  });
  
  const renderLinkRemotPort = useCallback(() => {
    const _targetEdges = edges.filter((e) => e.source  === source && e.target  === target).map(i => i.data.r_link_remote_port)
    const uniqueRemotePorts = new Set([..._targetEdges]);
    const finalRemotPort = Array.from(uniqueRemotePorts).join(', ');   
    return finalRemotPort;
  });
  

  // ---------------------------------------------------
  return (
    <>
      <BaseEdge id={id} path={edgePath}  style={style} />
      <EdgeLabelRenderer>
          {data.r_link_local_port && (
            <div
              style={{
                fontSize:'0.6rem',
                position: 'absolute',
                transform: `translate(-50%, -100%) translate(${sx}px,${sy}px)`,
                background: '#ffebee',
                padding: 1,
                color:'#f44336',
                borderRadius: 5,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                zIndex: 10000,
                'opacity':data.hoverEdgeLabel ? 1 : 0
              }}
              className="nodrag nopan"
            >
              <img src={PortIcon} height={8} width={8} alt="port" />&ensp;{renderLinkLocalPort()}
            </div>    
          )}
          {data.r_link_remote_port && (
            <div
              style={{
                fontSize:'0.6rem',
                position: 'absolute',
                transform: `translate(-50%, -100%) translate(${tx}px,${ty}px)`,
                background: '#ffebee',
                padding: 1,
                color:'#f44336',
                borderRadius: 5,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                zIndex: 10000,
                'opacity':data.hoverEdgeLabel ? 1 : 0
              }}
              className="nodrag nopan"
            >
              <img src={PortIcon} height={8} width={8} alt="port" />&ensp;{renderLinkRemotPort()}
            </div>    
          )}
      </EdgeLabelRenderer>
    </>
  );
}

export default SitewiseFloatingEdge;