// import React, { useState, useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import {
//   Box,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   useMediaQuery,
//   useTheme,
//   Button,
//   Divider,
//   Stack,
//   Menu,
//   MenuItem,
//   Tooltip,
//   ListItemIcon,
//   ListItemText,
// } from '@mui/material';
// import SourceIcon from '@mui/icons-material/Source';
// import UndoIcon from '@mui/icons-material/Undo';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandAllIcon from '@mui/icons-material/UnfoldMore';
// import CollapseAllIcon from '@mui/icons-material/UnfoldLess';
// import MoreVertIcon from '@mui/icons-material/MoreVert'; // Import MoreVertIcon
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import BlockIcon from '@mui/icons-material/Block';
// import { useNavigate } from 'react-router-dom';
// import ConfigDialog from './ConfigDialog';
// import { useSnackbar } from 'notistack';
// import { UserContext } from '../../../context/UserContext';
// import {
//   getEventsDifferenceData,
//   approveEventChange,
//   unApproveEventChange,
//   holdEventChange,
//   rollbackEventChange,
// } from '../../../API/S3/ConfigEvents';
// import ApproveDialog from './ApproveDialog';
// import UnapproveDialog from './UnApproveDialog';
// import HoldtillDialog from './HoldTillDialog';
// import { styled, alpha } from '@mui/material/styles';

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiPaper-root': {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//     boxShadow:
//       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     '& .MuiMenu-list': {
//       padding: '4px 0',
//     },
//     '& .MuiMenuItem-root': {
//       '& .MuiSvgIcon-root': {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       '&:active': {
//         backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
//       },
//     },
//   },
// }));

// const decodeBase64 = (str) => {
//   try {
//     return atob(str);
//   } catch (error) {
//     console.error('Base64 decoding failed', error);
//     return str;
//   }
// };

// // Utility function to generate HTML diff
// const generateHtmlDiff = (diffText) => {
//   let diffHtml = `
//     <style type="text/css">
//       .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
//       .diff div:hover {background-color:#ffc;}
//       .diff .control {background-color: #eaf2f5; color: #999999;}
//       .diff .insert {background-color: #ddffdd; color: #000000;}
//       .diff .delete {background-color: #ffdddd; color: #000000;}
//     </style>
//     <div class="diff">
//   `;

//   diffText.split('\n').forEach((line) => {
//     if (line.startsWith('@@')) {
//       diffHtml += `<div class="control">${line}</div>`;
//     } else if (line.startsWith('+++') || line.startsWith('+')) {
//       diffHtml += `<div class="insert">${line}</div>`;
//     } else if (line.startsWith('---') || line.startsWith('-')) {
//       diffHtml += `<div class="delete">${line}</div>`;
//     } else {
//       diffHtml += `<div>${line}</div>`;
//     }
//   });

//   diffHtml += '</div>';
//   return diffHtml;
// };

// const ConfigRenderer = ({ data, api }) => {
//   const uniqueIdentifier = data?.r_dvc_unique_identifier;
//   const dvcName = data?.network_device?.l_dvc_typ_name;
//   const ipAddress = data?.network_device?.r_dvc_ip_address;
//   const serialNumber = data?.network_device?.r_dvc_serial_number;
//   const locationName = data?.location?.r_location_name;

//   const navigate = useNavigate();
//   const { customerId } = useContext(UserContext);
//   const { enqueueSnackbar } = useSnackbar();

//   const [openChonkyDialog, setOpenChonkyDialog] = useState(false);
//   const [differenceData, setDifferenceData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [totalItems, setTotalItems] = useState(null);
//   const [error, setError] = useState(null);
//   const [openFileDiffDialog, setOpenFileDiffDialog] = useState(false);
//   const [decodedDiffs, setDecodedDiffs] = useState([]);
//   const [expanded, setExpanded] = useState([]);
//   const [allExpanded, setAllExpanded] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedEventId, setSelectedEventId] = useState(null);
//   const [selectedIndex, setSelectedIndex] = useState(null);
//   const [openApproveDialog, setOpenApproveDialog] = useState(false);
//   const [openUnapproveDialog, setOpenUnapproveDialog] = useState(false);
//   const [openHoldtillDialog, setOpenHoldtillDialog] = useState(false);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [holdTillDate, setHoldTillDate] = useState('');
//   const [actionTaken, setActionTaken] = useState(false); // Track if action was taken
//   const [initialApproveStatus, setInitialApproveStatus] = useState({}); // To store initial r_approve_status of each item

//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   // Handlers to open/close ConfigDialog
//   const handleClickOpen = () => {
//     setOpenChonkyDialog(true);
//   };

//   const handleCloseChonkyDialog = () => {
//     navigate('/dashboard/config-change-backup-table');
//     setOpenChonkyDialog(false);
//   };

//   const payload = {
//     startRow: 0,
//     endRow: 200,
//   };

//   const handleOpenFileDiffDialog = async () => {
//     setOpenFileDiffDialog(true);
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await getEventsDifferenceData(customerId, uniqueIdentifier, payload);
//       const deviceData = response?.data?.device_data || [];
//       const totalItems = response?.data?.total || 0;

//       setTotalItems(totalItems);

//       if (totalItems === 0) {
//         setError('No data available.');
//         setDifferenceData([]);
//       } else {
//         setDifferenceData(deviceData);

//         const decodedDiffs = deviceData.map((item) => {
//           if (!item.r_file_difference) {
//             return 'No file difference available';
//           }
//           const decoded = decodeBase64(item.r_file_difference);
//           return generateHtmlDiff(decoded);
//         });

//         setDecodedDiffs(decodedDiffs);
//         setExpanded(deviceData.map((_, idx) => idx));
//       }

//       console.log('Fetched differenceData from getEventsDifferenceData:', deviceData);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setError('Error fetching data');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCloseFileDiffDialog = () => {
//     setOpenFileDiffDialog(false);
//   };

//   const handleToggleAll = () => {
//     if (!allExpanded) {
//       const allIndexes = differenceData.map((_, index) => index);
//       setExpanded(allIndexes);
//     } else {
//       setExpanded([]);
//     }
//     setAllExpanded(!allExpanded);
//   };

//   const handleAccordionToggle = (index) => {
//     if (!dialogOpen) {
//       setExpanded((prevExpanded) => {
//         return prevExpanded.includes(index) ? prevExpanded.filter((item) => item !== index) : [...prevExpanded, index];
//       });
//     }
//   };

//   const handleRollback = async (eventId, index) => {
//     console.log('Passing Event ID to Rollback:', eventId);
  
//     if (initialApproveStatus[index] !== 0) {
//       console.error('Rollback not allowed: Initial approve status was not 0');
//       return;
//     }
  
//     if (!eventId) {
//       console.error('No Event ID to rollback');
//       return;
//     }
  
//     setLoading(true); 
  
//     try {
//       const response = await rollbackEventChange(customerId, eventId);
      
//       const successMessage = response.message || 'The event rolled back successfully.';
//       enqueueSnackbar(successMessage, {
//         variant: 'success',
//         anchorOrigin: {
//           vertical: 'bottom',
//           horizontal: 'right',
//         },
//       });

//       setDifferenceData((prevData) => {
//         const updatedData = [...prevData];
//         updatedData[index] = {
//           ...updatedData[index],
//           r_approve_status: 0,
//         };
//         return updatedData;
//       });
//       setActionTaken(false);
//       } catch (error) {
//       console.error('Error rolling back event:', error);
//       setError('Error rolling back event');
//     } finally {
//       setLoading(false); 
//     }
//   };
  

//   const handleApprove = async (eventId, index) => {
//     console.log('Passing Event ID to Approve:', eventId);

//     if (!eventId) {
//       console.error('No Event ID to approve');
//       return;
//     }

//     setLoading(true);
//     try {
//       await approveEventChange(customerId, eventId);
//       enqueueSnackbar('The event has been approved successfully.', {
//         variant: 'success',
//         anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
//       });

//       setDifferenceData((prevData) => {
//         const updatedData = [...prevData];
//         updatedData[index] = {
//           ...updatedData[index],
//           r_approve_status: 1,
//         };
//         return updatedData;
//       });
//     } catch (error) {
//       console.error('Error approving event:', error);
//       setError('Error approving event');
//     } finally {
//       setLoading(false);
//       handleCloseApproveDialog();
//     }
//   };

//   const handleReject = async (eventId, index) => {
//     console.log('Passing Event ID to Reject:', eventId);

//     if (!eventId) {
//       console.error('No Event ID to reject');
//       return;
//     }

//     setLoading(true);
//     try {
//       await unApproveEventChange(customerId, eventId);
//       enqueueSnackbar('The event has been Un-approved successfully.', {
//         variant: 'success',
//         anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
//       });

//       setDifferenceData((prevData) => {
//         const updatedData = [...prevData];
//         updatedData[index] = {
//           ...updatedData[index],
//           r_approve_status: 2,
//         };
//         return updatedData;
//       });
//     } catch (error) {
//       console.error('Error rejecting event:', error);
//     } finally {
//       setLoading(false);
//       handleCloseUnapproveDialog();
//     }
//   };

//   const handleMenuOpen = (event, eventId, index) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedEventId(eventId);
//     setSelectedIndex(index);
//   };

//   // const handleMenuClose = () => {
//   //   console.log('here');

//   //   setAnchorEl(null);
//   //   setSelectedEventId(null);
//   //   setSelectedIndex(null);
//   // };

//   const handleOpenApproveDialog = (index) => {
//     const eventId = differenceData[index]?.cmdb_event_id;
//     if (eventId) {
//       setSelectedEventId(eventId);
//       setSelectedIndex(index);
//       setOpenApproveDialog(true);
//       setActionTaken(true); // Mark that an action was taken
//       setInitialApproveStatus((prevState) => ({
//         ...prevState,
//         [index]: differenceData[index]?.r_approve_status,
//       }));
//     } else {
//       console.error('Event ID is undefined for index:', index);
//     }
//   };

//   const handleCloseApproveDialog = () => {
//     setOpenApproveDialog(false);
//   };

//   // const handleOpenUnapproveDialog = (index) => {
//   //   const eventId = differenceData[index]?.cmdb_event_id;
//   //   if (eventId) {
//   //     setSelectedEventId(eventId);
//   //     setSelectedIndex(index);
//   //     setOpenUnapproveDialog(true);
//   //     setActionTaken(true); // Mark that an action was taken
//   //     setInitialApproveStatus((prevState) => ({
//   //       ...prevState,
//   //       [index]: differenceData[index]?.r_approve_status,
//   //     }));
//   //   } else {
//   //     console.error('Event ID is undefined for index:', index);
//   //   }
//   // };

//   // const handleCloseUnapproveDialog = () => {
//   //   setOpenUnapproveDialog(false);
//   // };

//   const handleOpenHoldtillDialog = (index) => {
//     console.log('differenceData:', differenceData);
//     console.log('Selected index:', index);
//     const eventId = differenceData[index]?.cmdb_event_id;
//     console.log('Selected Event ID:', eventId);
//     if (eventId) {
//       setSelectedEventId(eventId);
//       setSelectedIndex(index);
//       setOpenHoldtillDialog(true);
//       setDialogOpen(true);
//       setActionTaken(true); // Mark that an action was taken
//       setInitialApproveStatus((prevState) => ({
//         ...prevState,
//         [index]: differenceData[index]?.r_approve_status,
//       }));
//     } else {
//       console.error('Event ID is undefined for index:', index);
//     }
//   };

//   // const handleCloseHoldtillDialog = () => {
//   //   setOpenHoldtillDialog(false);
//   // };

//   const handleHoldDate = (holdDate, index) => {
//     const currentHoldDate = differenceData[index]?.r_config_change_till;
//     setHoldTillDate(currentHoldDate);
//     setDifferenceData((prevData) => {
//       const updatedData = [...prevData];
//       updatedData[index] = {
//         ...updatedData[index],
//         r_approve_status: 3,
//         r_config_change_till: holdDate,
//       };
//       return updatedData;
//     });
//   };

//   // Handlers for Action Menu
//   // const handleMenuOpen = (event, eventId, index) => {
//   //   setAnchorEl(event.currentTarget);
//   //   setSelectedEventId(eventId);
//   //   setSelectedIndex(index);
//   // };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedEventId(null);
//     setSelectedIndex(null);
//   };

//   // Handlers for Action Dialogs
//   // const handleOpenApproveDialog = () => {
//   //   setOpenApproveDialog(true);
//   //   handleMenuClose();
//   // };

//   // const handleCloseApproveDialog = () => {
//   //   setOpenApproveDialog(false);
//   // };

//   const handleOpenUnapproveDialog = () => {
//     setOpenUnapproveDialog(true);
//     handleMenuClose();
//   };

//   const handleCloseUnapproveDialog = () => {
//     setOpenUnapproveDialog(false);
//   };

//   // const handleOpenHoldtillDialog = () => {
//   //   setOpenHoldtillDialog(true);
//   //   handleMenuClose();
//   // };

//   const handleCloseHoldtillDialog = () => {
//     setOpenHoldtillDialog(false);
//   };

//   return (
//     <Box sx={{ textAlign: 'center' }}>
//       <IconButton onClick={handleClickOpen} aria-label="Open Config Dialog">
//         <SourceIcon />
//       </IconButton>

//       {openChonkyDialog && (
//         <ConfigDialog openDialog={openChonkyDialog} data={data} handleClose={handleCloseChonkyDialog} />
//       )}

//       <IconButton onClick={handleOpenFileDiffDialog} sx={{ marginLeft: '10px' }} aria-label="View File Differences">
//         <VisibilityIcon />
//       </IconButton>

//       <Dialog
//         open={openFileDiffDialog}
//         onClose={handleCloseFileDiffDialog}
//         fullScreen={fullScreen}
//         maxWidth="xxl"
//         fullWidth
//         PaperProps={{
//           style: {
//             maxHeight: '95vh',
//             width: fullScreen ? '100%' : '80%',
//             margin: 'auto',
//           },
//         }}
//       >
//         <DialogTitle>
//           <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
//             <Typography
//               variant="subtitle1"
//               color="black"
//               sx={{ fontWeight: 'bold', bgcolor: 'grey.200', padding: 1, borderRadius: 1 }}
//             >
//               {dvcName} - {ipAddress} - {serialNumber} - {locationName} - Total Events: {totalItems}
//             </Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//               <IconButton onClick={handleToggleAll} aria-label={allExpanded ? 'Collapse All' : 'Expand All'}>
//                 {allExpanded ? <CollapseAllIcon /> : <ExpandAllIcon />}
//               </IconButton>
//             </Box>
//           </Stack>
//         </DialogTitle>
//         <Divider />
//         <DialogContent
//           sx={{
//             padding: 2,
//             overflowY: 'auto',
//           }}
//         >
//           {loading ? (
//             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
//               <Typography>Loading...</Typography>
//             </Box>
//           ) : error ? (
//             <Typography color="error">{error}</Typography>
//           ) : differenceData && Array.isArray(differenceData) && differenceData.length > 0 ? (
//             <Box>
//               {differenceData.map((item, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     mb: 2,
//                     border: '1px solid #ccc',
//                     borderRadius: 1,
//                     overflow: 'hidden',
//                   }}
//                 >
//                   <Accordion
//                     expanded={expanded.includes(index)}
//                     onChange={() => handleAccordionToggle(index)}
//                     sx={{
//                       backgroundColor:
//                         differenceData[index]?.r_approve_status === 1
//                           ? 'lightgreen'
//                           : differenceData[index]?.r_approve_status === 2
//                           ? '#ffcccb'
//                           : differenceData[index]?.r_approve_status === 3
//                           ? '#ffffe0'
//                           : 'grey.200',
//                       borderRadius: 1,
//                     }}
//                   >
//                     <AccordionSummary
//                       expandIcon={
//                         <ExpandMoreIcon
//                           sx={{
//                             transform: expanded.includes(index) ? 'rotate(180deg)' : 'rotate(0deg)',
//                             transition: 'transform 0.2s ease-in-out',
//                           }}
//                         />
//                       }
//                       aria-controls={`panel-content-${index}`}
//                       id={`panel-header-${index}`}
//                       sx={{
//                         minHeight: 20,
//                         padding: '0 0.0001px',
//                         '& .MuiAccordionSummary-content': {
//                           margin: '12px 0',
//                           alignItems: 'center',
//                           width: '100%',
//                           display: 'flex',
//                         },
//                         flexDirection: 'row-reverse',
//                         '& .MuiAccordionSummary-expandIconWrapper': {
//                           transform: 'rotate(0deg)',
//                           marginLeft: '1px',
//                           marginRight: '0px',
//                           p: 2,
//                         },
//                         '& .MuiAccordionSummary-content': {
//                           marginLeft: '0px',
//                           margin: '12px 0',
//                           alignItems: 'center',
//                           width: '100%',
//                           display: 'flex',
//                         },
//                       }}
//                     >
//                       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
//                         <Typography variant="subtitle1">{item.g_created_at}</Typography>
//                       </Box>

//                       <Box sx={{ flex: 5, textAlign: 'center' }}>
//                         <Typography variant="subtitle1">
//                           {differenceData[index]?.r_approve_status === 1
//                             ? `This change is approved, do you want to revert?`
//                             : differenceData[index]?.r_approve_status === 2
//                             ? `This change is un-approved, do you want to revert?`
//                             : differenceData[index]?.r_approve_status === 3
//                             ? `This change is on hold till ${
//                                 differenceData[index]?.r_config_change_till || holdTillDate || 'N/A'
//                               }.`
//                             : ''}
//                         </Typography>
//                       </Box>

//                       <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>

//                         {/* Rollback icon next to three dots if actionTaken is true */}
//                         {actionTaken && initialApproveStatus[index] === 0 && (
//                             <Tooltip title="Undo">
//                           <IconButton
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               handleRollback(differenceData[index].cmdb_event_id, index);
//                             }}
//                             aria-label="Rollback"
//                             size="small"
//                           >
//                             <UndoIcon fontSize="small" style={{ color: 'blue' }} />
//                           </IconButton>
//                           </Tooltip>
//                         )}

//                         {/* Three dot menu */}
//                         <IconButton
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             handleMenuOpen(e, item.cmdb_event_id, index);
//                           }}
//                           aria-haspopup="true"
//                           aria-controls={anchorEl ? 'action-menu' : undefined}
//                           aria-expanded={anchorEl ? 'true' : undefined}
//                           aria-label="Actions"
//                           size="small"
//                         >
//                           <MoreVertIcon />
//                         </IconButton>

//                         <StyledMenu
//                           id="action-menu"
//                           anchorEl={anchorEl}
//                           open={Boolean(anchorEl) && selectedEventId === item.cmdb_event_id && selectedIndex === index}
//                           onClose={handleMenuClose}
//                           MenuListProps={{
//                             'aria-labelledby': 'action-button',
//                           }}
//                         >
//                           {/* Show all three actions */}

//                           {differenceData[index]?.r_approve_status === 0 && (
//                             <>
//                               <MenuItem onClick={() => handleOpenApproveDialog(index)}>
//                                 <ListItemIcon>
//                                   <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Approve" />
//                               </MenuItem>

//                               <MenuItem onClick={() => handleOpenUnapproveDialog(index)}>
//                                 <ListItemIcon>
//                                   <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Un-Approve" />
//                               </MenuItem>

//                               <MenuItem onClick={() => handleOpenHoldtillDialog(index)}>
//                                 <ListItemIcon>
//                                   <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Hold Until" />
//                               </MenuItem>
//                             </>
//                           )}

//                           {/* Show all three actions but only Un-Approve is clickable */}
//                           {differenceData[index]?.r_approve_status === 1 && (
//                             <>
//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Approve" />
//                               </MenuItem>

//                               <MenuItem onClick={() => handleOpenUnapproveDialog(index)}>
//                                 <ListItemIcon>
//                                   <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Un-Approve" />
//                               </MenuItem>

//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Hold Until" />
//                               </MenuItem>
//                             </>
//                           )}

//                           {/* Show all three actions but only Approve is clickable */}
//                           {differenceData[index]?.r_approve_status === 2 && (
//                             <>
//                               <MenuItem onClick={() => handleOpenApproveDialog(index)}>
//                                 <ListItemIcon>
//                                   <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Approve" />
//                               </MenuItem>

//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Un-Approve" />
//                               </MenuItem>

//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Hold Until" />
//                               </MenuItem>
//                             </>
//                           )}

//                           {/* Show all three actions but only Hold Until is clickable */}
//                           {differenceData[index]?.r_approve_status === 3 && (
//                             <>
//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Approve" />
//                               </MenuItem>

//                               <MenuItem disabled>
//                                 <ListItemIcon>
//                                   <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Un-Approve" />
//                               </MenuItem>

//                               <MenuItem onClick={() => handleOpenHoldtillDialog(index)}>
//                                 <ListItemIcon>
//                                   <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Hold Until" />
//                               </MenuItem>
//                             </>
//                           )}
//                         </StyledMenu>
//                       </Box>
//                     </AccordionSummary>
//                     <AccordionDetails
//                       sx={{
//                         padding: '8px 16px',
//                         maxHeight: '200px',
//                         overflowY: 'auto',
//                         backgroundColor: '#f9f9f9',
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           fontSize: '12px',
//                         }}
//                         dangerouslySetInnerHTML={{ __html: decodedDiffs[index] }}
//                       />
//                     </AccordionDetails>
//                   </Accordion>
//                 </Box>
//               ))}
//             </Box>
//           ) : (
//             <Typography>No differences available</Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseFileDiffDialog} variant="contained" color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       {openApproveDialog && (
//         <ApproveDialog
//           openDialog={openApproveDialog}
//           handleClose={handleCloseApproveDialog}
//           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
//           data={differenceData[selectedIndex] || null}
//           api={api}
//           eventId={selectedEventId}
//           onApprove={() => handleApprove(selectedEventId, selectedIndex)}
//         />
//       )}

//       {openUnapproveDialog && (
//         <UnapproveDialog
//           openDialog={openUnapproveDialog}
//           handleClose={handleCloseUnapproveDialog}
//           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
//           data={differenceData[selectedIndex] || null}
//           api={api}
//           eventId={selectedEventId}
//           onReject={() => handleReject(selectedEventId, selectedIndex)}
//         />
//       )}

//       {openHoldtillDialog && (
//         <HoldtillDialog
//           openDialog={openHoldtillDialog}
//           handleClose={handleCloseHoldtillDialog}
//           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
//           data={differenceData[selectedIndex] || null}
//           api={{ holdEventChange }}
//           eventId={selectedEventId}
//           holdDate={holdTillDate} // Pass the current hold date to the dialog
//           onHold={(newHoldDate) => handleHoldDate(newHoldDate, selectedIndex)} // Update the hold date in the parent
//         />
//       )}
//     </Box>
//   );
// };

// ConfigRenderer.propTypes = {
//   data: PropTypes.object.isRequired,
//   api: PropTypes.any,
// };

// export default ConfigRenderer;


// // import React, { useState, useContext } from 'react';
// // import PropTypes from 'prop-types';
// // import {
// //   Box,
// //   IconButton,
// //   Accordion,
// //   AccordionSummary,
// //   AccordionDetails,
// //   Typography,
// //   Dialog,
// //   DialogActions,
// //   DialogContent,
// //   DialogTitle,
// //   useMediaQuery,
// //   useTheme,
// //   Button,
// //   Divider,
// //   Stack,
// //   Menu,
// //   MenuItem,
// //   Tooltip,
// //   ListItemIcon,
// //   ListItemText,
// // } from '@mui/material';
// // import SourceIcon from '@mui/icons-material/Source';
// // import VisibilityIcon from '@mui/icons-material/Visibility';
// // import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import ExpandAllIcon from '@mui/icons-material/UnfoldMore';
// // import CollapseAllIcon from '@mui/icons-material/UnfoldLess';
// // import MoreVertIcon from '@mui/icons-material/MoreVert'; // Import MoreVertIcon
// // import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// // import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// // import BlockIcon from '@mui/icons-material/Block';
// // import { useNavigate } from 'react-router-dom';
// // import ConfigDialog from './ConfigDialog';
// // import { UserContext } from '../../../context/UserContext';
// // import {
// //   getEventsDifferenceData,
// //   approveEventChange,
// //   unApproveEventChange,
// //   holdEventChange,
// // } from '../../../API/S3/ConfigEvents';
// // import ApproveDialog from './ApproveDialog';
// // import UnapproveDialog from './UnapproveDialog';
// // import HoldtillDialog from './HoldTillDialog';
// // import { styled, alpha } from '@mui/material/styles';

// // // StyledMenu Component for customizing the Menu appearance
// // const StyledMenu = styled((props) => (
// //   <Menu
// //     elevation={0}
// //     anchorOrigin={{
// //       vertical: 'bottom',
// //       horizontal: 'right',
// //     }}
// //     transformOrigin={{
// //       vertical: 'top',
// //       horizontal: 'right',
// //     }}
// //     {...props}
// //   />
// // ))(({ theme }) => ({
// //   '& .MuiPaper-root': {
// //     borderRadius: 6,
// //     marginTop: theme.spacing(1),
// //     minWidth: 180,
// //     color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
// //     boxShadow:
// //       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
// //     '& .MuiMenu-list': {
// //       padding: '4px 0',
// //     },
// //     '& .MuiMenuItem-root': {
// //       '& .MuiSvgIcon-root': {
// //         fontSize: 18,
// //         color: theme.palette.text.secondary,
// //         marginRight: theme.spacing(1.5),
// //       },
// //       '&:active': {
// //         backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
// //       },
// //     },
// //   },
// // }));

// // // Utility function to decode Base64 strings
// // const decodeBase64 = (str) => {
// //   try {
// //     return atob(str);
// //   } catch (error) {
// //     console.error('Base64 decoding failed', error);
// //     return str;
// //   }
// // };

// // // Utility function to generate HTML diff
// // const generateHtmlDiff = (diffText) => {
// //   let diffHtml = `
// //     <style type="text/css">
// //       .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
// //       .diff div:hover {background-color:#ffc;}
// //       .diff .control {background-color: #eaf2f5; color: #999999;}
// //       .diff .insert {background-color: #ddffdd; color: #000000;}
// //       .diff .delete {background-color: #ffdddd; color: #000000;}
// //     </style>
// //     <div class="diff">
// //   `;

// //   diffText.split('\n').forEach((line) => {
// //     if (line.startsWith('@@')) {
// //       diffHtml += `<div class="control">${line}</div>`;
// //     } else if (line.startsWith('+++') || line.startsWith('+')) {
// //       diffHtml += `<div class="insert">${line}</div>`;
// //     } else if (line.startsWith('---') || line.startsWith('-')) {
// //       diffHtml += `<div class="delete">${line}</div>`;
// //     } else {
// //       diffHtml += `<div>${line}</div>`;
// //     }
// //   });

// //   diffHtml += '</div>';
// //   return diffHtml;
// // };

// // const ConfigRenderer = ({ data, api }) => {
// //   const uniqueIdentifier = data?.r_dvc_unique_identifier;
// //   const dvcName = data?.network_device?.l_dvc_typ_name;
// //   const ipAddress = data?.network_device?.r_dvc_ip_address;
// //   const serialNumber = data?.network_device?.r_dvc_serial_number;
// //   const locationName = data?.location?.r_location_name;
// //   const navigate = useNavigate();
// //   const { customerId } = useContext(UserContext);

// //   // State variables
// //   const [openChonkyDialog, setOpenChonkyDialog] = useState(false);
// //   const [differenceData, setDifferenceData] = useState(null);
// //   const [loading, setLoading] = useState(false);
// //   const [totalItems, setTotalItems] = useState(null);
// //   const [error, setError] = useState(null);
// //   const [openFileDiffDialog, setOpenFileDiffDialog] = useState(false);
// //   const [decodedDiffs, setDecodedDiffs] = useState([]);
// //   const [expanded, setExpanded] = useState([]);
// //   const [approveError, setApproveError] = useState([]);
// //   const [unApproveError, setUnApproveError] = useState([]);
// //   const [holdError, setHoldError] = useState([]);
// //   const [successMessage, setSuccessMessage] = useState([]);
// //   const [allExpanded, setAllExpanded] = useState(false);

// //   // New state variables for Action Menu and Dialogs
// //   const [anchorEl, setAnchorEl] = useState(null);
// //   const [selectedEventId, setSelectedEventId] = useState(null);
// //   const [selectedIndex, setSelectedIndex] = useState(null);
// //   const [openApproveDialog, setOpenApproveDialog] = useState(false);
// //   const [openUnapproveDialog, setOpenUnapproveDialog] = useState(false);
// //   const [openHoldtillDialog, setOpenHoldtillDialog] = useState(false);
// //   const [dialogOpen, setDialogOpen] = useState(false);

// //   const theme = useTheme();
// //   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

// //   // Handlers to open/close ConfigDialog
// //   const handleClickOpen = () => {
// //     setOpenChonkyDialog(true);
// //   };

// //   const handleCloseChonkyDialog = () => {
// //     navigate('/dashboard/config-change-backup-table');
// //     setOpenChonkyDialog(false);
// //   };

// //   // Payload for fetching data
// //   const payload = {
// //     startRow: 0,
// //     endRow: 200,
// //   };

// //   // Handler to open FileDiff Dialog
// //   const handleOpenFileDiffDialog = () => {
// //     setOpenFileDiffDialog(true);
// //     setLoading(true);
// //     setError(null);

// //     getEventsDifferenceData(customerId, uniqueIdentifier, payload)
// //       .then((response) => {
// //         const deviceData = response?.data?.device_data || [];
// //         const totalItems = response?.data?.total || 0;

// //         setTotalItems(totalItems);

// //         if (totalItems === 0) {
// //           setError('No data available.');
// //         } else {
// //           setDifferenceData(deviceData);
// //         }
// //         console.log('difference data', differenceData);

// //         const decodedDiffs = deviceData.map((item) => {
// //           if (!item.r_file_difference) {
// //             return 'No file difference available';
// //           }

// //           const decoded = decodeBase64(item.r_file_difference);
// //           return generateHtmlDiff(decoded);
// //         });

// //         setDecodedDiffs(decodedDiffs);
// //         setLoading(false);
// //         setExpanded(deviceData.map((_, idx) => idx));
// //       })
// //       .catch((error) => {
// //         console.error('Error fetching data:', error);
// //         setError('Error fetching data');
// //         setLoading(false);
// //       });
// //   };

// //   const handleCloseFileDiffDialog = () => {
// //     setOpenFileDiffDialog(false);
// //     setApproveError([]);
// //     setUnApproveError([]);
// //     setSuccessMessage([]);
// //   };

// //   // Handlers for expanding/collapsing all accordions
// //   const handleToggleAll = () => {
// //     const newExpanded = !allExpanded ? differenceData.map((_, index) => index) : [];
// //     setExpanded(newExpanded);
// //     setAllExpanded((prev) => !prev);
// //   };

// //   const handleExpandAll = () => {
// //     setExpanded(differenceData.map((_, index) => index));
// //   };

// //   const handleCollapseAll = () => {
// //     setExpanded([]); // Collapse all
// //   };

// //   // Handler for individual accordion toggle
// //   const handleAccordionToggle = (index) => {
// //     if (!dialogOpen) {
// //       // Only allow toggling if no dialog is open
// //       const newExpanded = expanded.includes(index) ? expanded.filter((item) => item !== index) : [...expanded, index];
// //       setExpanded(newExpanded);
// //     }
// //   };

// //   // Handlers for approving/unapproving events
// //   const handleApprove = (eventId, index) => {
// //     setApproveError([]);
// //     setSuccessMessage([]);
// //     console.log('Passing Event ID to Approve:', eventId); // Add log to check eventId before API call
// //     if (!eventId) {
// //       console.error('No Event ID to approve');
// //       return;
// //     }

// //     approveEventChange(customerId, eventId)
// //       .then((response) => {
// //         const eventId = differenceData[index]?.cmdb_event_id;
// //         console.log('Response Event ID:', eventId);

// //         const newSuccessMessage = [...successMessage];
// //         newSuccessMessage[index] = 'Event approved successfully!';
// //         setSuccessMessage(newSuccessMessage);
// //         setOpenApproveDialog(false);
// //         setTimeout(() => {
// //           const clearedSuccessMessage = [...successMessage];
// //           clearedSuccessMessage[index] = '';
// //           setSuccessMessage(clearedSuccessMessage);
// //         }, 3000);

// //         handleCloseApproveDialog();
// //       })
// //       .catch((error) => {
// //         console.error('Error approving event:', error);
// //         const newApproveError = [...approveError];
// //         newApproveError[index] = 'Error approving event.';
// //         setApproveError(newApproveError);

// //         handleCloseApproveDialog();
// //       });
// //   };

// //   const handleReject = (eventId, index) => {
// //     setUnApproveError([]); // Clear previous errors
// //     setSuccessMessage([]); // Clear previous success messages
// //     if (!eventId) {
// //       console.error('No Event ID to reject');
// //       return;
// //     }

// //     unApproveEventChange(customerId, eventId)
// //       .then((response) => {
// //         const eventId = differenceData[index]?.cmdb_event_id;
// //         console.log('Response Event ID:', eventId);

// //         const newSuccessMessage = [...successMessage];
// //         newSuccessMessage[index] = 'Event rejected successfully!';
// //         setSuccessMessage(newSuccessMessage);

// //         handleCloseUnapproveDialog();
// //         // Clear success message after 3 seconds
// //         setTimeout(() => {
// //           const clearedSuccessMessage = [...successMessage];
// //           clearedSuccessMessage[index] = '';
// //           setSuccessMessage(clearedSuccessMessage);
// //         }, 3000);
// //       })
// //       .catch((error) => {
// //         console.error('Error rejecting event:', error);
// //         const newUnapproveError = [...unApproveError];
// //         newUnapproveError[index] = 'Error rejecting event.';
// //         setUnApproveError(newUnapproveError);

// //         handleCloseUnapproveDialog();

// //         // Clear error message after 3 seconds
// //         setTimeout(() => {
// //           const clearedUnApproveError = [...unApproveError];
// //           clearedUnApproveError[index] = '';
// //           setUnApproveError(clearedUnApproveError);
// //         }, 3000);
// //       });
// //   };

// //   const handleHold = (eventId, index) => {
// //     setHoldError([]); // Clear previous errors
// //     setSuccessMessage([]); // Clear previous success messages
// //     if (!eventId) {
// //       console.error('No Event ID to hold');
// //       return;
// //     }

// //     holdEventChange(customerId, eventId)
// //       .then((response) => {
// //         const eventId = differenceData[index]?.cmdb_event_id;
// //         console.log('Response Event ID:', eventId);

// //         const newSuccessMessage = [...successMessage];
// //         newSuccessMessage[index] = 'Event put on hold successfully!';
// //         setSuccessMessage(newSuccessMessage);

// //         handleCloseHoldtillDialog();
// //         // Clear success message after 3 seconds
// //         setTimeout(() => {
// //           const clearedSuccessMessage = [...successMessage];
// //           clearedSuccessMessage[index] = '';
// //           setSuccessMessage(clearedSuccessMessage);
// //         }, 3000);
// //       })
// //       .catch((error) => {
// //         console.error('Error putting event on hold:', error);
// //         const newHoldError = [...holdError];
// //         newHoldError[index] = 'Error putting event on hold.';
// //         setHoldError(newHoldError);

// //         handleCloseHoldtillDialog();

// //         // Clear error message after 3 seconds
// //         setTimeout(() => {
// //           const clearedHoldError = [...holdError];
// //           clearedHoldError[index] = '';
// //           setHoldError(clearedHoldError);
// //         }, 3000);
// //       });
// //   };

// //   const handleMenuOpen = (event, eventId, index) => {
// //     setAnchorEl(event.currentTarget);
// //     setSelectedEventId(eventId);
// //     setSelectedIndex(index);
// //   };

// //   const handleMenuClose = () => {
// //     setAnchorEl(null);
// //     setSelectedEventId(null);
// //     setSelectedIndex(null);
// //   };

// //   const handleOpenApproveDialog = (index) => {
// //     console.log('differenceData:', differenceData); // Log to ensure data is present
// //     console.log('Selected index:', index);
// //     const eventId = differenceData[index]?.cmdb_event_id; // Get event ID from data
// //     console.log('Selected Event ID:', eventId); // Log the event ID to ensure it's not null
// //     if (eventId) {
// //       setSelectedEventId(eventId); // Setting event ID in the state
// //       setSelectedIndex(index); // Setting the selected index
// //       setOpenApproveDialog(true); // Open the dialog
// //       setDialogOpen(true);
// //     } else {
// //       console.error('Event ID is undefined for index:', index);
// //     }
// //   };

// //   const handleCloseApproveDialog = () => {
// //     setOpenApproveDialog(false);
// //     setDialogOpen(true);
// //   };

// //   const handleOpenUnapproveDialog = (index) => {
// //     console.log('differenceData:', differenceData); // Log to ensure data is present
// //     console.log('Selected index:', index);
// //     const eventId = differenceData[index]?.cmdb_event_id; // Get event ID from data
// //     console.log('Selected Event ID:', eventId); // Log the event ID to ensure it's not null
// //     if (eventId) {
// //       setSelectedEventId(eventId); // Setting event ID in the state
// //       setSelectedIndex(index); // Setting the selected index
// //       setOpenUnapproveDialog(true); // Open the dialog
// //       setDialogOpen(true);
// //     } else {
// //       console.error('Event ID is undefined for index:', index);
// //     }
// //   };

// //   const handleCloseUnapproveDialog = () => {
// //     setOpenUnapproveDialog(false);
// //     setDialogOpen(true);
// //   };

// //   const handleOpenHoldtillDialog = (index) => {
// //     console.log('differenceData:', differenceData); // Log to ensure data is present
// //     console.log('Selected index:', index);
// //     const eventId = differenceData[index]?.cmdb_event_id; // Get event ID from data
// //     console.log('Selected Event ID:', eventId); // Log the event ID to ensure it's not null
// //     if (eventId) {
// //       setSelectedEventId(eventId); // Setting event ID in the state
// //       setSelectedIndex(index); // Setting the selected index
// //       setOpenHoldtillDialog(true); // Open the dialog
// //       setDialogOpen(true);
// //     } else {
// //       console.error('Event ID is undefined for index:', index);
// //     }
// //   };

// //   const handleCloseHoldtillDialog = () => {
// //     setOpenHoldtillDialog(false);
// //     setDialogOpen(true);
// //   };

// //   return (
// //     <Box sx={{ textAlign: 'center' }}>
// //       <IconButton onClick={handleClickOpen} aria-label="Open Config Dialog">
// //         <SourceIcon />
// //       </IconButton>

// //       {openChonkyDialog && (
// //         <ConfigDialog openDialog={openChonkyDialog} data={data} handleClose={handleCloseChonkyDialog} />
// //       )}

// //       <IconButton onClick={handleOpenFileDiffDialog} sx={{ marginLeft: '10px' }} aria-label="View File Differences">
// //         <VisibilityIcon />
// //       </IconButton>

// //       <Dialog
// //         open={openFileDiffDialog}
// //         onClose={handleCloseFileDiffDialog}
// //         fullScreen={fullScreen}
// //         maxWidth="md" // Adjusted for compact size
// //         fullWidth
// //         PaperProps={{
// //           style: {
// //             maxHeight: '80vh', // Ensures the Dialog doesn't exceed 80% of the viewport height
// //             width: fullScreen ? '100%' : '80%',
// //             margin: 'auto',
// //           },
// //         }}
// //       >
// //         <DialogTitle>
// //           <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
// //             {/* Display Device Information */}
// //             <Typography
// //               variant="subtitle1"
// //               color="black"
// //               sx={{ fontWeight: 'bold', bgcolor: 'grey.200', padding: 1, borderRadius: 1 }}
// //             >
// //               {dvcName} - {ipAddress} - {serialNumber} - {locationName} - Total Events: {totalItems}
// //             </Typography>
// //             <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
// //               <IconButton onClick={handleToggleAll} aria-label={allExpanded ? 'Collapse All' : 'Expand All'}>
// //                 {dialogOpen || allExpanded ? <CollapseAllIcon /> : <ExpandAllIcon />}
// //               </IconButton>
// //             </Box>
// //           </Stack>
// //         </DialogTitle>
// //         <Divider />
// //         <DialogContent
// //           sx={{
// //             padding: 2,
// //             overflowY: 'auto', // Allows scrolling if content exceeds maxHeight
// //           }}
// //         >
// //           {loading ? (
// //             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
// //               <Typography>Loading...</Typography>
// //             </Box>
// //           ) : error ? (
// //             <Typography color="error">{error}</Typography>
// //           ) : differenceData && Array.isArray(differenceData) && differenceData.length > 0 ? (
// //             <Box>
// //               {differenceData.map((item, index) => (
// //                 <Box
// //                   key={index}
// //                   sx={{
// //                     mb: 2,
// //                     border: '1px solid #ccc',
// //                     borderRadius: 1,
// //                     overflow: 'hidden',
// //                   }}
// //                 >
// //                   <Accordion
// //                     expanded={dialogOpen || expanded.includes(index)}
// //                     onChange={() => handleAccordionToggle(index)}
// //                     sx={{
// //                       backgroundColor: 'grey.200', // Set the background color to grey
// //                       borderRadius: 1, // Optional: To give rounded corners
// //                     }}
// //                   >
// //                     <AccordionSummary
// //                       expandIcon={<ExpandMoreIcon />}
// //                       aria-controls={`panel-content-${index}`}
// //                       id={`panel-header-${index}`}
// //                       sx={{
// //                         // Reduce padding and height
// //                         minHeight: 20,
// //                         padding: '0 0.0001px',
// //                         '& .MuiAccordionSummary-content': {
// //                           margin: '12px 0',
                          
// //                         },
// //                         // Reposition expand icon to the left
// //                         flexDirection: 'row-reverse',
// //                         '& .MuiAccordionSummary-expandIconWrapper': {
// //                           transform: 'rotate(0deg)',
// //                           marginLeft: '1px',
// //                           marginRight: '0px',
// //                           p:2
// //                         },
// //                         '& .MuiAccordionSummary-content': {
// //                           marginLeft: '0px',
// //                         },
// //                       }}
// //                     >
// //                       <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexGrow: 1  }}>
// //                         <Typography variant="subtitle1">{item.g_created_at}</Typography>
// //                         <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
// //                           {/* MoreVertIcon for Action Menu */}
// //                           <IconButton
// //                             onClick={(e) => {
// //                               e.stopPropagation(); // Prevent accordion toggle
// //                               handleMenuOpen(e, item.cmdb_event_id, index);
// //                             }}
// //                             aria-haspopup="true"
// //                             aria-controls={anchorEl ? 'action-menu' : undefined}
// //                             aria-expanded={anchorEl ? 'true' : undefined}
// //                             aria-label="Actions"
// //                             size="small"
// //                           >
// //                             <MoreVertIcon />
// //                           </IconButton>

// //                           {/* Action Menu */}
// //                           <StyledMenu
// //                             id="action-menu"
// //                             anchorEl={anchorEl}
// //                             open={
// //                               Boolean(anchorEl) && selectedEventId === item.cmdb_event_id && selectedIndex === index
// //                             }
// //                             onClose={handleMenuClose}
// //                             MenuListProps={{
// //                               'aria-labelledby': 'action-button',
// //                             }}
// //                           >
// //                             <MenuItem
// //                               onClick={() => {
// //                                 handleOpenApproveDialog(index);
// //                               }}
// //                             >
// //                               <ListItemIcon>
// //                                 <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
// //                               </ListItemIcon>
// //                               <ListItemText primary="Approve" />
// //                             </MenuItem>
// //                             <MenuItem
// //                               onClick={() => {
// //                                 handleOpenUnapproveDialog(index);
// //                               }}
// //                             >
// //                               <ListItemIcon>
// //                                 <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
// //                               </ListItemIcon>
// //                               <ListItemText primary="Un-Approve" />
// //                             </MenuItem>
// //                             <MenuItem
// //                               onClick={() => {
// //                                 handleOpenHoldtillDialog(index);
// //                               }}
// //                             >
// //                               <ListItemIcon>
// //                                 <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
// //                               </ListItemIcon>
// //                               <ListItemText primary="Hold Until" />
// //                             </MenuItem>
// //                           </StyledMenu>
// //                         </Box>
// //                       </Box>
// //                     </AccordionSummary>
// //                     <AccordionDetails
// //                       sx={{
// //                         padding: '8px 16px',
// //                         // Reduce height and add scroll if content overflows
// //                         maxHeight: '200px',
// //                         overflowY: 'auto',
// //                         backgroundColor: '#f9f9f9',
// //                       }}
// //                     >
// //                       <Box
// //                         sx={{
// //                           fontSize: '12px',
// //                         }}
// //                         dangerouslySetInnerHTML={{ __html: decodedDiffs[index] }}
// //                       />
// //                       {/* Display Messages */}
// //                       {approveError[index] && (
// //                         <Typography color="error" sx={{ mt: 1 }}>
// //                           {approveError[index]}
// //                         </Typography>
// //                       )}
// //                       {unApproveError[index] && (
// //                         <Typography color="error" sx={{ mt: 1 }}>
// //                           {unApproveError[index]}
// //                         </Typography>
// //                       )}
// //                       {successMessage[index] && (
// //                         <Typography color="primary" sx={{ mt: 1 }}>
// //                           {successMessage[index]}
// //                         </Typography>
// //                       )}
// //                     </AccordionDetails>
// //                   </Accordion>
// //                 </Box>
// //               ))}
// //             </Box>
// //           ) : (
// //             <Typography>No differences available</Typography>
// //           )}
// //         </DialogContent>
// //         <DialogActions>
// //           <Button onClick={handleCloseFileDiffDialog} variant="contained" color="primary">
// //             Close
// //           </Button>
// //         </DialogActions>
// //       </Dialog>
// //       {/* Approve Dialog */}
// //       {openApproveDialog && (
// //         <ApproveDialog
// //           openDialog={openApproveDialog}
// //           handleClose={handleCloseApproveDialog}
// //           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
// //           data={differenceData[selectedIndex] || null}
// //           api={api}
// //           eventId={selectedEventId}
// //           onApprove={() => handleApprove(selectedEventId, selectedIndex)} // Pass eventId and selectedIndex to handleApprove
// //         />
// //       )}

// //       {/* Unapprove (Reject) Dialog */}
// //       {openUnapproveDialog && (
// //         <UnapproveDialog
// //           openDialog={openUnapproveDialog}
// //           handleClose={handleCloseUnapproveDialog}
// //           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
// //           data={differenceData[selectedIndex] || null}
// //           api={api}
// //           eventId={selectedEventId}
// //           onReject={() => handleReject(selectedEventId, selectedIndex)}
// //         />
// //       )}

// //       {/* Hold Until Dialog */}
// //       {openHoldtillDialog && (
// //         <HoldtillDialog
// //           openDialog={openHoldtillDialog}
// //           handleClose={handleCloseHoldtillDialog}
// //           cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
// //           data={differenceData[selectedIndex] || null}
// //           api={{ holdEventChange }} // Pass the API function here
// //           eventId={selectedEventId}
// //           onHold={() => handleHold(selectedEventId, selectedIndex, holdDate)}
// //         />
// //       )}
// //     </Box>
// //   );
// // };

// // ConfigRenderer.propTypes = {
// //   data: PropTypes.object.isRequired,
// //   api: PropTypes.any, // Adjust the type as per your API prop
// // };

// // export default ConfigRenderer;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import UndoIcon from '@mui/icons-material/Undo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandAllIcon from '@mui/icons-material/UnfoldMore';
import CollapseAllIcon from '@mui/icons-material/UnfoldLess';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Import MoreVertIcon
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import ConfigDialog from './ConfigDialog';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context/UserContext';
import {
  getEventsDifferenceData,
  approveEventChange,
  unApproveEventChange,
  holdEventChange,
  rollbackEventChange,
} from '../../../API/S3/ConfigEvents';
import ApproveDialog from './ApproveDialog';
import UnApproveDialog from './UnApproveDialog';
import HoldtillDialog from './HoldTillDialog';
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const decodeBase64 = (str) => {
  try {
    return atob(str);
  } catch (error) {
    console.error('Base64 decoding failed', error);
    return str;
  }
};

const generateHtmlDiff = (diffText) => {
  let diffHtml = `
    <style type="text/css">
      .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
      .diff div:hover {background-color:#ffc;}
      .diff .control {background-color: #eaf2f5; color: #999999;}
      .diff .insert {background-color: #ddffdd; color: #000000;}
      .diff .delete {background-color: #ffdddd; color: #000000;}
    </style>
    <div class="diff">
  `;

  diffText.split('\n').forEach((line) => {
    if (line.startsWith('@@')) {
      diffHtml += `<div class="control">${line}</div>`;
    } else if (line.startsWith('+++') || line.startsWith('+')) {
      diffHtml += `<div class="insert">${line}</div>`;
    } else if (line.startsWith('---') || line.startsWith('-')) {
      diffHtml += `<div class="delete">${line}</div>`;
    } else {
      diffHtml += `<div>${line}</div>`;
    }
  });

  diffHtml += '</div>';
  return diffHtml;
};

const ConfigRenderer = ({ data, api }) => {
  const uniqueIdentifier = data?.r_dvc_unique_identifier;
  const dvcName = data?.network_device?.l_dvc_typ_name;
  const ipAddress = data?.network_device?.r_dvc_ip_address;
  const serialNumber = data?.network_device?.r_dvc_serial_number;
  const locationName = data?.location?.r_location_name;

  const navigate = useNavigate();
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [openChonkyDialog, setOpenChonkyDialog] = useState(false);
  const [differenceData, setDifferenceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [error, setError] = useState(null);
  const [openFileDiffDialog, setOpenFileDiffDialog] = useState(false);
  const [decodedDiffs, setDecodedDiffs] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openUnApproveDialog, setOpenUnApproveDialog] = useState(false);
  const [openHoldtillDialog, setOpenHoldtillDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [holdTillDate, setHoldTillDate] = useState('');
  const [actionTaken, setActionTaken] = useState(false); // Track if action was taken
  const [initialApproveStatus, setInitialApproveStatus] = useState({}); // To store initial r_approve_status of each item

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpenChonkyDialog(true);
  };

  const handleCloseChonkyDialog = () => {
    navigate('/dashboard/config-change-backup-table');
    setOpenChonkyDialog(false);
  };

  const payload = {
    startRow: 0,
    endRow: 200,
  };

  const handleOpenFileDiffDialog = async () => {
    setOpenFileDiffDialog(true);
    setLoading(true);
    setError(null);

    try {
      const response = await getEventsDifferenceData(customerId, uniqueIdentifier, payload);
      const deviceData = response?.data?.device_data || [];
      const totalItems = response?.data?.total || 0;

      setTotalItems(totalItems);

      if (totalItems === 0) {
        setError('No data available.');
        setDifferenceData([]);
      } else {
        setDifferenceData(deviceData);

        const decodedDiffs = deviceData.map((item) => {
          if (!item.r_file_difference) {
            return 'No file difference available';
          }
          const decoded = decodeBase64(item.r_file_difference);
          return generateHtmlDiff(decoded);
        });

        setDecodedDiffs(decodedDiffs);
        setExpanded(deviceData.map((_, idx) => idx));
      }

      console.log('Fetched differenceData from getEventsDifferenceData:', deviceData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseFileDiffDialog = () => {
    setOpenFileDiffDialog(false);
  };

  const handleToggleAll = () => {
    if (!allExpanded) {
      const allIndexes = differenceData.map((_, index) => index);
      setExpanded(allIndexes);
    } else {
      setExpanded([]);
    }
    setAllExpanded(!allExpanded);
  };

  const handleAccordionToggle = (index) => {
    if (!dialogOpen) {
      setExpanded((prevExpanded) => {
        return prevExpanded.includes(index) ? prevExpanded.filter((item) => item !== index) : [...prevExpanded, index];
      });
    }
  };

  const handleRollback = async (eventId, index) => {
    console.log('Passing Event ID to Rollback:', eventId);
  
    if (initialApproveStatus[index] !== 0) {
      console.error('Rollback not allowed: Initial approve status was not 0');
      return;
    }
  
    if (!eventId) {
      console.error('No Event ID to rollback');
      return;
    }
  
    setLoading(true); 
  
    try {
      const response = await rollbackEventChange(customerId, eventId);
      
      const successMessage = response.message || 'The event rolled back successfully.';
      enqueueSnackbar(successMessage, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

      setDifferenceData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          r_approve_status: 0,
        };
        return updatedData;
      });
      setActionTaken(false);
      } catch (error) {
      console.error('Error rolling back event:', error);
      setError('Error rolling back event');
    } finally {
      setLoading(false); 
    }
  };
  

  const handleApprove = async (eventId, index) => {
    console.log('Passing Event ID to Approve:', eventId);

    if (!eventId) {
      console.error('No Event ID to approve');
      return;
    }

    setLoading(true);
    try {
      await approveEventChange(customerId, eventId);
      enqueueSnackbar('The event has been approved successfully.', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });

      setDifferenceData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          r_approve_status: 1,
        };
        return updatedData;
      });
    } catch (error) {
      console.error('Error approving event:', error);
      setError('Error approving event');
    } finally {
      setLoading(false);
      handleCloseApproveDialog();
    }
  };

  const handleReject = async (eventId, index) => {
    console.log('Passing Event ID to Reject:', eventId);

    if (!eventId) {
      console.error('No Event ID to reject');
      return;
    }

    setLoading(true);
    try {
      await unApproveEventChange(customerId, eventId);
      enqueueSnackbar('The event has been Un-approved successfully.', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });

      setDifferenceData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          r_approve_status: 2,
        };
        return updatedData;
      });
    } catch (error) {
      console.error('Error rejecting event:', error);
    } finally {
      setLoading(false);
      handleCloseUnApproveDialog();
    }
  };

  const handleMenuOpen = (event, eventId, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventId(eventId);
    setSelectedIndex(index);
  };

  const handleMenuClose = () => {
    console.log('here');

    setAnchorEl(null);
    setSelectedEventId(null);
    setSelectedIndex(null);
  };

  const handleOpenApproveDialog = (index) => {
    const eventId = differenceData[index]?.cmdb_event_id;
    if (eventId) {
      setSelectedEventId(eventId);
      setSelectedIndex(index);
      setOpenApproveDialog(true);
      setActionTaken(true); // Mark that an action was taken
      setInitialApproveStatus((prevState) => ({
        ...prevState,
        [index]: differenceData[index]?.r_approve_status,
      }));
    } else {
      console.error('Event ID is undefined for index:', index);
    }
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  };

  const handleOpenUnApproveDialog = (index) => {
    const eventId = differenceData[index]?.cmdb_event_id;
    if (eventId) {
      setSelectedEventId(eventId);
      setSelectedIndex(index);
      setOpenUnApproveDialog(true);
      setActionTaken(true); // Mark that an action was taken
      setInitialApproveStatus((prevState) => ({
        ...prevState,
        [index]: differenceData[index]?.r_approve_status,
      }));
    } else {
      console.error('Event ID is undefined for index:', index);
    }
  };

  const handleCloseUnApproveDialog = () => {
    setOpenUnApproveDialog(false);
  };

  const handleOpenHoldtillDialog = (index) => {
    console.log('differenceData:', differenceData);
    console.log('Selected index:', index);
    const eventId = differenceData[index]?.cmdb_event_id;
    console.log('Selected Event ID:', eventId);
    if (eventId) {
      setSelectedEventId(eventId);
      setSelectedIndex(index);
      setOpenHoldtillDialog(true);
      setDialogOpen(true);
      setActionTaken(true); // Mark that an action was taken
      setInitialApproveStatus((prevState) => ({
        ...prevState,
        [index]: differenceData[index]?.r_approve_status,
      }));
    } else {
      console.error('Event ID is undefined for index:', index);
    }
  };

  const handleCloseHoldtillDialog = () => {
    setOpenHoldtillDialog(false);
  };

  const handleHoldDate = (holdDate, index) => {
    const currentHoldDate = differenceData[index]?.r_config_change_till;
    setHoldTillDate(currentHoldDate);
    setDifferenceData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        r_approve_status: 3,
        r_config_change_till: holdDate,
      };
      return updatedData;
    });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <IconButton onClick={handleClickOpen} aria-label="Open Config Dialog">
        <SourceIcon />
      </IconButton>

      {openChonkyDialog && (
        <ConfigDialog openDialog={openChonkyDialog} data={data} handleClose={handleCloseChonkyDialog} />
      )}

      <IconButton onClick={handleOpenFileDiffDialog} sx={{ marginLeft: '10px' }} aria-label="View File Differences">
        <VisibilityIcon />
      </IconButton>

      <Dialog
        open={openFileDiffDialog}
        onClose={handleCloseFileDiffDialog}
        fullScreen={fullScreen}
        maxWidth="xxl"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: '95vh',
            width: fullScreen ? '100%' : '80%',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography
              variant="subtitle1"
              color="black"
              sx={{ fontWeight: 'bold', bgcolor: 'grey.200', padding: 1, borderRadius: 1 }}
            >
              {dvcName} - {ipAddress} - {serialNumber} - {locationName} - Total Events: {totalItems}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconButton onClick={handleToggleAll} aria-label={allExpanded ? 'Collapse All' : 'Expand All'}>
                {allExpanded ? <CollapseAllIcon /> : <ExpandAllIcon />}
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            padding: 2,
            overflowY: 'auto',
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <Typography>Loading...</Typography>
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : differenceData && Array.isArray(differenceData) && differenceData.length > 0 ? (
            <Box>
              {differenceData.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    border: '1px solid #ccc',
                    borderRadius: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Accordion
                    expanded={expanded.includes(index)}
                    onChange={() => handleAccordionToggle(index)}
                    sx={{
                      backgroundColor:
                        differenceData[index]?.r_approve_status === 1
                          ? 'lightgreen'
                          : differenceData[index]?.r_approve_status === 2
                          ? '#ffcccb'
                          : differenceData[index]?.r_approve_status === 3
                          ? '#ffffe0'
                          : 'grey.200',
                      borderRadius: 1,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            transform: expanded.includes(index) ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out',
                          }}
                        />
                      }
                      aria-controls={`panel-content-${index}`}
                      id={`panel-header-${index}`}
                      sx={{
                        minHeight: 20,
                        padding: '0 0.0001px',
                        '& .MuiAccordionSummary-content': {
                          margin: '12px 0',
                          alignItems: 'center',
                          width: '100%',
                          display: 'flex',
                        },
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper': {
                          transform: 'rotate(0deg)',
                          marginLeft: '1px',
                          marginRight: '0px',
                          p: 2,
                        },
                        '& .MuiAccordionSummary-content': {
                          marginLeft: '0px',
                          margin: '12px 0',
                          alignItems: 'center',
                          width: '100%',
                          display: 'flex',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                        <Typography variant="subtitle1">{item.g_created_at}</Typography>
                      </Box>

                      <Box sx={{ flex: 5, textAlign: 'center' }}>
                        <Typography variant="subtitle1">
                          {differenceData[index]?.r_approve_status === 1
                            ? `This change is approved, do you want to revert?`
                            : differenceData[index]?.r_approve_status === 2
                            ? `This change is un-approved, do you want to revert?`
                            : differenceData[index]?.r_approve_status === 3
                            ? `This change is on hold till ${
                                differenceData[index]?.r_config_change_till || holdTillDate || 'N/A'
                              }.`
                            : ''}
                        </Typography>
                      </Box>

                      <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>

                        {/* Rollback icon next to three dots if actionTaken is true */}
                        {actionTaken && initialApproveStatus[index] === 0 && (
                            <Tooltip title="Undo">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRollback(differenceData[index].cmdb_event_id, index);
                            }}
                            aria-label="Rollback"
                            size="small"
                          >
                            <UndoIcon fontSize="small" style={{ color: 'blue' }} />
                          </IconButton>
                          </Tooltip>
                        )}

                        {/* Three dot menu */}
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMenuOpen(e, item.cmdb_event_id, index);
                          }}
                          aria-haspopup="true"
                          aria-controls={anchorEl ? 'action-menu' : undefined}
                          aria-expanded={anchorEl ? 'true' : undefined}
                          aria-label="Actions"
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <StyledMenu
                          id="action-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedEventId === item.cmdb_event_id && selectedIndex === index}
                          onClose={handleMenuClose}
                          MenuListProps={{
                            'aria-labelledby': 'action-button',
                          }}
                        >
                          {/* Show all three actions */}

                          {differenceData[index]?.r_approve_status === 0 && (
                            <>
                              <MenuItem onClick={() => handleOpenApproveDialog(index)}>
                                <ListItemIcon>
                                  <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
                                </ListItemIcon>
                                <ListItemText primary="Approve" />
                              </MenuItem>

                              <MenuItem onClick={() => handleOpenUnApproveDialog(index)}>
                                <ListItemIcon>
                                  <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemText primary="Un-Approve" />
                              </MenuItem>

                              <MenuItem onClick={() => handleOpenHoldtillDialog(index)}>
                                <ListItemIcon>
                                  <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
                                </ListItemIcon>
                                <ListItemText primary="Hold Until" />
                              </MenuItem>
                            </>
                          )}

                          {/* Show all three actions but only Un-Approve is clickable */}
                          {differenceData[index]?.r_approve_status === 1 && (
                            <>
                              <MenuItem disabled>
                                <ListItemIcon>
                                  <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
                                </ListItemIcon>
                                <ListItemText primary="Approve" />
                              </MenuItem>

                              <MenuItem onClick={() => handleOpenUnApproveDialog(index)}>
                                <ListItemIcon>
                                  <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemText primary="Un-Approve" />
                              </MenuItem>

                              <MenuItem disabled>
                                <ListItemIcon>
                                  <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
                                </ListItemIcon>
                                <ListItemText primary="Hold Until" />
                              </MenuItem>
                            </>
                          )}

                          {/* Show all three actions but only Approve is clickable */}
                          {differenceData[index]?.r_approve_status === 2 && (
                            <>
                              <MenuItem onClick={() => handleOpenApproveDialog(index)}>
                                <ListItemIcon>
                                  <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
                                </ListItemIcon>
                                <ListItemText primary="Approve" />
                              </MenuItem>

                              <MenuItem disabled>
                                <ListItemIcon>
                                  <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemText primary="Un-Approve" />
                              </MenuItem>

                              <MenuItem disabled>
                                <ListItemIcon>
                                  <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
                                </ListItemIcon>
                                <ListItemText primary="Hold Until" />
                              </MenuItem>
                            </>
                          )}

                          {/* Show all three actions but only Hold Until is clickable */}
                          {differenceData[index]?.r_approve_status === 3 && (
                            <>
                              <MenuItem disabled>
                                <ListItemIcon>
                                  <CheckCircleOutlinedIcon fontSize="small" style={{ color: 'green' }} />
                                </ListItemIcon>
                                <ListItemText primary="Approve" />
                              </MenuItem>

                              <MenuItem disabled>
                                <ListItemIcon>
                                  <CancelOutlinedIcon fontSize="small" style={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemText primary="Un-Approve" />
                              </MenuItem>

                              <MenuItem onClick={() => handleOpenHoldtillDialog(index)}>
                                <ListItemIcon>
                                  <BlockIcon fontSize="small" style={{ color: 'yellow' }} />
                                </ListItemIcon>
                                <ListItemText primary="Hold Until" />
                              </MenuItem>
                            </>
                          )}
                        </StyledMenu>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        padding: '8px 16px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: '12px',
                        }}
                        dangerouslySetInnerHTML={{ __html: decodedDiffs[index] }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No differences available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileDiffDialog} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {openApproveDialog && (
        <ApproveDialog
          openDialog={openApproveDialog}
          handleClose={handleCloseApproveDialog}
          cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
          data={differenceData[selectedIndex] || null}
          api={api}
          eventId={selectedEventId}
          onApprove={() => handleApprove(selectedEventId, selectedIndex)}
        />
      )}

      {openUnApproveDialog && (
        <UnApproveDialog
          openDialog={openUnApproveDialog}
          handleClose={handleCloseUnApproveDialog}
          cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
          data={differenceData[selectedIndex] || null}
          api={api}
          eventId={selectedEventId}
          onReject={() => handleReject(selectedEventId, selectedIndex)}
        />
      )}

      {openHoldtillDialog && (
        <HoldtillDialog
          openDialog={openHoldtillDialog}
          handleClose={handleCloseHoldtillDialog}
          cmdbGroupSummaryEvent={differenceData[selectedIndex]?.latest_group_summary || null}
          data={differenceData[selectedIndex] || null}
          api={{ holdEventChange }}
          eventId={selectedEventId}
          holdDate={holdTillDate} // Pass the current hold date to the dialog
          onHold={(newHoldDate) => handleHoldDate(newHoldDate, selectedIndex)} // Update the hold date in the parent
        />
      )}
    </Box>
  );
};

ConfigRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  api: PropTypes.any,
};

export default ConfigRenderer;