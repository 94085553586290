// @mui
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DevicesUpIcon, LinksUpIcon, LinksDownIcon, EndPointIcon } from '../../../icons';

const useStyles = makeStyles({
  boxPadding: {
    padding: '12px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

const InventoryCards = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item lg={12 / 5} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="small" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px','@media (max-width: 768px)': { mr: 0.5 }, }}>
                <DevicesUpIcon />
              </IconButton>
              <Box>
                <Typography variant="subtitle1" component="Box">
                  Devices up
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="Box">
                  250
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={12 / 5} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="small" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px',mr: 1  }}>
                <DevicesUpIcon />
              </IconButton>
              <Box>
                <Typography variant="subtitle1" component="Box">
                  Devices down
                </Typography>
                <Typography sx={{ color: 'red' }} variant="subtitle1" component="Box">
                  250
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={12 / 5} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="small" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px', mr: 1  }}>
                <LinksUpIcon />
              </IconButton>
              <Box>
                <Typography variant="subtitle1" component="Box">
                  Links up
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="Box">
                  250
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={12 / 5} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="small" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px', mr: 1  }}>
                <LinksDownIcon />
              </IconButton>
              <Box>
                <Typography variant="subtitle1" component="Box">
                  Links down
                </Typography>
                <Typography sx={{ color: 'red' }} variant="subtitle1" component="Box">
                  250
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={12 / 5} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="small" sx={{ color: 'yellow', background: '#ffff7b54', borderRadius: '12px', mr: 1  }}>
                <EndPointIcon />
              </IconButton>
              <Box>
                <Typography variant="subtitle1" component="Box">
                  Endpoints
                </Typography>
                <Typography sx={{ color: 'yellow' }} variant="subtitle1" component="Box">
                  250
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
export default InventoryCards;
