// import { Visibility } from '@mui/icons-material';
// import { IconButton, Tooltip, Autocomplete, TextField } from '@mui/material';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
// import { useContext, useEffect, useState, useCallback } from 'react';
// import { getRoles } from '../../../API/Roles/roles';
// import { UserContext } from '../../../context/UserContext';
// import ReadPermissionsDialog from './ReadPermissionsDialog';

// // ------------------------------------------------------------------------

// const useStyles = makeStyles({
//   icon: {
//     visibility: 'hidden',
//   },
//   field: {},
//   root: {
//     '&:hover $field $icon': {
//       visibility: 'visible',
//     },
//   },
// });

// // ----------------------------------------------------------------------

// export default function MemberRoleSelector(props) {
//   const classes = useStyles();
//   const { member, roleObject, placeholder, gridApi, roleOptions: initialRoleOptions } = props;
//   const [permissionDialog, setPermissionDialog] = useState(false);
//   const { customerId } = useContext(UserContext);
//   const [roleOptions, setRoleOptions] = useState([]);

//   // ------------------------------------------------------------------------

//   const fetchRoles = async () => {
//     console.log("here");

//     try {
//       const res = await getRoles(customerId);
//       console.log('API Response:', res.data);
//       setRoleOptions(Array.isArray(res.data) ? res.data : []);
//     } catch (error) {
//       console.error('Failed to fetch roles:', error);
//       setRoleOptions([]);
//     }
//   };

//   // ----------------------------------------------------------------------

//   return (
//     <>
//       <Autocomplete
//         fullWidth
//         size="small"
//         autoHighlight
//         disableClearable
//         className={classes.root}
//         options={roleOptions || []}  
//         sx={{ maxWidth: 900, minWidth: 500 }}
//         defaultValue={
//           roleObject || roleOptions.find((item) => item?.uuid === '97ab7ed0-0f0e-4e30-aa4a-d4e134f99481') || ""
//         }
//         onOpen={fetchRoles}  
//         onChange={props.selectRole && ((e, option) => props.selectRole(option, member))}
//         getOptionLabel={(option) => option.role_name || ""}  
//         isOptionEqualToValue={(option, value) => option.role_name === value.role_name}
//         renderOption={(props, option) => <li {...props}>{option.role_name}</li>}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             name="role"
//             label="Select role"
//             placeholder={placeholder}
//             className={classes.field}
//             InputLabelProps={{ shrink: true }}
//             InputProps={{
//               ...params.InputProps,
//               autoComplete: 'new-password',
//               endAdornment: (
//                 <>
//                   {member && (
//                     <Tooltip title={'Read permissions'}>
//                       <IconButton onClick={() => setPermissionDialog(true)} className={classes.icon}>
//                         <Visibility />
//                       </IconButton>
//                     </Tooltip>
//                   )}
//                   {params.InputProps.endAdornment}
//                 </>
//               ),
//             }}
//           />
//         )}
//       />



//       {permissionDialog && (
//         <ReadPermissionsDialog
//           gridApi={gridApi}
//           openDialog={permissionDialog}
//           roleuuid={member.roles?.[0]?.role_id}
//           handleClose={() => setPermissionDialog(false)}
//         />
//       )}
//     </>
//   );
// }

// MemberRoleSelector.propTypes = {
//   member: PropTypes.object,
//   roleObject: PropTypes.object,
//   placeholder: PropTypes.string,
//   gridApi: PropTypes.object,
//   selectRole: PropTypes.func,
//   roleOptions: PropTypes.array,
// };

// import { Visibility } from '@mui/icons-material';
// import { IconButton, Tooltip, Autocomplete, TextField, FormHelperText } from '@mui/material';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
// import { useContext, useState } from 'react';
// import { getRoles } from '../../../API/Roles/roles';
// import { UserContext } from '../../../context/UserContext';
// import ReadPermissionsDialog from './ReadPermissionsDialog';

// // ------------------------------------------------------------------------

// const useStyles = makeStyles({
//   icon: {
//     visibility: 'hidden',
//   },
//   field: {},
//   root: {
//     '&:hover $field $icon': {
//       visibility: 'visible',
//     },
//   },
// });

// // ----------------------------------------------------------------------

// export default function MemberRoleSelector(props) {
//   const classes = useStyles();
//   const { member, roleObject, placeholder, gridApi, roleOptions: initialRoleOptions, error, helperText } = props;
//   const [permissionDialog, setPermissionDialog] = useState(false);
//   const { customerId } = useContext(UserContext);
//   const [roleOptions, setRoleOptions] = useState([]);

//   // ------------------------------------------------------------------------

//   const fetchRoles = async () => {
//     try {
//       const res = await getRoles(customerId);
//       setRoleOptions(Array.isArray(res.data) ? res.data : []);
//     } catch (error) {
//       console.error('Failed to fetch roles:', error);
//       setRoleOptions([]);
//     }
//   };

//   // ----------------------------------------------------------------------

//   return (
//     <>
//       <Autocomplete
//         fullWidth
//         size="small"
//         autoHighlight
//         disableClearable
//         className={classes.root}
//         options={roleOptions || []}
//         sx={{ maxWidth: 600, minWidth: 250 }}
//         defaultValue={
//           roleObject || roleOptions.find((item) => item?.uuid === '97ab7ed0-0f0e-4e30-aa4a-d4e134f99481') || ""
//         }
//         onOpen={fetchRoles}
//         onChange={props.selectRole && ((e, option) => props.selectRole(option, member))}
//         getOptionLabel={(option) => option.role_name || ""}
//         isOptionEqualToValue={(option, value) => option.role_name === value.role_name}
//         renderOption={(props, option) => <li {...props}>{option.role_name}</li>}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             name="role"
//             label="Select role"
//             placeholder={placeholder}
//             className={classes.field}
//             InputLabelProps={{ shrink: true }}
//             InputProps={{
//               ...params.InputProps,
//               autoComplete: 'new-password',
//               endAdornment: (
//                 <>
//                   {member && (
//                     <Tooltip title={'Read permissions'}>
//                       <IconButton onClick={() => setPermissionDialog(true)} className={classes.icon}>
//                         <Visibility />
//                       </IconButton>
//                     </Tooltip>
//                   )}
//                   {params.InputProps.endAdornment}
//                 </>
//               ),
//             }}
//             error={!!error} 
//             helperText={error ? helperText : ""} 
//           />
//         )}
//       />

//       {permissionDialog && (
//         <ReadPermissionsDialog
//           gridApi={gridApi}
//           openDialog={permissionDialog}
//           roleuuid={member.roles?.[0]?.role_id}
//           handleClose={() => setPermissionDialog(false)}
//         />
//       )}
//     </>
//   );
// }

// MemberRoleSelector.propTypes = {
//   member: PropTypes.object,
//   roleObject: PropTypes.object,
//   placeholder: PropTypes.string,
//   gridApi: PropTypes.object,
//   selectRole: PropTypes.func,
//   roleOptions: PropTypes.array,
// };


import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip, Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useContext, useState } from 'react';
import { getRoles } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import ReadPermissionsDialog from './ReadPermissionsDialog';

// ------------------------------------------------------------------------

const useStyles = makeStyles({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
});

// ----------------------------------------------------------------------

export default function MemberRoleSelector(props) {
  const classes = useStyles();
  const { member, roleObject, placeholder, gridApi, roleOptions: initialRoleOptions, error, helperText } = props;
  const [permissionDialog, setPermissionDialog] = useState(false);
  const { customerId } = useContext(UserContext);
  const [roleOptions, setRoleOptions] = useState([]);

  // ------------------------------------------------------------------------

  const fetchRoles = async () => {
    try {
      const res = await getRoles(customerId);
      setRoleOptions(Array.isArray(res.data) ? res.data : []);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
      setRoleOptions([]);
    }
  };

  // ----------------------------------------------------------------------

  const handleOpenPermissionDialog = () => {
    if (member?.roles?.[0]?.role_id) {
      setPermissionDialog(true);
    } else {
      console.error('No role_id found for the selected member');
    }
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        autoHighlight
        disableClearable
        className={classes.root}
        options={roleOptions || []}
        sx={{ maxWidth: 600, minWidth: 250 }}
        defaultValue={
          roleObject || roleOptions.find((item) => item?.uuid === '97ab7ed0-0f0e-4e30-aa4a-d4e134f99481') || ""
        }
        onOpen={fetchRoles}
        onChange={props.selectRole && ((e, option) => props.selectRole(option, member))}
        getOptionLabel={(option) => option.role_name || ""}
        isOptionEqualToValue={(option, value) => option.role_name === value.role_name}
        renderOption={(props, option) => <li {...props}>{option.role_name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            name="role"
            label="Select role"
            placeholder={placeholder}
            className={classes.field}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {member && (
                    <Tooltip title={'Read permissions'}>
                      <IconButton onClick={handleOpenPermissionDialog} className={classes.icon}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!!error}
            helperText={error ? helperText : ""}
          />
        )}
      />

      {permissionDialog && (
        <ReadPermissionsDialog
          gridApi={gridApi}
          openDialog={permissionDialog}
          roleuuid={member?.roles?.[0]?.role_id}  // Ensure role_id is passed correctly
          handleClose={() => setPermissionDialog(false)}
        />
      )}
    </>
  );
}

MemberRoleSelector.propTypes = {
  member: PropTypes.object,
  roleObject: PropTypes.object,
  placeholder: PropTypes.string,
  gridApi: PropTypes.object,
  selectRole: PropTypes.func,
  roleOptions: PropTypes.array,
};
