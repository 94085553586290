
//  import React from 'react';
//  import ReactApexChart from 'react-apexcharts';

// const AvailabilityChart = ({ seriesData, xAxisData, tabIndex }) => {
//     // Reverse the xAxisData and seriesData to simulate RTL direction
//     const reversedXAxisData = [...xAxisData].reverse();
//     const reversedSeriesData = seriesData.map(series => ({
//       ...series,
//       data: [...series.data].reverse(),
//     }));
  
//     const yAxisFormatter = (value) => {
//       switch (tabIndex) {
//         case 0: // Latency
//           return `${value.toFixed(3)} ms`;
//         case 1: // Jitter
//           return `${value.toFixed(16)} ms`;
//         case 2: // Packet Loss
//           return `${value}%`;
//         case 3: // Up/Down
//         return value === 1 ? 'Up' : 'Down';
//         default:
//           return value;
//       }
//     };
  
//     const chartOptions = {
//         chart: {
//           type: 'area',
//           height: 260,
//           toolbar: { show: false },
//         },
//         annotations: {
//           yaxis: [{
//             y: 30,
//           }],
//         },
//         stroke: {
//           curve: 'smooth',
//           width: 2,
//         },
//         colors: ['#40E0D0'],
//         dataLabels: {
//           enabled: false,
//         },
//         tooltip: {
//           shared: false,
//           y: {
//             formatter: yAxisFormatter,
//           },
//           x: {
//             formatter: (value) => {
//               const date = new Date(value);
//               let hours = date.getHours();
//               const minutes = date.getMinutes();
//               const ampm = hours >= 12 ? 'PM' : 'AM';
//               hours = hours % 12;
//               hours = hours ? hours : 12; // the hour '0' should be '12'
//               const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
//               const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//               return `${formattedTime}\n${formattedDate}`;
//             }
//           },
//         },
//         // legend: {
//         //   show: true,
//         //   position: 'bottom',
//         //   horizontalAlign: 'left',
//         //   showForSingleSeries: true,
//         //   offsetX: 0.5,
//         //   markers: {
//         //     width: 15,
//         //     height: 7,
//         //     strokeWidth: 0,
//         //     radius: 12,
//         //     offsetX: 0,
//         //     offsetY: 0,
//         //   },
//         // },
//         xaxis: {
//           categories: reversedXAxisData,
//           type: 'datetime',
         
//           labels: {
//             formatter(value) {
//               const date = new Date(value);
//               let hours = date.getHours();
//               const minutes = date.getMinutes();
//               const ampm = hours >= 12 ? 'PM' : 'AM';
//               hours = hours % 12;
//               hours = hours ? hours : 12; // the hour '0' should be '12'
//               const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
//               const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//               return `${formattedTime}\n${formattedDate}`;
//             },
//           },
//         },
//         yaxis: {
//           forceNiceScale: true,
//           labels: {
//             formatter: yAxisFormatter,
//           },
//         },
//       };
      
  
//     return (
//       <div>
//         {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
//       </div>
//     );
//   };
  
//   export default AvailabilityChart;
  
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AvailabilityChart = ({ seriesData, xAxisData, tabIndex }) => {
    // Reverse the xAxisData and seriesData to simulate RTL direction
    const reversedXAxisData = [...xAxisData].reverse();
    const reversedSeriesData = seriesData.map(series => ({
      ...series,
      data: [...series.data].reverse(),
    }));

    // Define a custom y-axis formatter based on tabIndex
    const yAxisFormatter = (value) => {
        switch (tabIndex) {
            case 0: // Latency
                return `${value.toFixed(3)} ms`;
            case 1: // Jitter
                return `${value.toFixed(3)} ms`; // Adjusted precision to 3 for jitter
            case 2: // Packet Loss
                return `${value}%`;
            case 3: // Up/Down
                return value === 1 ? 'Up' : 'Down'; // Custom formatter for Up/Down
            default:
                return value;
        }
    };

    const chartOptions = {
        chart: {
            type: 'area',
            height: 260,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ['#40E0D0'],
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: false,
            y: {
                formatter: yAxisFormatter,
            },
            x: {
                formatter: (value) => {
                    const date = new Date(value);
                    let hours = date.getHours();
                    const minutes = date.getMinutes();
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12; 
                    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                    const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                    return `${formattedTime}\n${formattedDate}`;
                },
            },
        },
        xaxis: {
            categories: reversedXAxisData,
            type: 'datetime',
            labels: {
                formatter(value) {
                    const date = new Date(value);
                    let hours = date.getHours();
                    const minutes = date.getMinutes();
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                    const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                    return `${formattedTime}\n${formattedDate}`;
                },
            },
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: yAxisFormatter,
            },
            ...(tabIndex === 3 && {
                min: 0,
                max: 1, 
                tickAmount: 1, 
                labels: {
                    formatter: (value) => (value === 1 ? 'Up' : 'Down'), 
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                },
            }),
        },
    };

    return (
        <div>
            {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
        </div>
    );
};

export default AvailabilityChart;
