import { isEmpty } from "lodash";
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MenuItem, Stack } from '@mui/material';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'My Profile',
    icon: 'eva:settings-2-fill',
    linkTo: '/dashboard/settings',
  },

];


const DEVELOPER_MENU_OPTIONS = [
  {
    label: 'Device Types',
    icon: 'eva:settings-2-fill',
    linkTo: '/system-configuration/device-types',
  },
  {
    label: 'Developer console',
    icon: 'eva:settings-2-fill',
    linkTo: '/system-configuration/developer-console',
  },
  // {
  //   label: 'EOS/EOL Database',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '/system-configuration/device-life-cycle',
  // }
];


// ----------------------------------------------------------------------

export default function MenuItems(props) {

  const [menuOptions, setMenuOptions] = useState([])

  useEffect(() => {
    let _menuOptions = MENU_OPTIONS
    if (!isEmpty(localStorage.getItem('developer_mode'))) {
      const value = localStorage.getItem('developer_mode');
      if (value === "1") {
        _menuOptions = [
          ..._menuOptions,
          ...DEVELOPER_MENU_OPTIONS
        ]

      }
    }

    setMenuOptions(_menuOptions)
  }, [])

  const handleClose = () => {
    props.setOpen(null);
  };


  return (
    <Stack sx={{ p: 1 }}>
      {menuOptions.map((option) => (
        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
          {option.label}
        </MenuItem>
      ))}
    </Stack>
  );
}

