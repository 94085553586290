/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { radioRadioChannel } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';



// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250,
}));

// ---------------------------------------


const RadioMgtFrames = ({ radioExpanded }) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async () => {
        if (!healthDashState.selectedDevice || radioExpanded === false) {
            return '';
        }
        fetchRadioChannel();
    }

    const fetchRadioChannel = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setChartLoading(true)
        radioRadioChannel(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                setSeriesData(res[0]?.data)
                setXAxisData(res[0]?.timestamps)
            })
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, radioExpanded]);

    const chartSeries = seriesData;

    const chartOptions = {
        chart: {
            type: 'area',
            height: 250,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'smooth',
            width: 1,
        },
        colors: ['#ffcc80','#ffd54f','#f48fb1','#ce93d8','#fff59d'],
        dataLabels: {
            enabled: false,
            dropShadow: { enabled: true }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (value) => value.toFixed(0) + '%',
            },
            x: {
                show: false,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            offsetX: 0.5,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
        },
        xaxis: {
            categories: xAxisData,
            type: 'datetime',
            labels: {
                hideOverlappingLabels: true,
                formatter: function (value) {
                    const date = new Date(value * 1000);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    return timeString;
                },
            }
        },
        yaxis: {
            forceNiceScale: true,
            tickAmount: 10,
            labels: {
                formatter: (value) => value.toFixed(0) + '%',
            },
        },
    };

    return (
        <>
            {chartLoading && <LinearProgress />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                        Radio 0 Mgmt Frames(fps)
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        <ReactApexChart series={chartSeries} options={chartOptions} type="area" height={250} />
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default RadioMgtFrames;
