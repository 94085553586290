/* eslint-disable*/
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, InputAdornment, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { getGenerals, setGenerals } from '../../../API/Generals/General';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';

// -----------------------------------------------------------------------

const GloalConfigEditor = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { keys, valueComponent, label } = props;
  const { customerId } = useContext(UserContext);
  const [getConfig, setGetConfig] = useState([]);
  const [query, setQuery] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [_isDirty, setIsDirty] = useState(false);

  // ----------------------------------------------------------------

  useEffect(() => {
    if (getConfig.key === 'up-down-v3i-pooling-seconds') {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [getConfig]);

  const getConfigFunction = () => {
    setLoading(true);
    getGenerals(customerId, keys)
      .then((res) => {
        setGetConfig({
          ...res,
          context : res.context || label
        });
        setQuery(res.value);
        setLoading(false);
        setInitialLoading(false)
      })
      .catch((res) => {
        if (res.response.status === 422 || 404) {
          const data = {
            key: keys,
            value: valueComponent,
            context: label,
          };
          setLoading(true);
          setGenerals(customerId, data)
            .then((res) => {
              setQuery(res.value);
              setLoading(false);
            })
            .catch((res) => {
              setLoading(false);
            });
        }
      });
  }

  useEffect(() => {
    getConfigFunction();
  }, [customerId]);

  const generalSchema = Yup.object().shape({
    key: Yup.string(),
    value: Yup.number('Must be number').required('Is required').positive().min(0, 'Min is 0').max(22, 'max is 20'),
    context: Yup.string(),
  });

  const defaultValues = {
    key: getConfig.key || '',
    value: 0,
    context: getConfig.label || '',
  };

  useEffect(() => {
    if (query > -1) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [query]);

  const handleInputChange = (event) => {
    const query = event.target.value;
    if (query > -1) {
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid positive value.');
    }
    setQuery(query);
  };

  const methods = useForm({
    resolver: yupResolver(generalSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleRefresh = () => {
    getConfigFunction(); 
  };
  
  const onSubmit = async () => {
    setLoading(true);
    const data = {
      key: getConfig.global_key,
      value: query,
      context: getConfig.context,
    };
    setGenerals(customerId, data)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'value added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleRefresh();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res?.response?.data?.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleRefresh();
      });
  };

// -------------------------------------------------------------------------

  const renderComponent = () => {
    return <>
      <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>{getConfig.context}</Typography>

      {typeof getConfig.value === 'string' ? (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: 9 }}>
          <div style={{maxWidth:120}}>
            <RHFTextField
              name="value"
              label=""
              size="small"
              type="number"
              id="number"
              width={"100px"}
              disabled={_isDirty}
              value={query}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography>sec</Typography>
                  </InputAdornment>
                ),
              }}
            />
            {errorMessage && (
              <p className="error" style={{ fontSize: '10px', color: 'red' }}>
                *{errorMessage}
              </p>
            )}
          </div>
          <LoadingButton
            type="submit"
            variant="outlined"
            
            loading={isSubmitting}
            disabled={!isValid || _isDirty}
            sx={{ maxHeight: '37px' }}
          >
            Update
          </LoadingButton>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: 9 }}>
          <RHFTextField
            name="value"
            label=""
            size="small"
            type="number"
            id="number"
            disabled={_isDirty}
            value={query}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Typography>sec</Typography>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton type="submit" variant="outlined" width="50%" loading={isSubmitting} disabled={_isDirty} sx={{ maxHeight: '37px' }}>
            Update
          </LoadingButton>
        </div>
      )}
    </>
  }
  
  return (
    <>
      {loading && !initialLoading && <LinearProgress />}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: '30px',
            py: 2,
            pr: '30px',
          }}
        >
          {initialLoading ? (
            <Skeleton animation="wave"  height={40}  width={"100%"} />
          ) : renderComponent()}

        </Stack>
        <Divider/>
      </FormProvider>
    </>
  );
};

export default GloalConfigEditor;
