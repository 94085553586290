import { TestbedInstance ,instance as modem } from '../modem';

const id =1;
export const getUnknownDevices = async (id, data) => {
  const testbedModem = TestbedInstance();
  const newPromise =  modem
    .post(`${id}/inventory/unknown`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};