// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { agentConfigure, getDcAgentById, enableDcAgents } from '../../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../../hook-form';
import DcAgentSubnet from './DcAgentSubnet';
import DcLocationSelector from './DcLocationSelector';
import DcZoneSelector from './DcZoneSelector';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fieldContainer: { minHeight: 290 },
});

export default function ConfigureDcAgent(props) {
  const { openDialog, handleClose, agent } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { customers, user, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [dcAgentLoading, setDcAgentLoading] = useState(false);
  const [zoneValue, setZoneValue] = useState(agent.zone);
  const [locationValue, setLocationValue] = useState(agent?.locations || []);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [subnetOptions, setSubnetOptions] = useState([]);
  const [zoneSelectionSource, setZoneSelectionSource] = useState(null);

  const credentialsSchema = Yup.object().shape({
    agentName: Yup.string(),
  });

  const defaultValues = {
    agentName: agent.r_agent_name,
  };

  const methods = useForm({
    resolver: yupResolver(credentialsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const selectLocations = (value) => {
    setLocationValue(value);
  };

  const _setZoneValue = (value, _source) => {
    setZoneSelectionSource(_source);
    setZoneValue(value);
  };

  // const onSubmit = async (formData) => {
  //   const data = {
  //     name: formData.agentName,
  //     zone_uuid: zoneValue?._uid,
  //     location_uuid: locationValue?.map((item) => item.location_id),
  //     subnet_uuids: selectedSubnets?.map((item) => item.subnet_id),
  //   };
    
  //   setDcAgentLoading(true);
  //   setLoading(true);
  //   enableDcAgents(customerId, agent.agent_id, data)
  //     .then((res) => {
  //       setDcAgentLoading(false);
  //       setLoading(false);
  //       enqueueSnackbar(
  //         'Agent details configured sucessfully',
  //         {
  //           variant: 'success',
  //           anchorOrigin: {
  //             vertical: 'bottom',
  //             horizontal: 'right',
  //           },
  //         },
  //         500
  //       );
  //       handleClose();
  //     })
  //     .catch((res) => {
  //       setDcAgentLoading(false);
  //       setLoading(false);
  //       enqueueSnackbar(
  //         res.response.data.message,
  //         {
  //           variant: 'error',
  //           anchorOrigin: {
  //             vertical: 'bottom',
  //             horizontal: 'right',
  //           },
  //         },
  //         500
  //       );
  //       handleClose();
  //     });
  // };
  const onSubmit = async (formData) => {
    const data = {
      name: formData.agentName,
      zone_uuid: zoneValue?._uid,
      location_uuid: locationValue?.map((item) => item.location_id),
      subnet_uuids: selectedSubnets?.map((item) => item.subnet_id),
    };
  
    setDcAgentLoading(true);
    setLoading(true);
  
  
    try {
      let response;
  
      if (agent.r_agent_status === 'NEW') {
        response = await agentConfigure(customerId, agent.agent_id, data);
        enqueueSnackbar('Agent authorized successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });
      } else {
        
        response = await enableDcAgents(customerId, agent.agent_id, data); 
        enqueueSnackbar('Agent details configured successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });
      }
  
      handleClose();
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message || 'An error occurred',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        }
      );
    } finally {
      setDcAgentLoading(false);
      setLoading(false);
    }
  };
  
  // Filter duplicate locations by location_id
 const filteredLocationValue = locationValue.filter((value, index, self) =>
  index === self.findIndex((t) => t.location_id === value.location_id)
);
  
  const _setDcAgentSubnetOptions = (_subnetOptions) => {
    setSelectedSubnets(_subnetOptions);
    setSubnetOptions(_subnetOptions);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          {agent.r_agent_status === 'NEW' && 'Authorize'}
          {agent.r_agent_status !== 'NEW' && 'Configure'}
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent sx={{ py: 3, px: 3 }}>
          <Stack spacing={2}>
            <RHFTextField name="agentName" label="Agent name" size="small" />
            {/* <RHFTextField name="zoneName" label="zone name" size="small" /> */}
            <Stack direction="row" spacing={2} sx={{ width: '550px' }}>
              <FormControl fullWidth size="small">
                <DcZoneSelector
                  setZoneValue={_setZoneValue}
                  // zoneValue={agent?.zone?.r_zone_name}
                  zoneValue={zoneValue}
                />
              </FormControl>

              <FormControl fullWidth size="small">
                <DcLocationSelector
                  selectAllLabel="Select all"
                  zoneSelectionSource={zoneSelectionSource}
                  zoneValue={zoneValue}
                  setZoneValue={(_zone) => _setZoneValue(_zone, 'location')}
                  setLocationValue={selectLocations}
                  locationValue={filteredLocationValue}
                  setDcAgentSubnetOptions={_setDcAgentSubnetOptions}
                />
              </FormControl>
            </Stack>

            <FormControl fullWidth size="small">
              <DcAgentSubnet
                setSelectedSubnets={setSelectedSubnets}
                subNetOptions={subnetOptions}
                selectedSubnets={selectedSubnets}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ float: 'right' }}
            variant="outlined"
            color="primary"
            loading={dcAgentLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {agent.r_agent_status === 'NEW' ? 'Authorize' : 'Configure'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
