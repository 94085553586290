// /* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// // import { haClusterMemberStatus } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';


// // -------------------------------------

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     height: 259,
//     display: 'flex',
//     flexDirection: 'row',
//     gap: 0,
//     justifyContent: 'center',
//     alignItems: 'center'
// }));

// // ---------------------------------------

// const MemberStatus = ({ expandedState }) => {
//     const { healthDashState, getStartEnd } = useHealthContext()
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(false);

//     const solveCondition = async () => {
//         if (!healthDashState.selectedDevice || expandedState === false) {
//             return '';
//         }
//         fetchMemberStatus();
//     }

//     const fetchMemberStatus = async () => {
//         const data = getStartEnd()
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//             device_os: healthDashState.selectedDevice.r_dvc_os
//         }
//         setChartLoading(true)
//         haClusterMemberStatus(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//             .then((res) => {
//                 setChartLoading(false)
//                 const convertedData = res?.data.map((chartData) => ({
//                     ...chartData,
//                     data: chartData.data.map((value) => (value === 1 ? 100 : 0)),
//                 }));
//                 setSeriesData(convertedData);
//             })
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

//     const chartSeries = seriesData;

    
//     const chartOptions = {
//         chart: {
//             height: 250,
//             type: 'radialBar',
//             toolbar: { show: false },
//         },
//         stroke: {
//             lineCap: "round",
//         },
//         plotOptions: {
//             radialBar: {
//                 size: "100%",
//                 inverseOrder: false,
//                 startAngle: -110,
//                 endAngle: 110,
//                 offsetX: 0,
//                 offsetY: 0,
//                 hollow: {
//                     margin: 0,
//                     size: "60%"
//                 },
//                 track: {
//                     show: true,
//                     startAngle: -110,
//                     endAngle: 110,
//                     background: "#f2f2f2",
//                     strokeWidth: "100%",
//                     opacity: 1,
//                     margin: 1,
//                     dropShadow: {
//                         enabled: false,
//                         top: 0,
//                         left: 0,
//                         blur: 3,
//                         opacity: 0.5
//                     }
//                 },
//                 dataLabels: {
//                     show: true,
//                     name: {
//                         show: true,
//                         fontSize: "1.0rem",
//                         fontWeight: 600,
//                         offsetY: 65
//                     },
//                     value: {
//                         show: true,
//                         fontSize: "2.0rem",
//                         offsetY: 8,
//                         formatter: function (val) {
//                             return val / 100;
//                         },
//                     },
//                     total: {
//                         show: false,
//                     }
//                 }
//             },
//         },
//         legend: {
//             show: false
//         },
//     };

//     return (
//         <>
//             {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Member Status
//                         </Typography>
//                     </Grid>
//                 </Grid>
//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                         {chartSeries.map((chartData, index) => {
//                             return (
//                                 <ReactApexChart
//                                     key={index}
//                                     series={chartData.data}
//                                     options={{
//                                         ...chartOptions,
//                                         labels: [chartData.name], 
//                                     }}
//                                     type="radialBar"
//                                     height={250}
//                                 />
//                             )
//                         })}
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default MemberStatus;

/* eslint-disable */
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250, // Ensure height matches required size
}));

const MemberStatus = ({ expandedState, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [statusData, setStatusData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(() => {
        if (!healthDashState.selectedDevice || expandedState === false) {
            return;
        }
        setLoading(true);
        // Simulate API call and set data
        const memberStatusData = allData?.hacluster_table[0]?.device_hamemberstatus || [];
        const timestamps = [
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722576898,
            1722580930
        ];
        const convertedData = timestamps.map((time, index) => ({
            status: memberStatusData[index]?.status || 'N/A',
            time
        }));
        setStatusData(convertedData);
        setLoading(false);
    }, [healthDashState.selectedDevice, expandedState, allData]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState, fetchData]);

    const columnHeaders = useMemo(() => [
        {
            headerName: 'Status',
            field: 'status',
            width: 180,
            sortable: true,
            filter: true,
            cellStyle: { fontSize: '14px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'Time',
            field: 'time',
            width: 180,
            sortable: true,
            filter: true,
            cellStyle: { fontSize: '14px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
            valueFormatter: (params) => new Date(params.value * 1000).toLocaleString()
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Member Status
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        <Box style={{ height: '100%', width: '100%' }} className="ag-theme-material">
                            <AgGridReact
                                rowData={statusData}
                                columnDefs={columnHeaders}
                                defaultColDef={defaultColDef}
                            />
                        </Box>
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default MemberStatus;
