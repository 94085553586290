import React from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack } from '@mui/material';
import { FormProvider, RHFTextField } from '../../hook-form';
// components
// ------------------------------------------------------
export default function SmtpConfigForm(props) {

  const LoginSchema = Yup.object().shape({
    servername: Yup.string().required('required'),
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password should not be empty'),
    verifyPassword: Yup.string()
      .label('Verify password')
      .required('Please confirm password')
      .oneOf([Yup.ref('password'), null], 'Passwords does not match'),
    port: Yup.number().required('Invalid port number'),
    connectionTimer: Yup.string().required('Required'),
    formAddress: Yup.string().email('Invalid address').required('Please enter valid address required'),
  });

  const defaultValues = {
    servername: '',
    username: '',
    email: '',
    password: '',
    verifyPassword: '',
    port: '',
    connectionTimer: '',
    formAddress: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  // useEffect(()=> {reset({username: userInfo.name,email:userInfo.email})}, [userInfo])

  const onSubmit = (value) => {
    console.log(value);
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ px: 4, py: 2 }} elevation={2}>
        <Stack spacing={4} direction="row">
          <Stack direction="column" spacing={2} sx={{ width: '250px' }}>
            <RHFTextField name="servername" label="Server name" size="small" />
            <RHFTextField name="email" label="User name" size="small" />
            <RHFTextField name="password" label="Password" size="small" />
            <RHFTextField name="verifyPassword" label="Verify password" size="small" />
            <RHFTextField name="formAddress" label="Default form address" size="small" />
          </Stack>
          <Stack direction="column" spacing={2} sx={{ width: '250px' }}>
            <FormControl fullWidth size="small">
              <InputLabel>Connection security</InputLabel>
              <Select value={age} onChange={handleChange} displayEmpty>
              <MenuItem value={10}>None</MenuItem>
                <MenuItem value={20}>SSL/TLS</MenuItem>
                <MenuItem value={30}>STARTTLS</MenuItem>
                <MenuItem value={40}>Auto</MenuItem>
              </Select>
            </FormControl>
            <RHFTextField name="port" label="Port" size="small" />
            <RHFTextField name="connectionTimer" label="ConnectionTimer" size="small" />
          </Stack>
        </Stack>
        <Box sx={{ py: 2 }} spacing={2}>
          <Stack spacing={2} direction="row">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ float: 'left' }}
            >
              Save
            </LoadingButton>
            <LoadingButton type="submit" variant="outlined" loading={isSubmitting} color="error" sx={{ float: 'left' }}>
              Reset
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={isSubmitting}
              color="secondary"
              sx={{ float: 'left' }}
            >
              Send test email
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={isSubmitting}
              color="primary"
              sx={{ float: 'left' }}
            >
              Send test sms
            </LoadingButton>
          </Stack>
        </Box>
      </Paper>
    </FormProvider>
  );
}
