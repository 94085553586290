/* eslint-disable */
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useNodes } from 'reactflow';
import { toCamelizeWords } from '../../../../utils/formatString';

// ------------------------------------------------------------------------
export default function NodeSelector(props) {
  const [nodeOptions, setNodeOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(null); 
  const nodes = useNodes();

  useEffect(() => {
    setNodeOptions(nodes.filter(i => i.type !== "INTERNET"));
  }, [nodes]);

  useEffect(() => {
    setSelectedOption(null);
  }, [props.locationData]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSelectLocation = (event, selectedOptions) => {
    setLoading(true);
    setSelectedOption(selectedOptions); 
    if (selectedOptions) {
      const selectedLocationUids = selectedOptions.id;
      props.onSelectNode(selectedLocationUids);
      setLoading(false);
    } else {
      setLoading(false);
      props.onSelectNode("");
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Autocomplete
        disablePortal={true}
        sx={{ maxWidth: 900, minWidth: 390 }}
        limitTags={2}
        loading={loading}
        size="small"
        handleHomeEndKeys
        options={nodeOptions.sort((a, b) => a.position.y - b.position.y)}
        onChange={handleSelectLocation}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={selectedOption}
        getOptionLabel={(option) => `${toCamelizeWords(option.data.l_dvc_l2_type)} - ${option.data.r_dvc_host} - ${option.data.r_dvc_ip_address}`}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose Device"
            label="Device"
            value={query}
            onChange={handleQueryChange}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </div>
  );
}
