// /* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// // import { haClusterVirusEventsTotal } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';



// // -------------------------------------

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 250,
// }));

// // ---------------------------------------


// const VirusEventsTotal = ({ expandedState, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext()
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(false);

//     const solveCondition = async () => {
//         if (!healthDashState.selectedDevice || expandedState === false) {
//             return '';
//         }
//         fetchVirusEvents();
//     }

//     const fetchVirusEvents = async () => {
//         const data = getStartEnd()
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//             device_os: healthDashState.selectedDevice.r_dvc_os
//         }
//         setChartLoading(true)
//         haClusterVirusEventsTotal(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//             .then((res) => {
//                 setChartLoading(false)
//                 const editedRes = res?.data.map((key) => {
//                     return {
//                         "name": key.hostname,
//                         "data": key.data
//                     }
//                 });
//                 setSeriesData(editedRes)
//                 setXAxisData(res?.timestamps)
//             })
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

//     const chartSeries = seriesData;

//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 250,
//             stacked: true,
//             toolbar: { show: false },
//         },
//         annotations: {
//             yaxis: [{
//                 y: 30,
//             }],
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 1,
//         },
//         colors: ['#ef9a9a','#f48fb1','#ce93d8','#fff59d','#b39ddb','#9fa8da','#90caf9','#81d4fa','#80deea','#80cbc4','#a5d6a7','#c5e1a5','#e6ee9c','#ffe082','#ffcc80','#ffab91'],
//         dataLabels: {
//             enabled: false,
//             dropShadow: { enabled: true }
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: (value) => value.toFixed(0),
//             },
//             x: {
//                 show: false,
//             },
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             horizontalAlign: 'left',
//             showForSingleSeries: true,
//             offsetX: 0.5,
//             markers: {
//                 width: 15,
//                 height: 7,
//                 strokeWidth: 0,
//                 radius: 12,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         xaxis: {
//             categories: xAxisData,
//             type: 'datetime',
//             labels: {
//                 hideOverlappingLabels: true,
//                 formatter: function (value) {
//                     const date = new Date(value * 1000);
//                     const hours = date.getHours();
//                     const minutes = date.getMinutes();
//                     const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//                     return timeString;
//                 },
//             }
//         },
//         yaxis: {
//             forceNiceScale: true,
//             tickAmount: 1,
//             labels: {
//                 formatter: (value) => value.toFixed(0),
//             },
//         },
//     };

//     return (
//         <>
//             {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Virus Events Total
//                         </Typography>
//                     </Grid>
//                 </Grid>
//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                     {seriesData?.length > 0 && xAxisData?.length > 0 && <ReactApexChart series={chartSeries} options={chartOptions} type="area" height={250} />}   
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };
// export default VirusEventsTotal;

/* eslint-disable */
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250, // Ensure height matches required size
}));

const VirusEventsTotal = ({ expandedState, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [statusData, setStatusData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchVirusEvents = async () => {
        // if (!healthDashState.selectedDevice || expandedState === false) {
        //     return;
        // }
        setLoading(true);
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_os: healthDashState.selectedDevice.r_dvc_os
        };

        try {
            const res = await haClusterVirusEventsTotal(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload);
            const memberStatusData = allData?.hacluster_table[0]?.device_hamemberstatus || [];
            const timestamps = res?.timestamps || []; // Make sure you have timestamps

            const convertedData = timestamps.map((time, index) => ({
                hostname: memberStatusData[index]?.hostname || 'N/A', // Adjust according to your data structure
                data: memberStatusData[index]?.data || 'N/A',
                time
            }));
            setStatusData(convertedData);
        } catch (error) {
            console.error("Error fetching virus events", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVirusEvents();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    const columnHeaders = useMemo(() => [
        {
            headerName: 'Hostname',
            field: 'hostname',
            width: 180,
            sortable: true,
            filter: true,
            cellStyle: { fontSize: '14px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'Data',
            field: 'data',
            width: 180,
            sortable: true,
            filter: true,
            cellStyle: { fontSize: '14px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'Time',
            field: 'time',
            width: 180,
            sortable: true,
            filter: true,
            cellStyle: { fontSize: '14px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
            valueFormatter: (params) => new Date(params.value * 1000).toLocaleString()
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Virus Events Total
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        <Box style={{ height: '100%', width: '100%' }} className="ag-theme-material">
                            <AgGridReact
                                rowData={statusData}
                                columnDefs={columnHeaders}
                                defaultColDef={defaultColDef}
                            />
                        </Box>
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default VirusEventsTotal;
