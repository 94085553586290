import { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    LinearProgress,
    CircularProgress,
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SDwanChart from './SDwanChart';
import { UserContext } from 'src/context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import { sdwanGraph } from 'src/API/NetworkDevices/health';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 280,
}));

const SdwanGraph = ({ selectedInterface, sdwanStaticInterfaceId }) => {
    const { customerId } = useContext(UserContext);
    const { healthDashState, getStartEnd } = useHealthContext();
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);
    const [rawData, setRawData] = useState({}); 
    const [value, setValue] = useState(0); 
    const [response, setResponse] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        updateSeriesData(newValue);
    };

    const updateSeriesData = (tabIndex) => {
        switch (tabIndex) {
            case 0: // Latency tab
                setSeriesData([
                    { name: 'Latency', data: rawData.latencyData || [] },
                ]);
                break;
            case 1: // Jitter tab
                setSeriesData([
                    { name: 'Jitter', data: rawData.jitterData || [] },
                ]);
                break;
            case 2: // Packet Loss tab
                setSeriesData([
                    {
                        name: 'Packet Loss',
                        data: (rawData.packetLossData || []).map((val) =>
                            parseFloat(val.toFixed(0))
                        ),
                    },
                ]);
                break;
            default:
                break;
        }
    };

    const fetchThroughputBandwidth = async () => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: selectedInterface?.device_type,
            sdwanStaticInterfaceId,
        };

        setChartLoading(true);
        try {
            const res = await sdwanGraph(customerId, payload);

            const jitterData = res?.sdwan_link_jitter.map(parseFloat);
            const packetLossData = res?.sdwan_link_packet_loss.map(parseFloat);
            const latencyData = res?.sdwan_link_latency.map(parseFloat);
           
            const formattedXAxisData = res.timestamps.map(timestamp => {
                const date = new Date(timestamp * 1000);
                return `${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\n${date.toLocaleDateString([], { day: '2-digit', month: '2-digit' })}`;
            });

            setRawData({
                latencyData,
                jitterData,
                packetLossData,
            });

            setXAxisData(formattedXAxisData);
            setResponse(true);
        } catch (error) {
            console.error('Error fetching throughput data:', error);
        } finally {
            setChartLoading(false);
        }
    };

    useEffect(() => {
        if (sdwanStaticInterfaceId) fetchThroughputBandwidth();
    }, [sdwanStaticInterfaceId, healthDashState.timeRange]);

    useEffect(() => {
        updateSeriesData(value);
    }, [rawData, value]);

    return (
        <>
            {chartLoading && <LinearProgress />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant="h6" sx={{ pl: 1 }}>
                            SD-WAN Graph
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Latency" />
                            <Tab label="Jitter" />
                            <Tab label="Packet Loss" /> 
                        </Tabs>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                        <ChartWrapperStyle dir="ltr">
                            {chartLoading ? (
                                <CircularProgress />
                            ) : seriesData.length > 0 && xAxisData.length > 0 ? (
                                <SDwanChart seriesData={seriesData} xAxisData={xAxisData} />
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px', color: '#000000', fontWeight: 'bold' }}>
                                    No Data at the Moment
                                </div>
                            )}
                        </ChartWrapperStyle>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default SdwanGraph;


// import { useContext, useEffect, useState } from 'react';
// import {
//     Grid,
//     Paper,
//     Typography,
//     LinearProgress,
//     CircularProgress,
//     Box,
//     Tabs,
//     Tab,
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import SDwanChart from './SDwanChart';
// import { UserContext } from 'src/context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import { sdwanGraph } from 'src/API/NetworkDevices/health';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 290,
// }));

// const SdwanGraph = ({ selectedInterface, sdwanStaticInterfaceId }) => {
//     const { customerId } = useContext(UserContext);
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(false);
//     const [rawData, setRawData] = useState({}); 
//     const [value, setValue] = useState(0); 
//     const [response, setResponse] = useState(false);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//         updateSeriesData(newValue);
//     };

//     const updateSeriesData = (tabIndex) => {
//         switch (tabIndex) {
//             case 0: 
//                 setSeriesData([
//                     { name: 'Latency', data: rawData.latencyData || [] },
//                     { name: 'Jitter', data: rawData.jitterData || [] },
//                 ]);
//                 break;
//             case 1: 
//                 setSeriesData([
//                     {
//                         name: 'Packet Loss',
//                         data: (rawData.packetLossData || []).map((val) =>
//                             parseFloat(val.toFixed(0))
//                         ),
//                     },
//                 ]);
//                 break;
//             default:
//                 break;
//         }
//     };

//     const fetchThroughputBandwidth = async () => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: selectedInterface?.device_type,
//             sdwanStaticInterfaceId,
//         };

//         setChartLoading(true);
//         try {
//             const res = await sdwanGraph(customerId, payload);

//             const jitterData = res?.sdwan_link_jitter.map(parseFloat);
//             const packetLossData = res?.sdwan_link_packet_loss.map(parseFloat);
//             const latencyData = res?.sdwan_link_latency.map(parseFloat);
           

//             const formattedXAxisData = res.timestamps.map(timestamp => {
//                 const date = new Date(timestamp * 1000);
//                 return `${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\n${date.toLocaleDateString([], { day: '2-digit', month: '2-digit' })}`;
//             });

            
//             setRawData({
//                 latencyData,
//                 jitterData,
//                 packetLossData,
                
//             });

//             setXAxisData(formattedXAxisData);
//             setResponse(true);
//         } catch (error) {
//             console.error('Error fetching throughput data:', error);
//         } finally {
//             setChartLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (sdwanStaticInterfaceId) fetchThroughputBandwidth();
//     }, [sdwanStaticInterfaceId, healthDashState.timeRange]);

    
//     useEffect(() => {
//         updateSeriesData(value);
//     }, [rawData, value]);

//     return (
//         <>
//             {chartLoading && <LinearProgress />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant="h6" sx={{ pl: 1 }}>
//                             SD-WAN Graph
//                         </Typography>
//                     </Grid>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Tabs
//                             value={value}
//                             onChange={handleChange}
//                             variant="scrollable"
//                             scrollButtons="auto"
//                         >
//                             <Tab label="Latency & Jitter" />
//                             <Tab label="Packet Loss" /> 
//                         </Tabs>
//                     </Grid>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <ChartWrapperStyle dir="ltr">
//                             {chartLoading ? (
//                                 <CircularProgress />
//                             ) : seriesData.length > 0 && xAxisData.length > 0 ? (
//                                 <SDwanChart seriesData={seriesData} xAxisData={xAxisData} />
//                             ) : (
//                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px', color: '#000000', fontWeight: 'bold' }}>
//                                     No Data at the Moment
//                                 </div>
//                             )}
//                         </ChartWrapperStyle>
//                     </Grid>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default SdwanGraph;
