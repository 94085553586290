// @mui
import { Grid } from '@mui/material';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateLocation } from '../../actions/updateLocation';
import { UserContext } from '../../context/UserContext';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
import DeviceDownInventoryTable from './components/DeviceDownInventoryTable';

export default function DeviceDownComponent() {

  const { customerId } = useContext(UserContext);
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.devicedown.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'devicedown', locationUuid: _locations }));
  };
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.locationUuid) {
        dispatch(updateLocation({ page: 'devicedown', locationUuid: [parsed.locationUuid] }));
      }
    }
  }, [location]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} style={{ marginTop: '-35px' }}>
          <LocationSelect selectLocation={selectLocation} locationData={locationData} />
        </Grid>

        <Grid item lg={12} md={12} sm={12}>
          <hr style={{ border: 'none', borderBottom: '1px solid rgb(141 158 171 / 24%)' }} />
        </Grid>

        <Grid item lg={12} md={12} sm={12}>
          <DeviceDownInventoryTable locationData={locationData} />
        </Grid>
      </Grid>
    </>
  );
}
