import axios from 'axios';
import clientCredentials from "../api_endpoints"


export const ForgotPassword = async (payload) => {
  const endpoint = clientCredentials.apiHost
  await axios.post(`${endpoint}/forgot-password`, payload)
 
    .then((res) => {
      return res.data.data;
    })
    .catch((res) => {
      throw res;
    });
};

export const ResetPassword = async (payload) => {
  const endpoint = clientCredentials.apiHost
  await axios.post(`${endpoint}/reset-password`, payload)
 
    .then((res) => {
      return res.data.data;
    })
    .catch((res) => {
      throw res;
    });
};

// // ResetPassword API function
// export async function ResetPassword(payload) {
//   try {
//     const response = await fetch('reset-password', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(payload),
//       credentials: 'include', // Include cookies if necessary
//     });

//     if (response.ok) {
//       // Process the response if successful
//       return await response.json();
//     } else if (response.status === 401) {
//       // Handle Unauthorized response
//       throw new Error('Unauthorized. Please log in again.');
//     } else if (response.redirected) {
//       // Handle unexpected redirects
//       throw new Error('Redirected to login. Please try again.');
//     } else {
//       // Handle other response statuses
//       throw new Error('Failed to reset password. Please try again.');
//     }
//   } catch (error) {
//     console.error('API Error:', error);
//     throw error;
//   }
// }


