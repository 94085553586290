// @mui
import React, { useState } from 'react';
import {
    Table,TableBody, TableCell,TableContainer,TableHead,TableRow,styled,
    DialogActions,Dialog,DialogContent,DialogTitle,Slide,IconButton, Divider,
  } from '@mui/material';
  import { makeStyles } from '@mui/styles';
  import CloseIcon from '@mui/icons-material/Close';
  import IPAMsubtable from './IPAMsubtable';
  import SelectSubnets from './SelectSubnets';

  // ----------------------------------------------------------------------
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const Transition = React.forwardRef((props, ref) => { return <Slide direction="up" ref={ref} {...props} />; });

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: "flex-start"
    },
    topPaperScrollBody: {
      verticalAlign: "top"
    }
  });

  //----------------------------------------------------------------------
  export default function ScopesTable() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
      <>  
                    <TableContainer style={{ padding: '10px 10px 10px 10px' }}>
                      <Table sx={{ minWidth: 500, maxWidth: '60%' }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell> No.</TableCell>
                            <TableCell> Scope</TableCell>
                            <TableCell> Subnet</TableCell>
                            <TableCell> VLAN</TableCell>
                            <TableCell> Utilization </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                              <TableCell>1</TableCell> 
                              <TableCell>CCTV</TableCell>
                              <TableCell>192.168.10.0/23</TableCell>
                              <TableCell>1</TableCell> 
                              <TableCell 
                                sx={{ color: '#12baff', cursor: 'pointer' }}
                                onClick={handleClickOpen}
                              >
                                  58%
                              </TableCell>
                                  <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    onClose={handleClose}
                                    fullWidth
                                    maxWidth="sm"
                                    aria-labelledby="responsive-dialog-title"
                                    classes={{
                                      scrollPaper: classes.topScrollPaper,
                                      paperScrollBody: classes.topPaperScrollBody
                                    }}
                                  >
                                    <DialogTitle id="responsive-dialog-title" sx={{py:2}}>
                                      <SelectSubnets />
                                      <IconButton onClick={handleClose} sx={{ float: 'right' }}>
                                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                                      </IconButton>
                                    </DialogTitle>
                                    <Divider />
                                    <DialogContent sx={{ py: 3, px: 3 }}>
                                         <IPAMsubtable />
                                    </DialogContent>
                                  </Dialog>
                              <TableCell>12</TableCell>
                          </StyledTableRow>
                          <TableRow>
                              <TableCell>2</TableCell> 
                              <TableCell>Employee</TableCell>
                              <TableCell>192.168.10.0/23</TableCell>
                              <TableCell>10</TableCell>
                              <TableCell sx={{ color: '#12baff'}}>78%</TableCell>
                              <TableCell>10</TableCell>
                          </TableRow>
                          <StyledTableRow>
                              <TableCell>3</TableCell> 
                              <TableCell>Guest</TableCell>
                              <TableCell>192.168.10.0/23</TableCell>
                              <TableCell>20</TableCell>
                              <TableCell sx={{ color: '#12baff'}}>13%</TableCell>
                              <TableCell>14</TableCell>
                          </StyledTableRow>
                          <TableRow>
                              <TableCell>4</TableCell> 
                              <TableCell>Gamers</TableCell>
                              <TableCell>192.168.10.0/23</TableCell>
                              <TableCell>30</TableCell>
                              <TableCell sx={{ color: '#12baff'}}>25%</TableCell>
                              <TableCell>08</TableCell>
                          </TableRow>
                          <StyledTableRow>
                              <TableCell>5</TableCell>
                              <TableCell>Printer</TableCell>
                              <TableCell>192.168.10.0/23</TableCell>
                              <TableCell>40</TableCell>
                              <TableCell sx={{ color: '#12baff'}}>35%</TableCell>
                              <TableCell>14</TableCell>
                           </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
      </>
    );
  }
  