// import { Star } from '@mui/icons-material';
// import { Autocomplete, CircularProgress, Skeleton, TextField } from "@mui/material";

// import queryString from 'query-string';
// import { useContext, useEffect, useState } from "react";
// import { useLocation, useNavigate } from 'react-router-dom';
// import { getInventory } from '../../../API/NetworkDevices/networkDevices';
// import { UserContext } from '../../../context/UserContext/userContext';
// import { toCamelizeWords } from "../../../utils/formatString";
// import { useHealthContext } from './userContextHealth';
// import { availabilityStatus, networkHealthCpuUsage } from '../../../API/NetworkDevices/health';


// //------------------------------------------------------------------

// //--------------------------------------------------------------

// export default function DeviceTypeSelector() {
//   const navigate = useNavigate();
//   const { customerId } = useContext(UserContext);
//   const { healthDashState, healthDashDispatch ,getStartEnd} = useHealthContext();
//   const [isLoading, setLoadingData] = useState(false);
//   const [networkDeviceTypeOptions, setNetworkDeviceTypeOptions] = useState([]);
//   // const [isWLCControllednetwork, setWLCControllednetwork] = useState(false)
//   const { search } = useLocation();

//   const getQueryParams = () => {
//     return queryString.parse(window.location.search);
//   };


//   const fetchNetworkDeviceTypes = async () => {
//     if (healthDashState.locationuuid) {
//       const payload = {
//         endRow: 500,
//         startRow: 0,
//         location_uuids: [healthDashState.locationuuid],
//       };
//       setLoadingData(true);
//       try {
//         const res = await getInventory(customerId, payload);
//         setLoadingData(false);        
//         if(res?.data?.filter(item => item?.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER")?.length){
//           setNetworkDeviceTypeOptions(res?.data?.filter(item => item?.l_dvc_typ_name !== "ACCESS_POINT"));
//         }else{
//           setNetworkDeviceTypeOptions(res?.data);
//         }
//       } catch (err) {
//         setLoadingData(false);
//         console.log(err);
//       }
//     }
//   };

//   useEffect(() => {
  
//     fetchNetworkDeviceTypes();
//   }, [customerId, healthDashState.locationuuid]);

//   useEffect(() => {
//     if (networkDeviceTypeOptions) {
//       const serialNumberQuery = getQueryParams().identifier
//       let selectedDevice = networkDeviceTypeOptions.find((option) => option?.r_dvc_unique_identifier === serialNumberQuery)
//       // setSerialNumber(selectedDevice);
//       if (!selectedDevice) {
//         selectedDevice = null
//       }
//       healthDashDispatch({ type: 'SET_DEVICE', payload: selectedDevice });
//     }
//   }, [networkDeviceTypeOptions, search]);


//   // const handleSelectType = (event, selectedOptions) => {
//   //   if (selectedOptions) {
//   //     // setSerialNumber(selectedOptions);
//   //     navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${selectedOptions?.r_dvc_unique_identifier}`);
//   //   }
//   //   else {
//   //     // setSerialNumber(null);
//   //     navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}`);
//   //   }
//   // };

//   // const handleSelectType = async (event, selectedOptions) => {
//   //   if (selectedOptions) {
//   //     try {
//   //       // Fetch CPU usage data here
//   //       const startEndData = getStartEnd();
//   //       const payload = {
//   //         start: startEndData.start / 1000,
//   //         end: startEndData.end / 1000,
//   //         step: startEndData.step,
//   //         device_type: selectedOptions?.r_dvc_unique_identifier,
//   //       };
//   //       const cpuUsageData = await networkHealthCpuUsage(customerId, payload);
       
//   //       // navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${selectedOptions?.r_dvc_unique_identifier}`);
//   //       navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${selectedOptions?.r_dvc_unique_identifier}`, {
//   //         state: {
//   //           cpuUsageData, 
//   //           deviceTypeName: selectedOptions?.l_dvc_typ_name,
//   //           rDvcController: selectedOptions?.r_dvc_controller,
//   //         }
//   //       });
//   //       // console.log(selectedOptions?.r_dvc_controller,"device controller");
//   //       // console.log(selectedOptions?.l_dvc_typ_name,"device name");
//   //     } catch (error) {
//   //       console.error('API Error:', error);
//   //     }
//   //   } else {
//   //     navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}`);
//   //   }
//   // };

//   const handleSelectType = async (event, selectedOptions) => {
//     if (selectedOptions) {
//       try {
//         // Fetch CPU usage data
//         const startEndData = getStartEnd();
//         const payload = {
//           start: startEndData.start / 1000,
//           end: startEndData.end / 1000,
//           step: startEndData.step,
//           device_type: selectedOptions?.r_dvc_unique_identifier,
//         };
//         const cpuUsageData = await networkHealthCpuUsage(customerId, payload);
  
//         // Fetch availability status after fetching CPU usage
//         const availabilityPayload = {
//           start: startEndData.start / 1000,
//           end: startEndData.end / 1000,
//           device_type: selectedOptions?.r_dvc_unique_identifier,
//         };
//         const availabilityData = await availabilityStatus(customerId, availabilityPayload);
  
      
//         navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${selectedOptions?.r_dvc_unique_identifier}`, {
//           state: {
//             cpuUsageData,
//             availabilityData,  
//             deviceTypeName: selectedOptions?.l_dvc_typ_name,
//             rDvcController: selectedOptions?.r_dvc_controller,
//           }
//         });
  
//       } catch (error) {
//         console.error('API Error:', error);
//       }
//     } else {
//       navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}`);
//     }
//   };
  

//   if (isLoading) {
//     return <Skeleton width={250} minwidth={200} />
//   }
//   return (
//     <>
//       <Autocomplete
//         size="small"
//         handleHomeEndKeys
//         loading={isLoading}
//         disabled={!healthDashState.locationuuid}
//         options={networkDeviceTypeOptions}
//         value={healthDashState.selectedDevice || null}
//         sx={{ maxwidth: 300, minwidth: 200 }}
//         isOptionEqualToValue={(option, value) => option?._uid === value?._uid}
//         getOptionLabel={(option) => `${toCamelizeWords(option?.l_dvc_typ_name)} - ${option?.r_dvc_name} - ${option?.r_dvc_ip_address}`}
//         renderOption={(props, option) => {
//           return (
//             <li {...props} key={option._uid}>
//               {toCamelizeWords(option?.l_dvc_typ_name)} - {option?.r_dvc_name} - {option?.r_dvc_ip_address} 
//             </li>
//           )
//         }}
//         onChange={handleSelectType}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             placeholder="Choose devices"
//             label="Devices"
//             type="text"
//             InputLabelProps={{ shrink: true }}
//             autoComplete='new-password'
//             InputProps={{
//               ...params.InputProps,
//               autoComplete: 'new-password',
//               endAdornment: (
//                 <>
//                   {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
//                   {params.InputProps.endAdornment}
//                 </>
//               ),
//             }}
//           />
//         )}
//       />
//     </>
//   );
// }

import { Star } from '@mui/icons-material';
import { Autocomplete, CircularProgress, Skeleton, TextField, Backdrop } from "@mui/material";

import queryString from 'query-string';
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getInventory } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext/userContext';
import { toCamelizeWords } from "../../../utils/formatString";
import { useHealthContext } from './userContextHealth';
import { availabilityStatus, networkHealthCpuUsage } from '../../../API/NetworkDevices/health';

//------------------------------------------------------------------

//--------------------------------------------------------------

export default function DeviceTypeSelector() {
  const navigate = useNavigate();
  const { customerId } = useContext(UserContext);
  const { healthDashState, healthDashDispatch, getStartEnd } = useHealthContext();
  const [isLoading, setLoadingData] = useState(false);
  const [networkDeviceTypeOptions, setNetworkDeviceTypeOptions] = useState([]);
  const { search } = useLocation();

  // New loading state for handling API calls during selection
  const [isFetchingData, setIsFetchingData] = useState(false);

  const getQueryParams = () => {
    return queryString.parse(window.location.search);
  };

  const fetchNetworkDeviceTypes = async () => {
    if (healthDashState.locationuuid) {
      const payload = {
        endRow: 500,
        startRow: 0,
        location_uuids: [healthDashState.locationuuid],
      };
      setLoadingData(true);
      try {
        const res = await getInventory(customerId, payload);
        setLoadingData(false);
        if (res?.data?.filter(item => item?.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER")?.length) {
          setNetworkDeviceTypeOptions(res?.data?.filter(item => item?.l_dvc_typ_name !== "ACCESS_POINT"));
        } else {
          setNetworkDeviceTypeOptions(res?.data);
        }
      } catch (err) {
        setLoadingData(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchNetworkDeviceTypes();
  }, [customerId, healthDashState.locationuuid]);

  useEffect(() => {
    if (networkDeviceTypeOptions) {
      const serialNumberQuery = getQueryParams().identifier;
      let selectedDevice = networkDeviceTypeOptions.find((option) => option?.r_dvc_unique_identifier === serialNumberQuery);
      if (!selectedDevice) {
        selectedDevice = null;
      }
      healthDashDispatch({ type: 'SET_DEVICE', payload: selectedDevice });
    }
  }, [networkDeviceTypeOptions, search, healthDashDispatch]);

  const handleSelectType = async (event, selectedOptions) => {
    if (selectedOptions) {
      setIsFetchingData(true); // Start loading
      try {
        // Fetch CPU usage data
        const startEndData = getStartEnd();
        const payload = {
          start: startEndData.start / 1000,
          end: startEndData.end / 1000,
          step: startEndData.step,
          device_type: selectedOptions?.r_dvc_unique_identifier,
        };
        const cpuUsageData = await networkHealthCpuUsage(customerId, payload);

        // Fetch availability status after fetching CPU usage
        const availabilityPayload = {
          start: startEndData.start / 1000,
          end: startEndData.end / 1000,
          device_type: selectedOptions?.r_dvc_unique_identifier,
        };
        const availabilityData = await availabilityStatus(customerId, availabilityPayload);

        // Navigate with fetched data
        navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${selectedOptions?.r_dvc_unique_identifier}`, {
          state: {
            cpuUsageData,
            availabilityData,
            deviceTypeName: selectedOptions?.l_dvc_typ_name,
            rDvcController: selectedOptions?.r_dvc_controller,
          }
        });
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsFetchingData(false); // End loading
      }
    } else {
      navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}`);
    }
  };

  if (isLoading) {
    return <Skeleton width={250} minWidth={200} />;
  }

  return (
    <>
    
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetchingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Autocomplete
        size="small"
        handleHomeEndKeys
        loading={isLoading}
        disabled={!healthDashState.locationuuid || isFetchingData} 
        options={networkDeviceTypeOptions}
        value={healthDashState.selectedDevice || null}
        sx={{ maxWidth: 300, minWidth: 200 }}
        isOptionEqualToValue={(option, value) => option?._uid === value?._uid}
        getOptionLabel={(option) => `${toCamelizeWords(option?.l_dvc_typ_name)} - ${option?.r_dvc_name} - ${option?.r_dvc_ip_address}`}
        renderOption={(props, option) => (
          <li {...props} key={option._uid}>
            {toCamelizeWords(option?.l_dvc_typ_name)} - {option?.r_dvc_name} - {option?.r_dvc_ip_address}
          </li>
        )}
        onChange={handleSelectType}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose devices"
            label="Devices"
            type="text"
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}


