import { Grid } from '@mui/material';
import IgnoreTable2 from './Components/IgnoreTable2';

export default function IgnoreRuleComponents() {
  return (
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12}>
          <IgnoreTable2 />
        </Grid>
      </Grid>
  );
}