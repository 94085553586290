// @mui
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
  } from '@mui/material';
  // components
  import QualityColors from '../../utils/QualityColors';
  // sections
  
  function createData(no, vpn, wan, uptime, quality) {
    return { no, vpn, wan, uptime, quality };
  }
  
  const rows = [
    createData(1, 'VPN1', 'ACT', '5%', 'Good'),
    createData(2, 'VPN2', 'Airtel', '8%', 'Good'),
    createData(3, 'VPN3', 'Jio-Fiber', '10%', 'Average'),
    createData(4, 'VPN4', 'Gigatel', '15%', 'Average'),
    createData(5, 'VPN5', 'Hatway', '24%', 'Bad'),
  ];
  
  // ----------------------------------------------------------------------
  
  export default function SdwanCard2() {
    const getStyles = (quality) => {
      return QualityColors.find((item) => item.quality === quality).style;
    };
    
    return (
      <>              
             
            <Grid item lg={6} md={6} sm={12} sx={{maxWidth:'700px'}}>
              <Paper sx={{ padding: 3 }} elevation={15}>
                <Typography
                  sx={{ mb: 1 }}
                  color="text.primary"
                  style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '5px' }}
                >
                  VPN Monitor
                </Typography>
                <TableContainer sx={{ paddingTop: '20px' }}>
                  <Table sx={{ minWidth: 250 }} aria-label="customized table">
                    <TableHead sx={{ background: '#f0f0f0' }}>
                      <TableRow>
                        <TableCell> No.</TableCell>
                        <TableCell> VPN</TableCell>
                        <TableCell> WAN</TableCell>
                        <TableCell> UP Time</TableCell>
                        <TableCell> Quality</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.no}>
                          <TableCell>{row.no}</TableCell>
                          <TableCell> {row.vpn}</TableCell>
                          <TableCell> {row.wan}</TableCell>
                          <TableCell> {row.uptime}</TableCell>
                          <TableCell style={getStyles(row.quality)}> {row.quality}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
  
      </>
    );
  }
  