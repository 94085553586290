// @mui
import { Box, Grid, IconButton, Paper, Typography, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect, useContext } from 'react';
import { DevicesUpIcon, LinksUpIcon, LinksDownIcon } from '../../../icons';
import { getEndpointStatistics } from '../../../API/Endpoints/showEndpoints';
import { UserContext } from '../../../context/UserContext';

const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
});

const EndpointCards = () => {
  const { customerId } = useContext(UserContext);
  const classes = useStyles();
  const [endpointStatisticsData, setEdpointStatisticsData] = useState([]);
  const [endpointStatisticsDataLoading, setEndpointStatisticsDataLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);



  const fetchLocationStatistics = async () => {
    setEndpointStatisticsDataLoading(true);
    getEndpointStatistics(customerId)
      .then((res) => {
        setEndpointStatisticsDataLoading(false);
        setLabels(Object.keys(res));
        setEdpointStatisticsData(Object.keys(res).map((t) => ({ label: t, value: res[t] })));
      })
      .catch(() => {
        setEndpointStatisticsDataLoading(false);
      });
  };

  useEffect(() => {
    fetchLocationStatistics();
  }, [customerId]);

  useEffect(() => {
    setTimeout(() => {
      setChartLoading(false)
    }, 3000)
  }, [])

  return (
    <>
      {endpointStatisticsData.map((item) => {
        return (
        <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
          <Card elevation={10}>
            <CardHeader
              title={
                chartLoading ? (
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                            {item.label}
                  </Typography>                
                )
              }
              avatar={
                chartLoading ? (
                  <Skeleton animation="wave" variant="circular" width={40} height={45} />
                ) : (
                  <IconButton size="large" sx={{ color: 'blue', background: '#c3cfe6', borderRadius: '12px' }}>
                    <LinksUpIcon />
                  </IconButton>
                )
              }
            />
            <CardContent>
              {chartLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={300}
                  height={80}
                />
              ) : (
                <Typography variant="h1" component="div">
                  {item.value}
                </Typography>)}
            </CardContent>
          </Card>
        </Grid>
        );
      })}
    </>
  );
};
export default EndpointCards;
