
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { sendSupportMail } from '../../../API/Support/Support';
import { UserContext } from '../../../context/UserContext';
import { FormProvider } from '../../../components/hook-form';


const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  textArea: {
    width:'100%', 
    height: 180, 
    fontFamily:'inherit',
    borderRadius:'5px', 
    padding:'5px',
    border: '1px solid #ccc',
  }
});


export default function SupportDailogComponent(props) {
  const { opendialog, handleClose } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [supportLoading, setSupportLoading] = useState(false);


  const ref = useRef(null);

  const supportSchema = Yup.object().shape({
    message: Yup.string(),
  });

  const defaultValues = {
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(supportSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = async () => {
    if(!ref.current.value)
        return
    const data = {
      message: ref.current.value,
    };
    setSupportLoading(true);
    setLoading(true);
    sendSupportMail(customerId, data)
      .then((res) => {
        setSupportLoading(false);
        setLoading(false);
        handleClose();
        enqueueSnackbar(
          'Message sent sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
       
      })
      .catch((res) => {
        setSupportLoading(false);
        setLoading(false);
        handleClose();
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <Dialog
      open={opendialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{py:1.3}}>
          <div>
            <Typography variant="h4">Contact support</Typography>
          </div>
          <div>
            <Typography>Following message will be sent to support team</Typography>
          </div>
      </DialogTitle>

      <Divider />
      {supportLoading && <LinearProgress />}

        <DialogContent sx={{ py: 2, px: 2 }}>
            <textarea
              ref={ref} id="message" name="message"
              aria-label="minimum height"
              placeholder="Type your message"
              className={classes.textArea}
            />
        </DialogContent>
      <Divider />

        <DialogActions>
          <Button variant="text" color="primary" sx={{ float: 'right' }} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton sx={{ float: 'right' }} variant="outlined" color="primary" loading={loading} type="submit">
            send
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
