
import { SnackbarProvider } from "notistack";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import ScrollToTop from "./components/ScrollToTop";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      
        <SnackbarProvider maxSnack={3}>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </SnackbarProvider>
      
    </ThemeProvider>
  );
}
