
// import { MonitorHeart, Pause, PlayArrow } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
// import {
//   Box,
//   IconButton,
//   Stack,
//   Tooltip,
//   CircularProgress
// } from '@mui/material';
// import { useSnackbar } from 'notistack';
// import PropTypes from 'prop-types';
// import { useContext, useEffect, useState } from 'react';
// import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
// import { useNavigate } from 'react-router-dom';
// // import { WithConfirm } from '../../../WithConfirm';
// import { WithConfirm } from '../../WithConfirm';


// const ActionRender = (props) => {
//   console.log("props",props);
  
//   if (props?.data) {
//     return (
//       <>
//       <Box>
//       <TipsAndUpdatesIcon color="warning" />
//       </Box>
//       </>
//     );
//   }
//   return null;
 
// };

// ActionRender.propTypes = {
//   data: PropTypes.object,
//   confirm: PropTypes.func.isRequired,
// };

// export default WithConfirm(ActionRender);

import { MonitorHeart, Pause, PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useNavigate } from 'react-router-dom';
import { WithConfirm } from '../../WithConfirm';
import WifiTroubleshootInsightsDialog from '../WifiTroubleshootInsightsDialog';

const ActionRender = (props) => {
  const [openInsightDialog, setOpenInsightDialog] = useState(false);
  const { data, confirm } = props;

  const handleIconClick = () => {
    setOpenInsightDialog(true);
  };
  if (props?.data) {
  return (
    <>
      <Box>
        <Tooltip title="View Insights">
          <IconButton onClick={handleIconClick}>
            <TipsAndUpdatesIcon color="warning" />
          </IconButton>
        </Tooltip>
      </Box>
      {data && (
        <WifiTroubleshootInsightsDialog
          value={data}
          openInsightDialog={openInsightDialog}
          setOpenInsightDialog={setOpenInsightDialog}
        />
      )}
    </>
  );
}
return null;
};

ActionRender.propTypes = {
  data: PropTypes.object,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(ActionRender);
