/* eslint-disable*/
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from 'react';
import { fDateOnly } from 'src/utils/formatTime';
import { holdUntillDeviceSummaryChange } from '../../../../../API/CMDBOperations';
import { UserContext } from '../../../../../context/UserContext';
import SummaryDiff from './SummaryDiff';

// -----------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// --------------------------------------------------------------------------
function HoldtillDialog(props) {
  const classes = useStyles();
  const today = format(new Date(), `yyyy-MM-dd`);
  const { openDialog, handleClose, data, cmdbGroupSummaryEvent } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [inputMessage, setinputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false)
  const [alertText, setAlertText] = useState('');
  const [enable, setEnable] = useState(false);
  const [holdUntill, setHoldUntillDate] = useState('');
  const [dateErorMsg, setDateErorMsg] = useState('');

  // -------------------------------------------------------------------------------------------

  useEffect(() => {
    const a = data?.c_m_d_b_network_device?.r_dvc_latest_approved_config_change_version;
    const b = data?.r_dvc_config_change_version;

    if (b - 1 === a) {
      setAllowed(true)
      setAlertText("Are you sure you want to hold untill ?")
    }
    if (b - 1 > a) {
      setAllowed(false)
      setAlertText(" Cannot approve this change as there are past un-approved changes. Please review them and then proceed.")
    }
    if (b - 1 < a) {
      setAllowed(false)
      setAlertText("The change is already approved")
    }
  }, [data])

  useEffect(() => {
    if (allowed === true) {
      if (inputMessage.length > 0 && holdUntill.length > 0) {
        setEnable(true);
        setDateErorMsg('');
        return
      }
    }
    setEnable(false)
    setDateErorMsg('Please select date');
  }, [allowed, inputMessage, holdUntill])


  const handleHoldUntillDate = (date) => {
    setHoldUntillDate(fDateOnly(date.$d));
  };

  const handleinputMessage = (event) => {
    setinputMessage(event.target.value);
  };

  const handleConfirm = () => {
    const payload = {
      message: inputMessage,
      hold_untill: holdUntill,
    }
    setLoading(true);
    holdUntillDeviceSummaryChange(customerId, data._uid, payload)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'The changes are set to hold untill',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
        props.api.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  }


  // -------------------------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box style={{ textAlign: 'left' }}>
            Hold change untill
            <Typography variant="body2" gutterBottom>
              {data?.c_m_d_b_network_device?.r_dvc_ip_address} - {data?.c_m_d_b_network_device?.r_dvc_serial_number}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SummaryDiff cmdbGroupSummaryEvent={cmdbGroupSummaryEvent} />
        <Alert severity="warning">
          {alertText}
        </Alert>

        <TextField
          multiline
          rows={3}
          fullWidth
          size="normal"
          label="Message *"
          variant="outlined"
          placeholder="Add Message"
          onChange={handleinputMessage}
          InputLabelProps={{ shrink: true }}
        // error={!enable}
        // helperText={!enable ? 'Please write message' : ''}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disablePast
            minDate={today}
            label="Hold untill (Due date)*"
            format="DD/MM/YYYY"
            onChange={handleHoldUntillDate}
            slotProps={{
              textField: {
                placeholder: 'Select date',
                InputLabelProps: { shrink: true },
                //  error: !enable,
                //  helperText: dateErorMsg,
              }
            }}
          />
        </LocalizationProvider>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!enable}
          onClick={handleConfirm}
          loading={loading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default HoldtillDialog;
