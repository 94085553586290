import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import SupportDailogComponent from './SupportDialogComponent';

// ----------------------------------------------------------------------
export default function Support() {
  const [openSupportDialog, setOpenSupportDialog] = useState(false);

  const handleCellClick = () => {
    setOpenSupportDialog(true);
  };

  const handleCellClickClose = () => {
    setOpenSupportDialog(false);
  };

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '1111',
        right: '30px',
        bottom: '30px',
        border: '2px solid #eeeee',
        borderRadius: '45px',
      }}
    >
      <Tooltip title="Send email to support team">
        <IconButton
          sx={{ background: 'rgb(0,154,248)', px: 2, py: 2, "&:hover": { backgroundColor: '#7fccfb' } }}
          onClick={handleCellClick}
        >
          <ForwardToInboxOutlinedIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
        </IconButton>
      </Tooltip>
      <SupportDailogComponent opendialog={openSupportDialog} handleClose={handleCellClickClose} />
    </div>
  );
}
