import * as React from 'react';
import { useContext, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
  Typography, Autocomplete, TextField, InputAdornment, Snackbar, Alert
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { createFilterOptions } from '@mui/material/Autocomplete'; // Import the createFilterOptions
import {
  DataGrid, useGridApiRef
} from '@mui/x-data-grid';
import { getDeviceTypes } from '../../API/DeviceTypes/index';
import { markAsInventory } from '../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../context/UserContext';


export default function NetworkDevicesDialog(props) {
  const { openDialog, handleClose, selectedRecords, gridApi } = props;
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [deviceTypesOptions, setDeviceTypesOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])
  const [processedRow, setProcessedRow] = useState(null)
  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([]);
  const [isApiCallCompleted, setIsApiCallCompleted] = useState(false);
  const scrollRef = useRef(null);
  const filter = createFilterOptions();
  const [selectedItems, setSelectedItems] = useState(
    selectedRecords.map((item, ind) => ({
      ...item,
      key: ind,
      id: ind,
      r_dvc_os: '',
      r_dvc_vendor: '',
      l_dvc_typ_id: item.deviceType?.location_id,
      _status: 'New',
      _isDirty: false
    }))
  );

  const isSaveDisabled = () => {
    return selectedItems.some(item =>
      !item.r_dvc_mac_address || isEmpty(item.r_dvc_mac_address) ||
      !item.r_dvc_serial_number || isEmpty(item.r_dvc_serial_number) ||
      !item.l_dvc_typ_name || isEmpty(item.l_dvc_typ_name) ||
      !item.r_dvc_os || isEmpty(item.r_dvc_os) ||
      !item.l_dvc_typ_vendor || isEmpty(item.l_dvc_typ_vendor) ||
      !item.r_dvc_name || isEmpty(item.r_dvc_name) ||
      !item.r_dvc_host || isEmpty(item.r_dvc_host)
    );
  };
  const handleScrollRight = () => {
    const scrollWidth = scrollRef.current.scrollWidth;
    const clientWidth = scrollRef.current.clientWidth;
    const maxScrollLeft = scrollWidth - clientWidth;

    // Scroll to the end of the container
    scrollRef.current.scrollTo({ left: maxScrollLeft, behavior: "smooth" });
  };


  const [_isDirty, setIsDirty] = useState(false);
  const setError = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message,
    }));
  };


  const fetchDeviceType = async () => {
    try {
      const res = await getDeviceTypes(customerId);

      const deviceTypes = res.l_dvc_typ_name.map((name, index) => {
        return {
          l_dvc_typ_name: name,
        };
      });
      setDeviceTypesOptions(deviceTypes);

      const osOptions = res.r_dvc_os.map((os) => ({
        r_dvc_os: os,
      }));
      setOsOptions(osOptions);

      const vendorOptions = res.l_dvc_typ_vendor.map((vendor) => ({
        l_dvc_typ_vendor: vendor,
      }));
      setVendorOptions(vendorOptions);

    } catch (err) {
      console.error(err);
    }
  };


  const handleSubmit = async () => {
    const _selectedItems = selectedItems.map((value, index) => {
      const isMacAddressEmpty = isEmpty(value?.r_dvc_mac_address);
      const isSerialNumberEmpty = isEmpty(value?.r_dvc_serial_number);
      const isDeviceTypeEmpty = isEmpty(value?.l_dvc_typ_name);
      const isVendorEmpty = isEmpty(value?.l_dvc_typ_vendor);
      const isOSEmpty = isEmpty(value?.r_dvc_os);
      const isDeviceNameEmpty = isEmpty(value?.r_dvc_name);
      const isModelEmpty = isEmpty(value?.r_dvc_host);


      const newErrors = {
        r_dvc_mac_address: isMacAddressEmpty ? 'Mac Address is required' : '',
        r_dvc_serial_number: isSerialNumberEmpty ? 'Serial Number is required' : '',
        l_dvc_typ_name: isDeviceTypeEmpty ? 'Device Type is required' : '',
        l_dvc_typ_vendor: isVendorEmpty ? 'Vendor is required' : '',
        r_dvc_os: isOSEmpty ? 'OS is required' : '',
        r_dvc_name: isDeviceNameEmpty ? 'Device Name is required' : '',
        r_dvc_host: isModelEmpty ? 'Model is required' : '',
      };


      const hasErrors = Object.values(newErrors).some((error) => error);

      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: newErrors,
      }));

      return {
        ...value,
        _isDirty: value._isDirty,
        r_dvc_vendor: value?.l_dvc_typ_vendor,
        l_dvc_typ_id: value?._uid,
        r_dvc_os: value?.r_dvc_os,
        _status: hasErrors ? 'Fail' : 'Pass',
        _statusmessage: hasErrors
          ? 'All fields are required: MAC, Serial Number, Device Type, Vendor, OS, Device Name, Model'
          : '',
        message: value.message || '',

      };
    });

    setSelectedItems(_selectedItems);

    const passedObjects = _selectedItems.filter((item) => item._status === 'Pass');
    if (passedObjects.length === 0) {
      return;
    }


    passedObjects.forEach((item) => {
      const processedData = processedRow || item;
      _updateAsNetwork(processedData);

    });
  };

  // const _updateAsNetwork = async (value) => {
  //   const data = {
  //     _uid: value.r_dvc_ip_address,
  //     type: 'NETWORK',
  //     name: value.r_dvc_name,
  //     ip_address: value.r_dvc_ip_address,
  //     serial_number: value.r_dvc_serial_number,
  //     mac_address: value.r_dvc_mac_address,
  //     host: value.r_dvc_host,
  //     status: value.g_status,
  //     type_name: value.l_dvc_typ_name,
  //     vendor: value.l_dvc_typ_vendor,
  //     os: value.r_dvc_os,
  //     source: "MANUAL",
  //     network_device_id: value.network_device_id
  //   };
  //   // return markAsInventory(customerId, value.r_dvc_ip_address, data);
  //   try {
  //     const response = await markAsInventory(customerId, value.r_dvc_ip_address, data);
  //     // Assuming the response contains a message field
  //     const updatedRow = {
  //       ...value,
  //       message: response.data.message || 'No message' // Set response message in the 'message' field
  //     };
  //     // Update your data grid here
  //     setSelectedItems((prevItems) =>
  //       prevItems.map((row) =>
  //         row.r_dvc_ip_address === value.r_dvc_ip_address ? updatedRow : row
  //       )
  //     );
  //     setIsApiCallCompleted(true);
  //     gridApi.purgeInfiniteCache();

  //   } catch (error) {
  //     console.error('Error updating as network:', error);
  //     gridApi.purgeInfiniteCache();

  //   }
  // };
  let successCount = 0;
  let failureCount = 0;
  
  const _updateAsNetwork = async (value) => {
    const data = {
      _uid: value.r_dvc_ip_address,
      type: 'NETWORK',
      name: value.r_dvc_name,
      ip_address: value.r_dvc_ip_address,
      serial_number: value.r_dvc_serial_number,
      mac_address: value.r_dvc_mac_address,
      host: value.r_dvc_host,
      status: value.g_status,
      type_name: value.l_dvc_typ_name,
      vendor: value.l_dvc_typ_vendor,
      os: value.r_dvc_os,
      source: "MANUAL",
      network_device_id: value.network_device_id
    };
  
    try {
      const response = await markAsInventory(customerId, value.r_dvc_ip_address, data);
      const updatedRow = {
        ...value,
        message: response.data.message || 'No message' // Set response message in the 'message' field
      };
  
      setSelectedItems((prevItems) =>
        prevItems.map((row) =>
          row.r_dvc_ip_address === value.r_dvc_ip_address ? updatedRow : row
        )
      );
      successCount++;
  
      if (successCount + failureCount === selectedItems.length) {
        if (successCount === selectedItems.length) {
          enqueueSnackbar('All unknown devices were marked as known devices successfully', { 
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        } else {
          enqueueSnackbar('Error in marking unknown device as known device', { 
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        }
      }
  
      setIsApiCallCompleted(true);
      gridApi.purgeInfiniteCache();
    } catch (error) {
      console.error('Error updating as network:', error);
      failureCount++;
  
      if (successCount + failureCount === selectedItems.length) {
        // This means all API calls are completed
        enqueueSnackbar('Error in marking unknown device as known device', { variant: 'error' });
      }
  
      gridApi.purgeInfiniteCache();
    }
  };
  
  useEffect(() => {
    fetchDeviceType();
  }, [customerId]);

  const DeviceTypeAutocomplete = ({ params, deviceTypesOptions }) => {
    const [deviceTypeValue, setDeviceTypeValue] = useState(params.value || null);
    const [deviceTypeLoading, setDeviceTypeLoading] = useState(false);

    return (
      <Autocomplete
        value={deviceTypeValue || null}
        fullWidth
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setDeviceTypeValue({ l_dvc_typ_name: newValue });
          }
          else if (newValue && newValue.inputValue) {
            setDeviceTypeLoading(true);
            setDeviceTypeValue(newValue);
          }
          else {
            setDeviceTypeValue(newValue);
          }
          params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.l_dvc_typ_name || '' }, event);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue.toLowerCase() === option.l_dvc_typ_name.toLowerCase()
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Create "${inputValue}"`,
              l_dvc_typ_name: inputValue,
              create: true,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={deviceTypesOptions}
        getOptionLabel={(option) => option.l_dvc_typ_name || ''}
        renderOption={(props, option) => (
          <li {...props}>
            {option.create ? option.label : option.l_dvc_typ_name}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}

            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  };

  const OSAutocomplete = ({ params, osOptions }) => {
    const [osValue, setOsValue] = useState(params.value || null);
    const [osLoading, setOsLoading] = useState(false);

    return (
      <Autocomplete
        value={osValue || null}
        fullWidth
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setOsValue({ r_dvc_os: newValue });
          } else if (newValue && newValue.inputValue) {
            setOsLoading(true);
            setOsValue(newValue);
          } else {
            setOsValue(newValue);
          }
          params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.r_dvc_os || '' }, event);
        }}
        filterOptions={(options = [], params) => {
          const filtered = options.filter(option =>
            option.r_dvc_os.toLowerCase().includes(params.inputValue.toLowerCase())
          );
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue.toLowerCase() === option.r_dvc_os.toLowerCase()
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Create "${inputValue}"`,
              r_dvc_os: inputValue,
              create: true,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={osOptions}
        getOptionLabel={(option) => option.r_dvc_os || ''}
        renderOption={(props, option) => (
          <li {...props}>
            {option.create ? option.label : option.r_dvc_os}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  };

  const VendorAutocomplete = ({ params, vendorOptions }) => {
    const [vendorValue, setVendorValue] = useState(params.value || null);
    const [vendorLoading, setVendorLoading] = useState(false);

    return (
      <Autocomplete
        value={vendorValue || null}
        fullWidth
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setVendorValue(newValue);
          } else {
            setVendorValue(newValue);
          }
          params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.l_dvc_typ_vendor || '' }, event);
        }}
        filterOptions={(options, params) => {
          const filtered = options.filter(option =>
            option.l_dvc_typ_vendor.toLowerCase().includes(params.inputValue.toLowerCase())
          );
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue.toLowerCase() === option.l_dvc_typ_vendor.toLowerCase()
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Create "${inputValue}"`,
              l_dvc_typ_vendor: inputValue,
              create: true,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={vendorOptions}
        getOptionLabel={(option) => option.l_dvc_typ_vendor || ''}
        renderOption={(props, option) => (
          <li {...props}>
            {option.create ? option.label : option.l_dvc_typ_vendor}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  };

  const columns = [
    {
      field: 'r_dvc_ip_address',
      sortable: false,
      headerName: 'IP Address',
      width: 150,
      editable: false
    },
    {
      field: 'r_dvc_mac_address',
      headerName: 'Mac Address *',
      width: 210,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'r_dvc_serial_number',
      headerName: 'Serial Number *',
      width: 210,
      editable: true,
    },
    {
      field: 'l_dvc_typ_name',
      headerName: 'Device Type *',
      width: 230,
      editable: true,
      renderEditCell: (params) => (
        <DeviceTypeAutocomplete
          params={params}
          deviceTypesOptions={deviceTypesOptions}
        />
      ),
    },
    {
      field: 'r_dvc_os',
      headerName: 'OS *',
      width: 230,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params) => (
        <OSAutocomplete
          params={params}
          osOptions={osOptions}
        />
      ),

    },
    {
      field: 'l_dvc_typ_vendor',
      headerName: 'Vendor *',
      width: 230,
      editable: true,
      renderEditCell: (params) => (
        <VendorAutocomplete
          params={params}
          vendorOptions={vendorOptions}
        />
      ),
    },
    {
      field: 'r_dvc_name',
      headerName: 'Device Name *',
      sortable: false,
      width: 150,
      editable: true,
    },
    {
      field: 'r_dvc_host',
      headerName: 'Model *',
      width: 150,
      editable: true,
    },
    ...(isApiCallCompleted ? [{
      field: 'message',
      headerName: 'Message',
      width: 280,
      editable: false,
      renderCell: (params) => {
        const message = params.value || '';
        const isSuccess = message === 'Network device updated successfully';
        const color = isSuccess ? 'green' : 'red';

        return (
          <span className="message-column" style={{ color }}>
            {message}
          </span>
        );
      },
    }
    ] : [])

  ];
  const allMessagesMapped = selectedItems.every(item => item.message && item.message !== '');
  const dataGridRef = useRef(null);

  useEffect(() => {
    if (isApiCallCompleted) {
      setTimeout(() => {
        const gridElement = dataGridRef.current?.querySelector('.MuiDataGrid-virtualScroller');
        if (gridElement) {
          gridElement.scrollLeft = gridElement.scrollWidth; // Scroll to the end
        }
      }, 100); // Delay to ensure layout updates
    }
  }, [isApiCallCompleted]);


  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"

      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ display: 'flex', flexDirection: 'column' }}>
            Add to network devices
            <span style={{ fontSize: '12px', fontWeight: 'lighter', color: 'red' }}>Fields with * are mandatory</span>
          </Typography>
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3, px: 3 }}>
          <div
            // ref={scrollRef}
            style={{
              display: "flex",
              overflowX: "auto",
              width: '100%',
              whiteSpace: "nowrap",
            }}
          >
            <Box
              sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
              ref={dataGridRef} // Attach the ref to the container
            >
              <DataGrid
                rows={selectedItems}
                columns={columns}
                editMode="row"
                onCellEditCommit={(params) => console.log("Edit Commit:", params)}
                processRowUpdate={(newRow) => {
                  const updatedItems = selectedItems.map((item) =>
                    item.id === newRow.id ? { ...item, ...newRow, _isDirty: true } : item
                  );
                  setSelectedItems(updatedItems);
                  return newRow;
                }}
              />
            </Box>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          {allMessagesMapped ? (
            <Button
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSaveDisabled()}
              >
                Save
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>

    </>
  );
}