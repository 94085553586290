
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { format } from 'date-fns'; // Import necessary functions

const MainCpuChart = ({ seriesData }) => {
    const chartOptions = {
        chart: {
            type: 'area',
            height: 260,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ['#ef9a9a'],
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: false,
            y: {
                formatter: value => `${value?.toFixed(0)}%`,
            },
            x: {
                formatter: (value) => {
                    const date = new Date(value);
                    if (!isNaN(date)) { // Check if date is valid
                        return `${format(date, 'hh:mm a')}\n${format(date, 'dd/MM')}`;
                    }
                    return 'Invalid Date';
                },
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            offsetX: 0.5,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false, // Use local time
                formatter(value) {
                    const date = new Date(value);
                    if (!isNaN(date)) { // Check if date is valid
                        return `${format(date, 'hh:mm a')}\n${format(date, 'dd/MM')}`;
                    }
                    return 'Invalid Date';
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: value => `${value.toFixed(0)}%`,
            },
        },
    };

    return (
        <div>
            {seriesData.length > 0 && seriesData[0].data.length > 0 ? (
                <ReactApexChart series={seriesData} options={chartOptions} type="area" height={260} />
            ) : (
                <div>No data available</div>
            )}
        </div>
    );
};

export default MainCpuChart;


// import React from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { format } from 'date-fns'; // Import necessary functions

// const MainCpuChart = ({ seriesData }) => {
//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 260,
//             toolbar: {
//                 show: true,
//                 tools: {
//                     download: true,
//                     selection: true,
//                     zoom: true,
//                     zoomin: true,
//                     zoomout: true,
//                     pan: true,
//                     reset: true,
//                 },
//                 autoSelected: 'zoom',
//             },
//             zoom: {
//                 enabled: true,
//                 type: 'xy', // Enable zooming in both x and y directions
//                 autoScaleYaxis: true,
//                 zoomedArea: {
//                     fill: {
//                         color: '#90CAF9',
//                         opacity: 0.4,
//                     },
//                     stroke: {
//                         color: '#0D47A1',
//                         opacity: 0.4,
//                         width: 1,
//                     },
//                 },
//             },
//         },
//         annotations: {
//             yaxis: [
//                 {
//                     y: 30,
//                     borderColor: '#FF0000',
//                     label: {
//                         borderColor: '#FF0000',
//                         style: {
//                             color: '#fff',
//                             background: '#FF0000',
//                         },
//                         text: 'Threshold',
//                     },
//                 },
//             ],
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 2,
//         },
//         colors: ['#ef9a9a'], // Keep the color same as before
//         dataLabels: {
//             enabled: false,
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: (value) => `${value?.toFixed(0)}%`,
//             },
//             x: {
//                 formatter: (value) => {
//                     const date = new Date(value);
//                     if (!isNaN(date)) { // Check if date is valid
//                         return `${format(date, 'hh:mm a')}\n${format(date, 'dd/MM')}`;
//                     }
//                     return 'Invalid Date';
//                 },
//             },
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             horizontalAlign: 'left',
//             showForSingleSeries: true,
//             offsetX: 0.5,
//             markers: {
//                 width: 15,
//                 height: 7,
//                 strokeWidth: 0,
//                 radius: 12,
//                 offsetX: 0,
//                 offsetY: 0,
//             },
//         },
//         xaxis: {
//             type: 'datetime',
//             labels: {
//                 datetimeUTC: false, // Use local time
//                 formatter(value) {
//                     const date = new Date(value);
//                     if (!isNaN(date)) { // Check if date is valid
//                         return `${format(date, 'hh:mm a')}\n${format(date, 'dd/MM')}`;
//                     }
//                     return 'Invalid Date';
//                 },
//             },
//             tooltip: {
//                 enabled: false,
//             },
//         },
//         yaxis: {
//             forceNiceScale: true,
//             labels: {
//                 formatter: (value) => `${value.toFixed(0)}%`,
//             },
//         },
//     };

//     return (
//         <div>
//             {seriesData.length > 0 && seriesData[0].data.length > 0 ? (
//                 <ReactApexChart series={seriesData} options={chartOptions} type="area" height={260} />
//             ) : (
//                 <div>No data available</div>
//             )}
//         </div>
//     );
// };

// export default MainCpuChart;
