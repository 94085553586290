/* eslint-disable */
import OverView from './RelatedToGraph/OverView';
import { Card } from '@mui/material';
import { ReactFlowProvider } from 'reactflow';
import { MapSettingProvider } from '../components/RelatedToGraph/mapSettingProvider';


const GlobalGraph = () => {
    return (
        <>
          <Card sx={{ height: 'auto', px: 0, py: 0 }} variant="outlined">
            <MapSettingProvider>
              <ReactFlowProvider>
                <OverView />
              </ReactFlowProvider>
            </MapSettingProvider>
          </Card>
        </>
      );
};
export default GlobalGraph;
