import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import { EndpointsComponents } from '../components/EndpointsComponents';

// ----------------------------------------------------------------------

export default function Endpoint() {
  return (
    <Page title="Endpoint">
      <Container maxWidth={false}>
        <div role="presentation" style={{   paddingBottom: '20px', display: 'flex', alignItems: 'center',position:'relative'}}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5, cursor: 'pointer' }}>
              <HomeOutlinedIcon
              sx={{
                fontSize: {
                  xs: '20px',
                  sm: '22px',
                  md: '24px',
                  lg: '26px',
                  xl: '28px',
                  xxl: '30px',
                },
              }} />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/inventory"
            sx={{
              cursor: 'pointer',
              fontSize: {
                xs: '8px',
                sm: '12px',
                md: '14px',
                lg: '16px',
                xl: '18px',
                xxl: '20px',
              },
            }}>
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/endpoint" sx={{ cursor: 'pointer',
            fontSize: {
              xs: '8px',
              sm: '12px',
              md: '14px',
              lg: '16px',
              xl: '18px',
              xxl: '20px',
            },
          }}>
            Client Devices
            </Typography>
          </Breadcrumbs>
        </div>
        <EndpointsComponents />
      </Container>
    </Page>
  );
}
