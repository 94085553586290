/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// import { haClusterMEMusageRatio } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import MainMEMUsageRatio from '../../../MainCpuChart/MainMEMUsageratio';



// -------------------------------------
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 250,
}));

// ---------------------------------------


const MEMUsageRatio = ({expandedState, allData}) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async() => {
        // if(!healthDashState.selectedDevice || expandedState === false){
        //     return '';
        // }
        fetchMEMUsageRatio();
    }

    const fetchMEMUsageRatio = async () => {
        const data = getStartEnd()
        // const payload = {
        //     start: data.start / 1000,
        //     end: data.end / 1000,
        //     step: data.step,
        //     // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
        //     device_os: healthDashState.selectedDevice.r_dvc_os
        // }
        setChartLoading(true);
        // networkHealthCpuUsage(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
        //     .then((res) => {
            const cpuData = allData?.hacluster_table[0]?.device_hamem || [];
            const timestamps = allData?.timestamps || [];
            
            // Convert CPU data to numbers
            const convertedArray = cpuData.map(Number);
            
            // Format X-axis data
            const formattedXAxisData = timestamps.map(timestamp => {
                const date = new Date(timestamp * 1000);
                const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                return `${timeString}\n${dateString}`;
            });
            
            // Update state
            setChartLoading(false);
            setSeriesData([{ name: 'Member Packet total', data: convertedArray }]);
            setXAxisData(formattedXAxisData);
            // })
            // .catch(() => {
            //     setChartLoading(false);
            // });
    };
    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    const chartSeries = seriesData;

    const chartOptions = {
        chart: {
            type: 'area',
            height: 250,
            stacked: true,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'smooth',
            width: 1,
        },
        colors: ['#ef9a9a','#f48fb1','#ce93d8','#fff59d','#b39ddb','#9fa8da','#90caf9','#81d4fa','#80deea','#80cbc4','#a5d6a7','#c5e1a5','#e6ee9c','#ffe082','#ffcc80','#ffab91'],
        dataLabels: {
            enabled: false,
            dropShadow: { enabled: true }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (value) => value.toFixed(0),
            },
            x: {
                show: false,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            offsetX: 0.5,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
        },
        xaxis: {
            categories: xAxisData,
            type: 'datetime',
            labels: {
                hideOverlappingLabels: true,
                formatter: function (value) {
                    const date = new Date(value*1000);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    return timeString;
                },
            }
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: (value) => value.toFixed(0),
            },
        },
    };

    return (
        <>
            {chartLoading && <LinearProgress sx={{height:'2px'}} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Memory Usage Ratio
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                        <ChartWrapperStyle dir="ltr">
                        {seriesData?.length > 0 && xAxisData?.length > 0 && <MainMEMUsageRatio seriesData={seriesData} xAxisData={xAxisData} />}
                        </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default MEMUsageRatio;
