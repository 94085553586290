// // @mui
// import CloseIcon from '@mui/icons-material/Close';
// import { useState, useContext, useEffect } from 'react';
// import * as Yup from 'yup';
// import LoadingButton from '@mui/lab/LoadingButton';
// import { useSnackbar } from 'notistack';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   LinearProgress,
//   Stack,
//   Divider,
//   createFilterOptions,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { RHFTextField, FormProvider, RHFSubnetSelector } from '../../hook-form';
// import { UserContext } from '../../../context/UserContext';
// // import SubnetSelector from './SubnetSelector';
// import { getZones } from '../../../API/Zones/zones';
// import ZoneSelector from './ZoneSelector';
// import { addLocation } from '../../../API/Locations/showLocations';

// const useStyles = makeStyles({
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
// });

// export default function AddLocationDialog(props) {
//   const classes = useStyles();
//   const { enqueueSnackbar } = useSnackbar();
//   const { openDialog, handleClose, gridApi } = props;
//   const { customerId } = useContext(UserContext);
//   const [zoneOptions, setZoneOptions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [zoneLoading, setZoneLoading] = useState(false);
//   const [selectedSubnets, setSelectedSubnets] = useState([]);
//   const [zoneValue, setZoneValue] = useState(null);
//   const [page , setPage]=useState(0)


//   useEffect(() => {
//     setZoneLoading(true);
//     getZones(customerId , page).then((res) => {
//       setPage(res.current_page - 1);
//       setZoneOptions(res.data);
//       setZoneLoading(false);
//     });
//   }, []);

//   const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;
//   const locationNameRegex = /^[A-Za-z][A-Za-z ,/_-]*[A-Za-z]$/;
//   const phoneNumberRegex = /^\d{10}$/;

//   const membersSchema = Yup.object().shape({
//     locationName: Yup.string()
//       .matches(locationNameRegex, 'Invalid location name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
//       .required('Location name required'),
//     locationAddress: Yup.string(),
//     locationContact: Yup.string()
//       .matches(phoneNumberRegex, 'Phone number must be exactly 10 digits')
//       .required('Phone number is required'),
//     subnets: Yup.array()
//       .of(
//         Yup.object().shape({
//           value: Yup.string().matches(subnetRegex, "Invalid subnet"),
//           label: Yup.string()
//         })
//       )
//       .min(1, "Min 1 subnet is required"),
//     zoneValue: Yup.string(),
//   });

//   const defaultValues = {
//     locationName: '',
//     locationAddress: '',
//     locationContact: '',
//     subnets: [],
//     zoneValue: '',
//   };

//   const methods = useForm({
//     resolver: yupResolver(membersSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = async (value) => {
//     const data = {
//       name: value.locationName,
//       subnets: value.subnets.map(item => item.value),
//       address: value.locationAddress,
//       contact: value.locationContact,
//       zone_id: zoneValue?.id ?? null,
//     };
//     setLoading(true);
//     addLocation(customerId, data)
//       .then((res) => {
//         setLoading(false);
//         handleClose();
//         gridApi.purgeInfiniteCache();       
//         enqueueSnackbar(
//           'Location added sucessfully',
//           {
//             variant: 'success',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       })
//       .catch((res) => {
//         setLoading(false);
//         enqueueSnackbar(
//           res.response.data.message,
//           {
//             variant: 'error',
//             anchorOrigin: {
//               vertical: 'bottom',
//               horizontal: 'right',
//             },
//           },
//           500
//         );
//       });
//   };

//   return (
//     <>
//       <Dialog
//         open={openDialog}
//         onClose={handleClose}
//         fullWidth
//         maxWidth="sm"
//         style={{margin:0}}
//         aria-labelledby="responsive-dialog-title"
//         classes={{
//           scrollPaper: classes.topScrollPaper,
//           paperScrollBody: classes.topPaperScrollBody,
//         }}
//       >
//         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//           <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//             Add location
//             <IconButton onClick={handleClose} sx={{ float: 'right' }}>
//               <CloseIcon sx={{ fontSize: '1.5rem' }} />
//             </IconButton>
//           </DialogTitle>
//           <Divider />
//           {loading && <LinearProgress />}
//           <DialogContent >
//             <Stack spacing={3} sx={{ py: 2 }}>
//               <RHFTextField name="locationName" label="Location name *" placeholder="Enter location name" />
//               <RHFTextField name="locationAddress" label="Location address *" placeholder="Enter location address" />
//               <RHFTextField name="locationContact" label="Location contact *" placeholder="Enter location contact" />
//               <RHFSubnetSelector name="subnets" setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets}/>
//               <ZoneSelector
//                 zoneOptions={zoneOptions}
//                 zoneLoading={zoneLoading}
//                 setZoneValue={setZoneValue}
//                 zoneValue={zoneValue}
//               />
//             </Stack>
//           </DialogContent>
//           <Divider />
//           <DialogActions>
//             <Button onClick={handleClose}>Cancel</Button>
//             <LoadingButton type="submit" variant="contained" loading={loading}>
//               Add location
//             </LoadingButton>
//           </DialogActions>
//         </FormProvider>
//       </Dialog>
//     </>
//   );
// }

import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
  createFilterOptions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider, RHFSubnetSelector } from '../../hook-form';
import { UserContext } from '../../../context/UserContext';
// import SubnetSelector from './SubnetSelector';
import { getZones } from '../../../API/Zones/zones';
import ZoneSelector from './ZoneSelector';
import { addLocation } from '../../../API/Locations/showLocations';
 
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});
 
export default function AddLocationDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, gridApi } = props;
  const { customerId } = useContext(UserContext);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [zoneValue, setZoneValue] = useState(null);
  const [page , setPage]=useState(0)
 
 
  useEffect(() => {
    setZoneLoading(true);
    getZones(customerId , page).then((res) => {
      setPage(res.current_page - 1);
      setZoneOptions(res.data);
      setZoneLoading(false);
    });
  }, []);
 
  const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;
  const locationNameRegex = /^.*[A-Za-z0-9]$/;
  const phoneNumberRegex = /^\d{10}$/;
  const locationAddressRegex = /^[A-Za-z0-9\s,.'-]{3,}$/;
  const cityRegex = /^[A-Za-z\s'-]+$/; 
  const countryRegex = /^[A-Za-z\s'-]+$/;
 
 
  const membersSchema = Yup.object().shape({
    locationName: Yup.string()
      .matches(locationNameRegex, 'Invalid location name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
      .required('Location name is required'),
      locationAddress: Yup.string()
      .matches(locationAddressRegex, 'Invalid location address. Must contain at least one word and only allow specific characters.')
      .required('Location address is required'),
    locationContact: Yup.string()
      .matches(phoneNumberRegex, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),
      city: Yup.string()
      .matches(cityRegex, 'Invalid city name. Only letters, spaces, hyphens, and apostrophes are allowed.')
      .required('City is required'),
    country: Yup.string()
      .matches(countryRegex, 'Invalid country name. Only letters, spaces, hyphens, and apostrophes are allowed.')
      .required('Country is required'),
    subnets: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().matches(subnetRegex, "Invalid subnet"),
          label: Yup.string()
        })
      )
      .min(1, "Min 1 subnet is required"),
    zoneValue: Yup.string(),
  });
 
  const defaultValues = {
    locationName: '',
    locationAddress: '',
    locationContact: '',
    city: '',
    country: '',
    subnets: [],
    zoneValue: '',
  };
 
  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });
 
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
 
  const onSubmit = async (value) => {
    const data = {
      name: value.locationName,
      subnets: value.subnets.map(item => item.value),
      address: value.locationAddress,
      contact: value.locationContact,
      city: value.city,
      country: value.country,
      zone_id: zoneValue?.id ?? null,
    };
    setLoading(true);
    addLocation(customerId, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        gridApi.purgeInfiniteCache();      
        enqueueSnackbar(
          'Location added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };
 
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{margin:0}}
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Add location
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent >
            <Stack spacing={3} sx={{ py: 2 }}>
              <RHFTextField name="locationName" label="Name *" placeholder="Enter location name" />
              <RHFTextField name="locationAddress" label="Address *" placeholder="Enter location address" />
              <RHFTextField name="locationContact" label="Contact *" placeholder="Enter location contact" />
              <RHFTextField name="city" label="Enter City *" placeholder="Enter your City" />
              <RHFTextField name="country" label="Enter Country *" placeholder="Enter Your Country" />
              <RHFSubnetSelector name="subnets" setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets}/>
              <ZoneSelector
                zoneOptions={zoneOptions}
                zoneLoading={zoneLoading}
                setZoneValue={setZoneValue}
                zoneValue={zoneValue}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add location
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}