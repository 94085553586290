import { Grid } from '@mui/material';
import RolesTable from './components/RolesTable';

export default function RolesComponents() {
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12}>
          <RolesTable />
        </Grid>
      </Grid>
    </>
  );
}
