
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../actions/updateLocation';
import IndividualDevicesComponent from '../components/configChangeEvents/IndividualDevicesComponent';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function ConfigChangeEvents() {
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.cmdbchangeevent.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'cmdbchangeevent', locationUuid: _locations }));
  };


  return (
    <Page title="Config Change Events">
      <Container maxWidth={false}>

        <Grid container direction="column" spacing={3}>
            <Grid item container>
              <Grid item xs={3} sx={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                <div role="presentation" style={{ cursor: 'pointer'}}>
                  <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
                  <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{pb:1.5}}>
                      <HomeOutlinedIcon 
                      sx={{
                        fontSize: {
                          xs: '20px',
                          sm: '22px',
                          md: '24px',
                          lg: '26px',
                          xl: '28px',
                          xxl: '30px',
                        },
                      }} />
                    </Typography>
                    <Typography component={Link} underline="hover" color="inherit" to="/dashboard/device-up-down"
                    sx={{
                      fontSize: {
                        xs: '8px',
                        sm: '12px',
                        md: '14px',
                        lg: '16px',
                        xl: '18px',
                        xxl: '20px',
                      },
                    }}>
                      Logs
                    </Typography>
                    <Typography component={Link} underline="hover" color="inherit" to="/dashboard/config-change-events"
                    sx={{
                      fontSize: {
                        xs: '8px',
                        sm: '12px',
                        md: '14px',
                        lg: '16px',
                        xl: '18px',
                        xxl: '20px',
                      },
                    }}>
                      CMDB change events
                    </Typography>
                  </Breadcrumbs>
                </div>
              </Grid>

              <Grid item xs={9} sx={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                <LocationSelect selectLocation={selectLocation} locationData={locationData}/>
              </Grid>
              <Divider sx={{marginTop:'15px', width:'100%'}} />
            </Grid>


            <Grid item container >
              <Grid item xs={12} >
                  <IndividualDevicesComponent locationData={locationData} />
              </Grid>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
