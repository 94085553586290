import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import GlobalMapComponent from '../components/VisibilityComponents/GlobalMapComponent';

// ----------------------------------------------------------------------

export default function Dashboard() {
 
  return (
    <Page title="Global Map">
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={3}>
            <Grid item container>
              <Grid item xs={3} sx={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                <div role="presentation" style={{ cursor: 'pointer'}}>
                  <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
                    <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                      <HomeOutlinedIcon  sx={{
                  fontSize: {
                    xs: '20px',
                    sm: '22px',
                    md: '24px',
                    lg: '26px',
                    xl: '28px',
                    xxl: '30px',
                  },
                }}/>
                    </Typography>
                    <Typography component={Link} underline="hover" color="inherit" to="/dashboard/global-map"
                    sx={{
                      fontSize: {
                        xs: '8px',
                        sm: '12px',
                        md: '14px',
                        lg: '16px',
                        xl: '18px',
                        xxl: '20px',
                      },
                    }}>
                      Global Maps
                    </Typography>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Divider sx={{marginTop:'15px', width:'100%'}} />
            </Grid>


            <Grid item container >
              <Grid item xs={12} >
                  <GlobalMapComponent  />
              </Grid>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
