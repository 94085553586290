import { React, useContext, useState } from 'react';
// @mui
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../context/UserContext';
import { updateInventory } from '../../API/NetworkDevices/networkDevices';



const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

export default function EndpointsDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, selectedRecords, setSelectedData } = props;
  const [selectedItems, setSelectedItems] = useState(selectedRecords.map((item) => ({ ...item, id: item._uid })))
  const [_isDirty, setIsDirty] = useState(false)
  const [loading, setLoading] = useState(false)


  const columns = [
    { field: 'r_dvc_name', headerName: 'Device name', width: 200, editable: true },
    { field: 'r_dvc_ip_address', headerName: 'IP address', width: 200, editable: false },
    // { field: 'r_dvc_host', headerName: 'Vendor', width: 200, editable: true },
    { field: 'r_dvc_os', headerName: 'Operating system', width: 200, editable: true },
    { field: 'r_dvc_family', headerName: 'Family', width: 200, editable: true },
  ]
  // -----------------------------------------------------------------------------------------------------------------------------
  const handleSubmit = async (value) => {
    setLoading(true)
    const mapRequst = selectedItems.map((item) => {
      return _updateAsNetwork(item)
    })
    Promise.all(mapRequst)
      .then((res) => {
        setLoading(false);
        handleClose();
        setSelectedData([])
        enqueueSnackbar(
          'Device added as endpoint sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        handleClose();
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }

  const updateRowItem = ({ id, field, value }) => {
    setIsDirty(true)
    setSelectedItems((_selecetedItems) => {
      return [..._selecetedItems.map((item) => {
        if (item._uid === id) {
          item[field] = value
        }
        return item
      })]
    });
  }

  const _updateAsNetwork = async (value) => {
    const data = {
      type: "ENDPOINT",
      name: value.r_dvc_name,
      ip_address: value.r_dvc_ip_address,
      os: value.r_dvc_os,
      host: value.r_dvc_host,
      family: value.r_dvc_family,
    };
    return updateInventory(customerId, value._uid, data)
  };
  // -----------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Add to endpoints
          <IconButton onClick={handleClose} sx={{ float: "right" }}>
            <CloseIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3, px: 3 }}>

          <DataGrid
            rows={selectedItems}
            columns={columns}
            autoHeight
            onCellEditCommit={(params, event) => {
              updateRowItem(params)
            }}
          />

        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} type="submit" onClick={handleSubmit} variant="contained" disabled={!_isDirty}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
