import { Grid } from '@mui/material';
import EndpointsByOS from './EndpointsByOS';
import EndpointsByFamily from './EndpointsByFamily';
import EndpointsByVendor from './EndpointsByVendor';


const InventoryCharts2 = (props) => {
  const { locationData,selectEndpointOs,selectEndpointFamily,selectEndpointVendor } = props
  
  return (
    <>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <EndpointsByOS locationData={locationData} selectEndpointOs={selectEndpointOs}/>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <EndpointsByFamily locationData={locationData} selectEndpointFamily={selectEndpointFamily}/>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <EndpointsByVendor locationData={locationData} selectEndpointVendor={selectEndpointVendor}/>
      </Grid>

    </>
  );
};
export default InventoryCharts2;
