import { Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext, useState } from 'react';
import { deleteLocation } from '../../../../API/Locations/showLocations';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import AssignSubnetsDialog from '../AssignSubnetsDialog';
import EditLocationDialog from '../EditLocationDialog';


const ActionsRenderer = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [editLocationDialog, setEditLocationDialog] = useState(false);
  const [editLocationData, setEditLocationData] = useState({});
  const [assignSubnetsDialog, setAssignSubnetsDialog] = useState(false);

  // console.log(props,'---------------props');

  const handleDelete = (value) => {
    deleteLocation(customerId, value.location_id)
      .then((res) => {
        props.api.purgeInfiniteCache();
        enqueueSnackbar(
          'Location deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleLocationEdit = (value) => {
    setEditLocationData(value);
    setEditLocationDialog(true);
  };

  const handleAssignSubnet = (value) => {
    setEditLocationData(value);
    setAssignSubnetsDialog(true);
  };

  if (data === undefined) {
    return '';
  }
  return (
    <>
      <Button variant="outlined" onClick={() => handleAssignSubnet(data)} sx={{ minWidth: '120px' }}>
        Add subnets
      </Button>
      <Tooltip title="Edit">
        <IconButton onClick={() => handleLocationEdit(data)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={confirm((e) => handleDelete(data), {
            title: 'Information',
            confirmationText: 'Yes',
            disableOkBtn: false,
            description: 'Are you sure you want to delete this location',
          })}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      {editLocationDialog && (
        <EditLocationDialog
          openDialog={editLocationDialog}
          handleClose={() => setEditLocationDialog(false)}
          locationData={editLocationData}
          api={props}
        />
      )}
      {assignSubnetsDialog && (
        <AssignSubnetsDialog
          openDialog={assignSubnetsDialog}
          handleClose={() => setAssignSubnetsDialog(false)}
          locationData={editLocationData}
          api={props}
        />
      )}
    </>
  );
};

ActionsRenderer.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionsRenderer);
