// @mui
import {
    Button,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
  } from '@mui/material';
  import Lottie from 'lottie-react';
  // components
  import Animation1 from '../../Assets/75702.json';
  import QualityColors from '../../utils/QualityColors';
  // sections
  
  function createData(no, vpn, wan, uptime, quality) {
    return { no, vpn, wan, uptime, quality };
  }
  
  const rows = [
    createData(1, 'VPN1', 'ACT', '5%', 'Good'),
    createData(2, 'VPN2', 'Airtel', '8%', 'Good'),
    createData(3, 'VPN3', 'Jio-Fiber', '10%', 'Average'),
    createData(4, 'VPN4', 'Gigatel', '15%', 'Average'),
    createData(5, 'VPN5', 'Hatway', '24%', 'Bad'),
  ];
  
  // ----------------------------------------------------------------------
  
  export default function SdwanCard1() {
    const getStyles = (quality) => {
      return QualityColors.find((item) => item.quality === quality).style;
    };
    
    return (
      <>
            <Grid item xs={12} sm={12} md={12} lg={12} spacing={4}>
              <Paper elevation={15} sx={{ py: 3 }}>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card sx={{ ml: 2 }} variant="outlined">
                      <Typography
                        sx={{ mb: 1 }}
                        color="text.primary"
                        style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '5px' }}
                      >
                        Current Status
                      </Typography>
                      <Table>
                        <TableRow >
                          <TableCell>Internet</TableCell>
                          <TableCell>Airtel</TableCell>
                        </TableRow>
                        <TableRow >
                          <TableCell>Intranet</TableCell>
                          <TableCell>VPN</TableCell>
                        </TableRow>
                      </Table>
                    </Card>
                  </Grid>
  
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card variant="outlined">
                      <Typography
                        sx={{ mb: 1 }}
                        color="text.primary"
                        style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '5px' }}
                      >
                        SDWAN Status
                      </Typography>
                      <Lottie
                        animationData={Animation1}
                        loop
                        autoPlay
                        style={{ height: '34vh', marginTop: '-10px' }}
                      />
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                        <Button variant="contained">Test Here</Button>
                      </div>
                    </Card>
                  </Grid>
  
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card sx={{ mr: 2 }} variant="outlined">
                      <Typography
                        sx={{ mb: 1 }}
                        color="text.primary"
                        style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '5px' }}
                      >
                        SDWAN events
                      </Typography>
                      <Typography
                        color="text.primary"
                        variant="body2"
                        gutterBottom
                        component="div"
                        style={{ backgroundColor: '#b9f6ca' }}
                      >
                        # Internet links moved to Airtal, due to high packet loss in ACT
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
      </>
    );
  }
  