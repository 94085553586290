import { React,useState } from 'react';
// @mui
import {
  Box, Breadcrumbs, Grid, Link, Paper, styled, Table,Dialog,DialogContent,Slide,
  TableBody, TableCell,TableContainer,TableHead,TableRow, Typography,DialogActions,
  DialogTitle,IconButton,
} from '@mui/material';
// sections

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(no, server, ip, type, scope) {
  return { no, server, ip, type, scope };
}

const rows = [
  createData(1, 'NTPCL', '10.10.1.123', 'Firewall', 12),
  createData(2, 'XYLBN', '10.20.2.24', 'Windows', 10),
  createData(3, 'MSTCP', '21.6.13.5', 'Linux', 14),
  createData(4, 'MSTCP', '10.20.2.24', 'Linux', 14),
  createData(5, 'MSTCP', '10.20.2.24', 'Linux', 14),
];

//----------------------------------------------------------------------
export default function IPAMsubnets() {

  return (
    <>
        
       
          <Grid item lg={12} md={12} sm={12}>
            <Box >
                 <TableContainer style={{ padding: '10px 10px 10px 10px' }}>
                    <Table sx={{ minWidth: 500 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell> No.</TableCell>
                          <TableCell> Server</TableCell>
                          <TableCell> IP</TableCell>
                          <TableCell> Type</TableCell>
                          <TableCell> Scope</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.no}>
                            <TableCell component="th" scope="row">
                              {row.no}
                            </TableCell>
                            <TableCell> {row.server}</TableCell>
                            <TableCell> {row.ip}</TableCell>
                            <TableCell> {row.type}</TableCell>
                            <TableCell> {row.scope}</TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
            </TableContainer>
        </Box>
        </Grid>
    </>
  );
}

