import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Table,
  TableBody,
  LinearProgress,
  Box,
  CardHeader,
  Divider,
  CardContent,
} from '@mui/material';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context/UserContext';
import { WithConfirm } from '../../WithConfirm';
import SchedulerOptionSelector from './SchedulerOptionSelector';
import { getScheduler, updateScheduler } from '../../../API/Scheduler/Scheduler';
import NoDataComponent from '../../NoDataComponent';
import { toCamelizeWords } from '../../../utils/formatString';

const SchedulersTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [schedulerData, setSchedulerData] = useState([]);
  const [schedulerOptions, setSchedulerOptions] = useState([]);
  const [schedulerLoading, setSchedulerLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);

  const fetchSchedulers = async () => {
    setSchedulerLoading(true);
    const res = await getScheduler(customerId);
    setSchedulerLoading(false);
    setSchedulerData(res);
    setSchedulerOptions(res);
    setCountLoading(false);
  };
  useEffect(() => {
    fetchSchedulers();
    setCountLoading(false);
  }, [customerId]);

  const selectScheduler = (option, schedule) => {
    const data = {
      schedule_uid: schedule._uid,
      value: option.value,
      // value: '0 0 * * *',
    };
    updateScheduler(customerId, schedule._uid, data)
      .then((res) => {
        fetchSchedulers();
        enqueueSnackbar(
          'Schedule assigned sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader
          color="text.error"
          sx={{ mb: 2 }}
          title="Schedules"
          avatar={
            <Box>
              <DateRangeOutlinedIcon style={{ width: '28px', height: '28px', fontSize: '12px' }} />
              {/* {countLoading && (
                <CircularProgress
                  size={35}
                  style={{
                    position: 'absolute',
                    left: 20,
                    top: 20,
                  }}
                  value={schedulerData?.data?.length || 0}
                />
              )} */}
            </Box>
          }
        />
          
          <Divider />
         {schedulerLoading && <LinearProgress />}
            
        <CardContent style={{ padding: '0px' }}>
          <TableContainer
           sx={{
            width:'100%',
            maxHeight: 550 ,
            "&::-webkit-scrollbar": {
            width: 7,
            height:'12px',
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: '#f1f1f1',         
            },
            "&::-webkit-scrollbar-thumb": {
            backgroundColor: 'rgb(181, 179, 179)',
            borderRadius: '30px',
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: '#555'
            }
          }}
          >
            <Table>
              <TableBody>
                {schedulerData?.length === 0 ? (
                  <TableRow>
                    <TableCell>
                      <NoDataComponent noDataFoundText="Schedules" />
                    </TableCell>
                  </TableRow>
                ) : (
                  schedulerData?.map((item) => {
                    return (
                      <TableRow key={item._uid} sx={{ justifyContent: 'space-between', display: 'flex' }}>
                        <TableCell sx={{ width: '100%' }}>
                          <Typography color="textSecondary" variant="body2">
                            {toCamelizeWords(item.r_scdl_type)}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ width: '100%' }}>
                          <SchedulerOptionSelector
                            schedulerOptions={schedulerOptions}
                            selectScheduler={selectScheduler}
                            fetchSchedulers={fetchSchedulers}
                            schedule={item}
                            schedulerObject={item.r_scdl_type?.length && item.r_scdl_type}
                            placeholder="Select schedules"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default WithConfirm(SchedulersTable);
