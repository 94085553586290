/* eslint-disable*/
import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AWS from 'aws-sdk';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ACCESS_KEY_ID, BUCKET_NAME, BUCKET_REGION, SECRET_ACCESS_KEY } from '../../API/S3';
import S3Worker from './S3.worker';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  icon: {
    // visibility: 'hidden',
  },
  field: {},
  // root: {
  //   '&:hover $field $icon': {
  //     visibility: 'visible',
  //   },
  // },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function CMDBDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, selectedFile, details,selectedFileDetails } = props;

  const [docs, setDocs] = useState(selectedFile);
  // const [viewFile, setViewFile] = useState(docs);
  const [loading, setLoading] = useState(true);
  const [filePath, setFilePath] = useState();
  const worker = new S3Worker();
  const [decodedFile , setDecodedFile] = useState('')

  function getSignedUrl(bucket, key, expires) {
    const params = {
      Bucket: bucket,
      Key: key,
      Expires: expires,
    };
    return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }
  useEffect(() => {
    if (selectedFile && selectedFile !== "Data not available" && selectedFile !==  "No config available") {
      try {
        const cleanedBase64 = selectedFile.replace(/\s/g, '');
        const decodedData = atob(cleanedBase64);
        setDecodedFile(decodedData);
        setLoading(false);
      } catch (error) {
        console.error('Error decoding Base64 file:', error);
        enqueueSnackbar('Failed to decode file', { variant: 'error' });
        setLoading(false);
      }
    } else if (selectedFile === "Data not available" || selectedFile ===  "No config available") {
      setDecodedFile("Data not available");
      setLoading(false);
    }
  }, [selectedFile, enqueueSnackbar]);
  

  const downloadFile = () => {
    try {
      const blob = new Blob([atob(selectedFile)], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = details?.file_name || 'download.txt';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar('Downloaded successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
    } catch (error) {
      console.error('Error downloading file:', error);
      enqueueSnackbar('Failed to download file', { variant: 'error' });
    }
  };
  
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box className={classes.root}>
            {/* {fileName} */}
            <Typography variant="subtitle2" gutterBottom className={classes.field}>
              {selectedFileDetails?.locationName} / {selectedFileDetails?.id} / {selectedFileDetails?.deviceName}
              <Tooltip title="Download file">
                <IconButton
                  size="small"
                  onClick={() => {
                    // downloadSrcTxtFile(BUCKET_NAME, filePath, fileName);
                    downloadFile();
                  }}
                >
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />
      {loading && <LinearProgress />}

      <DialogContent>
        <pre>
          {decodedFile}
        </pre>
      </DialogContent>
    </Dialog>
  );
}
export default CMDBDialog;

