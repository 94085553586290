import { Alert, Divider, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../actions/updateLocation';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
import InactiveTable from './components/InactiveTable';
// -------------------------------------------------------------------------------------------


// ----------------------------------------------------------------------------------------------

const InactiveComponent = () => {
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.inactive.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'inactive', locationUuid: _locations }));
  };
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} style={{ marginTop: '-35px' }}>
        <LocationSelect selectLocation={selectLocation} locationData={locationData} />
      </Grid>

      <Divider sx={{ marginTop: '15px', width: '100%' }} />
      <Grid item lg={12} md={12} sm={12} style={{ paddingLeft: '24px' }}>
        <Alert severity="warning">The "Inactive" status is determined based on the scan conducted at midnight prior to taking any actions, so please proceed with caution.</Alert>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <InactiveTable locationData={locationData} />
      </Grid>
    </Grid>
  );
};
export default InactiveComponent;
