// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  FormControl,
  IconButton,
  Checkbox,
  LinearProgress,
  TextField,
  List,
} from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createFilterOptions } from '@mui/material/Autocomplete';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { UserContext } from '../../../context/UserContext';
import { FormProvider } from '../../hook-form';
import { scanSpecificSubnet ,scanNewlyAddedSubnet} from '../../../API/DcAgent/DcAgent';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fieldContainer: { minHeight: 290 },
});
const subnetLocationFilter = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option?.r_subnet_value + option?.location?.r_location_name,
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ScanSelectedSubnetsDialog(props) {
  const { openDialog, handleClose, agent } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const credentialsSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(credentialsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const onSubmit = async () => {
    // const data = {
    //   subnet_uuids: selectedSubnets.map((e) => e.subnet_id),
    // };
    // setLoading(true);
    // scanSpecificSubnet(customerId, agent.r_agent_id, data)
    //   .then((res) => {
    //     setLoading(false);
    //     enqueueSnackbar(
    //       'Configuration updated successfully',
    //       {
    //         variant: 'success',
    //         anchorOrigin: {
    //           vertical: 'bottom',
    //           horizontal: 'right',
    //         },
    //       },
    //       500
    //     );
    //     handleClose();
    //   })
    //   .catch((res) => {
    //     setLoading(false);
    //     enqueueSnackbar(
    //       res.response.data.message,
    //       {
    //         variant: 'error',
    //         anchorOrigin: {
    //           vertical: 'bottom',
    //           horizontal: 'right',
    //         },
    //       },
    //       500
    //     );
    //     handleClose();
    //   });
    const data = {
      onDemandScanType : 3,
      subnets: selectedSubnets.map((e) => e.subnet_id),
    };
    setLoading(true);
    scanNewlyAddedSubnet(customerId, agent.agent_id, data)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Configuration updated successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  };
  

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Scan selected subnets on-demand
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent sx={{ py: 3, px: 3 }}>
          <FormControl fullWidth size="small">
            <Autocomplete
              sx={{ minWidth: 300 }}
              limitTags={10}
              loading={loading}
              multiple
              filterOptions={subnetLocationFilter}
              options={agent.subnets}
              onChange={(event, value) => {
                setSelectedSubnets(value);
              }}
              getOptionLabel={(option) => option.r_subnet_value}
              renderOption={(props, option, { selected }) => (
                option?.r_subnet_value && (
                  <ListItemButton {...props}>
                    <ListItemIcon>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    </ListItemIcon>
                    <ListItemText
                      primary={option.r_subnet_value}
                      secondary={agent.subnets
                        .filter((item) => item.r_subnet_value === option.r_subnet_value)
                        .map((loc) => loc.location.r_location_name)
                        .join(', ')} 
                    />
                  </ListItemButton>
                )
              )}
              
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select subnets"
                label="Scan selected subnets"
                value={query}
                onChange={handleQueryChange}
                InputLabelProps={{ shrink: true }}
                autoComplete="new-password"
                inputProps={{
                  ...params.inputProps,
                  // autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            />
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton sx={{ float: 'right' }} variant="outlined" color="primary" loading={loading} type="submit">
            Scan
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
