// import { Close } from '@mui/icons-material';
// import LoadingButton from '@mui/lab/LoadingButton';
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   IconButton,
//   List,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Typography,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { useSnackbar } from 'notistack';
// import PropTypes from 'prop-types';
// import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { addMultipleLocation } from '../../../API/Locations/showLocations';
// import { addZone } from '../../../API/Zones/zones';
// import { TextField } from '@mui/material'; // Import TextField for editable cells
// // import FiletoDownload from '../../../Assets/location_test.csv';
// import FiletoDownload from '../../../Assets/Location_template.xlsx';
// import { UserContext } from '../../../context/UserContext/userContext';
// import validateSubnet from '../../../utils/ValidateSubnet';
// import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';

// // ----------------------------------------------------------------------------------------------------------------------

// const useStyles = makeStyles({
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
// });

// const baseStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   padding: '20px',
//   borderWidth: 2,
//   borderRadius: 2,
//   borderColor: '#eeeeee',
//   borderStyle: 'dashed',
//   backgroundColor: '#fafafa',
//   color: '#bdbdbd',
//   transition: 'border .3s ease-in-out',
// };

// const activeStyle = {
//   borderColor: '#2196f3',
// };

// const acceptStyle = {
//   borderColor: '#00e676',
// };

// const rejectStyle = {
//   borderColor: '#ff1744',
// };

// // ----------------------------------------------------------------------------------------------------------------------

// export default function UploadCsv(props) {
//   const classes = useStyles();
//   const { enqueueSnackbar } = useSnackbar();
//   const { customerId } = useContext(UserContext);
//   const { openDialog, handleClose, gridApi } = props;
//   const [showText, setShowText] = useState(true);
//   const [showTable, setShowTable] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [filteredData, setFilteredData] = useState([]);
//   const [hasErrors, setHasErrors] = useState(false);
//   const [isCompleted, setIsCompleted] = useState(false);
//   const locationNameRegex = /^[a-zA-Z0-9\s\-_(),]*$/;
//   const subnetRegex = /^(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2})(?:;\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2})*$/;
//   const locationAddressRegex = /^[a-zA-Z0-9\s\-_(),#]*$/;
//   const locationContactRegex = /^\d{10}$/;
//   const zoneNameRegex = /^[a-zA-Z0-9\s\-_(),]*$/;
//   const cityRegex = /^[a-zA-Z\s\-'.]*$/;
//   const countryRegex = /^[a-zA-Z0-9\s\-_(),]*$/;

//   // --------------------------------------------------------------------------------------------
//   const CSV_SEPARATER = ',';

//   const onDrop = useCallback((acceptedFiles) => {
//     acceptedFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const data = new Uint8Array(e.target.result);

//         // Read the file content as an array
//         const workbook = XLSX.read(data, { type: 'array' });

//         // Get the second sheet (index 1)
//         const secondSheetName = workbook.SheetNames[1];
//         const secondSheet = workbook.Sheets[secondSheetName];

//         // Convert the second sheet to JSON, including empty cells (header: 1)
//         const sheetData = XLSX.utils.sheet_to_json(secondSheet, { header: 1 });

//         // Skip the header row and filter out rows that are completely empty (all cells undefined or empty)
//         const parsedData = sheetData.slice(1).filter(row =>
//           row.some(cell => cell !== undefined && cell.toString().trim() !== "")
//         );

//         // Format the data to match your table structure
//         const formattedData = parsedData.map(row => ({
//           No: row[0],
//           Locationname: row[1],
//           Subnets: row[2],
//           Locationaddress: row[3],
//           Locationcontact: row[4],
//           Zonename: row[5],
//           city: row[6],
//           country: row[7],
//         }));

//         // Update the state with filtered and formatted data
//         setFilteredData(formattedData);
//         setShowText(false);
//         setShowTable(true);
//       };
//       reader.readAsArrayBuffer(file);
//     });
//   }, []);


//   const validateField = (field, value) => {
//     switch (field) {
//       case 'Locationname':
//         return locationNameRegex.test(value);
//       case 'Subnets':
//         return subnetRegex.test(value);
//       case 'Locationaddress':
//         return locationAddressRegex.test(value);
//       case 'Locationcontact':
//         return locationContactRegex.test(value);
//       case 'Zonename':
//         return zoneNameRegex.test(value);
//       case 'city':
//         return cityRegex.test(value);
//       case 'country':
//         return countryRegex.test(value);
//       default:
//         return true;
//     }
//   };
//   // const handleSubmit = async () => {
//   //   const uniqueZoneList = [];
//   //   filteredData.forEach((item) => {
//   //     const zone = item?.Zonename?.trim();
//   //     uniqueZoneList.push(zone);
//   //   });

//   //   try {
//   //     const zoneList = uniqueZoneList; // Since zone creation is commented, keep it simple

//   //     setLoading(true);

//   //     // Process each location sequentially
//   //     for (let index = 0; index < filteredData.length; index++) {
//   //       const item = filteredData[index];
//   //       await _addLocation(item, zoneList, index);
//   //     }

//   //     // Handle success
//   //     gridApi.purgeInfiniteCache();
//   //     // handleClose();
//   //     setLoading(false);
//   //     // enqueueSnackbar('Locations added successfully', { variant: 'success' });

//   //   } catch (error) {
//   //     console.error("Error:", error);
//   //     setLoading(false);
//   //     // handleClose();
//   //     const errorMessage = error?.response?.data?.message || "An error occurred";
//   //     // enqueueSnackbar(errorMessage, { variant: 'error' });
//   //   }
//   // };
//   const handleSubmit = async () => {
//     const uniqueZoneList = [];
//     filteredData.forEach((item) => {
//       const zone = item?.Zonename?.trim();
//       uniqueZoneList.push(zone);
//     });

//     const failedLocations = []; // Track failed locations

//     try {
//       setLoading(true);

//       // Process each location sequentially
//       for (let index = 0; index < filteredData.length; index++) {
//         const item = filteredData[index];
//         const result = await _addLocation(item, uniqueZoneList, index);

//         // If result indicates a failure, add the location to the failedLocations array
//         if (result === 'failed') {
//           failedLocations.push(item.Locationname);
//         }
//       }

//       setLoading(false);
//       setIsCompleted(true); // Mark the process as completed

//       // Check if there are any failed locations
//       if (failedLocations.length > 0) {
//         const failedNames = failedLocations.join(', ');
//         // enqueueSnackbar(`The following locations failed to upload: ${failedNames}`, { variant: 'error' });
//       } else {
//         // enqueueSnackbar('Locations added successfully', { variant: 'success' });
//       }

//       // Close the dialog in both success and failure cases
//       // handleClose();

//     } catch (error) {
//       console.error("Error:", error);
//       setLoading(false);
//       setIsCompleted(true); // Mark the process as completed
//       const errorMessage = error?.response?.data?.message || "An error occurred";
//       // enqueueSnackbar(errorMessage, { variant: 'error' });
//       // handleClose(); // Close the dialog in case of any error
//     }
//   };


//   const _addLocation = async (value, zoneList, index) => {
//     const subnetInputs = value?.Subnets?.trim().split(';')?.filter(item => item?.trim() !== "");
//     const subnets = [];
//     subnetInputs?.forEach(subnet => {
//       subnet = subnet?.trim();
//       if (validateSubnet(subnet)) {
//         subnets.push(subnet);
//       }
//     });

//     if (subnets.length === 0) {
//       setFilteredData(prevData => {
//         const updatedData = [...prevData];
//         updatedData[index].Message = 'Invalid Subnets';
//         return updatedData;
//       });
//       return 'failed'; // Return 'failed' if no valid subnets
//     }

//     const data = {
//       name: value.Locationname,
//       address: value.Locationaddress,
//       contact: value.Locationcontact?.toString(),
//       city: value.city,
//       country: value.country,
//       subnets,
//     };

//     const zoneName = value?.Zonename?.trim();
//     if (zoneName) {
//       const zoneuidValue = zoneList?.find(item => item === zoneName);
//       data.zone_name = zoneuidValue;
//     }

//     try {
//       await addMultipleLocation(customerId, data);
//       setFilteredData(prevData => {
//         const updatedData = [...prevData];
//         updatedData[index].Message = 'Successfully Created';
//         return updatedData;
//       });
//       gridApi.purgeInfiniteCache();
//       return 'success';
//     } catch (error) {
//       setFilteredData(prevData => {
//         const updatedData = [...prevData];
//         updatedData[index].Message = error?.response?.data?.message || 'Failed to create';
//         return updatedData;
//       });
//       gridApi.purgeInfiniteCache();
//       return 'failed';
//     }
//   };

//   const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
//     onDrop,
//     accept: {
//       'text/csv': [
//         '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//       ],
//     },
//   });

//   const style = useMemo(
//     () => ({
//       ...baseStyle,
//       ...(isDragActive ? activeStyle : {}),
//       ...(isDragAccept ? acceptStyle : {}),
//       ...(isDragReject ? rejectStyle : {}),
//     }),
//     [isDragActive, isDragReject, isDragAccept]
//   );


//   const handleEditCell = (index, field, value) => {
//     setFilteredData((prevData) => {
//       const updatedData = [...prevData];
//       updatedData[index][field] = value;

//       if (!validateField(field, value)) {
//         updatedData[index][`${field}Error`] = `${field.replace(/([a-z])([A-Z])/g, '$1 $2')} is invalid`;
//       } else {
//         updatedData[index][`${field}Error`] = '';
//       }

//       return updatedData;
//     });
//   };


//   const checkForErrors = (data) => {
//     return data.some(row =>
//       !row.Locationname || !locationNameRegex.test(row.Locationname) ||
//       !row.Subnets || !subnetRegex.test(row.Subnets) ||
//       !row.Locationaddress || !locationAddressRegex.test(row.Locationaddress) ||
//       !row.Locationcontact || !locationContactRegex.test(row.Locationcontact) ||
//       !row.Zonename || !zoneNameRegex.test(row.Zonename) ||
//       !row.city || !cityRegex.test(row.city) ||
//       !row.country || !countryRegex.test(row.country)
//     );
//   };

//   useEffect(() => {
//     const errorsExist = checkForErrors(filteredData);
//     setHasErrors(errorsExist);
//   }, [filteredData]);



//   // --------------------------------------------------------------------------------------------
//   return (
//     <>
//       <Dialog
//         open={openDialog}
//         onClose={handleClose}
//         fullWidth
//         maxWidth="lg"
//         aria-labelledby="responsive-dialog-title"
//         classes={{
//           scrollPaper: classes.topScrollPaper,
//           paperScrollBody: classes.topPaperScrollBody,
//         }}
//       >
//         <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//           Upload file here
//           <IconButton onClick={handleClose} sx={{ float: 'right' }}>
//             <Close sx={{ fontSize: '1.5rem' }} />
//           </IconButton>
//         </DialogTitle>
//         <Divider />
//         <DialogContent sx={{ px: 5, py: 3 }}>
//           <Box
//             {...getRootProps({
//               onClick: (event) => {
//                 event.stopPropagation();
//                 document.querySelector('input[type="file"]').click();
//               },
//               style,
//             })}
//           >
//             <input {...getInputProps()} />
//             {isDragActive ? 'Drop file here!' : 'Click here or drag and drop the file to upload!'}
//             {isDragReject && 'File type not supported!'}
//           </Box>
//           <Box sx={{ py: 2 }}>
//             {showText && (
//               <Box>
//                 <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
//                   File information will be shown here after the import
//                 </Typography>
//               </Box>
//             )}

//             {showTable && (
//               <Box sx={{ marginTop: '10px' }}>
//                 <Box>
//                   <List key={acceptedFiles.id}>
//                     {acceptedFiles.length > 0 &&
//                       acceptedFiles.map((acceptedFile, index) => (
//                         <Typography key={`files-${index}`}>File name: &ensp; {acceptedFile.name}</Typography>
//                       ))}
//                   </List>
//                 </Box>

//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>No</TableCell>
//                       <TableCell>Location name</TableCell>
//                       <TableCell>Subnets</TableCell>
//                       <TableCell>Location address</TableCell>
//                       <TableCell>Location contact</TableCell>
//                       <TableCell>Zone name</TableCell>
//                       <TableCell>City</TableCell>
//                       <TableCell>Country</TableCell>
//                       <TableCell>Message</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {filteredData?.map((item, index) => (
//                       <TableRow key={item.No}>
//                         <TableCell>{item.No}</TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.Locationname || ""}
//                             onChange={(e) => handleEditCell(index, 'Locationname', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.Locationname || !locationNameRegex.test(item.Locationname)}
//                             helperText={
//                               !item.Locationname
//                                 ? 'Location name is required'
//                                 : (!locationNameRegex.test(item.Locationname) ? 'Invalid format for location name' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.Subnets || ""}
//                             onChange={(e) => handleEditCell(index, 'Subnets', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.Subnets || !subnetRegex.test(item.Subnets)}
//                             helperText={
//                               !item.Subnets
//                                 ? 'Subnets are required'
//                                 : (!subnetRegex.test(item.Subnets) ? 'Invalid subnet format' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.Locationaddress || ""}
//                             onChange={(e) => handleEditCell(index, 'Locationaddress', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.Locationaddress || !locationAddressRegex.test(item.Locationaddress)}
//                             helperText={
//                               !item.Locationaddress
//                                 ? 'Location address is required'
//                                 : (!locationAddressRegex.test(item.Locationaddress) ? 'Invalid address format' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.Locationcontact || ""}
//                             onChange={(e) => handleEditCell(index, 'Locationcontact', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.Locationcontact || !locationContactRegex.test(item.Locationcontact)}
//                             helperText={
//                               !item.Locationcontact
//                                 ? 'Location contact is required'
//                                 : (!locationContactRegex.test(item.Locationcontact) ? 'Enter a valid 10-digit number' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.Zonename || ""}
//                             onChange={(e) => handleEditCell(index, 'Zonename', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.Zonename || !zoneNameRegex.test(item.Zonename)}
//                             helperText={
//                               !item.Zonename
//                                 ? 'Zone name is required'
//                                 : (!zoneNameRegex.test(item.Zonename) ? 'Invalid zone name format' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.city || ""}
//                             onChange={(e) => handleEditCell(index, 'city', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.city || !cityRegex.test(item.city)}
//                             helperText={
//                               !item.city
//                                 ? 'City is required'
//                                 : (!cityRegex.test(item.city) ? 'Invalid city format' : '')
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             value={item.country || ""}
//                             onChange={(e) => handleEditCell(index, 'country', e.target.value)}
//                             fullWidth
//                             required
//                             error={!item.country || !countryRegex.test(item.country)}
//                             helperText={
//                               !item.country
//                                 ? 'Country is required'
//                                 : (!countryRegex.test(item.country) ? 'Invalid country format' : '')
//                             }
//                           />
//                         </TableCell>

//                         <TableCell>
//                           <span
//                             style={{
//                               color: item.Message === 'Successfully Created' ? 'green' : 'red',
//                             }}
//                           >
//                             {item.Message || ''}
//                           </span>
//                         </TableCell>

//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>

//               </Box>
//             )}
//           </Box>
//         </DialogContent>
//         <Divider />
//         <DialogActions>
//           {!isCompleted ? (
//             <>
//               <Button>
//                 <a href={FiletoDownload} download="Template.XLSX">
//                   Download Template
//                 </a>
//               </Button>
//               <Button onClick={handleClose}>Cancel</Button>
//               <LoadingButton
//                 loading={loading}
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//                 onClick={handleSubmit}
//                 disabled={hasErrors}
//               >
//                 Save & Upload
//               </LoadingButton>
//             </>
//           ) : (
//             <Button onClick={handleClose} variant="contained" color="primary">
//               Close
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// UploadCsv.propTypes = {
//   gridApi: PropTypes.object,
//   openDialog: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
// };

import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { addMultipleLocation } from '../../../API/Locations/showLocations';
import FiletoDownload from '../../../Assets/Location_template.xlsx';
import { UserContext } from '../../../context/UserContext/userContext';
import validateSubnet from '../../../utils/ValidateSubnet';
import * as XLSX from 'xlsx';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function UploadCsv(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, gridApi } = props;
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  // Single subnet regex
  const singleSubnetRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2}$/;

  const locationNameRegex = /^[a-zA-Z0-9\s\-_(),]*$/;
  const locationAddressRegex = /^[a-zA-Z0-9\s\-_(),#]*$/;
  const locationContactRegex = /^\d{10}$/;
  const zoneNameRegex = /^[a-zA-Z0-9\s\-_(),]*$/;
  const cityRegex = /^[a-zA-Z\s\-'.]*$/;
  const countryRegex = /^[a-zA-Z0-9\s\-_(),]*$/;

  // --------------------------------------------------------------------------------------------
  const CSV_SEPARATER = ',';

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        // Read the file content as an array
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the second sheet (index 1)
        const secondSheetName = workbook.SheetNames[1];
        const secondSheet = workbook.Sheets[secondSheetName];

        // Convert the second sheet to JSON, including empty cells (header: 1)
        const sheetData = XLSX.utils.sheet_to_json(secondSheet, { header: 1 });

        // Skip the header row and filter out rows that are completely empty (all cells undefined or empty)
        const parsedData = sheetData.slice(1).filter(row =>
          row.some(cell => cell !== undefined && cell.toString().trim() !== "")
        );

        // Format the data to match your table structure
        const formattedData = parsedData.map(row => ({
          No: row[0],
          Locationname: row[1],
          Subnets: row[2],
          Locationaddress: row[3],
          Locationcontact: row[4],
          Zonename: row[5],
          city: row[6],
          country: row[7],
          Message: '', // Initialize message field
        }));

        // Update the state with filtered and formatted data
        setFilteredData(formattedData);
        setShowText(false);
        setShowTable(true);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const validateField = (field, value) => {
    switch (field) {
      case 'Locationname':
        return locationNameRegex.test(value);
      case 'Subnets':
        return true; // Subnets are validated separately
      case 'Locationaddress':
        return locationAddressRegex.test(value);
      case 'Locationcontact':
        return locationContactRegex.test(value);
      case 'Zonename':
        return zoneNameRegex.test(value);
      case 'city':
        return cityRegex.test(value);
      case 'country':
        return countryRegex.test(value);
      default:
        return true;
    }
  };

  const handleSubmit = async () => {
    const uniqueZoneList = [];
    filteredData.forEach((item) => {
      const zone = item?.Zonename?.trim();
      uniqueZoneList.push(zone);
    });

    const failedLocations = []; // Track failed locations

    try {
      setLoading(true);

      // Process each location sequentially
      for (let index = 0; index < filteredData.length; index++) {
        const item = filteredData[index];
        const result = await _addLocation(item, uniqueZoneList, index);

        // If result indicates a failure, add the location to the failedLocations array
        if (result === 'failed') {
          failedLocations.push(item.Locationname);
        }
      }

      setLoading(false);
      setIsCompleted(true); // Mark the process as completed

      // Check if there are any failed locations
      if (failedLocations.length > 0) {
        const failedNames = failedLocations.join(', ');
        enqueueSnackbar(`The following locations failed to upload: ${failedNames}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Locations added successfully', { variant: 'success' });
      }

      // Close the dialog in both success and failure cases
      handleClose();

    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setIsCompleted(true); // Mark the process as completed
      const errorMessage = error?.response?.data?.message || "An error occurred";
      enqueueSnackbar(errorMessage, { variant: 'error' });
      handleClose(); // Close the dialog in case of any error
    }
  };

  const _addLocation = async (value, zoneList, index) => {
    // Split by semicolon or comma, trim each subnet, and filter out empty strings
    const subnetInputs = value?.Subnets?.split(/[;]+/).map(item => item.trim()).filter(item => item !== "");

    const subnets = subnetInputs.filter(subnet => validateSubnet(subnet));

    if (subnets.length === 0) {
      setFilteredData(prevData => {
        const updatedData = [...prevData];
        updatedData[index].Message = 'Invalid Subnets';
        return updatedData;
      });
      return 'failed'; // Return 'failed' if no valid subnets
    }

    const data = {
      name: value.Locationname,
      address: value.Locationaddress,
      contact: value.Locationcontact?.toString(),
      city: value.city,
      country: value.country,
      subnets,
    };

    const zoneName = value?.Zonename?.trim();
    if (zoneName) {
      const zoneuidValue = zoneList?.find(item => item === zoneName);
      data.zone_name = zoneuidValue;
    }

    try {
      await addMultipleLocation(customerId, data);
      setFilteredData(prevData => {
        const updatedData = [...prevData];
        updatedData[index].Message = 'Successfully Created';
        return updatedData;
      });
      gridApi.purgeInfiniteCache();
      return 'success';
    } catch (error) {
      setFilteredData(prevData => {
        const updatedData = [...prevData];
        updatedData[index].Message = error?.response?.data?.message || 'Failed to create';
        return updatedData;
      });
      gridApi.purgeInfiniteCache();
      return 'failed';
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleEditCell = (index, field, value) => {
    setFilteredData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;

      if (field === 'Subnets') {
        // Split by semicolon or comma, trim each subnet
        const subnets = value.split(/[,;]+/).map(s => s.trim());
        const invalidSubnets = subnets.filter(subnet => !validateSubnet(subnet));

        if (invalidSubnets.length > 0) {
          updatedData[index][`${field}Error`] = `Invalid subnet(s): ${invalidSubnets.join(', ')}`;
        } else {
          updatedData[index][`${field}Error`] = '';
        }
      } else {
        if (!validateField(field, value)) {
          updatedData[index][`${field}Error`] = `${field.replace(/([a-z])([A-Z])/g, '$1 $2')} is invalid`;
        } else {
          updatedData[index][`${field}Error`] = '';
        }
      }

      return updatedData;
    });
  };

  const checkForErrors = (data) => {
    return data.some(row => {
      if (
        !row.Locationname || !locationNameRegex.test(row.Locationname) ||
        !row.Locationaddress || !locationAddressRegex.test(row.Locationaddress) ||
        !row.Locationcontact || !locationContactRegex.test(row.Locationcontact) ||
        !row.Zonename || !zoneNameRegex.test(row.Zonename) ||
        !row.city || !cityRegex.test(row.city) ||
        !row.country || !countryRegex.test(row.country)
      ) {
        return true;
      }

      // Split subnets by semicolon or comma and validate each
      const subnets = row.Subnets.split(/[,;]+/).map(s => s.trim());
      return subnets.some(subnet => !validateSubnet(subnet));
    });
  };

  useEffect(() => {
    const errorsExist = checkForErrors(filteredData);
    setHasErrors(errorsExist);
  }, [filteredData]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Upload file here
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Box
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation();
                document.querySelector('input[type="file"]').click();
              },
              style,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? 'Drop file here!' : 'Click here or drag and drop the file to upload!'}
            {isDragReject && 'File type not supported!'}
          </Box>
          <Box sx={{ py: 2 }}>
            {showText && (
              <Box>
                <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
                  File information will be shown here after the import
                </Typography>
              </Box>
            )}

            {showTable && (
              <Box sx={{ marginTop: '10px' }}>
                <Box>
                  <List>
                    {acceptedFiles.length > 0 &&
                      acceptedFiles.map((acceptedFile, index) => (
                        <Typography key={`files-${index}`}>File name: &ensp; {acceptedFile.name}</Typography>
                      ))}
                  </List>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Location name</TableCell>
                      <TableCell>Subnets</TableCell>
                      <TableCell>Location address</TableCell>
                      <TableCell>Location contact</TableCell>
                      <TableCell>Zone name</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData?.map((item, index) => (
                      <TableRow key={item.No}>
                        <TableCell>{item.No}</TableCell>
                        <TableCell>
                          <TextField
                            value={item.Locationname || ""}
                            onChange={(e) => handleEditCell(index, 'Locationname', e.target.value)}
                            fullWidth
                            required
                            error={!item.Locationname || !locationNameRegex.test(item.Locationname)}
                            helperText={
                              !item.Locationname
                                ? 'Location name is required'
                                : (!locationNameRegex.test(item.Locationname) ? 'Invalid format for location name' : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.Subnets || ""}
                            onChange={(e) => handleEditCell(index, 'Subnets', e.target.value)}
                            fullWidth
                            required
                            error={!!item.SubnetsError}
                            helperText={
                              item.SubnetsError ||
                              (!item.Subnets
                                ? 'Subnets are required'
                                : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.Locationaddress || ""}
                            onChange={(e) => handleEditCell(index, 'Locationaddress', e.target.value)}
                            fullWidth
                            required
                            error={!item.Locationaddress || !locationAddressRegex.test(item.Locationaddress)}
                            helperText={
                              !item.Locationaddress
                                ? 'Location address is required'
                                : (!locationAddressRegex.test(item.Locationaddress) ? 'Invalid address format' : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.Locationcontact || ""}
                            onChange={(e) => handleEditCell(index, 'Locationcontact', e.target.value)}
                            fullWidth
                            required
                            error={!item.Locationcontact || !locationContactRegex.test(item.Locationcontact)}
                            helperText={
                              !item.Locationcontact
                                ? 'Location contact is required'
                                : (!locationContactRegex.test(item.Locationcontact) ? 'Enter a valid 10-digit number' : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.Zonename || ""}
                            onChange={(e) => handleEditCell(index, 'Zonename', e.target.value)}
                            fullWidth
                            required
                            error={!item.Zonename || !zoneNameRegex.test(item.Zonename)}
                            helperText={
                              !item.Zonename
                                ? 'Zone name is required'
                                : (!zoneNameRegex.test(item.Zonename) ? 'Invalid zone name format' : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.city || ""}
                            onChange={(e) => handleEditCell(index, 'city', e.target.value)}
                            fullWidth
                            required
                            error={!item.city || !cityRegex.test(item.city)}
                            helperText={
                              !item.city
                                ? 'City is required'
                                : (!cityRegex.test(item.city) ? 'Invalid city format' : '')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.country || ""}
                            onChange={(e) => handleEditCell(index, 'country', e.target.value)}
                            fullWidth
                            required
                            error={!item.country || !countryRegex.test(item.country)}
                            helperText={
                              !item.country
                                ? 'Country is required'
                                : (!countryRegex.test(item.country) ? 'Invalid country format' : '')
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <span
                            style={{
                              color: item.Message === 'Successfully Created' ? 'green' : 'red',
                            }}
                          >
                            {item.Message || ''}
                          </span>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </Box>
            )}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          {!isCompleted ? (
            <>
              <Button>
                <a href={FiletoDownload} download="Template.XLSX">
                  Download Template
                </a>
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={hasErrors}
              >
                Save & Upload
              </LoadingButton>
            </>
          ) : (
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

UploadCsv.propTypes = {
  gridApi: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
