// @mui

import { Divider, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../actions/updateLocation';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
import { InventoryCharts2 } from './components';
import EndpointTable from './components/EndpointTable';



const EndpointsComponents = () => {
  const [endpointOS, setEndpointOS]=useState('');
  const [endpointFamily, setEndpointFamily]=useState('');
  const [endpointVendor, setEndpointVendor]=useState('');


  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.clientdevice.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'clientdevice', locationUuid: _locations }));
  };

  const selectEndpointOs = async (_osType) => {
    setEndpointOS(_osType);
  };
  const selectEndpointFamily = async (_familyType) => {
    setEndpointFamily(_familyType);
  };
  const selectEndpointVendor = async (_vendorType) => {
    setEndpointVendor(_vendorType);
  };
  

  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12} sm={12} style={{ marginTop: '-64px' }}>
        <LocationSelect selectLocation={selectLocation} locationData={locationData} />
        <Divider sx={{ marginTop: '15px', width: '100%' }} />
      </Grid>


      <Grid container item spacing={3}>
        <InventoryCharts2 locationData={locationData}  selectEndpointOs={selectEndpointOs} selectEndpointFamily={selectEndpointFamily} selectEndpointVendor={selectEndpointVendor}/>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <EndpointTable locationData={locationData} endpointOS={endpointOS} setEndpointOS={setEndpointOS} endpointFamily={endpointFamily} setEndpointFamily={setEndpointFamily} endpointVendor={endpointVendor} setEndpointVendor={setEndpointVendor}/>
      </Grid>
    </Grid>
  );
};
export default EndpointsComponents;
