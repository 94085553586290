
import { Breadcrumbs, Container, Typography, IconButton } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import { DevicewiseSummaryComponent } from '../components/DevicewiseSummary';
//---------------------------------------------------------------------


export default function DevicewiseSummary() {

  return (
    <Page title="Summary/Devicewise">
      <Container maxWidth={false} >
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <IconButton component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </IconButton>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/summary-events">
              CMDB
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/devicewise-summary">
              Devicewise summary 
            </Typography>
          </Breadcrumbs>
        </div>

       <DevicewiseSummaryComponent />
      </Container>
    </Page>
  );
}
