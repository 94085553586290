import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, HomeOutlined, MyLocation } from '@mui/icons-material';
import { Breadcrumbs, Card, Container, Grid, IconButton, Stack, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';
import { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../actions/updateLocation';
import { getNetworkStat } from '../API/NetworkDevices/networkDevices';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import Page from '../components/Page';
import { UserContext } from '../context/UserContext';
import DashboardCharts from '../layouts/dashboard/DashboardCharts';


// -------------------------------------------------------------------------------

const useStyles = makeStyles({
  breadCrumbs: {
    cursor: 'pointer',
    paddingBottom: '20px',
  },
  cardLocationIconButton: {
    color: 'blue',
    background: '#c3cde6',
    borderRadius: '12px',
  },
  cardUpIconButton: {
    color: 'green',
    background: '#c3e6c3',
    borderRadius: '12px',
  },
  cardDownIconButton: {
    color: 'red',
    background: '#e6b8bf',
    borderRadius: '12px',
  },
});

const DRAWER_WIDTH = 0;

const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 20,
  zIndex: 5,
  flexWrap: 'nowrap',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

// ----------------------------------------------------------------------------------------------

export default function Dashboard() {
  const classes = useStyles();
  const [networkData, setNetworkData] = useState([]);
  const { customerId } = useContext(UserContext);
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.dashboard.locationData);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);
  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'dashboard', locationUuid: _locations }));
  };

  const getNetworkData = useCallback(async () => {
    getNetworkStat(customerId).then((res) => {
      setNetworkData(res);
    });
  }, [customerId]);

  useEffect(() => {
    getNetworkData();
  }, [customerId, getNetworkData, refreshStats]);


  return (
    <Page title="Dashboard">
      <Container maxWidth={false} >
        <div role="presentation" className={classes.breadCrumbs} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5, cursor: 'pointer' }}>
              <HomeOutlined />
            </Typography>

            <Typography component={Link} underline="hover" sx={{ pointerEvents: 'none' }} color="inherit" to="/dashboard/dashboard">
              Dashboard
            </Typography>
          </Breadcrumbs>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ px: 2, py: 3, minWidth: '250px' }} elevation={10}>
              <Stack direction="row" spacing={1}>
                <IconButton size="large" className={classes.cardLocationIconButton}>
                  <MyLocation />
                </IconButton>
                <Typography variant="h3" component="div">
                  {networkData && networkData.total_locations}
                </Typography>
                <Typography
                  color="text.primary"
                  variant="subtitle1"
                  component="div"
                  style={{ fontSize: '1.4rem', marginTop: '8px' }}
                >
                  Locations
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ px: 2, py: 3, minWidth: '250px' }} elevation={10}>
              <Stack direction="row" spacing={2}>
                <IconButton size="large" className={classes.cardUpIconButton}>
                  <ArrowCircleUpOutlined />
                </IconButton>
                <Typography variant="h3" component="div">
                  {networkData && networkData.network_device_up}
                </Typography>
                <Typography
                  color="text.primary"
                  variant="subtitle1"
                  component="div"
                  style={{ fontSize: '1.4rem', marginTop: '8px' }}
                >
                  Up
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ px: 2, py: 3, minWidth: '250px' }} elevation={10}>
              <Stack direction="row" spacing={2}>
                <IconButton size="large" className={classes.cardDownIconButton}>
                  <ArrowCircleDownOutlined />
                </IconButton>
                <Typography variant="h3" component="div">
                  {networkData && networkData.network_device_down}
                </Typography>
                <Typography
                  color="text.primary"
                  variant="subtitle1"
                  component="div"
                  style={{ fontSize: '1.4rem', marginTop: '8px' }}
                >
                  Down
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ marginTop: '1px' }}>
          <Grid item lg={12} md={12} sm={12}>
            <BoxStyle>
              <hr style={{ marginBottom: '30px' }} />
              <LocationSelect selectLocation={selectLocation} locationData={locationData} />
            </BoxStyle>
          </Grid>

          <Grid container item spacing={3}>
              <DashboardCharts locationData={locationData} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
