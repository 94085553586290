

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card, CardHeader, Chip, CircularProgress,
  Divider, Grid, IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteDcAgentSubnet,
  deleteDcAgents,
  enableDcAgents,
  getDcAgent,
  rejectDcAgents
} from '../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from '../../../utils/formatString';
// import { formatDateDifference } from '../../../utils/formatTime';
import NoDataComponent from '../../NoDataComponent';
import NotAuthorized from '../../NotAuthorized';
import Scrollbar from '../../Scrollbar';
import { WithConfirm } from '../../WithConfirm';
import AddDcSubnets from './AddSubnets/AddDcSubnets';
import ConfigureDcAgent from './Configure/ConfigureDcAgent';
import ReconfigureDcAgent from './ReConfigure/ReConfigureDcAgent';
import ScanButton from './ScanButton';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  tableContainer: {
    "&::-webkit-scrollbar": {
      width: 7,
      height: '12px',
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: '#f1f1f1',
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: 'rgb(181, 179, 179)',
      borderRadius: '30px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: '#555'
    }
  },
  circularprogress: {
    position: 'absolute',
    left: 18,
    top: 22,
  },
  cardAvatar: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
    marginLeft: '10px',
  },
});
// -----------------------------------------------------------------------------------------------------------
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export function formatDateDifference(endDate) {
  if (endDate) {
    // If endDate is a Unix timestamp, convert it to a Date object
    const dateObject = new Date(typeof endDate === 'number' ? endDate * 1000 : endDate);
    
    // Format components
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');

    // Return formatted date and time
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  return "";
}

// -----------------------------------------------------------------------------------------------------------

const DcAgentTable = (props) => {
  const classes = useStyles();
  const { confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  // const [DcAgentsData, setDcAgentsData] = useState('');
  const [DcAgentsData, setDcAgentsData] = useState([]); // Initialize as an empty array

  const [dcAgentLoading, setDcAgentLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [agentData, setAgentData] = useState(null);
  const [openAuthoriseConfigureDialog, setOpenAuthoriseConfigureDialog] = useState(false);
  const [openEnableAgentDialog, setOpenEnableAgentDialog] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [assignSubnetsDialog, setAssignSubnetsDialog] = useState(false);
  const [editAgentData, setEditAgentData] = useState({});
  const [authorized, setAuthorized] = useState(true);
  const refreshStats = useSelector((state) => state.statCounter.refreshStats);


  const fetchDcAgents = async () => {
    setDcAgentLoading(true);
    setCountLoading(true);
    getDcAgent(customerId, page + 1)
      .then((res) => {
        setDcAgentLoading(false);
        setCountLoading(false);
        setDcAgentsData(res);

        setCount(res.total);
        setRowsPerPage(15);

      })
      .catch((result) => {
        setDcAgentLoading(false);
        setCountLoading(false);

        // if (result.response.status === 403) {
        //   setAuthorized(f"alse);
        // }
      });
  };

  useEffect(() => {
    fetchDcAgents();
  }, [customerId, refreshStats]);

  const handleRefresh = () => {
    setRefreshLoading(true);
    fetchDcAgents();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  //   const filteredAgents = DcAgentsData?.data?.filter((customer) =>
  //     customer?.r_agent_id.toLowerCase()?.includes(query?.toLowerCase())
  //   );
  // console.log(filteredAgents,"filteredAgents");

  const filteredAgents = DcAgentsData?.data?.filter((customer) =>
    customer?.r_agent_id.toLowerCase().includes(query.toLowerCase()) ||
    customer?.r_agent_name.toLowerCase().includes(query.toLowerCase())
  );

  // const filteredAgents = DcAgentsData?.data?.filter((customer) =>
  //   customer?.r_agent_id.toLowerCase().includes(query.toLowerCase())
  // );

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleDisable = (value) => {
    setDcAgentLoading(true);
    rejectDcAgents(customerId, value.agent_id)
      .then((res) => {
        setDcAgentLoading(false);
        fetchDcAgents();
        enqueueSnackbar(
          'Agent disabled sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  // ----------------------------------------------------------------------
  const handleRejects = (value) => {
    setDcAgentLoading(true);
    rejectDcAgents(customerId, value.agent_id)
      .then((res) => {
        setDcAgentLoading(false);
        fetchDcAgents();
        enqueueSnackbar(
          'Reject done sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleDelete = (value) => {
    setDcAgentLoading(true);
    deleteDcAgents(customerId, value.agent_id)
      .then((res) => {
        setDcAgentLoading(false);
        fetchDcAgents();
        enqueueSnackbar(
          'Deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const enableDcAgent = (value) => {
    setDcAgentLoading(true);
    enableDcAgents(customerId, value.agent_id)
      .then((res) => {
        setDcAgentLoading(false);
        fetchDcAgents();
        enqueueSnackbar(
          'enabled sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleCellClick = (value) => {
    setAgentData(value);
    setOpenAuthoriseConfigureDialog(true);
  };

  const reConfigCellClick = (value) => {
    setAgentData(value);
    setOpenEnableAgentDialog(true);
  };

  const onAuthorizeAgentSuccessClose = () => {
    setAgentData(null);
    fetchDcAgents();
    setOpenAuthoriseConfigureDialog(false);
    setOpenEnableAgentDialog(false);
  };

  const handleSubnetChipDelete = (agent, subnetUid) => {
    setDcAgentLoading(true);
    const payload = {
      subnet_uuids: [subnetUid],
    };
    deleteDcAgentSubnet(customerId, agent.agent_id, payload)
      .then((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          'subnet unselected',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDcAgentLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleAssignSubnet = (value) => {
    setEditAgentData(value);
    setAssignSubnetsDialog(true);
  };

  const showActionButtons = (agent) => {
    switch (agent.r_agent_status) {
      case 'NEW':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ color: '#2065D1', minWidth: '160px' }}
              onClick={() => handleCellClick(agent)}
            >
              Authorize
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ color: 'red', minWidth: '160px' }}
              onClick={confirm(() => handleRejects(agent), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to reject this agent details ?',
              })}
            >
              Reject
            </Button>
          </Box>
        );
      case 'INACTIVE':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ color: '#2065D1', minWidth: '160px' }}
              onClick={() => handleCellClick(agent)}
            >
              Enable
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ color: 'red', minWidth: '160px' }}
              onClick={confirm(() => handleDelete(agent), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to delete this agent details ?',
              })}
            >
              Delete
            </Button>
          </Box>
        );

      case 'SCAN_INPROGRESS':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={() => reConfigCellClick(agent)} sx={{ minWidth: '160px' }}>
              Re-configure
            </Button>
            <ScanButton style={{ width: '100%', minWidth: '160px' }} disabled agent={agent} />
            {/* <Button variant="outlined" onClick={() => handleAssignSubnet(agent)}>
              Add Subnet
            </Button> */}
            <Button
              variant="outlined"
              color="error"
              sx={{ color: 'red', minWidth: '160px' }}
              onClick={confirm(() => handleDisable(agent), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to disable this agent details ?',
              })}
            >
              Disable
            </Button>
          </Box>
        );
      case 'CONFIGURING':
      case 'CONFIGURED':
      case 'RUNNING':
      case 'STOPPED':
      case 'ACTIVE':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-end' }}>
            <Button variant="outlined" sx={{ color: '#2065D1' }} onClick={() => reConfigCellClick(agent)}>
              Re-configure
            </Button>
            <ScanButton style={{ width: '100%', minWidth: '160px' }} agent={agent} />
            {/* <Button variant="outlined" onClick={() => handleAssignSubnet(agent)} sx={{ minWidth: '160px' }}>
              Add Subnet
            </Button> */}
            <Button
              variant="outlined"
              color="error"
              sx={{ color: 'red', minWidth: '160px' }}
              onClick={confirm(() => handleDisable(agent), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to disable this agent details ?',
              })}
            >
              Disable
            </Button>
          </Box>
        );

      default:
        return '';
    }
  };

  const showAgentStatus = (item) => {
    switch (item.r_agent_status) {
      case 'NEW':
        return <Chip variant="outlined" color="success" label={toCamelizeWords('NEW')} />;
      case 'CONFIGURING':
        return <Chip variant="outlined" color="warning" label={toCamelizeWords('CONFIGURING')} />;
      case 'CONFIGURED':
        return <Chip variant="outlined" color="warning" label={toCamelizeWords('CONFIGURED')} />;
      case 'RUNNING':
        return (
          <Tooltip title={formatDateDifference(item.r_scan_end_time)}>
            <div
              style={{
                height: 25,
              }}
            >
              <Chip variant="outlined" color="success" label={toCamelizeWords('RUNNING')} />
              <p>

                {item.r_scan_end_time && `Last scanned ${formatDateDifference(item.r_scan_end_time)}`}
              </p>
            </div>
          </Tooltip>
        );
      case 'ACTIVE':
          if (!item.error_desc || item.error_desc === '') {
            return (
              
               <Tooltip title={formatDateDifference(item.r_scan_end_time)}>
               <div
                 style={{
                   height: 25,
                 }}
               >
                 <Chip variant="outlined" color="success" label={toCamelizeWords('Active')} />
                 <p>
                   {item.r_scan_end_time && `Last scanned ${formatDateDifference(item.r_scan_end_time)}`}
                 </p>
               </div>
             </Tooltip>
            );
          }
        return (          
          
                <div style={{ height: 25 }}>
                  <Chip variant="outlined" color="success" label={toCamelizeWords('Active')}/>
                  <p>{item.r_scan_end_time && `Last scanned ${formatDateDifference(item.r_scan_end_time)}`}</p>
                  <Tooltip title={item.error_desc}>
                  <WarningAmberIcon color="warning" />
                  </Tooltip>
                  
                </div>
        );
      case 'STOPPED':
        return <Chip variant="outlined" label={toCamelizeWords('STOPPED')} />;
      case 'SCAN_INPROGRESS':
        return (
          <Tooltip title={formatDateDifference(item.r_scan_start_time)}>
            <div
              style={{
                height: 25,
              }}
            >
              <Chip variant="outlined" color="warning" label={'Scan in progress'} />
              <p>

                {item.r_scan_start_time && `Scan started ${formatDateDifference(item.r_scan_start_time)}`}
              </p>
            </div>
          </Tooltip>
        );
      case 'NOT_RESPONDING':
        return <Chip variant="outlined" color="error" label={toCamelizeWords('NOT_RESPONDING')} />;
      case 'INACTIVE':
        return <Chip variant="outlined" label={toCamelizeWords('INACTIVE')} />;
      default:
        return '';
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Chip label={filteredAgents?.length || 0} />
                {countLoading && <CircularProgress size={28} className={classes.circularprogress} />}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '8px' }}> DC Agents </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>

                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='search'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search DC Agents" 
                />

              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1, px: 1 }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Scrollbar>
          <TableContainer className={classes.tableContainer}>
            {!authorized ? (
              <NotAuthorized />
            ) : (
              <Table>
                {filteredAgents?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <NoDataComponent noDataFoundText="Agents" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '10%' }}>Agent name</TableCell>
                        <TableCell style={{ width: '10%' }}>Agent ID</TableCell>
                        <TableCell style={{ width: '50%' }}>Subnets</TableCell>
                        {/* <TableCell style={{ width: '10%' }}>Last scanned</TableCell> */}
                        <TableCell style={{ width: '15%' }}>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>


                    <TableBody>
                      {filteredAgents?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <NoDataComponent noDataFoundText="Agents" />
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredAgents?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item) => (
                          <TableRow key={item.agent_id}>
                            <TableCell style={{ verticalAlign: 'top', width: '10%' }}>{item.r_agent_name}</TableCell>
                            <TableCell style={{ verticalAlign: 'top', width: '10%' }}>{item.r_agent_id}</TableCell>
                            <TableCell style={{ verticalAlign: 'top', width: '50%' }}>
                              <Stack direction="row" spacing={1}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                    maxHeight: '150px',
                                    overflow: 'auto',
                                  }}
                                >
                                  {item.subnets?.map((_subnet) => (
                                    <Tooltip arrow key={_subnet.subnet_id} title={toCamelizeWords(_subnet.r_scan_status)}>
                                      <Chip
                                        size="small"
                                        label={_subnet.r_subnet_value}
                                        key={_subnet.subnet_id}
                                        variant="outlined"
                                      />
                                    </Tooltip>
                                  ))}
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top', width: '16%' }}>
                              {showAgentStatus(item)}
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top', display: 'flex', gap: 5, minHeight: '185px' }}>
                              <Box>{showActionButtons(item)}</Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>

                  </>
                )}
                <TableFooter>
                  <TableRow>

                    <TablePagination
                      colSpan={5}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      count={filteredAgents?.length}
                      labelRowsPerPage=""
                      rowsPerPageOptions={[]}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />

                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </Scrollbar>
        {openAuthoriseConfigureDialog && (
          <ConfigureDcAgent
            openDialog={openAuthoriseConfigureDialog}
            handleClose={() => onAuthorizeAgentSuccessClose()}
            agent={agentData}
          />
        )}
        {openEnableAgentDialog && (
          <ReconfigureDcAgent
            openDialog={openEnableAgentDialog}
            handleClose={() => setOpenEnableAgentDialog(false)}
            onConfigure={() => onAuthorizeAgentSuccessClose()}
            agentData={agentData}
          />
        )}
        {assignSubnetsDialog && (
          <AddDcSubnets
            openDialog={assignSubnetsDialog}
            handleClose={() => setAssignSubnetsDialog(false)}
            fetchDcAgents={fetchDcAgents}
            agentData={editAgentData}
            handleRefresh={handleRefresh}
          />
        )}
      </Card>
    </>
  );
};
export default WithConfirm(DcAgentTable);