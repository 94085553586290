import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@mui/material';
import DateInputDialog from './DateInputDialog'; 
import { UserContext } from '../../../context/UserContext';

const HoldtillDialog = ({ openDialog, handleClose, api, eventId, onHold }) => {
  const [openDateDialog, setOpenDateDialog] = useState(false); 
  const [holdDate, setHoldDate] = useState(''); 
  const [loading, setLoading] = useState(false);
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleHoldUntil = () => {
    setOpenDateDialog(true);
  };

  const handleDateDialogClose = (date) => {
    if (date) {
      setHoldDate(date); 
    }
    setOpenDateDialog(false); 
  };

  const formatHoldDate = (date) => {
    if (!date) return ''; 
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleConfirmHold = async () => {
    if (holdDate) {
      setLoading(true);
      const formattedDate = formatHoldDate(holdDate);
      const payload = { 'Change till': formattedDate }; 

      try {
        const response = await api.holdEventChange(customerId, eventId, payload);
        console.log('API Response:', response);

        const successMessage = response.message || 'Event successfully put on hold.';
        enqueueSnackbar(successMessage, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });

        if (onHold && response['Change till']) {
          onHold(response['Change till']); 
        }
      } catch (error) {
        console.error('Error occurred:', error);

        let errorMessage = 'Failed to put the event on hold.';
        if (error.response) {
          errorMessage = error.response.data?.error || errorMessage;
        } else if (error.request) {
          errorMessage = 'No response received from the server.';
        } else if (error.message) {
          errorMessage = error.message;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="holdtill-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="holdtill-dialog-title">Hold Event</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            {holdDate
              ? `Do you want to hold the change until ${formatHoldDate(holdDate)}?`
              : 'Please select a date to put the event on hold.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleHoldUntil} color="warning" variant="contained" disabled={loading}>
            Hold Until
          </Button>
          {holdDate && (
            <Button onClick={handleConfirmHold} color="primary" disabled={loading}>
              {loading ? 'Processing...' : 'Confirm Hold'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Date Input Dialog */}
      <DateInputDialog open={openDateDialog} onClose={handleDateDialogClose} minDate={getTodayDate()} />
    </>
  );
};

HoldtillDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired, 
  eventId: PropTypes.string.isRequired,
  onHold: PropTypes.func.isRequired,
  // holdDate: PropTypes.string, // Add holdDate as an optional prop
};

export default HoldtillDialog;
