import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const fetchCredentials = async (id, data) => {
  const newPro = modem
    .post(`${id}/credentials`, data)
    .then((response) => {
      return response.data.data;
    })

  return newPro;
};

export const addCredentials = async (id, data) => {
  const newPromise = modem
    .post(`${id}/credentials/add`, data)
    .then((response) => {
      return response.data.data;
    })
  return newPromise
};

export const editCredentials = async (id, credentialId, data) => {
  const newPromise = modem
    // .post(`${id}/credentials/:credential_uuid`, data)
    .post(`${id}/credentials/${credentialId}`, data)
    .then((response) => {
      return response.data.data;
    })
  return newPromise
};

export const deleteCredentials = async (id, credentialsId) => {
  const newPromise = modem
    .delete(`${id}/credentials/${credentialsId}`)
    .then((response) => {
      return response.data.data;
    })
  return newPromise
};
