import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import endpointIcon from '../../Assets/endpointIcon.png';
import networkDeviceIcon from '../../Assets/networkDeviceIcon.png';
import sitewiseIcon from '../../Assets/sitewiseIcon.png';
import globalMapIcon from '../../Assets/globalMapIcon.png';
// ------------------------------------------
const navConfigCollapsed = [
  {
    title: 'Dashboard',
    icon: <DashboardOutlinedIcon />,
    to: '/dashboard/dashboard',
  },
  // {
  //   title: 'Network Health',
  //   icon: <MonitorHeartOutlinedIcon />,
  //   to: '/dashboard/network-health',
  // },
  {
    title: 'Global Map',
    icon: <img width="17px" height="17px" src={globalMapIcon} alt="globalMapIcon" />,
    to: '/dashboard/global-map',
  },
  {
    title: 'Sitewise Map',
    icon: <img width="17px" height="17px" src={sitewiseIcon} alt="sitewiseIcon" />,
    to: '/dashboard/sitewise-map',
  },
  {
    title: 'Network Devices',
    icon: <img width="17px" height="17px" src={networkDeviceIcon} alt="networkDeviceIcon" />,
    to: '/dashboard/network-device',
  },
  {
    title: 'Client devices',
    icon: <img width="19px" height="19px" src={endpointIcon} alt="endpointIcon" />,
    to: '/dashboard/endpoint',
  },
  {
    title: 'Unknown Devices',
    icon: <HelpOutlineOutlinedIcon />,
    to: '/dashboard/unknown-devices',
  },
  // {
  //   title: 'IPAM',
  //   icon: <CalendarViewDayOutlinedIcon />,
  //   to: '/dashboard/ipam',
  // },
  // {
  //   title: 'Grouped summary',
  //   icon: <DatasetIcon />,
  //   to: '/dashboard/grouped-summary',
  // },
  // {
  //   title: 'Devicewise summary',
  //   icon: <ViewTimelineIcon />,
  //   to: '/dashboard/devicewise-summary',
  // },
  {
    title: 'Change events',
    icon: <StorageIcon />,
    to: '/dashboard/summary-events',
  },
  {
    title: 'CMDB Inventory',
    icon: <BackupTableIcon />,
    to: '/dashboard/config-change-backup-table',
  },
  // {
  //   title: 'Backups(Old)',
  //   icon: <PieChartOutlinedIcon />,
  //   to: '/dashboard/configuration',
  // },
  // {
  //   title: 'Overview',
  //   icon: <StackedLineChartOutlinedIcon />,
  //   to: '/dashboard/isp-management',
  // },
  // {
  //   title: 'SDWAN',
  //   icon: <StackedLineChartOutlinedIcon />,
  //   to: '/dashboard/sdwan',
  // },
  // {
  //   title: 'Sitewise',
  //   icon: <PieChartOutlinedIcon />,
  //   to: '/dashboard/ISP-sitewise',
  // },
  {
    title: 'Device Up/Down',
    icon: <AnalyticsOutlinedIcon />,
    to: '/dashboard/device-up-down',
  },
  {
    title: 'CMDB Scan Events',
    icon: <MonitorHeartOutlinedIcon />,
    to: '/dashboard/config-backup-events',
  },
  {
    title: 'CMDB Change Events',
    icon: <AirlineStopsIcon />,
    to: '/dashboard/config-change-events',
  },
  {
    title: 'My Profile',
    icon: <AccountBoxIcon />,
    to: './Settings',
  },
  {
    title: 'Users',
    icon: <GroupOutlinedIcon />,
    to: '/dashboard/members',
  },
  // {
  //   title: 'Schedulers',
  //   icon: <DateRangeOutlinedIcon />,
  //   to: '/dashboard/schedulers',
  // },
  {
    title: 'Roles',
    icon: <AttributionOutlinedIcon />,
    to: '/dashboard/Roles',
  },
  {
    title: 'Email Configuration',
    icon: <NotificationsActiveOutlinedIcon />,
    to: '/dashboard/email-config',
  },
  {
    title: 'Notifications',
    icon: <SettingsOutlinedIcon />,
    to: '/dashboard/general',
  },
  {
    title: 'CMDB Ignore Rule',
    icon: <RuleIcon />,
    to: '/dashboard/ignore-rule',
    strict: true,
    permission: "NMS_PORTAL_DEVICE_CONFIG_IGNORE_RULES_READ"
  },
  {
    title: 'DC Agents',
    icon: <TokenOutlinedIcon />,
    to: '/dashboard/dc-agent',
  },
  {
    title: 'Locations',
    icon: <AddLocationOutlinedIcon />,
    to: '/dashboard/locations',
  },
  // {
  //   title: 'On-demand',
  //   icon: <OndemandVideoIcon />,
  //   to: '/dashboard/ondemand',
  // },
  {
    title: 'Zones',
    icon: <MyLocationOutlinedIcon />,
    to: '/dashboard/zones',
  },
  {
    title: 'Credentials',
    icon: <PasswordOutlinedIcon />,
    to: '/dashboard/credential',
  },
];

export default navConfigCollapsed;