import React from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack } from '@mui/material';
import { FormProvider, RHFTextField } from '../../hook-form';

// -------------------------------------------------------------------

// ------------------------------------------------------
export default function EmailTemplateConfig(props) {
  const LoginSchema = Yup.object().shape({
    servername: Yup.string().required('required'),
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    username: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  // useEffect(()=> {reset({username: userInfo.name,email:userInfo.email})}, [userInfo])

  const onSubmit = (value) => {
    console.log(value);
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ px: 4, py: 2 }} elevation={2}>
        <Stack spacing={4} direction="row">          
          <Stack direction="column" spacing={2} sx={{ width: '250px' }}>
            <FormControl fullWidth size="small">
              <InputLabel>Category</InputLabel>
              <Select value={age} onChange={handleChange} displayEmpty>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <RHFTextField name="number" label="Template name" size="small" />
            <RHFTextField name="emailsubject" label="Email subject" size="small" />
            <RHFTextField name="sms" label="Body" size="large" />
          </Stack>
        </Stack>
        <Box sx={{ py: 2 }} spacing={2}>
          <Stack spacing={2} direction="row">
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} color="primary" sx={{ float: 'left' }}>
              Save
            </LoadingButton>
            <LoadingButton type="submit" variant="outlined" loading={isSubmitting} color="error" sx={{ float: 'left' }}>
              Reset
            </LoadingButton>
          </Stack>
        </Box>
      </Paper>
    </FormProvider>
  );
}
