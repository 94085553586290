
// import {
//     Box,
//     LinearProgress, Paper
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { AgGridReact } from 'ag-grid-react';
// import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import Tooltip from '@mui/material/Tooltip';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import SdwanGraph from './SdwanGraph';
// import { useHealthContext } from '../../../userContextHealth';
// import { useSearchParams } from 'react-router-dom';
// import { UserContext } from 'src/context/UserContext';
// import { sdwanInterfaceLink, sdwanLink } from 'src/API/NetworkDevices/health';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     height: 300, // Reduced height
// }));

// const SDwanLinkTable = (sDwanLinkTableExpanded) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [searchParams] = useSearchParams();
//     const locationUuid = searchParams.get('locationUuid');
//     const identifier = searchParams.get('identifier');
//     const [linkData, setLinkData] = useState([]);
//     const [subTableData, setSubTableData] = useState([]);
//     const [selectedLink, setSelectedLink] = useState(null);
//     const [selectedSubLink, setSelectedSubLink] = useState(null);
//     const [loading, setLoading] = useState(true);

//     const isTablet = useMediaQuery('(max-width: 1020px)');
//     const isDesktop = useMediaQuery('(min-width: 1440px)');
//     const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

//     const getFontSize = () => {
//         if (isLargeDesktop) return '20px';
//         if (isDesktop) return '16px';
//         if (isTablet) return '14px';
//         return '12px';
//     };
//     const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

//     const linkColumnHeaders = [
//         {
//             headerName: 'Link Name',
//             field: 'name',
//             width: 500,
//             cellStyle: (params) => ({
//                 ...responsiveCellStyle,
//                 backgroundColor: selectedLink && selectedLink.name === params.value ? '#d1e7dd' : 'inherit',
//                 cursor: 'pointer',
//             }),
//             cellRenderer: (params) => (
//                 <Tooltip title={`Link: ${params.data.name}`} arrow>
//                     <span>{params.data.name}</span>
//                 </Tooltip>
//             ),
//             onCellClicked: (params) => {
//                 setSelectedLink(params.data);
//                 setSelectedSubLink(null);
//                 fetchSubTableData(params.data.health_link_id); 
//             },
//         },
//     ];

//     const subLinkColumnHeaders = [
//         {
//             headerName: 'Link Interface Name',
//             field: 'linkIfName',
//             width: 500,
//             cellStyle: (params) => ({
//                 ...responsiveCellStyle,
//                 backgroundColor: selectedSubLink && selectedSubLink.linkIfName === params.value ? '#d1e7dd' : 'inherit',
//                 cursor: 'pointer',
//             }),
//             cellRenderer: (params) => (
//                 <Tooltip title={`Sub Link: ${params.data.linkIfName}`} arrow>
//                     <span>{params.data.linkIfName}</span>
//                 </Tooltip>
//             ),
//             onCellClicked: (params) => {
//                 setSelectedSubLink(params.data); 
//             },
//         },
//     ];

//     const fetchLinkData = useCallback(() => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: identifier,
//         };
//         setLoading(true);
//         sdwanLink(customerId, payload)
//         .then((res) => {
//             setLoading(false);
//             const mappedData = res.map(item => ({
//                 name: item.sdwan_link_name, 
//                 health_link_id: item.health_link_id,
//                 timestamp: item.timestamp,
//             }));
//             setLinkData(mappedData); 
//         })
//         .catch(() => {
//             setLoading(false);
//         });
//     }, [sDwanLinkTableExpanded, healthDashState.timeRange]);

//     const fetchSubTableData = useCallback((healthLinkId) => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: identifier,
//         };

//         setLoading(true);
//         sdwanInterfaceLink(customerId, healthLinkId, payload)  
//             .then((res) => {
//                 setLoading(false);
//                 const mappedData = res.map(item => ({
//                     sdwanStaticInterfaceId: item.sdwan_static_interface_id,  
//                     healthLinkId: item.health_link_id,                       
//                     linkIfName: item.sdwan_link_interface_name,              
//                 }));
//                 setSubTableData(mappedData);  
//             })
//             .catch(() => {
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         fetchLinkData();
//         setSelectedLink(null);
//     }, [healthDashState.selectedDevice]);

//     const defaultColDef = useMemo(() => ({
//         resizable: true,
//         filter: true,
//         sortable: true,
//         suppressMenu: true,
//     }), []);

//     return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//             <Box sx={{ display: 'flex', gap: 2 }}>
//                 <Paper variant="outlined" sx={{ flex: 1 }}>
//                     {loading && <LinearProgress sx={{ height: '2px' }} />}
//                     <ChartWrapperStyle dir="ltr">
//                         <Box style={{ height: '290px', width: '100%' }} className="ag-theme-material">
//                             <AgGridReact
//                                 rowData={linkData}
//                                 columnDefs={linkColumnHeaders}
//                                 defaultColDef={defaultColDef}
//                             />
//                         </Box>
//                     </ChartWrapperStyle>
//                 </Paper>
//                 {selectedLink && (
//                     <Paper variant="outlined" sx={{ flex: 1 }}>
//                         <ChartWrapperStyle dir="ltr">
//                             <Box style={{ height: '290px', width: '100%' }} className="ag-theme-material">
//                                 <AgGridReact
//                                     rowData={subTableData}
//                                     columnDefs={subLinkColumnHeaders}
//                                     defaultColDef={defaultColDef}
//                                 />
//                             </Box>
//                         </ChartWrapperStyle>
//                     </Paper>
//                 )}
//             </Box>
//             {selectedSubLink && (
//                 <Box sx={{ mt: 2, width: '100%', height: '400px' }}> 
//                     <SdwanGraph
//                         selectedInterface={selectedSubLink}
//                         sdwanStaticInterfaceId={selectedSubLink.sdwanStaticInterfaceId} 
//                     />
//                 </Box>
//             )}
//         </Box>
//     );
// };

// export default SDwanLinkTable;


// import {
//     Box,
//     LinearProgress, Paper, Select, MenuItem, Typography, FormControl, InputLabel
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useCallback, useContext, useEffect, useState } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import SdwanGraph from './SdwanGraph';
// import { useHealthContext } from '../../../userContextHealth';
// import { useSearchParams } from 'react-router-dom';
// import { UserContext } from 'src/context/UserContext';
// import { sdwanInterfaceLink, sdwanLink } from 'src/API/NetworkDevices/health';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     height: 300, // Adjust as necessary
// }));

// const SDwanLinkTable = () => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [searchParams] = useSearchParams();
//     const locationUuid = searchParams.get('locationUuid');
//     const identifier = searchParams.get('identifier');
//     const [linkData, setLinkData] = useState([]);
//     const [subTableData, setSubTableData] = useState([]);
//     const [selectedLink, setSelectedLink] = useState('');
//     const [selectedSubLink, setSelectedSubLink] = useState('');
//     const [loading, setLoading] = useState(true);

//     const isTablet = useMediaQuery('(max-width: 1020px)');
//     const isDesktop = useMediaQuery('(min-width: 1440px)');
//     const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

//     const fetchLinkData = useCallback(() => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: identifier,
//         };
//         setLoading(true);
//         sdwanLink(customerId, payload)
//         .then((res) => {
//             setLoading(false);
//             const mappedData = res.map(item => ({
//                 name: item.sdwan_link_name, 
//                 health_link_id: item.health_link_id,
//             }));
//             setLinkData(mappedData); 
//         })
//         .catch(() => {
//             setLoading(false);
//         });
//     }, [healthDashState.timeRange]);

//     const fetchSubTableData = useCallback((healthLinkId) => {
//         const data = getStartEnd();
//         const payload = {
//             start: data.start / 1000,
//             end: data.end / 1000,
//             step: data.step,
//             device_type: identifier,
//         };

//         setLoading(true);
//         sdwanInterfaceLink(customerId, healthLinkId, payload)  
//             .then((res) => {
//                 setLoading(false);
//                 const mappedData = res.map(item => ({
//                     sdwanStaticInterfaceId: item.sdwan_static_interface_id,  
//                     linkIfName: item.sdwan_link_interface_name,              
//                 }));
//                 setSubTableData(mappedData);  
//             })
//             .catch(() => {
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         fetchLinkData();
//         setSelectedLink('');
//         setSelectedSubLink('');
//     }, [healthDashState.selectedDevice]);

//     // Handle Link Selection
//     const handleLinkSelect = (event) => {
//         const selectedLink = linkData.find(link => link.name === event.target.value);
//         setSelectedLink(selectedLink.name);
//         fetchSubTableData(selectedLink.health_link_id);
//         setSelectedSubLink(''); // Reset sub-link selection
//     };

//     // Handle Sub-Link Selection
//     const handleSubLinkSelect = (event) => {
//         const selectedSubLink = subTableData.find(subLink => subLink.linkIfName === event.target.value);
//         setSelectedSubLink(selectedSubLink);
//     };

//     return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
//             <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
//                 <FormControl fullWidth variant="outlined">
//                     <InputLabel>Select Link</InputLabel>
//                     <Select
//                         value={selectedLink}
//                         onChange={handleLinkSelect}
//                         label="Select Link"
//                     >
//                         {linkData.map(link => (
//                             <MenuItem key={link.health_link_id} value={link.name}>
//                                 {link.name}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>
                
//                 {selectedLink && (
//                     <FormControl fullWidth variant="outlined">
//                         <InputLabel>Select Interface</InputLabel>
//                         <Select
//                             value={selectedSubLink ? selectedSubLink.linkIfName : ''}
//                             onChange={handleSubLinkSelect}
//                             label="Select Interface"
//                         >
//                             {subTableData.map(subLink => (
//                                 <MenuItem key={subLink.sdwanStaticInterfaceId} value={subLink.linkIfName}>
//                                     {subLink.linkIfName}
//                                 </MenuItem>
//                             ))}
//                         </Select>
//                     </FormControl>
//                 )}
//             </Box>

//             {loading && <LinearProgress sx={{ height: '2px' }} />}

//             {selectedSubLink && (
//                 <Box sx={{ mt: 2, width: '100%', height: '400px' }}> 
//                     <SdwanGraph
//                         selectedInterface={selectedSubLink}
//                         sdwanStaticInterfaceId={selectedSubLink.sdwanStaticInterfaceId} 
//                     />
//                 </Box>
//             )}
//         </Box>
//     );
// };

// export default SDwanLinkTable;


import {
    Box,
    LinearProgress, Paper, Select, MenuItem, Typography, FormControl, InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import SdwanGraph from './SdwanGraph';
import { useHealthContext } from '../../../userContextHealth';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import { sdwanInterfaceLink, sdwanLink } from 'src/API/NetworkDevices/health';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 375, // Adjust as necessary
}));

const SDwanLinkTable = () => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const locationUuid = searchParams.get('locationUuid');
    const identifier = searchParams.get('identifier');
    const [linkData, setLinkData] = useState([]);
    const [subTableData, setSubTableData] = useState([]);
    const [selectedLink, setSelectedLink] = useState('');
    const [selectedSubLink, setSelectedSubLink] = useState('');
    const [loading, setLoading] = useState(true);

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const fetchLinkData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: identifier,
        };
        setLoading(true);
        sdwanLink(customerId, payload)
        .then((res) => {
            setLoading(false);
            const mappedData = res.map(item => ({
                name: item.sdwan_link_name, 
                health_link_id: item.health_link_id,
            }));
            setLinkData(mappedData); 
        })
        .catch(() => {
            setLoading(false);
        });
    }, [healthDashState.timeRange]);

    const fetchSubTableData = useCallback((healthLinkId) => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: identifier,
        };

        setLoading(true);
        sdwanInterfaceLink(customerId, healthLinkId, payload)  
            .then((res) => {
                setLoading(false);
                const mappedData = res.map(item => ({
                    sdwanStaticInterfaceId: item.sdwan_static_interface_id,  
                    linkIfName: item.sdwan_link_interface_name,              
                }));
                setSubTableData(mappedData);  
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchLinkData();
        setSelectedLink('');
        setSelectedSubLink('');
    }, [healthDashState.selectedDevice]);

    // Handle Link Selection
    const handleLinkSelect = (event) => {
        const selectedLink = linkData.find(link => link.name === event.target.value);
        setSelectedLink(selectedLink.name);
        fetchSubTableData(selectedLink.health_link_id);
        setSelectedSubLink(''); // Reset sub-link selection
    };

    // Handle Sub-Link Selection
    const handleSubLinkSelect = (event) => {
        const selectedSubLink = subTableData.find(subLink => subLink.linkIfName === event.target.value);
        setSelectedSubLink(selectedSubLink);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Select Link</InputLabel>
                    <Select
                        value={selectedLink}
                        onChange={handleLinkSelect}
                        label="Select Link"
                    >
                        {linkData.map(link => (
                            <MenuItem key={link.health_link_id} value={link.name}>
                                {link.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                <FormControl fullWidth variant="outlined" disabled={!selectedLink}>
                    <InputLabel>Select Interface</InputLabel>
                    <Select
                        value={selectedSubLink ? selectedSubLink.linkIfName : ''}
                        onChange={handleSubLinkSelect}
                        label="Select Interface"
                    >
                        {subTableData.map(subLink => (
                            <MenuItem key={subLink.sdwanStaticInterfaceId} value={subLink.linkIfName}>
                                {subLink.linkIfName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {loading && <LinearProgress sx={{ height: '2px' }} />}

            {selectedSubLink && (
                <Box sx={{ mt: 2, width: '100%', height: '400px' }}> 
                    <SdwanGraph
                        selectedInterface={selectedSubLink}
                        sdwanStaticInterfaceId={selectedSubLink.sdwanStaticInterfaceId} 
                    />
                </Box>
            )}
        </Box>
    );
};

export default SDwanLinkTable;
