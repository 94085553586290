import { instance as modem } from '../modem';

export const getDeviceTypes = async (id, data) => {
  const newPro = modem.post(`/${id}/network-devices/device-types`, data).then((response) => {
    return response.data.data
  });
  return newPro;
};

export const getDeviceLifeCycle = async (id, data) => {
  const newPro = modem.post(`/${id}/network-devices/device-life-cycle`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};
export const updateDeviceType = async (id, networkDeviceUid , data) => {
  const newPro = modem.post(`/${id}/network-devices/device-types/${networkDeviceUid}`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};