import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import HealthDashboardComponent from '../components/FirewallHealth/HealthDashboardComponent';
import DeviceTypeSelector from '../components/FirewallHealth/components/DeviceTypeSelector';
import LocationSingleSelector from '../components/FirewallHealth/components/LocationSingleSelector';
import TimeRangeComponent from '../components/FirewallHealth/components/TimeRangeComponent';
import Page from '../components/Page';


// -------------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function FirewallHealth() {

  return (
    <Page title="Health Dashboard">
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={3}>

          <Grid item container direction="row" spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
                  <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                    <HomeOutlinedIcon 
                    sx={{
                      fontSize: {
                        xs: '20px',
                        sm: '22px',
                        md: '24px',
                        lg: '26px',
                        xl: '28px',
                        xxl: '30px',
                      },
                    }} />
                  </Typography>
                  <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard" 
                  sx={{
                    fontSize: {
                      xs: '8px',
                      sm: '12px',
                      md: '14px',
                      lg: '16px',
                      xl: '18px',
                      xxl: '20px',
                    },
                  }}>
                    Dashboard
                  </Typography>
                  <Typography component={Link} underline="hover" color="inherit" to="/dashboard/health" 
                  sx={{
                    fontSize: {
                      xs: '8px',
                      sm: '12px',
                      md: '14px',
                      lg: '16px',
                      xl: '18px',
                      xxl: '20px',
                    },
                  }}>
                    Health-dashboard
                  </Typography>
                </Breadcrumbs>
              </div>
            </Grid>

            <Grid item container direction="column" spacing={2} sm={8} md={8} lg={8} sx={{ textAlign: 'right' }}>
              <Grid item container direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <LocationSingleSelector />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <DeviceTypeSelector />
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <TimeRangeComponent />
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: '20px' }} variant="middle" />

          <Grid item container>
            <Grid item xs={12}>
              <HealthDashboardComponent />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
