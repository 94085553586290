//  import { Autocomplete, Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
//  import { makeStyles } from '@mui/styles';
//  import { useSearchParams } from 'react-router-dom';
//  import { useCallback, useContext } from 'react';
//  import { UserContext } from '../../../context/UserContext';
//  import { toCamelizeWords } from "../../../utils/formatString";
//  import { timeRangeOptions } from "./timeRangeOptions";
//  import { useHealthContext } from './userContextHealth';
//  import { networkHealthCpuUsage } from '../../../API/NetworkDevices/health';



// // //-----------------------------------------


//  const useStyles = makeStyles({
//      root: {
//          "& .MuiSvgIcon-root": {
//              fontSize: 17,
//          },
//          "& .MuiFormControlLabel-label": {
//              fontSize: "1rem",
//              marginLeft: -6,
//          }
//      },
//      radioGroup: {
//          display: 'flex',
//          flexDirection: 'row',
//          gap: 3,
//          padding: 6,
//      },
//      overlayCard: {
//          width: '260px',
//          height: 'auto',
//          opacity: 1,
//      },
//      buttonContainer: {
//          display: 'flex',
//          justifyContent: 'center',
//          alignItems: 'center',
//          padding: '10px',
//          flexDirection: 'column',
//      },
//      popover: {
//          pointerEvents: 'none',
//      },
//      popoverContent: {
//          pointerEvents: 'auto',
//          zIndex: 9999,
//      },
//      singleButtonStyle: {
//          color: 'black',
//          fontWeight: 600,
//          marginBottom: '8px',
//      },
//      buttonGrpStyle: {
//          justifyContent: 'flex-start',
//         flexWrap: 'wrap',
//         padding: '2px',
//     },
//     buttonHeaderText: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         paddingLeft: 7,
//     },
//     oneGrpContainer: {
//         padding: '5px',
//         border: '1px solid',
//         borderColor: '#eeeeee',
//         borderRadius: 5
//     }
// });


// //--------------------------------------------------
// export default function TimeRangeComponent() {
//     const classes = useStyles();
//     const { healthDashDispatch, healthDashState, getStartEnd } = useHealthContext();
//     const [searchParams] = useSearchParams();
//     const locationUuid = searchParams.get('locationUuid');
//     const identifier = searchParams.get('identifier');
//     const { customerId } = useContext(UserContext);

//     const handleChange = useCallback((event) => {
//         healthDashDispatch({ type: 'SET_TIME_RANGE', payload: event.target.value });
//     });

//     const handleCustomChange = (event, value) => {
//         healthDashDispatch({ type: 'SET_TIME_RANGE', payload: value?.id });
//     };

//     const handleClick = (rangeId) => {
//         const selectedOption = timeRangeOptions.find((i) => i.id === rangeId);
//         const startEndData = getStartEnd();
//         const calculatedValue = selectedOption.calculatevalue();
//         const payload = {
//             device_type: identifier,
//             start: calculatedValue.start/1000,
//             end: calculatedValue.end/1000,
//             step: startEndData.step,
//         };

//         networkHealthCpuUsage(customerId, payload);
        
//     }

//     // ----------------------------------
  
//     return (

//         <>
//             <Box>
//                 <FormControl>
//                     <RadioGroup
//                         row
//                         variant="standard"
//                         onChange={handleChange}
//                         value={healthDashState.timeRange}
//                     >
                
//                         {timeRangeOptions?.slice(0, 7).map((range) => (
                            
//                             <FormControlLabel
//                                 key={range.id}
//                                 value={range.id}
//                                 label={range.id}
//                                 onClick={()=>handleClick(range.id)}
//                                 className={classes.root}
//                                 control={<Radio size="small" className={classes.root} />}
//                             />
//                         ))}

//                         {/* <Autocomplete
//                             size="small"
//                             handleHomeEndKeys
//                             sx={{ width: 230 }}
//                             onChange={handleCustomChange}
//                             value={timeRangeOptions?.slice(7, 36).find((i) => i.id === healthDashState?.timeRange) || null}
//                             options={timeRangeOptions?.slice(7, 36)}
//                             isOptionEqualToValue={(option, value) => option?.label === value?.label}
//                             renderOption={(props, option) => {
//                                 return (
//                                     <li {...props} key={option.id}>
//                                         {toCamelizeWords(option.label)}
//                                     </li>
//                                 )
//                             }}
//                             renderInput={(params) =>
//                                 <TextField
//                                     {...params}
//                                     placeholder="Choose custom range"
//                                     label="custom range"
//                                     type="text"
//                                     InputLabelProps={{ shrink: true }}
//                                     autoComplete='new-password'
//                                     InputProps={{
//                                         ...params.InputProps,
//                                         autoComplete: 'new-password',
//                                     }}
//                                 />
//                             }
//                         /> */}
//                     </RadioGroup>
//                 </FormControl>
//             </Box>
//         </>
//     );
// }




import { Autocomplete, Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from "../../../utils/formatString";
import { timeRangeOptions } from "./timeRangeOptions";
import { useHealthContext } from './userContextHealth';
import { networkHealthCpuUsage } from '../../../API/NetworkDevices/health';

const useStyles = makeStyles({
    root: {
        "& .MuiSvgIcon-root": {
            fontSize: 17,
        },
        "& .MuiFormControlLabel-label": {
            fontSize: "1rem",
            marginLeft: -6,
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
        padding: 6,
    },
    overlayCard: {
        width: '260px',
        height: 'auto',
        opacity: 1,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        flexDirection: 'column',
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
        zIndex: 9999,
    },
    singleButtonStyle: {
        color: 'black',
        fontWeight: 600,
        marginBottom: '8px',
    },
    buttonGrpStyle: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '2px',
    },
    buttonHeaderText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 7,
    },
    oneGrpContainer: {
        padding: '5px',
        border: '1px solid',
        borderColor: '#eeeeee',
        borderRadius: 5
    }
});

export default function TimeRangeComponent() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { healthDashDispatch, healthDashState } = useHealthContext();
    const [searchParams] = useSearchParams();
    const locationUuid = searchParams.get('locationUuid');
    const identifier = searchParams.get('identifier');
    const { customerId } = useContext(UserContext);
    const [isLoading, setLoading] = useState(false);
    const [cpuUsageData, setCpuUsageData] = useState(null);
    // const [apiCallCounter, setApiCallCounter] = useState(0); // New state for tracking API calls
    const defaultTimeRange = '1hr';
    const deviceTypeName = location.state?.deviceTypeName;
    const rDvcController = location.state?.rDvcController;

    const handleChange = useCallback((event) => {
        healthDashDispatch({ type: 'SET_TIME_RANGE', payload: event.target.value });
    }, [healthDashDispatch]);

    const handleCustomChange = (event, value) => {
        healthDashDispatch({ type: 'SET_TIME_RANGE', payload: value?.id });
    };
    useEffect(() => {
         healthDashDispatch({ type: 'SET_TIME_RANGE', payload: defaultTimeRange });
    //    handleClick(defaultTimeRange);
    }, []);

    const handleClick = async (rangeId) => {
        setLoading(true);
        try {
            const selectedOption = timeRangeOptions.find((i) => i.id === rangeId);
            const calculatedValue = selectedOption.calculatevalue();
    
            const payload = {
                device_type: identifier,
                start: calculatedValue.start / 1000,
                end: calculatedValue.end / 1000,
            };
    
            const data = await networkHealthCpuUsage(customerId, payload);
             setCpuUsageData(data); // Set the data here
    
            
    
            // setApiCallCounter(prevCounter => prevCounter + 1); // Increment the API call counter
    
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
      
        if (cpuUsageData  ) {
            navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&identifier=${healthDashState.selectedDevice?.r_dvc_unique_identifier}`, {
                state: {
                    cpuUsageData,
                    deviceTypeName,
                    rDvcController,
                    
                },
            });
        }
    }, [cpuUsageData]);
    

    return (
        <Box>
            <FormControl>
                <RadioGroup
                    row
                    variant="standard"
                    onChange={handleChange}
                    value={healthDashState.timeRange}
                >
                    {timeRangeOptions?.slice(0, 5).map((range) => (
                        <FormControlLabel
                            key={range.id}
                            value={range.id}
                            label={range.id}
                            onClick={() => handleClick(range.id)}
                            className={classes.root}
                            control={<Radio size="small" className={classes.root} />}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}


