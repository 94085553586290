

// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const SDwanChart = ({ seriesData, xAxisData }) => {
    
//     const reversedXAxisData = [...xAxisData].reverse();
//     const reversedSeriesData = seriesData.map(series => ({
//         ...series,
//         data: [...series.data].reverse(),
//     }));

//     const yAxisFormatter = (value, seriesName) => {
//         switch (seriesName) {
           
//             case 'Packet Loss':
//                 return `${value.toFixed(2)} %`; 
//             case 'Latency':
//                 return `${value.toFixed(2)} ms`;
//             case 'Jitter':
//                 return `${value.toFixed(2)} ms`; 
//             default:
//                 return `${value}`;
//         }
//     };

//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 350,
//             toolbar: { show: false },
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 2,
//         },
//         xaxis: {
//             categories: reversedXAxisData,
//             title: {
//                 text: 'Time',
//             },
//         },
//         yaxis: {
//             title: {
//                 text: 'Metrics Value',
//             },
//             labels: {
//                 offsetX: 0,
//                 offsetY: 10,
//                 formatter: (value, index) => {
//                     const seriesName = reversedSeriesData[index % reversedSeriesData.length]?.name; 
//                     return yAxisFormatter(value, seriesName);
//                 },
//             },
//         },
//         tooltip: {
//             shared: true,
//             intersect: false,
//             y: {
//                 formatter: (value, { seriesName }) => {
//                     if (value === null || value === undefined) {
//                         return '0 ms'; // Handle cases where value might be null or undefined
//                     }
//                     return yAxisFormatter(value, seriesName);
//                 },
//             },
//         },
//         legend: {
//             position: 'bottom',
//             horizontalAlign: 'center',
//         },
//         colors: ['#1E90FF', '#FF6347', '#32CD32', '#FFA500'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shade: 'light',
//                 type: 'vertical',
//                 shadeIntensity: 0.5,
//                 gradientToColors: ['#00BFFF', '#FF4500', '#3CB371', '#FFD700'],
//                 inverseColors: false,
//                 opacityFrom: 0.6,
//                 opacityTo: 0.2,
//             },
//         },
//         dataLabels: {
//             enabled: false,
//         },
//     };

//     return (
//         <ReactApexChart
//             options={chartOptions}
//             series={reversedSeriesData}
//             type="area"
//             height={290}
//         />
//     );
// };

// export default SDwanChart;

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SDwanChart = ({ seriesData, xAxisData }) => {
    const yAxisFormatter = (value, seriesName) => {
        switch (seriesName) {
            case 'Packet Loss':
                return `${value.toFixed(2)} %`;
            case 'Latency':
                return `${value.toFixed(2)} ms`;
            case 'Jitter':
                return `${value.toFixed(2)} ms`;
            default:
                return `${value}`;
        }
    };

    const chartOptions = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: { show: false },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        xaxis: {
            type: 'category',  // Set type as category for custom labels
            categories: xAxisData, // Use the original xAxis data
            title: {
                text: 'Time',
            },
            labels: {
                rotate: -45, // Rotate if timestamps are long
                formatter: (value) => value, // Display exact value
            },
            // Reverse the x-axis display
            reversed: true,
        },
        yaxis: {
            title: {
                text: 'Metrics Value',
            },
            labels: {
                offsetX: 0,
                offsetY: 10,
                formatter: (value, index) => {
                    const seriesName = seriesData[index % seriesData.length]?.name;
                    return yAxisFormatter(value, seriesName);
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                formatter: (value) => value,  // Ensure tooltip shows the correct value
            },
            y: {
                formatter: (value, { seriesName }) => {
                    if (value === null || value === undefined) {
                        return '0 ms'; // Handle cases where value might be null or undefined
                    }
                    return yAxisFormatter(value, seriesName);
                },
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
        },
        colors: ['#1E90FF', '#FF6347', '#32CD32', '#FFA500'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.5,
                gradientToColors: ['#00BFFF', '#FF4500', '#3CB371', '#FFD700'],
                inverseColors: false,
                opacityFrom: 0.6,
                opacityTo: 0.2,
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <ReactApexChart
            options={chartOptions}
            series={seriesData}
            type="area"
            height={290}
        />
    );
};

export default SDwanChart;















