import { Grid } from "@mui/material";
import GroupedSummaryTable from './components/GroupedSummaryTable';
// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function GroupedSummaryComponent() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
          <GroupedSummaryTable />
      </Grid>
    </Grid>
  );
}
