import React ,{ useState }from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Wifi from './Wifi';
import WanTable from './WanTable';


const useStyles = makeStyles({
  tabs: {
    '& button': {
      border: '1px solid #eeeeee',
      borderRadius: '2px 2px 0px 0px',
      fontSize: '18px',
      padding: '15px',
    },
  },
});


const WifiTable = (props) => {
  const classes = useStyles();
  const { locationData, deviceTypes, setDeviceTypes, setVendorsType, vendorsType, isWanSelected } = props;
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }
  return (
    <>
      {/* {isWanSelected ? (
        <Wifi locationData={locationData} deviceTypes={deviceTypes} setDeviceTypes={setDeviceTypes} />
      ) : (
        <WanTable locationData={locationData} vendorsType={vendorsType} setVendorsType={setVendorsType} />
      )} */}
       <Grid item lg={12} sm={12} xs={12} md={12} xl={12} style={{padding:'26px'}}>
        {/* <SummaryTable locationData={locationData} /> */}
        <Card>
          <Tabs value={tabValue} onChange={handleChange} className={classes.tabs}>
            <Tab label=" WAN " />
            <Tab label=" LAN " />
            <Tab label=" WiFi " />

          </Tabs>
          <Divider />
          <TabPanel value={tabValue} index={0}>
            <WanTable locationData={locationData} vendorsType={vendorsType} setVendorsType={setVendorsType} />

          </TabPanel>
          <TabPanel value={tabValue} index={1}>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Wifi locationData={locationData} deviceTypes={deviceTypes} setDeviceTypes={setDeviceTypes} />
          </TabPanel>
        </Card>
      </Grid>
    </>
  );
};

export default WifiTable;


