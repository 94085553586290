import { createContext, useContext, useEffect, useReducer } from 'react';

const MapSettingContext = createContext();
const mapSettingReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_SHOW_LABEL':
            return {
                ...state,
                showLabel: !state.showLabel
            }
        case 'TOGGLE_NOT_CONNECTED':
            return {
                ...state,
                showNotConnectedNodes: !state.showNotConnectedNodes
            }
        case 'TOGGLE_SHOW_LEGEND':
            return {
                ...state,
                showLegend: !state.showLegend
            }
        case 'TOGGLE_COUNTER':
            return {
                ...state,
                showCounterIcon: !state.showCounterIcon
            }
        case 'TOGGLE_GLOBAL_SHOW_LOCATION_NAME':
            return {
                ...state,
                showGlobalLocationName: !state.showGlobalLocationName
            }
        case 'TOGGLE_GLOBAL_SHOW_LEGEND':
            return {
                ...state,
                showGlobalLegend: !state.showGlobalLegend
            }
        case 'TOGGLE_GLOBAL_COUNTER':
            return {
                ...state,
                showGlobalCounter: !state.showGlobalCounter
            }
        case 'SET_NODE_SELECTED':
            return {
                ...state,
                selectedNode: action.payload
            }      
        case 'TOGGLE_FULLSCREEN':
            return {
                ...state,
                isFullscreen: !state.isFullscreen
            }
        default:
            return state

    }
}
const MapSettingProvider = ({ children }) => {
    const initialState = {
        showLabel: true,
        showNotConnectedNodes: false,
        showLegend: true,
        showCounterIcon: false,
        showGlobalLocationName: false,
        showGlobalLegend: true,
        showGlobalCounter: false,
        selectedNode: "",
        isFullscreen: false
    }
    const [mapSettingState, mapSettingDispatch] = useReducer(mapSettingReducer, JSON.parse(localStorage.getItem("mapSettingState")) || initialState)
    
    useEffect(() => {
        localStorage.setItem("mapSettingState", JSON.stringify(mapSettingState));
    }, [mapSettingState]);
    
    return <MapSettingContext.Provider value={{ mapSettingState, mapSettingDispatch }}>
        {children}
    </MapSettingContext.Provider>
}
const useMapSettingContext = () => {
    return useContext(MapSettingContext)
}
export { MapSettingProvider, useMapSettingContext };
