import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Google as GoogleIcon, Microsoft as MicrosoftIcon, Watch } from '@mui/icons-material'; // Import the icons
import { Grid, Link, Stack, IconButton, InputAdornment, Typography, Button, Box, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Skeleton  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ButtonGroup from '@mui/material/ButtonGroup';

// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { logIN, loginOtp, loginSSO, registerSSO, verifyOtp } from '../../../API/UserAuth/login';
// import {loginOtp } from '../../../API/UserAuth/login';
import CustomerSelectionModal from './CustomerSelectionModal';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  // const { loginWithRedirect } = useAuth0();
  const { instance } = useMsal();
  const { enqueueSnackbar } = useSnackbar(); 
  const [showPassword, setShowPassword] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(true);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [PasswordVariant, setPasswordVariant] = useState('contained')
  const [OtpVariant, setOtpVariant] = useState('outlined')
  const [openModal, setOpenModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [timer, setTimer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [otpVerificationStarted, setOtpVerificationStarted] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpRequestLoading, setOtpRequestLoading] = useState(false); // New state
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
const [dialogMessage, setDialogMessage] = useState('');
const [userData, setUserData] = useState(null);

let auth_token =  '';


  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().when('isOtpMode', {
      is: false,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired(),
    }),
    otp: Yup.string().when('isOtpMode', {
      is: true,
      then: Yup.string()
        .required('OTP is required')
        // .length(4, 'OTP must be 4 digits'),
        .matches(/^\d{6}$/, 'OTP must be exactly 6 digits'),
      otherwise: Yup.string().notRequired(),
    }),
    isOtpMode: Yup.boolean(),
  });


  const defaultValues = {
    email: '',
    password: '',
    otp: '',
    remember: true,
    isOtpMode: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
    mode: 'onChange',
  });

  // const {
  //   handleSubmit,
  //   getValues,
  //   setValue,
  //   formState: { isSubmitting, errors },
  //   trigger,
  // } = methods;
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
    trigger,
    clearErrors
  } = methods;

  useEffect(() => {
    setValue('isOtpMode', isOtpMode);
  }, [isOtpMode, setValue]);

  useEffect(() => {
    const token = localStorage.getItem('AuthorizationToken');
    const customerId = localStorage.getItem('customerId');

    if (token && customerId) {
      navigate('/dashboard/dashboard', { replace: true });
    }
  }, [navigate]);


  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  
  const clearLoginData = () => {
    localStorage.removeItem('AuthorizationToken');
    localStorage.removeItem('customerId');
    localStorage.removeItem('logo');
    localStorage.removeItem('multiCustomerStatus');
  };



const handleSignUp = async (userData) => {
  
  clearLoginData();
  
  
  setLoading(true);

  
  const payload = {
    user_name: userData?.name,
    email: userData?.email,
    phone_no: userData?.phone_no,
  };

  // Close the dialog
  setOpenDialog(false);

  try {
    // Make the SSO registration call
    const response = await registerSSO(payload);
    
    // Check if the response has a success message
    const successMessage = response?.message;
    
    // Show a success notification if the message exists
    if (successMessage) {
      enqueueSnackbar(successMessage, { variant: 'success', autoHideDuration: 3000 });
    }
  } catch (error) {
    // Get the error message if available
    const errorMessage = error?.response?.data?.message;
    
    // Show an error notification if the error message exists
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000 });
    } else {
      // Show a default error message if no specific message exists
      enqueueSnackbar("An error occurred during registration. Please try again.", { variant: 'error', autoHideDuration: 3000 });
    }
    
    console.error("Error during SSO registration", error);
  } finally {
    // Set loading to false after the operation completes
    setLoading(false);
  }
};




  const toggleLoginMode = () => {
    setIsOtpMode((prevMode) => {
      const newMode = !prevMode;
      clearErrors();
      if (newMode) {
        setValue('password', '');
      } else {
        setValue('otp', '');
      }
      return newMode;
    });
  };



  const onSubmit = async (d) => {

    if (isOtpMode && otpSent) {
      const data = {
        email: d.email,
        otp: d.otp,
      };
      try {
        setLoading(true);
        const res = await verifyOtp(data);
        if (res && Object.prototype.hasOwnProperty.call(res, "authorization")) {
          setLoading(false);
          checkCustomerAccess(res)
        }
      }
      catch (error) {
        setLoading(false);
        setErrorMsg(true);
      }
    }
    else {
      const data = {
        email: d.email,
        password: d.password,
      };
      try {
        const res = await logIN(data);
        if (res && Object.prototype.hasOwnProperty.call(res, "authorization")) {
          checkCustomerAccess(res)
        }
      }
      catch (error) {
        setErrorMsg(true);
      }
    }
  };

  const checkCustomerAccess = (response) => {
    const customerList = response.customers.map(customer => ({
      name: customer.cust_name,
      id: customer.unique_id,
      logo: customer.logo
    }));

    if (customerList.length === 1) {

      navigate('/dashboard/dashboard', { replace: true });
    } else {
      setCustomers(customerList);
      setOpenModal(true);
    }
  };
  useEffect(() => {
    let interval;
    if (timeLeft !== null) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    }

    if (timeLeft === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const startTimer = (expTime) => {
    const expirationInSeconds = expTime * 60;
    setTimeLeft(expirationInSeconds);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  // const handleRequestOtp = async () => {
  //   const email = getValues('email');

  //   const isEmailValid = await trigger('email');

  //   if (isEmailValid) {
  //     setOtpLoading(true); // Start loading
  //     try {
  //       const res = await loginOtp(email);
  //       setIsOtpMode(true)
  //       setOtpSent(true);
  //       setValue('otp', '');
  //       setErrorMsg(false);
  //       if (res) {
  //         // console.log(res);
  //         startTimer(res.exp_time); 

  //       }
  //     }
  //     catch (error) {
  //       setIsOtpMode(true)
  //       setErrorMsg(true);
  //     }
  //   }
  //   else {
  //     setIsOtpMode(true)
  //     setErrorMsg(true);
  //   }
  // };

  // const handleRequestOtp = async () => {
  //   const email = getValues('email');
  //   const isEmailValid = await trigger('email');

  //   if (isEmailValid) {
  //     setOtpRequestLoading(true); // Start loading indicator
  //     try {
  //       const res = await loginOtp(email);
  //       setIsOtpMode(true);
  //       setOtpSent(true);
  //       setValue('otp', '');
  //       setErrorMsg(false);
  //       if (res && res.exp_time) {
  //         startTimer(res.exp_time); 
  //       }
  //     } catch (error) {
  //       setErrorMsg('Failed to send OTP. Please try again.');
  //     } finally {
  //       setOtpRequestLoading(false); 
  //     }
  //   } else {
  //     setErrorMsg('Invalid email address.');
  //   }
  // };

  const handleRequestOtp = async () => {
    const email = getValues('email');
    const isEmailValid = await trigger('email');

    if (!isEmailValid) {
      setErrorMsg('Invalid email address.');
      return;
    }

    setOtpRequestLoading(true);
    try {
      const res = await loginOtp(email);
      if (res && res.exp_time) {
        setIsOtpMode(true);
        setOtpSent(true);
        setValue('otp', '');
        setErrorMsg('');
        startTimer(res.exp_time);

      } else {
        setErrorMsg('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      setErrorMsg('Failed to send OTP. Please try again.');
    } finally {
      setOtpRequestLoading(false);
    }
  };

 


  
  const onGoogleSubmit = async (data) => {
    // console.log("Google SSO Data: ", data); 
    
    const payload = {
      access_token: data.access_token, // Pass the access token
      email: data.email,               // Include email
      name: data.name,                 // Include name
      phone_no: data.phone_no || "",   // Handle missing phone number gracefully
      provider: "Google",              // Specify the provider
    };
  
    // console.log("Payload before login: ", payload); // Log the payload
  
    try {
      const res = await loginSSO(payload); // Call the loginSSO API with the payload
  
      // console.log("Login Response: ", res); // Log the login response
  
      if (res && res.authorization) {
        // Proceed if authorization token is present
        checkCustomerAccess(res); // Process customer access if authorized
      } else {
        // If no authorization, show dialog and prompt for signup
        // console.log("No authorization token, prompting for signup.");
        setUserData({ name: data.name, email: data.email, phone_no: data.phone_no });
        setDialogMessage('User does not exist. Do you want to sign up?');
        setOpenDialog(true);
      }
    } catch (error) {
      // Log the actual error for better debugging
      // console.error("Error during Google SSO: ", error); 
      setDialogMessage('User does not exist. Do you want to try signing up?');
      setOpenDialog(true);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };
  
  
  
  

 
  const onMicrosoftSubmit = async (d) => {
   
    const payload = {
      access_token: d.access_token,
      email: d.email,
      name: d.name,
      phone_no: d.phone_no || "", 
      provider: "Microsoft"       
    };
  
    // console.log("Microsoft SSO Payload: ", payload); 
  
    try {
      // Call loginSSO with the payload
      const res = await loginSSO(payload);  
      // console.log("Microsoft SSO Response: ", res); // Log the response for debugging
  
      // Check if authorization exists in the response
      if (res?.authorization) {
        checkCustomerAccess(res); // Process customer access if authorized
      } else {
        // If no authorization, prompt for signup
        // console.log("No authorization token, prompting for signup.");
        setDialogMessage('User does not exist. Do you want to sign up?');
        setUserData({ name: d.name, email: d.email, phone_no: d.phone_no || "" });
        setOpenDialog(true);
      }
    } catch (error) {
      // Log the error for better debugging
      // console.error("Error during Microsoft SSO: ", error);
      setDialogMessage('User does not exist. Do you want to try signing up?');
      setUserData({ name: d.name, email: d.email, phone_no: d.phone_no || "" });
      setOpenDialog(true);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };
  
  
  

  

  const responseMessage = async (response) => {
    if (response) {
      const auth_token = response.access_token;
      setLoading(true); // Set loading to true when the process starts

      try {
        const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        });
        const data = await userInfo.json();

        const email = data.email;
        const name = data.name;

        if (email && name) {
          const peopleResponse = await fetch('https://people.googleapis.com/v1/people/me?personFields=phoneNumbers', {
            headers: {
              Authorization: `Bearer ${auth_token}`,
            },
          });
          const peopleData = await peopleResponse.json();
          const phoneNumber = peopleData.phoneNumbers ? peopleData.phoneNumbers[0].value : " ";

          const payload = {
            access_token: auth_token,
            email: email,
            name: name,
            phone_no: phoneNumber,
          };

          onGoogleSubmit(payload);
        } else {
          console.error('Email or Name is missing:', { email, name });
          setErrorMsg(true);
        }
      } catch (error) {
        console.error('Error fetching user info or phone number:', error);
        setErrorMsg(true);
      } finally {
        setLoading(false); // Set loading to false once the process is complete
      }
    } else {
      console.error('Google response did not contain a credential:', response);
      setErrorMsg(true);
    }
  };
  
  
  


  
  
  const errorMessage = (error) => {
    // console.log(error);
  };

  

  

  const handleMicrosoftLogin = async () => {
    setLoading(true); // Start loading
    try {
      const response = await instance.loginPopup({
        scopes: ["openid", "profile", "User.Read", "User.ReadBasic.All"],
      });
  
      const email = response.account.username;
      const name = response.account.name || "Microsoft User";
  
      const userResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
      });
  
      const userData = await userResponse.json();
      const phone_no = userData.businessPhones ? userData.businessPhones[0] : "No phone number available";
  
      const payload = {
        access_token: response.idToken,
        email,
        name,
        phone_no,
        provider: "Microsoft",
      };
  
      await onMicrosoftSubmit(payload);
    } catch (error) {
      console.error("Microsoft Login Error:", error);
      setErrorMsg(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  
  
  
  
  
  


  const handleButtonClick = (e) => {
    const { id } = e.currentTarget;
    setErrorMsg('');

    if (id === 'OTP' && !isOtpMode) {
      // Switch to OTP mode
      toggleLoginMode();
      setOtpVariant("contained");
      setPasswordVariant("outlined");
      setShowPasswordField(false);
      setIsOtpMode(true);
      setValue("password", "");
    } else if (id === 'Password' && isOtpMode) {
      // Switch to Password mode
      toggleLoginMode();
      setPasswordVariant("contained");
      setOtpVariant("outlined");
      setShowPasswordField(true);
      setIsOtpMode(false);
      setOtpSent(false);
      setValue("otp", "");
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: responseMessage,
    onError: errorMessage,
    scope : 'openid email profile',
  });

  const handleSubmitOtp = async () => {
    const email = getValues('email');
    const otp = getValues('otp');
    const isEmailValid = await trigger('email');
    const payload = { email, otp };

    if (isEmailValid && otp) {
      try {
        const res = await verifyOtp(payload);
        if (res) {
          // console.log(res);
        }
      } catch (error) {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(true);
    }
  };


  return (
   
    <Box
  sx={{
    width: '100%',
    maxWidth: { xs: 320, sm: 480, md: 600, lg: 700, xl: 900 },
    mx: 'auto',
    px: 2,
    py: 4,
  }}
>
  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    <ButtonGroup disableElevation aria-label="Disabled button group" fullWidth>
      <Button
        id="Password"
        variant={isOtpMode ? 'outlined' : 'contained'}
        onClick={handleButtonClick}
        fullWidth
        sx={{
          borderRadius: '8px 0px 0px 0px',
        }}
      >
        Login with Password
      </Button>
      <Button
        id="OTP"
        variant={isOtpMode ? 'contained' : 'outlined'}
        onClick={handleButtonClick}
        fullWidth
        sx={{
          borderRadius: '0px 8px 0px 0px',
        }}
      >
        Login with OTP
      </Button>
    </ButtonGroup>

    <Stack spacing={2} sx={{ mb: 3 }}>
      <Stack
        spacing={2}
        sx={{
          py: 2,
          mt: 2,
          border: '1px solid #add8e6',
          borderRadius: '0px 0px 8px 8px',
          pt: '24px',
          pr: '16px',
          pb: '16px',
          pl: '16px',
        }}
      >
        {!isOtpMode && errorMsg && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            {errorMsg}
          </Typography>
        )}

        {isOtpMode && errorMsg && !otpSent && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            Invalid Email Address
          </Typography>
        )}

        {isOtpMode && otpSent && errorMsg && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            Invalid OTP
          </Typography>
        )}

        <RHFTextField
          name="email"
          label="Email address *"
          placeholder="Enter your email address"
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />

        {showPasswordField && (
          <>
            <RHFTextField
              name="password"
              label="Password *"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(prev => !prev)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errorMsg && <Typography color="error">Invalid login attempt</Typography>}

            {/* Remember Me and Forgot Password Section */}
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <RHFCheckbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover" href="/forgot-password">
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </>
        )}

        {isOtpMode && otpSent && !showPasswordField && (
          <>
            <RHFTextField
              name="otp"
              label="Enter OTP *"
              placeholder="Enter OTP"
              error={!!errors.otp}
              helperText={errors.otp ? errors.otp.message : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleRequestOtp} edge="end">
                      <Iconify icon="eva:refresh-fill" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {timeLeft > 0 && (
              <Typography sx={{ mt: 1, color: timeLeft <= 10 ? 'red' : 'textSecondary' }}>
                OTP expires in: {formatTime(timeLeft)}
              </Typography>
            )}
          </>
        )}

        {isOtpMode && !otpSent && (
          <LoadingButton
            fullWidth
            size="large"
            onClick={handleRequestOtp}
            variant="contained"
            loading={otpRequestLoading} 
            sx={{ mt: 2 }}
          >
            Receive OTP
          </LoadingButton>
        )}
        {isOtpMode && otpSent && (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Verify OTP
          </LoadingButton>
        )}

        {/* Alternative Login Options */}
        <Grid container item display='flex' justifyContent='center' alignItems='center' mt={3}>
          <Typography>--- Or Login with ---</Typography>
        </Grid>

        <Button
          fullWidth
          size="large"
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{ mt: 2 }}
        >
          Login with Google
        </Button>

        <Button
          fullWidth
          size="large"
          variant="outlined"
          startIcon={<MicrosoftIcon />}
          onClick={handleMicrosoftLogin}
          sx={{ mt: 2 }}
        >
          Login with Microsoft
        </Button>
      </Stack>
    </Stack>
  </FormProvider>

  <CustomerSelectionModal
    open={openModal}
    onClose={() => setOpenModal(false)}
    customers={customers}
    onSelectCustomer={(customer) => {
      setOpenModal(false);
      navigate('/dashboard/dashboard', { replace: true });
    }}
  />
 {
  loading ? (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      zIndex: 9999,
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Add some transparency if desired
    }}
  >
    <CircularProgress />
  </Box>
  ) : (
    <Dialog open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>
        <span style={{ color: 'red' }}>Login Error</span> 
      </DialogTitle>
      <DialogContent>
        <p>{dialogMessage}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleSignUp(userData)} 
          color="primary"
          autoFocus
        >
          Sign Up
        </Button>
      </DialogActions>
    </Dialog>
  )
}

</Box>

  );
}
