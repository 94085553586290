import * as React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useEffect, useState ,useRef , useContext} from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';
import { UserContext } from '../../../context/UserContext';
import { getIgnoreRule } from '../../../API/CMDBOperations';

const newStyles = {
  line: {
    wordBreak: 'break-word',
    padding: '10px 2px',
    '&:hover': {
      background: 'yellow',
    },
  },
  diffContainer: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    overflowX: 'auto',
    display: 'block !important',
    '& pre': { whiteSpace: 'pre-wrap' },
  },
  diffRemoved: {
    overflowX: 'auto',
    maxWidth: 300,
  },
  diffAdded: {
    overflowX: 'auto',
    maxWidth: 300,
  },
};

export default function DiffDialog002(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { data, cmdbGroupSummaryEvent, prevDetails } = props;
  const [loading, setLoading] = useState(true);
  const [openIgnore, setOpenIgnore] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const queryRef = useRef(''); 
  const [ignoreRules, setIgnoreRules] = useState([]); 
 
  const decodedPrevConfig = atob(prevDetails?.prev_config || '');
  const decodedCurrConfig = atob(prevDetails?.curr_config || '');
  const decodedCmdbGroupSummaryEvent = atob(prevDetails?.r_grp_smry_diff_html || '');

  const downloadSrcTxtFile = async () => {
    const blob = new Blob([decodedPrevConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_PrevConfig.txt`);
  };

  const downloadTarTxtFile = async () => {
    const blob = new Blob([decodedCurrConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_CurrentConfig.txt`);
  };

  const handleLineNumberClick = (lineId, event) => {
    if (lineId) {
      setOpenIgnore(true);
    }
  };

  useEffect(() => {
    if (prevDetails?.prev_config || prevDetails?.curr_config) {
      setLoading(false);
    }
  }, [prevDetails]);

  const [decodedHtml, setDecodedHtml] = useState('');

  useEffect(() => {
    try {
      const encodedHtml = prevDetails?.r_grp_smry_diff_html;
      if (encodedHtml) {
        const decodedString = atob(encodedHtml);
        try {
          const uriDecodedString = decodeURIComponent(decodedString);
          setDecodedHtml(generateHtmlDiff(uriDecodedString));
        } catch (uriError) {
          console.warn("URI decoding not needed or failed:", uriError);
          setDecodedHtml(generateHtmlDiff(decodedString)); 
        }
      } else {
        console.warn("No encoded HTML string provided.");
      }
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
    }
  }, [prevDetails]);
  
  useEffect(() => {
    const fetchData = async () => {
      setRefreshLoading(true);
  
      const payload = {
        global_keyword: queryRef.current,
        filterModel: {
          dcir_is_global: {
            filterType: 'text',
            type: '=',
            filter: '0',
          },
        },
        sortModel: {},
        startRow:0,
        endRow:200,
      };
  
      try {
        const pageCount = 1;
        const rulesData = await getIgnoreRule(customerId, payload, pageCount);
  
        if (rulesData?.data?.total) {
          // setTotalItems(rulesData.data.total);
        }
  
        if (rulesData.total === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          setIgnoreRules(rulesData?.data?.data);
          // gridApi.setRowData(rulesData?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setRefreshLoading(false);
      }
    };
  
    fetchData();
  }, [customerId]); 

  const generateHtmlDiff = (diffText) => {
    let diffHtml = `
      <style type="text/css">
        .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
        .diff div:hover {background-color:#ffc;}
        .diff .control {background-color: #eaf2f5; color: #999999;}
        .diff .insert {background-color: #ddffdd; color: #000000;}
        .diff .delete {background-color: #ffdddd; color: #000000;}
      </style>
      <div class="diff">
    `;

    diffText.split('\n').forEach((line) => {
      if (line.startsWith('@@')) {
        diffHtml += `<div class="control">${line}</div>`;
      } else if (line.startsWith('+++') || line.startsWith('+')) {
        diffHtml += `<div class="insert">${line}</div>`;
      } else if (line.startsWith('---') || line.startsWith('-')) {
        diffHtml += `<div class="delete">${line}</div>`;
      } else {
        diffHtml += `<div>${line}</div>`;
      }
    });

    diffHtml += '</div>';
    return diffHtml;
  };
  const filterTextByContent = (text, contentToIgnore) => {
    const lines = text.split("\n");
    let result = [];
    let skipMode = false;
  
    lines.forEach(line => {
      // Check if we should skip lines based on LINES_BETWEEN rules
      const startRule = contentToIgnore.find(
        rule => rule.start_with && line.includes(rule.start_with)
      );
      const endRule = contentToIgnore.find(
        rule => rule.ends_with && line.includes(rule.ends_with)
      );
  
      if (startRule) {
        skipMode = true; // Start skipping lines
      }
  
      if (!skipMode) {
        // Apply LINE_CONTAIN rules
        if (!contentToIgnore.some(content => typeof content === "string" && line.includes(content))) {
          result.push(line); // Keep the line if no match is found
        }
      }
  
      if (endRule) {
        skipMode = false; // Stop skipping lines after hitting the end rule
      }
    });
  
    return result.join("\n");
  };
  
  const filteredIgnoreRules = ignoreRules.map(rule => {
    if (rule.dcir_rule_type === "LINE_CONTAIN") {
        return rule.dcir_contains;
    } else if (rule.dcir_rule_type === "LINES_BETWEEN") {
        return {
            start_with: rule.dcir_start_with,
            ends_with: rule.dcir_ends_with
        };
    }
    else if (rule.dcir_rule_type === "REGEX") {
      return rule.dcir_regex
  }
    return null; // For rules that do not match the conditions
}).filter(item => item !== null); // To remove any null values


  const maskIgnoredContent = (text, contentToIgnore) => {
    return text
      .split("\n") // Split text into lines
      .map((line) => {
        // Replace all occurrences of the ignored content in the line
        contentToIgnore.forEach((content) => {
          line = line.split(content).join("IGNORE"); // Replace content with "IGNORE"
        });
        return line;
      })
      .join("\n"); // Join lines back together
  };

  const filteredOldText = filterTextByContent(decodedPrevConfig, filteredIgnoreRules);
  const filteredNewText = filterTextByContent(decodedCurrConfig, filteredIgnoreRules);
  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ p: 2, width: '100%' }}>
        <SummaryDiff cmdbGroupSummaryEvent={decodedHtml} />

        <Grid container>
          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_PrevConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadSrcTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_CurrentConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadTarTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        <Box>
          {decodedPrevConfig || decodedCurrConfig ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              oldValue={filteredOldText}
              newValue={filteredNewText}
              styles={newStyles}
              leftTitle={'Previous configuration'}
              rightTitle={'Current configuration'}
              onLineNumberClick={handleLineNumberClick}
              splitView
            />
          ) : (
            ''
          )}
          {openIgnore && (
            <IgnoreLineDialog
              gridApi={gridApi}
              openDialog={openIgnore}
              handleCloses={() => setOpenIgnore(false)}
              data={data}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

// import * as React from 'react';
// import DownloadIcon from '@mui/icons-material/Download';
// import {
//   Box,
//   Grid,
//   IconButton,
//   LinearProgress,
//   Tooltip,
//   Typography
// } from '@mui/material';
// import { saveAs } from 'file-saver';
// import { useSnackbar } from 'notistack';
// import { useEffect, useState, useRef, useContext } from 'react';
// import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
// import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
// import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';
// import { UserContext } from '../../../context/UserContext';
// import { getIgnoreRule } from '../../../API/CMDBOperations';

// const newStyles = {
//   line: {
//     wordBreak: 'break-word',
//     padding: '10px 2px',
//     '&:hover': {
//       background: 'yellow',
//     },
//   },
//   diffContainer: {
//     borderStyle: 'solid',
//     borderColor: 'black',
//     borderWidth: '1px',
//     overflowX: 'auto',
//     display: 'block !important',
//     '& pre': { whiteSpace: 'pre-wrap' },
//   },
//   diffRemoved: {
//     overflowX: 'auto',
//     maxWidth: 300,
//   },
//   diffAdded: {
//     overflowX: 'auto',
//     maxWidth: 300,
//   },
// };

// // Utility function to decode Base64 during idle times
// const decodeBase64WithPromise = (base64String) => {
//   return new Promise((resolve) => {
//     if (!base64String) return resolve('');
//     requestIdleCallback(() => {
//       const decodedString = atob(base64String);
//       try {
//         const uriDecodedString = decodeURIComponent(decodedString);
//         resolve(uriDecodedString);
//       } catch {
//         resolve(decodedString);
//       }
//     });
//   });
// };

// export default function DiffDialog002(props) {
//   const { enqueueSnackbar } = useSnackbar();
//   const { customerId } = useContext(UserContext);
//   const { data, cmdbGroupSummaryEvent, prevDetails } = props;
//   const [loading, setLoading] = useState(true);
//   const [openIgnore, setOpenIgnore] = useState(false);
//   const [gridApi, setGridApi] = useState(null);
//   const [refreshLoading, setRefreshLoading] = useState(false);
//   const queryRef = useRef('');
//   const [ignoreRules, setIgnoreRules] = useState([]);
//   const [decodedPrevConfig, setDecodedPrevConfig] = useState('');
//   const [decodedCurrConfig, setDecodedCurrConfig] = useState('');
//   const [decodedHtml, setDecodedHtml] = useState('');

//   useEffect(() => {
//     const decodeConfigs = async () => {
//       const [prevConfig, currConfig, html] = await Promise.all([
//         decodeBase64WithPromise(prevDetails?.prev_config),
//         decodeBase64WithPromise(prevDetails?.curr_config),
//         decodeBase64WithPromise(prevDetails?.r_grp_smry_diff_html),
//       ]);

//       setDecodedPrevConfig(prevConfig);
//       setDecodedCurrConfig(currConfig);
//       setDecodedHtml(generateHtmlDiff(html));
//       setLoading(false);
//     };

//     decodeConfigs();
//   }, [prevDetails]);

//   const downloadSrcTxtFile = async () => {
//     const blob = new Blob([decodedPrevConfig], { type: 'text/plain;charset=utf-8' });
//     saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_PrevConfig.txt`);
//   };

//   const downloadTarTxtFile = async () => {
//     const blob = new Blob([decodedCurrConfig], { type: 'text/plain;charset=utf-8' });
//     saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_CurrentConfig.txt`);
//   };

//   const handleLineNumberClick = (lineId, event) => {
//     if (lineId) {
//       setOpenIgnore(true);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setRefreshLoading(true);

//       const payload = {
//         global_keyword: queryRef.current,
//         filterModel: {
//           dcir_is_global: {
//             filterType: 'text',
//             type: '=',
//             filter: '0',
//           },
//         },
//         sortModel: {},
//         startRow: 0,
//         endRow: 200,
//       };

//       try {
//         const pageCount = 1;
//         const rulesData = await getIgnoreRule(customerId, payload, pageCount);

//         if (rulesData.total === 0) {
//           gridApi.showNoRowsOverlay();
//         } else {
//           setIgnoreRules(rulesData?.data?.data);
//         }
//       } catch (error) {
//         console.error("Error fetching initial data:", error);
//       } finally {
//         setRefreshLoading(false);
//       }
//     };

//     fetchData();
//   }, [customerId]);

//   const generateHtmlDiff = (diffText) => {
//     let diffHtml = `
//       <style type="text/css">
//         .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
//         .diff div:hover {background-color:#ffc;}
//         .diff .control {background-color: #eaf2f5; color: #999999;}
//         .diff .insert {background-color: #ddffdd; color: #000000;}
//         .diff .delete {background-color: #ffdddd; color: #000000;}
//       </style>
//       <div class="diff">
//     `;

//     diffText.split('\n').forEach((line) => {
//       if (line.startsWith('@@')) {
//         diffHtml += `<div class="control">${line}</div>`;
//       } else if (line.startsWith('+++') || line.startsWith('+')) {
//         diffHtml += `<div class="insert">${line}</div>`;
//       } else if (line.startsWith('---') || line.startsWith('-')) {
//         diffHtml += `<div class="delete">${line}</div>`;
//       } else {
//         diffHtml += `<div>${line}</div>`;
//       }
//     });

//     diffHtml += '</div>';
//     return diffHtml;
//   };

//   const filterTextByContent = (text, contentToIgnore) => {
//     const lines = text.split("\n");
//     let result = [];
//     let skipMode = false;

//     lines.forEach(line => {
//       const startRule = contentToIgnore.find(
//         rule => rule.start_with && line.includes(rule.start_with)
//       );
//       const endRule = contentToIgnore.find(
//         rule => rule.ends_with && line.includes(rule.ends_with)
//       );

//       if (startRule) {
//         skipMode = true;
//       }

//       if (!skipMode) {
//         if (!contentToIgnore.some(content => typeof content === "string" && line.includes(content))) {
//           result.push(line);
//         }
//       }

//       if (endRule) {
//         skipMode = false;
//       }
//     });

//     return result.join("\n");
//   };

//   const filteredIgnoreRules = ignoreRules.map(rule => {
//     if (rule.dcir_rule_type === "LINE_CONTAIN") {
//       return rule.dcir_contains;
//     } else if (rule.dcir_rule_type === "LINES_BETWEEN") {
//       return {
//         start_with: rule.dcir_start_with,
//         ends_with: rule.dcir_ends_with
//       };
//     } else if (rule.dcir_rule_type === "REGEX") {
//       return rule.dcir_regex;
//     }
//     return null;
//   }).filter(item => item !== null);

//   const filteredOldText = filterTextByContent(decodedPrevConfig, filteredIgnoreRules);
//   const filteredNewText = filterTextByContent(decodedCurrConfig, filteredIgnoreRules);

//   return (
//     <>
//       {loading && <LinearProgress />}
//       <Box sx={{ p: 2, width: '100%' }}>
//         <SummaryDiff cmdbGroupSummaryEvent={decodedHtml} />

//         <Grid container>
//           <Grid item lg={6} md={6} sm={6}>
//             <Typography sx={{ pt: 1, pb: 1 }}>
//               File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_PrevConfig`}
//               <Tooltip title="Download file">
//                 <IconButton onClick={downloadSrcTxtFile} size="small">
//                   <DownloadIcon fontSize="inherit" />
//                 </IconButton>
//               </Tooltip>
//             </Typography>
//           </Grid>

//           <Grid item lg={6} md={6} sm={6}>
//             <Typography sx={{ pt: 1, pb: 1 }}>
//               File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_CurrentConfig`}
//               <Tooltip title="Download file">
//                 <IconButton onClick={downloadTarTxtFile} size="small">
//                   <DownloadIcon fontSize="inherit" />
//                 </IconButton>
//               </Tooltip>
//             </Typography>
//           </Grid>
//         </Grid>

//         <Box>
//           {decodedPrevConfig || decodedCurrConfig ? (
//             <ReactDiffViewer
//               oldValue={filteredOldText}
//               newValue={filteredNewText}
//               splitView={true}
//               compareMethod={DiffMethod.WORDS}
//               hideLineNumbers={false}
//               extraLinesSurroundingDiff={1}
//               styles={newStyles}
//               onLineNumberClick={handleLineNumberClick}
//             />
//           ) : (
//             <Typography>No data to display.</Typography>
//           )}
//         </Box>
//       </Box>

//       {openIgnore && (
//         <IgnoreLineDialog
//           open={openIgnore}
//           handleClose={() => setOpenIgnore(false)}
//         />
//       )}
//     </>
//   );
// }
