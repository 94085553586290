import { IconButton, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
});

export default function SchedulerOptionSelector(props) {
  const classes = useStyles();
  const { schedule, placeholder } = props;
  //   const [permissionDialog, setPermissionDialog] = useState(false);
  const schedulerOptions = [
    {
      label: 'Daily once (Midnight)',
      value: '0 0 * * *',
    },
    {
      label: 'Every 12 hours',
      value: '0 */12 * * *',
    },
    { label: 'Every 6 Hour', value: '0 */6 * * *' },
  ];
  return (
    <>
      <Autocomplete
        size="small"
        id="location-select-demo"
        sx={{ width: '500px' }}
        className={classes.root}
        options={schedulerOptions}
        value={schedulerOptions.find((item) => item.value)}
        defaultValue={schedulerOptions.find((item) => item.value === schedule.r_scdl_value) }
        onChange={props.selectScheduler && ((e, option) => props.selectScheduler(option, schedule))}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => <li {...props}>{option.label}</li>}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            className={classes.field}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {schedule && (
                    <Tooltip title={'Read permissions'}>
                      <IconButton className={classes.icon}>{/* <Visibility /> */}</IconButton>
                    </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ), // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
}
