import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { DeviceLifeCycleComponent } from '../../components/DeviceLifeCycleComponent/DeviceLifeCycleComponent';
import Page from '../../components/Page';
// ----------------------------------------------------------------------

export default function DeviceLifeCycle() {
  return (
    <Page title="Inventory">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/inventory">
              System configuration
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/network-device">
              EOS/EOL Database
            </Typography>
          </Breadcrumbs>
        </div>
        <DeviceLifeCycleComponent />
      </Container>
    </Page>
  );
}
