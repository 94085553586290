import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
}));

const CopytoClipboard = (props) => {
  const { text } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onCopyText = (e, text) => {
    enqueueSnackbar(('Copied to clipboard'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  return (
    <>
      <CopyToClipboard
        text={text}
        options={{ format: 'text/plain' }}
        onCopy={e => onCopyText(e, text)}
      >
        <Tooltip title={'Click to copy'}>
          <IconButton size="small"  className={classes.icon}>
            <ContentCopy fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

export default CopytoClipboard;

