import { ContentCopy, Download, Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getDeviceLifeCycle } from '../../../API/DeviceTypes/index';
import { getUser } from '../../../API/User/User';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { toCamelizeWords } from '../../../utils/formatString';
import ArrayToCSVdownload from '../../ArrayToCSVdownload';

// -----------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 25,
    top: 22,
  },
}));
// ----------------------------------------------------------------------

const DeviceLifeCycleTable = (props) => {
  const classes = useStyles();
  const { locationData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [devDownload, setDevDownload] = useState(false);

  // ---------------------------------------------------------------

// -------------------------------------------------------------------------------------

const isTablet = useMediaQuery('(max-width: 1020px)');
const isDesktop = useMediaQuery('(min-width: 1440px)');
const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

const getFontSize = () => {
 if (isLargeDesktop) {
   return '20px';
 } 
 if (isDesktop) {
   return '16px';
 } 
 if (isTablet) {
   return '14px';
 } 
 return '12px';
 }

const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

// ----------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (navigator.onLine) {
      getUser().then((res) => {
        setUserCustomer(res.customer.name);
        if (window.localStorage.developer_mode === '1') {
          setDevDownload(true);
        }
      });
    }
  }, []);

  const isUndefined = (value, def = '') => {
    return value === undefined || value == null || value.length <= 0 || value === '' ? def : value;
  };

  const onBtnExport = () => {
    const payload = {
      endRow: totalItems,
      startRow: 0,
      location_uuids: locationData?.map((i) => i._uid),
      global_keyword: query,
    };
    setDownloadLoading(true);
    getDeviceLifeCycle(customerId, payload)
      .then((res) => {
        const result = res.data.map((item) => {
          delete item.r_dlc_hash;
          delete item.model;
          return {
            Vendor: isUndefined(item.r_dlc_vendor),
            Name: isUndefined(item.r_dlc_name),
            Summary: isUndefined(item.r_dlc_summary),
            'End of service': isUndefined(item.r_dlc_eoservice),
            'End of life': isUndefined(item.r_dlc_eolife),
            'End of support': isUndefined(item.r_dlc_eosupport),
          };
        });
        const CSV_SEPARATER = ',';
        ArrayToCSVdownload({
          data: result,
          headers: Object.keys(result[0]),
          delimiter: CSV_SEPARATER,
          filename: `DeviceLifeCycle-${userCustomer}-${new Date().toUTCString()}.csv`,
        });
        setDownloadLoading(false);
        enqueueSnackbar('Downloaded successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setTimeout(() => { }, 500);
      })
      .catch((res) => {
        setDownloadLoading(false);
        enqueueSnackbar(
          res.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData?.map((i) => i._uid),
      };
      gridApi.refreshInfiniteCache();
    }
  }, [locationData, gridApi]);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
        };
        delete payload.context;
        getDeviceLifeCycle(customerId, payload).then((deviceLifeCycle) => {
          let lastRow = -1;
          if (deviceLifeCycle.total <= _params.endRow) {
            lastRow = deviceLifeCycle.total;
          }
          setTotalItems(deviceLifeCycle.total);
          if (deviceLifeCycle.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(deviceLifeCycle.data, lastRow);
          setRefreshLoading(false);
        });
      },
    });
  }, [customerId, query]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 250,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnDefs = [
    {
      headerName: '#',
      colId: 'id',
      maxWidth: '90',
      // pinned: 'left',
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Vendor',
      colId: 'r_dlc_vendor',
      sortable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dlc_vendor) {
          return (
            <div className={classes.root}>
              {toCamelizeWords(params?.data?.r_dlc_vendor)}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dlc_vendor}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dlc_vendor)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return params.data?.r_dlc_vendor;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Name',
      colId: 'r_dlc_name',
      floatingFilter: true,
      sortable: false,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return (params.data?.r_dlc_name);
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Summary',
      colId: 'r_dlc_summary',
      floatingFilter: true,
      filter: true,
      sortable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dlc_summary;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'End of support',
      colId: 'r_dlc_eosupport',
      sortable: true,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dlc_eosupport;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'End of life',
      colId: 'r_dlc_eolife',
      sortable: true,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dlc_eolife;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'End of service',
      colId: 'r_dlc_eoservice',
      sortable: true,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dlc_eoservice;
      },
      cellStyle: responsiveCellStyle,
    },
  ];

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  // ----------------------------------------------------------------------
  return (
    <Card>
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px',
            }}
          >
            <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
              <Chip label={totalItems || 0} />
              {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
              <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                EOS/EOL database
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <TextField
                focused
                autoFocus
                type="text"
                size="small"
                value={query}
                variant="outlined"
                name='new-password'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              {devDownload ? (
                <Box>
                  <Tooltip title="Download as CSV">
                    <IconButton color="primary" onClick={onBtnExport}>
                      {downloadLoading ? (
                        <CircularProgress size={27} sx={{ mr: '5px' }} />
                      ) : (
                        <Download sx={{ fontSize: '1.8rem' }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                ''
              )}
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        }
      />

      <Divider />

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData?.map((i) => i._uid),
          }}
        />
      </Box>
    </Card>
  );
};


DeviceLifeCycleTable.propTypes = {
  locationData: PropTypes.array,
};

export default DeviceLifeCycleTable;
