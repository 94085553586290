// @mui
import { Grid , Divider} from "@mui/material";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../actions/updateLocation';
import LocationSelect from "../NetworkHealthComponents/LocationSelect";
import ConfigChangeBackupTable from "./ConfigChangeBackupTable";
import CMDBCharts from "./CMDBCharts"


// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function ConfigBackupTableComponent() {
  const [deviceTypes, setDeviceTypes] = useState('');

  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.cmdbinventory.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'cmdbinventory', locationUuid: _locations }));
  };

  const selectDeviceType = async (_deviceTypes) => {
    setDeviceTypes(_deviceTypes);
  };

  return (
    <Grid container spacing={4}>
      
      <Grid item lg={12} sm={12} xs={12} md={12} xl={12} style={{ marginTop: '-64px' }}>
        {/* <hr style={{marginBottom:'30px'}}/> */}
        <LocationSelect selectLocation={selectLocation} locationData={locationData}/>
      </Grid>

      <Divider sx={{ marginTop: '15px' ,width:'97%', marginLeft: '36px'}} />

  
      <Grid container item spacing={3}>
        <CMDBCharts locationData={locationData} selectDeviceType={selectDeviceType} />
      </Grid>

       <Grid item xs={12} md={12} lg={12}>
          <ConfigChangeBackupTable locationData={locationData} deviceTypes={deviceTypes} setDeviceTypes={setDeviceTypes} />
      </Grid> 
    </Grid>
  );
}

