// import { Stack, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';
// import AgGridHealthDashboard from './AgGridHealthDashboard';
// import ArubaOsHealthDashboardComponent from './components/ArubaOs/ArubaOsHealthDashboardComponent';
// import FortinetHealthDashboardComponent from './components/Fortinet/FortinetHealthDashboardComponent';
// import SwitchHealthDashboardComponent from './components/Switch/SwitchHealthDashboardComponent';
// import WLCHealthDashboardComponent from './components/WLC/WLCHealthDashboardComponent';
// import WMMHealthDashboardComponent from './components/WMM/WMMHealthDashboardComponent';
// import WirelessHealthDashboardComponent from './components/Wireless/WirelessHealthDashboardComponent';
// import { useHealthContext } from './components/userContextHealth';

// //----------------------------------------------------

// const useStyles = makeStyles({
//     skeletonLoader: {
//         top: 0,
//         left: 0,
//         width: "100%",
//         height: 600,
//         display: 'flex',
//     },
//     noDataSelectedContainer: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         padding: '40px',
//         border: '2px solid',
//         borderColor: '#cfd8dc'
//     }
// });

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(0),
//     width: "100%",
//     height: 500,
// }));

// //----------------------------------------------------

// const HealthDashboardComponent = () => {

//     const classess = useStyles();
//     const { healthDashState } = useHealthContext();

//     if (!healthDashState.locationuuid) {
//         return (
//             <ChartWrapperStyle dir="ltr">
//                 <Stack direction="column" className={classess.noDataSelectedContainer}>
//                     <Typography variant="h3" display="block" gutterBottom
//                     sx={{
//                         fontSize: {
//                           xs: '22px',
//                           sm: '24px',
//                           md: '26px',
//                           lg: '28px',
//                           xl: '30px',
//                           xxl: '32px',
//                         },
//                       }}>
//                         Please Select Location
//                     </Typography>
//                     <Typography variant="subtitle1" display="block" gutterBottom
//                     sx={{
//                         fontSize: {
//                           xs: '12px',
//                           sm: '14px',
//                           md: '16px',
//                           lg: '18px',
//                           xl: '20px',
//                           xxl: '22px',
//                         },
//                       }}>
//                         to view dashboard
//                     </Typography>
//                 </Stack>
//             </ChartWrapperStyle>
//         )
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "FIREWALL") {
//         return <FortinetHealthDashboardComponent />
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "ACCESS POINT") {
//         return <ArubaOsHealthDashboardComponent />
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "SWITCH") {
//         return <SwitchHealthDashboardComponent />
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_MOBILITY_MASTER") {
//         return <WMMHealthDashboardComponent />
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER") {
//         return <WLCHealthDashboardComponent />
//     }
//     if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS") {
//         return <WirelessHealthDashboardComponent />
//     }
//     return (
//         <AgGridHealthDashboard />
//     );
// };

// export default HealthDashboardComponent;
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import AgGridHealthDashboard from './AgGridHealthDashboard';
// import ArubaOsHealthDashboardComponent from './components/ArubaOs/ArubaOsHealthDashboardComponent';
// import FortinetHealthDashboardComponent from './components/Fortinet/FortinetHealthDashboardComponent';
// import SwitchHealthDashboardComponent from './components/Switch/SwitchHealthDashboardComponent';
// import WLCHealthDashboardComponent from './components/WLC/WLCHealthDashboardComponent';
// import WMMHealthDashboardComponent from './components/WMM/WMMHealthDashboardComponent';
// import WirelessHealthDashboardComponent from './components/Wireless/WirelessHealthDashboardComponent';
// import { useHealthContext } from './components/userContextHealth';
import MainHealthDashboard from './components/MainHealthDashboard/MainHealthDashboard';
import { useHealthContext } from './components/userContextHealth';

//----------------------------------------------------

const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        height: 600,
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    width: "100%",
    height: 500,
}));

//----------------------------------------------------

const HealthDashboardComponent = () => {

    const classess = useStyles();
    const { healthDashState } = useHealthContext();
    if (!healthDashState.locationuuid) {

        return (
            <ChartWrapperStyle dir="ltr">
                <Stack direction="column" className={classess.noDataSelectedContainer}>
                    <Typography variant="h3" display="block" gutterBottom
                    sx={{
                        fontSize: {
                          xs: '22px',
                          sm: '24px',
                          md: '26px',
                          lg: '28px',
                          xl: '30px',
                          xxl: '32px',
                        },
                      }}>
                        Please Select Location
                    </Typography>
                    <Typography variant="subtitle1" display="block" gutterBottom
                    sx={{
                        fontSize: {
                          xs: '12px',
                          sm: '14px',
                          md: '16px',
                          lg: '18px',
                          xl: '20px',
                          xxl: '22px',
                        },
                      }}>
                        to view dashboard
                    </Typography>
                </Stack>
            </ChartWrapperStyle>
        )
    }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "FIREWALL") {
    //     return <FortinetHealthDashboardComponent />
    // }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "ACCESS_POINT") {
    //     return <ArubaOsHealthDashboardComponent />
    // }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "SWITCH") {
    //     return <SwitchHealthDashboardComponent />
    // }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_MOBILITY_MASTER") {
    //     return <WMMHealthDashboardComponent />
    // }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER") {
    //     return <WLCHealthDashboardComponent />
    // }
    // if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS") {
    //     return <WirelessHealthDashboardComponent />
    // }
    // return (
    //     <AgGridHealthDashboard />
    // );

     return      <MainHealthDashboard />
};

export default HealthDashboardComponent;
