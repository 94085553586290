
// @mui
import { Grid, Button, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';

export default function LogoOnlyLayout() {

  return (
    <Page title="Logout">
      <Container>
        <Grid container spacing={3} alignItems="center" justifyContent="center" style={{  marginTop: '25%' }}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" gutterBottom>
              You are logged out!
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <Logo sx={{ width: '200px', height: '100px' }} />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button variant="contained" sx={{ width: '200px' }} href="/">
              Login
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
