import { CircularProgress ,TextField} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useContext, useEffect, useState } from 'react';
import { getLocationsWithoutPagination } from '../../../API/Locations/showLocations';
import { UserContext } from '../../../context/UserContext';

export default function OndemandLocationSelector(props) {
  const { zoneValue, setZoneValue, setLocationValue, locationValue, setOnDemandSubnetOptions, zoneSelectionSource } = props;
  const { customerId } = useContext(UserContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [page , setPage]=useState(0)
  const filter = createFilterOptions();


  const loadLoactionOptions = async () => {
    setLocationLoading(true);
    try {
      const _locationOption = await getLocationsWithoutPagination(customerId ,page);
      setPage(_locationOption.current_page -1)
      setLocations(_locationOption.data);
      setLocationOptions(_locationOption.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLocationLoading(false);
      setTimeout(() => {
        if (zoneValue) {
          _zoneValueChanged(zoneValue);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    loadLoactionOptions();
    // if (props.locationValue?.data?.uuid) {
    //   setLocationLoading(true);
    //   const _addLocations = getLocationsWithoutPagination(customerId);
    //   setLocationOptions(_addLocations).filter((item) => item.zone._uid === props.locationValue?.data._uid);
    //   setTimeout(() => {
    //     setLocationLoading(false);
    //   }, 1000);
    // }
  }, []);

  const _zoneValueChanged = (zone, locationValue) => {
    const newLocations = zone ? locations.filter((loc) => loc.zone?._uid === zone._uid) : locations;
    setLocationOptions(newLocations);
    if (zoneSelectionSource !== 'location' ) {
      setOnDemandSubnetOptions(newLocations.reduce((current, loc) => [...current, ...loc.subnets], []));
      setLocationValue(newLocations);
    }
  };

  useEffect(() => {
    if (zoneValue) {
      _zoneValueChanged(zoneValue, locationValue);

    } else {
      setLocationOptions(locations);
    //   setLocationValue([]);
    //   setDcAgentSubnetOptions([]);
    }
  }, [zoneValue]);

  const _selectLocations = (_locations) => {
    if (_locations?.length === 1 && zoneValue === null) {
      setZoneValue(_locations[0].zone);
      // setTimeout(() => {
      //   setLocationValue(_locations);
      // }, 1000);
    }
    setLocationValue(_locations);
    setOnDemandSubnetOptions(_locations.reduce((current, loc) => [...current, ...loc.subnets], []));
  };

  return (
    <Autocomplete
      size="small"
      value={locationValue}
      fullWidth
      limitTags={4}
      multiple
      onChange={(event, newValue) => {
        // if (typeof newValue === 'string') {
        //   setLocationValue({
        //     r_location_name: newValue,
        //   });
        // } else if (newValue && newValue.inputValue) {
        //   // Create a new value from the user input
        //   _addLocation(newValue.inputValue).then((res) => {
        //     setLocationValue({
        //       _uid: res._uid,
        //       r_location_name: res.r_location_name,
        //     });
        //   });
        // } else {
        _selectLocations(newValue);
        // }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // const { inputValue } = params;
        // // Suggest the creation of a new value
        // const isExisting = locationOptions?.some(
        //   (option) => inputValue.toLocaleLowerCase() === option.r_location_name.toLocaleLowerCase()
        // );
        // if (inputValue !== '' && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     // r_location_name: `Create "${inputValue}"`,
        //   });
        // }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={locationOptions}
      loading={locationLoading}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.r_location_name;
      }}
      // renderOption={(props , option)=>{<li {...props}>{option.r_location_name}</li>}}
      renderOption={(props, option) => {
        if (option?.zone?.r_zone_name) {
          return <li {...props}>{option.r_location_name}</li>;
        }
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Location"
          label="Location"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
