import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { forwardRef, useState ,useContext} from 'react';
import { toCamelizeWords } from '../../../utils/formatString';
import DiffDialog001 from './DiffDialog001';
import DiffDialog002 from './DiffDialog002';
import {getdiffCurrPrev} from '../../../API/S3/ConfigEvents'
import {UserContext} from '../../../context/UserContext'
//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {
    width: '50%',
    paddingLeft: '10px',
  },
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  fileNameBox: {
    width: '100%',
    height: '30px',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  tabs: {
    "& button": {
      border: "1px solid #eeeeee",
      borderRadius: '10px 10px 0px 0px',
    },
  },
});

//----------------------------------------------------------------------

export default function DiffDialogwithTabs(props) {
  const classes = useStyles();
  const { openDialog, handleClose, goldenConfigPath, previousConfigPath, currentConfigPath, data, cmdbGroupSummaryEvent, backupDetails } = props;
  const [tabValue, setTabValue] = useState(0);
  const [prevDetails,setPrevDetails] = useState(null);
  const { customerId } = useContext(UserContext);

  const fetchPreviousConfigData = () => {
    getdiffCurrPrev(customerId, data?.cmdb_backup_id).then((res) => {
      setPrevDetails(res);
    }).catch((error) => {
      console.error("Error fetching backup details:", error);
    });
     
  };

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      fetchPreviousConfigData();
    }
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }
  const deviceTypeName = toCamelizeWords(data?.l_dvc_typ_name) || 'Unknown Device';
  const ipAddress = data?.r_dvc_ip_address || 'Unknown IP';
  const serialNumber = data?.r_dvc_serial_number || 'Unknown Serial Number';

  const deviceInfo = `${deviceTypeName} - (${ipAddress} - ${serialNumber})`;

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        maxWidth="xxl"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle sx={{ padding: '0px' }}>
          <Stack direction="row" className={classes.stackContainer}>
            <Box sx={{ paddingLeft: '10px' }}>
              <Typography variant='h6' gutterBottom>
                Configuration diff view
              </Typography>

              <Typography gutterBottom>
              {deviceInfo}
              </Typography>
            </Box>

            <IconButton onClick={handleClose}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>

          <Tabs value={tabValue} onChange={handleChange} className={classes.tabs}>
            <Tab label="Diff with Golden configuration" />
            <Tab label="Diff with Previous configuration" />
          </Tabs>
        </DialogTitle>

        <Divider sx={{ width: '100%' }} />

        <DialogContent sx={{ padding: '0px' }}>
          <TabPanel value={tabValue} index={0}>
            <DiffDialog001
              sourcePath={goldenConfigPath}
              targetPath={currentConfigPath}
              data={data}
              backupDetails={backupDetails}
              cmdbGroupSummaryEvent={data?.r_golden_file_difference} />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <DiffDialog002
              previousConfigPath={previousConfigPath}
              targetPath={currentConfigPath}
              data={data}
              prevDetails={prevDetails}
              cmdbGroupSummaryEvent={cmdbGroupSummaryEvent?.r_grp_smry_diff_html} 
            />
          </TabPanel>
        </DialogContent>

        <DialogActions style={{ height: '3vh' }} />
      </Dialog>
    </>
  );
}
