export const labelStyle = {
    text: "8px",
    textDecoration: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    bg: "#f5f5f554",
    color: "#756f6f",
    fontSize: "8px",
    textAlign:"center"
    // maxWidth:"60px"
}