// import * as Yup from 'yup';
import { useState } from 'react';

// material
import { Box, Grid, Paper, Typography } from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import EmailTemplateConfig from './EmailTemplateConfig';
import SmtpConfigForm from './smtpconfigform';
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// tablabel1 strarts here

// don't touch below code

export default function SmtpTabCard1() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Paper elevation={15}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="SMTP Configuration" />
            <Tab label="Messaging Templetes" />
            <Tab label="Email Template Configuration" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <SmtpConfigForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SmtpConfigForm />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EmailTemplateConfig />
          </TabPanel>
        </Paper>
      </Grid>
    </>
  );
}
