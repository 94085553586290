import { TestbedInstance, instance as modem } from '../modem';
import Leaflet from 'leaflet';
import axios from 'axios';



export const getInventory = async (id, data, controller) => {

  const newPro = modem
    .post(`${id}/inventory/network-types`, data, {
      // signal: controller.signal,
    })
    .then((response) => {
      return response?.data?.data;

    });
  return newPro;
};

// export const addInventory = async (id, data) => {
//   const newPromise = modem.post(`${id}/inventory/network-types`, data).then((response) => {
//     return response.data.data;
//   });
//   return newPromise;
// };

// export const deleteInventory = async (id, unknownId, data) => {
//   const newPromise = modem.delete(`${id}/inventory/network-types/${unknownId}`, data).then((response) => {
//     return response.data.data;
//   });
//   return newPromise;
// };

export const getDeviceByVendor = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/inventory/network-types/count-by-vendor`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

let getNetworkStatRequest = null;

export const getNetworkStat = async (id, data) => {
  if (getNetworkStatRequest) {
    return getNetworkStatRequest;
  }
  getNetworkStatRequest = modem.post(`${id}/location-statistics/advanced`, data).then((response) => {
    getNetworkStatRequest = null;
    return response.data.data;
  }).catch((e) => {
    getNetworkStatRequest = null;
    throw e
  })

  return getNetworkStatRequest;
};

export const getDeviceByType = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/inventory/network-types/count-by-type`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getDownDeviceByType = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/inventory/network-types/count-by-locations`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const updateInventory = async (id, _uid, data) => {
  const newPromise = modem.post(`${id}/network-devices/${_uid}`, data).then((response) => {
    // return response.data.data;
    return response;
  });
  return newPromise;
};
export const markAsInventory = async (id, _uid, data) => {
  const newPromise = modem
    // .post(`${id}/network-devices/:network_device_uuid`, data)
    .post(`${id}/network-devices/${_uid}/v2`, data)
    .then((response) => {
      // return response.data.data;
      return response;
    })
    .catch((response) => {
      // return response.data.data;
      return response;
    });
  return newPromise;
};
export const getDevicesCount = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/statistics/network-devices`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getEventsNetworkUpDown = async (id, data) => {
  const newPro = modem.post(`${id}/events/network-up-down-v2`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getConfigChangeEvents = async (id, data) => {
  const newPro = modem.post(`${id}/network-devices/config-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const goldenConfigChangeEvents = async (id, uuid, data) => {
  const newPro = modem.put(`${id}/network-devices/config-change-events/${uuid}/status`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const createNewDevice = async (id, data) => {
  const newPro = modem.post(`${id}/network-devices`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getGroupSummaryChangeEvents = async (customerId, data) => {
  const newPro = modem.post(`${customerId}/network-devices/group-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getGroupSummaryChangeEventMultiple = async (customerId, _uid, data) => {
  const newPro = modem.post(`${customerId}/network-devices/group-change-events/${_uid}`, data).then((response) => {

    return response.data.data;
  });
  return newPro;
};
export const setGroupSummaryChangeEvents = async (id, uuid, data) => {
  const newPro = modem.put(`${id}/network-devices/group-change-events/${uuid}/status`, data).then((response) => {
    return response.data.data;
  });
  
  return newPro;
};

export const deviceByTypeChart = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPro = modem.post(`${id}/inventory/statistics/count-by-type`, payload).then((response) => {
    return response.data;
  });
  return newPro;
};

export const deleteDevice = async (id, unknownId, data) => {
  const newPromise = modem.delete(`${id}/network-devices/${unknownId}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};


// function calculateExtremes(markers, setMainBounds) {

//   let bounds = {}

//   if (markers?.length === 0) {
//     bounds = Leaflet.latLngBounds([
//       Leaflet.latLng(23.63936, 68.14712),
//       Leaflet.latLng(28.20453, 97.34466)
//     ])
//   }
//   else {
//     let topmost = markers[0].position;
//     let bottommost = markers[0].position;
//     let leftmost = markers[0].position;
//     let rightmost = markers[0].position;

//     if (markers.length === 1) {
     
//       bounds = Leaflet.latLngBounds([
//         Leaflet.latLng(markers[0].position.lat - 2, markers[0].position.lng - 2),
//         Leaflet.latLng(markers[0].position.lat + 2, markers[0].position.lng + 2)
//       ]);
//     }
//     else if (markers.length > 1) {
   
      
//       markers.forEach(coord => {
//         if (coord.position.lat > topmost.lat) topmost = coord.position;
//         if (coord.position.lat < bottommost.lat) bottommost = coord.position;
//         if (coord.position.lng < leftmost.lng) leftmost = coord.position;
//         if (coord.position.lng > rightmost.lng) rightmost = coord.position;
//       });
//       bounds = Leaflet.latLngBounds([
//         Leaflet.latLng(bottommost.lat, leftmost.lng),
//         Leaflet.latLng(topmost.lat, rightmost.lng)
//       ]);
//     }
//   }

//   setMainBounds(bounds)
// }

function calculateExtremes(markers, setMainBounds) {
  let bounds = {};

  if (markers?.length === 0) {
    // Shifted bounds to focus more on the right side of India
    bounds = Leaflet.latLngBounds([
      Leaflet.latLng(8.4, 75.0),   // Southwest corner of the new focus area
      Leaflet.latLng(37.6, 100.0)  // Northeast corner of the new focus area
    ]);
  } else if (markers.length === 1) {
    // Adjust bounds for a single marker with some padding
    bounds = Leaflet.latLngBounds([
      Leaflet.latLng(markers[0].position.lat - 2, markers[0].position.lng - 2),
      Leaflet.latLng(markers[0].position.lat + 2, markers[0].position.lng + 2)
    ]);
  } else if (markers.length > 1) {
    // Shifted bounds to focus more on the right side of India
    bounds = Leaflet.latLngBounds([
      Leaflet.latLng(8.4, 75.0),   // Southwest corner of the new focus area
      Leaflet.latLng(100.6, 100.0)  // Northeast corner of the new focus area
    ]);
  }

  setMainBounds(bounds);
}

function setSubMarkersArr(markers, setSubMarksers) {
  const offset = 0.005;
  const allMembers = {};

  // Group all markers by their positions
  markers.forEach((marker) => {
    const members = marker.members.reduce((acc, loc) => {
      const { lat, lng } = loc.position;
      if (lat === null || lng === null) {
        return acc;
      }
      const key = `${loc.position.lat}-${loc.position.lng}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(loc);
      return acc;
    }, {});

    // Merge grouped members into allMembers
    Object.keys(members).forEach(key => {
      if (!allMembers[key]) {
        allMembers[key] = [];
      }
      allMembers[key].push(...members[key]);
    });
  });

  // Prepare updated members for state update
  const updatedMembers = [];
  Object.values(allMembers).forEach(group => {
    if (group.length > 1) {
      const angleStep = (2 * Math.PI) / group.length;
      group.forEach((loc, index) => {
        const angle = angleStep * index;
        const latOffset = offset * Math.cos(angle);
        const lngOffset = offset * Math.sin(angle);
        updatedMembers.push({
          ...loc,
          position: {
            'lat': loc.position.lat + latOffset,
            'lng': loc.position.lng + lngOffset
          }
        });
      });
    } else {
      updatedMembers.push(group[0]);
    }
  });
  setSubMarksers(updatedMembers);
}

export const getMarkers = async (locationData, setMarkers, setCenter, setStatus, setMainBounds, setSubMarksers,Id) => { 
  const payload = {
        location_uuids: locationData,
      }; 
    const newPro =modem.post(`${Id}/geoLocation`, payload).then((response) => {
      const markers = response.data.data.markers;      
      calculateExtremes(markers, setMainBounds);
      setSubMarkersArr(markers, setSubMarksers);
      setMarkers(markers?.length > 0 ? markers : []);
      // setCenter(markers?.length > 0 ? markers[0].position : { lat: 12.971599, lng: 77.594563 });
      setCenter({ lat: 20.5937, lng: 78.9629 });

      setStatus(true);
  });
return newPro;
}

export const getCopilotSummary = async () => {
  const path ="get_isp_details.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    const regPayload = JSON.stringify({
    "auth_token":"3d6af6ff936ccaf5980d0cb52","client_id":"1","link_status":3
  });

return axios.post(baseUrl, regPayload).then((response) => {
  if (response.data.code === 1) {
    return response.data.isp_data
  }
  else {
    return []
  }
}).catch(() => {
    return []
})
}
