import { Grid } from '@mui/material';
import ZoneTable from './components/ZoneTable';

export default function ZoneComponent() {
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12}>
          <ZoneTable />
        </Grid>
      </Grid>
    </>
  );
}
