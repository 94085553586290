import { NotificationsActiveOutlined } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardHeader, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { setGenerals } from '../../../API/Generals/General';
import { UserContext } from '../../../context/UserContext';
import { WithConfirm } from '../../WithConfirm';
import GlobalConfigEditor from './GloalConfigEditor';
import globalConfig from './GlobalConfig';

// --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
  CardContent: {
    padding: '0px',
  },
  tableContainer: {
    maxHeight: 600,
    '&::-webkit-scrollbar': {
      width: 7,
      height: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(181, 179, 179)',
      borderRadius: '30px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  cardAvatar: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
  },
  circularprogress: {
    position: 'absolute',
    left: 22,
    top: 22,
  },
  cardActionButtons: {
    height: '40px',
  },
  AvatarBox: {
    alignItems: 'center',
    display: 'flex',
  },
  Avatar: {
    height: 42,
    width: 42,
  },
  subnetBox1: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subnetBox2: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
});

// --------------------------------------------------------------------------------------------------

const Notifications = (props) => {
  const { confirm } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [checked, setChecked] = useState({});
  // const [value, setValue] = useState([]);
  const [updateButton, showUpdateButton] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);


  // const updateConfig = async (keys, values, lable) => {
  //   console.log(values);
  //   const data = {
  //     key: keys,
  //     value: values,
  //     context: lable,
  //   };
  //   setGenerals(customerId, data)
  //     .then((res) => {
  //       enqueueSnackbar(
  //         'value added sucessfully',
  //         {
  //           variant: 'success',
  //           anchorOrigin: {
  //             vertical: 'bottom',
  //             horizontal: 'right',
  //           },
  //         },
  //         500
  //       );
  //     })
  //     .catch((res) => {
  //       enqueueSnackbar(
  //         res.response.data.message,
  //         {
  //           variant: 'error',
  //           anchorOrigin: {
  //             vertical: 'bottom',
  //             horizontal: 'right',
  //           },
  //         },
  //         500
  //       );
  //     });
  // };

  // const handleCheckMultipleRows = (event) => {
  //   console.log(event.target.name);
  //   const _checked = { ...checked, [event.target.name]: event.target.checked };
  //   setChecked(_checked);
  //   if (Object.keys(_checked).filter((id) => _checked[id]).length) {
  //     showUpdateButton(true);
  //   } else {
  //     showUpdateButton(false);
  //   }
  //   setValue(event.target.name);
  // };

  const handleMultipleUpdate = () => {
    setUpdateLoading(true);
    Promise.all(
      Object.keys(checked)
        .filter((id) => checked[id] === true)
        .map((id) => {
          return setGenerals(customerId, id);
        })
    )
      .then(() => {
        enqueueSnackbar(
          'selected values deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        setUpdateLoading(false);
        showUpdateButton(false);
        setChecked({});
      })
      .catch((res) => {
        setUpdateLoading(false);
        showUpdateButton(false);
        setChecked({});
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Card >
        <CardHeader
          color="text.error"
          sx={{ mb: 2 }}
          title="Notifications"
          avatar={<NotificationsActiveOutlined />}
          action={
            <>
              {updateButton ? (
                <LoadingButton
                  className={classes.cardActionButtons}
                  onClick={confirm(() => handleMultipleUpdate(), {
                    title: 'Information',
                    confirmationText: 'Yes',
                    disableOkBtn: false,
                    description: 'Are you sure you want to update selected values',
                  })}
                  loading={updateLoading}
                  variant="outlined"
                >
                  update
                </LoadingButton>
              ) : (
                ''
              )}
            </>
          }
        />

        <Divider />

        {globalConfig.map((e, key) => {
          return (
            <div key={key}>
              <GlobalConfigEditor keys={e.key} valueComponent={e.valueComponent} lable={e.lable} />
            </div>
          );
        })}

      </Card>
    </>
  );
};

Notifications.propTypes = {
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(Notifications);
