/* eslint-disable */
import { ContentCopy, Download, Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getDeviceTypes } from '../../../API/DeviceTypes/index';
import { getUser } from '../../../API/User/User';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { toCamelizeWords } from '../../../utils/formatString';
import ArrayToCSVdownload from '../../ArrayToCSVdownload';
import ActionRenderer from './renderer/ActionRenderer';


// ---------------------------------------------------
const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 27,
    top: 22,
  },
}));

// ----------------------------------------------------------

const DeviceTypesTable = (props) => {
  const classes = useStyles();
  const { locationData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [devDownload, setDevDownload] = useState(false);
  const [getItemData, setgetItemData] = useState([]);
  const [filterModel, setFilterModel] = useState('');
  const [sortModel, setSortModel] = useState('');

// -------------------------------------------------------------------------------------

const isTablet = useMediaQuery('(max-width: 1020px)');
const isDesktop = useMediaQuery('(min-width: 1440px)');
const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

const getFontSize = () => {
 if (isLargeDesktop) {
   return '20px';
 } 
 if (isDesktop) {
   return '16px';
 } 
 if (isTablet) {
   return '14px';
 } 
 return '12px';
 }

const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

// ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (navigator.onLine) {
      getUser().then((res) => {
        setUserCustomer(res.customer.name);
        setgetItemData(res.customer.name);
        if (window.localStorage.developer_mode === '1') {
          setDevDownload(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!navigator.onLine) {
      const setItemData = localStorage.getItem('getItemData');
      if (setItemData) {
        setgetItemData(JSON.parse(setItemData));
      }
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      suppressMenu: true
    };
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const columnDefs = [
    {
      headerName: 'Device type name',
      colId: 'r_dvc_typ_name',
      sortable: true,
      width: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        if (params.data?.r_dvc_typ_name) {
          return (
            <div className={classes.root}>
              {toCamelizeWords(params?.data?.r_dvc_typ_name)}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dvc_typ_name}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_typ_name)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
    },
    {
      headerName: 'Vendor',
      colId: 'r_dvc_typ_vendor',
      floatingFilter: false,
      sortable: false,
      filter: false,
      width: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_typ_vendor;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Code',
      colId: 'code',
      width: 250,
      floatingFilter: false,
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_typ_vendor;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'OS',
      colId: 'r_dvc_typ_os',
      sortable: true,
      width: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_typ_os;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Status',
      colId: 'r_dvc_typ_cmdb_command',
      editable: true,
      sortable: true,
      width: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueSetter: (params) => {
        params.data.r_dvc_typ_cmdb_command = params.newValue;
        return true;
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_typ_cmdb_command) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_typ_cmdb_command}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dvc_typ_cmdb_command}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_typ_cmdb_command)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Priority',
      colId: 'priority',
      sortable: true,
      editable: true,
      width: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueSetter: (params) => {
        params.data.r_dvc_typ_description_matcher_regex = params.newValue;
        return true;
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_typ_description_matcher_regex) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_typ_description_matcher_regex}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dvc_typ_description_matcher_regex}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_typ_description_matcher_regex)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Actions',
      colId: 'actions',
      // pinned: 'right',
      width: 180,
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: 'actionRenderer',
      cellStyle: responsiveCellStyle,
    },
  ];

  const isUndefined = (value, def = '') => {
    return value === undefined || value == null || value.length <= 0 || value === '' ? def : value;
  };

  const onBtnExport = () => {
    const payload = {
      endRow: totalItems,
      startRow: 0,
      location_uuids: locationData?.map((i) => i._uid),
      global_keyword: query,
      sortModel,
      filterModel,
    };
    setDownloadLoading(true);
    getDeviceTypes(customerId, payload)
      .then((res) => {
        const response = res.map((e) => {
          return {
            Id: isUndefined(e._uid),
            Type: isUndefined(e.r_dvc_typ_name),
            Vendor: isUndefined(e.r_dvc_typ_vendor),
            OS: isUndefined(e.r_dvc_typ_os),
            'CMBD Command': isUndefined(e.r_dvc_typ_cmdb_command),
            'CVE Match Regex': isUndefined(e.r_dvc_typ_cve_matcher_regex),
          };
        });
        const CSV_SEPARATER = ',';
        ArrayToCSVdownload({
          data: response,
          headers: Object.keys(response[0]),
          delimiter: CSV_SEPARATER,
          filename: `DeviceType-${userCustomer}-${new Date().toUTCString()}.csv`,
        });
        setDownloadLoading(false);
        enqueueSnackbar('Downloaded successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setTimeout(() => { }, 500);
      })
      .catch((res) => {
        setDownloadLoading(false);
        enqueueSnackbar(
          res.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          filterModel: {
            ..._params.filterModel,
            r_dvc_up_down: {
              filterType: 'text',
              type: '=',
              filter: 'INACTIVE',
            },
          },
        };
        delete payload.context;
        setFilterModel(payload.filterModel);
        setSortModel(_params.sortModel);
        getDeviceTypes(customerId, payload)
          .then((res) => {
            let lastRow = -1;
            if (res.length <= _params.endRow) {
              lastRow = res.length;
            }
            setTotalItems(res.length);
            if (res.total === 0) {
              params.api.showNoRowsOverlay();
            }
            _params.successCallback(res, lastRow);
            setRefreshLoading(false);
          });
      },
    });
  }, []);

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
  }), []);


  // -------------------------------------------------------------------------------------
  return (
    <Card>
      <CardHeader
        color="text.error"
        sx={{ mb: 2 }}
        title="Device-types"
        action={
          <Stack
            sx={{ marginTop: '-5px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}
            direction="row"
          >
            <TextField
              focused
              autoFocus
              type="text"
              size="small"
              value={query}
              variant="outlined"
              name='new-password'
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search device-types"
            />
            {devDownload ? (
              <Box>
                <Tooltip title="Download as CSV">
                  <IconButton color="primary" onClick={onBtnExport}>
                    {downloadLoading ? (
                      <CircularProgress size={27} sx={{ mr: '5px' }} />
                    ) : (
                      <Download sx={{ fontSize: '1.8rem' }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              ''
            )}

            <Box>
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Box>
          </Stack>
        }
        avatar={
          <Box>
            <Chip label={totalItems || 0} />
            {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
          </Box>
        }
      />

      <Divider />

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          {...defaultAgGridProps}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          components={components}
          context={{
            global_keyword: query,
          }}
        />
      </Box>
    </Card>
  );
};

export default DeviceTypesTable;
