/* eslint-disable */
import {
    Box,
    LinearProgress, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { networkTable } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 480,
}));

// ---------------------------------------

const SdWanTable = ({ sdwanExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([]);

    // -------------------------------------------------------------------------------------

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    // ----------------------------------------------------------------------------------------------------


    // const transformData = (data) => {
    //     console.log("data",data);
    //     if (!data || data.length === 0) {
    //         return [];
    //     }

    //     const transformedData = [];
    //     const keys = Object.keys(data[0]);


    //     const groupedData = {};

    //     keys.forEach((key) => {

    //         const pattern = key.match(/[^a-zA-Z0-9](.*?)$/); 
    //         if (pattern) {
    //             const dynamicPattern = pattern[1];
    //             if (!groupedData[dynamicPattern]) {
    //                 groupedData[dynamicPattern] = {};
    //             }
    //             const baseKey = key.split('.')[0];
    //             groupedData[dynamicPattern][baseKey] = data[0][key][0];
    //         } else {

    //             console.warn(`Key without identifiable dynamic pattern: ${key}`);
    //         }
    //     });


    //     Object.keys(groupedData).forEach((pattern) => {
    //         transformedData.push(groupedData[pattern]);
    //     });

    //     return transformedData;
    // };



    // const transformData = (data) => {
    //     console.log("data", data);
    //     if (!data || data.length === 0) {
    //         return [];
    //     }

    //     const transformedData = [];
    //     const groupedData = {};

    //     // Iterate through each row of data
    //     data.forEach((row) => {
    //         Object.keys(row).forEach((key) => {
    //             const parts = key.split('.'); // Split key by '.'
    //             const baseKey = parts[0];
    //             const dynamicPart = parts.length > 1 ? parts[1] : "default"; // Handle both baseKey and dynamicPart

    //             // If dynamic part is not present in groupedData, initialize it
    //             if (!groupedData[dynamicPart]) {
    //                 groupedData[dynamicPart] = {};
    //             }

    //             // Populate grouped data with base key values
    //             groupedData[dynamicPart][baseKey] = row[key][0]; // Assuming the data has a consistent format
    //         });
    //     });

    //     // Transform grouped data into an array format suitable for Ag-Grid
    //     Object.keys(groupedData).forEach((dynamicKey) => {
    //         transformedData.push(groupedData[dynamicKey]);
    //     });

    //     return transformedData;
    // };


    // const transformData = (data) => {
    //     console.log("data", data);
    //     if (!data || data.length === 0) {
    //         return [];
    //     }

    //     const transformedData = [];
    //     const groupedData = {};

    //     // Iterate through each row of data
    //     data.forEach((row) => {
    //         Object.keys(row).forEach((key) => {
    //             // Split the key by '.' to separate the base key and index
    //             const [baseKey, index] = key.split('.');

    //             // Ensure the index exists to group data accordingly
    //             if (index) {
    //                 if (!groupedData[index]) {
    //                     groupedData[index] = {}; // Initialize the object for this index if not already done
    //                 }

    //                 // If row[key] has values, assign the first value to the grouped data
    //                 groupedData[index][baseKey] = row[key][0] || ""; // Use default empty string if no value
    //             } else {
    //                 console.warn(`Key without identifiable dynamic part: ${key}`);
    //             }
    //         });
    //     });

    //     // Convert grouped data object to array format
    //     Object.keys(groupedData).forEach((index) => {
    //         transformedData.push(groupedData[index]);
    //     });

    //     return transformedData;
    // };

    // const transformData = (data) => {
    //     console.log("data", data);
    //     if (!data || data.length === 0) {
    //         return [];
    //     }
    //     const transformedData = [];
    //     const groupedData = {};
    //     data.forEach((row) => {
    //         Object.keys(row).forEach((key) => {
    //             if (key.startsWith("fgDiskCount") || key.startsWith("fgSlaProbeClientNumber")) {
    //                 return;
    //             }
    //             const [baseKey, index] = key.split('.');
    //             if (index) {
    //                 if (!groupedData[index]) {
    //                     groupedData[index] = {};
    //                 }
    //                 groupedData[index][baseKey] = row[key][0] || "";
    //             } else {
    //                 console.warn(`Key without identifiable dynamic part: ${key}`);
    //             }
    //         });
    //     });


    //     Object.keys(groupedData).forEach((index) => {
    //         transformedData.push(groupedData[index]);
    //     });

    //     return transformedData;
    // };

    const transformData = (data) => {
       
        if (!data || data.length === 0) {
            return [];
        }
    
        const transformedData = [];
        const groupedData = {};
        const skipKeys = new Set();
    
        // First pass to identify keys to skip
        data.forEach((row) => {
            const keysToCheck = Object.keys(row).filter(key => key.includes('.'));
            const baseKeyMap = {};
    
            keysToCheck.forEach((key) => {
                const [baseKey] = key.split('.');
                if (!baseKeyMap[baseKey]) {
                    baseKeyMap[baseKey] = new Set();
                }
    
                const values = row[key];
                baseKeyMap[baseKey].add(values.join('|')); // Join values as a unique string for comparison
            });
    
            Object.keys(baseKeyMap).forEach((baseKey) => {
                if (baseKeyMap[baseKey].size === 1) { // If all values for this key are the same, mark for skipping
                    skipKeys.add(baseKey);
                }
            });
        });
    
        // Second pass to transform data
        data.forEach((row) => {
            Object.keys(row).forEach((key) => {
                const [baseKey, index] = key.split('.');
                if (index && !skipKeys.has(baseKey)) {
                    if (!groupedData[index]) {
                        groupedData[index] = {};
                    }
                    groupedData[index][baseKey] = row[key][0] || "";
                }
            });
        });
    
        // Convert grouped data to array
        Object.keys(groupedData).forEach((index) => {
            transformedData.push(groupedData[index]);
        });
    
        return transformedData;
    };
    

    const fetchData = useCallback(() => {
        // if (!healthDashState.selectedDevice || networksExpanded === false) {
        //     return '';
        // }
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
            // device_os: healthDashState.selectedDevice.r_dvc_os,
        };
        setLoading(true);
        // networkTable(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
        // .then((res) => {

        setLoading(false);
        const networkTableData = allData?.sdwan_table || [];
        const transformedData = transformData(networkTableData);
        setResData(transformedData);
        if (transformedData.length > 0) {
            const headers = Object.keys(transformedData[0]).map((key) => ({
                headerName: key,
                field: key,
                width: 180,
                sortable: true,
                filter: true,
                cellStyle: responsiveCellStyle,
                valueFormatter: (params) => {
                    const value = params.value;
                    return value === null || value === '' ? 'NA' : value;
                },
            }));
            setColumnHeaders(headers);
        }
        // })
        // .catch(() => {
        //     setLoading(false);
        // });
    }, [healthDashState.selectedDevice, sdwanExpanded, customerId, getStartEnd]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, sdwanExpanded, fetchData]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    // -----------------------------------------------------------------------------------------------

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={resData}
                            columnDefs={columnHeaders}
                            // onGridReady={fetchData}
                            defaultColDef={defaultColDef}
                            rowHeight={50}  // Set row height
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default SdWanTable;
