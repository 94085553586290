// material
import {  Divider, Grid } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../actions/updateLocation';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
import { UserContext } from '../../context/UserContext';
import AlertsCards from './Components/AlertsCards';
import AlertsTable from './Components/AlertsTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Alerts = () => {
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.alertupdown.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'alertupdown', locationUuid: _locations }));
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} style={{ marginTop: '-69px' }}>
          <LocationSelect selectLocation={selectLocation} locationData={locationData}/>
        </Grid>

        <Divider sx={{ marginTop: '15px', width: '97%',marginLeft:'32px' }} />

        <Grid item lg={12} md={12} sm={12}>
          <AlertsTable locationData={locationData} />
        </Grid>
      </Grid>
    </>
  );
};
export default Alerts;
