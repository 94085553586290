import {
  Typography,
  Breadcrumbs,
  Container,
  Divider
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import LocationsComponents from '../components/LocationsComponents/LocationsComponents';
// ----------------------------------------------------------------------



export default function Location() {

  return (
    <Page title="Locations">
      <Container maxWidth={false}>

        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px' }}>
          <Breadcrumbs aria-label="breadcrumb"
                    sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}
                    >
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon 
              sx={{
                fontSize: {
                  xs: '20px',
                  sm: '22px',
                  md: '24px',
                  lg: '26px',
                  xl: '28px',
                  xxl: '30px',
                },
              }} />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dc-agent"
            sx={{
              fontSize: {
                xs: '8px',
                sm: '12px',
                md: '14px',
                lg: '16px',
                xl: '18px',
                xxl: '20px',
              },
            }}>
              Onboarding
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/locations"
            sx={{
              fontSize: {
                xs: '8px',
                sm: '12px',
                md: '14px',
                lg: '16px',
                xl: '18px',
                xxl: '20px',
              },
            }}>
              Locations
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>

        <LocationsComponents />

      </Container>
    </Page>
  );
}
