/* eslint-disable */
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useCallback, useState } from 'react';
import { mapColumnDefinitionToStore } from "../../../../utils/columnDef";
import NetworkColumnSettings from './NetworkColumnSettings';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
  stackContainer: {
    display:'flex', 
    justifyContent:'space-between',
    alignItems:'center',
  }
});

function ColumnSettingsDialog(props) {
  const classes = useStyles();
  const { openDialog, handleClose, columnDefs, gridApi } = props;
  const [columnsSettingData, setColumnsSettingData] = useState( props?.columnDefs);

  const handleSaveClose = () => {
    localStorage.setItem(
      'nms-network-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(columnsSettingData)),
    );
    updateNetworkDefsStore(columnsSettingData);
    handleClose();
  };

  const updateNetworkDefsStore = useCallback((columnsSettingData) => {
    gridApi.setColumnDefs(columnsSettingData);
  }, [columnsSettingData]);


  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box className={classes.root}>
            Column settings
          </Box>

          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Typography variant='body1' sx={{textAlign:'center'}}>
            Show, hide, pin(fix), unpin and re-arrange column order as you need.
        </Typography>
          <NetworkColumnSettings 
            columnsSettingData={columnsSettingData}
            setColumnsSettingData={setColumnsSettingData}
            columnDefs={columnDefs}
          />
      </DialogContent>
      <DialogActions sx={{display:'flex',align:'right',gap:1}}>
      
        <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
            Cancel
          </Button>
        <Button
            onClick={props.resetColumnSetting}
            color="primary"
            variant="contained"
        >
            Reset
        </Button>
        <Button
            onClick={handleSaveClose}
            color="primary"
            variant="contained"
        >
            Save & close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ColumnSettingsDialog;