// import { Close } from '@mui/icons-material';
// import DownloadIcon from '@mui/icons-material/Download';
// import {
//   Box,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   IconButton,
//   LinearProgress,
//   Slide,
//   Stack,
//   Tooltip,
//   Typography,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import AWS from 'aws-sdk';
// import { useSnackbar } from 'notistack';
// import { forwardRef, useEffect, useState } from 'react';
// import SyntaxHighlighter from 'react-syntax-highlighter';


// const Transition = forwardRef((props, ref) => {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//   },
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
//   fileNameBox: {
//     width: '100%',
//     padding: '10px',
//     marginBottom: '25px',
//     borderStyle: 'solid',
//     borderWidth: 2,
//     borderRadius: 2,
//     borderColor: '#f5f5f5',
//     fontFamily: 'Sans-serif',
//   },
//   stackContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
// });

// export default function Backupdialog(props) {
//   const classes = useStyles();
//   const { enqueueSnackbar } = useSnackbar();
//   const { openDialog, handleClose, data, backupDetails } = props;
//   const [loading, setLoading] = useState(false);
//   const [docs, setDocs] = useState('');
//   const [filePath, setFilePath] = useState(data.r_path);


//   // const downloadFile = async () => {
//   //   const backupDetailsString = backupDetails?.curr_config || '';
//   //   const blob = new Blob([backupDetailsString], { type: 'text/plain' });
//   //   const url = URL.createObjectURL(blob);
//   //   const element = document.createElement('a');
//   //   element.href = url;
//   //   element.download = `${data?.network_device1?.location?.r_location_name}_${data?.network_device1?.l_dvc_typ_name}_${data?.r_dvc_unique_identifier}_${data?.g_created_at}.txt`;
//   //   document.body.appendChild(element);
//   //   element.click();
//   //   document.body.removeChild(element);
//   //   URL.revokeObjectURL(url);
  
//   //   enqueueSnackbar('Downloaded successfully', {
//   //     variant: 'success',
//   //     anchorOrigin: {
//   //       vertical: 'bottom',
//   //       horizontal: 'right',
//   //     },
//   //   });
//   // };


  
//   const downloadFile = async () => {
//     const backupDetailsString = decodeBase64(backupDetails?.curr_config || '');
    
//     const blob = new Blob([backupDetailsString], { type: 'text/plain' });
//     const url = URL.createObjectURL(blob);
//     const element = document.createElement('a');
//     element.href = url;
//     element.download = `${data?.r_location_name}_${data?.l_dvc_typ_name}_${data?.r_dvc_unique_identifier}_${data?.g_created_at}.txt`;
//     document.body.appendChild(element);
//     element.click();
//     document.body.removeChild(element);
//     URL.revokeObjectURL(url);
  
//     enqueueSnackbar('Downloaded successfully', {
//       variant: 'success',
//       anchorOrigin: {
//         vertical: 'bottom',
//         horizontal: 'right',
//       },
//     });
//   };

//   const decodeBase64 = (base64) => {
//     const binaryString = atob(base64);
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);
//     for (let i = 0; i < len; i++) {
//       bytes[i] = binaryString.charCodeAt(i);
//     }
//     return new TextDecoder().decode(bytes);
//   };
  
//   const backupDetailsString = decodeBase64(backupDetails?.curr_config || '');
  
//   // const backupDetailsString = atob(backupDetails?.curr_config || '');
// console.log("loading",loading);

//   return (
//     <>
//       <Dialog
//         open={openDialog}
//         TransitionComponent={Transition}
//         onClose={handleClose}
//         fullWidth
//         maxWidth="lg"
//         classes={{
//           scrollPaper: classes.topScrollPaper,
//           paperScrollBody: classes.topPaperScrollBody,
//         }}
//       >
//         <DialogTitle>
//           <Stack direction="row" className={classes.stackContainer}>
//             <Box className={classes.root}>
//               {`${data?.r_location_name}_${data?.l_dvc_typ_name}_${data?.r_dvc_unique_identifier}_${data?.g_created_at}.txt`}
//               <Typography variant="subtitle2" gutterBottom className={classes.field}>
//                 {filePath}
//                 <Tooltip title="Download file">
//                   <IconButton size="small" onClick={downloadFile}>
//                     <DownloadIcon fontSize="inherit" />
//                   </IconButton>
//                 </Tooltip>
//               </Typography>
//             </Box>
//             <IconButton onClick={handleClose}>
//               <Close sx={{ fontSize: '1.5rem' }} />
//             </IconButton>
//           </Stack>
//         </DialogTitle>
//         <Divider />
//         {loading && <LinearProgress />}

//         <DialogContent sx={{ padding: '12px' }}>
//           {/* {data?.r_dvc_type_name === 'FIREWALL' ? ( */}
//             <Box className={classes.fileNameBox}>
//               <pre>{backupDetailsString}</pre>
//             </Box>
//           {/* ) : (
//             <pre>
//               <SyntaxHighlighter language="vim" showLineNumbers>
//                 {backupDetailsString}
//               </SyntaxHighlighter>
//             </pre>
//           )} */}
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// }

import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { forwardRef, useEffect, useState } from 'react';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fileNameBox: {
    width: '100%',
    padding: '10px',
    marginBottom: '25px',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#f5f5f5',
    fontFamily: 'Sans-serif',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default function Backupdialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, data, backupDetails } = props;
  const [loading, setLoading] = useState(false);
  const [decodedText, setDecodedText] = useState('');
  const [filePath, setFilePath] = useState(data.r_path);

  const decodeBase64 = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder().decode(bytes);
  };

  useEffect(() => {
    const decodeContent = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000)); 
      const decoded = decodeBase64(backupDetails?.curr_config || '');
      setDecodedText(decoded);
      setLoading(false); 
    };

    decodeContent();
  }, [backupDetails?.curr_config]);

  const downloadFile = async () => {
    const blob = new Blob([decodedText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.href = url;
    element.download = `${data?.r_location_name}_${data?.l_dvc_typ_name}_${data?.r_dvc_unique_identifier}_${data?.g_created_at}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(url);

    enqueueSnackbar('Downloaded successfully', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box className={classes.root}>
            {`${data?.r_location_name}_${data?.l_dvc_typ_name}_${data?.r_dvc_unique_identifier}_${data?.g_created_at}.txt`}
            <Typography variant="subtitle2" gutterBottom className={classes.field}>
              {filePath}
              <Tooltip title="Download file">
                <IconButton size="small" onClick={downloadFile}>
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      {loading && <LinearProgress />}
      <DialogContent sx={{ padding: '12px' }}>
        {/* {loading ? (
          <Typography variant="body2">Decoding content...</Typography>
        ) : ( */}
          <Box className={classes.fileNameBox}>
            <pre>{decodedText}</pre>
          </Box>
        {/* )} */}
      </DialogContent>
    </Dialog>
  );
}

