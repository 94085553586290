// @mui
import {
    Grid,
  } from '@mui/material';
import ISPBadTable from '../../ISPManagementTable/ISPManagementDashboard/ISPBadTable ';
  // components
  // ----------------------------------------------------------------------
  
  export default function SidewiseBad() {
    return (
      <>
           <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:2}}>
             <ISPBadTable />
           </Grid>
      </>
    );
  }
  