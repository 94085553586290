/* eslint-disable */
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Divider, IconButton, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { x } from "@xstyled/styled-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Handle, Position, useEdges, useNodes, useReactFlow } from 'reactflow';
import { getChildrensL2 } from '../../../../../API/NetworkDevices/topology';
import EndpointIcon from '../../../../../Assets/ap-network.svg';
import AccessIcon from '../../../../../Assets/ap.svg';
import WMMIcon from '../../../../../Assets/controller.png';
import ServerIcon from '../../../../../Assets/desktop.svg';
import StackIcon from '../../../../../Assets/dsw.svg';
import FirewallIcon from '../../../../../Assets/firewall-r1.svg';
import FirewallStackIcon from '../../../../../Assets/firewall.png';
import InternetIcon from '../../../../../Assets/internet.svg';
import SwitchStackIcon from '../../../../../Assets/network-stack.png';
import SwitchOsxIcon from '../../../../../Assets/network-switch.png';
import VirtualIcon from '../../../../../Assets/router.png';
import SwitchIcon from '../../../../../Assets/switch.svg';
import WVCIcon from '../../../../../Assets/wlc.svg';
import { UserContext } from '../../../../../context/UserContext';
import { toCamelizeWords } from '../../../../../utils/formatString';
import { useMapSettingContext } from '../mapSettingProvider';
import { labelStyle } from './constants';



// ----------------------------------------------------
const useStyles = makeStyles(() => ({
    
    arrowForward: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 40,
        paddingTop: '3px',
        paddingLeft: '5px',
    },
    arrowDownward: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: 40,
        left: 0,
        paddingLeft: '3px',
        marginRight: 6,
        marginTop: -5
    },
    iconButtons: {
        padding: 0,
        backgroundColor: '#eeeeee',
        display: 'none'
    },
    nodeRoot: {
        width: 56,
        '&:hover $iconsBox': {
            visibility: 'visible',
        },
        '&:hover $iconsBoxHidden': {
            visibility: 'visible',
        },
        "& $circleDown": {
            border: "3px solid red",
            animationName: '$blinkerDown',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
        }
    },
    nodeRootSelected: {
        width: 56,
        '&:hover $iconsBox': {
            visibility: 'visible',
        },
        '&:hover $iconsBoxHidden': {
            visibility: 'visible',
        },
        "& $circleUp": {
            border: "3px solid #8581fa",
        },
        "& $circleDown": {
            border: "3px solid red",
        }
    },
    circleUp: {
        border: "1px solid #81d4fa",
    },
    circleDown: {
        border: "1px solid red",
    },
    iconsBox: {
        visibility: 'visible',
        marginTop: -1
    },
    iconsBoxHidden: {
        visibility: 'hidden',
    },
    arrowDownwardCount: {
        background: '#c3e6c3',
        borderRadius: 5,
        color: '#2e2a2a',
        fontSize: 8,
        padding: '0 5px',
        "&:hover": {
            background: '#008000',
            color: 'white',

        }
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
    childrenLoadingLoader: {
        position: "absolute",
        bottom: -3,
        left: 17

    },
    '@keyframes blinkerDown': {
        from: { borderColor: "rgb(255 0 0)" },
        to: { borderColor: "#ffffff" },
    },

}));

const nodeTypes = {
    'ACCESS POINT': AccessIcon,
    'endpoint': EndpointIcon,
    'INTERNET': InternetIcon,
    'FIREWALL': FirewallIcon,
    'FIREWALL_STACK': FirewallStackIcon,
    'SERVER': ServerIcon,
    'STACK': StackIcon,
    'SWITCH': SwitchIcon,
    'SWITCH_STACK': SwitchStackIcon,
    'SWITCHOSX': SwitchOsxIcon,
    'VIRTUAL': VirtualIcon,
    'WIRELESS_MOBILITY_MASTER': WMMIcon,
    'WIRELESS_VIRTUAL_CONTROLLER': WVCIcon,
};

const topologyOrderTypes = [
    "WIRELESS_VIRTUAL_CONTROLLER",
    "WIRELESS_MOBILITY_MASTER",
    "FIREWALL",
    "CORE_SWITCH",
    "SWITCH",
    "ACCESS POINT",
    "PRINTER",
    "ETC"
]

// -------------------------------------------------
export const CustomDeviceNode = React.memo(
    ({ data, id, position, xPos, yPos, ...props }) => {
        const classes = useStyles();
        const edges = useEdges();
        const nodes = useNodes();
        const node = { id, data, yPos, xPos }
        const { mapSettingState } = useMapSettingContext()
        const { customerId } = useContext(UserContext);
        const locationData = useSelector((state) => state.locationSelectReducer.overview.locationData);
        const [openedPopover, setOpenedPopover] = useState(false);
        const popoverAnchor = useRef(null);
        const [childLoading, setChildLoading] = useState(false)
        const reactFlowInstance = useReactFlow();

        const handlePopoverOpen = (event) => {
            setOpenedPopover(true);
        };

        const handlePopoverClose = () => {
            setOpenedPopover(false);
        };

        const getChildrens = (childrens) => {
            
            let xArray = []
            let xInsertionPoint = 0;
            let xMovingPoint = 0;
            let yInsertionPoint = 0;
            let except = childrens.map((i) => i.id)

            switch (data.l_dvc_l2_type) {
                case "SWITCH":
                    xArray = childrens.map((i) => i.position?.x).sort()
                    if (xArray.length === 0) {
                        xArray = [xPos - 55, xPos, xPos + 55]
                    }
                    yInsertionPoint = 60; // childNodes.map((i)=> i.position.y).sort()

                    break
                case "CORE_SWITCH":
                    if (data.with_access_layer) {
                        yInsertionPoint = 300
                        xArray = (childrens.length === 0 ? nodes.filter(i => i.data.l_dvc_l2_type === "SWITCH" && i.position.x < (node.xPos + 100)) : childrens).map((i) => i.position?.x).sort()
                        xInsertionPoint = xMovingPoint = parseInt(Math.max(...xArray)) || 0
                    } else {
                        xArray = childrens.map((i) => i.position?.x).sort()
                        if (xArray.length === 0) {
                            xArray = [xPos - 55, xPos, xPos + 55]
                        }
                        yInsertionPoint = 60; // childNodes.map((i)=> i.position.y).sort()
                    }
                    break
                case "FIREWALL":
                    yInsertionPoint = 180
                    xArray = (childrens.length === 0 ? nodes.filter(i => i.data.l_dvc_l2_type === "SWITCH") : childrens).map((i) => i.position?.x).sort()
                    xInsertionPoint = xMovingPoint = parseInt(Math.max(...xArray)) || 0
                    break
            }
            setChildLoading(true)
            getChildrensL2(customerId, locationData, data.r_dvc_node_mac_address, data.l_dvc_l2_type, except).then((response) => {
                reactFlowInstance.setNodes([...nodes, ...response.nodes])
                const idsToRemove = new Set(edges.map(item => item.id));

                const childEdlged = response.edges.filter(item => !idsToRemove.has(item.id));

                reactFlowInstance.setEdges([...edges, ...childEdlged])
                setChildLoading(false)
            })
        }

        const getChildrenNodes = (entities, id, result = []) => {
            const childrens = immediateChildren(entities, id)
            if (childrens.length === 0) {
                return result;
            }
            result = [...result, ...childrens];
            for (const ele of childrens) {
                result = getChildrenNodes(entities, ele.id, result);
            }
            return result;
        }

        const immediateChildren = (entities, id, result = []) => {
            const connetedNodes = entities.filter(x => x.id !== id && x.data?.l_dvc_l2_parents_snmp?.findIndex(i => i.toUpperCase() === id.toUpperCase()) !== -1);
            if (connetedNodes.length === 0) {
                return result;
            }
            let found = false;
            const childTypes = topologyOrderTypes.filter(i => {
                if (i === data.l_dvc_l2_type) {
                    found = true
                    return false
                }
                return found
            })
            const childrens = connetedNodes.filter((i) => childTypes.findIndex(c => c === i.data.l_dvc_l2_type) !== -1)
            return childrens
        }

        const showHideChildrens = () => {
            if (data.l_dvc_typ_name === 'ACCESS POINT' || data.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER" || data.l_dvc_typ_name === "WIRELESS_MOBILITY_MASTER" || data.l_dvc_typ_name === "INTERNET") {
                return;
            }
            const childrens = immediateChildren(nodes, id)
            if (childrens.length === data?.l_dvc_l2_child_snmp?.length) {
                const allChildrens = getChildrenNodes(nodes, id)

                reactFlowInstance.setNodes(nodes.filter((inode) => {
                    if (allChildrens.findIndex(x => x.id === inode.id) !== -1 && inode.position.y > node.yPos) {
                        return false;
                    }
                    return true;
                }))
            } else {
                getChildrens(childrens)
            }
        };

        // ------------------------------------------------------


        return (
            <>
                <x.div className={mapSettingState.selectedNode === id ? classes.nodeRootSelected : classes.nodeRoot} title={mapSettingState.selectedNode}>
                    <x.div
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        spaceY={0}
                        spaceX={0}
                        // onClick={showHideChildrens}
                    >
                        <x.div>
                            <Handle
                                type="target"
                                position={Position.Top}
                                style={{ background: '#b3e5fc', border: 'none', display: 'none' }}
                            />

                            <x.div
                                p={6}
                                border={1}
                                bg="#b3e5fc"
                                borderColor="#81d4fa"
                                borderRadius="6px"
                                ref={popoverAnchor}
                                hoverTransform="scale(1.1)"
                                transition="transform 0.2s ease"
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                className={data?.r_dvc_up_down == "UP" ? classes.circleUp : classes.circleDown}
                            >

                                <x.img
                                    h={28}
                                    w={28}
                                    src={nodeTypes[data.l_dvc_typ_name]}
                                    alt={data.l_dvc_typ_name}
                                    draggable={false}
                                />

                                {childLoading && <CircularProgress className={classes.childrenLoadingLoader} color="inherit" size={20} />}

                            </x.div>

                            <Handle
                                type="source"
                                position={Position.Bottom}
                                style={{ background: '#b3e5fc', border: 'none', display: 'none' }}
                            />
                        </x.div>

                        {mapSettingState.showLabel && (
                            <x.p {...labelStyle}>
                                {data.r_dvc_name}
                            </x.p>
                        )}

                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.popoverContent,
                            }}

                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={openedPopover}
                            anchorEl={popoverAnchor.current}
                            placement='left-start'
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            container={document.getElementById("react-flow-sitewise-map")}
                            PaperProps={{ onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose }}
                        >
                            <Box>
                                <Box sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'row', letterSpacing: '2px' }}>
                                    <Typography variant="h6" sx={{ color: 'green' }}>
                                        {toCamelizeWords(data.l_dvc_typ_name)}
                                    </Typography>
                                </Box>
                                <Divider sx={{ height: '3px' }} />
                                <Box sx={{ px: 2, py: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px' }}>
                                        <Typography variant="subtitle2" >IP Address:&ensp;</Typography>
                                        <Typography>{data.r_dvc_ip_address}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px' }}>
                                        <Typography variant="subtitle2" >Host:&ensp;</Typography>
                                        <Typography>{data.r_dvc_name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', letterSpacing: '2px' }}>
                                        <Typography variant="subtitle2" >Online status:&ensp;</Typography>
                                        <Typography>{data.r_dvc_up_down}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Popover>
                    </x.div>

                    <x.div
                        className={!mapSettingState.showCounterIcon ? classes.iconsBoxHidden : classes.iconsBox}
                    >
                        <x.div>
                            {data.l_dvc_typ_name === 'ACCESS POINT' || data.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER" || data.l_dvc_typ_name === "WIRELESS_MOBILITY_MASTER" || data.l_dvc_typ_name === "INTERNET" ? '' : (
                                <Box className={classes.arrowDownward}>
                                    <IconButton
                                        size="small"
                                        onClick={showHideChildrens}
                                        className={classes.iconButtons}
                                    >
                                        <AddIcon sx={{ color: '#29b6f6' }} fontSize="inherit" />
                                    </IconButton>
                                    <Typography className={classes.arrowDownwardCount} sx={{ px: 0.5, fontSize: '12px', marginTop: '6px' }}>
                                        + {data?.l_dvc_l2_child_snmp?.length || 0}
                                    </Typography>
                                </Box>
                            )}
                        </x.div>
                    </x.div>

                </x.div>

            </>
        );
    }
);