// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import { Checkbox, CircularProgress } from '@mui/material';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { useContext, useEffect, useState } from 'react';
// import { getZones } from '../../../../API/DcAgent/DcAgent';
// import { UserContext } from '../../../../context/UserContext';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// export default function DcZoneSelector(props) {
//   const { customerId } = useContext(UserContext);
//   const { zoneValue, setZoneValue, page } = props;
//   const [zoneOptions, setZoneOptions] = useState([]);
//   const [zoneLoading, setZoneLoading] = useState(true);
//   const filter = createFilterOptions();

//   // const loadZoneOptions = async () => {
//   //   setZoneLoading(true);
//   //   try {
//   //     const _zoneOptions = await getZones(customerId, page);
//   //     setZoneOptions(
//   //       _zoneOptions?.data.map((item) => {
//   //         return {
//   //           _uid: item.zone_id,
//   //           r_zone_name: item.r_zone_name,
//   //         };
//   //       })
//   //     );
//   //   } catch (error) {
//   //     console.log(error);
//   //   } finally {
//   //     setZoneLoading(false);
//   //   }
//   // };
//   useEffect(() => {
//     const loadZoneOptions = async () => {
//       setZoneLoading(true);
//       try {
//         const _zoneOptions = await getZones(customerId, page);
//         const options = _zoneOptions?.data.map((item) => {
//           return {
//             _uid: item.zone_id,
//             r_zone_name: item.r_zone_name,
//           };
//         }) || [];
  
//         setZoneOptions(options);
  
//         // Set the first option as the default value if options are available
//         if (options.length > 0) {
//           setZoneValue(options[0]);
//         }
//       } catch (error) {
//         console.log(error);
//       } finally {
//         setZoneLoading(false);
//       }
//     };
  
//     loadZoneOptions();
//   }, [customerId, page]);
  
//   // useEffect(() => {
//   //   loadZoneOptions();
//   // }, []);
// console.log(zoneValue);

//   return (
//     <Autocomplete
//       size="small"
//       value={zoneValue}
//       fullWidth
//       disableCloseOnSelect
//       onChange={(event, newValue, reason, details) => {
//         setZoneValue(newValue);
//       }}
//       filterOptions={(options, params) => {
//         const filtered = filter(options, params);
//         const { inputValue } = params;
//         const isExisting = zoneOptions.some(
//           (option) => inputValue.toLowerCase() === option.r_zone_name.toLocaleLowerCase()
//         );
//         // if (inputValue !== '' && !isExisting) {
//         //   filtered.push({
//         //     inputValue,
//         //   });
//         // }
//         return filtered;
//       }}
//       selectOnFocus
//       clearOnBlur
//       handleHomeEndKeys
//       id="free-solo-with-text-demo"
//       options={zoneOptions}
//       loading={zoneLoading}
//       getOptionLabel={(option) => {
//         // Value selected with enter, right from the input
//         if (typeof option === 'string') {
//           return option;
//         }
//         // Add "xxx" option created dynamically
//         if (option.inputValue) {
//           return option.inputValue;
//         }
//         // Regular option
//         return option.r_zone_name;
//       }}
//       isOptionEqualToValue={(option, value) => option._uid === value._uid}
//       renderOption={(props, option, { selected }) => (
//         <li {...props}>
//           <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
//           {option.r_zone_name}
//         </li>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           placeholder="Select zones"
//           label="Zone"
//           InputLabelProps={{ shrink: true }}
//           InputProps={{
//             ...params.InputProps,
//             endAdornment: (
//               <>
//                 {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
//                 {params.InputProps.endAdornment}
//               </>
//             ),
//           }}
//         />
//       )}
//     />
//   );
// }
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { getZones } from '../../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../../context/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DcZoneSelector(props) {
  const { customerId } = useContext(UserContext);
  const { zoneValue, setZoneValue, page } = props;
  const [zoneOptions, setZoneOptions] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(true);
  const filter = createFilterOptions();

  useEffect(() => {
    const loadZoneOptions = async () => {
      setZoneLoading(true);
      try {
        const _zoneOptions = await getZones(customerId, page);
        const options = _zoneOptions?.data.map((item) => ({
          _uid: item.zone_id,
          r_zone_name: item.r_zone_name,
        })) || [];

        setZoneOptions(options);

        // Set the first option as the default value if options are available
        if (options.length > 0) {
          setZoneValue(options[0]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setZoneLoading(false);
      }
    };

    loadZoneOptions();
  }, [customerId, page]);

  useEffect(() => {
    // Ensure that zoneValue is updated when zoneOptions changes
    if (zoneOptions.length > 0 && !zoneValue) {
      setZoneValue(zoneOptions[0]);
    }
  }, [zoneOptions]);

  useEffect(() => {
    console.log('zoneValue:', zoneValue);
    console.log('zoneOptions:', zoneOptions);
  }, [zoneValue, zoneOptions]);

  return (
    <Autocomplete
      size="small"
      value={zoneValue}
      key={zoneOptions.length} // Force re-render when zoneOptions changes
      fullWidth
      disableCloseOnSelect
      onChange={(event, newValue, reason, details) => {
        setZoneValue(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = zoneOptions.some(
          (option) => inputValue.toLowerCase() === option.r_zone_name.toLowerCase()
        );
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={zoneOptions}
      loading={zoneLoading}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.r_zone_name;
      }}
      isOptionEqualToValue={(option, value) => option._uid === value._uid}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.r_zone_name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select zones"
          label="Zone"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

