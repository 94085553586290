export const mapColumnDefinitionToStore = columnCofing => {
    return columnCofing.map((item, index) => {
        return {
            colId: item.colId,
            width:item.width,
            hide: item.hide,
            order: index,
            pinned: item.pinned,
        };
    });
};

export const getSortedColumnDef = (columnDef, stored) => {
    return columnDef.reduce((a, b) => {
        const a1 = stored.find((e) => e.colId === b.colId);
        return a.concat(Object.assign(b, a1));
    }, []).sort((a, b) => a.order - b.order);
}