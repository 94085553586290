import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState,useContext } from 'react';
import Backupdialog from './Backupdialog';
import { UserContext } from '../../../../context/UserContext';
import {viewDetails} from '../../../../API/S3/ConfigEvents'

const Backuprendere = (props) => {
  const { data } = props;
  const { customerId } = useContext(UserContext);
  const [openChonkyDialog, setOpenChonkyDialog] = useState(false);
  const [backupDetails, setBackupDetails] = useState(null);

  const handleClickOpen = () => {
    setOpenChonkyDialog(true);
    viewDetails(customerId, data?.cmdb_backup_id).then((res) => {
      
      setBackupDetails(res);
    }).catch((error) => {
      console.error("Error fetching backup details:", error);
    });
  };
  

  if (data === undefined) {
    return '';
  }
  if (data?.file_exists === false) {
    return <Box>{data?.r_failed_reason}</Box>;
  }
if(data?.file_exists === true){  
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Tooltip title="View backup">
        <IconButton onClick={handleClickOpen}>
          <DescriptionOutlinedIcon />
        </IconButton>
        </Tooltip>
        {openChonkyDialog && (
          <Backupdialog openDialog={openChonkyDialog} data={data} backupDetails ={backupDetails} handleClose={() => setOpenChonkyDialog(false)} />
        )}
      </Box>
    );
}
  return 'Not available';
};


Backuprendere.propTypes = {
  data: PropTypes.object,
};

export default Backuprendere;
