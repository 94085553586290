
import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import DeviceDownComponent from '../components/DeviceDownComponents/DeviceDownComponent';

// ----------------------------------------------------------------------

export default function DeviceDown() {
  return (
    <Page title="Device Down">
      <Container maxWidth={false}>
        <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{pb:1.5, cursor: 'pointer'}}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" sx={{ cursor: 'pointer'}} color="inherit" to="/dashboard/inventory">
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" sx={{ cursor: 'pointer'}} color="inherit" to="/dashboard/network-device">
              Network Devices
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ pointerEvents: 'none' }} to="/">
              Device Down
            </Typography>
          </Breadcrumbs>
        </div>
        <DeviceDownComponent />
      </Container>
    </Page>
  );
}
