

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Skeleton,
  Box,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/UserContext';
import { getDownDeviceByType } from '../../API/NetworkDevices/networkDevices';
import NoDataComponent from 'src/components/NoDataComponent';
import { useNavigate } from 'react-router-dom';
import cardStyles from 'src/styles/cardsstyles';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0),
  height: 'auto',
  overflowY: 'auto',
  overflowX: 'auto',

}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    height: 300,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const DownDevicesByTypeChart = (props) => {
  const { locationData,  openedOnce } = props;
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [upCount, setUpCount] = useState([])
  const [downCount, setDownCount] = useState([])
  const [showGraph, setShowGraph]  = useState(true)

  const [chartLoading, setChartLoading] = useState(true);
  const fetchDeviceByType = async () => {
    const locations = locationData.map((item) => item.location_id);
    try {
      const res = await getDownDeviceByType(customerId, locationData);

      const toArray = res
        .map((item) => ({
          location: item.r_location_name || 'Unknown Location',
          upCount: parseInt(item.networkDeviceUpCount, 10) || 0,
          downCount: parseInt(item.networkDeviceDownCount, 10) || 0,
          locationId: item.location_id || null,
        }))
        .sort((a, b) => b.downCount - a.downCount);

      setLabels(toArray.map((item) => item.location));
      setSeries([
        { name: 'Up', data: toArray.map((item) => item.upCount) },
        { name: 'Down', data: toArray.map((item) => item.downCount) },
      ]);
      setLocationIds(toArray.map((item) => item.locationId));
      setChartLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (openedOnce) {
      fetchDeviceByType();
    }
  }, [customerId, locationData, openedOnce]);

  const handleDataPointClick = (event) => {
    const locationId = locationIds[event.point.index];
    const seriesName = event.point.series.name;
    if (locationId) {
      if (seriesName === 'Up') {
        navigate(`device-up?locationUuid=${locationId}`);
      } else if (seriesName === 'Down') {
        navigate(`device-down?locationUuid=${locationId}`);
      }
    }
  };


  const options = {
    chart: {
      type: 'bar',
      height: Math.max(300, labels.length * 40),
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: labels,
      gridLineWidth: 0,
     
    },
    yAxis: {
      visible: false, 
      min: 0,
      visible: false,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        cursor: 'pointer',
        point: {
          events: {
            click: handleDataPointClick,
          },
        },
        events: {
          legendItemClick: function () {
            const chart = this.chart;
            const clickedSeries = this;
  
            if (clickedSeries.visible) {
              clickedSeries.setVisible(false, false);
            } else {
              clickedSeries.setVisible(true, false);
            }
  
            chart.series.forEach(serie => {
              serie.update({
                opacity: serie === clickedSeries ? 1 : 0.3
              }, false);
            });
  
            chart.redraw();
  
            return false;
          }
        }
      },
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '15px',
      },
      itemHiddenStyle: {
        color: '#888',
      },
      itemClick: function () {
        const selectedSeries = this;
  
        this.chart.series.forEach(function (series) {
          if (series === selectedSeries) {
            series.setVisible(!series.visible, false);
          } else {
            series.setVisible(true, false);
          }
        });
        this.chart.redraw();
        return false;
      },
    },
    series: [
      {
        name: 'Down',
        data: series[1]?.data || [],
        color: '#F44336',
      },
      {
        name: 'Up',
        data: series[0]?.data || [],
        color: '#4CAF50',
      },
    ],
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
  };
  

  return (
    <Card elevation={10} sx={{ overflow: 'hidden' }}>
      <CardHeader
        sx={{ py: 2, px: 2 }}
        color="text.error"
        title={'Devices by Location'}
        avatar={<ShareIcon className={classes.boxTitleIcon} />}
        action={
          <IconButton onClick={() => setShowGraph(!showGraph)}>
            {showGraph ? (
              <KeyboardArrowUpIcon sx={{ fontSize: '1.8rem' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ fontSize: '1.8rem' }} />
            )}
          </IconButton>
        }
      />
      {showGraph && (
        <CardContent sx={{ maxHeight: 425, overflow: 'auto' }}>
          {series[0]?.data.length === 0 && !chartLoading ? (
            <Box className={classes.noDataContainer}>
              <NoDataComponent noDataFoundText="Devices by Location" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">
              {chartLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  className={classes.skeletonLoader}
                />
              ) : (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
      )}
    </Card>

  );
};

export default DownDevicesByTypeChart;
