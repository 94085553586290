import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../../components/Page';
import { DeveloperDashboard } from '../../components/DeveloperConsole';
// ----------------------------------------------------------------------

export default function DeveloperConsole() {
  return (
    <Page title="Inventory">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/system-configuration/inventory">
              System configuration
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/system-configuration/developer-console">
              Developer-console
            </Typography>
          </Breadcrumbs>
        </div>
        <DeveloperDashboard />
      </Container>
    </Page>
  );
}
