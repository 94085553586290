import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, LinearProgress, Link, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ForgotPassword } from '../../../API/UserAuth/ForgotPassword';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email must be a valid email address'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = async (value) => {
    const payload = {
      email: value.email,
    }
    setLoading(true);
    await ForgotPassword(payload)
    .then((res) => {
      setLoading(false);
      reset(res);
      enqueueSnackbar('Reset password link has been sent successfully to your Email-Id',
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }, 500);
    })
    .catch((res) => {
      setLoading(false);
      enqueueSnackbar(
        res.response.data.message,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        500
      );
    });
  };


  return (
    <>
      {loading && <LinearProgress style={{ width: '100%' }} />}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Stack spacing={1} sx={{ py: 1 }}>
            <RHFTextField name="email" label="Email address *" placeholder="Enter email address"  autoComplete='off'/>

            <Box sx={{textAlign:'right'}}>
              <Link variant="subtitle2" underline="hover" href="/">
                Login here
              </Link>
            </Box>
          </Stack>

          <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
}
