// import { Close } from '@mui/icons-material';
// import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Slide, Stack, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { forwardRef, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { ChonkyExpForTable } from './ChonkyExpForTable';
// import {viewBackUpDetails} from '../../../API/S3/ConfigEvents'

// //---------------------------------------------------------------------
// const Transition = forwardRef((props, ref) => {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const useStyles = makeStyles({
//   topScrollPaper: {
//     alignItems: 'flex-start',
//   },
//   topPaperScrollBody: {
//     verticalAlign: 'top',
//   },
//   fileNameBox: {
//     width: '100%',
//     padding: '10px',
//     marginBottom: '25px',
//     borderStyle: 'solid',
//     borderWidth: 2,
//     borderRadius: 2,
//     borderColor: '#f5f5f5',
//     fontFamily: 'Sans-serif',
//   },
//   stackContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
// });

// //----------------------------------------------------------------------

// export default function ConfigDialog(props) {
//   const classes = useStyles();
//   const { openDialog, handleClose, data } = props;
//   const [loading, setLoading] = useState(false);
//   const [filePath, setFilePath] = useState('');
//   const navigate = useNavigate();


//   useEffect(() => {    
//     // let fileUrl = data.r_s3_folder_path ;
//     // if (fileUrl.substr(-1) !== '/') fileUrl += '/';

//     setLoading(true);
//     // setFilePath(fileUrl);
//     setLoading(false);
//   }, [])


//   return (
//     <>
//       <Dialog
//         open={openDialog}
//         TransitionComponent={Transition}
//         onClose={handleClose}
//         fullWidth
//         maxWidth="lg"
//         classes={{
//           scrollPaper: classes.topScrollPaper,
//           paperScrollBody: classes.topPaperScrollBody,
//         }}
//       >
//         <DialogTitle>
//           <Stack direction="row" className={classes.stackContainer}>
//             <Box className={classes.root}>
//               <Typography> Config Backups</Typography>
//             </Box>

//             <IconButton onClick={() => {
//               navigate('/dashboard/config-change-backup-table');
//               handleClose();
//             }}
//             >
//               <Close sx={{ fontSize: '1.5rem' }} />
//             </IconButton>
//           </Stack>
//         </DialogTitle>

//         {loading && <LinearProgress />}
//         <Divider />

//         <DialogContent sx={{ padding: '0px' }}>
//           {/* {filePath !== '' && <ChonkyExpForTable filePath={filePath} />} */}
//            <ChonkyExpForTable filePath={filePath} data={data} />

//         </DialogContent>
//       </Dialog>
//     </>
//   );
// }


import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Slide, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChonkyExpForTable } from './ChonkyExpForTable';

//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fileNameBox: {
    width: '100%',
    padding: '10px',
    marginBottom: '25px',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#f5f5f5',
    fontFamily: 'Sans-serif',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    padding: '16px',
  },
});

//----------------------------------------------------------------------

export default function ConfigDialog(props) {
  const classes = useStyles();
  const { openDialog, handleClose, data } = props;
  const [loading, setLoading] = useState(false);
  const [filePath, setFilePath] = useState('');
  const navigate = useNavigate();

  useEffect(() => {    
    setLoading(true);
    // setFilePath(fileUrl);
    setLoading(false);
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        PaperProps={{
          style: { maxHeight: '80vh', height: 'auto' }, 
        }}
      >
        <DialogTitle>
          <Stack direction="row" className={classes.stackContainer}>
            <Box className={classes.root}>
              <Typography> Config Backups</Typography>
            </Box>

            <IconButton onClick={() => {
              navigate('/dashboard/config-change-backup-table');
              handleClose();
            }}
            >
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>
        </DialogTitle>

        {loading && <LinearProgress />}
        <Divider />

        <DialogContent className={classes.dialogContent} sx={{ padding: '16px' }}>
          <ChonkyExpForTable filePath={filePath} data={data} />
        </DialogContent>
      </Dialog>
    </>
  );
}
