import PropTypes from "prop-types";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Edit, Wysiwyg, Delete } from "@mui/icons-material";
import { useState } from "react";
import EditDialog from "./EditDialog";

const ActionRenderer = (props) => {
  const { data } = props;
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleEdit = () => {
    setOpenEditDialog(true);
  };

  if (props.data === undefined) {
    return '';
  }

  return (
    <Box>
      <Tooltip title="View config" component={'span'}>
        <IconButton disabled>
          <Wysiwyg />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit" component={'span'}>
        <IconButton onClick={handleEdit}>
          <Edit />
        </IconButton>
      </Tooltip>
      {openEditDialog && (
        <EditDialog
          openDialog={openEditDialog}
          handleClose={() => setOpenEditDialog(false)}
          api={props.api}
          data={data}
        />
      )}
      <Tooltip title="Delete" component={'span'}>
        <IconButton disabled>
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default ActionRenderer;
