import { Avatar, Box, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from "notistack";
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../API/UserAuth/logout';
import Account from '../../Assets/avatar_1.jpg';
import MenuPopover from '../../components/MenuPopover';
import MenuItems from "./MenuItems";
import { UserContext } from '../../context/UserContext/userContext';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function AccountPopover(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { userInfo } = props
  const [open, setOpen] = useState(null);


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    const res = await logOut();
    if (res.status === 200) {
      enqueueSnackbar('Logged out successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }, 500);
      navigate('/logout');
    } else {
      enqueueSnackbar(res.response.data.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }, 500);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={Account} alt="Jay" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

       <MenuItems setOpen={setOpen}/>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Box sx={{ textDecoration: 'none', color: 'black' }}>Logout</Box>
        </MenuItem>
      </MenuPopover>
    </>
  );
}

