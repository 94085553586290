import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { addDcAgentSubnet } from '../../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../../context/UserContext';
import { FormProvider } from '../../../hook-form';
import AddSubnetLocationSelector from './AddSubnetLocationSelector';
import AddSubnetSelector from './AddSubnetSelector';
import AddSubnetZoneSelector from './AddSubnetZoneSelector';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fieldContainer: { minHeight: 290 },
});

export default function AddDcSubnets(props) {
  const { openDialog, handleClose, agentData, handleRefresh } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [dcAgentLoading, setDcAgentLoading] = useState(false);
  const [zoneValue, setZoneValue] = useState(agentData?.zones);
  const [locationValue, setLocationValue] = useState(agentData?.locations || []);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [subnetOptions, setSubnetOptions] = useState([]);
  const [zoneSelectionSource, setZoneSelectionSource] = useState(null);

  const credentialsSchema = Yup.object().shape({
    agentName: Yup.string(),
  });

  const defaultValues = {
    agentName: agentData.r_agent_id,
  };

  const methods = useForm({
    resolver: yupResolver(credentialsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const selectLocations = (value) => {
    setLocationValue(value);
  };

  const _setZoneValue = (value, _source) => {
    setZoneSelectionSource(_source);
    setZoneValue(value);
  };

  const onSubmit = async (formData) => {
    if (selectedSubnets.length < 1) {
      enqueueSnackbar(
        'Atleast one subnet should be selected',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
        500
      );
      return;
    }
    setDcAgentLoading(true)
    const data = {
      subnets: selectedSubnets?.map((item) => item.subnet_id),
      // location_id :locationValue?.map((item)=> item.location_id)
    };
    addDcAgentSubnet(customerId, agentData.agent_id, data)
      .then((res) => {
        handleRefresh();
        setDcAgentLoading(false)
        enqueueSnackbar(
          'Subnet added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      })
      .catch((res) => {
        setDcAgentLoading(false)
        handleRefresh();
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        handleClose();
      });
  };

  const _setDcAgentSubnetOptions = (_subnetOptions) => {
    setSelectedSubnets(_subnetOptions);
    setSubnetOptions(_subnetOptions);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Add Subnets
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent sx={{ py: 3, px: 3 }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} sx={{ width: '550px' }}>
              <FormControl fullWidth size="small">
                <AddSubnetZoneSelector
                  setZoneValue={_setZoneValue}
                  zoneValue={agentData?.zones?.r_zone_name}
                  // zoneValue={zoneValue}
                />
              </FormControl>

              <FormControl fullWidth size="small">
                <AddSubnetLocationSelector
                  zoneSelectionSource={zoneSelectionSource}
                  // zoneValue={zoneValue}
                  // setZoneValue={(_zone) => _setZoneValue(_zone, 'location')}
                  setLocationValue={selectLocations}
                  locationValue={locationValue}
                  setDcAgentSubnetOptions={_setDcAgentSubnetOptions}
                />
              </FormControl>
            </Stack>
            <FormControl fullWidth size="small">
              <AddSubnetSelector
                setSelectedSubnets={setSelectedSubnets}
                subNetOptions={subnetOptions}
                selectedSubnets={selectedSubnets}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="textOnly" color="primary" sx={{ float: 'right', color: '#2065D1' }} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ float: 'right' }}
            variant="outlined"
            color="primary"
            loading={dcAgentLoading}
            // type="submit"
            // onClick={()=>{ handleSubmit(onSubmit); handleClose()}}
            onClick={handleSubmit(onSubmit)}
          >
            Add Subnets
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
