import { useState } from 'react';
// @mui
import {
  Card,
  Grid, Button,
  Paper, Box,
  Typography,
  CardHeader,
} from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import Lottie from 'lottie-react';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../../../chart';
// components
import Animation1 from '../../../../Assets/75702.json';
// components
import ISPBad1SubCards from './ISPBad1SubCards';
// sections

// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

const state1 = {
  series: [
    {
      name: 'series1',
      data: [11, 80, 20, 10, 62, 21, 68],
    },
  ],
  options: 
  {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    yaxis: {
      title: {
        text: 'Milliseconds'
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    responsive: [
      {
        breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: "100%"
              },
              legend: {
                show: false
              }
            }
      }
    ]
  },
};

const state2 = {
  series: [{
    name: "Desktops",
    data: [1, 1, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1]
  }],
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'stepline',
        },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0
      },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
    },
    responsive: [
      {
        breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: "100%"
              },
              legend: {
                show: false
              }
            }
      }
    ]
  },
};

const state3 = {
  series: [
    {
      name: 'series1',
      data: [11, 80, 20, 10, 62, 21, 68],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      title: {
        text: 'Date and Time'
      },
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    yaxis: {
      title: 
      {
        text: 'Bandwidth'
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    responsive: [
      {
        breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: "100%"
              },
              legend: {
                show: false
              }
            }
      }
    ]
  },
};

export default function ISPBad1Card1() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const state0 = [
    {
      name: 'Latency',
      type: 'area',
      fill: 'gradient',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: 'Jitter',
      type: 'line',
      fill: 'solid',
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ];

  const chartLabels = [
    '01/01/2003',
    '02/01/2003',
    '03/01/2003',
    '04/01/2003',
    '05/01/2003',
    '06/01/2003',
    '07/01/2003',
    '08/01/2003',
    '09/01/2003',
    '10/01/2003',
    '11/01/2003',
  ];
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: state0.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });


  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15}>
          <div className={classes.titleStyle}>
            <Typography>
              Airtel - 23.12.222.22 :{' '}
              <Typography sx={{ color: '#d32f2f' }} component={'span'}>
                UP Bad
              </Typography>{' '}
              4{' '}
              <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
                days
              </Typography>
              , 18{' '}
              <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
                hours
              </Typography>
            </Typography>
          </div>

          <ISPBad1SubCards />

          <Grid container spacing={3} sx={{px:2,py:2}}>
            <Grid item xs={8} sm={12} md={12} lg={8}>
              <Card variant="outlined" elevation={15}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency/jitter" />
                  <Tab label="Packet loss" />
                  <Tab label="Up/Down" />
                  <Tab label="Utilization" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <ReactApexChart options={state2.options} series={state2.series} type="line" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box>
                    <ReactApexChart options={state3.options} series={state3.series} type="area" height={350} />
                  </Box>
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={3} sm={12} md={12} lg={3}>
                <Paper elevation={15} sx={{ px: 2, py: 2, height: 415 }}>
                  <Typography variant="h5" gutterBottom color="text.primary">
                    DG rechability
                  </Typography>
                  <Lottie animationData={Animation1} loop autoPlay id="anime1" style={{ height: '300px' }} />
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" sx={{ fontSize: '1rem' }}>
                      Test Here
                    </Button>
                  </Box>
                </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
