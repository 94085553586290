export const toCamelizeWords = (string) => {
  if (string === "WIRELESS_VIRTUAL_CONTROLLER") {
    return "Wireless LAN Controller"
  }
  try {
    const newString = string.replaceAll("_", " ").toLowerCase().split(" ").map((i) => (i.charAt(0).toUpperCase() + i.slice(1))).join(" ")
    return newString
  } catch (error) {
    return string
  }
}


export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {

    let since = `${Math.floor(interval)} years`;
    interval = (seconds % 31536000) / 2592000;
    if (interval > 1) {
      since += ` ${Math.floor(interval)} months`;
    }
    return since
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    let since = `${Math.floor(interval)} months`;
    interval = (seconds % 2592000) / 86400;
    if (interval > 1) {
      since += ` ${Math.floor(interval)} days`;
    }
    return since
  }

  interval = seconds / 86400;
  if (interval > 1) {
    let since = `${Math.floor(interval)} days`;
    interval = (seconds % 86400) / 3600;
    if (interval > 1) {
      since += ` ${Math.floor(interval)} hours`;
    }
    return since
  }
  interval = seconds / 3600;
  if (interval > 1) {
    let since = `${Math.floor(interval)} hours`;
    interval = (seconds % 3600) / 60;
    if (interval > 1) {
      since += ` ${Math.floor(interval)} minutes`;
    }
    return since

  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
};