
import { Grid } from '@mui/material';
import DeviceTypesTable from './components/DeviceTypesTable';
// -------------------------------------------------------------------------------------------



// ----------------------------------------------------------------------------------------------

const DeviceTypesComponent = () => {


  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12} sm={12}>
        <DeviceTypesTable/>
      </Grid>
    </Grid>
  );
};
export default DeviceTypesComponent;
