// @mui
import { styled } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Container, Stack, Grid, Breadcrumbs, Typography, Divider } from '@mui/material';
import Page from '../components/Page';
import ChangeProfileInfoForm from '../sections/auth/settings/ChangeProfileInfoForm';
import ChangePassword from '../sections/auth/settings/ChangePassword';
// import TwoFactor from '../sections/auth/settings/TwoFactor';
// import DeleteAccount from '../sections/auth/settings/DeleteAccount';
// import BrowserSessions from '../sections/auth/settings/BrowserSessions';
import { getUser } from '../API/User/User';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  display: 'flex',
}));

// ----------------------------------------------------------------------

export default function Settings() {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    getUser().then((res) => {
      setUserInfo(res.user);
    });
  };

  return (
    <Page title="Settings">
      <RootStyle>
        <Container maxWidth={false}>
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px'}}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
              <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                <HomeOutlinedIcon 
                sx={{
                  fontSize: {
                    xs: '20px',
                    sm: '22px',
                    md: '24px',
                    lg: '26px',
                    xl: '28px',
                    xxl: '30px',
                  },
                }}/>
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/settings"
              sx={{
                fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                },
              }}>
                Settings
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/settings"
              sx={{
                fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                },
              }}>
                My Profile
              </Typography>
            </Breadcrumbs>
            <Divider sx={{ marginTop: '15px', width: '100%' }} />
          </div>

          <>
            <Stack spacing={2} maxWidth="100%">
              <Grid container item spacing={2}>
                <Grid item lg={6} sm={12} xs={12} md={6}>
                  <Paper sx={{ px: 2, py: 1, minHeight: '310px' }} elevation={2}>
                    <ChangeProfileInfoForm userInfo={userInfo} />
                  </Paper>
                </Grid>
                <Grid item lg={6} sm={12} xs={12} md={6}>
                  <Paper sx={{ px: 2, py: 1, minHeight: '310px' }} elevation={2}>
                    <ChangePassword />
                  </Paper>
                </Grid>
              </Grid>

              {/* <Paper sx={{px:2,py:1}} elevation={2}>
                <TwoFactor />
              </Paper> */}

              {/* <Paper sx={{px:2,py:1}} elevation={2}>
                <BrowserSessions />
              </Paper> */}

              {/* <Paper sx={{px:2,py:1}} elevation={2}>
                <DeleteAccount />
              </Paper> */}
            </Stack>
          </>
        </Container>
      </RootStyle>
    </Page>
  );
}
