import { TestbedInstance ,instance as modem } from '../modem';
import clientCredentials from "../api_endpoints"

export const customer = async () => {
  const endpoint = clientCredentials.apiHost;
  return modem.get(`${endpoint}/user/allocated_customers`)
    .then((res) => {
      return res.data.data;
    })
    .catch((res) => {
      throw res;
    });
};
