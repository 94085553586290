import { makeStyles } from '@mui/styles';

const cardStyles = makeStyles({
  boxContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitleIcon: {
    fontSize: '2rem',
    color: '#81d4fa',
    backgroundColor: '#e1f5fe',
    borderRadius: '5px',
    // marginLeft: '15px',
    marginTop: '5px',
    width: '1.5em',
    height: '1.5em',
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#81d4fa',
      color: '#e1f5fe',
    },
  },
  boxTitle: {
    width: '70%',
    paddingTop: '15px',
    paddingLeft: '10px',
    flexGrow: 1,
  },
  inventoryNoDataBox:{
    width: '100%',
    height: '690px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  vendorChartActionArrowButton:{
    justifyContent: 'center', 
    marginTop: '10px',
    marginRight:'5px',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 25,
    top: 22,
  },
});
export default cardStyles;
