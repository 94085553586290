

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { updateLocation } from '../../../API/Locations/showLocations';
import { getZones } from '../../../API/Zones/zones';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';
import ZoneSelector from './ZoneSelector';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function EditLocationDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, api, locationData } = props;
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [zoneValue, setZoneValue] = useState(null);
  const [zoneData,setZoneData] = useState(null)
  const [page, setPage] = useState(0);

  useEffect(() => {
    setSelectedSubnets(
      locationData.subnets?.map((item) => {
        return { id: item.uuid, name: item.r_subnet_value };
      })
    );
    setZoneData({r_zone_name:locationData?.r_zone_name || '', zone_id:locationData?.zone_id || ''})
    if (locationData.zone) {
      setZoneValue({ id: locationData.zone_id, r_zone_name: locationData.zone.r_zone_name });
    }
    setZoneLoading(true);
    getZones(customerId, page)
      .then((res) => {
        setZoneOptions(res.data);
        setZoneLoading(false);
        setPage(res.current_page - 1);
        setZoneValue(res.data)
      })
      .catch(() => {
        setZoneLoading(false);
      });
  }, []);

  // Regex patterns
  const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;
  const locationNameRegex = /^.*[A-Za-z0-9]$/;
  const phoneNumberRegex = /^\d{10}$/;
  const locationAddressRegex = /^[A-Za-z0-9\s,.'-]{3,}$/;
  const cityRegex = /^[A-Za-z\s'-]+$/; 
  const countryRegex = /^[A-Za-z\s'-]+$/;

  const membersSchema = Yup.object().shape({
    locationName: Yup.string()
      .matches(locationNameRegex, 'Invalid location name format')
      .required('Location name required'),
    locationAddress: Yup.string()
      .matches(locationAddressRegex, 'Invalid address format'),
    locationContact: Yup.string()
      .matches(phoneNumberRegex, 'Invalid phone number format'),
      city: Yup.string()
      .matches(cityRegex, 'Invalid city format')
      .required('City is required'),
    country: Yup.string()
      .matches(countryRegex, 'Invalid country format')
      .required('Country is required'),
  });

  const defaultValues = {
    locationName: locationData?.r_location_name || '',
    locationAddress: locationData?.r_location_address || '',
    locationContact: locationData?.r_location_contact || '',
    city: locationData?.city || '',
    country: locationData?.country || '',
    zoneData:locationData?.r_zone_name || '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      name: value.locationName,
      subnets: selectedSubnets?.map((item) => item.subnet_id),
      address: value.locationAddress,
      contact: value.locationContact,
      city: value.city,
      country: value.country,
      zone_uuid: zoneValue?.id ?? null, 
    };
    
    setLoading(true);
    updateLocation(customerId, locationData.location_id, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        api.api.purgeInfiniteCache(); 
        enqueueSnackbar(
          'Location updated successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };


  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Edit location
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <Stack spacing={3}>
              <RHFTextField name="locationName" label="Name" placeholder="Enter location name"/>

              <RHFTextField name="locationAddress" label="Location address" placeholder="Enter location address" />

              <RHFTextField name="locationContact" label="Location contact" placeholder="Enter location contact" />
              <RHFTextField name="city" label="Enter City *" placeholder="Enter your City" />
              <RHFTextField name="country" label="Enter Country *" placeholder="Enter Your Country" />
              <ZoneSelector
                zoneOptions={zoneOptions}
                zoneLoading={zoneLoading}
                setZoneValue={setZoneValue}
                zoneValue={zoneValue}
                zoneData={zoneData}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
