// @mui
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import SdwanCards from '../components/ISPManagementTable/SdwanCards';
import SdwanCard1 from '../components/ISPManagementTable/SdwanCard1';
import SdwanCard2 from '../components/ISPManagementTable/SdwanCard2';
// sections

// ----------------------------------------------------------------------

export default function Sdvan() {
  
  return (
    <Page title="SDVAN">
      <Container maxWidth={false}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
              Linkeye Basestation
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
              IPS Management
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/sdwan">
              SDWAN
            </Typography>
          </Breadcrumbs>
        </div>

        <Grid>
          <Grid container spacing={4} sx={{py:1}}>
              <SdwanCards />
          </Grid>

          <Grid item lg={12} md={12} sm={12} sx={{py:2  }}>
            <hr />
            <br />
            <LocationSelect />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{py:1}}>
              <SdwanCard1 />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{py:2}}>
              <SdwanCard2/>
          </Grid>

        </Grid>

      </Container>
    </Page>
  );
}
