// import React , { useState } from 'react'
// import { Breadcrumbs, Container, Typography ,Grid , Box , Switch} from '@mui/material';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import { Link } from 'react-router-dom';
// import Page from '../components/Page';
// import Wifi from '../components/WifiComponents/Wifi'

// const wifi = () => {
//   const [isWanSelected] = useState(false);

//     return (
//         <Page title="Wi-Fi CoPilot">
//           <Container maxWidth={false}>
//             <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative', paddingBottom: '20px' }}>
//               <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}>
//                 <Typography component={Link} color="inherit" to="/dashboard/wifi-events" sx={{ pb: 1.5, cursor: 'pointer'}}>
//                   <HomeOutlinedIcon 
//                   sx={{
//                     fontSize: {
//                       xs: '20px',
//                       sm: '22px',
//                       md: '24px',
//                       lg: '26px',
//                       xl: '28px',
//                       xxl: '30px',
//                     },
//                   }}/>
//                 </Typography>
//                 <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer',
//               fontSize: {
//                 xs: '8px',
//                 sm: '12px',
//                 md: '14px',
//                 lg: '16px',
//                 xl: '18px',
//                 xxl: '20px',
//               },
//               }} to="/dashboard/wifi-events">
//                   Wifi Copilot
//                 </Typography>
//                 {/* <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer', 
//                 fontSize: {
//                       xs: '8px',
//                       sm: '12px',
//                       md: '14px',
//                       lg: '16px',
//                       xl: '18px',
//                       xxl: '20px',
//                     }, }} to="/dashboard/wifi-events">
//                   Device Up-Down
//                 </Typography> */}
//               </Breadcrumbs>
//             </div>
//             <Grid
//               item
//               xs={6}
//               sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
//             >
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 {/* WAN label */}
//                 <Typography
//                   sx={{
//                     color: 'black',
//                     fontWeight: 'bold',
//                     mr: 1,
//                   }}
//                 >
//                   WAN
//                 </Typography>

//                 <Switch checked={isWanSelected} disabled />

//                 <Typography>
//                   LAN 
//                 </Typography>



//               </Box>
//             </Grid>
    
//             <Wifi />
//           </Container>
//         </Page>
//       );
// }

// export default wifi

import React, { useState } from 'react';
import { Breadcrumbs, Container, Typography, Grid, Box, Switch } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import Wifi from '../components/WifiComponents/Wifi';
import WifiTable from '../components/WifiComponents/WifiTable';

const WifiPage = () => {
  const [isWanSelected, setIsWanSelected] = useState(false);

  const handleToggle = (event) => {
    setIsWanSelected(event.target.checked);
  };

  return (
    <Page title="Wi-Fi CoPilot">
      <Container maxWidth={false}>
        <div
          role="presentation"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingBottom: '20px',
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px', xxl: '20px' } }}
          >
            <Typography
              component={Link}
              color="inherit"
              to="/dashboard/wifi-events"
              sx={{ pb: 1.5, cursor: 'pointer' }}
            >
              <HomeOutlinedIcon
                sx={{
                  fontSize: {
                    xs: '20px',
                    sm: '22px',
                    md: '24px',
                    lg: '26px',
                    xl: '28px',
                    xxl: '30px',
                  },
                }}
              />
            </Typography>
            <Typography
              component={Link}
              underline="hover"
              color="inherit"
              sx={{
                cursor: 'pointer',
                fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                },
              }}
              to="/dashboard/wifi-events"
            >
            CoPilot
            </Typography>
          </Breadcrumbs>
        </div>
        {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ color: 'black', fontWeight: 'bold', mr: 1 }}>WAN</Typography>
            <Switch checked={isWanSelected} onChange={handleToggle} />
            <Typography>LAN</Typography>
          </Box>
        </Grid> */}

        <WifiTable isWanSelected={isWanSelected} />
      </Container>
    </Page>
  );
};

export default WifiPage;
