/* eslint-disable */
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  Download,
  Refresh,
  Search,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getInventory } from '../../../API/NetworkDevices/networkDevices';
import { getUser } from '../../../API/User/User';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import { timeSince, toCamelizeWords } from '../../../utils/formatString';
import { fDateTimeForDownloadCsv } from '../../../utils/formatTime';
import ArrayToCSVdownload from '../../ArrayToCSVdownload';
import DeviceUptimeHeader from './DeviceUptimeHeader';
import ColumnSettingsDialog from './filters/ColumnSettingsDialog';
import { ActionRender, IpRenderer, VulnerableRenderer } from './renderer';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material';

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
    minWidth: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'left',
  },
  progressStyle: {
    position: 'absolute',
    left: 13,
    top: 18,
  },
}));

// ----------------------------------------------------------------------

const InventoryTable = (props) => {
  const { locationData, deviceTypes, setDeviceTypes, setVendorsType, vendorsType } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [filterModel, setFilterModel] = useState('');
  const [sortModel, setSortModel] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const refreshStats = useSelector((state) => state.statCounter.refreshStats);
  let controller = new AbortController();
  const locationDataRef = useRef(locationData);

  useEffect(() => {
    locationDataRef.current = locationData;
    
  }, [locationData]);

  const theme = useTheme();
  const is1024px = useMediaQuery(theme.breakpoints.only('md'));
  const is1440px = useMediaQuery('(min-width:1440px) and (max-width:2559px)');
  const is2560px = useMediaQuery('(min-width:2560px)');

  const fontSize = is2560px ? '20px' : is1440px ? '16px' : is1024px ? '14px' : '12px';

  useEffect(() => {
    if (gridApi) {
      if (controller) {
        controller.abort();
        controller = new AbortController();
      }
      gridApi.purgeInfiniteCache();
    }
  }, [refreshStats, locationData]);

  useEffect(() => {
    if (navigator.onLine) {
      getUser().then((res) => {
        setUserCustomer(res.customer?.name);
      });
    }
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
        deviceType: deviceTypes,
        vendor: vendorsType,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, deviceTypes, vendorsType]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-inventory-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const isUndefined = (value, def = '') => {
    return value === undefined || value == null || value.length <= 0 || value === '' ? def : value;
  };

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'id',
      width: 90,
      // pinned: 'left',
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        const id = params.node.id;
        if (params.node.id === undefined) {
          return '';
        }
        if (params.node.id === 0) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      sortable: true,
      width: 200,
      // pinned: 'left',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: 'ipRenderer',
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      floatingFilter: false,
      width: 200,
      // pinned: 'left',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_location_name;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_name;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Vendor Model',
      colId: 'r_dvc_model',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        // const l_dvc_typ_vendor = params.data?.r_dvc_model?.indexOf(params.data?.l_dvc_typ_vendor) === -1 || !params.data?.r_dvc_model ? params.data?.l_dvc_typ_vendor : ""
        const r_dvc_model = params.data?.r_dvc_model ?? ""
        return ` ${r_dvc_model}`;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_mac_address;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_serial_number;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'Vendor',
    //   colId: 'l_dvc_typ_vendor',
    //   sortable: false,
    //   width: 200,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   filterParams: {
    //     filterOptions: ['CONTAINS'],
    //     defaultOption: 'CONTAINS',
    //     suppressAndOrCondition: true,
    //     debounceMs: 300,
    //   },
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data.l_dvc_typ_vendor;
    //   },
    //   cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    // },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      sortable: true,
      width: 200,
      // pinned: 'right',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.l_dvc_typ_name;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Firmware version',
      colId: 'r_dvc_firmware',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_firmware;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'EOS',
    //   colId: 'r_dvc_eos',
    //   sortable: true,
    //   width: 200,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data?.r_dvc_eos ? params.data?.r_dvc_eos : 'Not Announced';
    //   },
    //   cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    // },
    // {
    //   headerName: 'EOL',
    //   colId: 'r_dvc_eol',
    //   sortable: true,
    //   width: 200,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data?.r_dvc_eol ? params.data?.r_dvc_eol : 'Not Announced';
    //   },
    //   cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    // },
    // {
    //   headerName: 'Vulnerable',
    //   colId: 'r_dvc_is_vulnerable',
    //   sortable: true,
    //   width: 200,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   filterParams: {
    //     filterOptions: ['CONTAINS'],
    //     defaultOption: 'CONTAINS',
    //     suppressAndOrCondition: true,
    //     debounceMs: 300,
    //   },
    //   cellRenderer: 'vulnerableRenderer',
    //   cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    // },
    {
      headerName: 'Description',
      colId: 'r_dvc_description',
      floatingFilter: false,
      width: 200,
      filter: false,
      sortable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_description;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'Information',
    //   colId: 'r_dvc_error',
    //   floatingFilter: false,
    //   width: 200,
    //   filter: false,
    //   sortable: false,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   filterParams: {
    //     filterOptions: ['CONTAINS'],
    //     defaultOption: 'CONTAINS',
    //     suppressAndOrCondition: true,
    //     debounceMs: 300,
    //   },
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data?.r_dvc_error;
    //   },
    //   cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    // },
    {
      headerName: 'Device Uptime',
      colId: 'r_dvc_uptime',
      sortable: false,
      width: 200,
      headerComponent: DeviceUptimeHeader,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'down') {
          return '';
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'up') {
          return params.data?.r_dvc_uptime;
        }
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Status',
      colId: 'r_dvc_up_down',
      sortable: true,
      filter: false,
      width: 200,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'down') {
          const inactiveSince = `Since ${timeSince(params?.data?.r_dvc_status_inactive_since * 1000)}`;
          let since = '';
          if (params?.data?.r_dvc_last_up_ts) {
            since = `Since : ${timeSince(params?.data?.r_dvc_last_up_ts * 1000)}`;
          }
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Box>
                <Chip
                  label="Down"
                  variant="outlined"
                  avatar={<ArrowCircleDownOutlined style={{ color: '#f44336' }} />}
                />
              </Box>
              <Tooltip title={(params.data.r_dvc_last_up_ts)}>
                <Typography sx={{ paddingLeft: '8px' }}>{since}</Typography>
              </Tooltip>

              <Box>
                {params?.data?.g_status?.toLowerCase() === 'inactive' ? (
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip title={inactiveSince}>
                      <Chip label="Inactive" variant="outlined" />
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          );
        }
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'up') {
          const inactiveSince = `Since ${timeSince(params?.data?.r_dvc_status_inactive_since * 1000)}`;
          return (
            <Box sx={{ gap: '8px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Box>
                <Chip label="Up" variant="outlined" avatar={<ArrowCircleUpOutlined style={{ color: '#4caf50' }} />} />
              </Box>
              <Box>
                {params?.data?.g_status?.toLowerCase() === 'inactive' ? (
                  <div>
                    <Tooltip title={inactiveSince}>
                      <Chip label="Inactive" variant="outlined" />
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          );
        }
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Actions',
      colId: 'action',
      // pinned: 'right',
      width: 300,
      filter: false,
      sortable: false,
      cellRenderer: 'actionRender',
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
      pinned: 'right',  
      lockPosition: true,  
      editable: false,  
      sortable: false,  
      filter: false,  
      resizable: false, 
      suppressMovable: true,  
    },
  ];

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };


  const onGridReady = useCallback((params) => {

    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
        deviceType: deviceTypes,
        vendor: vendorsType,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: locationDataRef.current,
          deviceType: _params.context.deviceType,
          vendor: _params.context.vendor,
          filterModel: {
            ..._params.filterModel,
          },
        };
        if (_params.context.vendor) {
          payload.filterModel.l_dvc_typ_vendor = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.vendor,
          };
        }

        if (_params.context.deviceType) {
          payload.filterModel.l_dvc_typ_name = {
            filterType: 'text',
            type: '=',
            filter: _params.context.deviceType === "Unknown" ? "" : _params.context.deviceType,
          };
        }

        delete payload.context;
        setFilterModel(payload.filterModel);
        setSortModel(_params.sortModel);
        getInventory(customerId, payload, controller).then((inventoryData) => {
          
          
          let lastRow = -1;
          if (inventoryData.total <= _params.endRow) {
            lastRow = inventoryData.total;
          }
          setTotalItems(inventoryData.total);
          if (inventoryData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(inventoryData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch((e) => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [query, deviceTypes, vendorsType, getInventory, customerId, controller]);
 
  const onBtnExport = () => {
    setDownloadLoading(true);
    const inventory = [];
    const pages = Math.ceil(totalItems / 200);
  
    const promises = Array(pages)
      .fill()
      .map((item, index) => {
        const startIndex = index * 200;
        const endIndex = Math.min((index + 1) * 200, totalItems+1);
        const payload = {
          endRow: endIndex,
          startRow: startIndex,
          location_uuids: locationData,
          deviceType: deviceTypes,
          global_keyword: query,
          sortModel,
          filterModel,
        };
        return getInventory(customerId, payload);
      });
  
    const allowedColumns = columnDefs.filter((def) => !def.hide).map((def) => def.colId);
  
  
    try {
      Promise.all(promises)
        .then((res) => {
          res.forEach((item, index) => {
            console.log(`Page ${index + 1} returned ${item.data?.length || 0} records`);
            item.data?.forEach((device) => {
              const record = {};
              allowedColumns.forEach((column) => {
                switch (column) {
                  case 'r_dvc_ip_address':
                    record['IP Address'] = device?.r_dvc_ip_address ?? '';
                    break;
                  case 'r_location_name':
                    record['Location'] = device?.r_location_name ?? '';
                    break;
                  case 'r_dvc_name':
                    record['Device Name'] = device?.r_dvc_name ?? '';
                    break;
                  case 'r_dvc_model':
                    record['Device Model'] = device?.r_dvc_model ?? '';
                    break;
                  case 'r_dvc_mac_address':
                    record['Mac Address'] = device?.r_dvc_mac_address ?? '';
                    break;
                  case 'r_dvc_serial_number':
                    record['Serial Number'] = device?.r_dvc_serial_number ?? '';
                    break;
                  case 'l_dvc_typ_vendor':
                    record['Vendor'] = device?.l_dvc_typ_vendor ?? '';
                    break;
                  case 'l_dvc_typ_name':
                    record['Device Type'] = device?.l_dvc_typ_name ?? '';
                    break;
                  case 'r_dvc_firmware':
                    record['Firmware Version'] = device?.r_dvc_firmware ?? '';
                    break;
                  case 'r_dvc_eos':
                    record['EOS'] = device?.r_dvc_eos || 'Not Announced';
                    break;
                  case 'r_dvc_eol':
                    record['EOL'] = device?.r_dvc_eol || 'Not Announced';
                    break;
                  case 'r_dvc_is_vulnerable':
                    record['Vulnerable'] = device?.r_dvc_is_vulnerable || 'No';
                    break;
                  case 'r_dvc_description':
                    record['Description'] = device?.r_dvc_description ?? '';
                    break;
                  case 'item.r_dvc_error':
                    record['Information'] = device?.r_dvc_error ?? '';
                    break;
                  case 'r_dvc_uptime':
                    record['Uptime'] = device?.r_dvc_uptime ?? '';
                    break;
                  default:
                    break;
                }
              });
              inventory.push(record);
            });
          });
        })
        .then(() => {
          if (inventory.length > 0) {
            const CSV_SEPARATER = ',';
            ArrayToCSVdownload({
              data: inventory,
              headers: Object.keys(inventory[0]),
              delimiter: CSV_SEPARATER,
              filename: `NetworkDevices-${userCustomer}-${fDateTimeForDownloadCsv(new Date())}.csv`,
            });
          }
  
          setDownloadLoading(false);
          enqueueSnackbar('Downloaded successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        })
        .catch((error) => {
          console.error('Error:', error);
          setDownloadLoading(false);
          enqueueSnackbar('Error occurred while exporting data', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    } catch (error) {
      console.error('Error:', error);
      setDownloadLoading(false);
      enqueueSnackbar('Error occurred while exporting data', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const components = useMemo(() => ({
    vulnerableRenderer: VulnerableRenderer,
    ipRenderer: IpRenderer,
    actionRender: ActionRender,
  }), []);


  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-network-inventory-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  const deleteDeviceTypeSearch = () => {
    setDeviceTypes('');
  };

  const deleteVendorsTypeSearch = () => {
    setVendorsType('');
  };

  // ----------------------------------------------------------------------
  return (
    <Card id="ndTable">
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Chip label={totalItems || 0} />
              {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
            </Grid>
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                Network Devices:
                <span className={classes.titleDown}> {totalItems} </span>
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', gap: 2 }}
            >
              {deviceTypes?.length > 0 ? (
                <Chip
                  label={toCamelizeWords(deviceTypes)}
                  value={deviceTypes}
                  variant="outlined"
                  onDelete={deleteDeviceTypeSearch}
                />
              ) : (
                ''
              )}
              {vendorsType?.length > 0 ? (
                <Chip
                  label={toCamelizeWords(vendorsType)}
                  value={vendorsType}
                  variant="outlined"
                  onDelete={deleteVendorsTypeSearch}
                />
              ) : (
                ''
              )}
              {/* <IconButton size="small">
                <StarIcon color='success' fontSize="inherit" />
                <Typography>WireLess Controller</Typography>
              </IconButton>
              <IconButton size="small">
                <StarIcon sx={{ color: 'orange' }} fontSize="inherit" />
                <Typography>VC Member</Typography>
              </IconButton> */}
              <IconButton size="small">
                <StarIcon color="info" fontSize="inherit" />
                <Typography>VC Master</Typography>
              </IconButton>
              <TextField
                focused
                autoFocus
                type="text"
                size="small"
                value={query}
                variant="outlined"
                name='new-password'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search devices"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Download as CSV">
                  <IconButton color="primary" onClick={onBtnExport}>
                    {downloadLoading ? (
                      <CircularProgress size={27} sx={{ mr: '5px' }} />
                    ) : (
                      <Download sx={{ fontSize: '1.8rem' }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    gridApi={gridApi}
                    columnDefs={columnDefs}
                    openDialog={openColSettingsDialog}
                    resetColumnSetting={resetColumnSetting}
                    handleClose={() => setOpenColSettingsDialog(false)}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />

      <Divider />
      {refreshLoading && <LinearProgress />}

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          components={components}
          onDragStopped={onDragStopped}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData,
            deviceType: deviceTypes,
            vendor: vendorsType,
          }}
        />
      </Box>
    </Card>
  );
};
export default InventoryTable;