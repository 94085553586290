// import { CircularProgress, Checkbox } from '@mui/material';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import { useContext, useEffect, useState } from 'react';
// import { getZones } from '../../../../API/DcAgent/DcAgent';
// import { UserContext } from '../../../../context/UserContext';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// export default function ReConfigZoneSelector(props) {
//   const { customerId } = useContext(UserContext);
//   const { zoneValue, setZoneValue, page } = props;
//   const [zoneOptions, setZoneOptions] = useState([]);
//   const [zoneLoading, setZoneLoading] = useState(true);
//   const filter = createFilterOptions();

//   const loadZoneOptions = async () => {
//     setZoneLoading(true);
//     try {
//       getZones(customerId, page).then((_zoneOptions) => {
//         setZoneOptions(
//           _zoneOptions?.data.map((item) => {
//             return {
//               _uid: item.zone_id,
//               r_zone_name: item.r_zone_name,
//             };
//           })
//         );
//       });
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setZoneLoading(false);
//     }
//   };

//   useEffect(() => {
//     loadZoneOptions();
//   }, []);
// console.log(zoneValue);

//   return (
//     <Autocomplete
//       disabled
//       size="small"
//       value={zoneValue || ''}
//       fullWidth
//       onChange={(event, newValue, reason, details) => {
//         setZoneValue(newValue);
//       }}
//       isOptionEqualToValue={(option, value) => option._uid === value._uid}
//       filterOptions={(options, params) => {
//         const filtered = filter(options, params);
//         const { inputValue } = params;
//         const isExisting = zoneOptions.some(
//           (option) => inputValue.toLowerCase() === option.r_zone_name.toLocaleLowerCase()
//         );
//         // if (inputValue !== '' && !isExisting) {
//         //   filtered.push({
//         //     inputValue,
//         //   });
//         // }
//         return filtered;
//       }}
//       disableCloseOnSelect
//       selectOnFocus
//       clearOnBlur
//       handleHomeEndKeys
//       id="free-solo-with-text-demo"
//       options={zoneOptions}
//       loading={zoneLoading}
//       getOptionLabel={(option) => {
//         // Value selected with enter, right from the input
//         if (typeof option === 'string') {
//           return option;
//         }
//         // Add "xxx" option created dynamically
//         if (option.inputValue) {
//           return option.inputValue;
//         }
//         // Regular option
//         return option.r_zone_name;
//       }}
//       renderOption={(props, option, { selected }) => (
//         <li {...props}>
//           {' '}
//           <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
//           {option.r_zone_name}
//         </li>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           placeholder="Select zones"
//           label="Zone"
//           InputLabelProps={{ shrink: true }}
//           InputProps={{
//             ...params.InputProps,
//             endAdornment: (
//               <>
//                 {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
//                 {params.InputProps.endAdornment}
//               </>
//             ),
//           }}
//         />
//       )}
//     />
//   );
// }
import { CircularProgress, Checkbox } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useContext, useEffect, useState } from 'react';
import { getZones } from '../../../../API/DcAgent/DcAgent';
import { UserContext } from '../../../../context/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ReConfigZoneSelector(props) {
  const { customerId } = useContext(UserContext);
  const { zoneValue, setZoneValue, page } = props;
  const [zoneOptions, setZoneOptions] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(true);
  const filter = createFilterOptions();

  const loadZoneOptions = async () => {
    setZoneLoading(true);
    try {
      getZones(customerId, page).then((_zoneOptions) => {
        setZoneOptions(
          _zoneOptions?.data.map((item) => {
            return {
              _uid: item.zone_id,
              r_zone_name: item.r_zone_name,
            };
          })
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      setZoneLoading(false);
    }
  };

  useEffect(() => {
    loadZoneOptions();
  }, []);

  return (
    <Autocomplete
      disabled
      size="small"
      value={zoneValue || ''}
      fullWidth
      onChange={(event, newValue, reason, details) => {
        setZoneValue(newValue);
      }}
      isOptionEqualToValue={(option, value) => option._uid === value._uid}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = zoneOptions.some(
          (option) => inputValue.toLowerCase() === option.r_zone_name.toLocaleLowerCase()
        );
        return filtered;
      }}
      disableCloseOnSelect
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={zoneOptions}
      loading={zoneLoading}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.r_zone_name;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.r_zone_name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select zones"
          label="Zone"
          InputLabelProps={{ shrink: true }}
          error={!zoneValue}
          helperText={!zoneValue ? "Add valid zone" : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: !zoneValue ? 'red' : '',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
            },
            '& .MuiInputLabel-root': {
              color: !zoneValue ? 'red' : '',
            },
            '& .MuiFormHelperText-root': {
              color: 'red',
            },
            '& .MuiAutocomplete-inputRoot': {
              '& .MuiAutocomplete-input': {
                color: !zoneValue ? 'red' : '',
              },
            },
          }}
        />
      )}
    />
  );
}
