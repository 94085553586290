import { useState } from 'react';
import { Chip, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import StatusDialog from '../StatusDialog';
import { fDateOnly } from '../../../../utils/formatTime';

// ---------------------------------------------------------------------
const useStyles = makeStyles(() => ({}));

// ---------------------------------------------------------------------

const StatusRendere = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [openstatus, setOpenstatus] = useState(false);

  const handleclickstatus = () => {
    setOpenstatus(true);
  };

  const handleclickstatuscolse = () => {
    setOpenstatus(false);
  };

  // -----------------------------------------------------------------------
  if (data === undefined) {
    return '';
  }
  
  if (data?.r_criticality === 'OPEN') {
    return (
      <div className={classes.downHigh}>
        <Chip label="OPEN" color="error" className={classes.criticalChip} onClick={handleclickstatus} />
      </div>
    );
  }
  if (data?.r_criticality === 'APPROVED') {
    return (
      <div>
        <Chip label="APPROVED" color="success" className={classes.criticalChip} onClick={handleclickstatus} />
        {openstatus &&
          <StatusDialog
            open={openstatus}
            handleClose={handleclickstatuscolse}
            data={data}
          />
        }
      </div>
    );
  }
  if (data?.r_criticality === 'UN_APPROVED') {
    return (
      <div>
        <Chip label="UN APPROVED" color="info" className={classes.criticalChip} onClick={handleclickstatus} />
        {openstatus &&
          <StatusDialog
            open={openstatus}
            handleClose={handleclickstatuscolse}
            data={data}
          />
        }
      </div>
    );
  }
  if (data?.r_criticality === 'ON_HOLD') {
    return <div>
     
        <Chip label="ON HOLD" color="warning" className={classes.criticalChip} onClick={handleclickstatus} />
        {openstatus &&
          <StatusDialog
            open={openstatus}
            handleClose={handleclickstatuscolse}
            data={data}
          />
        }
    </div>

  }
};

StatusRendere.propTypes = {
  data: PropTypes.object,
};

export default StatusRendere;
