import { Delete, Edit, Info } from "@mui/icons-material";
import AddTaskIcon from '@mui/icons-material/AddTask';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext, useState } from 'react';
import { deleteMember } from '../../../../API/Members/members';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import PendingMemberModel from "../PendingMemberModel";
import RejectMemberModel from "../RejectMemberModel";
// -------------------------------------------------------------------------------

const PendingActionRenderer = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [pendingMembersData, setPendingMembersData] = useState({});
  const [rejectMembersData, setrejectMembersData] = useState({});

  const [pendingMembersDialog, setPendingMembersDialog] = useState(false);
  const [rejectMembersDialog, setRejectMembersDialog] = useState(false);

  const [openInfoModal, setOpenInfoModal] = useState(false);

  // -------------------------------------------------------------------------------

  const handleDelete = (value) => {
    setrejectMembersData(value)
    setRejectMembersDialog(true)
    // deleteMember(customerId, value.user_id)
    //   .then(() => {
    //     props.api.purgeInfiniteCache();
    //     enqueueSnackbar(
    //       'Member deleted sucessfully',
    //       {
    //         variant: 'success',
    //         anchorOrigin: {
    //           vertical: 'bottom',
    //           horizontal: 'right',
    //         },
    //       },
    //       500
    //     );
    //   })
    //   .catch((res) => {
    //     enqueueSnackbar(
    //       res.response.data.message,
    //       {
    //         variant: 'error',
    //         anchorOrigin: {
    //           vertical: 'bottom',
    //           horizontal: 'right',
    //         },
    //       },
    //       500
    //     );
    //   });
  };

  const handleEdit = (value) => {
    setPendingMembersData(value);
    setPendingMembersDialog(true);
  };

  const handleInfoOpen = () => {
    setOpenInfoModal(true);
  };

  const handleInfoClose = () => {
    setOpenInfoModal(false);
  };

  // -------------------------------------------------------------------------------
  if (data === undefined) {
    return '';
  }
  return (
    <>
      <Tooltip title="Approve">
        <IconButton onClick={() => handleEdit(data)}>
        <AddTaskIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reject">
        <IconButton
          // onClick={confirm(() => handleDelete(data), {
          //   title: 'Information',
          //   confirmationText: 'Yes',
          //   disableOkBtn: false,
          //   description: 'Are you sure you want to delete this member ?',
          // })}
          onClick={() => handleDelete(data)}
        >
          <HighlightOffIcon />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Info">
        <IconButton onClick={handleInfoOpen}>
          <Info />
        </IconButton>
      </Tooltip> */}
      {/* <Tooltip title="Info">
        <IconButton onClick={handleInfoOpen}>
          <Diversity2Icon />
        </IconButton>
      </Tooltip> */}
      {pendingMembersDialog && (
        <PendingMemberModel
          member={pendingMembersData}
          openDialog={pendingMembersDialog}
          handleClose={() => setPendingMembersDialog(false)}
          gridApi={props.api}
        />
      )}
      {rejectMembersDialog && (
        <RejectMemberModel
          member={rejectMembersData}
          openDialog={rejectMembersDialog}
          handleClose={() => setRejectMembersDialog(false)}
          gridApi={props.api}
        />
      )}
    <Modal
        open={openInfoModal}
        onClose={handleInfoClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 800,
          
          bgcolor: 'background.paper', 
          boxShadow: 24, 
          p: 4, 
          borderRadius:2,
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Members Table
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Header</TableCell>
                <TableCell>Header1</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{data.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{data.email}</TableCell>
              </TableRow>
             
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
      <Button
        variant="text"
        onClick={handleInfoClose}
        sx={{ mr: 2 }} 
        color="success"
      >
        Save
      </Button>
      <Button
      
        variant="text"
        onClick={handleInfoClose}
      >
        Close
      </Button>
    </Box>
          
        </Box>
      </Modal>
    </>
  );
};

PendingActionRenderer.propTypes = {
  data: PropTypes.object,
  api: PropTypes.object, 
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(PendingActionRenderer);
