import { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid,Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';

// components
import { filter } from 'lodash';
// sections

import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
// mock
import devices from '../../../_mock/devices';
import ISPGoodTableToolbar from './ISPGoodTableToolbar';
import ISPGoodTableHead from './ISPGoodTableHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Uptime', label: 'Up-Time', alignRight: false },
  { id: 'Latency', label: 'Latency (ms)', alignRight: false },
  { id: 'Packet drops', label: 'Packet Loss (%)', alignRight: false },
  { id: 'Jitter', label: 'Jitter (ms)', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

//----------------------------------------------------------------------
export default function ISPGoodTable() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPGoodTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ISPGoodTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { id, number, name, role, status, company, avatarUrl, isVerified, isVendor } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow hover key={id} role="checkbox" selected={isItemSelected} aria-checked={isItemSelected}>
                      <TableCell component="th" scope="row">
                        {number}
                      </TableCell>
                      <TableCell><Link to="/dashboard/isp-management/isp-good/1">{'12344'}</Link></TableCell>
                      <TableCell>{'Bangalore'}</TableCell>
                      <TableCell>{'Prisa'}</TableCell>
                      <TableCell>{'122.233.333'}</TableCell>
                      <TableCell>{'10:32 AM'}</TableCell>
                      <TableCell>{'7.964'}</TableCell>
                      <TableCell>{'packet loss'}</TableCell>
                      <TableCell>{'8.650'}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}
