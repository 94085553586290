import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();


export const assignSubnets = async (id, agentId, data) => {
    const newPromise =  testbedModem.post(`${id}/agents/${agentId}/subnets/assign`, data).then((response) => {
      return response.data.data;
    });
    return newPromise;
  };