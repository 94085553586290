
export const getCustomerApiEndpoint = () => {
    // const theWindow = window || globalThis;
    let uiHost = ""
    try {
        uiHost = window.location.host
    } catch (error) {
        /* global globalThis */
        uiHost = globalThis.location.host
    }
    let apiHost = ""
    let cmdbBucket = ""
    const uiHostSplit = uiHost.split(".")
    switch (uiHost) {
        case "nms-portal-dev.onedigilta.com":
            apiHost = "https://nms-portal-api.onedigilta.com"
            cmdbBucket = "nms-stagging-cmdb-explorer"
            break
        case "localhost:3000":
            // apiHost = "https://decathlon-api.filoffee.ai"
            // apiHost = "https://testbed-api.basestation.linkeye.io"
             apiHost = "https://basestation-api.linkeye.io"

            cmdbBucket = "nms-stagging-cmdb-explorer"
            break
        // case "nms-portal-dev.onedigilta.com":
        //             apiHost = "https://nms-portal-api.onedigilta.com"
        //             cmdbBucket = "nms-stagging-cmdb-explorer"
        //             break
        //         case "localhost:3000":
        //             apiHost = "https://decathlon-api.filoffee.ai"
        //             cmdbBucket = "nms-stagging-cmdb-explorer"
        //             break

        // case "localhost:3000":
        //     apiHost = "http://localhost:8000"
        //     break
        case "decathlon-portal.filoffee.ai":
            apiHost = "https://decathlon-api.filoffee.ai"
            cmdbBucket = "decathlon-cmdb"
            break
        case "cowrks-basestation.linkeye.io":
            apiHost = "https://cowrks-api-basestation.linkeye.io"
            cmdbBucket = "coworks-cmdb"
            break
        default:
            cmdbBucket = `${uiHostSplit[0]}-cmdb`
            uiHostSplit[0] += "-api"
            apiHost = `https://${uiHostSplit.join(".")}`
    }
    apiHost += "/api"
    return { apiHost, cmdbBucket }
}
const clientCredentials = getCustomerApiEndpoint()

export default clientCredentials