// @mui
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider } from '../../hook-form';
import { UserContext } from '../../../context/UserContext';
import { addWebhookURL } from 'src/API/Scheduler/Scheduler';
// import { addSchedulerWebhook } from '../../../API/Scheduler/Scheduler'; // Adjust the import path accordingly

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function AddWebhook(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, fetchWebhooks } = props; // fetchWebhooks should be passed in props
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const membersSchema = Yup.object().shape({
    name: Yup.string().required('Webhook name is required'),
    url: Yup.string().url('Must be a valid URL').required('Webhook URL is required'),
  });

  const defaultValues = {
    name: '',
    url: '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      name: value.name,
      URL: value.url, 
      alert_types: null, 
    };
    setLoading(true);
    try {
      await addWebhookURL(customerId, data);
      setLoading(false);
      handleClose();
      fetchWebhooks();
      enqueueSnackbar('Webhook added successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.response?.data?.message || 'Error adding webhook', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
};


  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      style={{ margin: 0 }}
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 1 }}>
          Add New Webhook
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent>
          <Stack spacing={3} sx={{ py: 2 }}>
            <RHFTextField name="name" label="Webhook Name" fullWidth placeholder="Enter webhook name" />
            <RHFTextField name="url" label="Webhook URL" fullWidth placeholder="Enter webhook URL"  helperText="Must be a valid URL, e.g., https://example.com/webhook"  />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
