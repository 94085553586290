

import { Difference } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import DiffDialogwithTabs from '../../../SummaryComponents/Components/DiffDialogwithTab';
import { getdiff } from '../../../../API/S3/ConfigEvents';
import { UserContext } from '../../../../context/UserContext';

const ActionRenderer = (props) => {
  const { data } = props;
  const { customerId } = useContext(UserContext);
  const [differenceDialog, setDifferenceDialog] = useState(false); // Initialize to false
  const [diffData, setDiffData] = useState(null);
  const [backupDetails, setBackupDetails] = useState(null);

 
  const handleClickOpenDiffDialog = () => {
    getdiff(customerId, data?.cmdb_event_id).then((res) => {      
      setBackupDetails(res);
    }).catch((error) => {
      console.error("Error fetching backup details:", error);
    });
    setDifferenceDialog(true);
  };
  if (!data) {
    return null;
  }

  return (
    <Box>
      <Tooltip title={'View Diff'}>
        <IconButton onClick={handleClickOpenDiffDialog}>
          <Difference fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {differenceDialog && (
        <DiffDialogwithTabs
        openDialog={differenceDialog}
        goldenConfigPath={data.r_golden_config_path}
        currentConfigPath={data.r_change_backup_path}
        previousConfigPath={data.r_change_previous_path}
        data={data}
        cmdbGroupSummaryEvent={data}
        backupDetails={backupDetails}
        handleClose={() => setDifferenceDialog(false)}
        />
      )}
    </Box>
  );
};

ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default ActionRenderer;
