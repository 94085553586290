import {
    Typography,
    Breadcrumbs,
    Container
  } from '@mui/material';
  import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
  import { Link } from 'react-router-dom';
  import Page from '../components/Page';
  import { OnDemandcomponent } from  "../components/OnDemandComponents";
  // ----------------------------------------------------------------------
  

  export default function Location() {
  
    return (
      <Page title="On-Demand">
        <Container maxWidth={false}>
  
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb"
                      sx={{ fontSize: { xs: '10px',sm: '12px', md: '14px', lg:'16px',xl:'18px', xxl:'20px' } }}
                      >
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                <HomeOutlinedIcon 
                sx={{
                  fontSize: {
                    xs: '20px',
                    sm: '22px',
                    md: '24px',
                    lg: '26px',
                    xl: '28px',
                    xxl: '30px',
                  },
                }} />
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard"
              sx={{
                fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                },
              }}>
                Onboarding
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/ondemand"
              sx={{
                fontSize: {
                  xs: '8px',
                  sm: '12px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                  xxl: '20px',
                },
              }}>
                On-demand
              </Typography>
            </Breadcrumbs>
          </div>
    
        <OnDemandcomponent />

        </Container>
      </Page>
    );
  }
  