import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default (props) => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');
  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div ref={refButton} className="customHeaderMenuButton">
        <i className={`fa ${props.menuIcon}`} />
      </div>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div style={{ display: 'inline-block' }}>
        <div className={`customSortDownLabel ${ascSort}`}>
          <i className="fa fa-long-arrow-alt-down" />
        </div>
        <div className={`customSortUpLabel ${descSort}`}>
          <i className="fa fa-long-arrow-alt-up" />
        </div>
        <div className={`customSortRemoveLabel ${noSort}`}>
          <i className="fa fa-times" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {menu}
      <div className="customHeaderLabel">
        {props.displayName}
        <Tooltip title="The up time is based on the last scheduled device scan" arrow>
          <ErrorOutlineRoundedIcon style = {{transform: 'rotate(180deg)',marginBottom:'-5px',marginLeft:'4px', cursor:'pointer'}} />
        </Tooltip>
      </div>
      {sort}
    </div>
  );
};
