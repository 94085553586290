
import React from 'react';
import ReactApexChart from 'react-apexcharts';


const formatBytes = (bytes) => {
  if (bytes >= 1e6) {
    return (bytes / 1e6).toFixed(2) + ' MB';
  } else if (bytes >= 1e3) {
    return (bytes / 1e3).toFixed(2) + ' KB';
  } else {
    return bytes.toFixed(2) + ' B';
  }
};

const MainMemoryFreeChart = ({ seriesData }) => {
  const chartOptions = {
    chart: {
      type: 'area',
      height: 260,
      toolbar: {
        show: true,
        tools: {
          download: true, // Allows downloading the chart as an image
          selection: true, // Enables selection zoom
          zoom: true, // Enables the zoom button
          zoomin: true, // Enables the zoom-in button
          zoomout: true, // Enables the zoom-out button
          pan: true, // Enables panning
          reset: true, // Enables the reset zoom button
        },
        autoSelected: 'zoom', // Default tool selected
      },
      zoom: {
        enabled: true, // Enable zooming
        type: 'xy', // Enable zooming in both x and y directions
        autoScaleYaxis: true, // Automatically scale the y-axis when zooming
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    // Removed the annotations section to eliminate the Threshold line
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#ffcc80'],
    dataLabels: {
      enabled: false,
      dropShadow: { enabled: true },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: (value) => (value <= 100 ? `${value.toFixed(2)}%` : formatBytes(value)),
      },
      x: {
        formatter: (value) => {
          const date = new Date(value);
          if (!isNaN(date)) {
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
            return `${formattedTime}\n${formattedDate}`;
          }
          return 'Invalid Date';
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      showForSingleSeries: true,
      formatter: (seriesName, opts) => {
        const series = opts.w.globals.series[opts.seriesIndex];

        // Calculate the latest memory value
        const latestValue = series[series.length - 1];
        const formattedValue = latestValue <= 100 ? `${latestValue.toFixed(2)}%` : formatBytes(latestValue);

        return `
          <div>
            <div> Current Memory: ${formattedValue}</div>
          </div>
        `;
      },
      offsetX: 0.5,
      markers: {
        width: 15,
        height: 7,
        strokeWidth: 0,
        radius: 12,
        offsetX: 0,
        offsetY: 0,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        hideOverlappingLabels: true,
        formatter: function (value) {
          const date = new Date(value);
          if (!isNaN(date)) {
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12;
            const timeString = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            const dateString = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
            return `${timeString} ${dateString}`;
          }
          return 'Invalid Date';
        },
      },
    },
    yaxis: {
      min: 0, // Ensures that the y-axis starts from zero
      forceNiceScale: true,
      labels: {
        formatter: (value) => (value <= 100 ? `${value.toFixed(2)}%` : formatBytes(value)),
      },
    },
  };

  return (
    <div>
      {seriesData.length > 0 && seriesData[0].data.length > 0 ? (
        <ReactApexChart series={seriesData} options={chartOptions} type="area" height={260} />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default MainMemoryFreeChart;
