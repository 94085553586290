import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const deAssignSubnets = async (id,locationUuid,data) => {
  const newPromise =  modem
    .post(`${id}/locations/${locationUuid}/subnets/de-assign`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const assignSubnets = async (id,locationUuid,data) => {
  const newPromise =  modem
    .post(`${id}/locations/${locationUuid}/subnets/assign`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

