// material
import { Grid, Typography, Paper, Box, IconButton, LinearProgress, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
// components
import React, { useState, useEffect, useContext } from 'react';
import { DevicesUpIcon, LinksDownIcon, LinksUpIcon } from '../../../icons';
import { getLocationStatistics } from '../../../API/LocationStatistics/LocationStatistics';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from '../../../utils/formatString';


// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
});

// ----------------------------------------------------------------------

export default function CredentialCards() {
  const { customerId } = useContext(UserContext);
  const classes = useStyles();
  const [locationStatisticsData, setLocationStatisticsData] = useState([]);
  const [loctionStatisticsDataLoading, setLoctionStatisticsDataLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(true);
  const fetchLocationStatistics = async () => {
    setLoctionStatisticsDataLoading(true);

    getLocationStatistics(customerId)
      .then((res) => {
        setLoctionStatisticsDataLoading(false);
        setLocationStatisticsData(Object.keys(res).map((t) => ({ label: t, value: res[t] })));
      })
      .catch(() => {
        setLoctionStatisticsDataLoading(false);
      });
  };

  useEffect(() => {
    fetchLocationStatistics();
  }, [customerId]);

  useEffect(() => {
    // setTimeout(() => {
      setCardLoading(false);
    // }, 2000);
  }, []);

  return (
    <>
      {locationStatisticsData.splice(2, 1) && locationStatisticsData?.map((item , key) => {
        return (
          <Grid key={key} item lg={12 / 4} sm={12 / 4} xs={12} md={12 / 4} >
            <Paper elevation={15}>
              <Box className={classes.boxPadding} >
                <Box className={classes.boxItems}>
                  <IconButton size="large" sx={{ color: 'blue', background: '#c3cfe6', borderRadius: '12px' }}>
                    <LinksUpIcon />
                  </IconButton>
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    {toCamelizeWords(item.label)}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
                  {cardLoading ? (
                    <CircularProgress size={33} />
                  ) : (
                    <Typography variant="h3" component="div">
                      {item.value === 0 || item.value === null || item.value === undefined ? 0 : item.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </>
  );
}
