import React, { useState, useEffect } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, Divider, Typography } from '@mui/material';


export default function WifiTroubleshootInsightsDialog(props) {

    const { value, openInsightDialog, setOpenInsightDialog } = props;
    const [infoList, setInfoList] = useState(value.steps.split('.'));
    const [rcaData, setRcaData] = useState(value.co_pilot_summary);
    const [count, setCount] = useState(0);

    return (
        <>
            <Dialog open={openInsightDialog} onClose={() => { setOpenInsightDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Co-Pilot insights
                    <IconButton onClick={() => { setOpenInsightDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 2, px: 3 }}>
                    {infoList.length !== 0 && infoList.map((value, index) => (
                        <List key={index} sx={{ bgcolor: 'background.paper' }}>
                            <ListItemButton>
                                {index !== infoList.length - 1 && (
                                    <ListItemIcon>
                                        <CheckIcon sx={{ color: 'green', marginRight:"20px" }} />
                                        <ListItemText primary={value} sx={{ color: 'green' }}/>
                                    </ListItemIcon>
                                )}
                                {index === infoList.length - 1 && (
                                    <ListItemIcon>
                                        <ClearIcon sx={{ color: 'red', marginRight:"20px" }} />
                                        <ListItemText primary={value} sx={{ color: 'red' }}/>
                                    </ListItemIcon>
                                )}
                                
                            </ListItemButton>
                        </List>
                    ))}
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "black" }}>
                            <Typography variant='h6'>
                                {rcaData} 
                            </Typography>
                        </Box>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { setOpenInsightDialog(false) }}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}