import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Alert, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCallback, useState } from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const defaultOptions = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  onClose: () => {},
  onCancel: () => {},
};

const useStyles = makeStyles(() => ({
  titleFont: {
    '& .MuiTypography-h6': {
      fontSize: 23,
    },
    borderBottom: '1px solid #ececec',
  },
}));

const withConfirm = (WrappedComponent) => (props) => {
  const classes = useStyles();
  const [onConfirm, setOnConfirm] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const [isLoading, setIsLoading] = useState(false);
  const { title, description, confirmationText, cancellationText, dialogProps, onClose, onCancel, disableOkBtn} = options;


  const handleClose = useCallback(() => {
    onClose();
    setOnConfirm(null);
    setIsLoading(false)
  }, [onClose]);

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [onCancel, handleClose]);

  const handleConfirm = useCallback((...args) => {
      onConfirm(...args);
      setIsLoading(!isLoading);
      setTimeout(() => handleClose(), 2000);
    },
   
  [onConfirm, setIsLoading, handleClose]);

  /* Returns function opening the dialog, passed to the wrapped component. */
  const confirm = useCallback(
    (onConfirm, options = {}) =>
      () => {
        setOnConfirm(() => onConfirm);
        setOptions({ ...defaultOptions, ...options });
      },
    []
  );

  return (
    <>
      <WrappedComponent {...props} confirm={confirm} />
      <Dialog fullWidth {...dialogProps} onClose={handleCancel} open={!!onConfirm}>
        {title && <DialogTitle className={classes.titleFont}>
          <Box style={{textAlign:'left'}}>
            <WarningAmberOutlinedIcon sx={{color:'#ffc107',fontSize:'1.7rem',paddingRight:'5px',marginBottom:'-5px'}}/>
            {title}
          </Box>
          </DialogTitle>
        }
        {description && (
          <DialogContent>
            <Alert severity="warning" icon={false}  sx={{marginTop:'10px'}}>
              {description}
            </Alert>
          </DialogContent>
        )}
        <Divider />
        <DialogActions>
          <Button variant="text" onClick={handleCancel}>
            {cancellationText}
          </Button>
          <LoadingButton color="primary" variant="contained" disabled={disableOkBtn} onClick={handleConfirm} loading={isLoading}>
            {confirmationText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withConfirm;
