import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { deleteCredentials } from '../../../../API/Credentials/listCredentials';
import { WithConfirm } from '../../../WithConfirm';
import EditCredentials from '../../Components/EditCredentials';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------


const ActionRender = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [editCredentialsDialog, setEditCredentialsDialog] = useState(false);
  const [credentialsLoading, setCredentialsLoading] = useState(false);
  const [credentialData, setCredentialData] = useState(null);



  const handlecloseEditCredentialDialog = () => {
    setEditCredentialsDialog(false);
  };

  const handleEdit = (value) => {
    setEditCredentialsDialog(true);
    setCredentialData(value);
  };

  const handleDelete = (value) => {
    setCredentialsLoading(true);
    deleteCredentials(customerId, value.credential_id)
      .then((res) => {
        props.api.purgeInfiniteCache();
        setCredentialsLoading(false);
        enqueueSnackbar(
          'Credential deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
          );
      })
      .catch((err) => {
        setCredentialsLoading(false);
        enqueueSnackbar(
          err.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  if (props.data === undefined) {
    return '';
  }

  if (props?.data) {
    return (
      <div style={{ textAlign: 'left' }}>
        <Tooltip title="Edit">
              <IconButton onClick={() => handleEdit(data)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
               onClick={confirm((e) => handleDelete(data), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to delete this credential?',
              })}
              >
                <Delete />
              </IconButton>
            </Tooltip>
              {editCredentialsDialog && (
                <EditCredentials
                  openDialog={editCredentialsDialog}
                  handleClose={handlecloseEditCredentialDialog}
                  credentialData={credentialData}
                  api={props.api}
                />
            )}
      </div>
    )
  }
};


ActionRender.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionRender);
