import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getInactiveDevices = async (id, data) => {
  const newPromise =  testbedModem
    .post(`/api/${id}/inventory/network-types`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};