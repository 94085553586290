import { IconButton, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { toCamelizeWords } from './formatString';

const TooltipRenderer = (rDvcMgmtIp, rDvcMgmtType, rDvcController) => {
  // if (rDvcMgmtIp && rDvcMgmtIp.length) {
  //   return (
  //     <Tooltip
  //       title={
  //         <div>
  //           <p>Management IP : {rDvcMgmtIp}</p>
  //           <p>Management Type : {toCamelizeWords(rDvcMgmtType)} </p>
  //         </div>
  //       }
  //     >
  //       <IconButton size="small">
  //         <StarIcon color="error" fontSize="inherit" />
  //       </IconButton>
  //     </Tooltip>
  //   );
  // }
  if (rDvcController === 'VC MASTER') {
    return (
      <Tooltip
        title={
          <div>
            <p>Management IP : {rDvcMgmtIp}</p>
            <p>Management Type : {toCamelizeWords(rDvcMgmtType)} </p>
          </div>
        }
      >
        <IconButton size="small">
          <StarIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  }
  // if (rDvcController === 'VC MEMBER') {
  //   return (
  //     <Tooltip
  //       title={
  //         <div>
  //           {/* <p>Management IP : {rDvcMgmtIp}</p> */}
  //           <p>Management Type : {toCamelizeWords(rDvcMgmtType)} </p>
  //         </div>
  //       }
  //     >
  //       <IconButton size="small">
  //         <StarIcon sx={{ color: 'orange' }} fontSize="inherit" />
  //       </IconButton>
  //     </Tooltip>
  //   );
  // }
  // if (rDvcController === 'WLC MEMBER') {
  //   return (
  //     <Tooltip
  //       title={
  //         <div>
  //           <p>Management IP : {rDvcMgmtIp}</p>
  //           <p>Management Type : {toCamelizeWords(rDvcMgmtType)} </p>
  //         </div>
  //       }
  //     >
  //       <IconButton size="small">
  //         <StarIcon color='success' fontSize="inherit" />
  //       </IconButton>
  //     </Tooltip>
  //   );
  // }
  return null;
};
export default TooltipRenderer;
