import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const addRole = async (id, payload) => {
  const newPromise =  modem.post(`${id}/roles`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getRoles = async (id, payload) => {
  const newPromise =  modem.get(`${id}/roles`, payload).then((response) => {
    return response.data;
    
  });
  
  return newPromise;
};

export const assignRole = async (id, user_id, payload) => {
  const newPromise =  modem.post(`${id}/members/${user_id}/role`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
export const createRole = async (id, payload) => {
  const newPromise =  modem.post(`${id}/role`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getPermissions = async (id, data) => {
  const newPromise =  modem.get(`${id}/permissions`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getRoleByUuid = async (id, uuid) => {
  const newPromise =  modem.get(`${id}/roles/${uuid}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const updateRole = async (id, roleId, payload) => {
  const newPromise =  modem.post(`${id}/roles/${roleId}`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const deleteRole = async (id, roleId, payload) => {
  const newPromise =  modem.delete(`${id}/roles/${roleId}`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};