// material
import { Grid } from '@mui/material';
import ConfigBackupEventsTable from './Components/ConfigBackupTable';


// ----------------------------------------------------------------------

const ConfigBackupEventsComponent = (props) => {
  const { locationData } = props;
  return (
    <>
      <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <ConfigBackupEventsTable locationData={locationData}/>
          </Grid>
      </Grid>
    </>
  );
};
export default ConfigBackupEventsComponent;

