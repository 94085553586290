// material
import { Breadcrumbs, Container, Grid, Link ,IconButton} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// components
import Page from '../components/Page';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import SmtpCards from '../components/SmtpComponents/Components/SmtpCards';
import SmtpTabCard1 from '../components/SmtpComponents/Components/SmtpTabCard1';

// mock

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Smtp() {
  return (
    <Page title="SMTP/IMAP">
      <Container maxWidth={false}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
          <IconButton component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </IconButton>
            <Link underline="hover" color="inherit" href="/dashboard/dashboard">
              Settings
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/smtp-imap">
              SMTP-IMAP
            </Link>
          </Breadcrumbs>
        </div>

        <Grid>
          <Grid container spacing={4} sx={{ py: 1 }}>
            <hr />
            <SmtpCards />
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ py: 2 }}>
            <hr />
            <br />
            <LocationSelect />
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ py: 1 }}>
            <SmtpTabCard1 />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
