import { Grid } from "@mui/material";
import DevicewiseSummaryTable from "./Components/DevicewiseSummaryTable";
// ----------------------------------------------------------------------

//----------------------------------------------------------------------
export default function DevicewiseSummaryComponent() {


  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
          <DevicewiseSummaryTable />
      </Grid>
    </Grid>
  );
}
