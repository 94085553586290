/* eslint-disable */
import {
    Box,
    LinearProgress,
    Paper,
    Typography,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 150,
    overflowY: 'auto', // Allow scrolling if the content exceeds the height
    padding: theme.spacing(2),
}));

const CardStyle = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
}));

// ---------------------------------------

const VdomTab = ({ vdomExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const transformData = (data) => {
        if (!data || data.length === 0) {
            return [];
        }

        const transformedData = [];
        const keys = Object.keys(data[0]);

        const groupedData = {};

        keys.forEach((key) => {
            const pattern = key.match(/[^a-zA-Z0-9](.*?)$/);
            if (pattern) {
                const dynamicPattern = pattern[1];
                if (!groupedData[dynamicPattern]) {
                    groupedData[dynamicPattern] = {};
                }
                const baseKey = key.split('.')[0];
                groupedData[dynamicPattern][baseKey] = data[0][key][0];
            } else {
                console.warn(`Key without identifiable dynamic pattern: ${key}`);
            }
        });

        Object.keys(groupedData).forEach((pattern) => {
            transformedData.push(groupedData[pattern]);
        });

        return transformedData;
    };

    const fetchData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
        };
        setLoading(true);

        setLoading(false);

        const macTableData = allData?.vdom_table || [];
        const transformedData = transformData(macTableData);
       
        setResData(transformedData);

    }, [healthDashState.selectedDevice, vdomExpanded, customerId, getStartEnd]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, vdomExpanded, fetchData]);

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <ChartWrapperStyle dir="ltr">
                <Grid container spacing={2}>
                    {resData.map((item, index) => (
                        <Grid item xs={3} sm={3} md={3} lg={3} key={index}>
                            <CardStyle elevation={3} sx={{ padding: 2 }}>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: getFontSize(),
                                        fontWeight: 'bold',
                                        color: 'text.primary', 

                                    }}
                                >
                                    VDOM
                                </Typography>
                                {Object.entries(item).map(([key, value]) => (
                                    <Typography
                                        key={key}
                                        sx={{
                                            fontSize: isLargeDesktop ? '24px' : isDesktop ? '20px' : '16px',
                                            fontWeight: '800',
                                            color: 'primary.light',
                                            lineHeight: 1,
                                        }}
                                    >
                                        {value}
                                    </Typography>
                                ))}
                            </CardStyle>
                        </Grid>
                    ))}
                </Grid>
            </ChartWrapperStyle>


        </>
    );
};

export default VdomTab;
