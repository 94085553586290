// import React, { useState, useEffect } from 'react';
// import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

// const CustomerSelectionModal = ({ open, onClose, customers, onSelectCustomer }) => {
//   const [selectedCustomer, setSelectedCustomer] = useState('');
//   const [imageSrc, setImageSrc] = useState({}); 
  
//   useEffect(() => {
//     const processLogos = async () => {
//       const imageUrls = {};

//       customers.forEach(customer => {
//         const { id, logo } = customer;

//         try {
//           // Validate and decode base64 string
//           if (logo && /^[A-Za-z0-9+/=]+$/.test(logo)) {
//             const byteCharacters = atob(logo);
//             const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
//             const blob = new Blob([byteArray], { type: 'image/png' });
//             imageUrls[id] = URL.createObjectURL(blob);
//           } else {
//             imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//           }
//         } catch (error) {
//           console.error('Failed to decode base64 string:', error);
//           imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//         }
//       });

//       setImageSrc(imageUrls);
//     };

//     processLogos();
//   }, [customers]);

//   const handleCustomerChange = (event) => {
//     setSelectedCustomer(event.target.value);
//   };

//   const handleSelect = () => {
//     onSelectCustomer(selectedCustomer);
//     localStorage.setItem('customerId', selectedCustomer?.id);
//     localStorage.setItem('logo', selectedCustomer?.logo);
//     localStorage.setItem('customerName', selectedCustomer?.name);
//     onClose();
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       aria-labelledby="modal-title"
//       aria-describedby="modal-description"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}>
//         <Typography id="modal-title" variant="h6" component="h2">
//           Select Customer
//         </Typography>
//         <FormControl fullWidth sx={{ mt: 2 }}>
//           <InputLabel id="customer-select-label">Customer</InputLabel>
//           <Select
//             labelId="customer-select-label"
//             id="customer-select"
//             value={selectedCustomer}
//             label="Customer"
//             onChange={handleCustomerChange}
//           >
//             {customers.length > 0 ? (
//               customers.map((customer) => (
//                 <MenuItem key={customer.id} value={customer}>
//                   {imageSrc[customer.id] && (
//                     <img
//                       src={imageSrc[customer.id]}
//                       alt="Logo"
//                       style={{ width: '50px', height: 'auto', marginRight: '10px' }}
//                     />
//                   )}
//                   {customer.name}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>No customers available</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//           <Button
//             color="success"
//             variant="text"
//             sx={{ mt: 3 }}
//             onClick={handleSelect}
//             disabled={!selectedCustomer}
//           >
//             Proceed
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default CustomerSelectionModal;

// import React, { useState, useEffect } from 'react';
// import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

// const CustomerSelectionModal = ({ open, onClose, customers, onSelectCustomer }) => {
//   const [selectedCustomer, setSelectedCustomer] = useState('');
//   const [imageSrc, setImageSrc] = useState({});

//   useEffect(() => {
//     const processLogos = async () => {
//       const imageUrls = {};

//       customers.forEach(customer => {
//         const { id, logo } = customer;

//         try {
//           // Validate and decode base64 string
//           if (logo && /^[A-Za-z0-9+/=]+$/.test(logo)) {
//             const byteCharacters = atob(logo);
//             const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
//             const blob = new Blob([byteArray], { type: 'image/png' });
//             imageUrls[id] = URL.createObjectURL(blob);
//           } else {
//             imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//           }
//         } catch (error) {
//           console.error('Failed to decode base64 string:', error);
//           imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//         }
//       });

//       setImageSrc(imageUrls);
//     };

//     processLogos();
//   }, [customers]);

//   const handleCustomerChange = (event) => {
//     setSelectedCustomer(event.target.value);
//   };

//   const handleSelect = () => {
//     onSelectCustomer(selectedCustomer);
//     localStorage.setItem('customerId', selectedCustomer?.id);
//     localStorage.setItem('logo', selectedCustomer?.logo);
//     localStorage.setItem('customerName', selectedCustomer?.name);
//     handleCloseModal(); // Call handleCloseModal after setting values
//   };

//   const handleCloseModal = () => {
//     setSelectedCustomer(''); 
//     localStorage.removeItem('AuthorizationToken'); 
//     localStorage.removeItem('customerId'); 
//     localStorage.removeItem('logo'); 
//     localStorage.removeItem('customerName'); 
//     onClose(); 
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleCloseModal} // Use handleCloseModal to reset local storage on modal close
//       aria-labelledby="modal-title"
//       aria-describedby="modal-description"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}>
//         <Typography id="modal-title" variant="h6" component="h2">
//           Select Customer
//         </Typography>
//         <FormControl fullWidth sx={{ mt: 2 }}>
//           <InputLabel id="customer-select-label">Customer</InputLabel>
//           <Select
//             labelId="customer-select-label"
//             id="customer-select"
//             value={selectedCustomer}
//             label="Customer"
//             onChange={handleCustomerChange}
//           >
//             {customers.length > 0 ? (
//               customers.map((customer) => (
//                 <MenuItem key={customer.id} value={customer}>
//                   {imageSrc[customer.id] && (
//                     <img
//                       src={imageSrc[customer.id]}
//                       alt="Logo"
//                       style={{ width: '50px', height: 'auto', marginRight: '10px' }}
//                     />
//                   )}
//                   {customer.name}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>No customers available</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//           <Button
//             color="success"
//             variant="text"
//             sx={{ mt: 3 }}
//             onClick={handleSelect}
//             disabled={!selectedCustomer}
//           >
//             Proceed
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default CustomerSelectionModal;

import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const CustomerSelectionModal = ({ open, onClose, customers, onSelectCustomer }) => {
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [imageSrc, setImageSrc] = useState({});
  const [isCustomerSelected, setIsCustomerSelected] = useState(false); 

  useEffect(() => {
    const processLogos = async () => {
      const imageUrls = {};

      customers.forEach(customer => {
        const { id, logo } = customer;

        try {
          // Validate and decode base64 string
          if (logo && /^[A-Za-z0-9+/=]+$/.test(logo)) {
            const byteCharacters = atob(logo);
            const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
            const blob = new Blob([byteArray], { type: 'image/png' });
            imageUrls[id] = URL.createObjectURL(blob);
          } else {
            imageUrls[id] = '/path/to/placeholder.png'; 
          }
        } catch (error) {
          console.error('Failed to decode base64 string:', error);
          imageUrls[id] = '/path/to/placeholder.png'; 
        }
      });

      setImageSrc(imageUrls);
    };

    processLogos();
  }, [customers]);

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };

  const handleSelect = () => {
    setIsCustomerSelected(true); 
    onSelectCustomer(selectedCustomer);
    localStorage.setItem('customerId', selectedCustomer?.id);
    localStorage.setItem('logo', selectedCustomer?.logo);
    localStorage.setItem('customerName', selectedCustomer?.name);
    onClose(); 
  };

  const handleCloseModal = () => {
    if (!isCustomerSelected) { 
      localStorage.removeItem('AuthorizationToken');
      localStorage.removeItem('customerId');
      localStorage.removeItem('logo');
      localStorage.removeItem('customerName');
    }
    setIsCustomerSelected(false); // Reset the flag
    setSelectedCustomer(''); // Clear selected customer
    onClose(); // Close the modal
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal} 
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography id="modal-title" variant="h6" component="h2">
          Select Customer
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="customer-select-label">Customer</InputLabel>
          <Select
            labelId="customer-select-label"
            id="customer-select"
            value={selectedCustomer}
            label="Customer"
            onChange={handleCustomerChange}
          >
            {customers.length > 0 ? (
              customers.map((customer) => (
                <MenuItem key={customer.id} value={customer}>
                  {imageSrc[customer.id] && (
                    <img
                      src={imageSrc[customer.id]}
                      alt="Logo"
                      style={{ width: '50px', height: 'auto', marginRight: '10px' }}
                    />
                  )}
                  {customer.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No customers available</MenuItem>
            )}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="success"
            variant="text"
            sx={{ mt: 3 }}
            onClick={handleSelect}
            disabled={!selectedCustomer}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerSelectionModal;

