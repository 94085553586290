import { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import VulnerableDialog from '../VulnerableDialog';

const VulnerableRenderer = (props) => {
  const { data } = props;
  const [openVulDialog, setOpenVulDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenVulDialog(true);
  };

  if (props.data === undefined) {
    return '';
  }

  if (data?.vulnerables?.length >= 1) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Button onClick={handleClickOpen} style={{ color: 'red' }}>
          {data.r_dvc_is_vulnerable}
        </Button>
        {openVulDialog && (
          <VulnerableDialog openDialog={openVulDialog} data={data} handleClose={() => setOpenVulDialog(false)} />
        )}
      </Box>
    );
  }

  if (data?.vulnerables?.length === 0) {
    return <Box sx={{ textAlign: 'center' }}>No</Box>;
  }
};

VulnerableRenderer.propTypes = {
  data: PropTypes.object,
};

export default VulnerableRenderer;
