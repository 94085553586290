export const defaultAgGridProps = {
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
  overlayNoRowsTemplate: '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No records found</span>',
  rowSelection: 'multiple',
  rowModelType: 'infinite',
  animateRows: 'true',
  blockLoadDebounceMillis: 500,
  enableColResize:'true',
  suppressMovable:'true',
  suppressDragLeaveHidesColumns:'true',
  suppressRowClickSelection:'true',
  suppressCellFocus: 'true',
  suppressMaxRenderedRowRestriction: 'true',
  rowBuffer: 100,
  cacheBlockSize: 200,
  cacheOverflowSize: 200,
  maxConcurrentDatasourceRequests: 1,
  infiniteInitialRowCount: 0,
  enableCellChangeFlash: 'true',
  enableCellTextSelection: 'true',
  maxBlocksInCache: 10,
  sortingOrder: ['desc', 'asc', null],
  enableSorting: 'true',
};


