// import { DeleteOutlineOutlined } from '@mui/icons-material';
// import AddAlertIcon from '@mui/icons-material/AddAlert';
// import { Box, Button, Card, Divider, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { useSnackbar } from 'notistack';
// import { useContext, useEffect, useState } from 'react';
// import { deleteEmail, getSchedulerEmail } from '../../../API/Scheduler/Scheduler';
// import { UserContext } from '../../../context/UserContext';
// import NoDataComponent from '../../NoDataComponent';
// import { WithConfirm } from '../../WithConfirm';
// import AddEmail from './AddEmail';

// // ---------------------------------------------------------------------------------

// const useStyles = makeStyles({
//     CardContent: {
//         padding: '0px',
//     },
//     tableContainer: {
//         maxHeight: 550,
//         "&::-webkit-scrollbar": {
//             width: 7,
//             height: '12px',
//         },
//         "&::-webkit-scrollbar-track": {
//             backgroundColor: '#f1f1f1',
//         },
//         "&::-webkit-scrollbar-thumb": {
//             backgroundColor: 'rgb(181, 179, 179)',
//             borderRadius: '30px',
//         },
//         "&::-webkit-scrollbar-thumb:hover": {
//             backgroundColor: '#555'
//         }
//     },
// });

// // -----------------------------------------------------------------------------------------------------------

// const AddDeleteEmailCard = (props) => {
//     const classes = useStyles();
//     const { confirm } = props;
//     const { enqueueSnackbar } = useSnackbar();
//     const { customerId } = useContext(UserContext);
//     const [emailData, setEmailData] = useState([]);
//     const [emailLoading, setEmailLoading] = useState(false);
//     const [addEmailDialog, setAddEmailDialog] = useState(false);


//     const fetchEmails = async () => {
//         setEmailLoading(true);
//         const res = await getSchedulerEmail(customerId);
//         setEmailData(res);
//         setEmailLoading(false);
//     };

//     useEffect(() => {
//         fetchEmails();
//     }, [customerId]);

//     const handleDelete = (value) => {
//         setEmailLoading(true);
//         deleteEmail(customerId, value.id)
//             .then((res) => {
//                 setEmailLoading(false);
//                 fetchEmails();
//                 enqueueSnackbar(
//                     'Email deleted sucessfully',
//                     {
//                         variant: 'success',
//                         anchorOrigin: {
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         },
//                     },
//                     500
//                 );
//             })
//             .catch((res) => {
//                 setEmailLoading(false);
//                 enqueueSnackbar(
//                     res.response.data.message,
//                     {
//                         variant: 'error',
//                         anchorOrigin: {
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         },
//                     },
//                     500
//                 );
//             });
//     };


//     return (
//         <>
//             <Card>
//                 {/* <CardHeader
//                     color="text.error"
//                     sx={{ mb: 2 }}
//                     title="E-Mails for notifications"
//                     action={
//                         <>
//                             <Box sx={{ flexGrow: 1 }} />
//                             <Button color="primary" onClick={() => setAddEmailDialog(true)} variant="outlined">
//                                 Add new email
//                             </Button>
//                         </>
//                     }
//                     avatar={
//                         <NotificationsActiveOutlined />
//                     }
//                 /> */}

//                 <Box>
//                     <Grid container spacing={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
//                         <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
//                             <AddAlertIcon />
//                             <Typography variant="h5" gutterBottom sx={{ marginLeft: '10px', marginTop: '8px' }}> Alerts </Typography>
//                             <Box sx={{ flexGrow: 1 }} />
//                         </Grid>

//                         <Grid container item xs={6} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'center', paddingTop: '10px' }}>
//                             <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
//                                 <Button color="primary" onClick={() => setAddEmailDialog(true)} variant="outlined">
//                                     Add new email
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Box>

//                 <Divider />
//                 {emailLoading && <LinearProgress />}

//                 <TableContainer className={classes.tableContainer}>
//                     <Table>
//                         <TableBody>
//                             {emailData?.data?.length === 0 ? (
//                                 <TableRow>
//                                     <TableCell>
//                                         <NoDataComponent noDataFoundText="Alerts" />
//                                     </TableCell>
//                                 </TableRow>
//                             ) : (
//                                 emailData?.data?.map((item, index) => {
//                                     return (
//                                         <TableRow key={item.email} sx={{ justifyContent: 'space-between', display: 'flex' }}>
//                                             <TableCell sx={{ width: '95%' }}>
//                                                 <Typography color="textSecondary" variant="body1" gutterBottom>
//                                                     {item.email}
//                                                 </Typography>
//                                             </TableCell>
//                                             <TableCell sx={{ width: '5%' }}>
//                                                 <Tooltip title="Delete">
//                                                     <IconButton
//                                                         onClick={confirm((e) => handleDelete(item), {
//                                                             title: 'Information',
//                                                             confirmationText: 'Yes',
//                                                             disableOkBtn: false,
//                                                             description: 'Are you sure you want to delete this Email',
//                                                         })}>
//                                                         <DeleteOutlineOutlined />
//                                                     </IconButton>
//                                                 </Tooltip>
//                                             </TableCell>
//                                         </TableRow>
//                                     )
//                                 })
//                             )}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Card>
//             {addEmailDialog && (
//                 <AddEmail
//                     openDialog={addEmailDialog}
//                     handleClose={() => setAddEmailDialog(false)}
//                     fetchEmails={fetchEmails}
//                 />
//             )}
//         </>
//     );
// };

// export default WithConfirm(AddDeleteEmailCard);






import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Tabs,
    Tab,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
// import { deleteEmail, getSchedulerEmail, getWebhooks, deleteWebhook } from '../../../API/Scheduler/Scheduler'; 
import { deleteEmail, deleteWebhook, getSchedulerEmail, getWebhooks } from '../../../API/Scheduler/Scheduler';
import { UserContext } from '../../../context/UserContext';
import NoDataComponent from '../../NoDataComponent';
import { WithConfirm } from '../../WithConfirm';
import AddEmail from './AddEmail';
import AddWebhook from './AddWebhook';
import EditWebhook from './EditWebhook';
// import EditWebhook from './EditWebhook'; // Ensure this component exists
// import AddEmail from './AddEmail';
// import AddWebhook from './AddWebhook'; // Uncomment if these components are implemented

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    tabs: {
        '& button': {
            border: '1px solid #eeeeee',
            borderRadius: '2px 2px 0px 0px',
            fontSize: '18px',
            padding: '15px',
            textTransform: 'none', // Prevent uppercase
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
                fontSize: '16px',
            },
        },
    },
    tabsContainer: {
        borderBottom: '1px solid #eeeeee',
        marginBottom: theme.spacing(2),
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(1),
            },
        },
    },
    tableContainer: {
        padding: theme.spacing(2),
    },
    addButton: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
}));

// -----------------------------------------------------------------------------------------------------------

const AddDeleteEmailCard = (props) => {
    const classes = useStyles();
    const { confirm } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);
    const [emailData, setEmailData] = useState([]);
    const [webhookData, setWebhookData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addEmailDialog, setAddEmailDialog] = useState(false);
    const [addWebhookDialog, setAddWebhookDialog] = useState(false);
    const [editWebhookDialog, setEditWebhookDialog] = useState(false);
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [activeTab, setActiveTab] = useState(0); // 0: Email, 1: Webhooks

    // Fetch Emails
    const fetchEmails = async () => {
        setLoading(true);
        try {
            const res = await getSchedulerEmail(customerId);
            setEmailData(res);
        } catch (error) {
            enqueueSnackbar(
                error.response?.data?.message || 'Failed to fetch emails',
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                }
            );
        } finally {
            setLoading(false);
        }
    };

    // Fetch Webhooks
    const fetchWebhooks = async () => {
        setLoading(true);
        try {
            const res = await getWebhooks(customerId); 
            setWebhookData(res);
        } catch (error) {
            enqueueSnackbar(
                error.response?.data?.message || 'Failed to fetch webhooks',
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                }
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            fetchEmails();
        } else {
            fetchWebhooks();
        }
        
    }, [customerId, activeTab]);

    const handleDeleteEmail = (value) => {
        setLoading(true);
        deleteEmail(customerId, value.id)
            .then(() => {
                enqueueSnackbar('Email deleted successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                fetchEmails();
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.response?.data?.message || 'Failed to delete email',
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    }
                );
            })
            .finally(() => setLoading(false));
    };

    const handleDeleteWebhook = (value) => {
        setLoading(true);
        deleteWebhook(customerId, value.id) // Ensure this API exists
            .then(() => {
                enqueueSnackbar('Webhook deleted successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                fetchWebhooks();
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.response?.data?.message || 'Failed to delete webhook',
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    }
                );
            })
            .finally(() => setLoading(false));
    };

    const handleEditWebhook = (webhook) => {
        setSelectedWebhook(webhook);
        setEditWebhookDialog(true);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Render Table Rows for Webhooks
    const renderWebhookRows = (webhooks) => {
        if (!webhooks || webhooks.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={3}>
                        <NoDataComponent noDataFoundText="Webhooks Not Found!" />
                    </TableCell>
                </TableRow>
            );
        }

        return webhooks.map((item) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Typography color="textSecondary" variant="body1" gutterBottom>
                        {item.name}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography color="textSecondary" variant="body1" gutterBottom>
                        {item.webhook_URL}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    {/* Edit Button */}
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => handleEditWebhook(item)}
                            aria-label={`edit webhook ${item.name}`}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    {/* Delete Button */}
                    <Tooltip title="Delete">
                        <IconButton
                            onClick={confirm(() => handleDeleteWebhook(item), {
                                title: 'Confirm Deletion',
                                confirmationText: 'Yes',
                                disableOkBtn: false,
                                description: 'Are you sure you want to delete this Webhook?',
                            })}
                            aria-label={`delete webhook ${item.name}`}
                        >
                            <DeleteOutlineOutlined />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));
    };

    // Render Table Rows for Emails
    const renderEmailRows = (emails) => {
        if (!emails || emails.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={2}>
                        <NoDataComponent noDataFoundText="Emails Not Found!" />
                    </TableCell>
                </TableRow>
            );
        }

        return emails.map((item) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Typography color="textSecondary" variant="body1" gutterBottom>
                        {item.email}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Tooltip title="Delete">
                        <IconButton
                            onClick={confirm(() => handleDeleteEmail(item), {
                                title: 'Confirm Deletion',
                                confirmationText: 'Yes',
                                disableOkBtn: false,
                                description: 'Are you sure you want to delete this Email?',
                            })}
                            aria-label={`delete email ${item.email}`}
                        >
                            <DeleteOutlineOutlined />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <>
            <Card>
                {/* Tabs Container */}
                <Box className={classes.tabsContainer}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="Email and Webhooks Tabs"
                        className={classes.tabs}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Email" />
                        <Tab label="Webhooks" />
                    </Tabs>
                </Box>

                <Divider />

                {/* Header with Icon, Title, and Add Button */}
                <Box className={classes.headerContainer}>
                    <Box display="flex" alignItems="center">
                        <AddAlertIcon />
                        <Typography variant="h5" gutterBottom sx={{ marginLeft: '10px' }}>
                            Alerts
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (activeTab === 0) {
                                setAddEmailDialog(true);
                            } else {
                                setAddWebhookDialog(true);
                            }
                        }}
                        variant="outlined"
                        className={classes.addButton}
                    >
                        {activeTab === 0 ? 'Add New Email' : 'Add New Webhook URL'}
                    </Button>
                </Box>

                <Divider />

                {/* Loading Indicator */}
                {loading && <LinearProgress />}

                {/* Tab Panels */}
                {activeTab === 0 && (
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            {/* Table Head for Emails */}
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email Address</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                                {renderEmailRows(emailData?.data)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {activeTab === 1 && (
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            {/* Table Head for Webhooks */}
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Webhook URL</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                                {renderWebhookRows(webhookData?.data)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Card>

            {/* Add Email Dialog */}
            {/* Uncomment and implement if AddEmail component exists */}
            {addEmailDialog && (
                <AddEmail
                    openDialog={addEmailDialog}
                    handleClose={() => setAddEmailDialog(false)}
                    fetchEmails={fetchEmails}
                />
            )}

            {/* Add Webhook Dialog */}
            {/* Uncomment and implement if AddWebhook component exists */}
            {addWebhookDialog && (
                <AddWebhook
                    openDialog={addWebhookDialog}
                    handleClose={() => setAddWebhookDialog(false)}
                    fetchWebhooks={fetchWebhooks}
                />
            )}

            {/* Edit Webhook Dialog */}
            {editWebhookDialog && selectedWebhook && (
                <EditWebhook
                    openDialog={editWebhookDialog}
                    handleClose={() => setEditWebhookDialog(false)}
                    fetchWebhooks={fetchWebhooks}
                    webhook={selectedWebhook}
                />
            )}
        </>
    );

};

export default WithConfirm(AddDeleteEmailCard);
