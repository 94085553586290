
// import * as Yup from 'yup';
// import { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom'; 
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { Stack, IconButton, InputAdornment } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import Iconify from '../../../components/Iconify';
// import { FormProvider, RHFTextField } from '../../../components/hook-form';
// import { ResetPassword } from '../../../API/UserAuth/ForgotPassword';

// // ----------------------------------------------------------------------

// export default function ResetPasswordForm() {
//   const navigate = useNavigate();
//   const location = useLocation(); 

//   const [showPassword, setShowPassword] = useState(false);
//   const [token, setToken] = useState('');
//   const [email, setEmail] = useState('');
//   //  const [userId, setUserId] = useState(''); 
  
//   useEffect(() => {
//     const pathToken = location.pathname.split('/reset-password/')[1];
//     if (pathToken) {
//       setToken(pathToken.replace('/', ''));
//     }

//     const searchParams = new URLSearchParams(location.search);
//     const emailParam = searchParams.get('email');
//     if (emailParam) {
//       setEmail(emailParam);
//     }
//   }, [location]);

//   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

//   const ResetSchema = Yup.object().shape({
//     password: Yup.string()
//       .required('Password is required')
//       .matches(
//         passwordRegex,
//         'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character'
//       ),
//     confirmpassword: Yup.string()
//       .required('Confirm Password is required')
//       .oneOf([Yup.ref('password')], 'Passwords must match'),
//   });

//   const defaultValues = {
//     password: '',
//     confirmpassword: '',
//     remember: true,
//   };

//   const methods = useForm({
//     resolver: yupResolver(ResetSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = async (data) => {
//     console.log("rest");
//     try {
      
//       const payload = {
//         token,
//         email,
//         password: data.password,
//         confirm_password: data.confirmpassword,
//       };

     
//       const response = await ResetPassword(payload);
//       console.log('API Response:', response);
//       navigate('/dashboard/dashboard', { replace: true });
//     } catch (error) {
      
//       console.error('Error resetting password:', error);
      
//     }
//   };

//   return (
//     <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//       <Stack spacing={3}>
//         <RHFTextField
//           name="password"
//           label="Password *"
//           placeholder="Enter new password"
//           type={showPassword ? 'text' : 'password'}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                   <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           fullWidth 
//         />

//         <RHFTextField
//           name="confirmpassword"
//           label="Confirm Password *"
//           placeholder="Confirm password"
//           type={showPassword ? 'text' : 'password'}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                   <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           fullWidth 
//         />
//       </Stack>

//       <LoadingButton
//         fullWidth
//         size="large"
//         type="submit"
//         variant="contained"
//         loading={isSubmitting}
//         sx={{ marginTop: '30px' }}
//       >
//         Submit
//       </LoadingButton>
//     </FormProvider>
//   );
// }

// import * as Yup from 'yup';
// import { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom'; 
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { Stack, IconButton, InputAdornment, Typography } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import Iconify from '../../../components/Iconify';
// import { FormProvider, RHFTextField } from '../../../components/hook-form';
// import { ResetPassword } from '../../../API/UserAuth/ForgotPassword';

// // ----------------------------------------------------------------------

// export default function ResetPasswordForm() {
//   const navigate = useNavigate();
//   const location = useLocation(); 

//   const [showPassword, setShowPassword] = useState(false);
//   const [token, setToken] = useState('');
//   const [email, setEmail] = useState('');
//   const [successMessage, setSuccessMessage] = useState(''); // State for success message

//   useEffect(() => {
//     const pathToken = location.pathname.split('/reset-password/')[1];
//     if (pathToken) {
//       setToken(pathToken.replace('/', ''));
//     }

//     const searchParams = new URLSearchParams(location.search);
//     const emailParam = searchParams.get('email');
//     if (emailParam) {
//       setEmail(emailParam);
//     }
//   }, [location]);

//   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

//   const ResetSchema = Yup.object().shape({
//     password: Yup.string()
//       .required('Password is required')
//       .matches(
//         passwordRegex,
//         'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character'
//       ),
//     confirmpassword: Yup.string()
//       .required('Confirm Password is required')
//       .oneOf([Yup.ref('password')], 'Passwords must match'),
//   });

//   const defaultValues = {
//     password: '',
//     confirmpassword: '',
//     remember: true,
//   };

//   const methods = useForm({
//     resolver: yupResolver(ResetSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = async (data) => {
//     try {
//       const payload = {
//         token,
//         email,
//         password: data.password,
//         confirm_password: data.confirmpassword,
//       };

//       const response = await ResetPassword(payload);
//       console.log('API Response:', response);
      
      
//       setSuccessMessage('Password reset Successfully');

      
//       setTimeout(() => {
//         navigate('/dashboard/dashboard', { replace: true });
//       }, 5000); 
//     } catch (error) {
//       console.error('Error resetting password:', error);
//     }
//   };

//   return (
//     <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//       <Stack spacing={3}>
//         <RHFTextField
//           name="password"
//           label="Password *"
//           placeholder="Enter new password"
//           type={showPassword ? 'text' : 'password'}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                   <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           fullWidth 
//         />

//         <RHFTextField
//           name="confirmpassword"
//           label="Confirm Password *"
//           placeholder="Confirm password"
//           type={showPassword ? 'text' : 'password'}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                   <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           fullWidth 
//         />
//       </Stack>

//       <LoadingButton
//         fullWidth
//         size="large"
//         type="submit"
//         variant="contained"
//         loading={isSubmitting}
//         sx={{ marginTop: '30px' }}
//       >
//         Submit
//       </LoadingButton>

      
//       {successMessage && (
//         <Typography variant="body1" color="success.main" sx={{ marginTop: '20px' }}>
//           {successMessage}
//         </Typography>
//       )}
//     </FormProvider>
//   );
// }

import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ResetPassword } from '../../../API/UserAuth/ForgotPassword';

export default function ResetPasswordForm() {
  const location = useLocation();
  const navigate = useNavigate(); 

  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [errorMessage, setErrorMessage] = useState(''); 

  useEffect(() => {
    const pathToken = location.pathname.split('/reset-password/')[1];
    if (pathToken) {
      setToken(pathToken.replace('/', ''));
    }

    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(
        passwordRegex,
        'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character'
      ),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  const defaultValues = {
    password: '',
    confirmpassword: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const payload = {
        token,
        email,
        password: data.password,
        confirm_password: data.confirmpassword,
      };

      
      const response = await ResetPassword(payload);
      console.log('API Response:', response);

      
      setSuccessMessage('Password reset successfully.');
      setErrorMessage(''); 

    } catch (error) {
      console.error('Error resetting password:', error);

      
      setSuccessMessage(''); 
      setErrorMessage('Failed to reset password. Please try again.');
    }
  };

  const handleLoginClick = () => {
    navigate('/');
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          label="Password *"
          placeholder="Enter new password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth 
        />

        <RHFTextField
          name="confirmpassword"
          label="Confirm Password *"
          placeholder="Confirm password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth 
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ marginTop: '30px' }}
      >
        Submit
      </LoadingButton>

      {/* Success Message */}
      {successMessage && (
        <Typography variant="body1" color="success.main" sx={{ marginTop: '20px' }}>
          {successMessage} {' '}
          <Link component="button" variant="body1" onClick={handleLoginClick}>
            Click here to log in.
          </Link>
        </Typography>
      )}

      {/* Error Message */}
      {errorMessage && (
        <Typography variant="body1" color="error.main" sx={{ marginTop: '20px' }}>
          {errorMessage}
        </Typography>
      )}
    </FormProvider>
  );
}
