import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  Download,
  Refresh,
  Search,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,

  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
//   import { getCopilotSummary } from '../../API/NetworkDevices/networkDevices';
import { getWanCopilotEvents } from '../../API/WifiEvents/wifi'
import { getUser } from '../../API/User/User';
import { UserContext } from '../../context/UserContext';
import { defaultAgGridProps } from '../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../utils/columnDef';
import { timeSince, toCamelizeWords } from '../../utils/formatString';
import { fDateTimeForDownloadCsv } from '../../utils/formatTime';
import ArrayToCSVdownload from '../ArrayToCSVdownload';
import ISPDownTableToolbar from './ISPDownTableToolbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ISPDownTableDataAPI } from '../../API/CoPilot/Copilot';
import TroubleshootInsightsDialog from './TroubleshootInsightsDialog';
import DeviceUptimeHeader from '../InventoryComponent/components/DeviceUptimeHeader';
import ColumnSettingsDialog from '../InventoryComponent/components/filters/ColumnSettingsDialog';
// import { ActionRender, IpRenderer, VulnerableRenderer } from '../InventoryComponent/components/renderer';
import WanActionRender from './renderer/WanActionRender';
import StarIcon from '@mui/icons-material/Star';

import { useTheme } from '@mui/material';

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
    minWidth: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'left',
  },
  progressStyle: {
    position: 'absolute',
    left: 13,
    top: 18,
  },
}));

// ----------------------------------------------------------------------

const WanTable = (props) => {
  const { locationData, deviceTypes, setDeviceTypes, setVendorsType, vendorsType } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [filterModel, setFilterModel] = useState('');
  const [sortModel, setSortModel] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const refreshStats = useSelector((state) => state.statCounter.refreshStats);
  let controller = new AbortController();
  const locationDataRef = useRef(locationData);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [downIspData, setDownIspData] = useState([]);
  const [openInsightDialog, setOpenInsightDialog] = useState(false);
  const [value, setValue] = useState(null);

  const mspClientId = localStorage.getItem('cust_id');
  const regClientId = localStorage.getItem('reg_cust_id');
  const mspStatus = localStorage.getItem('msp_status');

  const onInsightClick = (e, val) => {
    setValue(val);
    setOpenInsightDialog(true);
  };

  const _ISPDownTableDataAPI = async () => {
    await ISPDownTableDataAPI(setDownIspData);
  };

  useEffect(() => {
    _ISPDownTableDataAPI();
  }, []);


  useEffect(() => {
    locationDataRef.current = locationData;
  }, [locationData]);

  const theme = useTheme();
  const is1024px = useMediaQuery(theme.breakpoints.only('md'));
  const is1440px = useMediaQuery('(min-width:1440px) and (max-width:2559px)');
  const is2560px = useMediaQuery('(min-width:2560px)');

  const fontSize = is2560px ? '20px' : is1440px ? '16px' : is1024px ? '14px' : '12px';

  useEffect(() => {
    if (gridApi) {
      if (controller) {
        controller.abort();
        controller = new AbortController();
      }
      gridApi.purgeInfiniteCache();
    }
  }, [refreshStats, locationData]);

  useEffect(() => {
    if (navigator.onLine) {
      getUser().then((res) => {
        setUserCustomer(res.customer?.name);
      });
    }
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
        deviceType: deviceTypes,
        vendor: vendorsType,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, deviceTypes, vendorsType]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-inventory-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);



  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }


  const columnHeaders = [
    {
      headerName: '#',
      colId: 'id',
      width: 90,
      // pinned: 'left',
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        const id = params.node.id;
        if (params.node.id === undefined) {
          return '';
        }
        if (params.node.id === 0) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'Circuit Id',
    //   colId: 'circuit_id',
    //   sortable: true,
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data?.circuit_id;
    //   },
    //   cellStyle: {
    //     fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',
    //     lineHeight: '1.5'
    //   },
    // },
    {
      headerName: 'Site',
      colId: 'location',
      floatingFilter: false,
      width: 200,
      // pinned: 'left',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.location;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'City',
      colId: 'city',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.city;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Public Ip',
      colId: 'public_ip',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.public_ip;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Vendor',
      colId: 'vendor',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.vendor;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Internal IP',
      colId: 'internal_ip',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.internal_ip;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },

    {
      headerName: 'Default Gateway',
      colId: 'default_gateway',
      sortable: true,
      width: 200,
      // pinned: 'right',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.default_gateway;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Down Time',
      colId: 'Down Time',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        const time = convertMinToHourMinutes(params.data?.down_cnt)
        return time;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Co-pilot Summary',
      colId: 'troubleshoot_action',
      floatingFilter: false,
      width: 500,
      filter: false,
      sortable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.troubleshoot_action;
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },
    {
      headerName: 'Insights',
      colId: 'insights',
      floatingFilter: false,
      width: 200,
      // pinned: 'left',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'actionRender',
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },
    },

  ];

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };


  const onGridReady = useCallback((params) => {

    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
        deviceType: deviceTypes,
        vendor: vendorsType,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: locationDataRef.current,
          deviceType: _params.context.deviceType,
          vendor: _params.context.vendor,
          filterModel: {
            ..._params.filterModel,
          },
        };
        if (_params.context.vendor) {
          payload.filterModel.l_dvc_typ_vendor = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.vendor,
          };
        }

        if (_params.context.deviceType) {
          payload.filterModel.l_dvc_typ_name = {
            filterType: 'text',
            type: '=',
            filter: _params.context.deviceType === "Unknown" ? "" : _params.context.deviceType,
          };
        }

        delete payload.context;
        setFilterModel(payload.filterModel);
        setSortModel(_params.sortModel);
        getWanCopilotEvents(customerId, payload, controller).then((inventoryData) => {
          let lastRow = -1;
          if (inventoryData.total <= _params.endRow) {
            lastRow = inventoryData.total;
          }
          setTotalItems(inventoryData.total);
          // if (inventoryData.total === 0) {
          //   params.api.showNoRowsOverlay();
          // }
          _params.successCallback(inventoryData, lastRow);
          setRefreshLoading(false);

        })
          .catch((e) => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [query, deviceTypes, vendorsType, getWanCopilotEvents, customerId, controller]);

  const onBtnExport = () => {
    setDownloadLoading(true);
    const inventory = [];
    const pages = Math.ceil(totalItems / 200);

    const promises = Array(pages)
      .fill()
      .map((item, index) => {
        const startIndex = index * 200;
        const endIndex = Math.min((index + 1) * 200, totalItems + 1);
        const payload = {
          endRow: endIndex,
          startRow: startIndex,
          location_uuids: locationData,
          deviceType: deviceTypes,
          global_keyword: query,
          sortModel,
          filterModel,
        };
        return getWanCopilotEvents(customerId, payload);
      });

    const allowedColumns = columnDefs.filter((def) => !def.hide).map((def) => def.colId);


    try {
      Promise.all(promises)
        .then((res) => {
          res.forEach((item, index) => {
            item.data?.forEach((device) => {
              const record = {};
              allowedColumns.forEach((column) => {
                switch (column) {
                  case 'circuit_id':
                    record['Circuit Id'] = device?.circuit_id ?? '';
                    break;
                  case 'r_location_name':
                    record['Location'] = device?.r_location_name ?? '';
                    break;
                  case 'r_dvc_name':
                    record['Device Name'] = device?.r_dvc_name ?? '';
                    break;
                  case 'r_dvc_model':
                    record['Device Model'] = device?.r_dvc_model ?? '';
                    break;
                  case 'r_dvc_mac_address':
                    record['Mac Address'] = device?.r_dvc_mac_address ?? '';
                    break;
                  case 'r_dvc_serial_number':
                    record['Serial Number'] = device?.r_dvc_serial_number ?? '';
                    break;
                  case 'l_dvc_typ_vendor':
                    record['Vendor'] = device?.l_dvc_typ_vendor ?? '';
                    break;
                  case 'l_dvc_typ_name':
                    record['Device Type'] = device?.l_dvc_typ_name ?? '';
                    break;
                  case 'r_dvc_firmware':
                    record['Firmware Version'] = device?.r_dvc_firmware ?? '';
                    break;
                  case 'r_dvc_eos':
                    record['EOS'] = device?.r_dvc_eos || 'Not Announced';
                    break;
                  case 'r_dvc_eol':
                    record['EOL'] = device?.r_dvc_eol || 'Not Announced';
                    break;
                  case 'r_dvc_is_vulnerable':
                    record['Vulnerable'] = device?.r_dvc_is_vulnerable || 'No';
                    break;
                  case 'r_dvc_description':
                    record['Description'] = device?.r_dvc_description ?? '';
                    break;
                  case 'item.r_dvc_error':
                    record['Information'] = device?.r_dvc_error ?? '';
                    break;
                  case 'r_dvc_uptime':
                    record['Uptime'] = device?.r_dvc_uptime ?? '';
                    break;
                  default:
                    break;
                }
              });
              inventory.push(record);
            });
          });
        })
        .then(() => {
          if (inventory.length > 0) {
            const CSV_SEPARATER = ',';
            ArrayToCSVdownload({
              data: inventory,
              headers: Object.keys(inventory[0]),
              delimiter: CSV_SEPARATER,
              filename: `NetworkDevices-${userCustomer}-${fDateTimeForDownloadCsv(new Date())}.csv`,
            });
          }

          setDownloadLoading(false);
          enqueueSnackbar('Downloaded successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        })
        .catch((error) => {
          console.error('Error:', error);
          setDownloadLoading(false);
          enqueueSnackbar('Error occurred while exporting data', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    } catch (error) {
      console.error('Error:', error);
      setDownloadLoading(false);
      enqueueSnackbar('Error occurred while exporting data', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const components = useMemo(() => ({
    // vulnerableRenderer: VulnerableRenderer,
    // ipRenderer: IpRenderer,
    actionRender: WanActionRender,
  }), []);


  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-network-inventory-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  const deleteDeviceTypeSearch = () => {
    setDeviceTypes('');
  };

  const deleteVendorsTypeSearch = () => {
    setVendorsType('');
  };

  // ----------------------------------------------------------------------
  return (
    <Card id="ndTable">
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Chip label={totalItems || 3} />
              {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
            </Grid>
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                WAN 
                {/* <span className={classes.titleDown}> {240} </span> */}
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', gap: 2 }}
            >
              {deviceTypes?.length > 0 ? (
                <Chip
                  label={toCamelizeWords(deviceTypes)}
                  value={deviceTypes}
                  variant="outlined"
                  onDelete={deleteDeviceTypeSearch}
                />
              ) : (
                ''
              )}
              {vendorsType?.length > 0 ? (
                <Chip
                  label={toCamelizeWords(vendorsType)}
                  value={vendorsType}
                  variant="outlined"
                  onDelete={deleteVendorsTypeSearch}
                />
              ) : (
                ''
              )}

              <TextField
                focused
                autoFocus
                type="text"
                size="small"
                value={query}
                variant="outlined"
                name='new-password'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search devices"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>

            {/* <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Box>
                  <Tooltip title="Download as CSV">
                    <IconButton color="primary" onClick={onBtnExport}>
                      {downloadLoading ? (
                        <CircularProgress size={27} sx={{ mr: '5px' }} />
                      ) : (
                        <Download sx={{ fontSize: '1.8rem' }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
   */}
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    gridApi={gridApi}
                    columnDefs={columnDefs}
                    openDialog={openColSettingsDialog}
                    resetColumnSetting={resetColumnSetting}
                    handleClose={() => setOpenColSettingsDialog(false)}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />

      <Divider />
      {refreshLoading && <LinearProgress />}

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowHeight={150} // Set your desired row height here
          components={components}
          onDragStopped={onDragStopped}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData,
            deviceType: deviceTypes,
            vendor: vendorsType,
          }}
        />
      </Box>
      {openInsightDialog && (
        <TroubleshootInsightsDialog
          value={value}
          openInsightDialog={openInsightDialog}
          setOpenInsightDialog={setOpenInsightDialog}
        />
      )}
    </Card>
  );
};
export default WanTable;