// @mui
import { Box, Button, Card, CardHeader, Paper, Typography, Grid } from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Lottie from 'lottie-react';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import merge from 'lodash/merge';
import { useState, useEffect } from 'react';

import Animation1 from '../../../Assets/75702.json';
// components
import SidewiseSubCards from './SidewiseSubCards';
import { sitewiseLocationData } from '../../../_mock/locations';
import { BaseOptionChart } from '../../chart';

/* eslint-disable eqeqeq */

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
    display: 'flex',
    gap: 4,
  },
});
export default function LocationSpecificData(props) {
  const { locationData } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [jitterData, setJitterData] = useState([]);
  const [upDownData, setUpDownData] = useState([]);
  const [utilizationData, setUtilizationData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const state0 = [
    {
      name: 'Latency',
      type: 'area',
      fill: 'gradient',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: 'Jitter',
      type: 'line',
      fill: 'solid',
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ];

  const chartLabels = [
    '01/01/2003',
    '02/01/2003',
    '03/01/2003',
    '04/01/2003',
    '05/01/2003',
    '06/01/2003',
    '07/01/2003',
    '08/01/2003',
    '09/01/2003',
    '10/01/2003',
    '11/01/2003',
  ];
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: state0.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  const state1 = {
    series: [
      {
        name: 'series1',
        data: jitterData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };

  const state2 = {
    series: [
      {
        data: [1, -1, 1, 1, 1, -1, 1, -1],
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
      },
      //   annotations: {
      //     position: 'back',
      //     yaxis: [
      //       {
      //         label: {
      //           text: ' ',
      //         },
      //         y: 1,
      //         y2: 0,
      //         fillColor: 'green',
      //       },
      //       {
      //         label: {
      //           text: ' ',
      //         },
      //         y: 0,
      //         y2: -1,
      //         fillColor: 'red',
      //       },
      //     ],
      //   },
      stroke: {
        curve: 'stepline',
      },
      yaxis: {
        min: -1,
        max: 1,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
    },
  };
  const state3 = {
    series: [
      {
        name: 'series1',
        data: utilizationData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };
  useEffect(() => {
    setUtilizationData(sitewiseLocationData.find((item) => item.id == locationData.id)?.utilizationSeries);
    setJitterData(sitewiseLocationData.find((item) => item.id == locationData.id)?.jitterSeries);
    setUpDownData(sitewiseLocationData.find((item) => item.id == locationData.id)?.upDownSeries);
  }, [locationData]);
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15} sx={{ mt: 2 }}>
          <div className={classes.titleStyle}>
            <Typography>Airtel - 23.12.222.22 : </Typography>
            <Typography sx={{ color: '#3bcd89' }} component={'span'}>
              UP time
            </Typography>{' '}
            4{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              days
            </Typography>
            , 18{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              hours
            </Typography>
          </div>

          <SidewiseSubCards />
          <Grid container>
            <Grid item xs={8} sm={12} md={12} lg={8} sx={{ px: 2, py: 2 }}>
              <Card variant="outlined">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency/jitter" />
                  <Tab label="Packet loss" />
                  <Tab label="Up/Down" />
                  <Tab label="Utilization" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <ReactApexChart options={state2.options} series={state2.series} type="line" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box>
                    <ReactApexChart options={state3.options} series={state3.series} type="area" height={350} />
                  </Box>
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={4} sm={12} md={12} lg={4}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Paper elevation={15} sx={{ px: 2, py: 2, height: 415 }}>
                  <Typography variant="h5" gutterBottom color="text.primary">
                    DG rechability
                  </Typography>
                  <Lottie animationData={Animation1} loop autoPlay id="anime1" style={{ height: '300px' }} />
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" sx={{ fontSize: '1rem' }}>
                      Test Here
                    </Button>
                  </Box>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15} sx={{ mt: 2 }}>
          <div className={classes.titleStyle}>
            <Typography>Airtel1 - 23.12.222.22 : </Typography>
            <Typography sx={{ color: '#3bcd89' }} component={'span'}>
              UP time
            </Typography>{' '}
            4{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              days
            </Typography>
            , 18{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              hours
            </Typography>
          </div>

          <SidewiseSubCards />
          <Grid container>
            <Grid item xs={8} sm={12} md={12} lg={8} sx={{ px: 2, py: 2 }}>
              <Card variant="outlined">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency/jitter" />
                  <Tab label="Packet loss" />
                  <Tab label="Up/Down" />
                  <Tab label="Utilization" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <ReactApexChart options={state2.options} series={state2.series} type="line" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box>
                    <ReactApexChart options={state3.options} series={state3.series} type="area" height={350} />
                  </Box>
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={4} sm={12} md={12} lg={4}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Paper elevation={15} sx={{ px: 2, py: 2, height: 415 }}>
                  <Typography variant="h5" gutterBottom color="text.primary">
                    DG rechability
                  </Typography>
                  <Lottie animationData={Animation1} loop autoPlay id="anime1" style={{ height: '300px' }} />
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" sx={{ fontSize: '1rem' }}>
                      Test Here
                    </Button>
                  </Box>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15} sx={{ mt: 2 }}>
          <div className={classes.titleStyle}>
            <Typography>Airtel - 23.12.222.22 : </Typography>
            <Typography sx={{ color: '#3bcd89' }} component={'span'}>
              UP time
            </Typography>{' '}
            4{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              days
            </Typography>
            , 18{' '}
            <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
              hours
            </Typography>
          </div>

          <SidewiseSubCards />
          <Grid container>
            <Grid item xs={8} sm={12} md={12} lg={8} sx={{ px: 2, py: 2 }}>
              <Card variant="outlined">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency/jitter" />
                  <Tab label="Packet loss" />
                  <Tab label="Up/Down" />
                  <Tab label="Utlization" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <ReactApexChart options={state2.options} series={state2.series} type="line" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box>
                    <ReactApexChart options={state3.options} series={state3.series} type="area" height={350} />
                  </Box>
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={4} sm={12} md={12} lg={4}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Paper elevation={15} sx={{ px: 2, py: 2, height: 415 }}>
                  <Typography variant="h5" gutterBottom color="text.primary">
                    DG rechability
                  </Typography>
                  <Lottie animationData={Animation1} loop autoPlay id="anime1" style={{ height: '300px' }} />
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" sx={{ fontSize: '1rem' }}>
                      Test Here
                    </Button>
                  </Box>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
